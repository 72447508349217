import React, { useEffect, useState } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';
import { multiplication } from '../shared/Shared';
import { updateScore } from '../services/RankingService';
import Snackbar from '../toaster-message/Snackbar';

const rankValue = ['1', '2', '3', '4', '5', "NA"]

function RankingDialog(props) {

    const { register, handleSubmit, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const { openRankingDialog, setOpenRankingDialog, getScoringData } = props;
    const [businessScore, setBusinessScore] = useState("");
    const [stakeholderScore, setStakeholderScore] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [value, setValue] = useState(false);

    useEffect(() => {
        if (businessScore !== "" && stakeholderScore !== "") {
            let multiplicationValue = multiplication(parseInt(businessScore === "NA" ? "1" : businessScore), parseInt(stakeholderScore))
            reset({ "maturityThreshold": multiplicationValue })
        };
        setValue(false)
    }, [value === true]);
    useEffect(() => {
        if (openRankingDialog.action === true) {
            reset(openRankingDialog.data)
            setBusinessScore(openRankingDialog.data.businessInterestScore)
            setStakeholderScore(openRankingDialog.data.stakeHolderInterestScore)
        }
    }, [openRankingDialog.action === true])

    const selectBusinessInterestScore = (event) => {
        setBusinessScore(event.target.value)
        setValue(true)
    };
    const selectStakeholderInterestScore = (event) => {
        setStakeholderScore(event.target.value)
        // if (businessScore !== "" && event.target.value !== "") {
        //     let multiplicationValue = multiplication(parseInt(businessScore === "NA" ? "5" : businessScore), parseInt(event.target.value))
        //     reset({ "maturityThreshold": multiplicationValue })
        // };
        setValue(true)
    };
    const update = async (data) => {
        let obj = {
            "corporateRefNo": openRankingDialog.data.corporateRefNo,
            "facilityRefNo": openRankingDialog.data.siteRefNo,
            "pillar": openRankingDialog.data.pillar,
            "maturityThreshold": data.maturityThreshold.toString(),
            "stakeHolderInterestScore": data.stakeHolderInterestScore,
            "businessInterestScore": data.businessInterestScore,
            "issue": data.issue
        }
        await updateScore(obj)
            .then((res) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Updated successfully",
                })
                getScoringData()
                setOpenRankingDialog({ action: false, data: "" })
                reset({ "issue": "", "maturityThreshold": "" });
                setBusinessScore("");
                setStakeholderScore("")
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
        // console.log(obj)
    };
    const handleClose = () => {
        setOpenRankingDialog({ action: false, data: "" })
        reset({ "issue": "", "maturityThreshold": "" });
        setBusinessScore("");
        setStakeholderScore("")
    };

    return (
        <div>
            <material.Dialog open={openRankingDialog.action} maxWidth="sm" fullWidth>
                <material.DialogTitle className='headingText fw-bold'>{openRankingDialog.data.issue} Ranking</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <div>
                            <material.TextField
                                required
                                label="Issue"
                                id="issue"
                                variant="standard"
                                type="text"
                                fullWidth
                                color='success'
                                InputProps={{ readOnly: true }}
                                {...register("issue", { required: true })}
                            />
                        </div>
                        <div>
                            <material.FormControl variant="standard" fullWidth className='mt-2'>
                                <material.InputLabel required id="userType">Business Interest Score</material.InputLabel>
                                <material.Select
                                    {...register("businessInterestScore", { required: true })}
                                    id="userType"
                                    value={businessScore}
                                    onChange={selectBusinessInterestScore}
                                >
                                    {rankValue.map((ele, i) => (
                                        <material.MenuItem value={ele} key={i}>{ele}</material.MenuItem>
                                    ))}
                                </material.Select>
                            </material.FormControl>
                        </div>
                        <div>
                            <material.FormControl variant="standard" fullWidth className='mt-2'>
                                <material.InputLabel required id="stakeHolderInterestScore">Stakeholder Interest Score</material.InputLabel>
                                <material.Select
                                    {...register("stakeHolderInterestScore", { required: true })}
                                    id="stakeHolderInterestScore"
                                    value={stakeholderScore}
                                    onChange={selectStakeholderInterestScore}
                                >
                                    {rankValue.map((ele, i) => (
                                        <material.MenuItem value={ele} key={i}>{ele === "NA" ? null : ele}</material.MenuItem>
                                    ))}
                                </material.Select>
                            </material.FormControl>
                        </div>
                        <div className='mt-2'>
                            <material.TextField
                                label="Score"
                                id="maturityThreshold"
                                variant="standard"
                                type="number"
                                fullWidth
                                color='success'
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                                {...register("maturityThreshold", { required: true })}
                            />
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button onClick={handleClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button color='success' variant="outlined" onClick={handleSubmit(update)} sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} disabled={!isValid}>Update</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default RankingDialog;