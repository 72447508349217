import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import DatePeriod from '../../shared/DatePeriod';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


const nationalRegulationData = ['Labor Code (Wage Code) 2019', 'Social Security Code 2020', 'OSH Code (Occupational, Safety, Health and Working Conditions Code) 2020', 'Industrial Relations (IR) Code, 2020', 'Other (Specify)']
const internationalStandardsData = [
    'ILO Convention 87 on Freedom of Association and Protection of the Right to Organize',
    'ILO Convention 98 on the Right to Organize and Collective Bargaining',
    'ILO Convention 29 on Forced Labor',
    'ILO Convention 105 on the Abolition of Forced Labor',
    'ILO Convention 138 on Minimum Age (of Employment)',
    'ILO Convention 182 on the Worst Forms of Child Labor',
    'ILO Convention 100 on Equal Remuneration',
    'ILO Convention 111 on Discrimination (Employment and Occupation)',
    'UN Convention on the Rights of the Child, Article 32.1',
    'UN Convention on the Protection of the Rights of all Migrant Workers and Members of their Families',
    'Other (Specify)',
]

function AddLaborStandards(props) {
    const { openAddLaborStandards, setOpenAddLaborStandards, getAllLaborStandards, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [standardRegulationReference, setStandardRegulationReference] = useState('');
    const [standardRegulation, setStandardRegulation] = useState('');
    const [standardRegulationData, setStandardRegulationData] = useState([]);
    const [compliedEntity, setCompliedEntity] = useState('');

    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const payloadData = {
        page,
        size: rowsPerPage
    };

    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (openAddLaborStandards.action && openAddLaborStandards.laborStandardsTableData) {
            setStandardRegulationReference(openAddLaborStandards.laborStandardsTableData.standard_or_regulation_reference);
            if (openAddLaborStandards.laborStandardsTableData.standard_or_regulation_reference === "National Regulation") {
                setStandardRegulationData(nationalRegulationData)
            }
            else if (openAddLaborStandards.laborStandardsTableData.standard_or_regulation_reference === "International Standards") {
                setStandardRegulationData(internationalStandardsData)
            };
            setStandardRegulation(openAddLaborStandards.laborStandardsTableData.standard_regulation);
            setCompliedEntity(openAddLaborStandards.laborStandardsTableData.complied_by_the_entity);
            reset(openAddLaborStandards.laborStandardsTableData);
        }
    }, [openAddLaborStandards.action === true]);

    const changeStandardRegulationReference = (event) => {
        let StandardRegulationReferenceType = event.target.value;
        setStandardRegulationReference(StandardRegulationReferenceType);
        if (StandardRegulationReferenceType === "National Regulation") {
            setStandardRegulationData(nationalRegulationData)
        }
        else if (StandardRegulationReferenceType === "International Standards") {
            setStandardRegulationData(internationalStandardsData)
        }
    };

    const selectStandardRegulation = (event) => {
        setStandardRegulation(event.target.value);
    };

    const selectCompliedEntity = (event) => {
        setCompliedEntity(event.target.value);
    };

    const handleClose = () => {
        reset({ remarks_on_management: "", other_specify: "", })
        setStandardRegulationReference("");
        setStandardRegulation("");
        setCompliedEntity("");
        setOpenAddLaborStandards({ action: false, laborStandardsTableData: null });
    };

    const saveLaborStandards = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subIssueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "standard_or_regulation_reference": data.standard_or_regulation_reference,
                "standard_regulation": data.standard_regulation,
                "other_specify": data.other_specify,
                "complied_by_the_entity": data.complied_by_the_entity,
                "remarks_on_management": data.remarks_on_management,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllLaborStandards(getFilterDate, payloadData);
                    reset({ remarks_on_management: "", other_specify: "", })
                    setStandardRegulationReference("");
                    setStandardRegulation("");
                    setCompliedEntity("");
                    setOpenAddLaborStandards({ action: false, laborStandardsTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateLaborStandards = async (data) => {
        const refNo = openAddLaborStandards.laborStandardsTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "standard_or_regulation_reference": data.standard_or_regulation_reference,
            "standard_regulation": data.standard_regulation,
            "other_specify": standardRegulation === "Other (Specify)" ? data.other_specify : "",
            "complied_by_the_entity": data.complied_by_the_entity,
            "remarks_on_management": data.remarks_on_management,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllLaborStandards(getFilterDate, payloadData);
                reset({ remarks_on_management: "", other_specify: "", })
                setStandardRegulationReference("");
                setStandardRegulation("");
                setCompliedEntity("");
                setOpenAddLaborStandards({ action: false, laborStandardsTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };



    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAddLaborStandards.laborStandardsTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ remarks_on_management: "", other_specify: "", })
                setStandardRegulationReference("");
                setStandardRegulation("");
                setCompliedEntity("");
                getAllLaborStandards(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddLaborStandards({ action: false, laborStandardsTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddLaborStandards.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddLaborStandards.laborStandardsTableData ? "Edit Labor Standards" : "Add Labor Standards"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddLaborStandards.laborStandardsTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Labor Standards</material.Typography>
                            </div>
                            <div>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Standard/Regulation Reference</material.InputLabel>
                                    <material.Select
                                        {...register("standard_or_regulation_reference", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={standardRegulationReference}
                                        onChange={changeStandardRegulationReference}
                                        label="Standard / Regulation Reference"
                                    >
                                        <material.MenuItem value={"National Regulation"}>National Regulation</material.MenuItem>
                                        <material.MenuItem value={"International Standards"}>International Standards</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='mt-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Standard Regulation</material.InputLabel>
                                    <material.Select
                                        {...register("standard_regulation", { required: true, })}
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={standardRegulation}
                                        onChange={selectStandardRegulation}
                                        label="Standard Regulation"
                                    >
                                        {standardRegulationData.map((ele, ind) => (
                                            <material.MenuItem value={ele} key={ind}>{ele}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {standardRegulation === "Other (Specify)" ? (
                                <div className='mt-2'>
                                    <material.TextField
                                        {...register("other_specify", { required: true, maxLength: 20 })}
                                        required
                                        label="Other Standard / Regulation (Specify)"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                        multiline
                                    />
                                    <p className='form-text text-danger'>{errors.other_specify?.type === "required" && 'This field is required'}</p>
                                    {errors?.other_specify?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='mt-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Complied by the Entity</material.InputLabel>
                                    <material.Select
                                        {...register("complied_by_the_entity", { required: true, })}
                                         label="Complied by the Entity"
                                        required
                                        fullWidth
                                        // labelId="demo-simple-select-standard-label"
                                        // id="demo-simple-select-standard"
                                        value={compliedEntity}
                                        onChange={selectCompliedEntity}
                                       
                                    >
                                        <material.MenuItem value={"Yes"}>Yes</material.MenuItem>
                                        <material.MenuItem value={"No"}>No</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='mt-2'>
                                <material.TextField
                                    {...register("remarks_on_management", { required: true, minLength: 2, maxLength: 200 })}
                                    required
                                    label="Remarks on Management"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                                <p className='form-text text-danger'>{errors.remarks_on_management?.type === "required" && 'This field is required'}</p>
                                {errors.remarks_on_management && (errors.remarks_on_management.type === "minLength" || errors.remarks_on_management.type === "maxLength") && (
                                    <p className="errorMsg text-danger">Describe The Remarks on Management Issues should be between 2 to 200 character.</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddLaborStandards.laborStandardsTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateLaborStandards)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddLaborStandards.laborStandardsTableData.rejection_note === null) || (openAddLaborStandards.laborStandardsTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveLaborStandards)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddLaborStandards;