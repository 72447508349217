import React, { useEffect, useState } from 'react';
import { material } from '../library/Material';
import Table from '../shared/Table';
import { tableContent, actions } from '../shared/AllTableContent';
import { addCorporate, getCorporateNicDetails, getCorporateWithFacilitiesByCorporateRefNo, getFacilityCountByNationalInterNational, getFacilityCountByStateCountry, getMarketServedEntity, getProductServiceDetailsByCorporateRefNo } from '../services/CorporateService';
import { useSelector } from 'react-redux';
import ProductOrServices from '../component/product-or-services/ProductOrServices';
import { useForm } from 'react-hook-form';
import Snackbar from '../toaster-message/Snackbar';
import UploadDocument from './upload-document/UploadDocument';

function BusinessAndOperationsInfo(props) {
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [facilityData, setFacility] = useState([]);
    const [productServiceData, setProductServiceData] = useState([]);
    const [nationalInternationalFacilityData, setNationalInternationalData] = useState([]);
    const [stateCountryFacilityData, setStateCountryFacilityData] = useState([]);
    const [marketServedData, setMarketServedData] = useState({});
    const [editMarketServedByEntity, setEditMarketServedByEntity] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const { register, handleSubmit, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });

    useEffect(() => {
        getCorporateWithFacilities();
        getProductServiceDetails();
        getCountByNationalInternationalFacility();
        getCountByStateCountryFacility();
        getMarketServedEntityData();
    }, []);
    const getCorporateWithFacilities = async () => {
        await getCorporateWithFacilitiesByCorporateRefNo(userDetails.corporateRefNo)
            .then((resp) => {
                setFacility(resp.data.facilities)
                // console.log(resp.data)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    };
    const getProductServiceDetails = async () => {
        await getProductServiceDetailsByCorporateRefNo(userDetails.corporateRefNo)
            .then((resp) => {
                setProductServiceData(resp.data)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    };
    const getCountByNationalInternationalFacility = async () => {
        await getFacilityCountByNationalInterNational(userDetails.corporateRefNo)
            .then((resp) => {
                setNationalInternationalData(resp.data)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    };
    const getCountByStateCountryFacility = async () => {
        await getFacilityCountByStateCountry(userDetails.corporateRefNo)
            .then((resp) => {
                setStateCountryFacilityData(resp.data)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    };
    const getMarketServedEntityData = async () => {
        await getMarketServedEntity(userDetails.corporateRefNo)
            .then((resp) => {
                setMarketServedData(resp.data)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    }
    const editMarketServedData = (data) => {
        reset(data)
        setEditMarketServedByEntity(true)
    };
    const closeMarketServedData = () => {
        setEditMarketServedByEntity(false)
    };
    const saveMarketServedData = async (data) => {
        if (parseFloat(data.contributionOfExport) <= 100) {
            let obj = {
                "contributionOfExport": data.contributionOfExport,
                "typeOfCustomer": data.typeOfCustomer,
                "refNo": userDetails.corporateRefNo
            }
            await addCorporate(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Updated successfully",
                    })
                    getMarketServedEntityData()
                    setEditMarketServedByEntity(false)
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    };
                })
        } else {
            alert("Total exceeding 100")
        };
    };

    return (
        <div>
            <material.Paper elevation={3} sx={{ p: 3 }}>
                <material.Accordion>
                    <material.AccordionSummary
                        expandIcon={<material.ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>Facility Details</material.Typography>
                    </material.AccordionSummary>
                    <material.AccordionDetails>
                        <material.Typography>
                            <Table
                                columns={tableContent[0].facilityDetailsColumns}
                                fields={facilityData} callFrom={"facilityDetails"}
                                getCorporateWithFacilities={getCorporateWithFacilities}
                                actions={actions}
                            />
                        </material.Typography>
                    </material.AccordionDetails>
                </material.Accordion>
                <material.Accordion sx={{ mt: 2 }}>
                    <material.AccordionSummary
                        expandIcon={<material.ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>Business Activities</material.Typography>
                    </material.AccordionSummary>
                    <material.AccordionDetails>
                        <material.Typography>
                            <Table
                                columns={tableContent[1].businessActivitiesColumns}
                                fields={facilityData}
                                callFrom={"businessActivities"}
                                getCorporateWithFacilities={getCorporateWithFacilities}
                            />
                        </material.Typography>
                    </material.AccordionDetails>
                </material.Accordion>
                <material.Accordion sx={{ mt: 2 }}>
                    <material.AccordionSummary
                        expandIcon={<material.ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>Products/Services</material.Typography>
                    </material.AccordionSummary>
                    <material.AccordionDetails>
                        <material.Typography>
                            <ProductOrServices
                                columns={tableContent[2].productsOrServicesColumns}
                                fields={productServiceData}
                                getProductServiceDetails={getProductServiceDetails}
                            />
                        </material.Typography>
                    </material.AccordionDetails>
                </material.Accordion>
                <material.Accordion sx={{ mt: 2 }}>
                    <material.AccordionSummary
                        expandIcon={<material.ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>Number of Facility Offices and Plants</material.Typography>
                    </material.AccordionSummary>
                    <material.AccordionDetails>
                        <material.Typography>
                            <Table
                                columns={tableContent[3].numberOfFacilityPlantsAndOfficesColumns}
                                fields={stateCountryFacilityData}
                            />
                            <div className='mt-3'>
                                <Table
                                    columns={tableContent[4].numberOfLocationsWherePlantsAndOfficesColumns}
                                    fields={nationalInternationalFacilityData}
                                />
                            </div>
                        </material.Typography>
                    </material.AccordionDetails>
                </material.Accordion>
                <material.Accordion sx={{ mt: 2 }}>
                    <material.AccordionSummary
                        expandIcon={<material.ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>Markets Served by The Entity</material.Typography>
                    </material.AccordionSummary>
                    <material.AccordionDetails>
                        <material.Typography>
                            <form>
                                <div className="row">
                                    <div className="col-6"></div>
                                    <div className="col-6">
                                        <span className='float-end'>
                                            {editMarketServedByEntity ? (
                                                <>
                                                    <material.IconButton sx={{ mt: -1 }} title='Save Corporate Details' aria-label="create" size="large" onClick={handleSubmit(saveMarketServedData)}>
                                                        <material.SaveIcon color='success' />
                                                    </material.IconButton>
                                                    <material.IconButton sx={{ mt: -1 }} title='Close' aria-label="create" size="large" onClick={closeMarketServedData}>
                                                        <material.CloseIcon color='error' />
                                                    </material.IconButton>
                                                </>
                                            ) : (
                                                <material.IconButton sx={{ mt: -1 }} title='Edit Corporate Details' aria-label="create" size="large" onClick={() => editMarketServedData(marketServedData)}>
                                                    <material.CreateIcon color='success' />
                                                </material.IconButton>
                                            )}
                                        </span>
                                    </div>
                                </div>
                                {editMarketServedByEntity ? (
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-6">
                                            <material.TextField
                                                label="International (No. of Countries)"
                                                id="number_of_country"
                                                variant="standard"
                                                type="text"
                                                size="small"
                                                fullWidth
                                                color='success'
                                                InputProps={{ readOnly: true }}
                                                {...register("number_of_country", { required: true })}
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <material.TextField
                                                label="National (No. of States)"
                                                id="number_of_states"
                                                variant="standard"
                                                type="text"
                                                size="small"
                                                fullWidth
                                                color='success'
                                                InputProps={{ readOnly: true }}
                                                {...register("number_of_states", { required: true })}
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <material.TextField
                                                error={errors.contributionOfExport?.type === "required"}
                                                label="Contribution of exports as a percentage (%)"
                                                id="contributionOfExport"
                                                variant="standard"
                                                type="number"
                                                size="small"
                                                fullWidth
                                                color='success'
                                                {...register("contributionOfExport", { required: true, pattern: /^(0|[1-9]\d*)(\.\d+)?$/i })}
                                            />
                                            {(errors?.contributionOfExport?.type === "pattern") && (
                                                <p className='text-danger'>Invalid input, please input only number</p>
                                            )}
                                        </div>
                                        <div className="col-lg-12 col-md-12 mt-3">
                                            <material.TextField
                                                label="Types of customers (a brief)"
                                                id="typeOfCustomer"
                                                variant="standard"
                                                type="text"
                                                size="small"
                                                fullWidth
                                                multiline
                                                color='success'
                                                {...register("typeOfCustomer", { required: true, maxLength: 4000 })}
                                            />
                                            {(errors?.typeOfCustomer?.type === "maxLength") && (
                                                <p className='text-danger'>Can not exceed 4000 character</p>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                            <span className='fw-bold'>Number of locations</span>
                                            <table className="table ms-3">
                                                <thead>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className='col-4'>National (No. of States) :</td>
                                                        <td className='col-8'>{marketServedData.number_of_states}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='col-4'>International (No. of Countries) :</td>
                                                        <td className='col-8'>{marketServedData.number_of_country}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='col-4'>Contribution of exports (% of total Turnover) :</td>
                                                        <td className='col-8'>{marketServedData.contributionOfExport}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='col-4'>Customer types :</td>
                                                        <td className='col-8' style={{ width: "10rem" }}>{marketServedData.typeOfCustomer}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </material.Typography>
                    </material.AccordionDetails>
                </material.Accordion>
                <material.Accordion sx={{ mt: 2 }}>
                    <material.AccordionSummary
                        expandIcon={<material.ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>Uploaded Documentation</material.Typography>
                    </material.AccordionSummary>
                    <material.AccordionDetails>
                        <material.Typography>
                            <UploadDocument />
                        </material.Typography>
                    </material.AccordionDetails>
                </material.Accordion>
            </material.Paper>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default BusinessAndOperationsInfo;