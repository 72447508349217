import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateDiscriminationIncidentsData } from '../../services/sub-issue-services/non-discrimination service/DiscriminationIncidentsService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddDiscriminationIncidents(props) {

    const { openDiscriminationIncidents, setOpenDiscriminationIncidents, getDiscriminationIncidentsAllData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, watch, getValues, control, setValue, formState: { errors }, } = useForm({ mode: "onTouched" });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const onChangeIncidentsOfDiscrimination = watch('incidentsOfDiscrimination');
    const onChangePlantsAndOfficesAssessedThirdParty = watch('plantsAndOfficesAssessedThirdParty');
    const onChangeSupplyChainAssessedThirdParty = watch('supplyChainAssessedThirdParty');
    const onChangeIncidentReviewed = watch('incidentReviewed');
    const onChangeRemediationPlanImplemented = watch('remediationPlanImplemented');
    const onChangeRemediationPlansImplementedAndResultReviewed = watch('remediationPlansImplementedAndResultReviewed');
    const onChangeIncidentNoLongerSubjectToAction = watch('incidentNoLongerSubjectToAction');

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        totalCalculation();
    }, [onChangeIncidentsOfDiscrimination, onChangeIncidentReviewed, onChangeRemediationPlanImplemented, onChangeSupplyChainAssessedThirdParty, onChangeRemediationPlansImplementedAndResultReviewed, onChangePlantsAndOfficesAssessedThirdParty, onChangeIncidentNoLongerSubjectToAction]);

    const totalCalculation = () => {
        let incidentsOfDiscriminationValue = getValues('incidents_of_discrimination');
        let plantsAndOfficesAssessedThirdPartyValue = getValues('plants_and_offices_assessed_third_party');
        let supplyChainAssessedThirdPartyValue = getValues('supply_chain_assessed_third_party');
        let incidentReviewedValue = getValues('incident_reviewed');
        let remediationPlanImplementedValue = getValues('remediation_plan_implemented');
        let remediationPlansImplementedAndResultReviewedValue = getValues('remediation_plans_implemented_and_result_reviewed');
        let incidentNoLongerSubjectToActionValue = getValues('incident_no_longer_subject_to_action');
        if (Number(incidentReviewedValue) > Number(incidentsOfDiscriminationValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Incident reviewed value not greater than incidents of discrimination value",
            })
            setButtonDisable(true)
        } else if (Number(plantsAndOfficesAssessedThirdPartyValue) > Number(incidentsOfDiscriminationValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Plants and offices third party value not greater than Number of violations incidents value",
            })
            setButtonDisable(true)
        } else if (Number(supplyChainAssessedThirdPartyValue) > Number(incidentsOfDiscriminationValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Supply chain assessed by third party value not greater than Number of violations incidents value",
            })
            setButtonDisable(true)
        } else if (Number(remediationPlanImplementedValue) > Number(incidentsOfDiscriminationValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Remediation plan implemented value not greater than Number of violations incidents value",
            })
            setButtonDisable(true)
        } else if (Number(remediationPlansImplementedAndResultReviewedValue) > Number(incidentsOfDiscriminationValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Remediation plans implemented & result reviewed routinely value not greater than Number of violations incidents value",
            })
            setButtonDisable(true)
        } else if (Number(incidentNoLongerSubjectToActionValue) > Number(incidentsOfDiscriminationValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Incident no longer subject to action value not greater than Number of violations incidents value",
            })
            setButtonDisable(true)
        } else {
            setButtonDisable(false)
        }
    };

    useEffect(() => {
        if (openDiscriminationIncidents.action && openDiscriminationIncidents.discriminationIncidentsTableData) {
            reset(openDiscriminationIncidents.discriminationIncidentsTableData)
        };
    }, [openDiscriminationIncidents.action === true]);

    const handleClose = () => {
        reset({ incidents_of_discrimination: "", plants_and_offices_assessed_third_party: "", supply_chain_assessed_third_party: "", remediation_plans_implemented_and_result_reviewed: "", incident_no_longer_subject_to_action: "", incident_reviewed: "", remediation_plan_implemented: "" })
        setOpenDiscriminationIncidents({ action: false, discriminationIncidentsTableData: null });
    };

    const saveDiscriminationIncidents = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "incidents_of_discrimination": Number(data.incidents_of_discrimination),
                "plants_and_offices_assessed_third_party": Number(data.plants_and_offices_assessed_third_party),
                "supply_chain_assessed_third_party": Number(data.supply_chain_assessed_third_party),
                "incident_reviewed": data.incident_reviewed,
                "remediation_plan_implemented": data.remediation_plan_implemented,
                "remediation_plans_implemented_and_result_reviewed": Number(data.remediation_plans_implemented_and_result_reviewed),
                "incident_no_longer_subject_to_action": Number(data.incident_no_longer_subject_to_action),
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(object, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getDiscriminationIncidentsAllData(getFilterDate, payloadData);
                    reset({ incidents_of_discrimination: "", plants_and_offices_assessed_third_party: "", supply_chain_assessed_third_party: "", remediation_plans_implemented_and_result_reviewed: "", incident_no_longer_subject_to_action: "", incident_reviewed: "", remediation_plan_implemented: "" })
                    setOpenDiscriminationIncidents({ action: false, discriminationIncidentsTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateDiscriminationIncidents = async (data) => {
        const refNo = openDiscriminationIncidents.discriminationIncidentsTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let object = {
            "incidents_of_discrimination": Number(data.incidents_of_discrimination),
            "plants_and_offices_assessed_third_party": Number(data.plants_and_offices_assessed_third_party),
            "supply_chain_assessed_third_party": Number(data.supply_chain_assessed_third_party),
            "incident_reviewed": data.incident_reviewed,
            "remediation_plan_implemented": data.remediation_plan_implemented,
            "remediation_plans_implemented_and_result_reviewed": Number(data.remediation_plans_implemented_and_result_reviewed),
            "incident_no_longer_subject_to_action": Number(data.incident_no_longer_subject_to_action),
        }
        await updateSubIssueData(object, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getDiscriminationIncidentsAllData(getFilterDate, payloadData);
                reset({ incidents_of_discrimination: "", plants_and_offices_assessed_third_party: "", supply_chain_assessed_third_party: "", remediation_plans_implemented_and_result_reviewed: "", incident_no_longer_subject_to_action: "", incident_reviewed: "", remediation_plan_implemented: "" })
                setOpenDiscriminationIncidents({ action: false, discriminationIncidentsTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openDiscriminationIncidents.discriminationIncidentsTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                getDiscriminationIncidentsAllData(getFilterDate, payloadData);
                reset({ incidents_of_discrimination: "", plants_and_offices_assessed_third_party: "", supply_chain_assessed_third_party: "", remediation_plans_implemented_and_result_reviewed: "", incident_no_longer_subject_to_action: "", incident_reviewed: "", remediation_plan_implemented: "" })
                setOpenDiscriminationIncidents({ action: false, discriminationIncidentsTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <>
            <material.Dialog open={openDiscriminationIncidents.action} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold headingText'>
                    {openDiscriminationIncidents.discriminationIncidentsTableData ? "Edit Discrimination Incidents" : "Add Discrimination Incidents"}
                </material.DialogTitle>
                <material.DialogContent>
                    <div className='my-3'>
                        {openDiscriminationIncidents.discriminationIncidentsTableData ? null : (
                            <DatePeriod
                                register={register}
                                control={control}
                            />
                        )}
                    </div>
                    <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Discrimination Incidents</material.Typography>
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("incidents_of_discrimination", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Incidents of Discrimination"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.incidents_of_discrimination?.type === "required" && 'This field is required'}</p>
                                {errors.incidents_of_discrimination?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("plants_and_offices_assessed_third_party", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Plants and Offices Assessed by 3rd Party"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.plants_and_offices_assessed_third_party?.type === "required" && 'This field is required'}</p>
                                {errors.plants_and_offices_assessed_third_party?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("supply_chain_assessed_third_party", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Supply Chain Assessed by 3rd Party"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.supply_chain_assessed_third_party?.type === "required" && 'This field is required'}</p>
                                {errors.supply_chain_assessed_third_party?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("incident_reviewed", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Incident Reviewed"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.incident_reviewed?.type === "required" && 'This field is required'}</p>
                                {errors.incident_reviewed?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("remediation_plan_implemented", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Remediation Plan Implemented"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.remediation_plan_implemented?.type === "required" && 'This field is required'}</p>
                                {errors.remediation_plan_implemented?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("remediation_plans_implemented_and_result_reviewed", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Remediation Plans Implemented & Result Reviewed Routinely"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.remediation_plans_implemented_and_result_reviewed?.type === "required" && 'This field is required'}</p>
                                {errors.remediation_plans_implemented_and_result_reviewed?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("incident_no_longer_subject_to_action", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Incident no Longer Subject to Action"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.incident_no_longer_subject_to_action?.type === "required" && 'This field is required'}</p>
                                {errors.incident_no_longer_subject_to_action?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openDiscriminationIncidents.discriminationIncidentsTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateDiscriminationIncidents)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openDiscriminationIncidents.discriminationIncidentsTableData.rejection_note === null) || (openDiscriminationIncidents.discriminationIncidentsTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveDiscriminationIncidents)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </>
    )
}

export default AddDiscriminationIncidents