import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import FilterImage from '../../../assets/filter.png';
import Loader from '../../../shared/Loader';
import { MoreOptionMenu } from '../Dashboard';
import ColumnChart from '../../../shared/reporting/ColumnChart';
import GlobalFilter from '../GlobalFilter';
import { useSelector } from 'react-redux';
import PieChart from '../../../shared/reporting/PieChart';
import { getEnergyConsumptionOutsideReportData, getEnergyReportData } from '../../../services/reporting-services/EnergyService';
import Snackbar from '../../../toaster-message/Snackbar';
import dayjs from 'dayjs';
import { tableHeaderContent } from '../../../shared/reporting/TableHeader';
import BarsLoader from '../../../shared/loader/BarsLoader';



const yearData = dayjs(new Date());

function EnergyDashboard(props) {
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [isLoading, setIsLoading] = useState(false);
    const [openFilterDialog, setOpenFilterDialog] = useState({ action: false, userData: null });
    const [isBarsLoader, setIsBarsLoader] = useState(true);
    const [value, setValue] = React.useState(dayjs(new Date()));
    localStorage.setItem("year", yearData.$y);
    const year = localStorage.getItem("year");
    const [columnChartLoading, setColumnChartLoading] = useState(true);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [chartTableData, setChartTableData] = useState([]);
    const [energyConsumptionChartData, setEnergyConsumptionChartData] = useState(null);
    const [energyConsumptionOutsideChartData, setEnergyConsumptionOutsideChartData] = useState(null);
    const [energyConsumptionOutsideTableData, setEnergyConsumptionOutsideTableData] = useState([]);
    const [electricityPieChartData, setElectricityPieChartData] = useState(null);
    const [energyConsumptionTableData, setEnergyConsumptionTableData] = useState([]);

    const energyConsumptionFromRenewableNonRenewableSourceOptions = {
        title: "Energy Consumption from Renewable & Non Renewable Source",
        titleTextStyle: {
            fontName: 'Arial', // Font family of the title
            fontSize: 18,      // Size of the title text
            bold: true,        // Make the title text bold
            italic: false      // Title text style is not italic
        },
        colors: ['#81272e', '#a15755', '#dfb591', '#a15755', '#5f8971'],
        vAxis: { title: "Energy (GJ)" },
        hAxis: { title: "Source" },
        seriesType: "bars",
        chartArea: { width: "75%" },
        legend: { position: "top" },
    };

    const energyConsumptionOutsideOptions = {
        title: "Energy consumption Outside",
        titleTextStyle: {
            fontName: 'Arial', // Font family of the title
            fontSize: 18,      // Size of the title text
            bold: true,        // Make the title text bold
            italic: false      // Title text style is not italic
        },
        colors: ['#79bedb', '#9f6210', '#562e19', '#a15755', '#5f8971'],
        hAxis: {
            title: 'Category',
        },
        vAxis: {
            title: 'Energy (GJ)',
        },
        legend: {
            position: "top",
            alignment: 'end'
        },
        chartArea: { width: "80%" },
    };

    const energyConsumptionWithinOrganizationElectricityGJOptions = {
        title: "Energy consumption within Organization - Electricity(GJ)",
        titleTextStyle: {
            fontName: 'Arial', // Font family of the title
            fontSize: 18,      // Size of the title text
            bold: true,        // Make the title text bold
            italic: false      // Title text style is not italic
        },
        colors: [ '#9f6210', '#562e19', '#a15755', '#5f8971','#79bedb'],
        vAxis: { title: "Energy (GJ)" },
        hAxis: { title: "Source" },
        seriesType: "bars",
        chartArea: { width: "75%" },
        legend: { position: "right" },
    };

    useEffect(() => {
        // let facilities = null;
        const facilities = userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? null : [userDetails.facilityRefNo];
        getAllEnergyReportData(facilities, year);
        getEnergyConsumptionOutsideData(facilities, year);
    }, []);

    const getAllEnergyReportData = async (facilities, year) => {
        let array = []
        let derivedDataArray = [];
        let fuelDataArray = [];
        setColumnChartLoading(true);
        setIsBarsLoader(true);
        let obj = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityList: facilities?.length > 0 ? facilities.join(',') : null,
            YearList: year
        }
        await getEnergyReportData(obj)
            .then((resp) => {
                console.log(resp.data)
                let totalRenewableElectricity = 0;
                let totalNonRenewableElectricity = 0;
                let totalRenewableFuel = 0;
                let totalNonRenewableFuel = 0;

                if (resp.data.consumptionDerivedData) {
                    resp.data.consumptionDerivedData.forEach(facility => {
                        facility.breakdown.forEach(breakdownItem => {
                            if (breakdownItem.type === 'Electricity') {
                                totalRenewableElectricity += breakdownItem.renewable;
                                totalNonRenewableElectricity += breakdownItem.nonRenewable;
                            } else if (breakdownItem.type === 'Fuel') {
                                totalRenewableFuel += breakdownItem.renewable;
                                totalNonRenewableFuel += breakdownItem.nonRenewable;
                            }
                        });
                    });
                }
                const totalElectricity = totalRenewableElectricity + totalNonRenewableElectricity;
                const totalFuel = totalRenewableFuel + totalNonRenewableFuel;
                const totalEnergy = totalElectricity + totalFuel;

                setEnergyConsumptionChartData([
                    ["Category", "Electricity", "Fuel", "Total"],
                    ["Renewable", totalRenewableElectricity, totalRenewableFuel, totalRenewableElectricity + totalRenewableFuel],
                    ["Non-Renewable", totalNonRenewableElectricity, totalNonRenewableFuel, totalNonRenewableElectricity + totalNonRenewableFuel],
                    // ["Total", totalElectricity, totalFuel, totalEnergy]
                ]);

                let totalSelfProduced = 0;
                let totalPurchased = 0;
                let grandTotal = 0;
                resp.data.electricityConsumptionData.facilityWiseData.forEach(facility => {
                    const facilityName = facility.facilityName;
                    facility.dataBreakDown.forEach(electricityData => {
                        const typeOfSource = electricityData.typeOfSource;
                        electricityData.data.forEach(data => {
                            const row = {
                                facilityName,
                                typeOfSource,
                                purchased: data.purchased,
                                purpose: data.purpose,
                                selfProduced: data.selfProduced,
                                total: data.total
                            }
                            array.push(row);

                            totalSelfProduced += data.selfProduced;
                            totalPurchased += data.purchased;
                            grandTotal += data.total;
                        })
                    })
                });
                array.push({
                    facilityName: "Total",
                    typeOfSource: "",
                    purchased: totalPurchased,
                    purpose: "",
                    selfProduced: totalSelfProduced,
                    total: grandTotal
                });

                const fuelTypes = [
                    "Fully Synthetic Fuel",
                    "Bio Fuels",
                    "Hydrogen Fuel",
                    "Other Renewable Fuels",
                    "Heavy Liquid Fuel",
                    "Natural Gas (NG)",
                    "Liquefied Petroleum Gas (LPG)",
                    "Vehicular Fuel",
                    "Coal",
                    "Other Non-renewable Fuels",
                    "Light Liquid Fuel"
                ];
                let cumulativeRenewable = 0;
                let cumulativeNonRenewable = 0;
                let cumulativeTotal = 0;

                fuelTypes.forEach(fuelType => {
                    let totalRenewable = 0;
                    let totalNonRenewable = 0;
                    let total = 0;
                    let found = false;

                    resp.data.fuelConsumptionData.facilityWiseData.forEach(facility => {
                        facility.dataBreakDown.forEach(fuelData => {
                            if (fuelData.fuelType === fuelType) {
                                totalRenewable += fuelData.renewable;
                                totalNonRenewable += fuelData.nonRenewable;
                                total += fuelData.total;
                                found = true;
                            }
                        });
                    });
                    const fuelDataObject = {
                        fuelType: fuelType,
                        renewable: found ? totalRenewable : 0,
                        nonRenewable: found ? totalNonRenewable : 0,
                        total: found ? total : 0
                    };

                    cumulativeRenewable += totalRenewable;
                    cumulativeNonRenewable += totalNonRenewable;
                    cumulativeTotal += total;

                    fuelDataArray.push(fuelDataObject);
                });
                fuelDataArray.push({
                    fuelType: "Total",
                    renewable: cumulativeRenewable,
                    nonRenewable: cumulativeNonRenewable,
                    total: cumulativeTotal
                });

                resp.data.consumptionDerivedData.forEach(facility => {
                    facility.breakdown.forEach(breakdown => {
                        let breakdownObject;

                        if (breakdown.type === "Electricity") {
                            breakdownObject = {
                                facilityName: facility.facilityName,
                                type: breakdown.type,
                                renewable: breakdown.renewable,
                                nonRenewable: breakdown.nonRenewable,
                                total: breakdown.total
                            };
                        } else {
                            breakdownObject = {
                                facilityName: facility.facilityName,
                                type: breakdown.type,
                                renewable: 0,
                                nonRenewable: 0,
                                total: 0
                            };
                        }
                        derivedDataArray.push(breakdownObject);
                    });
                });
                let totalRenewable = 0;
                let totalNonRenewable = 0;
                let totalTotal = 0;
                // Calculate totals
                derivedDataArray.forEach(data => {
                    totalRenewable += data.renewable;
                    totalNonRenewable += data.nonRenewable;
                    totalTotal += data.total;
                });
                // Add totals to the array
                derivedDataArray.push({
                    facilityName: "",
                    type: "Total",
                    renewable: totalRenewable,
                    nonRenewable: totalNonRenewable,
                    total: totalTotal
                });
                console.log(derivedDataArray);

                const energyTableData = [
                    { columnName: tableHeaderContent[5].energyConsumptionWithinOrgElectricityTableColumns, tableData: array, tableName: "Energy consumption within Organization Electricity" },
                    { columnName: tableHeaderContent[20].energyConsumptionFuelTableColumns, tableData: fuelDataArray, tableName: "Energy consumption within Organization Fuel" },
                    { columnName: tableHeaderContent[21].energyConsumptionDerivedTableColumns, tableData: derivedDataArray, tableName: "Waste disposal in Metric Tons" }
                ];
                setEnergyConsumptionTableData(energyTableData);


                resp.data.electricityConsumptionData.facilityWiseData.forEach(facility => {
                    totalRenewableElectricity += facility["totalRenewable(kwh)"];
                    totalNonRenewableElectricity += facility["totalNonRenewable(kwh)"];
                });
                // Create the chart data structure
                const electricityChartData = [
                    ["Electricity", "Amount"],
                    ["Renewable", totalRenewableElectricity],
                    ["Non-renewable", totalNonRenewableElectricity]
                ];
                setElectricityPieChartData(electricityChartData);

                setColumnChartLoading(false);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    };

    const getEnergyConsumptionOutsideData = (facilities, year) => {
        setIsBarsLoader(true);
        const categoriesList = [
            "Purchased Goods and Services",
            "Capital Goods",
            "Fuel and Energy Related Activities",
            "Upstream Transportation and Distribution",
            "Waste Generated in Operations",
            "Business Travel Explore",
            "Employee Commuting",
            "Upstream Leased Assets",
            "Other Upstream",
            "Downstream Transportation and Distribution",
            "Processing of Sold Products",
            "Use of Sold Products",
            "End of Life Treatment of Sold Products",
            "Downstream Leased Assets",
            "Franchises",
            "Investments",
            "Other Downstream"
        ];
        const categoryTotals = {};
        categoriesList.forEach(category => {
            categoryTotals[category] = 0;
        });

        let obj = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityList: facilities?.length > 0 ? facilities.join(',') : null,
            // YearList: "2023"
            YearList: year
        }
        getEnergyConsumptionOutsideReportData(obj)
            .then((resp) => {
                if (resp.data.facilityDetails) {
                    resp.data.facilityDetails.forEach(facility => {
                        facility.parameters.forEach(parameter => {
                            parameter.categories.forEach(category => {
                                const categoryName = category.category;
                                const totalAmount = category.totalAmount;
                                if (categoryTotals[categoryName] !== undefined) {
                                    categoryTotals[categoryName] += totalAmount;
                                } else {
                                    categoryTotals[categoryName] = totalAmount;
                                }
                            });
                        });
                    });

                    const result = [["category", "totalAmount"]];
                    categoriesList.forEach(category => {
                        result.push([category, categoryTotals[category]]);
                    });

                    setEnergyConsumptionOutsideChartData(result);
                }
                const upstreamCategories = [
                    "Purchased Goods and Services",
                    "Capital Goods",
                    "Fuel and Energy Related Activities",
                    "Upstream Transportation and Distribution",
                    "Waste Generated in Operations",
                    "Business Travel Explore",
                    "Employee Commuting",
                    "Upstream Leased Assets",
                    "Other Upstream"
                ];

                const downstreamCategories = [
                    "Downstream Transportation and Distribution",
                    "Processing of Sold Products",
                    "Use of Sold Products",
                    "End of Life Treatment of Sold Products",
                    "Downstream Leased Assets",
                    "Franchises",
                    "Investments",
                    "Other Downstream"
                ];

                const tableData = [];
                upstreamCategories.forEach(categoryName => {
                    tableData.push({
                        parameter: "Energy consumption in upstream categories",
                        category: categoryName,
                        amount: categoryTotals[categoryName]
                    });
                });
                downstreamCategories.forEach(categoryName => {
                    tableData.push({
                        parameter: "Energy consumption in downstream categories",
                        category: categoryName,
                        amount: categoryTotals[categoryName]
                    });
                });
                const totalAmount = Object.values(categoryTotals).reduce((acc, curr) => acc + curr, 0);
                tableData.push({
                    parameter: "Total",
                    category: "",
                    amount: totalAmount
                });
                setEnergyConsumptionOutsideTableData(tableData);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    }

    const getFilterData = (filterData) => {
        getAllEnergyReportData(filterData.facilityRefNo, filterData.year);
        getEnergyConsumptionOutsideData(filterData.facilityRefNo, filterData.year);
    };

    const refreshData = () => {
        const facilities = userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? null : [userDetails.facilityRefNo];
        getAllEnergyReportData(facilities, yearData.$y);
        getEnergyConsumptionOutsideData(facilities, yearData.$y);
        localStorage.setItem("year", yearData.$y);
    };

    const handleMenu = () => {
        setOpenFilterDialog({ action: true, userData: userDetails });
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                {isLoading ? (
                    <Loader />
                ) : (
                    <material.Box component="main" sx={{ flexGrow: 1, p: 1, mt: 7 }}>
                        <div className="container-fluid">
                            <div className="row p-2">
                                <div className="col-lg-8 col-md-5">
                                    <span><material.Typography variant="h5" className='text-capitalize'>Energy</material.Typography></span>
                                </div>
                                <div className="col-lg-4 col-md-3 mt-3">
                                    <div className='float-end'>
                                        <span>
                                            <material.IconButton
                                                aria-label="more"
                                                id="long-button"
                                                onClick={handleMenu}
                                                title='Filter'
                                                color='info'
                                            >
                                                <img
                                                    src={FilterImage}
                                                    height={25}
                                                    width={25}
                                                />
                                            </material.IconButton>
                                        </span>
                                        <span>
                                            <material.IconButton title='Refresh' color='success' onClick={refreshData}>
                                                <material.RefreshIcon />
                                            </material.IconButton>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div>
                                    <material.Card elevation={3} sx={{ mt: 1 }} className='column-chart'>                                        <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                        <span>
                                            <MoreOptionMenu
                                                chartName="column-chart"
                                                options={energyConsumptionFromRenewableNonRenewableSourceOptions}
                                                columnChartData={energyConsumptionChartData}
                                                tableData={energyConsumptionTableData}
                                                year={year}
                                                callFrom="multiple-table"
                                            />
                                        </span>
                                    </div>
                                        {columnChartLoading ? (
                                            <BarsLoader />
                                        ) : (
                                            <ColumnChart
                                                options={energyConsumptionFromRenewableNonRenewableSourceOptions}
                                                columnChartData={energyConsumptionChartData}
                                            />
                                        )}
                                    </material.Card>
                                </div>
                                <div className="col-lg-6">
                                    <material.Card elevation={3} sx={{ mt: 1 }} className='column-chart'>
                                        <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                            <span>
                                                <MoreOptionMenu
                                                    chartName="column-chart"
                                                    options={energyConsumptionOutsideOptions}
                                                    columnChartData={energyConsumptionOutsideChartData}
                                                    tableData={energyConsumptionOutsideTableData}
                                                    year={year}
                                                    columnName={tableHeaderContent[19].energyConsumptionOutsideTableColumns}
                                                    tableHeading="Energy Consumption Outside"
                                                />
                                            </span>
                                        </div>
                                        <ColumnChart
                                            options={energyConsumptionOutsideOptions}
                                            columnChartData={energyConsumptionOutsideChartData}
                                        />
                                    </material.Card>
                                    {/* )} */}
                                </div>
                                <div className="col-lg-6">
                                    <material.Card elevation={3} sx={{ mt: 2 }} className='column-chart'>
                                        <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                            <span>
                                                <MoreOptionMenu
                                                    chartName="pie-chart"
                                                    options={energyConsumptionWithinOrganizationElectricityGJOptions}
                                                    pieChartData={electricityPieChartData}
                                                    tableData={energyConsumptionTableData}
                                                    year={year}
                                                    callFrom="multiple-table"

                                                />
                                            </span>
                                        </div>
                                        <PieChart
                                            options={energyConsumptionWithinOrganizationElectricityGJOptions}
                                            pieChartData={electricityPieChartData}
                                        />
                                    </material.Card>
                                </div>
                            </div>
                        </div>
                    </material.Box>
                )}
            </material.Box>
            <GlobalFilter
                openFilterDialog={openFilterDialog}
                setOpenFilterDialog={setOpenFilterDialog}
                getFilterData={getFilterData}
                callFrom="issue-dashboard"
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default EnergyDashboard;