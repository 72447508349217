import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import moment from 'moment';
import AddGhgScope3 from '../../../sub-issues-dialogs/climate-change-dialog/AddGhgScope3';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import Snackbar from '../../../toaster-message/Snackbar';
import { approveGhgScope, getGhgScopeData, sendForApprovalGhgScope } from '../../../services/sub-issue-services/ghg-scope-common-services/GhgScopeCommonService';
import { getDataFilterByCurrentApprovedDataAndNotApprovedDate, getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';
import { getSubIssueRecordData, saveSubIssueData, updateSubIssueData } from '../../../services/sub-issue-services/AddSubissueService';
import Pagination from '../../../shared/pagination/Pagination';
import ApplicableDialog from '../../../dialogs/ApplicableDialog';


var recordRefNo = [];
var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };

function GhgScope3(props) {
    const location = useLocation();
    const treeViewData = location?.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };

    const [applicable, setApplicable] = useState(true);
    const [scope3TableData, setScope3TableData] = useState([]);
    const [addOpenClimateChangeScope3Ghg, setAddOpenClimateChangeScope3Ghg] = useState({ action: false, scope3TableData: null });
    const [climateChangeScope3GhgEmissionTabs, setClimateChangeScope3GhgEmissionTabs] = useState('GHG Scope 3');
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = React.useState(0);
    const [getFilterDate, setGetFilterDate] = useState(null);

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        getGhgScope3AllData()
    }, [climateChangeScope3GhgEmissionTabs]);

    const getGhgScope3AllData = async (filterDate, paginationValue) => {
        if (climateChangeScope3GhgEmissionTabs === "GHG Scope 3") {
            let forApproval = userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? true : null;
            let approveHistory = false;
            let typeOfRecord = "GHG_SCOPE3"
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                typeOfRecord,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                subissueName: treeViewData.subIssue,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            await getSubIssueRecordData(payloadData)
                .then((resp) => {
                    setScope3TableData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setApplicableRefNo(resp.data.applicableRecord?.ref_no ? resp.data.applicableRecord.ref_no : null);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else if (climateChangeScope3GhgEmissionTabs === "GHG Scope 3 History") {
            let forApproval = true;
            let approveHistory = true;
            let typeOfRecord = "GHG_SCOPE3"
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                typeOfRecord,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                subissueName: treeViewData.subIssue,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            await getSubIssueRecordData(payloadData)
                .then((resp) => {
                    setScope3TableData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    const addClimateChangeScope3GhgEmission = () => {
        setAddOpenClimateChangeScope3Ghg({ action: true, scope3TableData: null });
    };

    const editClimateChangeScope3GhgEmission = (rowData) => {
        setAddOpenClimateChangeScope3Ghg({ action: true, scope3TableData: rowData });
    };

    const clickCheckBox = (value) => {
        setAddOpenClimateChangeScope3Ghg({ action: false, scope3TableData: null });
        if (recordRefNo.includes(value)) {
            // recordRefNo.pop(value);
            recordRefNo = recordRefNo.filter(item => item !== value);
        } else {
            recordRefNo.push(value)
        };
    };

    const sendForApprovalScope3 = async () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true
            }
            await updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    recordRefNo = [];
                    getGhgScope3AllData(getFilterDate, paginationData);
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    const approveScope3 = async () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true,
                "approved_by": userDetails.username,
                "approved_date": moment().format("YYYY-MM-DD"),
                "approved_status": "APPROVED",
                "rejection_note": "",
                "type_of_record": "GHG_SCOPE3"
            }
            await updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    recordRefNo = [];
                    getGhgScope3AllData(getFilterDate, paginationData);
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    const rejectScope3 = async () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        };
    };

    const getRejectionNoteData = async (noteData) => {
        const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
        const subissueName = treeViewData.subIssue;
        const payload = {
            send_for_approval: false,
            "rejection_note": noteData.rejectionNote,
            "approved_status": "",
            "type_of_record": "GHG_SCOPE3"
        }
        await updateSubIssueData(payload, refNo, subissueName)
            .then((resp) => {
                recordRefNo = [];
                getGhgScope3AllData(getFilterDate, paginationData);
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    const handleChangeTabs = (event, newValue) => {
        setClimateChangeScope3GhgEmissionTabs(newValue);
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const getFormDateToDate = (data) => {
        setGetFilterDate(data);
        getGhgScope3AllData(data, paginationData);
    };

    const getApplicableData = () => {
        if (applicableRefNo === null) {
            let subIssueName = treeViewData?.subIssue;
            const payload = {
                applicable: false,
                applicable_record: true,
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
            }
            saveSubIssueData(payload, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getGhgScope3AllData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else {
            let subIssueName = treeViewData?.subIssue;
            let refNo = applicableRefNo;
            const payload = {
                applicable: applicable ? false : true,
            }
            updateSubIssueData(payload, refNo, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getGhgScope3AllData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const getPaginationData = (filterData, paginationData) => {
        setRowsPerPage(paginationData.size);
        setPage(paginationData.page);
        getGhgScope3AllData(filterData, paginationData);
    };

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 2, mt: 7 }}>
                    <div className="row">
                        <div className="col-6">
                            <span><material.Typography variant="h5" className='text-capitalize'>{treeViewData?.subIssue}</material.Typography></span>
                        </div>
                        <div className="col-6">
                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") || userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? null : (
                                <span className="float-end">
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} onChange={applicableNotApplicable}
                                            checked={applicable}
                                            color="secondary" />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>
                    <material.Box sx={{ width: '100%', typography: 'body1' }}>
                        <material.TabContext value={climateChangeScope3GhgEmissionTabs}>
                            <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <material.TabList onChange={handleChangeTabs} aria-label="lab API tabs example">
                                    <material.Tab className='text-capitalize' label="GHG Scope 3" value="GHG Scope 3" />
                                    <material.Tab className='text-capitalize' label="GHG Scope 3 History" value="GHG Scope 3 History" />
                                </material.TabList>
                            </material.Box>
                            <material.TabPanel value="GHG Scope 3">
                                <div className="row">
                                    <div className="col-6">
                                        {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        )}
                                    </div>
                                    <div className="col-6" hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                        <span className='float-end'>
                                            {scope3TableData?.length ? (
                                                <div>
                                                    <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")} color='success'
                                                        disabled={!applicable || !recordRefNo.length}
                                                        sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approveScope3}>Approve</material.Button>
                                                    <material.Button sx={{ textTransform: "none" }} hidden={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")}
                                                        disabled={!applicable || !recordRefNo.length}
                                                        variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectScope3}>Reject</material.Button>
                                                </div>
                                            ) : null}
                                            <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_APPROVER")} color='success'
                                                disabled={!applicable}
                                                sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendForApprovalScope3}>Send for Approval</material.Button>
                                            <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_APPROVER")} color='success'
                                                disabled={!applicable}
                                                sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addClimateChangeScope3GhgEmission}>Add GHG Scope 3 Data</material.Button>
                                        </span>
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                                    <material.TableContainer>
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow >
                                                    <StyledTableCell>Type of Source</StyledTableCell>
                                                    <StyledTableCell>Activity</StyledTableCell>
                                                    <StyledTableCell>Quantity</StyledTableCell>
                                                    <StyledTableCell>Unit CO2</StyledTableCell>
                                                    <StyledTableCell>From Date</StyledTableCell>
                                                    <StyledTableCell>To Date</StyledTableCell>
                                                    <StyledTableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>{userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                    {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                        <StyledTableCell>Rejection Note</StyledTableCell>
                                                    )}
                                                </material.TableRow>
                                            </material.TableHead>

                                            <material.TableBody>
                                                {scope3TableData?.length ? scope3TableData.map((item, ind) => (
                                                    <material.TableRow key={ind}
                                                        sx={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                        onClick={((userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")) && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true) ?
                                                            () => editClimateChangeScope3GhgEmission(item) : null}
                                                    >
                                                        <material.TableCell>{item.type_of_source}</material.TableCell>
                                                        <material.TableCell>{item.activity}</material.TableCell>
                                                        <material.TableCell>{item.amount}</material.TableCell>
                                                        <material.TableCell>{item.unit_co2}</material.TableCell>
                                                        <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                            {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                <material.Checkbox {...label} disabled={!applicable || item.send_for_approval} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                            ) : (
                                                                <material.Checkbox {...label} disabled={!applicable || item.approved_status === "APPROVED"} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                            )}
                                                        </material.TableCell>
                                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                            <material.TableCell>{(item.rejection_note === null) || (item.rejection_note === "") ? "No" : (
                                                                <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                            )}
                                                            </material.TableCell>
                                                        )}
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={9}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                    <Pagination
                                        getPaginationData={getPaginationData}
                                        totalElements={totalElements}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        getFilterDate={getFilterDate}
                                    />
                                </material.Paper>
                            </material.TabPanel>
                            <material.TabPanel value="GHG Scope 3 History">
                                <div className="row">
                                    <div className="col-8">
                                        <DateFilter
                                            getFormDateToDate={getFormDateToDate}
                                        />
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                                    <material.TableContainer>
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow >
                                                    <StyledTableCell>Type of Source</StyledTableCell>
                                                    <StyledTableCell>Activity</StyledTableCell>
                                                    <StyledTableCell>Quantity</StyledTableCell>
                                                    <StyledTableCell>Unit CO2</StyledTableCell>
                                                    <StyledTableCell>From Date</StyledTableCell>
                                                    <StyledTableCell>To Date</StyledTableCell>
                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                    <StyledTableCell>Status</StyledTableCell>
                                                </material.TableRow>
                                            </material.TableHead>
                                            <material.TableBody>
                                                {scope3TableData?.length ? scope3TableData.map((item, ind) => (
                                                    <material.TableRow key={ind}>
                                                        <material.TableCell>{item.type_of_source}</material.TableCell>
                                                        <material.TableCell>{item.activity}</material.TableCell>
                                                        <material.TableCell>{item.amount}</material.TableCell>
                                                        <material.TableCell>{item.unit_co2}</material.TableCell>
                                                        <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>
                                                            {item.approved_status === "APPROVED" ? <span className="badge bg-success">
                                                                APPROVED
                                                            </span> : null}
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )) :
                                                    (
                                                        <material.TableRow >
                                                            <material.TableCell colSpan={9}>
                                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                            </material.TableCell>
                                                        </material.TableRow>
                                                    )
                                                }
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                    <Pagination
                                        getPaginationData={getPaginationData}
                                        totalElements={totalElements}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        getFilterDate={getFilterDate}
                                    />
                                </material.Paper>
                            </material.TabPanel>
                        </material.TabContext>
                    </material.Box>
                </material.Box>
            </material.Box>
            <AddGhgScope3
                getGhgScope3AllData={getGhgScope3AllData}
                addOpenClimateChangeScope3Ghg={addOpenClimateChangeScope3Ghg}
                setAddOpenClimateChangeScope3Ghg={setAddOpenClimateChangeScope3Ghg}
                treeViewData={treeViewData}
                page={page}
                rowsPerPage={rowsPerPage}
                getFilterDate={getFilterDate}
            />
            <ApplicableDialog
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getApplicableData={getApplicableData}
                applicable={applicable}
            />
            {/* <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getGhgScope3AllData={getGhgScope3AllData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            /> */}
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default GhgScope3;