import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import DatePeriod from '../../shared/DatePeriod';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';

function AddWorkersCoveredByOHS(props) {
    const { openAddWorkerCoveredByOHS, setOpenAddWorkerCoveredByOHS, getAllWorkerCoveredByOHS, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [category, setCategory] = useState("");
    const [gender, setGender] = useState("");

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openAddWorkerCoveredByOHS.action && openAddWorkerCoveredByOHS.workerCoveredByOHSTableData) {
            setCategory(openAddWorkerCoveredByOHS.workerCoveredByOHSTableData.category);
            setGender(openAddWorkerCoveredByOHS.workerCoveredByOHSTableData.gender);
            reset(openAddWorkerCoveredByOHS.workerCoveredByOHSTableData)
        }
    }, [openAddWorkerCoveredByOHS.action === true]);

    const selectCategory = (event) => {
        setCategory(event.target.value);
    };

    const selectGender = (event) => {
        setGender(event.target.value);
    };

    const handleClose = () => {
        reset({ total_number_of_employee_and_worker: "", total_num_of_emp_worker_coverd_by_ohs: "", });
        setCategory("");
        setGender("");
        setOpenAddWorkerCoveredByOHS({ action: false, workerCoveredByOHSTableData: null });
    };

    const saveWorkerCoveredByOHS = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue);
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "category": data.category,
                "gender": data.gender,
                "total_number_of_employee_and_worker": data.total_number_of_employee_and_worker,
                "total_num_of_emp_worker_coverd_by_ohs": data.total_num_of_emp_worker_coverd_by_ohs,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllWorkerCoveredByOHS(getFilterDate, payloadData);
                    reset({ total_number_of_employee_and_worker: "", total_num_of_emp_worker_coverd_by_ohs: "", });
                    setCategory("");
                    setGender("");
                    setOpenAddWorkerCoveredByOHS({ action: false, workerCoveredByOHSTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateWorkerCoveredByOHS = async (data) => {
        const refNo = openAddWorkerCoveredByOHS.workerCoveredByOHSTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "category": data.category,
            "gender": data.gender,
            "total_number_of_employee_and_worker": data.total_number_of_employee_and_worker,
            "total_num_of_emp_worker_coverd_by_ohs": data.total_num_of_emp_worker_coverd_by_ohs,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllWorkerCoveredByOHS(getFilterDate, payloadData);
                reset({ total_number_of_employee_and_worker: "", total_num_of_emp_worker_coverd_by_ohs: "", });
                setCategory("");
                setGender("");
                setOpenAddWorkerCoveredByOHS({ action: false, workerCoveredByOHSTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        const payload = {
            refNo: openAddWorkerCoveredByOHS.workerCoveredByOHSTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ total_number_of_employee_and_worker: "", total_num_of_emp_worker_coverd_by_ohs: "", });
                setCategory("");
                setGender("");
                getAllWorkerCoveredByOHS(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddWorkerCoveredByOHS({ action: false, workerCoveredByOHSTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };


    return (
        <div>
            <material.Dialog open={openAddWorkerCoveredByOHS.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddWorkerCoveredByOHS.workerCoveredByOHSTableData ? "Edit Worker Covered by OHS" : "Add Worker Covered by OHS"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddWorkerCoveredByOHS.workerCoveredByOHSTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Worker Covered by OHS</material.Typography>
                            </div>
                            <div className='col-6'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Category</material.InputLabel>
                                    <material.Select
                                        {...register("category", { required: true, })}
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={category}
                                        onChange={selectCategory}
                                        label="Unit CFC"
                                    >
                                        <material.MenuItem value={"Employee Permanent"}>Employee Permanent</material.MenuItem>
                                        <material.MenuItem value={"Employee other than Permanent"}>Employee other than Permanent</material.MenuItem>
                                        <material.MenuItem value={"Worker Permanent"}>Worker Permanent</material.MenuItem>
                                        <material.MenuItem value={"Worker other than Permanent"}>Worker other than Permanent</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Gender</material.InputLabel>
                                    <material.Select
                                        {...register("gender", { required: true, })}
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={gender}
                                        onChange={selectGender}
                                        label="Unit CFC"
                                    >
                                        <material.MenuItem value={"Female"}>Female</material.MenuItem>
                                        <material.MenuItem value={"Male"}>Male</material.MenuItem>
                                        <material.MenuItem value={"Others"}>Others</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 mt-2'>
                                <material.TextField
                                    {...register("total_number_of_employee_and_worker", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Total Number of Employee / Worker"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.total_number_of_employee_and_worker && errors.total_number_of_employee_and_worker.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                            <div className='col-6 mt-2'>
                                <material.TextField
                                    {...register("total_num_of_emp_worker_coverd_by_ohs", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Total Number of Employee/Worker Covered by OHS"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.total_num_of_emp_worker_coverd_by_ohs && errors.total_num_of_emp_worker_coverd_by_ohs.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className="m-3">
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddWorkerCoveredByOHS.workerCoveredByOHSTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateWorkerCoveredByOHS)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddWorkerCoveredByOHS.workerCoveredByOHSTableData.rejection_note === null) || (openAddWorkerCoveredByOHS.workerCoveredByOHSTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveWorkerCoveredByOHS)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddWorkersCoveredByOHS;