import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateViolationAndExceedanceData } from '../../services/sub-issue-services/violation-exceedance-service/ViolationExceedanceService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddViolationExceedance(props) {
    const { addOpenViolationExceedance, setAddOpenViolationExceedance, getAllViolationExceedanceData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, watch, getValues, setValue, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [violationAndExceedanceAspect, setViolationAndExceedanceAspect] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    const onChangeNoOfDisputes = watch('no_of_disputes');
    // const onChangeDisputePendingCases = watch('dispute_pending_cases');
    const onChangeDisputeResolutionCase = watch('dispute_resolution_case');

    useEffect(() => {
        totalCalculation();
    }, [onChangeNoOfDisputes, onChangeDisputeResolutionCase]);

    const totalCalculation = () => {
        let noOfDisputesValue = getValues('no_of_disputes');
        let disputeResolutionCaseValue = getValues('dispute_resolution_case');

        if (Number(disputeResolutionCaseValue) > Number(noOfDisputesValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Number of disputes resolved value not greater than number of disputes value",
            })
            setButtonDisable(true)
        } else {
            let val = Number(noOfDisputesValue) - Number(disputeResolutionCaseValue)
            setValue('dispute_pending_cases', val)
            setButtonDisable(false)
        }
    };

    const handleViolationExceedanceAspectChange = (event) => {
        setViolationAndExceedanceAspect(event.target.value);
    };

    useEffect(() => {
        if (addOpenViolationExceedance.action && addOpenViolationExceedance.violationExceedanceTableData) {
            setViolationAndExceedanceAspect(addOpenViolationExceedance.violationExceedanceTableData.violation_and_exceedance_aspect);
            reset(addOpenViolationExceedance.violationExceedanceTableData);
        }
    }, [addOpenViolationExceedance.action === true]);

    const handleClose = () => {
        reset({ no_of_disputes: "", describe_the_violation_and_exceedance_issues: "", dispute_pending_cases: "", dispute_resolution_case: "", other_specify: "", });
        setViolationAndExceedanceAspect("");
        setAddOpenViolationExceedance({ action: false, violationExceedanceTableData: null });
    };

    const saveViolationExceedance = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "violation_and_exceedance_aspect": data.violation_and_exceedance_aspect,
                "other_specify": violationAndExceedanceAspect === "Other (Specify)" ? data.other_specify : "",
                "no_of_disputes": Number(data.no_of_disputes),
                "describe_the_violation_and_exceedance_issues": data.describe_the_violation_and_exceedance_issues,
                "dispute_resolution_case": Number(data.dispute_resolution_case),
                "dispute_pending_cases": Number(data.dispute_pending_cases),
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(object, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllViolationExceedanceData(getFilterDate, payloadData);
                    reset({ no_of_disputes: "", describe_the_violation_and_exceedance_issues: "", dispute_pending_cases: "", dispute_resolution_case: "", other_specify: "" });
                    setViolationAndExceedanceAspect("");
                    setAddOpenViolationExceedance({ action: false, violationExceedanceTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateViolationExceedance = async (data) => {
        const refNo = addOpenViolationExceedance.violationExceedanceTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let object = {
            "violation_and_exceedance_aspect": data.violation_and_exceedance_aspect,
            "other_specify": violationAndExceedanceAspect === "Other (Specify)" ? data.other_specify : "",
            "no_of_disputes": Number(data.no_of_disputes),
            "describe_the_violation_and_exceedance_issues": data.describe_the_violation_and_exceedance_issues,
            "dispute_resolution_case": Number(data.dispute_resolution_case),
            "dispute_pending_cases": Number(data.dispute_pending_cases),
        }
        await updateSubIssueData(object, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllViolationExceedanceData(getFilterDate, payloadData);
                reset({ no_of_disputes: "", describe_the_violation_and_exceedance_issues: "", dispute_pending_cases: "", dispute_resolution_case: "", other_specify: "" });
                setViolationAndExceedanceAspect("");
                setAddOpenViolationExceedance({ action: false, violationExceedanceTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: addOpenViolationExceedance.violationExceedanceTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                getAllViolationExceedanceData(getFilterDate, payloadData);
                reset({ no_of_disputes: "", describe_the_violation_and_exceedance_issues: "", dispute_pending_cases: "", dispute_resolution_case: "", other_specify: "" });
                setViolationAndExceedanceAspect("");
                setAddOpenViolationExceedance({ action: false, violationExceedanceTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={addOpenViolationExceedance.action} maxWidth="lg" fullWidth>
                <material.DialogTitle id="alert-dialog-title" className='fw-bold headingText'>
                    {addOpenViolationExceedance.violationExceedanceTableData ? "Edit Violation & Exceedance" : "Add Violation & Exceedance"}
                </material.DialogTitle>
                <material.DialogContent>
                    {addOpenViolationExceedance.violationExceedanceTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Violation & Exceedance</material.Typography>
                            </div>
                            <div className='col-6'>
                                <material.FormControl required variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Violation & Exceedance Aspect</material.InputLabel>
                                    <material.Select
                                        {...register("violation_and_exceedance_aspect", { required: true, })}
                                        require
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={violationAndExceedanceAspect}
                                        onChange={handleViolationExceedanceAspectChange}
                                        label="Type of source"
                                    >
                                        <material.MenuItem value={"Regulatory Clearance & Permits"}>Regulatory Clearance & Permits</material.MenuItem>
                                        <material.MenuItem value={"Permit Conditions"}>Permit Conditions</material.MenuItem>
                                        <material.MenuItem value={"Court Cases"}>Court Cases</material.MenuItem>
                                        <material.MenuItem value={"Stakeholder Complaints"}>Stakeholder Complaints</material.MenuItem>
                                        <material.MenuItem value="Other (Specify)">Other (Specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <>
                                {violationAndExceedanceAspect === "Other (Specify)" ? (
                                    <div className='col-6'>
                                        <material.TextField
                                            {...register("other_specify", { required: true, maxLength: 20 })}
                                            required
                                            margin="dense"
                                            label="Other (Specify)"
                                            id="userName"
                                            variant="standard"
                                            type='text'
                                            size="small"
                                            fullWidth
                                        />
                                        <p className='form-text text-danger'>{errors.other_specify?.type === "required" && 'This field is required'}</p>
                                        {errors?.other_specify?.type === "maxLength" && (
                                            <p className='text-danger'>Maximum length 20 character</p>
                                        )}
                                    </div>
                                ) : null}
                            </>
                            <div className='col-6'>
                                <material.TextField
                                    {...register("no_of_disputes", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Number of Disputes"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.no_of_disputes?.type === "required" && 'This field is required'}</p>
                                {errors.no_of_disputes && errors.no_of_disputes.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-12'>
                                <material.TextField
                                    {...register("describe_the_violation_and_exceedance_issues", { required: true, minLength: 20, maxLength: 100 })}
                                    required
                                    margin="dense"
                                    label="Describe Violation & Exceedance Issues"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={3}

                                />
                                <p className='form-text text-danger'>{errors.describe_the_violation_and_exceedance_issues?.type === "required" && 'This field is required'}</p>
                                {errors.describe_the_violation_and_exceedance_issues && (errors.describe_the_violation_and_exceedance_issues.type === "minLength" || errors.describe_the_violation_and_exceedance_issues.type === "maxLength") && (
                                    <p className="errorMsg text-danger">Describe The Violation & Exceedance Issues should be between 20 to 100 character.</p>
                                )}
                            </div>
                            <div className='col-6'>
                                <material.TextField
                                    {...register("dispute_resolution_case", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Number of Disputes Resolved"
                                    id="userName"
                                    defaultValue={0}
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.dispute_resolution_case?.type === "required" && 'This field is required'}</p>
                                {errors.dispute_resolution_case && errors.dispute_resolution_case.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-6'>
                                <material.TextField
                                    {...register("dispute_pending_cases", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Dispute Pending Cases"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    defaultValue={0}
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    InputLabelProps={{ shrink: true }}
                                />
                                {errors.dispute_pending_cases && errors.dispute_pending_cases.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenViolationExceedance.violationExceedanceTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} disabled={buttonDisable} onClick={handleSubmit(updateViolationExceedance)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenViolationExceedance.violationExceedanceTableData.rejection_note === null) || (addOpenViolationExceedance.violationExceedanceTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} disabled={buttonDisable} onClick={handleSubmit(saveViolationExceedance)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddViolationExceedance;