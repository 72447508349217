import { rootApiUrl } from "../environment/Environment";
import { axiosInstance } from "../shared/AxiosConfig";

export const documentUpload = async (file, payload) => {
    return await axiosInstance.post(`${rootApiUrl}/api/file/upload`, file, { params: { ...payload } })
};

export const getDocumentList = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/file/list`, { params: { ...payload } })
};

export const downloadDocument = async (rowData) => {
    return await axiosInstance.get(`${rootApiUrl}/api/file/download`, {
        params: { refNo: rowData.refNo },
        responseType: "blob"
    })
};

export const deleteDocumentByRefNo = async (refNo) => {
    return await axiosInstance.delete(`${rootApiUrl}/api/file/delete`, { params: { refNo } })
};

export const getAttachedDocumentList = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/file/get-answer-files`, { params: { ...payload } })
};