import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { commonDelete, getPollutantParametersList, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';



const physicalPollutant = ["Colour", "Odour", "Suspended solids", "Temperature"];
const chemicalPollutant = ["Chemical Oxygen Demand", "Biochemical Oxygen demand"];
const metalsPollutant = ["Arsenic", "Mercury", "Lead", "Cadmium"];
const aromaticsPollutant = ["Phenolic Compounds", "Chloroamines", "Polychlorinated Biphenyls", "Polynuclear Aromatic Hydrocarbons", "Alachlor", "Atrazine", "Beta HCH", "Butachlor", "Chlorpyriphos"];
const bacteriologicalPollutant = ["Coliform Bacteria", "Escherichiacoli"];


function AddWaterDischargeNew(props) {
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, watch, control, setValue, getValues, formState: { errors }, } = useForm({ mode: "onTouched" });
    const { openWaterDischarge, setOpenWaterDischarge, getAllWaterDischarge, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const [pollutantSuite, setPollutantSuite] = useState("");
    const [pollutantParameter, setPollutantParameter] = useState("");
    const [pollutantParameterValue, setPollutantParameterValue] = useState([]);
    const [qualityUnit, setQualityUnit] = useState("");
    const [receptorQualityUnit, setReceptorQualityUnit] = useState("");
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [pollutantSuiteAndParameter, setPollutantSuiteAndParameter] = useState([]);
    const [selectedSuite, setSelectedSuite] = useState("");
    const [selectedParameter, setSelectedParameter] = useState('');

    const payload = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        getPollutantParameters();
    }, [openWaterDischarge.action === true]);

    const selectPollutantSuite = (event) => {
        let pollutantSuiteChangeData = event.target.value;
        setPollutantSuite(pollutantSuiteChangeData);
        if (pollutantSuiteChangeData === "Physical") {
            setPollutantParameterValue(physicalPollutant);
        } else if (pollutantSuiteChangeData === "Chemical") {
            setPollutantParameterValue(chemicalPollutant);
        } else if (pollutantSuiteChangeData === "Metals") {
            setPollutantParameterValue(metalsPollutant);
        } else if (pollutantSuiteChangeData === "Aromatics") {
            setPollutantParameterValue(aromaticsPollutant);
        } else if (pollutantSuiteChangeData === "Bacteriological") {
            setPollutantParameterValue(bacteriologicalPollutant);
        }
        else {
            setPollutantParameterValue([]);
        };
    };

    const getPollutantParameters = async () => {
        await getPollutantParametersList()
            .then((resp) => {
                setPollutantSuiteAndParameter(resp.data);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const uniqueSuites = [...new Set(pollutantSuiteAndParameter.map(item => item.pollutantSuite))];
    const filteredParameters = pollutantSuiteAndParameter.filter(item => item.pollutantSuite === selectedSuite);

    const pollutantParameterChange = (event) => {
        setPollutantParameter(event.target.value);
    };

    const selectQualityUnit = (event) => {
        setQualityUnit(event.target.value);
    };

    const selectReceptorQualityPollutantSuite = (event) => {
        setSelectedSuite(event.target.value);
    };

    const receptorQualityParameterChange = (event) => {
        setSelectedParameter(event.target.value);
    };

    const selectReceptorQualityUnit = (event) => {
        setReceptorQualityUnit(event.target.value);
    };

    const dischargePoint = (event) => {
        const value = event.target.value;
        setValue("discharge_point", value);
    };

    useEffect(() => {
        if (openWaterDischarge.action && openWaterDischarge.waterDischargeTableData) {

            setPollutantSuite(openWaterDischarge.waterDischargeTableData.quality_pollutant_suit);
            if ((openWaterDischarge.waterDischargeTableData.quality_pollutant_suit) === "Physical") {
                setPollutantParameterValue(physicalPollutant);
            } else if ((openWaterDischarge.waterDischargeTableData.quality_pollutant_suit) === "Chemical") {
                setPollutantParameterValue(chemicalPollutant);
            } else if ((openWaterDischarge.waterDischargeTableData.quality_pollutant_suit) === "Metals") {
                setPollutantParameterValue(metalsPollutant);
            } else if (openWaterDischarge.waterDischargeTableData.quality_pollutant_suit === "Aromatics") {
                setPollutantParameterValue(aromaticsPollutant);
            } else if (openWaterDischarge.waterDischargeTableData.quality_pollutant_suit === "Bacteriological") {
                setPollutantParameterValue(bacteriologicalPollutant);
            }

            setPollutantParameter(openWaterDischarge.waterDischargeTableData.quality_pollutant_parameter);
            setSelectedSuite(openWaterDischarge.waterDischargeTableData.receptor_quality_pollutant_suit);
            setSelectedParameter(openWaterDischarge.waterDischargeTableData.receptor_quality_pollutant_parameter);
            setQualityUnit(openWaterDischarge.waterDischargeTableData.quality_unit);
            setReceptorQualityUnit(openWaterDischarge.waterDischargeTableData.receptor_quality_unit);
            reset(openWaterDischarge.waterDischargeTableData);
        };
    }, [openWaterDischarge.action === true]);

    const handleClose = () => {
        setSelectedSuite("");
        setSelectedParameter("");
        setPollutantSuite("");
        setPollutantParameter("");
        setQualityUnit("");
        setReceptorQualityUnit("");
        reset({ discharge_point: "", quality_pollutant_value: "", discharge_location_lat: "", discharge_location_long: "", receptor_quality_pollutant_value: "", });
        setOpenWaterDischarge({ action: false, waterDischargeTableData: null });
    };

    const submitWaterDischarge = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = treeViewData?.subIssue;
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "discharge_point": data.discharge_point,
                "quality_pollutant_suit": data.quality_pollutant_suit,
                "quality_pollutant_parameter": data.quality_pollutant_parameter,
                "quality_pollutant_value": data.quality_pollutant_value,
                "quality_unit": data.quality_unit,
                "discharge_location_lat": data.discharge_location_lat,
                "discharge_location_long": data.discharge_location_long,
                "receptor_quality_pollutant_suit": data.receptor_quality_pollutant_suit,
                "receptor_quality_pollutant_parameter": data.receptor_quality_pollutant_parameter,
                "receptor_quality_pollutant_value": data.receptor_quality_pollutant_value,
                "receptor_quality_unit": data.receptor_quality_unit,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
                "type_of_record": "Water_Discharge",
                other_specify_receptor_pollutant: selectedParameter === "Other Pollutant (Specify)" ? data.other_specify_receptor_pollutant : "",
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllWaterDischarge(getFilterDate, payload);
                    setSelectedSuite("");
                    setSelectedParameter("");
                    setPollutantSuite("");
                    setPollutantParameter("");
                    setQualityUnit("");
                    setReceptorQualityUnit("");
                    reset({ discharge_point: "", quality_pollutant_value: "", discharge_location_lat: "", discharge_location_long: "", receptor_quality_pollutant_value: "", });
                    setOpenWaterDischarge({ action: false, waterDischargeTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        }
    };

    const updateWaterDischarge = async (data) => {
        const refNo = openWaterDischarge.waterDischargeTableData.ref_no;
        let subIssueName = treeViewData?.subIssue;
        let obj = {
            "discharge_point": data.discharge_point,
            "quality_pollutant_suit": data.quality_pollutant_suit,
            "quality_pollutant_parameter": data.quality_pollutant_parameter,
            "quality_pollutant_value": data.quality_pollutant_value,
            "quality_unit": data.quality_unit,
            "discharge_location_lat": data.discharge_location_lat,
            "discharge_location_long": data.discharge_location_long,
            "receptor_quality_pollutant_suit": data.receptor_quality_pollutant_suit,
            "receptor_quality_pollutant_parameter": data.receptor_quality_pollutant_parameter,
            "receptor_quality_pollutant_value": data.receptor_quality_pollutant_value,
            "receptor_quality_unit": data.receptor_quality_unit,
            "type_of_record": "Water_Discharge",
            other_specify_receptor_pollutant: selectedParameter === "Other Pollutant (Specify)" ? data.other_specify_receptor_pollutant : "",
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllWaterDischarge(getFilterDate, payload);
                setSelectedSuite("");
                setSelectedParameter("");
                setPollutantSuite("");
                setPollutantParameter("");
                setQualityUnit("");
                setReceptorQualityUnit("");
                reset({ discharge_point: "", quality_pollutant_value: "", discharge_location_lat: "", discharge_location_long: "", receptor_quality_pollutant_value: "", });
                setOpenWaterDischarge({ action: false, waterDischargeTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openWaterDischarge.waterDischargeTableData.ref_no,
            subissueName: treeViewData.subIssue,
            "type_of_record": "Water_Discharge",
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                getAllWaterDischarge(getFilterDate, payload);
                setPollutantSuite("");
                setPollutantParameter("");
                setQualityUnit("");
                setReceptorQualityUnit("");
                reset({ discharge_point: "", quality_pollutant_value: "", discharge_location_lat: "", discharge_location_long: "", receptor_quality_pollutant_value: "", });
                setOpenWaterDischarge({ action: false, waterDischargeTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openWaterDischarge.action} maxWidth="lg" fullWidth >
                <material.DialogTitle id="alert-dialog-title" className='fw-bold headingText'>
                    {openWaterDischarge.waterDischargeTableData ? "Edit Water Discharge" : "Add Water Discharge"}
                </material.DialogTitle>
                <material.DialogContent>
                    {openWaterDischarge.waterDischargeTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 1, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography><h5 className='fw-bold headingText'>Water Discharge Quality</h5></material.Typography>
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("discharge_point", { required: true, })}
                                    required
                                    label="Discharge Point"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    onChange={dischargePoint}
                                />
                            </div>
                            <div className='col-4'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label">Pollutant Suite</material.InputLabel>
                                    <material.Select
                                        {...register("quality_pollutant_suit", { required: true, })}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={pollutantSuite}
                                        onChange={selectPollutantSuite}
                                        label="Pollutant suite"
                                    >
                                        <material.MenuItem value={"Physical"}>{"Physical"}</material.MenuItem>
                                        <material.MenuItem value={"Metals"}>{"Metals"}</material.MenuItem>
                                        <material.MenuItem value={"Chemical"}>{"Chemical"}</material.MenuItem>
                                        <material.MenuItem value={"Aromatics"}>{"Aromatics"}</material.MenuItem>
                                        <material.MenuItem value={"Bacteriological"}>{"Bacteriological"}</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label">Pollutant Parameter</material.InputLabel>
                                    <material.Select
                                        {...register("quality_pollutant_parameter",)}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={pollutantParameter}
                                        onChange={pollutantParameterChange}
                                        label="Pollutant Parameter"
                                    >
                                        {pollutantParameterValue.map((ele, ind) => (
                                            <material.MenuItem key={ind} value={ele}>{ele}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4 mt-2'>
                                <material.TextField
                                    {...register("quality_pollutant_value", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Measured Value"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.quality_pollutant_value && errors.quality_pollutant_value.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 2 digit after decimal</p>
                                )}
                            </div>
                            <div className='col-4 mt-2'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label">Unit</material.InputLabel>
                                    <material.Select
                                        {...register("quality_unit", { required: true, })}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={qualityUnit}
                                        onChange={selectQualityUnit}
                                        label="Pollutant suite"
                                    >
                                        <material.MenuItem value={"mg/L"}>mg/L</material.MenuItem>
                                        <material.MenuItem value={"pH unit"}>pH unit</material.MenuItem>
                                        <material.MenuItem value={"Hazen"}>Hazen</material.MenuItem>
                                        <material.MenuItem value={"μg/l"}>μg/l</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                    <material.Card elevation={1} sx={{ p: 2, mt: 1, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography><h5 className='fw-bold headingText'>Water Discharge Receptor Quality</h5></material.Typography>
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("discharge_point", { required: true, })}
                                    required
                                    label="Discharge Point"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("discharge_location_lat", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    required
                                    label="Monitoring Location (Latitude)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.discharge_location_lat && errors.discharge_location_lat.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("discharge_location_long", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    required
                                    label="Monitoring Location (Longitude)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.discharge_location_long && errors.discharge_location_long.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 mt-2'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label">Pollutant Suite</material.InputLabel>
                                    <material.Select
                                        {...register("receptor_quality_pollutant_suit", { required: true, })}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={selectedSuite}
                                        onChange={selectReceptorQualityPollutantSuite}
                                        label="Pollutant suite"
                                    >
                                        {uniqueSuites.map((ele, k) => (
                                            <material.MenuItem key={k} value={ele}>{ele}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </div>

                            <div className='col-4 mt-2'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label">Pollutant Parameter</material.InputLabel>
                                    <material.Select
                                        {...register("receptor_quality_pollutant_parameter",)}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={selectedParameter}
                                        onChange={receptorQualityParameterChange}
                                        label="Pollutant Parameter"
                                    >
                                        {filteredParameters.map((ele, ind) => (
                                            <material.MenuItem key={ind} value={ele.pollutantParameter}>{ele.pollutantParameter}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {selectedParameter === "Other Pollutant (Specify)" ? (
                                <div className='col-4 py-2'>
                                    <material.TextField
                                        {...register("other_specify_receptor_pollutant", { required: true, maxLength: 20 })}
                                        label="Other Pollutant Specify"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors?.other_specify_receptor_pollutant?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-4 mt-2'>
                                <material.TextField
                                    {...register("receptor_quality_pollutant_value", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Measured Value"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.receptor_quality_pollutant_value && errors.receptor_quality_pollutant_value.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 2 digit after decimal</p>
                                )}
                            </div>
                            <div className='col-4 mt-2'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label">Unit</material.InputLabel>
                                    <material.Select
                                        {...register("receptor_quality_unit", { required: true, })}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={receptorQualityUnit}
                                        onChange={selectReceptorQualityUnit}
                                        label="Pollutant suite"
                                    >
                                        <material.MenuItem value={"mg/L"}>mg/L</material.MenuItem>
                                        <material.MenuItem value={"pH unit"}>pH unit</material.MenuItem>
                                        <material.MenuItem value={"Hazen"}>Hazen</material.MenuItem>
                                        <material.MenuItem value={"μg/l"}>μg/l</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openWaterDischarge.waterDischargeTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateWaterDischarge)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openWaterDischarge.waterDischargeTableData.rejection_note === null) || (openWaterDischarge.waterDischargeTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(submitWaterDischarge)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div >
    )
}

export default AddWaterDischargeNew;