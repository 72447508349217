import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import moment from 'moment';
import { getSiteByRefNo } from '../../services/SiteService';


function AddInitiatives(props) {

    const { openInitiatives, setOpenInitiatives, treeViewData, getAllInitiatives, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, setValue, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [currencyCode, setCurrencyCode] = useState(true);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openInitiatives.action && openInitiatives.initiativesTableData) {
            reset(openInitiatives.initiativesTableData);
        }
        else if (openInitiatives.action && openInitiatives.initiativesTableData === null) {
            getFacilityDetails();
        };
    }, [openInitiatives.action === true]);

    const handleClose = () => {
        reset({ csr_project: "", state: "", aspirational_district: "", amount_spent: "", currency_code: "", number_persons_benefited: "", percent_of_vulnerable_and_marg_grp_id: "", });
        setOpenInitiatives({ action: false, initiativesTableData: null });
    };

    const getFacilityDetails = () => {
        getSiteByRefNo(userDetails.facilityRefNo)
            .then((resp) => {
                setValue("currency_code", resp.data.turnOverUnit);
                if (resp.data.turnOverUnit) {
                    setCurrencyCode(true)
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "warning",
                        "message": "Currency code is not present",
                    })
                    setCurrencyCode(false)
                };
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const saveInitiatives = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "csr_project": data.csr_project,
                "state": data.state,
                "aspirational_district": data.aspirational_district,
                "amount_spent": data.amount_spent,
                "currency_code": data.currency_code,
                "number_persons_benefited": data.number_persons_benefited,
                "percent_of_vulnerable_and_marg_grp_id": data.percent_of_vulnerable_and_marg_grp_id,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllInitiatives(getFilterDate, payloadData);
                    reset({ csr_project: "", state: "", aspirational_district: "", amount_spent: "", currency_code: "", number_persons_benefited: "", percent_of_vulnerable_and_marg_grp_id: "", });
                    setOpenInitiatives({ action: false, initiativesTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateInitiatives = async (data) => {
        const refNo = openInitiatives.initiativesTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "csr_project": data.csr_project,
            "state": data.state,
            "aspirational_district": data.aspirational_district,
            "amount_spent": data.amount_spent,
            "currency_code": data.currency_code,
            "number_persons_benefited": data.number_persons_benefited,
            "percent_of_vulnerable_and_marg_grp_id": data.percent_of_vulnerable_and_marg_grp_id,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllInitiatives(getFilterDate, payloadData);
                reset({ csr_project: "", state: "", aspirational_district: "", amount_spent: "", currency_code: "", number_persons_benefited: "", percent_of_vulnerable_and_marg_grp_id: "", });
                setOpenInitiatives({ action: false, initiativesTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openInitiatives.initiativesTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ csr_project: "", state: "", aspirational_district: "", amount_spent: "", currency_code: "", number_persons_benefited: "", percent_of_vulnerable_and_marg_grp_id: "", });
                getAllInitiatives(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenInitiatives({ action: false, initiativesTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openInitiatives.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openInitiatives.initiativesTableData ? "Edit CSR Initiatives" : "Add CSR Initiatives"}</material.DialogTitle>
                <material.DialogContent>
                    {openInitiatives.initiativesTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>CSR Initiatives</material.Typography>
                            </div>
                            <div className='col-12 py-2'>
                                <material.TextField
                                    {...register("csr_project", { required: true, minLength: 2, maxLength: 200 })}
                                    required
                                    label="CSR Project"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    disabled={!currencyCode}
                                />
                                <p className='form-text text-danger'>{errors.csr_project?.type === "required" && 'This field is required'}</p>
                                {errors.csr_project && (errors.csr_project.type === "minLength" || errors.csr_project.type === "maxLength") && (
                                    <p className="errorMsg text-danger">Describe The CSR Project Issues should be between 2 to 200 character.</p>
                                )}
                            </div>
                            <div className='col-12 py-2'>
                                <material.TextField
                                    {...register("state", { required: true, minLength: 2, maxLength: 200 })}
                                    required
                                    label="State"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    disabled={!currencyCode}
                                />
                                <p className='form-text text-danger'>{errors.state?.type === "required" && 'This field is required'}</p>
                                {errors.state && (errors.state.type === "minLength" || errors.state.type === "maxLength") && (
                                    <p className="errorMsg text-danger">Describe The State Issues should be between 2 to 200 character.</p>
                                )}
                            </div>
                            <div className='col-12 py-2'>
                                <material.TextField
                                    {...register("aspirational_district", { required: true, minLength: 2, maxLength: 200 })}
                                    required
                                    label="Aspirational District"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    disabled={!currencyCode}
                                />
                                <p className='form-text text-danger'>{errors.aspirational_district?.type === "required" && 'This field is required'}</p>
                                {errors.aspirational_district && (errors.aspirational_district.type === "minLength" || errors.aspirational_district.type === "maxLength") && (
                                    <p className="errorMsg text-danger">Describe The Aspirational District Issues should be between 2 to 200 character.</p>
                                )}
                            </div>
                            <div className='col-6 py-2'>
                                <material.TextField
                                    {...register("amount_spent", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    required
                                    label="Amount Spent (In Million )"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                    disabled={!currencyCode}
                                />
                                <p className='form-text text-danger'>{errors.amount_spent?.type === "required" && 'This field is required'}</p>
                                {errors.amount_spent && errors.amount_spent.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6th digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-6 py-2'>
                                <material.TextField
                                    {...register("currency_code", { required: true, })}
                                    required
                                    label="Currency Code"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={!currencyCode}
                                />
                            </div>
                            <div className='col-6 py-2'>
                                <material.TextField
                                    {...register("number_persons_benefited", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number Persons Benefited"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                    disabled={!currencyCode}
                                />
                                <p className='form-text text-danger'>{errors.number_persons_benefited?.type === "required" && 'This field is required'}</p>
                                {errors.number_persons_benefited && errors.number_persons_benefited.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-6 py-2'>
                                <material.TextField
                                    {...register("percent_of_vulnerable_and_marg_grp_id", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Percentage of Vulnerable & Marginalized Group Identified"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                    disabled={!currencyCode}
                                />
                                <p className='form-text text-danger'>{errors.percent_of_vulnerable_and_marg_grp_id?.type === "required" && 'This field is required'}</p>
                                {errors.percent_of_vulnerable_and_marg_grp_id && errors.percent_of_vulnerable_and_marg_grp_id.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openInitiatives.initiativesTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateInitiatives)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openInitiatives.initiativesTableData.rejection_note === null) || (openInitiatives.initiativesTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveInitiatives)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddInitiatives; 