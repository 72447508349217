import React, { useEffect, useState } from 'react';
import SwipeDrawer from '../drawer/SwipeDrawer';
import { material } from '../../library/Material';
import { tableContent, actions } from '../../shared/AllTableContent';
import Table from '../../shared/Table';
import { useSelector } from 'react-redux';
import { getScoreData } from '../../services/RankingService';
import Snackbar from '../../toaster-message/Snackbar';

var usersRole;

function Ranking(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };
    const [scoreData, setScoreData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getScoringData()
    }, []);
    const getScoringData = async () => {
        await getScoreData(userDetails.corporateRefNo, userDetails.facilityRefNo)
            .then((resp) => {
                let data = resp.data?.scoreData?.map((ele) => {
                    return ele.values.map((res) => {
                        let obj = { pillar: ele.pillar, ...res, corporateRefNo: resp.data.corporateRefNo, siteRefNo: resp.data.siteRefNo }
                        return obj
                    })
                })
                setScoreData(data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <span><material.Typography variant="h5" className='headingText'>{usersRole === "ROLE_CORPORATE_EDITOR" ? "Corporate " + userDetails.corporateName : usersRole === "ROLE_FACILITY_ESG_DATA" || usersRole === "ROLE_FACILITY_EDITOR" ? "Facility under " + userDetails.corporateName : ""}</material.Typography></span>
                    <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                        {scoreData.length ? scoreData.map((scores, i) => (
                            <material.Accordion key={i} sx={{ mb: 2 }}>
                                <material.AccordionSummary
                                    expandIcon={<material.ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>{scores[0]?.pillar}</material.Typography>
                                </material.AccordionSummary>
                                <material.AccordionDetails>
                                    <material.Typography>
                                        <Table
                                            columns={tableContent[5].esgMaterialColumns}
                                            fields={scores}
                                            actions={actions}
                                            callFrom="RANKING"
                                            getScoringData={getScoringData}
                                        />
                                    </material.Typography>
                                </material.AccordionDetails>
                            </material.Accordion>
                        )) : null}
                    </material.Paper>
                </material.Box>
            </material.Box>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default Ranking;