import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import { getPillarIssueSubIssueTreeList } from '../../services/GeneralDisclosureService';
import Snackbar from '../../toaster-message/Snackbar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { getSitesByCorporateRefNo } from '../../services/SiteService';
import { useSelector } from 'react-redux';
import { dataPeriod, halfYearPeriod, months, quarterDataForDashboard } from '../../shared/TimePeriod';
import { useForm, Controller } from 'react-hook-form';

var facilityRefNo = [];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <material.Slide direction="up" ref={ref} {...props} />;
});

const icon = <material.CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <material.CheckBoxIcon fontSize="small" />;

const currentYear = new Date().getFullYear();
const years = Array.from(new Array(currentYear - 1900 + 1), (val, index) => currentYear - index);

function GlobalFilter(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { openFilterDialog, setOpenFilterDialog, getFilterData, callFrom } = props;
    const { register, handleSubmit, control, reset, resetField, getValues, watch, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [value, setValue] = React.useState();
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [facilityName, setFacilityName] = useState([]);
    const [selectedFacilities, setSelectedFacilities] = useState([]);
    const [periodType, setPeriodType] = React.useState('');
    const [selectedMonth, setSelectedMonth] = React.useState('');
    const [quarterType, setQuarterType] = React.useState('');
    const [halfYearType, setHalfYearType] = React.useState('');
    const [selectedYear, setSelectedYear] = React.useState('');
    const year = localStorage.getItem("year");

    useEffect(() => {
        if (openFilterDialog.action) {
            if (userDetails.roles.includes("ROLE_CORPORATE_EDITOR")) {
                getFacilityByCorporateRefNo();
            }
            setValue([currentYear])
        };
    }, [openFilterDialog.action === true]);

    const getFacilityByCorporateRefNo = () => {
        getSitesByCorporateRefNo(openFilterDialog.userData.corporateRefNo)
            .then((resp) => {
                setFacilityName(resp.data)
            })
            .catch((error) => {

            })
    };

    const getPillarIssueSubIssueList = () => {
        getPillarIssueSubIssueTreeList()
            .then((resp) => {
                console.log(resp.data)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    };

    const selectFacility = (e, newValue) => {
        let refNo = newValue.map((ele) => ele.refNo);
        setSelectedFacilities(refNo)
    };

    const handleChangeYear = (e, newValue) => {
        setValue(newValue);
        // localStorage.setItem("year", newValue.$y);
    };
    console.log(value)
    const applyFilter = (data) => {
        let payload = {
            facilityRefNo: selectedFacilities,
            dataRangeDetails: periodType === 'Monthly' ? data.selectedMonth : periodType === 'Quarterly' ? data.selectedQuarter : periodType === 'Half Yearly' ? data.halfYearType : "",
            dataRange: periodType === 'Monthly' ? 'MONTHLY' : periodType === 'Quarterly' ? 'QUARTERLY' : periodType === 'Half Yearly' ? 'HALF_YEARLY' : "YEARLY",
            yearList: value
        }
        getFilterData(payload);
        setPeriodType("")
        setSelectedMonth("")
        setQuarterType("")
        setHalfYearType("")
        setSelectedYear("")
        setOpenFilterDialog({ action: false, userData: null });
        setSelectedFacilities([]);
        facilityRefNo = [];
    };

    const handleClose = () => {
        setPeriodType("");
        setSelectedMonth("");
        setQuarterType("");
        setHalfYearType("");
        setSelectedYear("");
        setOpenFilterDialog({ action: false, userData: null });
        setSelectedFacilities([]);
        facilityRefNo = [];
    };

    const onchangePeriodType = (event) => {
        setPeriodType(event.target.value);
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value)
    };

    const handleQuarterChange = (event) => {
        setQuarterType(event.target.value);
    };

    const onchangeHalfYearlyType = (event) => {
        setHalfYearType(event.target.value);
    };

    const changeLabel = () => {
        if (periodType === 'Monthly') {
            return 'Month';
        } else if (periodType === 'Quarterly') {
            return 'Quarter';
        } else if (periodType === 'Half Yearly') {
            return 'One half';
        }
    };

    const changeSourceArray = () => {
        if (periodType === 'Monthly') {
            return months;
        } else if (periodType === 'Quarterly') {
            return quarterDataForDashboard;
        } else if (periodType === 'Half Yearly') {
            return halfYearPeriod;
        } else {
            return [];
        }
    };

    const changeSelectionChangeFunction = (event) => {
        if (periodType === 'Monthly') {
            handleMonthChange(event);
        } else if (periodType === 'Quarterly') {
            handleQuarterChange(event);
        } else if (periodType === 'Half Yearly') {
            onchangeHalfYearlyType(event);
        }
    };

    const changeValueState = () => {
        if (periodType === 'Monthly') {
            return selectedMonth;
        } else if (periodType === 'Quarterly') {
            return quarterType;
        } else if (periodType === 'Half Yearly') {
            return halfYearType;
        }
    };

    return (
        <div>
            <material.Dialog open={openFilterDialog.action} maxWidth={userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? "md" : "sm"} fullWidth TransitionComponent={Transition}>
                <material.DialogTitle>Filter by</material.DialogTitle>
                <material.DialogContent>
                    {userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? (
                        <div className="row">
                            <div className='col-12'>
                                <material.Autocomplete
                                    id="treatment"
                                    margin="dense"
                                    onChange={selectFacility}
                                    options={facilityName}
                                    disableCloseOnSelect
                                    multiple
                                    getOptionLabel={(option) => option.assetName}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <material.Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.assetName}
                                        </li>
                                    )}
                                    renderInput={(params) => <material.TextField {...params} required variant="standard" label="Facility Name"
                                    />}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <material.FormControl variant="standard" fullWidth >
                                    <material.InputLabel id="demo-simple-select-standard-label">Data Period</material.InputLabel>
                                    <material.Select
                                        {...register('periodType', { required: true, })}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={periodType}
                                        onChange={onchangePeriodType}
                                        label="Data Period"
                                    >
                                        {
                                            dataPeriod.map((period, pInd) => (
                                                <material.MenuItem value={period} key={pInd}>
                                                    {period}
                                                </material.MenuItem>
                                            )
                                            )
                                        }
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {(periodType == '') || (periodType == 'Yearly') ? null : (
                                <div className='col-lg-4'>
                                    <material.FormControl variant="standard" fullWidth >
                                        <material.InputLabel id="demo-simple-select-standard-label">{changeLabel()}</material.InputLabel>
                                        <material.Select
                                            {...register(periodType === 'Monthly' ? 'selectedMonth' : periodType === 'Quarterly' ? 'selectedQuarter' : periodType === 'Half Yearly' ? 'halfYearType' : null, { required: true, })}
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            label="Month"
                                            value={changeValueState()}
                                            onChange={changeSelectionChangeFunction}
                                        >
                                            {
                                                changeSourceArray().map((item, ind) => (
                                                    <material.MenuItem value={item.value} key={ind}>
                                                        {item.name}
                                                    </material.MenuItem>
                                                )
                                                )
                                            }
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                            )}
                            <div className='col-lg-4'>
                                {periodType !== '' ? (
                                    <material.Autocomplete
                                        id="treatment"
                                        margin="dense"
                                        onChange={handleChangeYear}
                                        options={years}
                                        disableCloseOnSelect
                                        defaultValue={[currentYear]}
                                        multiple
                                        getOptionLabel={(option) => option.toString()}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <material.Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option}
                                            </li>
                                        )}
                                        renderInput={(params) => <material.TextField {...params} required variant="standard" label="Year"
                                        />}
                                    />
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className='col-lg-4'>
                                <material.FormControl variant="standard" fullWidth >
                                    <material.InputLabel id="demo-simple-select-standard-label">Data Period</material.InputLabel>
                                    <material.Select
                                        {...register('periodType', { required: true, })}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={periodType}
                                        onChange={onchangePeriodType}
                                        label="Data Period"
                                    >
                                        {
                                            dataPeriod.map((period, pInd) => (
                                                <material.MenuItem value={period} key={pInd}>
                                                    {period}
                                                </material.MenuItem>
                                            )
                                            )
                                        }
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {(periodType == '') || (periodType == 'Yearly') ? null : (
                                <div className='col-lg-4'>
                                    <material.FormControl variant="standard" fullWidth >
                                        <material.InputLabel id="demo-simple-select-standard-label">{changeLabel()}</material.InputLabel>
                                        <material.Select
                                            {...register(periodType === 'Monthly' ? 'selectedMonth' : periodType === 'Quarterly' ? 'selectedQuarter' : periodType === 'Half Yearly' ? 'halfYearType' : null, { required: true, })}
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            label="Month"
                                            value={changeValueState()}
                                            onChange={changeSelectionChangeFunction}
                                        >
                                            {
                                                changeSourceArray().map((item, ind) => (
                                                    <material.MenuItem value={item.value} key={ind}>
                                                        {item.name}
                                                    </material.MenuItem>
                                                )
                                                )
                                            }
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                            )}
                            <div className='col-lg-4'>
                                {periodType !== '' ? (
                                    <material.Autocomplete
                                        id="treatment"
                                        margin="dense"
                                        onChange={handleChangeYear}
                                        options={years}
                                        disableCloseOnSelect
                                        defaultValue={[currentYear]}
                                        multiple
                                        getOptionLabel={(option) => option.toString()}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <material.Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option}
                                            </li>
                                        )}
                                        renderInput={(params) => <material.TextField {...params} required variant="standard" label="Year"
                                        />}
                                    />
                                ) : null}
                            </div>
                        </div>
                    )}
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="outlined" color='error' sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />} onClick={handleClose}>Cancel</material.Button>
                    <material.Button variant="outlined" color='info' sx={{ textTransform: "none", mr: 2 }} startIcon={<material.DoneIcon />} onClick={handleSubmit(applyFilter)}>Apply</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default GlobalFilter;