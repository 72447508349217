import React, { useEffect, useState } from "react";
import { material } from "../../../library/Material";
import SwipeDrawer from "../../drawer/SwipeDrawer";
import { useLocation } from "react-router-dom";
import { StyledTableCell } from "../../../shared/TableHeaderStyle";
import AddCoverageOfTraining from "../../../sub-issues-dialogs/subissue-coverage-of-training/AddCoverageOfTraining";
import { useSelector } from "react-redux";
import Snackbar from "../../../toaster-message/Snackbar";
import moment from "moment";
import { getDataFilterByCurrentApprovedDataAndNotApprovedDate } from "../../../shared/Filter";
import SpecificDisclosureHistory from "../../specific-disclosure/SpecificDisclosureHistory";
import SpecificDisclosure from "../../specific-disclosure/SpecificDisclosure";
import RejectionNote from "../../../dialogs/RejectionNote";
import DateFilter from "../../../shared/DateFilter";
import { getSubIssueRecordData, saveSubIssueData, updateSubIssueData } from "../../../services/sub-issue-services/AddSubissueService";
import ApplicableDialog from "../../../dialogs/ApplicableDialog";
import Pagination from "../../../shared/pagination/Pagination";


var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };
var recordRefNo = [];

function CoverageOfTraining(props) {
  const location = useLocation();
  const treeViewData = location?.state;
  const userDetails = useSelector((state) => state.LoginSlice.data);
  const [value, setValue] = useState("Coverage of Training");
  const [openAddCoverageOfTraining, setOpenAddCoverageOfTraining] = useState({ action: false, coverageOfTrainingAllData: null, });
  const [coverageOfTrainingAllData, setCoverageOfTrainingAllData] = useState([]);
  const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
  const [applicable, setApplicable] = useState(true);
  const [applicableRefNo, setApplicableRefNo] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState({
    "action": false,
    "type": "",
    "message": "",
  });
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [getFilterDate, setGetFilterDate] = useState(null);

  for (let i = 0; i < userDetails?.roles?.length; i++) {
    usersRole = userDetails.roles[i];
  };

  const paginationData = {
    page,
    size: rowsPerPage
  };

  useEffect(() => {
    getCoverageOfTrainingData()
  }, [value]);

  const getFormDateToDate = (data) => {
    setGetFilterDate(data);
    getCoverageOfTrainingData(data, paginationData);
  };

  const getCoverageOfTrainingData = async (filterDate, paginationValue) => {
    if (value === "Coverage of Training") {
      let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
      let approveHistory = false;
      let payload = {
        subissueName: treeViewData.subIssue,
        corporateRefNo: userDetails.corporateRefNo,
        facilityRefNo: userDetails.facilityRefNo,
        fromDate: filterDate?.fromDate,
        toDate: filterDate?.toDate,
        forApproval,
        approveHistory,
        page: paginationValue?.page ? paginationValue.page : 0,
        size: paginationValue?.size ? paginationValue.size : 10
      }
      await getSubIssueRecordData(payload)
        .then((resp) => {
          setCoverageOfTrainingAllData(resp.data.records.content);
          setApplicable(resp.data.applicableRecord.applicable);
          setApplicableRefNo(resp.data.applicableRecord?.ref_no ? resp.data.applicableRecord.ref_no : null);
          setRowsPerPage(resp.data.records.size);
          setPage(resp.data.records.number);
          setTotalElements(resp.data.records.totalElements);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
            })
          };
        })
    } else if (value === "Coverage of Training History") {
      let forApproval = true;
      let approveHistory = true;
      let payload = {
        subissueName: treeViewData.subIssue,
        corporateRefNo: userDetails.corporateRefNo,
        facilityRefNo: userDetails.facilityRefNo,
        fromDate: filterDate?.fromDate,
        toDate: filterDate?.toDate,
        forApproval,
        approveHistory,
        page: paginationValue?.page ? paginationValue.page : 0,
        size: paginationValue?.size ? paginationValue.size : 10
      }
      await getSubIssueRecordData(payload)
        .then((resp) => {
          setCoverageOfTrainingAllData(resp.data.records.content);
          setRowsPerPage(resp.data.records.size);
          setPage(resp.data.records.number);
          setTotalElements(resp.data.records.totalElements);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
            })
          };
        })
    }
  };

  const handleCoverageOfTrainingAndHistoryTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handelAddCoverageOfTraining = () => {
    setOpenAddCoverageOfTraining({ action: true, coverageOfTrainingAllData: null });
  };

  const editCoverageOfTraining = (rowData) => {
    setOpenAddCoverageOfTraining({ action: true, coverageOfTrainingAllData: rowData, });
  };

  const clickCheckBox = (ids) => {
    setOpenAddCoverageOfTraining({ action: false, coverageOfTrainingAllData: null });
    if (recordRefNo.includes(ids)) {
      // recordRefNo.pop(ids)
      recordRefNo = recordRefNo.filter(item => item !== ids);
    } else {
      recordRefNo.push(ids)
    }
  };

  const sendForApprovalRecords = async () => {
    if (recordRefNo.length) {
      const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
      const subissueName = treeViewData.subIssue;
      const payload = {
        send_for_approval: true
      }
      await updateSubIssueData(payload, refNo, subissueName)
        .then((resp) => {
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
          getCoverageOfTrainingData(getFilterDate, paginationData);
          recordRefNo = [];
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
            })
          }
        })
    }
  };

  const approveRecords = async () => {
    if (recordRefNo.length) {
      const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
      const subissueName = treeViewData.subIssue;
      const payload = {
        send_for_approval: true,
        "approved_by": userDetails.username,
        "approved_date": moment().format("YYYY-MM-DD"),
        "approved_status": "APPROVED",
        "rejection_note": "",
      }
      await updateSubIssueData(payload, refNo, subissueName)
        .then((resp) => {
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
          getCoverageOfTrainingData(getFilterDate, paginationData);
          recordRefNo = [];
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
            })
          }
        })
    }
  };

  const rejectRecords = async () => {
    if (recordRefNo.length) {
      setOpenRejectionNoteDialog({ action: true, rejectionData: null });
    }
  };

  const viewRejectionNote = (rejectData) => {
    setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
  };

  const getRejectionNoteData = async (noteData) => {
    const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
    const subissueName = treeViewData.subIssue;
    const payload = {
      send_for_approval: false,
      "rejection_note": noteData.rejectionNote,
      "approved_status": "",
    }
    await updateSubIssueData(payload, refNo, subissueName)
      .then((resp) => {
        setOpenSnackBar({
          "action": true,
          "type": "success",
          "message": resp.data,
        })
        getCoverageOfTrainingData(getFilterDate, paginationData);
        recordRefNo = [];
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "You are not authorized to perform this action. Redirected to login",
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
          })
        }
      })
  };

  const applicableNotApplicable = () => {
    setOpenWarningDialog(true);
  };

  const getApplicableData = (data) => {
    if (applicableRefNo === null) {
      let subIssueName = treeViewData?.subIssue;
      const payload = {
        applicable: false,
        applicable_record: true,
        "corporate_ref_no": userDetails.corporateRefNo,
        "facility_ref_no": userDetails.facilityRefNo,
      }
      saveSubIssueData(payload, subIssueName)
        .then((resp) => {
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
          getCoverageOfTrainingData(getFilterDate, paginationData);
          setOpenWarningDialog(false);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
            })
          }
        })
    } else {
      let subIssueName = treeViewData?.subIssue;
      let refNo = applicableRefNo;
      const payload = {
        applicable: applicable ? false : true,
      }
      updateSubIssueData(payload, refNo, subIssueName)
        .then((resp) => {
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
          getCoverageOfTrainingData(getFilterDate, paginationData);
          setOpenWarningDialog(false);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
            })
          }
        })
    };
  };

  const getPaginationData = (filterData, paginationData) => {
    setRowsPerPage(paginationData.size);
    setPage(paginationData.page);
    getCoverageOfTrainingData(filterData, paginationData);
  };

  return (
    <div>
      <material.Box sx={{ display: "flex" }}>
        <SwipeDrawer />
        <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7, width: 100 }}>
          <div className="row">
            <div className="col-6">
              <span><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
            </div>
            <div className="col-6">
              <span className="float-end" hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}>
                <material.Stack direction="row" spacing={1} alignItems="center">
                  <material.Typography>Not applicable</material.Typography>
                  <material.Switch {...label} color="secondary" checked={applicable} onChange={applicableNotApplicable} />
                  <material.Typography>Applicable</material.Typography>
                </material.Stack>
              </span>
            </div>
          </div>
          <div className="mt-4">
            <material.TabContext value={value}>
              <material.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <material.TabList
                  onChange={handleCoverageOfTrainingAndHistoryTabs}
                  aria-label="lab API tabs example"
                >
                  <material.Tab sx={{ textTransform: "none" }} label="Coverage of Training" value="Coverage of Training" />
                  <material.Tab sx={{ textTransform: "none" }} label="Coverage of Training History" value="Coverage of Training History" />
                  <material.Tab sx={{ textTransform: "none" }} label="Specific Disclosure" value="Specific Disclosure" />
                  <material.Tab sx={{ textTransform: "none" }} label="Specific Disclosure History" value="Specific Disclosure History" />
                </material.TabList>
              </material.Box>
              <material.TabPanel value="Coverage of Training">
                <div className="row mt-2 align-items-center">
                  <div className="col-lg-6">
                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                      <DateFilter
                        getFormDateToDate={getFormDateToDate}
                      />
                    )}
                  </div>
                  <div className="col-lg-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                    <span className='float-end'>
                      {coverageOfTrainingAllData?.length ? (
                        <>
                          <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} color='success' disabled={!applicable || !recordRefNo.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approveRecords}>Approve</material.Button>
                          <material.Button sx={{ textTransform: "none" }} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} disabled={!applicable || !recordRefNo.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectRecords}>Reject</material.Button>
                        </>
                      ) : null}
                      <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendForApprovalRecords}>Send for Approval</material.Button>
                      <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={handelAddCoverageOfTraining}>Add Coverage of Training</material.Button>
                    </span>
                  </div>
                </div>
                <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                  <div className='row'>
                    <div className="col-12">
                      <material.TableContainer>
                        <material.Table>
                          <material.TableHead>
                            <material.TableRow>
                              <StyledTableCell>Category of Worker/Employee</StyledTableCell>
                              <StyledTableCell>Gender</StyledTableCell>
                              <StyledTableCell>Type of Training</StyledTableCell>
                              <StyledTableCell>Training Topics</StyledTableCell>
                              <StyledTableCell>Other Training Topics (Specify)</StyledTableCell>
                              <StyledTableCell>Number of Worker/Employee Received Training</StyledTableCell>
                              <StyledTableCell>Duration of Training (hr)</StyledTableCell>
                              <StyledTableCell>From Date</StyledTableCell>
                              <StyledTableCell>To Date</StyledTableCell>
                              <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                              {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                <StyledTableCell>Rejection Note</StyledTableCell>
                              )}
                            </material.TableRow>
                          </material.TableHead>
                          <material.TableBody>
                            {coverageOfTrainingAllData.length ? coverageOfTrainingAllData.map((rowData, k) => (
                              <material.TableRow key={k}
                                sx={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") && (rowData.send_for_approval === null || rowData.send_for_approval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                onClick={((userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")) && (rowData.send_for_approval === null || rowData.send_for_approval === false) && applicable === true) ?
                                  () => editCoverageOfTraining(rowData) : null}
                              >
                                <material.TableCell>{rowData.category_of_worker_or_employee}</material.TableCell>
                                <material.TableCell>{rowData.gender}</material.TableCell>
                                <material.TableCell>{rowData.type_of_training}</material.TableCell>
                                <material.TableCell>{rowData.training_topic}</material.TableCell>
                                <material.TableCell>{(rowData.other_specify === "" || rowData.other_specify === null) ? "N/A" : rowData.other_specify}</material.TableCell>
                                <material.TableCell>{rowData.number_of_workers_or_employees_receiving_training}</material.TableCell>
                                <material.TableCell>{rowData.duration_of_training}</material.TableCell>
                                <material.TableCell>{rowData.from_date ? moment(rowData.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                <material.TableCell>{rowData.to_date ? moment(rowData.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                <material.TableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                  {userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") ? (
                                    <material.Checkbox {...label} disabled={!applicable || rowData.send_for_approval} onChange={() => clickCheckBox(rowData.ref_no)} color="success" />
                                  ) : (
                                    <material.Checkbox {...label} disabled={!applicable || rowData.approved_status === "APPROVED"} onChange={() => clickCheckBox(rowData.ref_no)} color="success" />
                                  )}
                                </material.TableCell>
                                {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                  <material.TableCell>{(rowData.rejection_note === null) || (rowData.rejection_note === "") ? "No" : (
                                    <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(rowData) }}>Yes</material.Link>
                                  )}
                                  </material.TableCell>
                                )}
                              </material.TableRow>
                            )) : (
                              <material.TableRow>
                                <material.TableCell colSpan={12}>
                                  <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                </material.TableCell>
                              </material.TableRow>
                            )}
                          </material.TableBody>
                        </material.Table>
                      </material.TableContainer>
                      <Pagination
                        getPaginationData={getPaginationData}
                        totalElements={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        getFilterDate={getFilterDate}
                      />
                    </div>
                  </div>
                </material.Paper>
              </material.TabPanel>
              <material.TabPanel value="Coverage of Training History">
                <div className="row">
                  <div className="col-lg-8">
                    <DateFilter
                      getFormDateToDate={getFormDateToDate}
                    />
                  </div>
                </div>
                <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                  <div className='row'>
                    <div className="col-12">
                      <material.TableContainer>
                        <material.Table>
                          <material.TableHead>
                            <material.TableRow>
                              <StyledTableCell>Category of Worker/Employee</StyledTableCell>
                              <StyledTableCell>Gender</StyledTableCell>
                              <StyledTableCell>Type of Training</StyledTableCell>
                              <StyledTableCell>Training Topics</StyledTableCell>
                              <StyledTableCell>Others Training Topics (Specify)</StyledTableCell>
                              <StyledTableCell>Number of Worker/Employee Received Training</StyledTableCell>
                              <StyledTableCell>Duration of Training (hr)</StyledTableCell>
                              <StyledTableCell>From Date</StyledTableCell>
                              <StyledTableCell>To Date</StyledTableCell>
                              <StyledTableCell>Approval Date</StyledTableCell>
                              <StyledTableCell>Status</StyledTableCell>
                            </material.TableRow>
                          </material.TableHead>
                          <material.TableBody>
                            {coverageOfTrainingAllData?.length ? coverageOfTrainingAllData.map((rowData, k) => (
                              <material.TableRow
                                key={k}
                              >
                                <material.TableCell>{rowData.category_of_worker_or_employee}</material.TableCell>
                                <material.TableCell>{rowData.gender}</material.TableCell>
                                <material.TableCell>{rowData.type_of_training}</material.TableCell>
                                <material.TableCell>{rowData.training_topic}</material.TableCell>
                                <material.TableCell>{(rowData.other_specify === "" || rowData.other_specify === null) ? "N/A" : rowData.other_specify}</material.TableCell>
                                <material.TableCell>{rowData.number_of_workers_or_employees_receiving_training}</material.TableCell>
                                <material.TableCell>{rowData.duration_of_training}</material.TableCell>
                                <material.TableCell>{rowData.from_date ? moment(rowData.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                <material.TableCell>{rowData.to_date ? moment(rowData.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                <material.TableCell>{rowData.approved_date ? moment(rowData.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                <material.TableCell>
                                  {rowData.approved_status === "APPROVED" ? <span className="badge bg-success">
                                    APPROVED
                                  </span> : null}
                                </material.TableCell>
                              </material.TableRow>
                            )) : (
                              <material.TableRow >
                                <material.TableCell colSpan={12}>
                                  <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                </material.TableCell>
                              </material.TableRow>
                            )}
                          </material.TableBody>
                        </material.Table>
                      </material.TableContainer>
                      <Pagination
                        getPaginationData={getPaginationData}
                        totalElements={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        getFilterDate={getFilterDate}
                      />
                    </div>
                  </div>
                </material.Paper>
              </material.TabPanel>
              <material.TabPanel value="Specific Disclosure">
                <SpecificDisclosure
                  treeViewData={treeViewData}
                />
              </material.TabPanel>
              <material.TabPanel value="Specific Disclosure History">
                <SpecificDisclosureHistory
                  treeViewData={treeViewData}
                />
              </material.TabPanel>
            </material.TabContext>
          </div>
        </material.Box>
      </material.Box>
      <AddCoverageOfTraining
        openAddCoverageOfTraining={openAddCoverageOfTraining}
        setOpenAddCoverageOfTraining={setOpenAddCoverageOfTraining}
        getCoverageOfTrainingData={getCoverageOfTrainingData}
        treeViewData={treeViewData}
        page={page}
        rowsPerPage={rowsPerPage}
        getFilterDate={getFilterDate}
      />
      <ApplicableDialog
        openWarningDialog={openWarningDialog}
        setOpenWarningDialog={setOpenWarningDialog}
        getApplicableData={getApplicableData}
        applicable={applicable}
      />
      <Snackbar
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
      />
      <RejectionNote
        openRejectionNoteDialog={openRejectionNoteDialog}
        setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
        getRejectionNoteData={getRejectionNoteData}
      />
    </div>
  );
}

export default CoverageOfTraining;
