import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { saveOrUpdateBiodiversityValueOfSiteData } from '../../services/sub-issue-services/biodiversity-services/BiodiversityValueOfSiteService';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';

const protectedArea = ["National Park", "Wildlife Sanctuary", "Marine Protected Area", "Community Reserve", "Conservation Reserves",];
const siteOfConservationImportance = ["Tiger Reserve", "Elephant Reserve", "Biosphere Reserve", "Ramsar Site", "Natural World Heritage Site", "Important Coastal & Marine Biodiversity Area", "Important Bird Area (IBA)", "Key Biodiversity Area (KBA)", "Biodiversity Heritage Sites", "Alliance for Zero Extinction Area (AZE)",];


function AddBiodiversityValueOfSite(props) {
    const { addOpenBiodiversityValueOfSite, setAddOpenBiodiversityValueOfSite, getBiodiversityValueOfSiteTableData, treeViewData, page, rowsPerPage, getFilterDate } = props;

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [landOwnership, setLandOwnership] = useState("");
    const [sensitiveBiodiversityArea, setSensitiveBiodiversityArea] = useState("")
    const [protectedAreaSiteOfConservationImportance, setProtectedAreaSiteOfConservationImportance] = useState([]);
    const [categoryOfPAConservationImportanceSite, setCategoryOfPAConservationImportanceSite] = useState("");
    const [positionInRelation, setPositionInRelation] = useState("");
    const [ecosystemCharacterization, setEcosystemCharacterization] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (addOpenBiodiversityValueOfSite.action && addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData) {
            setLandOwnership(addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.land_ownership);
            setSensitiveBiodiversityArea(addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.sensitive_biodiversity_area);
            setCategoryOfPAConservationImportanceSite(addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.category_of_conservation_importance_site);
            setPositionInRelation(addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.position_in_relation);
            setEcosystemCharacterization(addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.ecosystem_characterization);
            if (addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.sensitiveBiodiversityArea === 'Protected Area') {
                setProtectedAreaSiteOfConservationImportance(protectedArea);
            } else {
                setProtectedAreaSiteOfConservationImportance(siteOfConservationImportance);
            }
            reset(addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData);
        };
    }, [addOpenBiodiversityValueOfSite.action === true]);


    const selectLandOwnership = (event) => {
        setLandOwnership(event.target.value);
    };

    const selectSensitiveBiodiversityArea = (event) => {
        let selectedSensitiveBiodiversityArea = event.target.value;
        setSensitiveBiodiversityArea(selectedSensitiveBiodiversityArea)
        if (selectedSensitiveBiodiversityArea === 'Protected Area') {
            setProtectedAreaSiteOfConservationImportance(protectedArea)
        } else {
            setProtectedAreaSiteOfConservationImportance(siteOfConservationImportance)
        }
    };

    const selectCategoryOfPAConservationImportanceSite = (event) => {
        setCategoryOfPAConservationImportanceSite(event.target.value);
    };

    const selectPositionInRelation = (event) => {
        setPositionInRelation(event.target.value);
    };

    const selectEcosystemCharacterization = (event) => {
        setEcosystemCharacterization(event.target.value);
    };


    const handleClose = () => {
        setLandOwnership("");
        setSensitiveBiodiversityArea("");
        setCategoryOfPAConservationImportanceSite("");
        setProtectedAreaSiteOfConservationImportance([]);
        setPositionInRelation("");
        setEcosystemCharacterization("");
        setAddOpenBiodiversityValueOfSite({ action: false, biodiversityValueOfSiteTableData: null });
    };

    const saveBiodiversityValueOfSite = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "land_ownership": data.land_ownership,
                "sensitive_biodiversity_area": data.sensitive_biodiversity_area,
                "category_of_conservation_importance_site": data.category_of_conservation_importance_site,
                "position_in_relation": data.position_in_relation,
                "ecosystem_characterization": data.ecosystem_characterization,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(object, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getBiodiversityValueOfSiteTableData(getFilterDate, payloadData);
                    setLandOwnership("");
                    setSensitiveBiodiversityArea("");
                    setCategoryOfPAConservationImportanceSite("");
                    setProtectedAreaSiteOfConservationImportance([]);
                    setPositionInRelation("");
                    setEcosystemCharacterization("");
                    setAddOpenBiodiversityValueOfSite({ action: false, biodiversityValueOfSiteTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateBiodiversityValueOfSite = async (data) => {
        const refNo = addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "land_ownership": data.land_ownership,
            "sensitive_biodiversity_area": data.sensitive_biodiversity_area,
            "category_of_conservation_importance_site": data.category_of_conservation_importance_site,
            "position_in_relation": data.position_in_relation,
            "ecosystem_characterization": data.ecosystem_characterization,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getBiodiversityValueOfSiteTableData(getFilterDate, payloadData);
                setLandOwnership("");
                setSensitiveBiodiversityArea("");
                setCategoryOfPAConservationImportanceSite("");
                setProtectedAreaSiteOfConservationImportance([]);
                setPositionInRelation("");
                setEcosystemCharacterization("");
                setAddOpenBiodiversityValueOfSite({ action: false, biodiversityValueOfSiteTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getBiodiversityValueOfSiteTableData(getFilterDate, payloadData);
                setLandOwnership("");
                setSensitiveBiodiversityArea("");
                setCategoryOfPAConservationImportanceSite("");
                setProtectedAreaSiteOfConservationImportance([]);
                setPositionInRelation("");
                setEcosystemCharacterization("");
                setOpenDeleteRecordConfirmationDialog(false);
                setAddOpenBiodiversityValueOfSite({ action: false, biodiversityValueOfSiteTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={addOpenBiodiversityValueOfSite.action} maxWidth="lg" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData ? "Edit Biodiversity Value of Site" : "Add Biodiversity Value of Site"}</material.DialogTitle>
                <material.DialogContent>
                    {addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Biodiversity Value of Site</material.Typography>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Land Ownership</material.InputLabel>
                                    <material.Select
                                        {...register("land_ownership", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={landOwnership}
                                        onChange={selectLandOwnership}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Owned"}>Owned</material.MenuItem>
                                        <material.MenuItem value={"Leased"}>Leased</material.MenuItem>
                                        <material.MenuItem value={"Managed by the Organization"}>Managed by the Organization</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Sensitive Biodiversity Area</material.InputLabel>
                                    <material.Select
                                        {...register("sensitive_biodiversity_area", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={sensitiveBiodiversityArea}
                                        onChange={selectSensitiveBiodiversityArea}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Protected Area"}>Protected Area</material.MenuItem>
                                        <material.MenuItem value={"Site of Conservation Importance"}>Site of Conservation Importance</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Category of PA / Conservation Importance Site</material.InputLabel>
                                    <material.Select
                                        {...register("category_of_conservation_importance_site", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={categoryOfPAConservationImportanceSite}
                                        onChange={selectCategoryOfPAConservationImportanceSite}
                                        fullWidth
                                        label="Material"
                                    >
                                        {protectedAreaSiteOfConservationImportance.map((ele, i) => (
                                            <material.MenuItem value={ele} key={i}>{ele}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Position in Relation</material.InputLabel>
                                    <material.Select
                                        {...register("position_in_relation", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={positionInRelation}
                                        onChange={selectPositionInRelation}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Containing Portions"}>Containing Portions</material.MenuItem>
                                        <material.MenuItem value={"1 km"}>1 km</material.MenuItem>
                                        <material.MenuItem value={"1-2 km"}>1-2 km</material.MenuItem>
                                        <material.MenuItem value={"2-5 km"}>2-5 km</material.MenuItem>
                                        <material.MenuItem value={"More than 10 km"}>More than 10 km</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Ecosystem Characterization</material.InputLabel>
                                    <material.Select
                                        {...register("ecosystem_characterization", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={ecosystemCharacterization}
                                        onChange={selectEcosystemCharacterization}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Terrestrial"}>Terrestrial</material.MenuItem>
                                        <material.MenuItem value={"Freshwater or"}>Fresh Water</material.MenuItem>
                                        <material.MenuItem value={"Marine"}>Marine</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateBiodiversityValueOfSite)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.rejection_note === null) || (addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveBiodiversityValueOfSite)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddBiodiversityValueOfSite;