import React, { useEffect, useState } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';
import { currencyCode } from '../shared/Currency';
import { addSiteByCorporateRefNo } from '../services/SiteService';
import Snackbar from '../toaster-message/Snackbar';
import { useSelector } from 'react-redux';

function BusinessActivity(props) {

    const { openBusinessActivity, setOpenBusinessActivity, getSiteBySiteRefNo } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, resetField, watch, getValues, setValue, formState: { errors, isValid }, } = useForm({ mode: "onTouched" });
    const [selectUnit, setSelectUnit] = useState("");
    const [selectCurrency, setSelectCurrency] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openBusinessActivity.action) {
            reset(openBusinessActivity.facilityData)
            setSelectUnit(openBusinessActivity.facilityData.productionQuantityUnit)
            setSelectCurrency(openBusinessActivity.facilityData.turnOverUnit)
        }
    }, [openBusinessActivity.action === true]);

    const handleChangeUnit = (event) => {
        setSelectUnit(event.target.value)
    };

    const handleChangeCurrency = (event) => {
        setSelectCurrency(event.target.value)
    };

    const handleClickClose = () => {
        setOpenBusinessActivity({ action: false, facilityData: null })
    };

    const saveActivity = async (data) => {
        let obj = {
            assetName: data.assetName,
            refNo: openBusinessActivity.facilityData.refNo,
            totalTurnOverContributed: data.totalTurnOverContributed,
            turnOverUnit: data.turnOverUnit,
            productionQuantity: data.productionQuantity,
            productionQuantityUnit: data.productionQuantityUnit
        }
        await addSiteByCorporateRefNo(userDetails.corporateRefNo, [obj])
            .then((resp) => {
                setOpenBusinessActivity({ action: false, facilityData: null })
                getSiteBySiteRefNo()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Save successfully",
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openBusinessActivity.action} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold headingText'>Edit business activity</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6'>
                            <material.TextField
                                {...register("assetName")}
                                required
                                margin="dense"
                                label="Facility Name"
                                id="assetName"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <material.TextField
                                {...register("primaryActivity")}
                                required
                                margin="dense"
                                label="Description of Main Activity"
                                id="projectActivity"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                // defaultValue={openBusinessActivity.facilityData?.projectActivity}
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <material.TextField
                                {...register("activityDetails")}
                                required
                                margin="dense"
                                label="Activity Details"
                                id="activityDetails"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <material.TextField
                                {...register("productionQuantity", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                required
                                margin="dense"
                                label="Production Quantity"
                                id="productionQuantity"
                                variant="standard"
                                type="number"
                                size="small"
                                fullWidth
                            />
                            {errors.totalNumber && errors.totalNumber.type === "pattern" && (
                                <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                            )}
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <material.FormControl variant="standard" required fullWidth>
                                <material.InputLabel id="demo-simple-select-label">Unit</material.InputLabel>
                                <material.Select
                                    {...register("productionQuantityUnit", { required: true })}
                                    labelId="demo-simple-select-label"
                                    variant='standard'
                                    id="illHealthSource"
                                    value={selectUnit}
                                    label="productionQuantityUnit"
                                    onChange={handleChangeUnit}
                                >
                                    <material.MenuItem value={"MT"}>MT</material.MenuItem>
                                    <material.MenuItem value={"MW"}>MW</material.MenuItem>
                                    <material.MenuItem value={"KL"}>KL</material.MenuItem>
                                    <material.MenuItem value={"Thousand cubic"}>Thousand cubic</material.MenuItem>
                                    <material.MenuItem value={"feet"}>feet</material.MenuItem>
                                    <material.MenuItem value={"barrels, or “bbl.”"}>barrels, or “bbl.”</material.MenuItem>
                                </material.Select>
                            </material.FormControl>
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <material.TextField
                                {...register("totalTurnOverContributed", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                required
                                margin="dense"
                                label="Total TurnOver Contributed"
                                id="totalTurnOverContributed"
                                variant="standard"
                                type="number"
                                size="small"
                                fullWidth
                            />
                            {errors.totalNumber && errors.totalNumber.type === "pattern" && (
                                <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                            )}
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <material.FormControl variant="standard" required fullWidth>
                                <material.InputLabel id="demo-simple-select-label">Currency Code</material.InputLabel>
                                <material.Select
                                    {...register("turnOverUnit", { required: true })}
                                    labelId="demo-simple-select-label"
                                    variant='standard'
                                    id="turnOverUnit"
                                    value={selectCurrency}
                                    onChange={handleChangeCurrency}
                                >
                                    {currencyCode.map((code, i) => (
                                        <material.MenuItem value={code.code}>{code.code}</material.MenuItem>
                                    ))}
                                </material.Select>
                            </material.FormControl>
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions className="m-3">
                    <material.Button onClick={handleClickClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button color="success" variant="outlined" onClick={handleSubmit(saveActivity)} sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default BusinessActivity;