import { axiosInstance } from "../../shared/AxiosConfig";
import { rootApiUrl } from "../../environment/Environment";


// export const saveUsbIssueData = async (injuriesData) => {
//     return await axiosInstance.post(`${rootApiUrl}/api/data-operation/insert`, { params: { corporateRefNo: payloadData.corporateRefNo,}})
// };

export const saveSubIssueData = async (subIssueData, subIssueName) => {
    return await axiosInstance.post(`${rootApiUrl}/api/data-operation/save`, subIssueData, { params: { subissueName: subIssueName } })
};

export const getOdsEmmisionExports = async (payloadData) => {
    return await axiosInstance.get(`${rootApiUrl}/api/ods_exports/get-all`, { params: payloadData })
};

export const getOdsEmmisionImports = async (emmisionData) => {
    return await axiosInstance.get(`${rootApiUrl}/api/ods_imports/get-all`, { params: emmisionData })
};


export const updateSubIssueData = async (payload, refNo, subIssueName) => {
    return await axiosInstance.post(`${rootApiUrl}/api/data-operation/update`, payload, { params: { subissueName: subIssueName, refNo: refNo } })
};

export const getSubIssueRecordData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/data-operation/getRecords`, { params: { ...payload } })
};

export const commonDelete = async (payload) => {
    return await axiosInstance.delete(`${rootApiUrl}/api/data-operation/delete`, { params: { ...payload } })
};

export const getMaterialsSourced = async (materialsSourced) => {
    return await axiosInstance.get(`${rootApiUrl}/api/data-operation/get-distinct-materials-list`, { params: materialsSourced })
};

export const getPollutantParametersList = async (pollutantParametersList) => {
    return await axiosInstance.get(`${rootApiUrl}/api/master/get-pollutant-parameters-list`, { params: pollutantParametersList })
};

export const saveOrUpdateFuelUsedSubIssueData = async (subIssueData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/fuel-used/save-or-update`, subIssueData)
};

export const saveOrUpdateOtherMaterialsUsedData = async (subIssueData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/other-materials-used/save-or-update`, subIssueData)
};

export const fuelUsedSubIssueDelete = async (recordDelete) => {
    return await axiosInstance.delete(`${rootApiUrl}/api/fuel-used/delete`, { params: recordDelete })
};

export const otherMaterialsUsedSubIssueDelete = async (deleteRecord) => {
    return await axiosInstance.delete(`${rootApiUrl}/api/other-materials-used/delete`, { params: deleteRecord })
};











