import React, { useEffect } from 'react';
import { material } from '../../library/Material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import './NavBar.css'
import { useSelector, useDispatch } from 'react-redux';
import { logoutAction } from '../../store/slice/LogoutSlice';
import axios from 'axios';
import { axiosInstance, setNavigateCallback } from '../../shared/AxiosConfig';
import { loginAction } from '../../store/slice/LoginSlice';

const drawerWidth = 300;

const AppBar = styled(material.MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "rgb(0, 100, 50)",
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: "rgb(0, 100, 50)",
    }),
}));

const initialState = {
    isLoggedIn: false,
    data: {}
};

var usersRole;

function NavBar(props) {

    const { handleDrawerOpen, openDrawer } = props;
    const userDetails = useSelector((state) => state.LoginSlice?.data);
    const [choseOption, setChoseOption] = React.useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    useEffect(() => {
        axiosInstance.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${userDetails.accessToken}`;
        setNavigateCallback(navigate);
        if (!userDetails.accessToken) {
            navigate("/login");
            window.location.reload();
        }
    }, []);

    const handleMenu = (event) => {
        setChoseOption(event.currentTarget);
    };
    function logOut() {
        sessionStorage.removeItem("main-root");
        localStorage.removeItem("main-root");
        localStorage.removeItem("year");
        dispatch(loginAction(initialState));
        navigate('/login');
        window.location.reload(true);
    };
    const handleClose = () => {
        setChoseOption(null);
    };

    return (
        <AppBar position="fixed" open={openDrawer} className='nav-content'>
            <material.Toolbar>
                <material.IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        ...(openDrawer && { display: 'none' }),
                    }}
                >
                    <material.MenuIcon sx={{ color: "white" }} />
                </material.IconButton>
                <material.Typography component="div">
                    {userDetails.corporateName ? (
                        <div style={{ fontSize: 12 }}>Corporate: {userDetails.corporateName}</div>
                    ) : null}
                    {userDetails.facilityName ? (
                        <div style={{ fontSize: 12 }}>Facility: {userDetails.facilityName}</div>
                    ) : null}
                </material.Typography>
                <material.Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></material.Typography>
                <material.Typography component="div">Logged in as: {userDetails.username}</material.Typography>
                <material.IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                >
                    <material.Tooltip>
                        <material.Avatar />
                    </material.Tooltip>
                </material.IconButton>
                <material.Menu
                    id="menu-appbar"
                    anchorEl={choseOption}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={Boolean(choseOption)}
                    onClose={handleClose}
                >
                    <material.MenuItem className="nav-content" sx={{ fontWeight: "bold" }}><material.UserIcon sx={{ mr: 2 }} />User Name: {userDetails.username}</material.MenuItem>
                    <material.MenuItem className="nav-content" sx={{ fontWeight: "bold" }}><material.EmailIcon sx={{ mr: 2 }} />Email: {userDetails.email}</material.MenuItem>
                    <material.MenuItem sx={{ fontWeight: "bold" }}><material.RoleIcon sx={{ mr: 2 }} />Role: {usersRole?.split("_").slice(1).join(" ")}</material.MenuItem>
                    <material.MenuItem onClick={logOut} className="nav-content" sx={{ fontWeight: "bold" }}><material.LogoutIcon sx={{ mr: 2 }} />Logout</material.MenuItem>
                </material.Menu>
            </material.Toolbar>
        </AppBar>
    );
}

export default NavBar;