import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import DatePeriod from '../../shared/DatePeriod';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import { useSelector } from 'react-redux';
import moment from 'moment';


function AddGovernanceScreening(props) {
    const { openAddGovernanceScreening, setOpenAddGovernanceScreening, getAllGovernanceScreeningData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const { register, handleSubmit, control, reset, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [topicCoveredUnderAssessment, setTopicCoveredUnderAssessment] = useState("");
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openAddGovernanceScreening.action && openAddGovernanceScreening.governanceScreeningTableData) {
            setTopicCoveredUnderAssessment(openAddGovernanceScreening.governanceScreeningTableData.topics_covered_under_assessment);
            reset(openAddGovernanceScreening.governanceScreeningTableData);
        }
    }, [openAddGovernanceScreening.action === true]);

    const selectTopicCoveredUnderAssessment = (event) => {
        setTopicCoveredUnderAssessment(event.target.value);
    };

    const handleClose = () => {
        reset({ total_number_of_supplier: "", other_specify: "", number_of_supplier_assessed: "", });
        setTopicCoveredUnderAssessment("");
        setOpenAddGovernanceScreening({ action: false, governanceScreeningTableData: null });
    };

    const submitGovernanceScreening = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                // "total_number_of_supplier ": data.total_number_of_supplier,
                "topics_covered_under_assessment": data.topics_covered_under_assessment,
                "other_specify": data.other_specify,
                "number_of_supplier_assessed": data.number_of_supplier_assessed,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllGovernanceScreeningData(getFilterDate, payloadData);
                    reset({ total_number_of_supplier: "", other_specify: "", number_of_supplier_assessed: "", });
                    setTopicCoveredUnderAssessment("");
                    setOpenAddGovernanceScreening({ action: false, governanceScreeningTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateGovernanceScreening = async (data) => {
        const refNo = openAddGovernanceScreening.governanceScreeningTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            // "total_number_of_supplier ": data.total_number_of_supplier,
            "topics_covered_under_assessment": data.topics_covered_under_assessment,
            "other_specify": topicCoveredUnderAssessment === "Other (Specify)" ? data.other_specify : "",
            "number_of_supplier_assessed": data.number_of_supplier_assessed,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllGovernanceScreeningData(getFilterDate, payloadData);
                reset({ total_number_of_supplier: "", other_specify: "", number_of_supplier_assessed: "", });
                setTopicCoveredUnderAssessment("");
                setOpenAddGovernanceScreening({ action: false, governanceScreeningTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAddGovernanceScreening.governanceScreeningTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ total_number_of_supplier: "", other_specify: "", number_of_supplier_assessed: "", });
                setTopicCoveredUnderAssessment("");
                getAllGovernanceScreeningData(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddGovernanceScreening({ action: false, governanceScreeningTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddGovernanceScreening.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddGovernanceScreening.governanceScreeningTableData ? "Edit Governance Screening" : "Add Governance Screening"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddGovernanceScreening.governanceScreeningTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        {/* <div>
                            <material.TextField
                                {...register("total_number_of_supplier", { required: true, pattern: /^[0-9]+$/ })}
                                required
                                label="Total Number of Supplier"
                                id="userName"
                                variant="standard"
                                type='number'
                                size="small"
                                fullWidth
                            />
                            <p className='form-text text-danger'>{errors.total_number_of_supplier?.type === "required" && 'This field is required'}</p>
                            {errors.total_number_of_supplier && errors.total_number_of_supplier.type === "pattern" && (
                                <p className="errorMsg text-danger">Allowed only number</p>
                            )}
                        </div> */}
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Governance Screening</material.Typography>
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("number_of_supplier_assessed", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number of Supplier Assessed"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.number_of_supplier_assessed?.type === "required" && 'This field is required'}</p>
                                {errors.number_of_supplier_assessed && errors.number_of_supplier_assessed.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-4'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Topic(s) Covered Under Assessment</material.InputLabel>
                                    <material.Select
                                        {...register("topics_covered_under_assessment", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={topicCoveredUnderAssessment}
                                        onChange={selectTopicCoveredUnderAssessment}
                                        label="Currency Code"
                                    >
                                        <material.MenuItem value={"Working Conditions"}>Working Conditions</material.MenuItem>
                                        <material.MenuItem value={"Health and Safety Practices"}>Health and Safety Practices</material.MenuItem>
                                        <material.MenuItem value={"Sexual Harassment"}>Sexual Harassment</material.MenuItem>
                                        <material.MenuItem value={"Discrimination at Workplace"}>Discrimination at Workplace</material.MenuItem>
                                        <material.MenuItem value={"Child Labor"}>Child Labor</material.MenuItem>
                                        <material.MenuItem value={"Forced Labor"}>Forced Labor</material.MenuItem>
                                        <material.MenuItem value={"Wages"}>Wages</material.MenuItem>
                                        <material.MenuItem value={"Freedom of Association"}>Freedom of Association</material.MenuItem>
                                        {/* <material.MenuItem value={"Geographical Location Based Risk Assessed for Freedom of Association"}>Geographical Location Based Risk Assessed for Freedom of Association</material.MenuItem> */}
                                        <material.MenuItem value={"Other (Specify)"}>Other (Specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {topicCoveredUnderAssessment === "Other (Specify)" ? (
                                <div className='col-4'>
                                    <material.TextField
                                        {...register("other_specify", { required: true, maxLength: 20 })}
                                        required
                                        label="Other (specify)"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    <p className='form-text text-danger'>{errors.other_specify?.type === "required" && 'This field is required'}</p>
                                    {(errors?.other_specify?.type === "maxLength") && (
                                        <p className='text-danger'>Maximum 20 characters</p>
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </material.Card>
                </material.DialogContent>

                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddGovernanceScreening.governanceScreeningTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateGovernanceScreening)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddGovernanceScreening.governanceScreeningTableData.rejection_note === null) || (openAddGovernanceScreening.governanceScreeningTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(submitGovernanceScreening)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddGovernanceScreening;