import { rootApiUrl } from "../../environment/Environment";
import { axiosInstance } from "../../shared/AxiosConfig";


export const saveStrategyManagementProcess = async (saveStrategyManagement) => {
    return await axiosInstance.post(`${rootApiUrl}/api/esg-strategy-management-and-process-disclosure/save`, saveStrategyManagement)
};

export const sendForApprovalStrategyManagement = async (sendForApproval) => {
    return await axiosInstance.put(`${rootApiUrl}/api/esg-strategy-management-and-process-disclosure/send-for-approval`, sendForApproval)
};

export const approveRecordStrategyManagement = async (approveData) => {
    return await axiosInstance.put(`${rootApiUrl}/api/esg-strategy-management-and-process-disclosure/approve`, approveData)
};

export const getStrategyManagementData = async (payloadData) => {
    return await axiosInstance.get(`${rootApiUrl}/api/esg-strategy-management-and-process-disclosure/get-all`, { params: payloadData })
};