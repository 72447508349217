export const tableHeaderContent = [
    {
        airEmissionTableColumns: [
            { headerName: "Facility Name", key: "facilityName" },
            { headerName: "Source ID", key: "sourceId" },
            { headerName: "Stack Height", key: "stackHeight" },
            { headerName: "Stack Diameter", key: "stackDiameter" },
            { headerName: "Stack Velocity", key: "stackVelocity" },
            { headerName: "Pollutant Name", key: "pollutantName" },
            { headerName: "Concentration", key: "concentration" },
            { headerName: "Rate", key: "rate" },
            { headerName: "Load", key: "load" },
        ]
    },
    {
        wasteGenerationTableColumns: [
            { headerName: "Facility Name", key: "facilityName" },
            { headerName: "Waste Category", key: "wasteCategory" },
            { headerName: "Non-hazardous", key: "nonHazardous" },
            { headerName: "Hazardous", key: "hazardous" },
            { headerName: "Others", key: "other" },
            { headerName: "Total", key: "total" },
        ]
    },
    {
        wasteRecoveryTableColumns: [
            { headerName: "Facility Name", key: "facilityName" },
            { headerName: "Waste Category", key: "wasteCategory" },
            { headerName: "Waste Recovery Option", key: "wasteRecoveryOption" },
            { headerName: "Off-Site", key: "offSite" },
            { headerName: "On-Site", key: "onSite" },
            { headerName: "Total", key: "total" },
        ]
    },
    {
        wasteDisposalTableColumns: [
            { headerName: "Facility Name", key: "facilityName" },
            { headerName: "Waste Category", key: "wasteCategory" },
            { headerName: "Waste Disposal Option", key: "wasteDisposalOption" },
            { headerName: "Off-Site", key: "offSite" },
            { headerName: "On-Site", key: "onSite" },
            { headerName: "Total", key: "total" },
        ]
    },
    {
        materialTableColumns: [
            { headerName: "Facility Name", key: "facilityName" },
            { headerName: "Material Name", key: "materialName" },
            { headerName: "Renewable", key: "renewable" },
            { headerName: "Non Renewable", key: "nonRenewable" },
            { headerName: "Total Material", key: "totalMaterialsUsed" },
        ]
    },
    {
        energyConsumptionWithinOrgElectricityTableColumns: [
            { headerName: "Facility Name", key: "facilityName" },
            { headerName: "Type of Source", key: "typeOfSource" },
            { headerName: "Purpose of Consumption", key: "purpose" },
            { headerName: "Self Produced", key: "selfProduced" },
            { headerName: "Purchased", key: "purchased" },
            { headerName: "Total", key: "total" },
        ]
    },
    {
        wasteDerivedTableColumns: [
            { headerName: "Facility Name", key: "facilityName" },
            { headerName: "Total Waste Generation", key: "facilityWasteGeneratedTotal" },
            { headerName: "Total Waste Recover", key: "facilityWasteRecoveredTotal" },
            { headerName: "Total Waste Disposal", key: "facilityWasteDisposedTotal" },
        ]
    },
    {
        wasteRecoveryTableColumns: [
            { headerName: "Facility Name", key: "facilityName" },
            { headerName: "Waste Category", key: "wasteCategory" },
            { headerName: "Waste Recovery Option", key: "wasteRecoveryOption" },
            { headerName: "Off Site", key: "offSite" },
            { headerName: "On Site", key: "onSite" },
            { headerName: "Total Off Site & On Site", key: "total" },
        ]
    },
    {
        wasteDisposalTableColumns: [
            { headerName: "Facility Name", key: "facilityName" },
            { headerName: "Waste Category", key: "wasteCategory" },
            { headerName: "Waste Disposal Option", key: "wasteDisposalOption" },
            { headerName: "Off Site", key: "offSite" },
            { headerName: "On Site", key: "onSite" },
            { headerName: "Total Off Site & On Site", key: "total" },
        ]
    },
    {
        scope1TableColumns: [
            { headerName: "Facility Name", key: "facilityName" },
            { headerName: "Type of Source", key: "source" },
            { headerName: "Electricity", key: "totalElectricityAmount" },
            { headerName: "Heating", key: "totalHeatingAmount" },
            { headerName: "Cooling", key: "totalCoolingAmount" },
            { headerName: "Steam", key: "totalSteamAmount" },
            { headerName: "Total", key: "total" },
        ]
    },
    {
        scope2TableColumns: [
            { headerName: "Facility Name", key: "facilityName" },
            { headerName: "Type of Source", key: "source" },
            { headerName: 'Electricity', key: 'Electricity' },
            { headerName: 'Heating', key: 'Heating' },
            { headerName: 'Cooling', key: 'Cooling' },
            { headerName: 'Steam', key: 'Steam' },
            { headerName: 'Total', key: 'Total' }
        ]
    },
    {
        waterWithDrawlTableColumns: [
            { headerName: "Facility Name", key: "facilityName" },
            { headerName: "Type of Source", key: "type_of_source" },
            { headerName: "Fresh Water - Amount (≤1,000 mg/L TDS)", key: "freshWaterTotal" },
            { headerName: "Other Water - Amount (≤1,000 mg/L TDS)", key: "otherWaterTotal" },
            { headerName: "Total Water", key: "totalWater" },
        ]
    },
    {
        waterDischargeTableColumns: [
            { headerName: "Facility Name", key: "facilityName" },
            { headerName: "Output Source", key: "outputSource" },
            { headerName: "Level of Treatment", key: "levelOfTreatment" },
            { headerName: "Category", key: "category" },
            { headerName: "Quantity -Reused/ Recycled", key: "totalRecycleQuantity" },
            { headerName: "Purpose  of Reuse or Recycle", key: "purposeOfReuseOrRecycle" },
            { headerName: "Final Discharge Quantity", key: "totalDischargeQuantity" },
            { headerName: "Discharge point", key: "dischargePoint" },
            { headerName: "Discharge Location (latitude)", key: "dischargeLocationLat" },
            { headerName: "Discharge Location (longitude)", key: "dischargeLocationLong" },
            { headerName: "Discharge Destination Type", key: "dischargeDestinationType" },
            { headerName: "Discharge Destination", key: "dischargeDestination" },
            { headerName: "Destination Category", key: "destinationCategory" },
        ]
    },
    {
        totalConsumptionTableColumn: [
            { headerName: "", key: "category" },
            // { headerName: "Total Discharge", key: "totalDischarge" },
            // { headerName: "Total Recycle", key: "totalRecycle" },
            // { headerName: "Total Withdrawal", key: "totalWithdrawal" },
            { headerName: "Total Consumption", key: "totalConsumption" },
            // { headerName: "Consumption Percentage", key: "consumptionPercentage" },
            // { headerName: "Discharge Percentage", key: "dischargePercentage" },
        ]
    },
    {
        percentageTableColumn: [
            { headerName: "Type", key: "category" },
            { headerName: "Consumption", key: "consumptionPercentage" },
            { headerName: "Discharge", key: "dischargePercentage" },
        ]
    },
    {
        materialUsedTableColumns: [
            { headerName: "Facility Name", key: "facilityName" },
            { headerName: "Material Name", key: "materialName" },
            { headerName: "Renewable", key: "renewable" },
            { headerName: "Non Renewable", key: "nonRenewable" },
            { headerName: "Total Material", key: "totalMaterialsUsed" },
        ]
    },
    {
        recycleInputColumns: [
            { headerName: "Facility Name", key: "facilityName" },
            { headerName: "Material Name", key: "materialName" },
            { headerName: "Recycled", key: "recycled" },
            { headerName: "Re-Used", key: "reUsed" },
            { headerName: "Safely Disposed", key: "safelyDisposed" },
            { headerName: "Total Reuse & Recycle Material", key: "totalMaterialsUsed" },
        ]
    },
    {
        derivedColumns: [
            { headerName: "Total Material", key: "totalMaterialUsed" },
            { headerName: "Reclaimed", key: "totalReclaimed" },
            { headerName: "Recycled", key: "totalRecycle" },
        ]
    },
    {
        wasteDerivedTableColumnsName: [
            { headerName: "Facility Name", key: "facilityName" },
            { headerName: "Waste Category", key: "wasteCategory" },
            { headerName: "Total Waste Generation", key: "wasteGeneratedTotal" },
            { headerName: "Total Waste Recover", key: "wasteRecoveryTotal" },
            { headerName: "Total Waste Disposal", key: "wasteDisposalTotal" },
        ]
    },
    {
        energyConsumptionOutsideTableColumns: [
            { headerName: "Parameter", key: "parameter" },
            { headerName: "Category", key: "category" },
            { headerName: "Amount", key: "amount" },
        ]
    },
    {
        energyConsumptionFuelTableColumns: [
            { headerName: "Fuel Type", key: "fuelType" },
            { headerName: "Renewable", key: "renewable" },
            { headerName: "Non Renewable", key: "nonRenewable" },
            { headerName: "Total", key: "total" },
        ]
    },
    {
        energyConsumptionDerivedTableColumns: [
            { headerName: "Facility Name", key: "facilityName" },
            { headerName: "Type", key: "type" },
            { headerName: "Renewable", key: "renewable" },
            { headerName: "Non Renewable", key: "nonRenewable" },
            { headerName: "Total", key: "total" },
        ]
    }
];

export const fuelConsumptionTableColumns = [
    // { headerName: "Year", key: "year" },
    // { headerName: "Facility Name", key: "facilityName" },
    { headerName: "Type of Source", key: "typeOfSource" },
    { headerName: "Fuel Name", key: "fuelName" },
    { headerName: "Purpose of Consumption", key: "purposeOfUse" },
    { headerName: "Quantity (Tone/ Kl/ Km3)", key: "quantity" },
    { headerName: "Standard Density (Tone/ Kl/ Km3)", key: "standardDensity" },
    { headerName: "Quantity of Materials (Tonne)", key: "quantityOfMaterials" },
    { headerName: "Gross Calorific value (Kcal)", key: "grossCalorificValue" },
    { headerName: "Unit Conversion (GJ)", key: "unitConversion" },
    { headerName: "Energy Use from Fuel (GJ)", key: "energyUsesFromFuel" },
];

export const fuelUsedDerivedColumns = [
    { headerName: "Year", key: "year" },
    { headerName: "Renewable (Tonne)", key: "totalRenewable" },
    { headerName: "Non Renewable (Tonne)", key: "totalNonRenewable" },
];

export const fuelUsedFacilityWiseDerivedColumns = [
    { headerName: "Type of Source", key: "typeOfSource" },
    { headerName: "Quantity of Materials (Tonne)", key: "quantityOfMaterials" },
];


export const otherMaterialUsedTableColumns = [
    { headerName: "Type", key: "type" },
    { headerName: "Purpose", key: "purpose" },
    { headerName: "Name", key: "name" },
    { headerName: "GWP (tco2e/Tonne)", key: "gwp" },
    { headerName: "Quantity (Tonne)", key: "quantity" },
];

export const otherMaterialUsedDerivedColumns = [
    { headerName: "Year", key: "year" },
    { headerName: "Refrigerant (Tonne)", key: "totalCorporateRefrigerantQuantity" },
    { headerName: "Other Material (Tonne)", key: "totalCorporateOtherMaterialQuantity" },
];

export const otherMaterialUsedFacilityWiseDerivedColumns = [
    { headerName: "Type", key: "type" },
    { headerName: "Quantity (Tonne)", key: "quantity" },
];

export const ghgScope1Columns = [
    { headerName: "Type of Source", key: "typeOfSource" },
    { headerName: "Name", key: "name" },
    { headerName: "Quantity (Tonne)", key: "quantity" },
    { headerName: "CO2 Emission Factor (tCO2e/t-mat.)", key: "emission_factor" },
    { headerName: "GWP (tCO2e)", key: "gwp" },
    { headerName: "GHG Quantity (tCO2e)", key: "ghgQuantity" },
];

export const ghgScope1FacilityWiseDerivedColumns = [
    { headerName: "GHG Quantity (tCO2e)", key: "ghgQuantity" },
];

export const ghgScope2Columns = [
    { headerName: "Activity", key: "activity" },
    { headerName: "Quantity (tCO2e)", key: "quantity" },
];

export const ghgScope2FacilityWiseDerivedColumns = [
    { headerName: "Quantity (tCO2e)", key: "quantity" },
];

export const materialSourcedColumns = [
    { headerName: "Material Name", key: "materialName" },
    { headerName: "Material Sourced", key: "materialsSourced" },
    { headerName: "Material Imported", key: "isImported" },
    { headerName: "Amount (in Million)", key: "amount" },
];

export const materialSourcedFacilityWiseColumns = [
    { headerName: "Material Name", key: "materialName" },
    { headerName: "Amount (in Million)", key: "amount" },
];

export const materialSourcedDerivedColumns = [
    { headerName: "Year", key: "year" },
    { headerName: "Material Sourced  MSMEs/ Small Producers (in Million)", key: "totalDirectlySourcedMaterials" },
    { headerName: "Material Imported (in Million)", key: "totalImportedMaterials" },
];

export const energyConsumptionTableColumns = [
    { headerName: "Purpose of Consumption ", key: "purposeOfUse" },
    { headerName: "Source Type", key: "sourceType" },
    { headerName: "Source Category", key: "sourceCategory" },
    { headerName: "Quantity (KwH/ MwH/Gj)", key: "quantity" },
    { headerName: "Unit", key: "unit" },
    { headerName: "1st Unit Conversion (GJ)", key: "firstUnitConversion" },
];

export const energyAndFuelConsumptionFacilityWiseColumns = [
    { headerName: "Source Type", key: "sourceType" },
    { headerName: "Energy (GJ)", key: "totalQuantityEnergy" },
    { headerName: "Fuel (GJ)", key: "totalQuantityFuel" },
    { headerName: "Total Energy and Fuel (GJ)", key: "totalQuantity" },
];

export const energyAndFuelDerivedColumns = [
    { headerName: "Year", key: "year" },
    { headerName: "Renewable (GJ)", key: "totalRenewable" },
    { headerName: "Non Renewable (GJ)", key: "totalNonRenewable" },
];