import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { material } from '../../library/Material';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { useForm } from 'react-hook-form';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import moment from 'moment';


function AddPublicPolicy(props) {

    const { openPublicPolicy, setOpenPublicPolicy, getPublicPolicy, page, rowsPerPage, getFilterDate, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [informationAvailable, setInformationAvailable] = useState('');
    const [frequencyReview, setFrequencyReview] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openPublicPolicy.action && openPublicPolicy.publicPolicyTableData) {
            setInformationAvailable(openPublicPolicy.publicPolicyTableData.information_available_in_public_domain);
            setFrequencyReview(openPublicPolicy.publicPolicyTableData.frequency_of_review);
            reset(openPublicPolicy.publicPolicyTableData);
        }
    }, [openPublicPolicy.action === true]);

    const handleClose = () => {
        setInformationAvailable("");
        setFrequencyReview("");
        reset({ public_policy_advocated: "", method_resorted_for_such_advocacy: "", other_specify: "", web_link_if_available: "", });
        setOpenPublicPolicy({ action: false, publicPolicyTableData: null });
    };

    const savePublicPolicy = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "public_policy_advocated": data.public_policy_advocated,
                "method_resorted_for_such_advocacy": data.method_resorted_for_such_advocacy,
                "information_available_in_public_domain": data.information_available_in_public_domain,
                "frequency_of_review": data.frequency_of_review,
                "other_specify": data.other_specify,
                "web_link_if_available": data.web_link_if_available,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getPublicPolicy(getFilterDate, payloadData);
                    setInformationAvailable("");
                    setFrequencyReview("");
                    reset({ public_policy_advocated: "", method_resorted_for_such_advocacy: "", other_specify: "", web_link_if_available: "", });
                    setOpenPublicPolicy({ action: false, publicPolicyTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updatePublicPolicy = async (data) => {
        const refNo = openPublicPolicy.publicPolicyTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "public_policy_advocated": data.public_policy_advocated,
            "method_resorted_for_such_advocacy": data.method_resorted_for_such_advocacy,
            "information_available_in_public_domain": data.information_available_in_public_domain,
            "frequency_of_review": data.frequency_of_review,
            "other_specify": frequencyReview === "Others (Specify)" ? data.other_specify : "",
            "web_link_if_available": data.web_link_if_available,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getPublicPolicy(getFilterDate, payloadData);
                setInformationAvailable("");
                setFrequencyReview("");
                reset({ public_policy_advocated: "", method_resorted_for_such_advocacy: "", other_specify: "", web_link_if_available: "", });
                setOpenPublicPolicy({ action: false, publicPolicyTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const changeInformationAvailable = (event) => {
        setInformationAvailable(event.target.value);
    };
    const selectFrequencyReview = (event) => {
        setFrequencyReview(event.target.value);
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openPublicPolicy.publicPolicyTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setInformationAvailable("");
                setFrequencyReview("");
                reset({ public_policy_advocated: "", method_resorted_for_such_advocacy: "", other_specify: "", web_link_if_available: "", });
                getPublicPolicy(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenPublicPolicy({ action: false, publicPolicyTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openPublicPolicy.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openPublicPolicy.publicPolicyTableData ? "Edit Public Policy" : "Add Public Policy"}</material.DialogTitle>
                <material.DialogContent>
                    {openPublicPolicy.publicPolicyTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Public Policy</material.Typography>
                            </div>
                            <div className='col-12'>
                                <material.TextField
                                    {...register("public_policy_advocated", { required: true, minLength: 2, maxLength: 200 })}
                                    required
                                    label="Public Policy Advocated"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                                <p className='form-text text-danger'>{errors.public_policy_advocated?.type === "required" && 'This field is required'}</p>
                                {errors.public_policy_advocated && (errors.public_policy_advocated.type === "minLength" || errors.public_policy_advocated.type === "maxLength") && (
                                    <p className="errorMsg text-danger">Public Policy Advocated Issues should be between 2 to 200 character.</p>
                                )}
                            </div>
                            <div className='col-12'>
                                <material.TextField
                                    {...register("method_resorted_for_such_advocacy", { required: true, minLength: 2, maxLength: 200 })}
                                    required
                                    label="Method Resorted for Such Advocacy"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                                <p className='form-text text-danger'>{errors.method_resorted_for_such_advocacy?.type === "required" && 'This field is required'}</p>
                                {errors.method_resorted_for_such_advocacy && (errors.method_resorted_for_such_advocacy.type === "minLength" || errors.method_resorted_for_such_advocacy.type === "maxLength") && (
                                    <p className="errorMsg text-danger">Describe The Method Resorted for Such Advocacy Issues should be between 2 to 200 character.</p>
                                )}
                            </div>
                            <div className='col-6'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Information Available in Public Domain</material.InputLabel>
                                    <material.Select
                                        {...register("information_available_in_public_domain", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={informationAvailable}
                                        onChange={changeInformationAvailable}
                                        label="Information Available in Public Domain"
                                    >
                                        <material.MenuItem value={"Yes"}>Yes</material.MenuItem>
                                        <material.MenuItem value={"No"}>No</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Frequency of Review</material.InputLabel>
                                    <material.Select
                                        {...register("frequency_of_review", { required: true, })}
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={frequencyReview}
                                        onChange={selectFrequencyReview}
                                        label="Frequency of Review"
                                    >
                                        <material.MenuItem value={"Annually"}>Annually</material.MenuItem>
                                        <material.MenuItem value={"Half Yearly"}>Half Yearly</material.MenuItem>
                                        <material.MenuItem value={"Quarterly"}>Quarterly</material.MenuItem>
                                        <material.MenuItem value={"Others (Specify)"}>Others (Specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {frequencyReview === "Others (Specify)" ? (
                                <div className='col-6 mt-3'>
                                    <material.TextField
                                        {...register("other_specify", { required: true, maxLength: 20 })}
                                        required
                                        label="Others (Specify)"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    <p className='form-text text-danger'>{errors.other_specify?.type === "required" && 'This field is required'}</p>
                                    {errors?.other_specify?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-6 mt-3'>
                                <material.TextField
                                    {...register("web_link_if_available")}
                                    label="Web Link, if available"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openPublicPolicy.publicPolicyTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updatePublicPolicy)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openPublicPolicy.publicPolicyTableData.rejection_note === null) || (openPublicPolicy.publicPolicyTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(savePublicPolicy)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddPublicPolicy; 