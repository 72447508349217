import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { saveOrUpdateInjuriesData } from '../../services/sub-issue-services/InjuriesServices';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddWorkRelatedInjuries(props) {
    const { register, handleSubmit, reset, control, resetField, watch, getValues, setValue, formState: { errors, isValid }, } = useForm({ mode: "onTouched" });
    const { openAddWorkRelatedInjuries, setOpenAddWorkRelatedInjuries, getInjuriesTableData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [workRelatedInjuries, setWorkRelatedInjuries] = useState("");
    const [injureSource, setInjureSource] = useState("");
    const [category, setCategory] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    const onChangeTotalNumber = watch('total_number');
    const onChangeRehabilitated = watch('rehabilitated_and_placed_in_suitable_employment');
    const onChangeFamilyMembers = watch('family_members_have_been_placed_in_suitable_employment');

    useEffect(() => {
        totalCalculation();
    }, [onChangeTotalNumber, onChangeRehabilitated, onChangeFamilyMembers]);

    const totalCalculation = () => {
        let totalNumberValue = getValues('total_number');
        let val1 = getValues('rehabilitated_and_placed_in_suitable_employment');
        let val2 = getValues('family_members_have_been_placed_in_suitable_employment');
        if ((Number(val1) + Number(val2)) > Number(totalNumberValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Rehabilitation & Placement and Placement of Employee/Worker Family Member value should not be greater than Total Number",
            });
            setButtonDisable(true);
        } else {
            setButtonDisable(false);
        };
    };

    useEffect(() => {
        if (openAddWorkRelatedInjuries.action && openAddWorkRelatedInjuries.workRelatedInjuriesData) {
            setWorkRelatedInjuries(openAddWorkRelatedInjuries.workRelatedInjuriesData.safety_incident);
            setCategory(openAddWorkRelatedInjuries.workRelatedInjuriesData.worker_category);
            setInjureSource(openAddWorkRelatedInjuries.workRelatedInjuriesData.injury_source)
            reset(openAddWorkRelatedInjuries.workRelatedInjuriesData);
        }
        setValue("total_number", 0);
        setValue("rehabilitated_and_placed_in_suitable_employment", 0);
        setValue("family_members_have_been_placed_in_suitable_employment", 0);
    }, [openAddWorkRelatedInjuries.action === true]);

    const selectWorkRelatedInjuries = (event) => {
        setWorkRelatedInjuries(event.target.value);
    };

    const selectCategory = (event) => {
        setCategory(event.target.value);
    };

    const handleClose = () => {
        reset({ total_number: "", lost_time: "", rehabilitated_and_placed_in_suitable_employment: "", family_members_have_been_placed_in_suitable_employment: "" });
        setWorkRelatedInjuries("");
        setCategory("");
        setInjureSource("")
        setOpenAddWorkRelatedInjuries({ action: false, workRelatedInjuriesData: null });
    };

    const saveWorkRelatedInjuries = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "safety_incident": data.safety_incident,
                "injury_source": data.injury_source,
                "worker_category": data.worker_category,
                "total_number": parseInt(data.total_number),
                "lost_time": data.lost_time,
                "rehabilitated_and_placed_in_suitable_employment": data.rehabilitated_and_placed_in_suitable_employment,
                "family_members_have_been_placed_in_suitable_employment": data.family_members_have_been_placed_in_suitable_employment,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(object, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getInjuriesTableData(getFilterDate, payloadData);
                    reset({ total_number: "", lost_time: "", rehabilitated_and_placed_in_suitable_employment: "", family_members_have_been_placed_in_suitable_employment: "" });
                    setWorkRelatedInjuries("");
                    setCategory("");
                    setInjureSource("")
                    setOpenAddWorkRelatedInjuries({ action: false, workRelatedInjuriesData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateWorkRelatedInjuries = async (data) => {
        const refNo = openAddWorkRelatedInjuries.workRelatedInjuriesData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let object = {
            "safety_incident": data.safety_incident,
            "injury_source": data.injury_source,
            "worker_category": data.worker_category,
            "total_number": parseInt(data.total_number),
            "lost_time": data.lost_time,
            "rehabilitated_and_placed_in_suitable_employment": data.rehabilitated_and_placed_in_suitable_employment,
            "family_members_have_been_placed_in_suitable_employment": data.family_members_have_been_placed_in_suitable_employment,
        }
        await updateSubIssueData(object, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getInjuriesTableData(getFilterDate, payloadData);
                reset({ total_number: "", lost_time: "", rehabilitated_and_placed_in_suitable_employment: "", family_members_have_been_placed_in_suitable_employment: "" });
                setWorkRelatedInjuries("");
                setCategory("");
                setInjureSource("")
                setOpenAddWorkRelatedInjuries({ action: false, workRelatedInjuriesData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })

    };

    const selectInjurySource = (event) => {
        setInjureSource(event.target.value)
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAddWorkRelatedInjuries.workRelatedInjuriesData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getInjuriesTableData(getFilterDate, payloadData);
                reset({ total_number: "", lost_time: "", rehabilitated_and_placed_in_suitable_employment: "", family_members_have_been_placed_in_suitable_employment: "" });
                setWorkRelatedInjuries("");
                setCategory("");
                setInjureSource("")
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddWorkRelatedInjuries({ action: false, workRelatedInjuriesData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddWorkRelatedInjuries.action} maxWidth="lg" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddWorkRelatedInjuries.workRelatedInjuriesData ? "Edit Work Related Injuries" : "Add Work Related Injuries"}</material.DialogTitle>
                <material.DialogContent>
                    <material.DialogContentText id="alert-dialog-description">
                        <div className='my-3'>
                            {openAddWorkRelatedInjuries.workRelatedInjuriesData ? null : (
                                <DatePeriod
                                    register={register}
                                    control={control}
                                />
                            )}
                        </div>
                        <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
                            <div className='row'>
                                <div className='py-1'>
                                    <material.Typography className='fw-bold'>Work Related Injuries</material.Typography>
                                </div>
                                <div className='col-4'>
                                    <material.FormControl variant="standard" required fullWidth>
                                        <material.InputLabel id="demo-simple-select-label">Safety Incident</material.InputLabel>
                                        <material.Select
                                            {...register("safety_incident", { required: true })}
                                            labelId="demo-simple-select-label"
                                            variant='standard'
                                            id="safetyIncident"
                                            value={workRelatedInjuries}
                                            label="Category of worker"
                                            onChange={selectWorkRelatedInjuries}
                                        >
                                            <material.MenuItem value={"Fatalities as a Result of Work Related Injury"}>Fatalities as a Result of Work Related Injury</material.MenuItem>
                                            <material.MenuItem value={"High Consequence Work Related Injuries (Excluding Fatalities)"}>High Consequence Work Related Injuries (Excluding Fatalities)</material.MenuItem>
                                            <material.MenuItem value={"Recordable Work Related Injury"}>Recordable Work Related Injury</material.MenuItem>
                                            <material.MenuItem value={"Near Misses"}>Near Misses</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                                <div className='col-4'>
                                    <material.FormControl variant="standard" required fullWidth>
                                        <material.InputLabel id="demo-simple-select-label">Injury Source</material.InputLabel>
                                        <material.Select
                                            {...register("injury_source", { required: true })}
                                            labelId="demo-simple-select-label"
                                            variant='standard'
                                            id="injurySource"
                                            value={injureSource}
                                            label="Category of worker"
                                            onChange={selectInjurySource}
                                        >
                                            <material.MenuItem value={"Slip / Trip / Fall"}>Slip / Trip / Fall</material.MenuItem>
                                            <material.MenuItem value={"Struck on"}>Struck on</material.MenuItem>
                                            <material.MenuItem value={"Material Handling"}>Material Handling</material.MenuItem>
                                            <material.MenuItem value={"Machine Safety"}>Machine Safety</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                                <div className='col-4'>
                                    <material.Box>
                                        <material.FormControl variant="standard" required fullWidth>
                                            <material.InputLabel id="demo-simple-select-label">Category</material.InputLabel>
                                            <material.Select
                                                {...register("worker_category", { required: true })}
                                                labelId="demo-simple-select-label"
                                                variant='standard'
                                                id="workerCategory"
                                                value={category}
                                                label="Category"
                                                onChange={selectCategory}
                                            >
                                                <material.MenuItem value={"Employee Permanent"}>Employee Permanent</material.MenuItem>
                                                <material.MenuItem value={"Employee other than Permanent"}>Employee other than Permanent</material.MenuItem>
                                                <material.MenuItem value={"Workers Permanent"}>Workers Permanent</material.MenuItem>
                                                <material.MenuItem value={"Workers other than Permanent"}>Workers other than Permanent</material.MenuItem>
                                            </material.Select>
                                        </material.FormControl>
                                    </material.Box>
                                </div>
                                <div className='col-4 mt-2'>
                                    <material.TextField
                                        {...register("total_number", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        margin="dense"
                                        label="Number"
                                        id="total_number"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                    // InputLabelProps={{ shrink: true }}
                                    // InputProps={{ readOnly: true }}
                                    />
                                    {errors.total_number && errors.total_number.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed</p>
                                    )}
                                </div>
                                <div className='col-4 mt-2'>
                                    <material.TextField
                                        {...register("lost_time", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                        required
                                        margin="dense"
                                        label="Lost Time (hr)"
                                        id="lost_time"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.lost_time && errors.lost_time.type === "pattern" && (
                                        <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                    )}
                                </div>
                                <div className='col-4 mt-2'>
                                    <material.TextField
                                        {...register("rehabilitated_and_placed_in_suitable_employment", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        margin="dense"
                                        label="Rehabilitation & Placement of Employee/Worker"
                                        id="rehabilitated_and_placed_in_suitable_employment"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.rehabilitated_and_placed_in_suitable_employment && errors.rehabilitated_and_placed_in_suitable_employment.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed</p>
                                    )}
                                </div>
                                <div className='col-4 mt-2'>
                                    <material.TextField
                                        {...register("family_members_have_been_placed_in_suitable_employment", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        margin="dense"
                                        label="Placement of Employee/Worker Family Members"
                                        id="family_members_have_been_placed_in_suitable_employment"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.family_members_have_been_placed_in_suitable_employment && errors.family_members_have_been_placed_in_suitable_employment.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed</p>
                                    )}
                                </div>
                                {/* <div className='col-4'>
                                    <material.TextField
                                        {...register("total_number", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        margin="dense"
                                        label="Number"
                                        id="total_number"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ readOnly: true }}
                                    />
                                    {errors.total_number && errors.total_number.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed</p>
                                    )}
                                </div> */}
                            </div>
                        </material.Card>
                    </material.DialogContentText>
                </material.DialogContent>
                <material.DialogActions className="m-3">
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddWorkRelatedInjuries.workRelatedInjuriesData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} disabled={buttonDisable} onClick={handleSubmit(updateWorkRelatedInjuries)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddWorkRelatedInjuries.workRelatedInjuriesData.rejection_note === null) || (openAddWorkRelatedInjuries.workRelatedInjuriesData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} disabled={buttonDisable} onClick={handleSubmit(saveWorkRelatedInjuries)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddWorkRelatedInjuries;