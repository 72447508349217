import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { material } from '../../library/Material';
import { getNseList } from '../../services/materiality-services/MaterialityService';
import Snackbar from '../../toaster-message/Snackbar';
import { useSelector } from 'react-redux';
import { addSiteByCorporateRefNo } from '../../services/SiteService';


function AddNse(props) {

    const { openNse, setOpenNse, getSiteBySiteRefNo } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });

    const [selectedSector, setSelectedSector] = useState(null);
    const [selectedIndustry, setSelectedIndustry] = useState(null);
    const [selectedBasicIndustry, setSelectedBasicIndustry] = useState(null);
    const [filteredIndustries, setFilteredIndustries] = useState([]);
    const [filteredBasicIndustries, setFilteredBasicIndustries] = useState([]);
    const [nseData, setNseData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openNse.action && openNse.editData === null) {
            getAllNseList()
        } else if (openNse.action && openNse.editData) {
            getAllNseList();
            setSelectedSector(openNse.editData.sector);
            setSelectedIndustry(openNse.editData.industry);
            setSelectedBasicIndustry(openNse.editData.basicIndustry)
        }
    }, [openNse.action === true]);

    const getAllNseList = () => {
        getNseList()
            .then((resp) => {
                setNseData(resp.data)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    const handleSectorChange = (event, value) => {
        setSelectedSector(value);
        setFilteredIndustries([...new Set(nseData.filter(item => item.sector === value).map(item => item.industry))]);
        setSelectedIndustry(null);
        setSelectedBasicIndustry(null);
        setFilteredBasicIndustries([]);
    };

    const handleIndustryChange = (event, value) => {
        setSelectedIndustry(value);
        setFilteredBasicIndustries(nseData.filter(item => item.industry === value));
        setSelectedBasicIndustry(null);
    };

    const handleBasicIndustryChange = (event, value) => {
        setSelectedBasicIndustry(value);
    };

    const handleClose = () => {
        setSelectedBasicIndustry(null);
        setSelectedIndustry(null);
        setSelectedSector(null);
        setOpenNse({ action: false, editData: null });
        setFilteredIndustries([]);
        setFilteredBasicIndustries([]);
    };

    const SaveNse = () => {
        if (selectedBasicIndustry) {
            const selectedData = nseData.find(item => item.basicIndustry === selectedBasicIndustry);
            const payload = [{
                assetName: userDetails.facilityName,
                refNo: userDetails.facilityRefNo,
                nseDtos: [selectedData]
            }]
            addSiteByCorporateRefNo(userDetails.corporateRefNo, payload)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Save successfully",
                    })
                    getSiteBySiteRefNo();
                    setOpenNse({ action: false, editData: null });
                    setSelectedBasicIndustry(null);
                    setSelectedIndustry(null);
                    setSelectedSector(null);
                    setFilteredIndustries([]);
                    setFilteredBasicIndustries([]);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    // const deleteConfirmation = () => {

    // }

    return (
        <div>
            <material.Dialog open={openNse.action} maxWidth="lg" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openNse.editData ? "Edit NSE" : "Add NSE"}</material.DialogTitle>
                <material.DialogContent>
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='col-4 mt-2'>
                                <material.Autocomplete
                                    options={[...new Set(nseData.map(item => item.sector))]}
                                    value={selectedSector}
                                    onChange={handleSectorChange}
                                    renderInput={(params) => <material.TextField variant="standard" {...params} label="Sector" />}
                                />
                            </div>
                            <div className='col-4 mt-2'>
                                {/* {filteredIndustries.length > 0 && ( */}
                                <material.Autocomplete
                                    options={filteredIndustries}
                                    value={selectedIndustry}
                                    onChange={handleIndustryChange}
                                    renderInput={(params) => <material.TextField variant="standard" {...params} label="Industry" />}
                                />
                                {/* )} */}
                            </div>
                            <div className='col-4 mt-2'>
                                {/* {filteredBasicIndustries.length > 0 && ( */}
                                <material.Autocomplete
                                    options={filteredBasicIndustries.map(item => item.basicIndustry)}
                                    value={selectedBasicIndustry}
                                    onChange={handleBasicIndustryChange}
                                    renderInput={(params) => <material.TextField variant="standard" {...params} label="Basic Industry" />}
                                />
                                {/* )} */}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(SaveNse)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    {/* {openNse.editData === null ? (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(SaveNse)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    ) : (
                        <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                    )} */}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

export default AddNse