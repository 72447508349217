import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import DatePeriod from '../../shared/DatePeriod';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import { getSiteByRefNo } from '../../services/SiteService';




function AddOrUpdateFinancialAssistanceDialog(props) {
    const { openAddFinancialAssistance, setOpenAddFinancialAssistance, getAllFinancialAssistanceData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const { register, handleSubmit, control, reset, resetField, setValue, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [financialAssistanceReceivedOn, setFinancialAssistanceReceivedOn] = useState("");
    const [currencyCode, setCurrencyCode] = useState(true);
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openAddFinancialAssistance.action && openAddFinancialAssistance.financialAssistanceTableData) {
            setFinancialAssistanceReceivedOn(openAddFinancialAssistance.financialAssistanceTableData.financial_assistance_received_on);
            reset(openAddFinancialAssistance.financialAssistanceTableData);
        }
        else if (openAddFinancialAssistance.action && openAddFinancialAssistance.financialAssistanceTableData === null) {
            getFacilityDetails();
        };
    }, [openAddFinancialAssistance.action === true]);

    const selectFinancialAssistanceReceivedOn = (event) => {
        setFinancialAssistanceReceivedOn(event.target.value)
    };

    const handleClose = () => {
        reset({ other_specify: "", amount: "", currency_code: "" });
        setFinancialAssistanceReceivedOn("");
        setOpenAddFinancialAssistance({ action: false, financialAssistanceTableData: null });
    };

    const getFacilityDetails = () => {
        getSiteByRefNo(userDetails.facilityRefNo)
            .then((resp) => {
                setValue("currency_code", resp.data.turnOverUnit);
                if (resp.data.turnOverUnit) {
                    setCurrencyCode(true)
                } else {
                    setCurrencyCode(false)
                }
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const submitFinancialAssistance = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "financial_assistance_received_on": data.financial_assistance_received_on,
                "other_specify": data.other_specify,
                "amount": data.amount,
                "currency_code": data.currency_code,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllFinancialAssistanceData(getFilterDate, payloadData);
                    reset({ other_specify: "", amount: "", currency_code: "", });
                    setFinancialAssistanceReceivedOn("");
                    setOpenAddFinancialAssistance({ action: false, financialAssistanceTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateFinancialAssistance = async (data) => {
        const refNo = openAddFinancialAssistance.financialAssistanceTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "financial_assistance_received_on": data.financial_assistance_received_on,
            "other_specify": financialAssistanceReceivedOn === "Other (Specify)" ? data.other_specify : "",
            "amount": data.amount,
            "currency_code": data.currency_code
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllFinancialAssistanceData(getFilterDate, payloadData);
                reset({ other_specify: "", amount: "", currency_code: "", });
                setFinancialAssistanceReceivedOn("");
                setOpenAddFinancialAssistance({ action: false, financialAssistanceTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAddFinancialAssistance.financialAssistanceTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ other_specify: "", amount: "", currency_code: "" });
                setFinancialAssistanceReceivedOn("");
                getAllFinancialAssistanceData(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddFinancialAssistance({ action: false, financialAssistanceTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddFinancialAssistance.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddFinancialAssistance.financialAssistanceTableData ? "Edit Financial Assistance" : "Add Financial Assistance"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddFinancialAssistance.financialAssistanceTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Financial Assistance</material.Typography>
                            </div>
                            <div>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Financial Assistance Received On</material.InputLabel>
                                    <material.Select
                                        {...register("financial_assistance_received_on", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={financialAssistanceReceivedOn}
                                        onChange={selectFinancialAssistanceReceivedOn}
                                        label="Type of material"
                                        disabled={!currencyCode}
                                    >
                                        <material.MenuItem value={"Tax Relief and Tax Credits"}>Tax Relief and Tax Credits</material.MenuItem>
                                        <material.MenuItem value={"Subsidies"}>Subsidies</material.MenuItem>
                                        <material.MenuItem value={"Investment Grants, Research and Development Grants, and Other Relevant Types of Grant"}>Investment Grants, Research and Development Grants, and Other Relevant Types of Grant</material.MenuItem>
                                        <material.MenuItem value={"Awards"}>Awards</material.MenuItem>
                                        <material.MenuItem value={"Royalty Holidays"}>Royalty Holidays</material.MenuItem>
                                        <material.MenuItem value={"Financial Assistance from Export Credit Agencies (EC As)"}>Financial Assistance from Export Credit Agencies (EC As)</material.MenuItem>
                                        <material.MenuItem value={"Financial Incentives"}>Financial Incentives</material.MenuItem>
                                        <material.MenuItem value={"Other Financial Benefits Received or Receivable from Any Government for Any Operation"}>Other Financial Benefits Received or Receivable from Any Government for Any Operation</material.MenuItem>
                                        <material.MenuItem value={"Other (Specify)"}>Other (Specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {financialAssistanceReceivedOn === "Other (Specify)" ? (
                                <div className='mt-3'>
                                    <material.TextField
                                        {...register("other_specify", { required: true, maxLength: 20 })}
                                        required
                                        label="Other (specify)"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                        disabled={!currencyCode}
                                    />
                                    <p className='form-text text-danger'>{errors.other_specify?.type === "required" && 'This field is required'}</p>
                                    {(errors?.other_specify?.type === "maxLength") && (
                                        <p className='text-danger'>Maximum 20 characters</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='mt-3'>
                                <material.TextField
                                    {...register("amount", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    required
                                    label="Amount (in Million)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                    disabled={!currencyCode}
                                />
                                <p className='form-text text-danger'>{errors.amount?.type === "required" && 'This field is required'}</p>
                                {errors.amount && errors.amount.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6th digit after decimal.</p>
                                )}
                            </div>
                            <div className='mt-3'>
                                <material.TextField
                                    {...register("currency_code", { required: true, })}
                                    required
                                    label="Currency Code"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={!currencyCode}
                                />
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddFinancialAssistance.financialAssistanceTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateFinancialAssistance)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddFinancialAssistance.financialAssistanceTableData.rejection_note === null) || (openAddFinancialAssistance.financialAssistanceTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(submitFinancialAssistance)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    );
}

export default AddOrUpdateFinancialAssistanceDialog;