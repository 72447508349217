import React, { useEffect, useState } from 'react';
import SwipeDrawer from '../drawer/SwipeDrawer';
import { material } from '../../library/Material';
import AddUser from '../../dialogs/AddUser';
import { useSelector, useDispatch } from 'react-redux';
import { addUsers, generateOtpByUserName, getUsersByAdmin, getUsersByCorporate } from '../../services/UsermanagementService';
import VerifyOtpDialog from '../../dialogs/VerifyOtpDialog';
import Snackbar from '../../toaster-message/Snackbar';
import EditOrViewUser from '../../dialogs/EditOrViewUser';
import { StyledTableCell } from '../../shared/TableHeaderStyle';


const label = { inputProps: { 'aria-label': 'Color switch demo' } };
var userRole;

function UserManagemant(props) {
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [openAddUser, setOpenAddUser] = useState({ "action": false, "corporateData": "", "callFrom": "" });
    const [usersData, setUsersData] = useState([]);
    const [openVerifyOtpDialog, setOpenVerifyOtpDialog] = useState({ action: false, userName: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [openEditOrViewUser, setOpenEditOrViewUser] = useState({ "action": false, "usersData": null });

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        userRole = userDetails.roles[i]
    };

    useEffect(() => {
        getUserList()
    }, []);

    const getUserList = async () => {
        if (userRole === "ROLE_ADMIN") {
            await getUsersByAdmin()
                .then((resp) => {
                    setUsersData(resp.data)
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (userRole === "ROLE_CORPORATE_EDITOR") {
            await getUsersByCorporate(userDetails.corporateRefNo)
                .then((resp) => {
                    setUsersData(resp.data)
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        };
    };

    const addUser = () => {
        setOpenAddUser({ "action": true, corporateData: "", callFrom: "userManagement" })
    };

    const editUser = (rowData) => {
        setOpenEditOrViewUser({ "action": true, usersData: rowData })
    };

    const viewUsers = (rowData) => {
        setOpenEditOrViewUser({ "action": true, usersData: rowData });
    };

    const activeDeActiveUser = async (rowData) => {
        let obj = {
            "username": rowData.username,
            "name": rowData.name,
            "email": rowData.email,
            "role": [
                rowData.role
            ],
            "updateUser": true,
            active: rowData.isActive ? false : true
        }
        await addUsers(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.message,
                })
                getUserList();
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    const managePassword = async () => {
        setIsLoading(true)
        await generateOtpByUserName(userDetails.username)
            .then((resp) => {
                setIsLoading(false)
                setOpenVerifyOtpDialog({ action: true, userName: userDetails.username })
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                })
            })
    };


    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7, width: "80%" }}>
                    {isLoading ? (
                        <material.Stack spacing={1}>
                            <material.Skeleton variant="rounded" width="100%" height="85vh" />
                        </material.Stack>
                    ) : (
                        <div className='row'>
                            <div className="col-6">
                                <span><material.Typography variant="h5" className="headingText">{userRole === "ROLE_CORPORATE_EDITOR" ? `Users list for : ${userDetails.corporateName}` : "User Management"}</material.Typography></span>
                            </div>
                            <div className="col-6">
                                <span className='float-end'>
                                    <material.Button variant="contained" color='success' sx={{ textTransform: "none", mr: 1 }} onClick={managePassword} startIcon={<material.PasswordIcon />}>Manage Password</material.Button>
                                    <material.Button variant="contained" color='success' sx={{ textTransform: "none" }} onClick={addUser} startIcon={<material.PersonIcon />}>Add-User</material.Button>
                                </span>
                            </div>
                            <material.Paper sx={{ p: 2, mt: 2, }} elevation={3}>
                                <div className='col-12'>
                                    <material.TableContainer sx={{ maxHeight: 560 }}>
                                        <material.Table stickyHeader aria-label="sticky table">
                                            <material.TableHead >
                                                <material.TableRow>
                                                    <StyledTableCell>Name</StyledTableCell>
                                                    <StyledTableCell>User Name</StyledTableCell>
                                                    <StyledTableCell>Email</StyledTableCell>
                                                    <StyledTableCell>Corporate Name</StyledTableCell>
                                                    <StyledTableCell>Facility Name</StyledTableCell>
                                                    <StyledTableCell>Role</StyledTableCell>
                                                    <StyledTableCell>Status</StyledTableCell>
                                                    <StyledTableCell>Action</StyledTableCell>
                                                </material.TableRow>
                                            </material.TableHead>
                                            <material.TableBody>
                                                {usersData?.length ? usersData.map((row, i) => (
                                                    <material.TableRow key={i}>
                                                        <material.TableCell>{row.name}</material.TableCell>
                                                        <material.TableCell>{row.username}</material.TableCell>
                                                        <material.TableCell>{row.email}</material.TableCell>
                                                        <material.TableCell>{row.corporateName}</material.TableCell>
                                                        <material.TableCell>{row.facilityName}</material.TableCell>
                                                        <material.TableCell>{row.role?.split("_").slice(1).join(" ")}</material.TableCell>
                                                        <material.TableCell size='small'>{row.isActive ? (<p style={{ color: "green", fontWeight: "bold" }}>active</p>) : (<p style={{ color: "red", fontWeight: "bold" }}>In-active</p>)}</material.TableCell>
                                                        <material.TableCell className=" row" size='small'>
                                                            <div className="d-flex align-items-center">
                                                                <material.IconButton title={row.isActive ? "Active" : "In-active"} aria-label="visibility" size="small">
                                                                    <material.Switch {...label} checked={row.isActive} color='success' onClick={() => activeDeActiveUser(row)} />
                                                                </material.IconButton>
                                                                <material.IconButton title='Edit User' aria-label="create" size="small" onClick={() => editUser(row)}>
                                                                    <material.CreateIcon color='primary' />
                                                                </material.IconButton>
                                                                {/* <material.IconButton title='View User' aria-label="create" size="small" onClick={() => viewUsers(row)}>
                                                                    <material.VisibilityIcon color='primary' />
                                                                </material.IconButton> */}
                                                            </div>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )) : null}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                </div>
                            </material.Paper>
                        </div>
                    )}
                </material.Box>
            </material.Box>
            <VerifyOtpDialog
                openVerifyOtpDialog={openVerifyOtpDialog}
                setOpenVerifyOtpDialog={setOpenVerifyOtpDialog}
            />
            <AddUser
                openAddUser={openAddUser}
                setOpenAddUser={setOpenAddUser}
                getUserList={getUserList}
            />
            <EditOrViewUser
                openEditOrViewUser={openEditOrViewUser}
                setOpenEditOrViewUser={setOpenEditOrViewUser}
                getUserList={getUserList}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default UserManagemant;