import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { useState } from 'react';

function DynamicQuestionForm({ questions, save, cancel }) {
    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        watch,
    } = useForm({
        mode: 'onTouched',
        defaultValues: {
            questionsId: [],
        },
    });

    const [isHiddenSaveButton, setIsHiddenSaveButton] = useState(false);
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [formUpdate, SetFormUpdate] = useState(false);

    const usersRole = userDetails?.roles?.[0];

    useEffect(() => {
        const flattenQuestions = (questions) => {
            let allQuestions = [];
            const stack = [...questions];
            while (stack.length) {
                const current = stack.pop();
                allQuestions.push(current);
                if (current.child_questions && current.child_questions.length) {
                    stack.push(...current.child_questions);
                }
            }
            return allQuestions;
        };

        const allFlattenedQuestions = flattenQuestions(questions);

        allFlattenedQuestions.forEach((question) => {
            if (question.answer?.answers[0]?.sentForApproval) {
                setIsHiddenSaveButton(true);
            } else {
                setIsHiddenSaveButton(false);
            }
            const value = question.answer?.answers?.filter(ans => (ans.approvalStatus !== "APPROVED"))[0]?.answer || "";
            setValue(question.questionid, value);
        });

    }, [questions, setValue, formUpdate]);

    const renderChildQuestions = (childQuestions, questionAnsValue = null) => {
        if (childQuestions.length > 0) {
            if (questionAnsValue !== null) {
                return childQuestions.map((child, index) => (
                    <div key={index}>
                        {questionAnsValue === child.open_for ?
                            renderQuestions([child])
                            : null}
                    </div>
                ));
            } else {
                return childQuestions.map((child, index) => (
                    <div key={index}>
                        {renderQuestions([child])}
                    </div>
                ));
            }
        } else {
            return null;
        }
    };

    const renderQuestions = (questionsAll) => {
        return questionsAll?.map((question, index) => (
            <div key={index}>
                {console.log(question)}
                {question.field_type === 'RADIO' ? (
                    <material.FormControl component="fieldset" fullWidth margin="normal">
                        <material.FormLabel component="legend">{question.question}</material.FormLabel>
                        <Controller
                            name={question.questionid}
                            control={control}
                            defaultValue={question.answer?.answers?.filter(ans => (ans.approvalStatus !== "APPROVED"))[0].answer || ""}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <material.RadioGroup
                                    {...field}
                                    row
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (question.child_open_for === "YES") {
                                            if (value === "NO") {
                                                question.child_questions?.forEach((childQuestion) => {
                                                    setValue(childQuestion.questionid, "");
                                                });
                                            }
                                        } else {
                                            if (value === "YES") {
                                                question.child_questions?.forEach((childQuestion) => {
                                                    setValue(childQuestion.questionid, "");
                                                });
                                            }
                                        }
                                        setValue(question.questionid, value);
                                    }}
                                >
                                    {question.option_value?.split(',').map((option, index) => (
                                        <material.FormControlLabel
                                            key={index}
                                            value={option}
                                            control={<material.Radio color='secondary' />}
                                            label={option}
                                        />
                                    ))}
                                </material.RadioGroup>
                            )}
                        />
                    </material.FormControl>
                ) : question.field_type === 'TEXT_BOX' || question.field_type === 'OPTIONAL_TEXT_BOX' ? (
                    <Controller
                        name={question.questionid}
                        control={control}
                        defaultValue=""
                        rules={{
                            required: { value: true, message: 'This field is required' },
                            maxLength: { value: 4000, message: 'Maximum 4000 characters' },
                        }}
                        render={({ field }) => (
                            <>
                                <material.FormLabel component="legend" required>{question.question}</material.FormLabel>
                                <material.TextField
                                    required
                                    {...field}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={6}
                                />
                                {errors[question.questionid] && (
                                    <span style={{ color: 'red' }}>{errors[question.questionid].message}</span>
                                )}
                            </>
                        )}
                    />
                ) : question.field_type === 'HEADING' ? (
                    <material.Typography>{question.question}</material.Typography>
                ) : null}

                {question.child_questions && question.field_type === 'HEADING' && renderChildQuestions(question.child_questions)}
                {question.child_questions && question.field_type === 'TEXT_BOX' && renderChildQuestions(question.child_questions)}
                {question.child_questions && question.field_type === 'RADIO' && renderChildQuestions(question.child_questions, watch(question.questionid))}
                {/* {question.child_questions && renderChildQuestions(question.child_questions, watch(question.questionid))} */}
            </div>
        ));
    };

    const onSubmit = (data) => {
        let newObj = removeBlankProperties(data);
        save(newObj);
        SetFormUpdate(true);
    };

    const close = () => {
        cancel();
    };

    function removeBlankProperties(obj) {
        for (const key in obj) {
            if ((Array.isArray(obj[key]) && obj[key].length === 0)) {
                delete obj[key];
            }
        }
        return obj;
    };

    return (
        <>
            <material.DialogContent>
                <form>
                    {renderQuestions(questions)}
                </form>
            </material.DialogContent>
            <material.DialogActions sx={{ mr: 4 }} hidden={usersRole === "ROLE_FACILITY_EDITOR" || usersRole === "ROLE_FACILITY_APPROVER" || isHiddenSaveButton}>
                <material.Button
                    sx={{ mb: 2, textTransform: 'none' }}
                    variant="outlined"
                    color="success"
                    onClick={handleSubmit(onSubmit)}
                    startIcon={<material.DoneIcon />}
                >
                    Save
                </material.Button>
            </material.DialogActions>
        </>
    );
}

export default DynamicQuestionForm;
