import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { material } from '../../library/Material';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateIndigenousPeoplesRightsData } from '../../services/sub-issue-services/indigenous-peoples-services/IndigenousPeoplesRightsService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddIndigenousPeoplesRights(props) {

    const { openIndigenousPeoplesRights, setOpenIndigenousPeoplesRights, getIndigenousPeoplesRightsAllData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const { register, handleSubmit, reset, watch, getValues, control, setValue, formState: { errors }, } = useForm({ mode: "onTouched" });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    const onChangeNumberOfViolationsIncidents = watch('number_of_violations_incidents');
    const onChangeIncidentReviewed = watch('incident_reviewed');
    const onChangeRemediationPlanImplemented = watch('remediation_plan_implemented');
    const onChangeRemediationPlansReviewed = watch('remediation_plans_reviewed');
    const onChangeIncidentNoLongerSubjectToAction = watch('incident_no_longer_subject_to_action');

    useEffect(() => {
        totalCalculation();
    }, [onChangeNumberOfViolationsIncidents, onChangeIncidentReviewed, onChangeRemediationPlanImplemented, onChangeRemediationPlansReviewed, onChangeIncidentNoLongerSubjectToAction]);

    // const totalCalculation = () => {
    //     let numberOfViolationsIncidentsValue = getValues('number_of_violations_incidents');
    //     let incidentReviewedValue = getValues('incident_reviewed');
    //     let remediationPlanImplementedValue = getValues('remediation_plan_implemented');
    //     let remediationPlansReviewedValue = getValues('remediation_plans_reviewed');
    //     let incidentNoLongerSubjectToActionValue = getValues('incident_no_longer_subject_to_action');
    //     if (Number(incidentReviewedValue) <= Number(numberOfViolationsIncidentsValue)) {
    //         setOpenSnackBar({
    //             "action": true,
    //             "type": "warning",
    //             "message": "Incident reviewed value not greater than Number of violations incidents value",
    //         })
    //         setButtonDisable(true)
    //     } else if (Number(remediationPlanImplementedValue) <= Number(numberOfViolationsIncidentsValue)) {
    //         // setOpenSnackBar({
    //         //     "action": true,
    //         //     "type": "warning",
    //         //     "message": "Remediation plan implemented value not greater than Number of violations incidents value",
    //         // })
    //         setButtonDisable(true)
    //     } else if (Number(remediationPlansReviewedValue) <= Number(numberOfViolationsIncidentsValue)) {
    //         // setOpenSnackBar({
    //         //     "action": true,
    //         //     "type": "warning",
    //         //     "message": "Remediation plans reviewed value not greater than Number of violations incidents value",
    //         // })
    //         setButtonDisable(true)
    //     } else if (Number(incidentNoLongerSubjectToActionValue) <= Number(numberOfViolationsIncidentsValue)) {
    //         // setOpenSnackBar({
    //         //     "action": true,
    //         //     "type": "warning",
    //         //     "message": "Incident no longer subject to action value not greater than Number of violations incidents value",
    //         // })
    //         setButtonDisable(true)
    //     } else {
    //         setOpenSnackBar({
    //             "action": true,
    //             "type": "warning",
    //             "message": "Remediation plans reviewed value not greater than Number of violations incidents value",
    //         })
    //         setButtonDisable(false)
    //     }
    // };
    const totalCalculation = () => {
        let numberOfViolationsIncidentsValue = getValues('number_of_violations_incidents');
        let incidentReviewedValue = getValues('incident_reviewed');
        let remediationPlanImplementedValue = getValues('remediation_plan_implemented');
        let remediationPlansReviewedValue = getValues('remediation_plans_reviewed');
        let incidentNoLongerSubjectToActionValue = getValues('incident_no_longer_subject_to_action');

        if (
            Number(incidentReviewedValue) > Number(numberOfViolationsIncidentsValue) ||
            Number(remediationPlanImplementedValue) > Number(numberOfViolationsIncidentsValue) ||
            Number(remediationPlansReviewedValue) > Number(numberOfViolationsIncidentsValue) ||
            Number(incidentNoLongerSubjectToActionValue) > Number(numberOfViolationsIncidentsValue)
        ) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Some value is not greater than Number of violations incidents value",
            })
            setButtonDisable(true);
        } else {
            // setOpenSnackBar({
            //     "action": true,
            //     "type": "warning",
            //     "message": "Some value is not greater than Number of violations incidents value",
            // })
            setButtonDisable(false);
        }
    };

    useEffect(() => {
        if (openIndigenousPeoplesRights.action && openIndigenousPeoplesRights.indigenousPeoplesRightsTableData) {
            reset(openIndigenousPeoplesRights.indigenousPeoplesRightsTableData)
        }
    }, [openIndigenousPeoplesRights.action === true])

    const handleClose = () => {
        reset({ number_of_violations_incidents: "", incident_reviewed: "", remediation_plan_implemented: "", remediation_plans_reviewed: "", incident_no_longer_subject_to_action: "" });
        setOpenIndigenousPeoplesRights({ action: false, indigenousPeoplesRightsTableData: null });
    };

    const saveChildAndForcedLabour = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "number_of_violations_incidents": Number(data.number_of_violations_incidents),
                "incident_reviewed": Number(data.incident_reviewed),
                "remediation_plan_implemented": Number(data.remediation_plan_implemented),
                "remediation_plans_reviewed": Number(data.remediation_plans_reviewed),
                "incident_no_longer_subject_to_action": Number(data.incident_no_longer_subject_to_action),
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getIndigenousPeoplesRightsAllData(getFilterDate, payloadData);
                    reset({ number_of_violations_incidents: "", incident_reviewed: "", remediation_plan_implemented: "", remediation_plans_reviewed: "", incident_no_longer_subject_to_action: "" });
                    setOpenIndigenousPeoplesRights({ action: false, indigenousPeoplesRightsTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateChildAndForcedLabour = async (data) => {
        const refNo = openIndigenousPeoplesRights.indigenousPeoplesRightsTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "number_of_violations_incidents": Number(data.number_of_violations_incidents),
            "incident_reviewed": Number(data.incident_reviewed),
            "remediation_plan_implemented": Number(data.remediation_plan_implemented),
            "remediation_plans_reviewed": Number(data.remediation_plans_reviewed),
            "incident_no_longer_subject_to_action": Number(data.incident_no_longer_subject_to_action),
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getIndigenousPeoplesRightsAllData(getFilterDate, payloadData);
                reset({ number_of_violations_incidents: "", incident_reviewed: "", remediation_plan_implemented: "", remediation_plans_reviewed: "", incident_no_longer_subject_to_action: "" });
                setOpenIndigenousPeoplesRights({ action: false, indigenousPeoplesRightsTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openIndigenousPeoplesRights.indigenousPeoplesRightsTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                getIndigenousPeoplesRightsAllData(getFilterDate, payloadData);
                reset({ number_of_violations_incidents: "", incident_reviewed: "", remediation_plan_implemented: "", remediation_plans_reviewed: "", incident_no_longer_subject_to_action: "" });
                setOpenIndigenousPeoplesRights({ action: false, indigenousPeoplesRightsTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <>
            <material.Dialog open={openIndigenousPeoplesRights.action} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold headingText'>
                    {openIndigenousPeoplesRights.indigenousPeoplesRightsTableData ? "Edit Indigenous Peoples Rights" : "Add Indigenous Peoples Rights"}
                </material.DialogTitle>
                <material.DialogContent>
                    {openIndigenousPeoplesRights.indigenousPeoplesRightsTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row align-items-center'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Indigenous Peoples Rights Data</material.Typography>
                            </div>
                            <div className='col-md-4 py-2'>
                                <material.TextField
                                    {...register("number_of_violations_incidents", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Number of Violations Incidents"
                                    id="number_of_violations_incidents"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.number_of_violations_incidents?.type === "required" && 'This field is required'}</p>
                                {errors.number_of_violations_incidents && errors.number_of_violations_incidents.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                            <div className='col-md-4 py-2'>
                                <material.TextField
                                    {...register("incident_reviewed", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Incident Reviewed"
                                    id="incident_reviewed"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.incident_reviewed?.type === "required" && 'This field is required'}</p>
                                {errors.incident_reviewed && errors.incident_reviewed.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                            <div className='col-md-4 py-2'>
                                <material.TextField
                                    {...register("remediation_plan_implemented", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Remediation Plan Implemented"
                                    id="remediation_plan_implemented"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.remediation_plan_implemented?.type === "required" && 'This field is required'}</p>
                                {errors.remediation_plan_implemented && errors.remediation_plan_implemented.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                            <div className='col-md-4 py-2'>
                                <material.TextField
                                    {...register("remediation_plans_reviewed", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Remediation Plans Reviewed"
                                    id="remediation_plans_reviewed"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.remediation_plans_reviewed?.type === "required" && 'This field is required'}</p>
                                {errors.remediation_plans_reviewed && errors.remediation_plans_reviewed.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                            <div className='col-md-4 py-2'>
                                <material.TextField
                                    {...register("incident_no_longer_subject_to_action", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Incident no Longer Subject to Action"
                                    id="incident_no_longer_subject_to_action"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.incident_no_longer_subject_to_action?.type === "required" && 'This field is required'}</p>
                                {errors.incident_no_longer_subject_to_action && errors.incident_no_longer_subject_to_action.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openIndigenousPeoplesRights.indigenousPeoplesRightsTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} disabled={buttonDisable} onClick={handleSubmit(updateChildAndForcedLabour)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openIndigenousPeoplesRights.indigenousPeoplesRightsTableData.rejection_note === null) || (openIndigenousPeoplesRights.indigenousPeoplesRightsTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} disabled={buttonDisable} onClick={handleSubmit(saveChildAndForcedLabour)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </>
    )
}

export default AddIndigenousPeoplesRights;