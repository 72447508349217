import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getDisclosuresQuestion, getGeneralDisclosuresHistory } from '../../services/GeneralDisclosureService';
import Snackbar from '../../toaster-message/Snackbar';

var usersRole;

function SpecificDisclosureHistory(props) {

    const { treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };
    const [specificDisclosureQuestions, setSpecificDisclosureQuestions] = useState([]);
    const [specificDisclosureAns, setSpecificDisclosureAns] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getSpecificDisclosuresData()
    }, []);

    const getSpecificDisclosuresData = () => {
        let questionId;
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "pillar": treeViewData?.pillar,
            "issue": treeViewData?.subIssue,
            "typeOfDisclosure": "SPECIFIC_DISCLOSURE"
        }
        Promise.all([getDisclosuresQuestion(treeViewData?.subIssue, "SPECIFIC_DISCLOSURE"), getGeneralDisclosuresHistory(obj)]).then((responses) => {
            // Handle the responses for each API request
            const allQuestions = responses[0].data;
            const allAnswers = responses[1].data;
            if (allAnswers.length > 0) {
                setSpecificDisclosureAns(allAnswers)
                // allAnswers[0].answers?.forEach(element => {
                //     if (element.approvalStatus === "APPROVED") {
                //         setAllowNewDisclosure(true);
                //     } else {
                //         setAllowNewDisclosure(false);
                //     }
                //     setHideButton(element.sentForApproval)
                // });
            } else {
                setSpecificDisclosureAns(allAnswers)
                // setAllowNewDisclosure(true);
            };

            // Create a data structure to associate questions with answers using question ID
            // Create a map to store answers based on question IDs
            const answersMap = new Map();
            // Populate the answers map
            allAnswers.forEach(answer => {
                const questionId = answer.questionId;
                answersMap.set(questionId, answer);
            });
            // Create a new array by adding the 'answer' property to each question
            const questionsWithAnswers = allQuestions.map(question => {
                const questionId = question.questionid;
                const answer = answersMap.get(questionId);
                // Add an 'answer' property to the question object
                question.answer = answer ? answer : null;
                return question;
            });
            const questionnaireStructure = [];
            questionsWithAnswers.forEach((question, index) => {
                const { questionid, parent_question_no } = question;
                const isChild = parent_question_no !== null;
                if (!isChild) {
                    const childQuestions = questionsWithAnswers.filter((child) => child.parent_question_no === questionid);
                    questionnaireStructure.push({
                        ...question,
                        child_questions: childQuestions.length > 0 ? childQuestions : null,
                    });
                }
            });
            // Log the resulting array
            setSpecificDisclosureQuestions(questionnaireStructure)
        })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            });
    };

    return (
        <>
            <div>
                <material.TableContainer>
                    <material.Table>
                        <material.TableHead>
                            <material.TableRow style={{ border: "2px solid blue" }}>
                                <StyledTableCell align="center" style={{ border: "2px solid black" }} >Question</StyledTableCell>
                                <StyledTableCell align="center" style={{ width: "430px", border: "2px solid black" }}  >Answer</StyledTableCell>
                                <StyledTableCell align="left" style={{ border: "2px solid black" }}>Approval Date</StyledTableCell>
                                <StyledTableCell align="center" style={{ border: "2px solid black" }}>Status</StyledTableCell>
                            </material.TableRow>
                        </material.TableHead>
                        <material.TableBody>{console.log(specificDisclosureQuestions)}
                            {specificDisclosureAns.length > 0 && specificDisclosureQuestions.length > 0 ? specificDisclosureQuestions.map((element, i) => (
                                element.field_type === "HEADING" ? (
                                    <>
                                        <material.TableRow key={i} style={{ border: "2px solid black" }}>
                                            <material.TableCell colSpan={5}>
                                                <div >{element.question} :</div>
                                            </material.TableCell>
                                        </material.TableRow>
                                        {element.child_questions != null ? element.child_questions.map((childQues, qId) => (
                                            <>
                                                {childQues.field_type === "HEADING" ? (
                                                    <material.TableRow key={qId} style={{ border: "2px solid black" }}>
                                                        <material.TableCell colSpan={5}>
                                                            <div >{childQues.question} :</div>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                ) : (
                                                    <material.TableRow key={qId} style={{ border: "2px solid black" }}>
                                                        <material.TableCell style={{ width: "300px" }}  >{childQues.question}</material.TableCell>

                                                        <material.TableCell rowSpan={childQues.answer !== null && childQues.answer?.answers.length > 1 ? childQues.answer?.answers.length : null} style={{ display: childQues.answer !== null && childQues.answer?.answers.length > 1 ? "flex" : null, flexDirection: "column", borderLeft: '1px solid black', borderRight: '1px solid black' }}>
                                                            {childQues.answer != null ? childQues.answer.answers.map((ans, ansInd) => (
                                                                <div style={{ width: "430px", borderBottom: childQues.answer?.answers.length - 1 !== ansInd ? '1px solid black' : null }} className='break-long-text py-4' > {ans.answer ? ans.answer : 'N/A'}</div>
                                                            )) : (<material.TableRow>N/A</material.TableRow>)}
                                                        </material.TableCell>

                                                        <material.TableCell align='center' style={{ borderRight: '1px solid black', marginTop: '2px' }}>
                                                            {childQues.answer?.answers.map((ans, ansInd) => (
                                                                <div style={{ borderBottom: childQues.answer !== null && childQues.answer?.answers.length - 1 !== ansInd ? '1px solid black' : null }} className='break-long-text py-4' >{ans.approvalDate ? moment(ans.approvalDate).format("DD-MM-YYYY") : 'Not Approved'}</div>
                                                            ))}
                                                        </material.TableCell>

                                                        <material.TableCell align='center' style={{ borderRight: '1px solid black', marginTop: '2px', height: 200 }}>
                                                            {element.answer?.answers.map((ans, ansInd) => (
                                                                <div style={{ borderBottom: element.answer !== null && element.answer?.answers.length - 1 !== ansInd ? '1px solid black' : null }} className='py-4' >
                                                                    {ans.approvalStatus === "APPROVED" ? <span className="badge bg-success">
                                                                        APPROVED
                                                                    </span> : null}
                                                                </div>
                                                            ))}
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </>

                                        )) : null
                                        }
                                    </>
                                ) : (
                                    <>
                                        <material.TableRow key={i} style={{ border: "2px solid black" }}>
                                            <material.TableCell>
                                                <div style={{ width: "300px" }} >{element.question} :</div>
                                            </material.TableCell>
                                            <material.TableCell rowSpan={element.answer !== null && element.answer?.answers?.length > 1 ? element.answer?.answers?.length : null} style={{ display: element.answer !== null && element.answer?.answers.length > 1 ? "flex" : null, flexDirection: "column", borderLeft: '1px solid black', borderRight: '1px solid black' }}>
                                                {element.answer && element.answer?.answers?.map((ans, ansInd) => (
                                                    <div className='break-long-text py-4' style={{ width: "430px", borderBottom: element.answer !== null && element.answer?.answers.length - 1 !== ansInd ? '1px solid black' : null }} key={ansInd}> {ans.answer ? ans.answer : 'N/A'}</div>
                                                ))}
                                            </material.TableCell>
                                            <material.TableCell align='center' style={{ borderRight: '1px solid black' }} >
                                                {element.answer && element.answer?.answers?.map((ans, ansInd) => (
                                                    <div style={{ borderBottom: element.answer !== null && element.answer?.answers.length - 1 !== ansInd ? '1px solid black' : null }} className='py-4'>{ans.approvalDate ? moment(ans.approvalDate).format("DD-MM-YYYY") : 'Not Approved'}</div>
                                                ))}
                                            </material.TableCell>

                                            <material.TableCell align='center' style={{ borderRight: '1px solid black' }}>
                                                {element.answer?.answers.map((ans, ansInd) => (
                                                    <div style={{ borderBottom: element.answer !== null && element.answer?.answers.length - 1 !== ansInd ? '1px solid black' : null }} className='py-4' >
                                                        {ans.approvalStatus === "APPROVED" ? <span className="badge bg-success">
                                                            APPROVED
                                                        </span> : null}
                                                    </div>
                                                ))}
                                            </material.TableCell>
                                        </material.TableRow>
                                        {element.child_questions != null ? element.child_questions.map((childQues, qId) => (
                                            <material.TableRow key={qId} style={{ border: "2px solid black" }}>
                                                {childQues.field_type === "HEADING" ? (
                                                    <material.TableCell colSpan={5}>
                                                        <div >{childQues.question} :</div>
                                                    </material.TableCell>
                                                ) : (
                                                    <>
                                                        <material.TableCell style={{ width: "300px" }}  >{childQues.question}</material.TableCell>

                                                        <material.TableCell rowSpan={childQues.answer !== null && childQues.answer?.answers.length > 1 ? childQues.answer?.answers.length : null} style={{ display: childQues.answer !== null && childQues.answer?.answers.length > 1 ? "flex" : null, flexDirection: "column", borderLeft: '1px solid black', borderRight: '1px solid black' }}>
                                                            {childQues.answer != null ? childQues.answer.answers.map((ans, ansInd) => (
                                                                <div style={{ width: "430px", borderBottom: childQues.answer?.answers.length - 1 !== ansInd ? '1px solid black' : null }} className='break-long-text py-4' > {ans.answer ? ans.answer : 'N/A'}</div>
                                                            )) : (<material.TableRow>N/A</material.TableRow>)}
                                                        </material.TableCell>

                                                        <material.TableCell align='center' style={{ borderRight: '1px solid black', marginTop: '2px' }}>
                                                            {childQues.answer?.answers.map((ans, ansInd) => (
                                                                <div style={{ borderBottom: childQues.answer !== null && childQues.answer?.answers.length - 1 !== ansInd ? '1px solid black' : null }} className='py-4' >{ans.approvalDate ? moment(ans.approvalDate).format("DD-MM-YYYY") : 'Not Approved'}</div>
                                                            ))}
                                                        </material.TableCell>

                                                        <material.TableCell align='center' style={{ borderRight: '1px solid black' }}>
                                                            {childQues.answer?.answers.map((ans, ansInd) => (
                                                                <div style={{ borderBottom: childQues.answer !== null && childQues.answer?.answers.length - 1 !== ansInd ? '1px solid black' : null }} className='py-4' >
                                                                    {ans.approvalStatus === "APPROVED" ? <span className="badge bg-success">
                                                                        APPROVED
                                                                    </span> : null}
                                                                </div>
                                                            ))}
                                                        </material.TableCell>
                                                    </>
                                                )}
                                            </material.TableRow>

                                        )) : null
                                        }
                                    </>
                                )
                            ))
                                : (
                                    <material.TableRow >
                                        <material.TableCell colSpan={10}>
                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                    </material.TableRow>
                                )}
                        </material.TableBody>
                    </material.Table>
                </material.TableContainer>
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
            </div>
        </>
    )
}

export default SpecificDisclosureHistory;