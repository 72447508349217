import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { material } from '../../library/Material';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateEmployeesWorkersWellBeingData } from '../../services/sub-issue-services/employees-workers-well-being-service/EmployeesWorkers-WellBeingService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddEmployeesWorkersWellBeing(props) {
    const { openEmployeesWorkersWellBeing, setOpenEmployeesWorkersWellBeing, getAllEmployeesWorkersWellBeingData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, watch, getValues, setValue, formState: { errors }, } = useForm({ mode: "onTouched" });
    const [categoryOfWorker, setCategoryOfWorker] = useState("");
    const [gender, setGender] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const payload = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openEmployeesWorkersWellBeing.action && openEmployeesWorkersWellBeing.employeesWorkersWellBeingTableData) {
            setCategoryOfWorker(openEmployeesWorkersWellBeing.employeesWorkersWellBeingTableData.category_of_worker);
            setGender(openEmployeesWorkersWellBeing.employeesWorkersWellBeingTableData.gender);
            reset(openEmployeesWorkersWellBeing.employeesWorkersWellBeingTableData);
        }
    }, [openEmployeesWorkersWellBeing.action === true]);

    const handleClose = () => {
        reset({ health_insurance: "", accident_insurance: "", maternity_benefits: "", paternity_benefits: "", day_care_facilities: "", life_insurance: "", stock_ownership: "", retirement_provision: "", disability_and_invalidity_coverage: "", total: "", });
        setCategoryOfWorker("");
        setGender("");
        setOpenEmployeesWorkersWellBeing({ action: false, employeesWorkersWellBeingTableData: null });
    };


    const saveEmployeesWorkersWellBeing = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "category_of_worker": data.category_of_worker,
                "gender": data.gender,
                "health_insurance": Number(data.health_insurance),
                "accident_insurance": Number(data.accident_insurance),
                "maternity_benefits": Number(gender === "Male" || gender === "Other" ? 0 : data.maternity_benefits),
                "paternity_benefits": Number(gender === "Female" || gender === "Other" ? 0 : data.paternity_benefits),
                "day_care_facilities": Number(data.day_care_facilities),
                "life_insurance": Number(data.life_insurance),
                "stock_ownership": Number(data.stock_ownership),
                "retirement_provision": Number(data.retirement_provision),
                "disability_and_invalidity_coverage": Number(data.disability_and_invalidity_coverage),
                "total": Number(data.total),
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllEmployeesWorkersWellBeingData(getFilterDate, payload);
                    setCategoryOfWorker("");
                    setGender("");
                    reset({ health_insurance: "", accident_insurance: "", maternity_benefits: "", paternity_benefits: "", day_care_facilities: "", life_insurance: "", stock_ownership: "", retirement_provision: "", disability_and_invalidity_coverage: "", total: "", });
                    setOpenEmployeesWorkersWellBeing({ action: false, employeesWorkersWellBeingTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateEmployeesWorkersWellBeing = async (data) => {
        const refNo = openEmployeesWorkersWellBeing.employeesWorkersWellBeingTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "category_of_worker": data.category_of_worker,
            "gender": data.gender,
            "health_insurance": Number(data.health_insurance),
            "accident_insurance": Number(data.accident_insurance),
            "maternity_benefits": Number(gender === "Male" || gender === "Other" ? 0 : data.maternity_benefits),
            "paternity_benefits": Number(gender === "Female" || gender === "Other" ? 0 : data.paternity_benefits),
            "day_care_facilities": Number(data.day_care_facilities),
            "life_insurance": Number(data.life_insurance),
            "stock_ownership": Number(data.stock_ownership),
            "retirement_provision": Number(data.retirement_provision),
            "disability_and_invalidity_coverage": Number(data.disability_and_invalidity_coverage),
            "total": Number(data.total),
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllEmployeesWorkersWellBeingData(getFilterDate, payload);
                setCategoryOfWorker("");
                setGender("");
                reset({ health_insurance: "", accident_insurance: "", maternity_benefits: "", paternity_benefits: "", day_care_facilities: "", life_insurance: "", stock_ownership: "", retirement_provision: "", disability_and_invalidity_coverage: "", total: "", });
                setOpenEmployeesWorkersWellBeing({ action: false, employeesWorkersWellBeingTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };


    const changeCategoryOfWorker = (event) => {
        setCategoryOfWorker(event.target.value);
    };
    const changeGender = (event) => {
        setGender(event.target.value);
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openEmployeesWorkersWellBeing.employeesWorkersWellBeingTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ health_insurance: "", accident_insurance: "", maternity_benefits: "", paternity_benefits: "", day_care_facilities: "", life_insurance: "", stock_ownership: "", retirement_provision: "", disability_and_invalidity_coverage: "", total: "", });
                setCategoryOfWorker("");
                setGender("");
                getAllEmployeesWorkersWellBeingData(getFilterDate, payload);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenEmployeesWorkersWellBeing({ action: false, employeesWorkersWellBeingTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <>
            <material.Dialog open={openEmployeesWorkersWellBeing.action} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold headingText'>
                    {openEmployeesWorkersWellBeing.employeesWorkersWellBeingTableData ? "Edit Employee & Worker Well Being" : "Add Employee & Worker Well Being"}
                </material.DialogTitle>
                <material.DialogContent>
                    {openEmployeesWorkersWellBeing.employeesWorkersWellBeingTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 1, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography><h5 className='fw-bold headingText'>Employees & Workers Well Being</h5></material.Typography>
                            </div>
                            <div className='col-4 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Category of Employee & Worker</material.InputLabel>
                                    <material.Select
                                        {...register("category_of_worker", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={categoryOfWorker}
                                        onChange={changeCategoryOfWorker}
                                        label="Category of Worker"
                                    >
                                        <material.MenuItem value={"Employee Permanent"}>Employee Permanent</material.MenuItem>
                                        <material.MenuItem value={"Employee other than Permanent"}>Employee other than Permanent</material.MenuItem>
                                        <material.MenuItem value={"Workers Permanent"}>Worker Permanent</material.MenuItem>
                                        <material.MenuItem value={"Workers other than Permanent"}>Worker other than Permanent</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Gender</material.InputLabel>
                                    <material.Select
                                        {...register("gender", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={gender}
                                        onChange={changeGender}
                                        label="Gender"
                                    >
                                        <material.MenuItem value={"Male"}>Male</material.MenuItem>
                                        <material.MenuItem value={"Female"}>Female</material.MenuItem>
                                        <material.MenuItem value={"Other"}>Other</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("health_insurance", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Health  Insurance"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.health_insurance && errors.health_insurance.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("accident_insurance", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Accident Insurance"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.accident_insurance && errors.accident_insurance.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            {gender === "Other" ? null : (
                                <>
                                    {gender === "Male" ? (
                                        <div className='col-4 py-2'>
                                            <material.TextField
                                                {...register("paternity_benefits", { required: true, pattern: /^[0-9]+$/ })}
                                                required
                                                label="Paternity Benefits"
                                                id="userName"
                                                variant="standard"
                                                type='number'
                                                size="small"
                                                fullWidth
                                            />
                                            {errors.paternity_benefits && errors.paternity_benefits.type === "pattern" && (
                                                <p className="errorMsg text-danger">Allowed only number.</p>
                                            )}
                                        </div>
                                    ) : null}
                                    {gender === "Female" ? (
                                        <div className='col-4 py-2'>
                                            <material.TextField
                                                {...register("maternity_benefits", { required: true, pattern: /^[0-9]+$/ })}
                                                required
                                                label="Maternity Benefits"
                                                id="userName"
                                                variant="standard"
                                                type='number'
                                                size="small"
                                                fullWidth
                                            />
                                            {errors.maternity_benefits && errors.maternity_benefits.type === "pattern" && (
                                                <p className="errorMsg text-danger">Allowed only number.</p>
                                            )}
                                        </div>
                                    ) : null}
                                </>
                            )}
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("day_care_facilities", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Day Care Facilities"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.day_care_facilities && errors.day_care_facilities.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("life_insurance", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Life Insurance"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.life_insurance && errors.life_insurance.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("stock_ownership", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Stock Ownership"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.stock_ownership && errors.stock_ownership.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("retirement_provision", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Retirement Provision"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.retirement_provision && errors.retirement_provision.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div><div className='col-4 py-2'>
                                <material.TextField
                                    {...register("disability_and_invalidity_coverage", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Disability and Invalidity Coverage"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.disability_and_invalidity_coverage && errors.disability_and_invalidity_coverage.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            {/* <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("total", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Total"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.total && errors.total.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div> */}
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openEmployeesWorkersWellBeing.employeesWorkersWellBeingTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none" }} onClick={handleSubmit(updateEmployeesWorkersWellBeing)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openEmployeesWorkersWellBeing.employeesWorkersWellBeingTableData.rejection_note === null) || (openEmployeesWorkersWellBeing.employeesWorkersWellBeingTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none" }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveEmployeesWorkersWellBeing)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </>
    )
}

export default AddEmployeesWorkersWellBeing;