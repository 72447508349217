import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { material } from '../../library/Material';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateProductionEfficiencyData } from '../../services/sub-issue-services/energy/EnergyEfficiencyProductionService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddEnergyEfficiencyProduction(props) {

    const { openEnergyEfficiencyProduction, setOpenEnergyEfficiencyProduction, getEfficiencyProductionData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, watch, getValues, setValue, formState: { errors }, } = useForm({ mode: "onTouched" });
    const [reduction_initiatives, setReductionInitiatives] = useState("");
    const [types_of_energy_included, setTypesOfEnergyIncluded] = useState("");
    const [unit, setUnit] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openEnergyEfficiencyProduction.action && openEnergyEfficiencyProduction.energyEfficiencyProductionData) {
            setReductionInitiatives(openEnergyEfficiencyProduction.energyEfficiencyProductionData.reduction_initiatives)
            setTypesOfEnergyIncluded(openEnergyEfficiencyProduction.energyEfficiencyProductionData.types_of_energy_included)
            setUnit(openEnergyEfficiencyProduction.energyEfficiencyProductionData.unit)
            reset(openEnergyEfficiencyProduction.energyEfficiencyProductionData)
        };
    }, [openEnergyEfficiencyProduction.action === true]);

    const handleClose = () => {
        setReductionInitiatives("");
        setTypesOfEnergyIncluded("");
        setUnit("");
        reset({ amount: "" });
        setOpenEnergyEfficiencyProduction({ action: false, energyEfficiencyProductionData: null });
    };
    const changeReductionInitiatives = (event) => {
        setReductionInitiatives(event.target.value)
    };
    const changeTypesOfEnergyIncluded = (event) => {
        setTypesOfEnergyIncluded(event.target.value);
    };
    const changeUnit = (event) => {
        setUnit(event.target.value);
    };
    const saveEnergyEfficiencyProduction = (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                // "applicable": true,
                // refNo: "",
                "reduction_initiatives": data.reduction_initiatives,
                "types_of_energy_included": data.types_of_energy_included,
                "amount": data.amount,
                "unit": data.unit,
                "achieved_of_conservation_and_efficiency_initiatives": true,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            saveSubIssueData(object, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getEfficiencyProductionData(getFilterDate, payloadData);
                    setReductionInitiatives("");
                    setTypesOfEnergyIncluded("");
                    setUnit("");
                    reset({ amount: "" });
                    setOpenEnergyEfficiencyProduction({ action: false, energyEfficiencyProductionData: null });

                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateEnergyEfficiencyProduction = (data) => {
        const refNo = openEnergyEfficiencyProduction.energyEfficiencyProductionData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let object = {
            // "corporateRefNo": userDetails.corporateRefNo,
            // "facilityRefNo": userDetails.facilityRefNo,
            // "applicable": true,
            // refNo: openEnergyEfficiencyProduction.energyEfficiencyProductionData.refNo,
            "reduction_initiatives": data.reduction_initiatives,
            "types_of_energy_included": data.types_of_energy_included,
            "amount": data.amount,
            "unit": data.unit,
            "achieved_of_conservation_and_efficiency_initiatives": true,
            // toDate: data.toDate,
            // fromDate: data.fromDate
        }
        updateSubIssueData(object, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getEfficiencyProductionData(getFilterDate, payloadData);
                setReductionInitiatives("");
                setTypesOfEnergyIncluded("");
                setUnit("");
                reset({ amount: "" });
                setOpenEnergyEfficiencyProduction({ action: false, energyEfficiencyProductionData: null });

            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openEnergyEfficiencyProduction.energyEfficiencyProductionData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        await commonDelete(payload)
            .then((resp) => {
                getEfficiencyProductionData(getFilterDate, payloadData);
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                setReductionInitiatives("");
                setTypesOfEnergyIncluded("");
                setUnit("");
                reset({ amount: "" });
                setOpenEnergyEfficiencyProduction({ action: false, energyEfficiencyProductionData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={openEnergyEfficiencyProduction.action} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold headingText'>
                    {openEnergyEfficiencyProduction.energyEfficiencyProductionData ? "Edit Energy Efficiency Production" : "Add Energy Efficiency Production"}
                </material.DialogTitle>
                <material.DialogContent>
                    {openEnergyEfficiencyProduction.energyEfficiencyProductionData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Energy Efficiency Production Data</material.Typography>
                            </div>
                            <div className='col-md-3 py-2'>
                                <material.FormControl required variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Reduction Initiatives</material.InputLabel>
                                    <material.Select
                                        {...register("reduction_initiatives", { required: true, })}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={reduction_initiatives}
                                        onChange={changeReductionInitiatives}
                                        label="Reduction Initiatives"
                                    >
                                        <material.MenuItem value={"Process Redesign"}>Process Redesign</material.MenuItem>
                                        <material.MenuItem value={" Conversion and Retrofitting of Equipment"}> Conversion and Retrofitting of Equipment</material.MenuItem>
                                        <material.MenuItem value={" Changes in Behavior"}> Changes in Behavior</material.MenuItem>
                                        <material.MenuItem value={" Operational Changes"}> Operational Changes</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-md-3 py-2'>
                                <material.FormControl required variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Types of Energy Included</material.InputLabel>
                                    <material.Select
                                        {...register("types_of_energy_included", { required: true, })}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={types_of_energy_included}
                                        onChange={changeTypesOfEnergyIncluded}
                                        label="Types of Energy Included"
                                    >
                                        <material.MenuItem value={"Fuel"}>Fuel</material.MenuItem>
                                        <material.MenuItem value={"Electricity"}>Electricity</material.MenuItem>
                                        <material.MenuItem value={"Heating"}>Heating</material.MenuItem>
                                        <material.MenuItem value={"Cooling"}>Cooling</material.MenuItem>
                                        <material.MenuItem value={"Steam"}>Steam</material.MenuItem>
                                        <material.MenuItem value={"All"}>All</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-md-3 py-2'>
                                <material.TextField
                                    {...register("amount", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Quantity"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                {errors.amount && errors.amount.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-md-3 py-2'>
                                <material.FormControl required variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Unit</material.InputLabel>
                                    <material.Select
                                        {...register("unit", { required: true, })}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={unit}
                                        onChange={changeUnit}
                                        label="Unit"
                                    >
                                        <material.MenuItem value={"J"}>J</material.MenuItem>
                                        <material.MenuItem value={"GJ"}>GJ</material.MenuItem>
                                        <material.MenuItem value={"TJ"}>TJ</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openEnergyEfficiencyProduction.energyEfficiencyProductionData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateEnergyEfficiencyProduction)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openEnergyEfficiencyProduction.energyEfficiencyProductionData.rejection_note === null) || (openEnergyEfficiencyProduction.energyEfficiencyProductionData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveEnergyEfficiencyProduction)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddEnergyEfficiencyProduction;