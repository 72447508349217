import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { material } from '../../library/Material';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddEmissionExportDialog(props) {

    const { openOdsEmissionExport, setOpenOdsEmissionExport, getAllOdsEmissionExportData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [unitCfc, setUnitCfc] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openOdsEmissionExport.action && openOdsEmissionExport.odsEmissionExportTableData) {
            setUnitCfc(openOdsEmissionExport.odsEmissionExportTableData.unit_cfc);
            reset(openOdsEmissionExport.odsEmissionExportTableData);
        }
    }, [openOdsEmissionExport.action === true]);

    const handleClose = () => {
        setUnitCfc("");
        reset({ "substances": "", "country_of_destination_of_exports": "", "total_quant_new_imported_for_all_uses": "", "total_quant_recovered_imported_for_all_uses": "", "quant_new_substances_imported_as_feedstock": "", "quant_new_substances_imported_essential_uses": "", });
        setOpenOdsEmissionExport({ action: false, odsEmissionExportTableData: null });
    };

    const selectUnit = (event) => {
        setUnitCfc(event.target.value);
    };

    const saveEmissionExport = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue);
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "substances": data.substances,
                "country_of_destination_of_exports": data.country_of_destination_of_exports,
                "total_quant_new_imported_for_all_uses": data.total_quant_new_imported_for_all_uses,
                "total_quant_recovered_imported_for_all_uses": data.total_quant_recovered_imported_for_all_uses,
                "quant_new_substances_imported_as_feedstock": data.quant_new_substances_imported_as_feedstock,
                "quant_new_substances_imported_essential_uses": data.quant_new_substances_imported_essential_uses,
                "unit_cfc": data.unit_cfc,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
                "other_specify": null
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllOdsEmissionExportData(getFilterDate, paginationData);
                    setUnitCfc("");
                    reset({ "substances": "", "country_of_destination_of_exports": "", "total_quant_new_imported_for_all_uses": "", "total_quant_recovered_imported_for_all_uses": "", "quant_new_substances_imported_as_feedstock": "", "quant_new_substances_imported_essential_uses": "", });
                    setOpenOdsEmissionExport({ action: false, odsEmissionExportTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateEmissionExport = async (data) => {
        const refNo = openOdsEmissionExport.odsEmissionExportTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "substances": data.substances,
            "country_of_destination_of_exports": data.country_of_destination_of_exports,
            "total_quant_new_imported_for_all_uses": data.total_quant_new_imported_for_all_uses,
            "total_quant_recovered_imported_for_all_uses": data.total_quant_recovered_imported_for_all_uses,
            "quant_new_substances_imported_as_feedstock": data.quant_new_substances_imported_as_feedstock,
            "quant_new_substances_imported_essential_uses": data.quant_new_substances_imported_essential_uses,
            "unit_cfc": data.unit_cfc,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllOdsEmissionExportData(getFilterDate, paginationData);
                setUnitCfc("");
                reset({ "substances": "", "country_of_destination_of_exports": "", "total_quant_new_imported_for_all_uses": "", "total_quant_recovered_imported_for_all_uses": "", "quant_new_substances_imported_as_feedstock": "", "quant_new_substances_imported_essential_uses": "", });
                setOpenOdsEmissionExport({ action: false, odsEmissionExportTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openOdsEmissionExport.odsEmissionExportTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setUnitCfc("");
                reset({ "substances": "", "country_of_destination_of_exports": "", "total_quant_new_imported_for_all_uses": "", "total_quant_recovered_imported_for_all_uses": "", "quant_new_substances_imported_as_feedstock": "", "quant_new_substances_imported_essential_uses": "", });
                getAllOdsEmissionExportData(getFilterDate, paginationData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenOdsEmissionExport({ action: false, odsEmissionExportTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <>
            <material.Dialog open={openOdsEmissionExport.action} maxWidth="lg" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openOdsEmissionExport.odsEmissionExportTableData ? "Edit ODS Export" : "Add ODS Export"}</material.DialogTitle>
                <material.DialogContent>
                    {openOdsEmissionExport.odsEmissionExportTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>ODS Emission Export</material.Typography>
                            </div>

                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("substances", { required: true, })}
                                    required
                                    label="Substances"
                                    id="materials"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    multiline
                                />
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("country_of_destination_of_exports", { required: true, })}
                                    required
                                    label="Exports Destination"
                                    id="materials"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    multiline
                                />
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("total_quant_new_imported_for_all_uses", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Total Quantity (new) Exported for All Uses"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.total_quant_new_imported_for_all_uses && errors.total_quant_new_imported_for_all_uses.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("total_quant_recovered_imported_for_all_uses", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Total Quantity (recovered & reclaimed ) Exported for All Uses"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.total_quant_recovered_imported_for_all_uses && errors.total_quant_recovered_imported_for_all_uses.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("quant_new_substances_imported_as_feedstock", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Quantity Used as Feedstock"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.quant_new_substances_imported_as_feedstock && errors.quant_new_substances_imported_as_feedstock.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("quant_new_substances_imported_essential_uses", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Quantity  Used as Exempted Essential"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.quant_new_substances_imported_essential_uses && errors.quant_new_substances_imported_essential_uses.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Unit CFC</material.InputLabel>
                                    <material.Select
                                        {...register("unit_cfc", { required: true, })}
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={unitCfc}
                                        onChange={selectUnit}
                                        label="Unit CFC"
                                    >
                                        <material.MenuItem value={"Metric Tons"}>Metric Tons</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openOdsEmissionExport.odsEmissionExportTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateEmissionExport)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openOdsEmissionExport.odsEmissionExportTableData.rejection_note === null) || (openOdsEmissionExport.odsEmissionExportTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveEmissionExport)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </>
    )
}

export default AddEmissionExportDialog;