import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import Snackbar from '../../../toaster-message/Snackbar';
import moment from 'moment';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import AddSecurityPractices from '../../../sub-issues-dialogs/security-practices/AddSecurityPractices';
import { useSelector } from 'react-redux';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';
import { getSubIssueRecordData, saveSubIssueData, updateSubIssueData } from '../../../services/sub-issue-services/AddSubissueService';
import ApplicableDialog from '../../../dialogs/ApplicableDialog';
import Pagination from '../../../shared/pagination/Pagination';


var recordRefNo = [];
var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };

function SecurityPractices(props) {
    const location = useLocation();
    const treeViewData = location?.state;

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [securityPracticesTabs, setSecurityPracticesTabs] = useState('Security Practices');
    const [securityPracticesTableData, setSecurityPracticesTableData] = useState([]);
    const [addOpenSecurityPractices, setAddOpenSecurityPractices] = useState({ action: false, securityPracticesTableData: null });
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [getFilterDate, setGetFilterDate] = useState(null);

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        getSecurityPracticesAllData()
    }, [securityPracticesTabs]);

    const handleChangeTabs = (event, newValue) => {
        setSecurityPracticesTabs(newValue);
    };

    const getFormDateToDate = (data) => {
        setGetFilterDate(data);
        getSecurityPracticesAllData(data, paginationData);
    };

    const getSecurityPracticesAllData = async (filterDate, paginationValue) => {
        if (securityPracticesTabs === "Security Practices") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let payloadData = {
                subissueName: treeViewData.subIssue,
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                forApproval,
                approveHistory,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            await getSubIssueRecordData(payloadData)
                .then((resp) => {
                    setSecurityPracticesTableData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setApplicableRefNo(resp.data.applicableRecord?.ref_no ? resp.data.applicableRecord.ref_no : null);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (securityPracticesTabs === "Security Practices History") {
            let forApproval = true;
            let approveHistory = true;
            let payloadData = {
                subissueName: treeViewData.subIssue,
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                forApproval,
                approveHistory,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            await getSubIssueRecordData(payloadData)
                .then((resp) => {
                    setSecurityPracticesTableData(resp.data.records.content);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        };
    };

    const addSecurityPractices = () => {
        setAddOpenSecurityPractices({ action: true, securityPracticesTableData: null });
    };
    const editSecurityPractices = (rowData) => {
        setAddOpenSecurityPractices({ action: true, securityPracticesTableData: rowData });
    };

    const clickCheckBox = (value) => {
        setAddOpenSecurityPractices({ action: false, HabitatsProtectedRestoredData: null });
        if (recordRefNo.includes(value)) {
            // recordRefNo.pop(value);
            recordRefNo = recordRefNo.filter(item => item !== value);
        }
        else {
            recordRefNo.push(value)
        }
    };

    const sendForApproval = async () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true
            }
            await updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getSecurityPracticesAllData(getFilterDate, paginationData);
                    recordRefNo = [];
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const approve = async () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true,
                "approved_by": userDetails.username,
                "approved_date": moment().format("YYYY-MM-DD"),
                "approved_status": "APPROVED",
                "rejection_note": "",
            }
            await updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getSecurityPracticesAllData(getFilterDate, paginationData);
                    recordRefNo = [];
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const reject = async () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        };
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const getRejectionNoteData = async (noteData) => {
        const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
        const subissueName = treeViewData.subIssue;
        const payload = {
            send_for_approval: false,
            "rejection_note": noteData.rejectionNote,
            "approved_status": "",
        }
        await updateSubIssueData(payload, refNo, subissueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getSecurityPracticesAllData(getFilterDate, paginationData);
                recordRefNo = [];
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };

    const getApplicableData = (data) => {
        if (applicableRefNo === null) {
            let subIssueName = treeViewData?.subIssue;
            const payload = {
                applicable: false,
                applicable_record: true,
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
            }
            saveSubIssueData(payload, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getSecurityPracticesAllData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else {
            let subIssueName = treeViewData?.subIssue;
            let refNo = applicableRefNo;
            const payload = {
                applicable: applicable ? false : true,
            }
            updateSubIssueData(payload, refNo, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getSecurityPracticesAllData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    const getPaginationData = (filterData, paginationData) => {
        setRowsPerPage(paginationData.size);
        setPage(paginationData.page);
        getSecurityPracticesAllData(filterData, paginationData);
    };

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className="row">
                        <div className="col-6">
                            <span><material.Typography variant="h5" className='text-capitalize'>{treeViewData?.subIssue}</material.Typography></span>
                        </div>
                        <div className="col-6">
                            <span className="float-end" hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}>
                                <material.Stack direction="row" spacing={1} alignItems="center">
                                    <material.Typography>Not applicable</material.Typography>
                                    <material.Switch {...label} color="secondary" checked={applicable} onChange={applicableNotApplicable} />
                                    <material.Typography>Applicable</material.Typography>
                                </material.Stack>
                            </span>
                        </div>
                    </div>
                    <material.Box sx={{ width: '100%', typography: 'body1' }}>
                        <material.TabContext value={securityPracticesTabs}>
                            <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <material.TabList onChange={handleChangeTabs} aria-label="lab API tabs example">
                                    <material.Tab className='text-capitalize' label="Security Practices" value="Security Practices" />
                                    <material.Tab className='text-capitalize' label="Security Practices History" value="Security Practices History" />
                                </material.TabList>
                            </material.Box>
                            <material.TabPanel value="Security Practices">
                                <div className="row mt-2 align-items-center">
                                    <div className="col-lg-7">
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        )}
                                    </div>
                                    <div className="col-lg-5" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                        <span className='float-end'>
                                            {securityPracticesTableData?.length ? (
                                                <>
                                                    <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} color='success' disabled={!applicable || !recordRefNo.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approve}>Approve</material.Button>
                                                    <material.Button sx={{ textTransform: "none" }} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} disabled={!applicable || !recordRefNo.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={reject}>Reject</material.Button>
                                                </>
                                            ) : null}
                                            <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendForApproval}>Send for Approval</material.Button>
                                            <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addSecurityPractices}>Add Security Practices</material.Button>
                                        </span>
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                    <material.TableContainer>
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow>
                                                    <StyledTableCell colSpan={2} align='center' sx={{ border: 1 }}>Security Personnel (Inside)</StyledTableCell>
                                                    <StyledTableCell colSpan={2} align='center' sx={{ border: 1 }}>Security Personnel (3rd Party)</StyledTableCell>
                                                    <StyledTableCell rowSpan={2} align='center' sx={{ border: 1 }}>From Date</StyledTableCell>
                                                    <StyledTableCell rowSpan={2} align='center' sx={{ border: 1 }}>To Date</StyledTableCell>
                                                    <StyledTableCell rowSpan={2} align='center' sx={{ border: 1 }} hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                        <StyledTableCell rowSpan={2} align='center' sx={{ border: 1 }}>Rejection Note</StyledTableCell>
                                                    )}
                                                </material.TableRow>
                                                <material.TableRow>
                                                    <StyledTableCell align='center' sx={{ border: 1 }}>Total Number</StyledTableCell>
                                                    <StyledTableCell align='center' sx={{ border: 1 }}>Number Received Training on Human Rights</StyledTableCell>
                                                    <StyledTableCell align='center' sx={{ border: 1 }}>Total Number</StyledTableCell>
                                                    <StyledTableCell align='center' sx={{ border: 1 }}>Number Received Training on Human Rights</StyledTableCell>
                                                </material.TableRow>
                                            </material.TableHead>
                                            <material.TableBody>
                                                {securityPracticesTableData.length ? securityPracticesTableData.map((item, k) => (
                                                    <material.TableRow key={k}
                                                        sx={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                        onClick={((userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")) && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true) ?
                                                            () => editSecurityPractices(item) : null}
                                                    >
                                                        <material.TableCell align='center'>{item.total_number_of_security_personnel_facility}</material.TableCell>
                                                        <material.TableCell align='center'>{item.received_training_on_human_rights_facility}</material.TableCell>
                                                        <material.TableCell align='center'>{item.total_number_of_security_personnel_third_party}</material.TableCell>
                                                        <material.TableCell align='center'>{item.received_training_on_human_rights_third_party}</material.TableCell>
                                                        <material.TableCell align='center'>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell align='center'>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell align='center' hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                                            {userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") ? (
                                                                <material.Checkbox {...label} disabled={!applicable || item.send_for_approval} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                            ) : (
                                                                <material.Checkbox {...label} disabled={!applicable || item.approved_status === "APPROVED"} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                            )}
                                                        </material.TableCell>
                                                        {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                            <material.TableCell align='center'>{(item.rejection_note === null) || (item.rejection_note === "") ? "No" : (
                                                                <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                            )}
                                                            </material.TableCell>
                                                        )}
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow>
                                                        <material.TableCell colSpan={12}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                    <Pagination
                                        getPaginationData={getPaginationData}
                                        totalElements={totalElements}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        getFilterDate={getFilterDate}
                                    />
                                </material.Paper>
                            </material.TabPanel>
                            <material.TabPanel value="Security Practices History">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <DateFilter
                                            getFormDateToDate={getFormDateToDate}
                                        />
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                    <div className='row'>
                                        <div className="col-12">
                                            <material.TableContainer>
                                                <material.Table>
                                                    <material.TableHead>
                                                        <material.TableRow >
                                                            <StyledTableCell colSpan={2} align='center' sx={{ border: 1 }}>Security Personnel (Inside)</StyledTableCell>
                                                            <StyledTableCell colSpan={2} align='center' sx={{ border: 1 }}>Security Personnel (3rd Party)</StyledTableCell>
                                                            <StyledTableCell rowSpan={2} align='center' sx={{ border: 1 }}>From Date</StyledTableCell>
                                                            <StyledTableCell rowSpan={2} align='center' sx={{ border: 1 }}>To Date</StyledTableCell>
                                                            <StyledTableCell rowSpan={2} sx={{ border: 1 }}>Approval Date</StyledTableCell>
                                                            <StyledTableCell rowSpan={2} sx={{ border: 1 }}>Status</StyledTableCell>
                                                        </material.TableRow>
                                                        <material.TableRow>
                                                            <StyledTableCell align='center' sx={{ border: 1 }}>Total Number</StyledTableCell>
                                                            <StyledTableCell align='center' sx={{ border: 1 }}>Number Received Training on Human Rights</StyledTableCell>
                                                            <StyledTableCell align='center' sx={{ border: 1 }}>Total Number</StyledTableCell>
                                                            <StyledTableCell align='center' sx={{ border: 1 }}>Number Received Training on Human Rights</StyledTableCell>
                                                        </material.TableRow>
                                                    </material.TableHead>
                                                    <material.TableBody>
                                                        {securityPracticesTableData?.length ? securityPracticesTableData.map((item, ind) => (
                                                            <material.TableRow key={ind}>
                                                                <material.TableCell align='center'>{item.total_number_of_security_personnel_facility}</material.TableCell>
                                                                <material.TableCell align='center'>{item.received_training_on_human_rights_facility}</material.TableCell>
                                                                <material.TableCell align='center'>{item.total_number_of_security_personnel_third_party}</material.TableCell>
                                                                <material.TableCell align='center'>{item.received_training_on_human_rights_third_party}</material.TableCell>
                                                                <material.TableCell align='center'>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell align='center'>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell align='center'>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell align='center'>
                                                                    {item.approved_status === "APPROVED" ? <span className="badge bg-success">APPROVED</span> : null}
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )) : (
                                                            <material.TableRow >
                                                                <material.TableCell colSpan={12}>
                                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )}
                                                    </material.TableBody>
                                                </material.Table>
                                            </material.TableContainer>
                                            <Pagination
                                                getPaginationData={getPaginationData}
                                                totalElements={totalElements}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                getFilterDate={getFilterDate}
                                            />
                                        </div>
                                    </div>
                                </material.Paper>
                            </material.TabPanel>
                        </material.TabContext>
                    </material.Box>
                </material.Box>
            </material.Box>
            <AddSecurityPractices
                addOpenSecurityPractices={addOpenSecurityPractices}
                setAddOpenSecurityPractices={setAddOpenSecurityPractices}
                getSecurityPracticesAllData={getSecurityPracticesAllData}
                treeViewData={treeViewData}
                page={page}
                rowsPerPage={rowsPerPage}
                getFilterDate={getFilterDate}
            />
            <ApplicableDialog
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getApplicableData={getApplicableData}
                applicable={applicable}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default SecurityPractices;