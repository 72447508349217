import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import DatePeriod from '../../shared/DatePeriod';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment/moment';

function AddEnergySold(props) {
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, setValue, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const { openEnergySold, setOpenEnergySold, getAllEnergySold, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const [purpose, setPurpose] = useState("");
    const [unit, setUnit] = useState("");
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const payload = {
        page,
        size: rowsPerPage
    };

    const selectPurpose = (event) => {
        setPurpose(event.target.value);
    };

    const selectUnit = (event) => {
        setUnit(event.target.value);
    };

    useEffect(() => {
        if (openEnergySold.action && openEnergySold.energySoldTableData) {
            setPurpose(openEnergySold.energySoldTableData.purpose);
            setUnit(openEnergySold.energySoldTableData.unit);
            reset(openEnergySold.energySoldTableData);
        };
    }, [openEnergySold.action === true]);

    const handleClose = () => {
        setPurpose("");
        setUnit("");
        reset({ quantity: "" });
        setOpenEnergySold({ action: false, energySoldTableData: null });
    };

    const submitEnergySold = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = treeViewData.subIssue;
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "purpose": data.purpose,
                "quantity": data.quantity,
                "unit": data.unit,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllEnergySold(getFilterDate, payload);
                    setPurpose("");
                    setUnit("");
                    reset({ quantity: "" });
                    setOpenEnergySold({ action: false, energySoldTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        }
    };

    const updateEnergySold = async (data) => {
        const refNo = openEnergySold.energySoldTableData.ref_no;
        const subIssueName = treeViewData.subIssue;
        let obj = {
            "purpose": data.purpose,
            "quantity": data.quantity,
            "unit": data.unit,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllEnergySold(getFilterDate, payload);
                setPurpose("");
                setUnit("");
                reset({ quantity: "" });
                setOpenEnergySold({ action: false, energySoldTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openEnergySold.energySoldTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                getAllEnergySold(getFilterDate, payload);
                setPurpose("");
                setUnit("");
                reset({ quantity: "" });
                setOpenEnergySold({ action: false, energySoldTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openEnergySold.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openEnergySold.energySoldTableData ? "Edit Energy Sold" : "Add Energy Sold"}</material.DialogTitle>
                <material.DialogContent>
                    {openEnergySold.energySoldTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row align-items-center'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Energy Sold</material.Typography>
                            </div>
                            <div>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Purpose</material.InputLabel>
                                    <material.Select
                                        {...register("purpose", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={purpose}
                                        onChange={selectPurpose}
                                        label="Material Sourced"
                                    >
                                        <material.MenuItem value={"Electricity Consumption"}>Electricity Consumption</material.MenuItem>
                                        <material.MenuItem value={"Heating Consumption"}>Heating Consumption</material.MenuItem>
                                        <material.MenuItem value={"Cooling Consumption"}>Cooling Consumption</material.MenuItem>
                                        <material.MenuItem value={"Steam Consumption"}>Steam Consumption</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='mt-2'>
                                <material.TextField
                                    {...register("quantity", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Quantity"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.quantity && errors.quantity.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 2 digit after decimal</p>
                                )}
                            </div>
                            <div className='mt-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Unit</material.InputLabel>
                                    <material.Select
                                        {...register("unit", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={unit}
                                        onChange={selectUnit}
                                        label="Material Sourced"
                                    >
                                        <material.MenuItem value={"MJ"}>MJ</material.MenuItem>
                                        <material.MenuItem value={"GJ"}>GJ</material.MenuItem>
                                        <material.MenuItem value={"TJ"}>TJ</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openEnergySold.energySoldTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateEnergySold)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openEnergySold.energySoldTableData.rejection_note === null) || (openEnergySold.energySoldTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(submitEnergySold)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div >
    )
}

export default AddEnergySold;