import React, { useEffect, useState } from 'react';
import { material } from '../library/Material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { getUsersByCorporate } from '../services/UsermanagementService';
import { useSelector } from 'react-redux';
import Snackbar from '../toaster-message/Snackbar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgb(4, 153, 79)",
        color: theme.palette.common.white,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

function ViewUsers(props) {
    const { openViewUsers, setOpenViewUsers } = props;
    const [usersData, setUsersData] = useState([]);
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openViewUsers.action) {
            getUsersByCorporateRefNo()
        }
    }, [openViewUsers.action === true]);
    const getUsersByCorporateRefNo = async () => {
        if (openViewUsers.callFrom === "corporateList") {
            let siteRefNo = null;
            await getUsersByCorporate(openViewUsers.corporateData.refNo, siteRefNo)
                .then((resp) => {
                    setUsersData(resp.data)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (openViewUsers.callFrom === "siteList") {
            await getUsersByCorporate(userDetails.corporateRefNo, openViewUsers.siteData.refNo)
                .then((resp) => {
                    setUsersData(resp.data)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        }
    };

    const close = () => {
        setOpenViewUsers({ action: false })
    };

    return (
        <div>
            <material.Dialog maxWidth="lg" fullWidth open={openViewUsers.action}>
                <material.DialogTitle className='d-flex justify-content-between fw-bold headingText'>
                    {openViewUsers.callFrom === "corporateList" ? (
                        <span>Corporate users for {openViewUsers.corporateData?.corporateName}</span>
                    ) : openViewUsers.callFrom === "siteList" ? (
                        <span>Facility users for {openViewUsers.siteData?.assetName}</span>
                    ) : null}
                    <span>
                        <material.Button variant="outlined" color='error' onClick={close}><material.CloseIcon titleAccess='Close' /></material.Button>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    {/* <material.Paper sx={{ p: 2, mt: 2, }} elevation={3}> */}
                    <div className='row'>
                        <div className='col-12'>
                            <material.TableContainer sx={{ maxHeight: 560 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell>User Name</StyledTableCell>
                                            <StyledTableCell>Email</StyledTableCell>
                                            <StyledTableCell>Corporate Name</StyledTableCell>
                                            <StyledTableCell>Facility Name</StyledTableCell>
                                            <StyledTableCell>Role</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {usersData.length ? usersData.map((row, i) => (
                                            <material.TableRow key={i}>
                                                <material.TableCell>{row.name}</material.TableCell>
                                                <material.TableCell>{row.username}</material.TableCell>
                                                <material.TableCell>{row.email}</material.TableCell>
                                                <material.TableCell>{row.corporateName}</material.TableCell>
                                                <material.TableCell>{row.facilityName}</material.TableCell>
                                                <material.TableCell>{row.role?.split("_").slice(1).join(" ")}</material.TableCell>
                                            </material.TableRow>
                                        )) : null}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                        </div>
                    </div>
                    {/* </material.Paper> */}
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default ViewUsers;