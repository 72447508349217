import React from 'react';
import { material } from '../../library/Material';
import './Pagination.css';

function Pagination(props) {

    const { rowsPerPage, getFilterDate, page, totalElements, getPaginationData } = props;


    const handleChangePage = (event, newPage) => {
        let filterData = getFilterDate;
        let size = rowsPerPage;
        let payloadData = {
            size,
            page: newPage
        }
        getPaginationData(filterData, payloadData);
    };

    const handleChangeRowsPerPage = (event) => {
        let filterData = getFilterDate;
        let payloadData = {
            size: event.target.value,
            page: page
        }
        getPaginationData(filterData, payloadData);
    };

    return (
        <div>
            <material.TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}

export default Pagination;