import React, { useEffect, useState } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { addSiteByCorporateRefNo, getDistinctNicListForFacility, getNicList, getSiteByRefNo } from '../services/SiteService';
import { State, Country, City } from 'country-state-city';
import AddContact from '../dialogs/AddContact';
import { StyledTableCell } from '../shared/TableHeaderStyle';
import Snackbar from '../toaster-message/Snackbar';
import { tableContent, actions } from '../shared/AllTableContent';
import Table from '../shared/Table';
import BusinessActivity from '../dialogs/BusinessActivity';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './FacilityGenInfo.css'
import AddGeographicalLocation from '../dialogs/AddGeographicalLocation';
import SebiMateriality from './project-capability/SebiMateriality'
import NseMateriality from './project-capability/NseMateriality'
import NicMateriality from './project-capability/NicMateriality'
import CpcbMateriality from './project-capability/CpcbMateriality'
import UploadDocument from './upload-document/UploadDocument';

var allCountry = [];
var contactData = [];
var allNicData = [];
var nicData;
var usersRole;

function FacilityGenInfo(props) {

    const { register, handleSubmit, reset, control, resetField, setValue, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [facilityData, setFacilityData] = useState({});
    const [editFacilityDetails, setEditFacilityDetails] = useState(false);
    const [editAddress, setEditAddress] = useState(false);
    const [indicateValue, setIndicateValue] = useState("");
    const [entityValue, setEntityValue] = useState("");
    const [states, setStates] = useState([]);
    const [selectStateValue, setSelectStateValue] = useState("");
    const [allCountryName, setAllCountryName] = useState([]);
    const [countryName, setCountryName] = useState("");
    const [openAddContact, setOpenAddContact] = React.useState({ action: false, contactData: null });
    const [openBusinessActivity, setOpenBusinessActivity] = React.useState({ action: false, facilityData: null });
    const [capacityOfFacilityUnit, setCapacityOfFacilityUnit] = useState("");
    const [areaUnit, setAreaUnit] = useState("");
    const [supplier, setSupplier] = useState();
    const [nicList, setNicList] = useState([]);
    const [selectNicData, setSelectNicData] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openAddGeographicalLocation, setOpenAddGeographicalLocation] = useState({ action: false, facilityData: null });
    const [phone, setPhone] = useState("");

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    useEffect(() => {
        getSiteBySiteRefNo();
        // getNicData();
        getDistinctNicList();
        allCountry = Country.getAllCountries();
        // let countryName = allCountry?.map((country) => country.name);
        setAllCountryName(allCountry)
    }, []);
    const getSiteBySiteRefNo = async () => {
        await getSiteByRefNo(userDetails.facilityRefNo)
            .then((resp) => {
                setFacilityData(resp.data)
                // console.log(resp.data.geographicalLocations)
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };
    const getNicData = async () => {
        await getNicList()
            .then((resp) => {
                // allNicData = resp.data;
                let projectActivity = resp.data?.map((ele) => ele.projectActivity)
                // setNicList(projectActivity)
                // console.log(resp.data)
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const getDistinctNicList = () => {
        getDistinctNicListForFacility(userDetails.facilityRefNo)
            .then((resp) => {
                allNicData = resp.data;
                let subclassDescription = resp.data?.map((ele) => ele.subclassDescription);
                setNicList(subclassDescription)
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const editDetails = () => {
        setEditFacilityDetails(true)
        setEntityValue(facilityData?.affiliation ? facilityData.affiliation : "")
        setIndicateValue(facilityData?.facilityType ? facilityData.facilityType : "")
        setCapacityOfFacilityUnit(facilityData?.facilityCapacityUnit ? facilityData.facilityCapacityUnit : "")
        setAreaUnit(facilityData?.areaUnit ? facilityData.areaUnit : "")
        setSupplier(facilityData?.isSupplier ? facilityData.isSupplier : "")
        setSelectNicData(facilityData?.primaryActivity ? facilityData.primaryActivity : "")
        reset(facilityData)
        setValue("primaryActivity", facilityData?.primaryActivity ? facilityData.primaryActivity : "")
    };
    const editAdministrativeAddress = () => {
        setEditAddress(true)
        setCountryName(facilityData?.country ? facilityData.country : "");
        let countryCode = allCountry.filter((country) => country.name === facilityData?.country ? facilityData.country : "")
        setStates(State.getStatesOfCountry(countryCode.length ? countryCode[0].isoCode : []))
        setSelectStateValue(facilityData?.state ? facilityData.state : "");
        reset({ "address": facilityData.address });
        setPhone(facilityData.phone);
    };
    const selectIndicateWhether = (event) => {
        setIndicateValue(event.target.value)
    };
    const selectEntity = (event) => {
        setEntityValue(event.target.value)
    };
    const selectPrimaryActivity = (event, value) => {
        setSelectNicData(value)
        nicData = allNicData.filter((ele) => ele.subclassDescription === value);
    };
    const selectCountry = (event, value) => {
        setCountryName(event.target.value)
        reset({ "state": "", "address": "" })
        let countryCode = allCountry.filter((country) => country.name === event.target.value)
        setStates(State.getStatesOfCountry(countryCode.length ? countryCode[0].isoCode : []))
    };
    const selectState = (event) => {
        setSelectStateValue(event.target.value)
    };
    const selectIsSupplier = (event) => {
        setSupplier(event.target.value)
        if (event.target.value) {
            setIndicateValue("")
            reset({ facilityType: "" })
        }
    };
    const saveFacilityDetails = async (data) => {
        // let array = [];
        // array.push({ ...data,
        //      phone: phone, 
        //      nicDto: nicData ? nicData[0] : facilityData.nicDto, 
        //      refNo: facilityData.refNo, 
        //      isSupplier: supplier ,
        //      affiliation:data.affiliation,
        //      facilityType:data.facilityType,
        //      primaryActivity:data.primaryActivity,
        //     })
        const payload = [
            {
                "assetName": data.assetName,
                "refNo": facilityData.refNo,
                "address": data.address,
                "country": data.country,
                "state": data.state,
                "phone": phone,
                "pin": data.pin,
                "fax": data.fax,
                "email": data.email,
                "website": data.website,
                "affiliation": data.affiliation,
                "facilityType": data.facilityType,
                "isSupplier": supplier,
                "activityDetails": data.activityDetails,
                "facilityCapacity": data.facilityCapacity,
                "facilityCapacityUnit": data.facilityCapacityUnit,
                "totalArea": data.totalArea,
                "areaUnit": data.areaUnit,
                "percentageOfShare": data.percentageOfShare,
                "participatesInBusinessResponsibility": data.participatesInBusinessResponsibility,
                "totalTurnOverContributed": data.totalTurnOverContributed,
                "turnOverUnit": data.turnOverUnit,
                "productionQuantity": data.productionQuantity,
                "productionQuantityUnit": data.productionQuantityUnit,
                "primaryActivity": data.primaryActivity
            }
        ]
        await addSiteByCorporateRefNo(userDetails.corporateRefNo, payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Save successfully",
                })
                setEditFacilityDetails(false);
                setEditAddress(false);
                getSiteBySiteRefNo();
                reset({ activityDetails: "" })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };
    const addContact = () => {
        setOpenAddContact({ action: true, contactData: "" })
    };
    const selectCapacityOfFacilityUnit = (event) => {
        setCapacityOfFacilityUnit(event.target.value)
    };
    const selectAreaUnit = (event) => {
        setAreaUnit(event.target.value)
    };
    const editContact = (contactData) => {
        setOpenAddContact({ action: true, contactData: contactData })
    };
    const closeFacilityAddress = () => {
        setEditAddress(false)
        reset({ "state": "", "address": "" })
    };
    const closeFacilityDetails = () => {
        setEditFacilityDetails(false);
        reset({ activityDetails: "" });
    };

    const editBusinessActivity = (data) => {
        setOpenBusinessActivity({ action: true, facilityData: data })
    };

    const handelGeographicalLocation = () => {
        setOpenAddGeographicalLocation({ action: true, facilityData: null });
    };

    const editGeographicalLocation = (rowdata) => {
        setOpenAddGeographicalLocation({ action: true, facilityData: rowdata });
    };

    const viewNotes = () => {

    }

    return (
        <div>
            <material.Paper elevation={3} sx={{ p: 3 }}>
                <div>
                    <form className='mb-2'>
                        <material.Accordion >
                            <material.AccordionSummary
                                expandIcon={<material.ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }} className='headingText'>Administrative location</material.Typography>
                            </material.AccordionSummary>
                            <material.AccordionDetails>
                                <div className="row">
                                    <div className="col-6"></div>
                                    <div className="col-6">
                                        <span className='float-end'>
                                            {editAddress ? (
                                                <>
                                                    <material.IconButton sx={{ mt: -1 }} title='Save Facility Details' hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_ESG_DATA"} aria-label="create" size="large" onClick={handleSubmit(saveFacilityDetails)}>
                                                        <material.SaveIcon color='success' />
                                                    </material.IconButton>
                                                    <material.IconButton sx={{ mt: -1 }} title='Close' aria-label="create" size="large" onClick={closeFacilityAddress}>
                                                        <material.CloseIcon color='error' />
                                                    </material.IconButton>
                                                </>
                                            ) : (
                                                <material.IconButton sx={{ mt: -1 }} title='Edit Administrative location' hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_ESG_DATA"} aria-label="create" size="large" onClick={() => editAdministrativeAddress()}>
                                                    <material.CreateIcon color='success' />
                                                </material.IconButton>
                                            )}
                                        </span>
                                    </div>
                                </div>
                                {editAddress ? (
                                    <material.Typography>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                {/* <material.Autocomplete
                                                    fullWidth
                                                    options={allCountryName}
                                                    onChange={selectCountry}
                                                    defaultValue={facilityData?.country}
                                                    autoFocus
                                                    renderInput={(params) => <material.TextField {...params} variant="standard" label="Country"
                                                        fullWidth color='success' id="country" required {...register("country", { required: true })}
                                                    />}
                                                /> */}
                                                <material.FormControl variant="standard" fullWidth required>
                                                    <material.InputLabel id="userType">Country</material.InputLabel>
                                                    <material.Select
                                                        {...register("country", { required: true })}
                                                        id="country"
                                                        value={countryName}
                                                        onChange={selectCountry}
                                                    >
                                                        {allCountryName?.map((ele, i) => (
                                                            <material.MenuItem value={ele.name} key={i}>{ele.name}</material.MenuItem>
                                                        ))}
                                                    </material.Select>
                                                </material.FormControl>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                {/* <material.Autocomplete
                                                    fullWidth
                                                    options={states}
                                                    onChange={selectState}
                                                    autoFocus
                                                    defaultValue={facilityData?.state}
                                                    renderInput={(params) => <material.TextField {...params} variant="standard" label="State"
                                                        fullWidth color='success' id="state" defaultValue={facilityData?.state} {...register("state", { required: true })}
                                                    />}
                                                /> */}
                                                <material.FormControl variant="standard" fullWidth required>
                                                    <material.InputLabel id="State">State</material.InputLabel>
                                                    <material.Select
                                                        {...register("state", { required: true })}
                                                        id="State"
                                                        value={selectStateValue}
                                                        onChange={selectState}
                                                    >
                                                        {states?.map((ele, i) => (
                                                            <material.MenuItem value={ele.name} key={i}>{ele.name}</material.MenuItem>
                                                        ))}
                                                    </material.Select>
                                                </material.FormControl>
                                            </div>
                                            {/* <div className="col-lg-4 col-md-6 mt-3">
                                                <material.TextField
                                                    label="City"
                                                    id="city"
                                                    variant="standard"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={facilityData.city}
                                                    {...register("city", { required: true })}
                                                />
                                            </div> */}
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.TextField
                                                    required
                                                    label="Email"
                                                    id="email"
                                                    variant="standard"
                                                    type="email"
                                                    size="small"
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={facilityData.email}
                                                    {...register("email", { required: true })}
                                                />
                                            </div>
                                            <div className="col-lg-12 col-md-12 mt-3">
                                                <material.TextField
                                                    required
                                                    label="Facility Address"
                                                    id="registeredAddress"
                                                    variant="standard"
                                                    type="text"
                                                    size="small"
                                                    multiline
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={facilityData.address}
                                                    {...register("address", { required: true })}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.TextField
                                                    required
                                                    label="Pin Code"
                                                    id="pin"
                                                    variant="standard"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={facilityData.pin}
                                                    {...register("pin", { required: true })}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-4">
                                                <PhoneInput
                                                    required
                                                    placeholder="Enter phone number"
                                                    defaultCountry="IN"
                                                    inputRef={register}
                                                    enableSearch={true}
                                                    value={phone}
                                                    control={control}
                                                    international
                                                    rules={{ required: true }}
                                                    onChange={(phone) => setPhone(phone)}
                                                    error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                                />
                                                {/* {(errors?.phone?.type === "pattern") && (
                                                    <p className='text-danger'>Invalid input, please input only number</p>
                                                )} */}
                                            </div>
                                            {/* <div className="col-lg-4 col-md-6 mt-3">
                                                <material.TextField
                                                    required
                                                    error={errors.phone?.type === "required"}
                                                    label="Phone"
                                                    id="phone"
                                                    variant="standard"
                                                    type="number"
                                                    size="small"
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={facilityData.phone}
                                                    {...register("phone", { required: true, pattern: /^[+]?([0-9]+)$/i, minLength: 10, maxLength: 10 })}
                                                />
                                                {(errors?.phone?.type === "pattern") && (
                                                    <p className='text-danger'>Invalid input, please input only number</p>
                                                )}
                                                {(errors?.phone?.type === "minLength" || errors?.phone?.type === "maxLength") && (
                                                    <p className='text-danger'>Enter 10 digit number</p>
                                                )}
                                            </div> */}
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.TextField
                                                    required
                                                    error={errors.fax?.type === "required"}
                                                    label="Fax"
                                                    id="fax"
                                                    variant="standard"
                                                    type="number"
                                                    size="small"
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={facilityData.fax}
                                                    {...register("fax", { required: true, pattern: /^[+]?([0-9]+)$/i })}
                                                />
                                                {(errors?.totalArea?.type === "pattern") && (
                                                    <p className='text-danger'>Invalid input, please input only number</p>
                                                )}
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.TextField
                                                    required
                                                    label="Website URL"
                                                    id="website"
                                                    variant="standard"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={facilityData.website}
                                                    {...register("website", { required: true, pattern: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/ })}
                                                />
                                                {errors.website && errors.website.type === "pattern" && (
                                                    <p className="errorMsg text-danger">Invalid url.</p>
                                                )}
                                            </div>
                                        </div>
                                    </material.Typography>
                                ) : (
                                    <material.Typography>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className='col-4'>Country</td>
                                                            <td className='col-8'>{facilityData.country}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4'>State</td>
                                                            <td className='col-8'>{facilityData.state}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4'>Facility Address</td>
                                                            <td className='col-8'>{facilityData.address}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4'>Pin Code</td>
                                                            <td className='col-8'>{facilityData.pin}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4'>Phone</td>
                                                            <td className='col-8'>{facilityData.phone}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4'>Fax</td>
                                                            <td className='col-8'>{facilityData.fax}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4'>Email</td>
                                                            <td className='col-8'>{facilityData.email}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4'>Website</td>
                                                            <td className='col-8'>{facilityData.website}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </material.Typography>
                                )}
                            </material.AccordionDetails>
                        </material.Accordion>
                        <material.Accordion sx={{ mt: 2 }}>
                            <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header" >
                                <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>Product, Service & Sector</material.Typography>
                            </material.AccordionSummary>
                            <material.AccordionDetails>
                                <material.Accordion>
                                    <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header" >
                                        <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>NIC</material.Typography>
                                    </material.AccordionSummary>
                                    <material.AccordionDetails>
                                        <NicMateriality
                                            getSiteBySiteRefNo={getSiteBySiteRefNo}
                                            facilityData={facilityData}
                                            getDistinctNicList={getDistinctNicList}
                                        />
                                    </material.AccordionDetails>
                                </material.Accordion>
                                <material.Accordion sx={{ mt: 2 }}>
                                    <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header" >
                                        <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>SEBI</material.Typography>
                                    </material.AccordionSummary>
                                    <material.AccordionDetails>
                                        <SebiMateriality
                                            getSiteBySiteRefNo={getSiteBySiteRefNo}
                                            facilityData={facilityData}
                                        />
                                    </material.AccordionDetails>
                                </material.Accordion>
                                <material.Accordion sx={{ mt: 2 }}>
                                    <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header" >
                                        <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>NSE</material.Typography>
                                    </material.AccordionSummary>
                                    <material.AccordionDetails>
                                        <NseMateriality
                                            getSiteBySiteRefNo={getSiteBySiteRefNo}
                                            facilityData={facilityData}
                                        />
                                    </material.AccordionDetails>
                                </material.Accordion>
                                <material.Accordion sx={{ mt: 2 }}>
                                    <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header" >
                                        <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>CPCB</material.Typography>
                                    </material.AccordionSummary>
                                    <material.AccordionDetails>
                                        <CpcbMateriality
                                            getSiteBySiteRefNo={getSiteBySiteRefNo}
                                            facilityData={facilityData}
                                        />
                                    </material.AccordionDetails>
                                </material.Accordion>
                            </material.AccordionDetails>
                        </material.Accordion>
                        <material.Accordion sx={{ mt: 2 }}>
                            <material.AccordionSummary
                                expandIcon={<material.ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }} className='headingText'>Facility Name : {facilityData.assetName}</material.Typography>
                            </material.AccordionSummary>
                            <material.AccordionDetails>
                                <div className="row">
                                    <div className="col-6"></div>
                                    <div className="col-6">
                                        <span className='float-end'>
                                            {editFacilityDetails ? (
                                                <>
                                                    <material.IconButton sx={{ mt: -1 }} hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_ESG_DATA"} title='Save Corporate Details' aria-label="create" size="large" onClick={handleSubmit(saveFacilityDetails)}>
                                                        <material.SaveIcon color='success' />
                                                    </material.IconButton>
                                                    <material.IconButton sx={{ mt: -1 }} title='Close' aria-label="create" size="large" onClick={closeFacilityDetails}>
                                                        <material.CloseIcon color='error' />
                                                    </material.IconButton>
                                                </>
                                            ) : (
                                                <material.IconButton sx={{ mt: -1 }} hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_ESG_DATA"} title='Edit Facility Details' aria-label="create" size="large" onClick={editDetails}>
                                                    <material.CreateIcon color='success' />
                                                </material.IconButton>
                                            )}
                                        </span>
                                    </div>
                                </div>
                                {editFacilityDetails ? (
                                    <material.Typography>
                                        <div className="row">
                                            <material.InputLabel required className="col-lg-1 col-md-2" sx={{ fontSize: 18, mt: 2.5 }}>Supplier</material.InputLabel>
                                            <div className="col-lg-3 col-md-4 mt-3">
                                                <material.FormControl>
                                                    <material.RadioGroup
                                                        {...register("isSupplier")}
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={selectIsSupplier}
                                                        value={supplier}
                                                    >
                                                        <material.FormControlLabel value="true" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="false" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.FormControl variant="standard" fullWidth required>
                                                    <material.InputLabel id="userType">Affiliation / association with Corporate</material.InputLabel>
                                                    <material.Select
                                                        {...register("affiliation", { required: true })}
                                                        id="affiliation"
                                                        value={entityValue}
                                                        onChange={selectEntity}
                                                    >
                                                        <material.MenuItem value="Holding">Holding</material.MenuItem>
                                                        <material.MenuItem value="Subsidiary">Subsidiary</material.MenuItem>
                                                        <material.MenuItem value="Associate">Associate</material.MenuItem>
                                                        <material.MenuItem value="Joint Venture">Joint Venture</material.MenuItem>
                                                    </material.Select>
                                                </material.FormControl>
                                            </div>
                                            {supplier === "true" ? null : supplier === "false" ? (
                                                <div className="col-lg-4 col-md-6 mt-3">
                                                    <material.FormControl variant="standard" fullWidth required>
                                                        <material.InputLabel id="facilityType">Facility type</material.InputLabel>
                                                        <material.Select
                                                            {...register("facilityType", { required: true })}
                                                            id="facilityType"
                                                            value={indicateValue}
                                                            onChange={selectIndicateWhether}
                                                        >
                                                            <material.MenuItem value="Plant">Plant</material.MenuItem>
                                                            <material.MenuItem value="Office">Office</material.MenuItem>
                                                        </material.Select>
                                                    </material.FormControl>
                                                </div>
                                            ) : null}
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.Autocomplete
                                                    id="primaryActivity"
                                                    options={nicList}
                                                    onChange={selectPrimaryActivity}
                                                    value={selectNicData}
                                                    renderInput={(params) => <material.TextField {...params} variant="standard" label="Primary activity (Products/Service)"
                                                        fullWidth color='success' id="country" defaultValue={selectNicData} required {...register("primaryActivity", { required: true })}
                                                    />}
                                                />
                                                {/* <material.FormControl variant="standard" fullWidth required>
                                                    <material.InputLabel id="userType">Primary activity (Products/Service)</material.InputLabel>
                                                    <material.Select
                                                        {...register("primaryActivity", { required: true })}
                                                        id="primaryActivity"
                                                        value={selectNicData}
                                                        onChange={selectPrimaryActivity}
                                                    >
                                                        {nicList?.map((nicData, i) => (
                                                            <material.MenuItem sx={{ width: 200 }} value={nicData.projectActivity} key={i}>{nicData.projectActivity}</material.MenuItem>
                                                        ))}
                                                    </material.Select>
                                                </material.FormControl> */}

                                                {/* <material.Autocomplete
                                                    disablePortal
                                                    fullWidth
                                                    required
                                                    variant="standard"
                                                    id="auto-select"
                                                    autoSelect
                                                    options={{ types: [nicList] }}
                                                    renderInput={(params) => (<material.TextField {...params} variant="standard" label="Primary activity (Products/Service)" />)}
                                                /> */}
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.TextField
                                                    error={errors.facilityCapacity?.type === "required"}
                                                    required
                                                    label="Capacity of Facility"
                                                    id="capacityOfFacility"
                                                    variant="standard"
                                                    type="number"
                                                    size="small"
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={facilityData.facilityCapacity}
                                                    {...register("facilityCapacity", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                                />
                                                {errors.facilityCapacity && errors.facilityCapacity.type === "pattern" && (
                                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                                )}
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.FormControl variant="standard" fullWidth required>
                                                    <material.InputLabel id="capacityOfFacilityUnit">Unit</material.InputLabel>
                                                    <material.Select
                                                        {...register("facilityCapacityUnit", { required: true })}
                                                        id="facilityCapacityUnit"
                                                        value={capacityOfFacilityUnit}
                                                        onChange={selectCapacityOfFacilityUnit}
                                                    >
                                                        <material.MenuItem value="MT/month">MT/month</material.MenuItem>
                                                        <material.MenuItem value="MW/month">MW/month</material.MenuItem>
                                                        <material.MenuItem value="KL/month">KL/month</material.MenuItem>
                                                        <material.MenuItem value="Thousand cubic feet/month">Thousand cubic feet/month</material.MenuItem>
                                                        <material.MenuItem value="barrels/month">barrels/month</material.MenuItem>
                                                    </material.Select>
                                                </material.FormControl>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.TextField
                                                    error={errors.totalArea?.type === "required"}
                                                    required
                                                    label="Total Area"
                                                    id="totalArea"
                                                    variant="standard"
                                                    type="number"
                                                    size="small"
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={facilityData.totalArea}
                                                    {...register("totalArea", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                                />
                                                {errors.totalArea && errors.totalArea.type === "pattern" && (
                                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                                )}
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.FormControl variant="standard" fullWidth required>
                                                    <material.InputLabel id="areaUnit">Unit</material.InputLabel>
                                                    <material.Select
                                                        {...register("areaUnit", { required: true })}
                                                        id="areaUnit"
                                                        value={areaUnit}
                                                        onChange={selectAreaUnit}
                                                    >
                                                        <material.MenuItem value="Hectare">Hectare</material.MenuItem>
                                                        <material.MenuItem value="Acre">Acre</material.MenuItem>
                                                    </material.Select>
                                                </material.FormControl>
                                            </div>
                                            <div className="col-lg-12 col-md-12 mt-3">
                                                <material.TextField
                                                    required
                                                    label="Describe the activity/services"
                                                    id="activityDetails"
                                                    variant="standard"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    color='success'
                                                    multiline
                                                    defaultValue={facilityData.activityDetails}
                                                    {...register("activityDetails", { required: true, maxLength: 500 })}
                                                />
                                                {(errors?.activityDetails?.type === "maxLength") && (
                                                    <p className='text-danger'>Can not exceed 500 character</p>
                                                )}
                                            </div>

                                        </div>
                                    </material.Typography>
                                ) : (
                                    <material.Typography>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className='col-4 headingText'>Affiliation / association with Corporate</td>
                                                            <td className='col-8'>{facilityData.affiliation}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4 headingText'>Supplier</td>
                                                            <td className='col-8'>{facilityData.isSupplier === "true" ? "YES" : facilityData.isSupplier === "false" ? "NO" : facilityData.isSupplier ? "" : ""}</td>
                                                        </tr>
                                                        {facilityData.isSupplier === "true" ? null : (
                                                            <tr>
                                                                <td className='col-4 headingText'>Facility type</td>
                                                                <td className='col-8'>{facilityData.facilityType}</td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <td className='col-4 headingText'>Primary activity (Products/Service)</td>
                                                            <td className='col-8'>{facilityData?.primaryActivity}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4 headingText'>Describe the activity/services</td>
                                                            <td className='break-cell'>{facilityData.activityDetails}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4 headingText'>Capacity of facility</td>
                                                            <td className='col-8'>{facilityData.facilityCapacity} {facilityData.facilityCapacityUnit}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4 headingText'>Total area</td>
                                                            <td className='col-8'>{facilityData.totalArea} {facilityData.areaUnit}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </material.Typography>
                                )}
                            </material.AccordionDetails>
                        </material.Accordion>
                    </form>
                    <material.Accordion sx={{ mt: 2 }}>
                        <material.AccordionSummary
                            expandIcon={<material.ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>Business Activities</material.Typography>
                        </material.AccordionSummary>
                        <material.AccordionDetails>
                            <material.Typography>
                                <material.TableContainer>
                                    <material.Table>
                                        <material.TableHead>
                                            <material.TableRow>
                                                <StyledTableCell>Facility Name</StyledTableCell>
                                                <StyledTableCell>Description of Main Activity</StyledTableCell>
                                                <StyledTableCell>Description of Business Activity</StyledTableCell>
                                                <StyledTableCell>Production Quantity</StyledTableCell>
                                                <StyledTableCell>Turnover Contributed Amount</StyledTableCell>
                                                <StyledTableCell hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_ESG_DATA"}>Action</StyledTableCell>
                                            </material.TableRow>
                                        </material.TableHead>
                                        <material.TableBody>
                                            <material.TableRow>
                                                <material.TableCell>{facilityData.assetName}</material.TableCell>
                                                <material.TableCell>{facilityData.primaryActivity}</material.TableCell>
                                                <material.TableCell className='text-break' size='small' style={{ width: "25rem" }}>{facilityData.activityDetails}</material.TableCell>
                                                <material.TableCell>{facilityData.productionQuantity} {facilityData.productionQuantityUnit}</material.TableCell>
                                                <material.TableCell>{facilityData.totalTurnOverContributed} {facilityData.turnOverUnit}</material.TableCell>
                                                <material.TableCell hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_ESG_DATA"}>
                                                    <material.IconButton title='Edit business activity' aria-label="create" size="large" onClick={() => editBusinessActivity(facilityData)}>
                                                        <material.CreateIcon color='success' />
                                                    </material.IconButton>
                                                </material.TableCell>
                                            </material.TableRow>
                                        </material.TableBody>
                                    </material.Table>
                                </material.TableContainer>
                            </material.Typography>
                        </material.AccordionDetails>
                    </material.Accordion>
                    <material.Accordion sx={{ mt: 2 }}>
                        <material.AccordionSummary
                            expandIcon={<material.ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }} className='headingText'>Geographical location (provide boundary coordinates to demarcate the site)</material.Typography>
                        </material.AccordionSummary>
                        <material.AccordionDetails>
                            <material.Typography>
                                <div className='my-2 float-end' hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_ESG_DATA"}>
                                    <material.Button className='text-capitalize' variant="contained" color='success' onClick={handelGeographicalLocation}>Add Geographical location</material.Button>
                                </div>
                                <div>
                                    <material.TableContainer sx={{ maxHeight: 500 }}>
                                        <material.Table stickyHeader aria-label="sticky table">
                                            <material.TableHead>
                                                <material.TableRow>
                                                    <StyledTableCell>Boundary Points</StyledTableCell>
                                                    <StyledTableCell>Latitude</StyledTableCell>
                                                    <StyledTableCell>Longitude</StyledTableCell>
                                                    <StyledTableCell hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_ESG_DATA"}>Action</StyledTableCell>
                                                </material.TableRow>
                                            </material.TableHead>
                                            <material.TableBody>
                                                {facilityData?.geographicalLocations?.length ? facilityData.geographicalLocations.map((item, ind) => (
                                                    <material.TableRow key={ind}>
                                                        <material.TableCell>{item.boundaryPoints}</material.TableCell>
                                                        <material.TableCell>{item.latitude}</material.TableCell>
                                                        <material.TableCell>{item.longitude}</material.TableCell>
                                                        <material.TableCell hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_ESG_DATA"}>
                                                            <material.IconButton title='Edit Geographical Location' aria-label="create" size="large" onClick={() => editGeographicalLocation(item)}>
                                                                <material.CreateIcon color='success' />
                                                            </material.IconButton>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={5}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                </div>
                            </material.Typography>
                        </material.AccordionDetails>
                    </material.Accordion>
                    <material.Accordion sx={{ mt: 2 }}>
                        <material.AccordionSummary
                            expandIcon={<material.ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>Point of Contacts</material.Typography>
                        </material.AccordionSummary>
                        <material.AccordionDetails>
                            <div className="row mb-2">
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <span className='float-end'>
                                        <material.Button variant="contained" color='success' sx={{ textTransform: "none" }}
                                            startIcon={<material.AddIcon />} onClick={addContact} hidden={facilityData.pointOfContactsDto?.length === 3 || usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_ESG_DATA"}>Add-Contact</material.Button>
                                    </span>
                                </div>
                            </div>
                            <material.Typography>
                                <material.TableContainer>
                                    <material.Table>
                                        <material.TableHead>
                                            <material.TableRow>
                                                <StyledTableCell>Name</StyledTableCell>
                                                <StyledTableCell align='right'>Designation</StyledTableCell>
                                                <StyledTableCell align='right'>Phone No.</StyledTableCell>
                                                <StyledTableCell align='right'>Email</StyledTableCell>
                                                <StyledTableCell align='right' hidden={usersRole === "ROLE_FACILITY_APPROVER"}>Action</StyledTableCell>
                                            </material.TableRow>
                                        </material.TableHead>
                                        <material.TableBody>
                                            {facilityData.pointOfContactsDto?.length ? facilityData.pointOfContactsDto.map((item, i) => (
                                                <material.TableRow key={i}>
                                                    <material.TableCell>{item.name}</material.TableCell>
                                                    <material.TableCell align='right'>{item.designation}</material.TableCell>
                                                    <material.TableCell align='right'>{item.phNo}</material.TableCell>
                                                    <material.TableCell align='right'>{item.email}</material.TableCell>
                                                    <material.TableCell align='right' hidden={usersRole === "ROLE_FACILITY_APPROVER"}>
                                                        <material.IconButton title='Edit Corporate Details' aria-label="create" size="large" onClick={() => editContact(item)}>
                                                            <material.CreateIcon color='success' />
                                                        </material.IconButton>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )) : null}
                                        </material.TableBody>
                                    </material.Table>
                                </material.TableContainer>
                            </material.Typography>
                        </material.AccordionDetails>
                    </material.Accordion>
                    <material.Accordion sx={{ mt: 2 }}>
                        <material.AccordionSummary
                            expandIcon={<material.ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>Document Upload</material.Typography>
                        </material.AccordionSummary>
                        <material.AccordionDetails>
                            <UploadDocument />
                        </material.AccordionDetails>
                    </material.Accordion>
                </div>
            </material.Paper>
            <AddContact
                openAddContact={openAddContact}
                setOpenAddContact={setOpenAddContact}
                getSiteBySiteRefNo={getSiteBySiteRefNo}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <BusinessActivity
                getSiteBySiteRefNo={getSiteBySiteRefNo}
                openBusinessActivity={openBusinessActivity}
                setOpenBusinessActivity={setOpenBusinessActivity}
            />
            <AddGeographicalLocation
                openAddGeographicalLocation={openAddGeographicalLocation}
                setOpenAddGeographicalLocation={setOpenAddGeographicalLocation}
                getSiteBySiteRefNo={getSiteBySiteRefNo} />
        </div>
    );
}

export default FacilityGenInfo;