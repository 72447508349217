import React, { useEffect, useState, useRef } from 'react';
import { material } from '../../library/Material';
import { Controller, useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import { useSelector } from 'react-redux';
import { documentUpload } from '../../services/DocumentUploadService';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';

function UploadDocumentDialog(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, watch, getValues, control, resetField, setValue, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const { openUploadDialog, setOpenUploadDialog, getAllDocumentList, page, rowsPerPage, ansRefNo, getAssessmentQuestionsAndAnswer, callFrom } = props;
    const [selectDocType, setSelectDocType] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [showFileSize, setShowFileSize] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [selectDate, setSelectDate] = useState(null)
    const [fileAlreadyExists, setFileAlreadyExists] = useState(false);
    const [files, setFiles] = useState(null);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openUploadDialog.action && openUploadDialog.documentData !== null) {
            reset({ category: openUploadDialog.documentData.category, nameOfDocument: openUploadDialog.documentData.nameOfDocument });
            setSelectDocType(openUploadDialog.documentData.category);
            setSelectDate(openUploadDialog.documentData.validUpTo === null ? null : dayjs(openUploadDialog.documentData.validUpTo))
        }
    }, [openUploadDialog.action === true]);

    const handleChangeDocumentType = (event) => {
        setSelectDocType(event.target.value)
    };

    const cancel = () => {
        setSelectDocType("");
        reset({ category: "", nameOfDocument: "", file: "" });
        setShowFileSize(null);
        setFileAlreadyExists(false);
        setFiles(null);
        setSelectDate("");
        setOpenUploadDialog({ action: false, documentData: null });
    };

    const uploadFile = (formData) => {
        // const file = formData.file instanceof FileList ? formData.file[0] : formData.file;
        const object = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: userDetails.facilityRefNo,
            answerRefNo: ansRefNo ? ansRefNo : "",
            category: formData.category,
            nameOfDocument: formData.nameOfDocument,
            validUpTo: selectDate !== null ? moment(selectDate.$d).format("YYYY-MM-DD") : "",
            update: false
        }
        const data = new FormData()
        data.append("file", files);
        if (files !== null) {
            documentUpload(data, object)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllDocumentList(payloadData);
                    if (callFrom == "supporting-document") {
                        getAssessmentQuestionsAndAnswer()
                    }
                    setSelectDocType("");
                    reset({ category: "", nameOfDocument: "", file: "" });
                    setShowFileSize(null);
                    setFileAlreadyExists(false);
                    setFiles(null);
                    setSelectDate("");
                    setOpenUploadDialog({ action: false, documentData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 409) {
                        setFileAlreadyExists(true);
                        setOpenSnackBar({
                            "action": true,
                            "type": "warning",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    } else if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Please you can choose file",
            })
        }
    };

    const updateFile = (formData) => {
        // const file = formData.file instanceof FileList ? formData.file[0] : formData.file;
        const object = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: userDetails.facilityRefNo,
            answerRefNo: ansRefNo ? ansRefNo : "",
            category: formData.category,
            nameOfDocument: formData.nameOfDocument,
            validUpTo: selectDate !== null ? moment(selectDate.$d).format("YYYY-MM-DD") : "",
            update: true
        }
        const data = new FormData();
        data.append("file", files);
        documentUpload(data, object)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllDocumentList(payloadData);
                setSelectDocType("");
                reset({ category: "", nameOfDocument: "", file: "" });
                setShowFileSize(null);
                setFileAlreadyExists(false);
                setFiles(null);
                setSelectDate("");
                setOpenUploadDialog({ action: false, documentData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const handleFilesDropped = (droppedFiles) => {
        const file = droppedFiles[0];
        if (file) {
            const validFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png'];
            const fileSize = file?.size;
            if (!validFileTypes.includes(file.type)) {
                setOpenSnackBar({
                    "action": true,
                    "type": "warning",
                    "message": "Invalid file type",
                })
                setButtonDisabled(true);
            } else if (fileSize !== undefined) {
                const fileSizeMB = fileSize / (1024 * 1024);
                setShowFileSize(`${fileSizeMB?.toFixed(2)} MB`);
                if (fileSize > 5 * 1024 * 1024) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "File exceeds 5MB",
                    })
                    setButtonDisabled(true);
                } else {
                    setButtonDisabled(false);
                };
            } else {
                setButtonDisabled(false);
            }
        };
        setFiles(file);
    };

    return (
        <div>
            <material.Dialog maxWidth="sm" fullWidth open={openUploadDialog.action}>
                <material.DialogTitle className='fw-bold'>Document Upload</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-12">
                            <material.FormControl variant="standard" fullWidth required color='success' margin="dense">
                                <material.InputLabel id="areaUnit">Category</material.InputLabel>
                                <material.Select
                                    {...register("category", { required: true })}
                                    id="areaUnit"
                                    value={selectDocType}
                                    onChange={handleChangeDocumentType}
                                    readOnly={openUploadDialog.documentData !== null || fileAlreadyExists ? true : false}
                                >
                                    <material.MenuItem value="Policy & Procedure">Policy & Procedure</material.MenuItem>
                                    <material.MenuItem value="Permit & Clearance">Permit & Clearance</material.MenuItem>
                                    <material.MenuItem value="Code of Conduct">Code of Conduct</material.MenuItem>
                                    <material.MenuItem value="Audit 1st Party">Audit 1st Party</material.MenuItem>
                                    <material.MenuItem value="Audit 2nd Party">Audit 2nd Party</material.MenuItem>
                                    <material.MenuItem value="Audit 3rd Party">Audit 3rd Party</material.MenuItem>
                                    <material.MenuItem value="Sector Specific Certification">Sector Specific Certification</material.MenuItem>
                                    <material.MenuItem value="Others">Others</material.MenuItem>
                                </material.Select>
                            </material.FormControl>
                        </div>
                        <div className="col-12">
                            <material.TextField
                                required
                                margin="dense"
                                label="Name of Document"
                                id="nameOfDocument"
                                variant="standard"
                                type="text"
                                fullWidth
                                color='success'
                                InputProps={{ readOnly: openUploadDialog.documentData !== null || fileAlreadyExists ? true : false }}
                                {...register("nameOfDocument", { required: true })}
                            />
                        </div>
                        <div className='col-12 mt-2'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="validUpTo"
                                    control={control}
                                    defaultValue={null}
                                    render={({ field }) => (
                                        <material.DatePicker
                                            label="Valid Up to *"
                                            format='DD-MM-YYYY'
                                            value={selectDate}
                                            onChange={(newValue) => {
                                                setSelectDate(newValue);
                                                field.onChange(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    {...params}
                                                    variant="standard"
                                                    color="success"
                                                    {...field}
                                                />
                                            )}
                                            slotProps={{ textField: { variant: 'standard', color: 'success', readOnly: true } }}
                                            sx={{ width: '100%' }}
                                            disablePast
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                        {/* <div className='col-12 mt-2'>
                            <material.InputLabel>Choose File*</material.InputLabel>
                            <input
                                required
                                type="file"
                                label="Choose File"
                                accept=".pdf, .doc, .docx, .jpeg, .png"
                                margin="dense"
                                {...register("file", { required: true })}
                            />
                            <material.Typography sx={{ color: buttonDisabled ? "red" : "green" }}>{showFileSize}</material.Typography>
                        </div> */}
                        <div className='col-12 mt-3'>
                            <FileDropzone onFilesDropped={handleFilesDropped} register={register} />
                            <span className='d-flex justify-content-center align-item-center'>
                                <material.Typography sx={{ color: buttonDisabled ? "red" : "green" }}>{showFileSize}</material.Typography>
                            </span>
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions sx={{ p: 2 }}>
                    <material.Button variant="outlined" color='error' sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />} onClick={cancel}>Cancel</material.Button>
                    {fileAlreadyExists || openUploadDialog.documentData !== null ? (
                        <material.Button variant="outlined" color='success' sx={{ textTransform: "none" }} disabled={!isValid || buttonDisabled} startIcon={<material.DoneIcon />} onClick={handleSubmit(updateFile)}>Update</material.Button>
                    ) : (
                        <material.Button variant="outlined" color='success' sx={{ textTransform: "none" }} disabled={!isValid || buttonDisabled} startIcon={<material.DoneIcon />} onClick={handleSubmit(uploadFile)}>Save</material.Button>
                    )}
                </material.DialogActions>
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const FileDropzone = ({ onFilesDropped, register }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [fileNames, setFileNames] = useState([]);
    const fileInputRef = useRef(null);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const filesArray = Array.from(e.dataTransfer.files);
            onFilesDropped(filesArray);
            setFileNames(filesArray.map(file => file.name));
            e.dataTransfer.clearData();
        }
    };

    const handleFileInputChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const filesArray = Array.from(e.target.files);
            onFilesDropped(filesArray);
            setFileNames(filesArray.map(file => file.name));
        }
    };

    const openFileDialog = () => {
        fileInputRef.current.click();
    };

    return (
        <div
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            style={{
                border: '2px dashed #cccccc',
                borderRadius: '4px',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                backgroundColor: isDragging ? '#e0e0e0' : '#ffffff',
            }}
            onClick={openFileDialog}
        >
            <p>Drag 'n' drop some files here, or click to select files upto 5 MB</p>
            <input
                type="file"
                accept=".pdf, .doc, .docx, .jpeg, .png"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileInputChange}
            // {...register("file", { required: true })}
            />
            {fileNames.length > 0 && (
                <div style={{ marginTop: '10px' }}>
                    <h4>Selected Files:</h4>
                    <ul>
                        {fileNames.map((name, index) => (
                            <li key={index}>{name}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};


export default UploadDocumentDialog;