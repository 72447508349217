import React, { useEffect, useState } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Snackbar from '../toaster-message/Snackbar';
import DatePeriod from '../shared/DatePeriod';
import { generateFromDateAndToDate } from '../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../dialogs/DeleteRecordConfirmationDialog';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../services/sub-issue-services/AddSubissueService';


function AddMaterialDialog(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { openAddMaterial, setOpenAddMaterial, getMaterialUsedData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [materialType, setMaterialType] = React.useState('');
    const [unit, setUnit] = React.useState('');
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openAddMaterial.action && openAddMaterial.materialData) {
            reset(openAddMaterial.materialData);
            setMaterialType(openAddMaterial.materialData.type_of_material);
            setUnit(openAddMaterial.materialData.unit);
        }
    }, [openAddMaterial.action === true]);

    const handleClickClose = () => {
        reset({ material: "", amount: "", periodType: "", selectedYear: '' });
        setMaterialType("");
        setUnit("");
        setOpenAddMaterial({ action: false, materialData: null });
    };
    const submitMaterialData = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "type_of_record": "MATERIAL_USED",
                "material": data.material,
                "type_of_material": data.type_of_material,
                "amount": Number(data.amount),
                "unit": data.unit,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getMaterialUsedData(getFilterDate, payloadData)
                    setOpenAddMaterial({ action: false, materialData: null });
                    reset({ material: "", amount: "" });
                    setMaterialType("");
                    setUnit("");
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateMaterialData = async (data) => {
        const refNo = openAddMaterial.materialData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "material": data.material,
            "type_of_material": data.type_of_material,
            "amount": Number(data.amount),
            "unit": data.unit,
            "type_of_record": "MATERIAL_USED",
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getMaterialUsedData(getFilterDate, payloadData)
                setOpenAddMaterial({ action: false, materialData: null });
                reset({ material: "", amount: "" });
                setMaterialType("");
                setUnit("");
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const selectMaterialType = (event) => {
        setMaterialType(event.target.value);
    };

    const selectUnit = (event) => {
        setUnit(event.target.value);
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openAddMaterial.materialData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        await commonDelete(payload)
            .then((resp) => {
                getMaterialUsedData(getFilterDate, payloadData)
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ material: "", amount: "" });
                setMaterialType("");
                setUnit("");
                setOpenAddMaterial({ action: false, materialData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddMaterial.action} maxWidth="lg" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddMaterial.materialData ? `Edit ${treeViewData?.subIssue}` : `Add ${treeViewData?.subIssue}`}</material.DialogTitle>
                <material.DialogContent>
                    {openAddMaterial.materialData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Material</material.Typography>
                            </div>
                            <div className='col-lg-3 py-2'>
                                <material.TextField
                                    {...register("material", { required: true, })}
                                    required
                                    label="Material"
                                    id="materials"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                />
                            </div>
                            <div className='col-lg-3 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Type of Material</material.InputLabel>
                                    <material.Select
                                        {...register("type_of_material", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={materialType}
                                        onChange={selectMaterialType}
                                        label="Type of material"
                                    >
                                        <material.MenuItem value={"Renewable"}>Renewable</material.MenuItem>
                                        <material.MenuItem value={"Non Renewable"}>Non Renewable</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-3 py-2'>
                                <material.TextField
                                    {...register("amount", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Quantity"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.amount && errors.amount.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-lg-3 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Unit</material.InputLabel>
                                    <material.Select
                                        {...register("unit", { required: true, })}
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={unit}
                                        onChange={selectUnit}
                                        label="Unit"
                                    >
                                        <material.MenuItem value={"Tons"}>Tons</material.MenuItem>
                                        <material.MenuItem value={"Kl"}>Kl</material.MenuItem>
                                        <material.MenuItem value={"Thousand m3"}>Thousand m3</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button onClick={handleClickClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddMaterial.materialData ? (
                        <>
                            <material.Button color='success' variant="outlined" onClick={handleSubmit(updateMaterialData)} sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddMaterial.materialData.rejection_note === null) || (openAddMaterial.materialData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button color='success' variant="outlined" onClick={handleSubmit(submitMaterialData)} sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    );
}

export default AddMaterialDialog;