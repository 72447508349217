import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AddPoliticalContributions from '../../../sub-issues-dialogs/trade and commerce dialog/AddPoliticalContributions';
import DateFilter from '../../../shared/DateFilter';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import moment from 'moment';
import RejectionNote from '../../../dialogs/RejectionNote';
import Snackbar from '../../../toaster-message/Snackbar';
import { getSubIssueRecordData, saveSubIssueData, updateSubIssueData } from '../../../services/sub-issue-services/AddSubissueService';
import Pagination from '../../../shared/pagination/Pagination';
import ApplicableDialog from '../../../dialogs/ApplicableDialog';


const label = { inputProps: { 'aria-label': 'Color switch demo' } };
var usersRole;
var recordRefNo = [];

function PoliticalContributions(props) {

    const location = useLocation();
    const treeViewData = location.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [openPoliticalContributions, setOpenPoliticalContributions] = useState({ action: false, politicalContributionsTableData: null });
    const [politicalContributionsTableData, setPoliticalContributionsTableData] = useState([]);
    const [politicalContributionsTab, setPoliticalContributionsTab] = useState('Political Contributions');
    const [applicable, setApplicable] = useState(true);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [getFilterDate, setGetFilterDate] = useState(null);

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        getPoliticalContributions()
    }, [politicalContributionsTab]);

    const handleChange = (event, newValue) => {
        setPoliticalContributionsTab(newValue);
    };

    const getFormDateToDate = (data) => {
        setGetFilterDate(data);
        getPoliticalContributions(data, paginationData);
    };

    const getPoliticalContributions = (filterDate, paginationValue) => {
        if (politicalContributionsTab === "Political Contributions") {
            let forApproval = userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? true : null;
            let approveHistory = false;
            const payload = {
                subissueName: treeViewData.subIssue,
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                forApproval,
                approveHistory,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            getSubIssueRecordData(payload)
                .then((resp) => {
                    setPoliticalContributionsTableData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setApplicableRefNo(resp.data.applicableRecord?.ref_no ? resp.data.applicableRecord.ref_no : null);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else if (politicalContributionsTab === "Political Contributions History") {
            let forApproval = true;
            let approveHistory = true;
            const payload = {
                subissueName: treeViewData.subIssue,
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                forApproval,
                approveHistory,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            getSubIssueRecordData(payload)
                .then((resp) => {
                    setPoliticalContributionsTableData(resp.data.records.content);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };
    const addPoliticalContributions = () => {
        setOpenPoliticalContributions({ action: true, politicalContributionsTableData: null })
    };

    const editPoliticalContributions = (rowData) => {
        setOpenPoliticalContributions({ action: true, politicalContributionsTableData: rowData })
    };

    const clickCheckBox = (value) => {
        setOpenPoliticalContributions({ action: false, politicalContributionsTableData: null });
        if (recordRefNo.includes(value)) {
            // recordRefNo.pop(value)
            recordRefNo = recordRefNo.filter(item => item !== value);
        } else {
            recordRefNo.push(value)
        }
    };

    const sendApproval = async () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true
            }
            updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getPoliticalContributions(getFilterDate, paginationData);
                    recordRefNo = [];
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const approved = async () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true,
                "approved_by": userDetails.username,
                "approved_date": moment().format("YYYY-MM-DD"),
                "approved_status": "APPROVED",
                "rejection_note": "",
            }
            updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getPoliticalContributions(getFilterDate, paginationData);
                    recordRefNo = [];
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const rejectRecord = async () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        }
    };

    const getRejectionNoteData = async (noteData) => {
        const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
        const subissueName = treeViewData.subIssue;
        const payload = {
            send_for_approval: false,
            "rejection_note": noteData.rejectionNote,
            "approved_status": "",
        }
        updateSubIssueData(payload, refNo, subissueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getPoliticalContributions(getFilterDate, paginationData);
                recordRefNo = [];
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };

    const getApplicableData = (data) => {
        if (applicableRefNo === null) {
            let subIssueName = treeViewData?.subIssue;
            const payload = {
                applicable: false,
                applicable_record: true,
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
            }
            saveSubIssueData(payload, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getPoliticalContributions(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else {
            let subIssueName = treeViewData?.subIssue;
            let refNo = applicableRefNo;
            const payload = {
                applicable: applicable ? false : true,
            }
            updateSubIssueData(payload, refNo, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getPoliticalContributions(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const getPaginationData = (filterData, paginationData) => {
        setRowsPerPage(paginationData.size);
        setPage(paginationData.page);
        getPoliticalContributions(filterData, paginationData);
    };


    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className='row'>
                        <div className='col-6'>
                            <span><material.Typography variant="h5" className="headingText">{treeViewData?.subIssue}</material.Typography></span></div>
                        <div className='col-6'>
                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") || userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? null : (
                                <span className="float-end">
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} onChange={applicableNotApplicable}
                                            checked={applicable}
                                            color="secondary" />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className='mt-3'>
                        <material.Box sx={{ width: '100%', typography: 'body1' }}>
                            <material.TabContext value={politicalContributionsTab}>
                                <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <material.TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <material.Tab className='text-capitalize' label="Political Contributions" value="Political Contributions" />
                                        <material.Tab className='text-capitalize' label="Political Contributions History" value="Political Contributions History" />
                                    </material.TabList>
                                </material.Box>
                                <material.TabPanel value="Political Contributions">
                                    <div className="row mt-2 align-items-center">
                                        <div className="col-lg-6">
                                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                <DateFilter
                                                    getFormDateToDate={getFormDateToDate}
                                                />
                                            )}
                                        </div>
                                        <div className="col-6" hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                            <span className='float-end'>
                                                {politicalContributionsTableData?.length ? (
                                                    <div>
                                                        <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")} color='success'
                                                            disabled={!applicable || !recordRefNo.length}
                                                            sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approved}>Approve</material.Button>
                                                        <material.Button sx={{ textTransform: "none" }} hidden={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")}
                                                            disabled={!applicable || !recordRefNo.length}
                                                            variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectRecord}>Reject</material.Button>
                                                    </div>
                                                ) : null}
                                                <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_APPROVER")} color='success'
                                                    disabled={!applicable}
                                                    sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendApproval}>Send for Approval</material.Button>
                                                <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_APPROVER")} color='success'
                                                    disabled={!applicable}
                                                    sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addPoliticalContributions}>Add Political Contributions</material.Button>
                                            </span>
                                        </div>
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className='row'>
                                            <div className="col-12">
                                                <material.TableContainer >
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow >
                                                                <StyledTableCell>Support Type</StyledTableCell>
                                                                <StyledTableCell>Purpose of Support</StyledTableCell>
                                                                <StyledTableCell>Amount (in Million)</StyledTableCell>
                                                                <StyledTableCell>Currency Code</StyledTableCell>
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                <StyledTableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>{userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                                {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                    <StyledTableCell>Rejection Note</StyledTableCell>
                                                                )}
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {politicalContributionsTableData.length ? politicalContributionsTableData.map((item, k) => (
                                                                <material.TableRow key={k}
                                                                    sx={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                    onClick={((userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")) && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true) ?
                                                                        () => editPoliticalContributions(item) : null}
                                                                >
                                                                    <material.TableCell>{item.support_type}</material.TableCell>
                                                                    <material.TableCell className='text-break' size='small' style={{ width: "10rem" }}>{item.purpose_of_support}</material.TableCell>
                                                                    <material.TableCell>{item.amount}</material.TableCell>
                                                                    <material.TableCell>{(item.currency_code === null) || (item.currency_code === "") ? 'N/A' : item.currency_code}</material.TableCell>
                                                                    <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                                                        {userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") ? (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.send_for_approval} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                                        ) : (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.approved_status === "APPROVED"} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                                        )}
                                                                    </material.TableCell>
                                                                    {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                        <material.TableCell>{(item.rejection_note === null) || (item.rejection_note === "") ? "No" : (
                                                                            <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                        )}
                                                                        </material.TableCell>
                                                                    )}
                                                                </material.TableRow>
                                                            )) : (
                                                                <material.TableRow>
                                                                    <material.TableCell colSpan={12}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )}
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                                <Pagination
                                                    getPaginationData={getPaginationData}
                                                    totalElements={totalElements}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    getFilterDate={getFilterDate}
                                                />
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                                <material.TabPanel value="Political Contributions History">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        </div>
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className='row'>
                                            <div className="col-12">
                                                <material.TableContainer >
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow >
                                                                <StyledTableCell>Support Type</StyledTableCell>
                                                                <StyledTableCell>Purpose of Support</StyledTableCell>
                                                                <StyledTableCell>Amount (in Million)</StyledTableCell>
                                                                <StyledTableCell>Currency Code</StyledTableCell>
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                <StyledTableCell>Approval Date</StyledTableCell>
                                                                <StyledTableCell>Status</StyledTableCell>
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {politicalContributionsTableData?.length ? politicalContributionsTableData.map((item, i) => (
                                                                <material.TableRow key={i}>
                                                                    <material.TableCell>{item.support_type}</material.TableCell>
                                                                    <material.TableCell className='text-break' size='small' style={{ width: "10rem" }}>{item.purpose_of_support}</material.TableCell>
                                                                    <material.TableCell>{item.amount}</material.TableCell>
                                                                    <material.TableCell>{(item.currency_code === null) || (item.currency_code === "") ? 'N/A' : item.currency_code}</material.TableCell>
                                                                    <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>
                                                                        {item.approved_status === "APPROVED" ? <span className="badge bg-success">
                                                                            APPROVED
                                                                        </span> : null}
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )) : (
                                                                <material.TableRow >
                                                                    <material.TableCell colSpan={12}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )}
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                                <Pagination
                                                    getPaginationData={getPaginationData}
                                                    totalElements={totalElements}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    getFilterDate={getFilterDate}
                                                />
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                            </material.TabContext>
                        </material.Box>
                    </div>
                </material.Box>
            </material.Box>
            <AddPoliticalContributions
                openPoliticalContributions={openPoliticalContributions}
                setOpenPoliticalContributions={setOpenPoliticalContributions}
                getPoliticalContributions={getPoliticalContributions}
                treeViewData={treeViewData}
                page={page}
                rowsPerPage={rowsPerPage}
                getFilterDate={getFilterDate}
            />
            <ApplicableDialog
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getApplicableData={getApplicableData}
                applicable={applicable}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </div>
    );
}

export default PoliticalContributions;