import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import DatePeriod from '../../shared/DatePeriod';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';

function AddABCPolicyTraining(props) {
    const { openAddABCPolicyTraining, setOpenAddABCPolicyTraining, getAllABCPolicyTrainingData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [category, setCategory] = useState("");
    const [gender, setGender] = useState("");
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openAddABCPolicyTraining.action && openAddABCPolicyTraining.aBCPolicyTrainingTableData) {
            setCategory(openAddABCPolicyTraining.aBCPolicyTrainingTableData.category);
            setGender(openAddABCPolicyTraining.aBCPolicyTrainingTableData.gender);
            reset(openAddABCPolicyTraining.aBCPolicyTrainingTableData);
        }
    }, [openAddABCPolicyTraining.action === true]);

    const selectCategory = (event) => {
        setCategory(event.target.value);
    };

    const selectGender = (event) => {
        setGender(event.target.value);
    };

    const handleClose = () => {
        reset({ other_specify: "", number: "", training_on_abc_policy: "",fe_male: "",male: "",other: "" });
        setCategory("");
        setGender("");
        setOpenAddABCPolicyTraining({ action: false, aBCPolicyTrainingTableData: null });
    };

    const submitABCPolicyTraining = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "category": data.category,
                "other_specify": data.other_specify,
                "fe_male": data.fe_male,
                "male": data.male,
                "other": data.other,
                // "gender": data.gender,
                // "number": data.number,
                // "training_on_abc_policy": data.training_on_abc_policy,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllABCPolicyTrainingData(getFilterDate, payloadData);
                    reset({ other_specify: "", number: "", training_on_abc_policy: "",fe_male: "",male: "",other: "" });
                    setCategory("");
                    setGender("");
                    setOpenAddABCPolicyTraining({ action: false, aBCPolicyTrainingTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateABCPolicyTraining = async (data) => {
        console.log(data)
        const refNo = openAddABCPolicyTraining.aBCPolicyTrainingTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "category": data.category,
            "other_specify": category === "Other (Specify)" ? data.other_specify : "",
            "fe_male": data.fe_male,
            "male": data.male,
            "other": data.other
            // "gender": data.gender,
            // "number": data.number,
            // "training_on_abc_policy": data.training_on_abc_policy,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllABCPolicyTrainingData(getFilterDate, payloadData);
                reset({ other_specify: "", number: "", training_on_abc_policy: "",fe_male: "",male: "",other: "" });
                setCategory("");
                setGender("");
                setOpenAddABCPolicyTraining({ action: false, aBCPolicyTrainingTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAddABCPolicyTraining.aBCPolicyTrainingTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ other_specify: "", number: "", training_on_abc_policy: "",fe_male: "",male: "",other: "" });
                setCategory("");
                setGender("");
                getAllABCPolicyTrainingData(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddABCPolicyTraining({ action: false, aBCPolicyTrainingTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddABCPolicyTraining.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddABCPolicyTraining.aBCPolicyTrainingTableData ? "Edit ABC Policy Training" : "Add ABC Policy Training"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddABCPolicyTraining.aBCPolicyTrainingTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>ABC Policy Training</material.Typography>
                            </div>
                            <div className='col-6 mt-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Category of Employee & Worker</material.InputLabel>
                                    <material.Select
                                        {...register("category", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={category}
                                        onChange={selectCategory}
                                        label="Category of Employee & Worker"
                                    >
                                        <material.MenuItem value={"Board of Directors"}>Board of Directors</material.MenuItem>
                                        <material.MenuItem value={"Key Managerial Personnel"}>Key Managerial Personnel</material.MenuItem>
                                        <material.MenuItem value={"Employee Permanent"}>Employee Permanent</material.MenuItem>
                                        <material.MenuItem value={"Employee other than Permanent"}>Employee other than Permanent</material.MenuItem>
                                        <material.MenuItem value={"Worker Permanent"}>Worker Permanent</material.MenuItem>
                                        <material.MenuItem value={"Worker other than Permanent"}>Worker other than Permanent</material.MenuItem>
                                        <material.MenuItem value={"Other (Specify)"}>Other (Specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {category === "Other (Specify)" ? (
                                <div className='col-6 mt-2'>
                                    <material.TextField
                                        {...register("other_specify", { required: true, maxLength: 20 })}
                                        required
                                        label="Other Category (Specify)"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors?.other_specify?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                            {/* <div className='col-6 mt-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Gender</material.InputLabel>
                                    <material.Select
                                        {...register("gender", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={gender}
                                        onChange={selectGender}
                                        label="Material Sourced"
                                    >
                                        <material.MenuItem value={"Male"}>Male</material.MenuItem>
                                        <material.MenuItem value={"Female"}>Female</material.MenuItem>
                                        <material.MenuItem value={"Others"}>Others</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div> */}
                            {/* <div className='col-6 mt-2'>
                                <material.TextField
                                    {...register("number", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.number && errors.number.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-6 mt-2'>
                                <material.TextField
                                    {...register("training_on_abc_policy", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Training on ABC Policy"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.training_on_abc_policy && errors.training_on_abc_policy.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div> */}
                            <div className='col-6 mt-2'>
                                <material.TextField
                                    {...register("male", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Male Training on ABC Policy"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.male && errors.male.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-6 mt-2'>
                                <material.TextField
                                    {...register("fe_male", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Female Training on ABC Policy"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.fe_male && errors.fe_male.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-6 mt-2'>
                                <material.TextField
                                    {...register("other", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Others Training on ABC Policy"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.other && errors.other.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddABCPolicyTraining.aBCPolicyTrainingTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateABCPolicyTraining)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddABCPolicyTraining.aBCPolicyTrainingTableData.rejection_note === null) || (openAddABCPolicyTraining.aBCPolicyTrainingTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(submitABCPolicyTraining)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddABCPolicyTraining;