import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import UploadDocumentDialog from './UploadDocumentDialog';
import { deleteDocumentByRefNo, downloadDocument, getDocumentList } from '../../services/DocumentUploadService';
import Snackbar from '../../toaster-message/Snackbar';
import { getSitesByCorporateRefNo } from '../../services/SiteService';
import Pagination from '../../shared/pagination/Pagination';
import moment from 'moment';
import BarsLoader from '../../shared/loader/BarsLoader';
import DeletedPermissionDialog from './DeletedPermissionDialog';

function UploadDocument(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);

    const [openUploadDialog, setOpenUploadDialog] = useState({ action: false, documentData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [facilityData, setFacilityData] = useState([]);
    const [getFacilityName, setGetFacilityName] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = React.useState(0);
    const [facilityRefNo, setFacilityRefNo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [openDeletePermissionDialog, setOpenDeletePermissionDialog] = useState({ action: false, document: null });

    const paginationData = {
        facilityRefNo: facilityRefNo,
        page: page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (userDetails.roles.includes("ROLE_CORPORATE_EDITOR") || userDetails.roles.includes("ROLE_CORPORATE_APPROVER")) {
            facilityList()
        } else {
            getAllDocumentList()
        }
    }, []);

    const facilityList = () => {
        getSitesByCorporateRefNo(userDetails.corporateRefNo)
            .then((resp) => {
                setFacilityData(resp.data);
                const facilityName = resp.data?.map((ele) => ele.assetName);
                setGetFacilityName(facilityName);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const selectFacility = (e, newValue) => {
        let facilityRefNo = null;
        if (newValue !== null) {
            facilityRefNo = facilityData.length ? facilityData.filter((ele) => ele.assetName === newValue).map((resp) => resp.refNo)[0] : null;
            if (facilityRefNo !== null) {
                const payload = {
                    facilityRefNo: facilityRefNo,
                    page: 0,
                    size: 10
                }
                getAllDocumentList(payload);
                setFacilityRefNo(facilityRefNo);
            }
        } else {
            setDocumentList([]);
        }
    };

    const getAllDocumentList = (payloadData) => {
        setIsLoading(true);
        const payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: userDetails.roles.includes("ROLE_CORPORATE_EDITOR") || userDetails.roles.includes("ROLE_CORPORATE_APPROVER") ? payloadData.facilityRefNo : userDetails.facilityRefNo,
            nameOfDocument: "",
            page: payloadData?.page ? payloadData.page : 0,
            size: payloadData?.size ? payloadData.size : 10,
            search: payloadData?.search ? payloadData.search : ""
        }
        getDocumentList(payload)
            .then((resp) => {
                setDocumentList(resp.data.content);
                setPage(resp.data.number);
                setRowsPerPage(resp.data.size);
                setTotalElements(resp.data.totalElements);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const getPaginationData = (filterData, paginationData) => {
        setRowsPerPage(paginationData.size);
        setPage(paginationData.page);
        const payload = {
            facilityRefNo: facilityRefNo,
            page: paginationData.page,
            size: paginationData.size
        }
        getAllDocumentList(payload);
    };

    const addDocument = () => {
        setOpenUploadDialog({ action: true, documentData: null });
    };

    const downloadFile = (rowData) => {
        const fileType = rowData.documentType === "PDF" ? ".pdf" : rowData.documentType === "DOC" ? ".doc" : rowData.documentType === "DOCX" ? ".docx" : rowData.documentType === "PNG" ? ".png" : rowData.documentType === "JPEG" ? ".jpeg" : "";
        downloadDocument(rowData)
            .then((resp) => {
                const blob = new Blob([resp.data], { type: resp.headers['content-type'] });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = rowData.nameOfDocument.concat(fileType);
                link.click();
                window.URL.revokeObjectURL(link.href);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const editDocument = (rowData) => {
        setOpenUploadDialog({ action: true, documentData: rowData });
    };

    const searchCategory = (value) => {
        const payload = {
            facilityRefNo: userDetails.roles.includes("ROLE_CORPORATE_EDITOR") || userDetails.roles.includes("ROLE_CORPORATE_APPROVER") ? facilityRefNo : "",
            search: value
        }
        getAllDocumentList(payload)
    };

    const deleteDocument = (rowData) => {
        setOpenDeletePermissionDialog({ action: true, document: rowData })
    };

    const getDeleteConformationData = (deleteData) => {
        deleteDocumentByRefNo(deleteData.refNo)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllDocumentList(paginationData);
                setOpenDeletePermissionDialog({ action: false, document: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    return (
        <div>
            <div className="row">
                <div className={userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? "col-4" : ""}>
                    {userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? (
                        <material.Autocomplete
                            sx={{ mb: 2, mt: -3 }}
                            margin="dense"
                            id="sitesName"
                            onChange={selectFacility}
                            options={getFacilityName}
                            renderInput={(params) => <material.TextField {...params} variant="standard" label="Facility Name"
                                fullWidth
                                required
                            />}
                        />
                    ) : null}
                </div>
                <div className="col-3">
                    {documentList.length ? (
                        <material.TextField
                            sx={{ mb: 2, mt: -3 }}
                            fullWidth
                            variant="standard"
                            label="Search"
                            onChange={(e) => searchCategory(e.target.value)}
                        />
                    ) : null}
                </div>
                <div className={userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? "" : "col-9 mb-2"}>
                    {userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? (
                        <span className='float-end'>
                            <material.Button variant="contained" color='success' sx={{ textTransform: "none", mt: -2 }}
                                startIcon={<material.AddIcon />} onClick={addDocument}>Add Document</material.Button>
                        </span>
                    ) : null}
                </div>
                <div className='col-12'>
                    <material.TableContainer>
                        <material.Table>
                            <material.TableHead>
                                <material.TableRow>
                                    <StyledTableCell>Document Category</StyledTableCell>
                                    <StyledTableCell>Name of Document</StyledTableCell>
                                    <StyledTableCell>Validity Date</StyledTableCell>
                                    {/* <StyledTableCell>Document</StyledTableCell> */}
                                    <StyledTableCell align='center'>Action</StyledTableCell>
                                </material.TableRow>
                            </material.TableHead>
                            <material.TableBody>
                                {isLoading ? (
                                    <material.TableRow>
                                        <material.TableCell colSpan={5}>
                                            <BarsLoader />
                                        </material.TableCell>
                                    </material.TableRow>
                                ) : (
                                    <>
                                        {documentList.length ? documentList.map((item, i) => (
                                            <material.TableRow key={i}>
                                                <material.TableCell>{item.category}</material.TableCell>
                                                <material.TableCell>{item.nameOfDocument}</material.TableCell>
                                                <material.TableCell>{(item.validUpTo === "" || item.validUpTo === null) ? "N/A" : moment(item.validUpTo).format("DD-MM-YYYY")}</material.TableCell>
                                                {/* <material.TableCell>{item.documentType}</material.TableCell> */}
                                                <material.TableCell align='center'>
                                                    {userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? (
                                                        <>
                                                            <material.IconButton title='Edit Document' aria-label="create" disabled={item.answerRefNo !== ""} size="large" onClick={() => editDocument(item)}>
                                                                <material.CreateIcon color={(item.answerRefNo === "") ? "success" : 'disabled'} />
                                                            </material.IconButton>
                                                            <material.IconButton title='Delete Document' aria-label="create" size="large" disabled={item.answerRefNo !== ""} onClick={() => deleteDocument(item)}>
                                                                <material.DeleteIcon color={(item.answerRefNo === "") ? "error" : 'disabled'} />
                                                            </material.IconButton>
                                                        </>
                                                    ) : null}
                                                    <material.IconButton title='Download Document' aria-label="create" size="large" onClick={() => downloadFile(item)}>
                                                        <material.DownloadIcon color='secondary' />
                                                    </material.IconButton>
                                                </material.TableCell>
                                            </material.TableRow>
                                        )) : (
                                            <material.TableRow >
                                                <material.TableCell colSpan={10}>
                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                </material.TableCell>
                                            </material.TableRow>
                                        )}
                                    </>
                                )}
                            </material.TableBody>
                        </material.Table>
                    </material.TableContainer>
                    <Pagination
                        getPaginationData={getPaginationData}
                        totalElements={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                    />
                </div>
            </div>
            <UploadDocumentDialog
                openUploadDialog={openUploadDialog}
                setOpenUploadDialog={setOpenUploadDialog}
                getAllDocumentList={getAllDocumentList}
                rowsPerPage={rowsPerPage}
                page={page}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeletedPermissionDialog
                openDeletePermissionDialog={openDeletePermissionDialog}
                setOpenDeletePermissionDialog={setOpenDeletePermissionDialog}
                getDeleteConformationData={getDeleteConformationData}
            />
        </div>
    );
}

// const DeletedPermissionDialog = (props) => {

//     const { openDeletePermissionDialog, setOpenDeletePermissionDialog, getDeleteConformationData } = props;
//     const [selectValue, setSelectValue] = useState("");
//     const handleChange = (event) => {
//         let value = event.target.value;
//         setSelectValue(value);
//         if (value === "Yes") {
//             getDeleteConformationData(openDeletePermissionDialog.document);
//             setSelectValue("");
//         } else {
//             setSelectValue("");
//             setOpenDeletePermissionDialog({ action: false, document: null });
//         };
//     };

//     return (
//         <div>
//             <material.Dialog open={openDeletePermissionDialog.action} maxWidth="auto">
//                 <material.DialogContent>
//                     <div className='row'>
//                         <div className='col-12 d-flex flex-row align-items-center'>
//                             <div className='me-2 fw-bold text-dark'>Are you sure, you want to delete this document ?</div>
//                             <material.FormControl>
//                                 <material.RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
//                                     name="row-radio-buttons-group"
//                                     value={selectValue}
//                                     onChange={handleChange}
//                                 >
//                                     <material.FormControlLabel value="No" label="No" control={<material.Radio color='secondary' />} />
//                                     <material.FormControlLabel value="Yes" label="Yes" control={<material.Radio color='secondary' />} />
//                                 </material.RadioGroup>
//                             </material.FormControl>
//                         </div>
//                     </div>
//                 </material.DialogContent>
//             </material.Dialog>
//         </div>
//     )
// };


export default UploadDocument;