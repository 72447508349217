import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import DatePeriod from '../../shared/DatePeriod';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { otherMaterialsUsedSubIssueDelete, saveOrUpdateOtherMaterialsUsedData } from '../../services/sub-issue-services/AddSubissueService';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';


const nameData = ["C2F6", "C3F8", "C4F10", "C5F12", "C6F14", "C7F16", "c-C4F8", "CF4", "CH4 Biogenic", "CH4 Fossil (Combustion)", "CH4 Fossil (Fugitive)", "CH4 Fossil (Process)", "CO2", "HCFC-141b", "HCFC-142b", "HFC-125", "HFC-134a", "HFC-134", "HFC-143a", "HFC-143", "HFC-152a", "HFC-227ea", "HFC-23", "HFC-236fa", "HFC-245fa", "HFC-32", "HFC-365mfc", "HFC-41", "HFC-43-10-mee", "HFC-43-10mee", "N2O", "NF3", "SF6",];
const refrigerantUnit = ['Tonne'];
const otherMaterialUnit = ['Tonne', 'Kl', 'Km3']

function AddOtherMaterialsUsed(props) {
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, setValue, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const { openAddOtherMaterialsUsed, setOpenAddOtherMaterialsUsed, getAllOtherMaterialsUsedData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [unit, setUnit] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const payload = {
        page,
        size: rowsPerPage
    };

    const mapOtherMaterialsUsedData = (data) => {
        return {
            refNo: data.ref_no,
            applicable: data.applicable,
            type: data.type,
            purposeOfUse: data.purpose_of_use,
            name: data.name,
            quantity: data.quantity,
            grossCalorificValue: data.gross_calorific_value,
            unit: data.unit,
            standardDensity: data.standard_density
        };
    };

    useEffect(() => {
        if (openAddOtherMaterialsUsed.action && openAddOtherMaterialsUsed.otherMaterialsUsedTableData) {
            const mapData = mapOtherMaterialsUsedData(openAddOtherMaterialsUsed.otherMaterialsUsedTableData);
            setType(mapData.type);
            setName(mapData.name);
            setUnit(mapData.unit);
            reset(mapData);
        }
    }, [openAddOtherMaterialsUsed.action === true]);

    const selectType = (event) => {
        setType(event.target.value);
        setValue("purposeOfUse", "");
        setValue("quantity", "");
        setValue("grossCalorificValue", "");
        setValue("standardDensity", "");
        setValue("name", "");
        setName("");
        setUnit("");
    };

    const selectName = (event) => {
        setName(event.target.value);
    };

    const selectUnit = (event) => {
        if (event.target.value === "Tonne") {
            setValue("standardDensity", 1)
        } else {
            setValue("standardDensity", "")
        }
        setUnit(event.target.value);
    };

    const handleClose = () => {
        reset({ "purposeOfUse": "", "quantity": "", "grossCalorificValue": "", standardDensity: "", name: "" });
        setType("");
        setName("");
        setUnit("");
        setOpenAddOtherMaterialsUsed({ action: false, otherMaterialsUsedTableData: null });
    };

    const submitOtherMaterialsUsed = async (data) => {
        const payLoad = generateFromDateAndToDate(data);
        const newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            const object = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": true,
                "fromDate": payLoad?.fromDate,
                "toDate": payLoad?.toDate,
                "applicable": true,
                "quantity": parseFloat(data.quantity),
                ...data
            }
            await saveOrUpdateOtherMaterialsUsedData(object)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllOtherMaterialsUsedData(getFilterDate, payload)
                    reset({ "purposeOfUse": "", "quantity": "", "grossCalorificValue": "", standardDensity: "", name: "" })
                    setType("");
                    setName("");
                    setUnit("");
                    setOpenAddOtherMaterialsUsed({ action: false, otherMaterialsUsedTableData: null })
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        }
    };

    const updateOtherMaterialsUsed = async (data) => {
        const payloadData = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            ...data
        }
        await saveOrUpdateOtherMaterialsUsedData(payloadData)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllOtherMaterialsUsedData(getFilterDate, payload)
                reset({ "purposeOfUse": "", "quantity": "", "grossCalorificValue": "", standardDensity: "" })
                setType("");
                setName("");
                setUnit("");
                setOpenAddOtherMaterialsUsed({ action: false, otherMaterialsUsedTableData: null })
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAddOtherMaterialsUsed.otherMaterialsUsedTableData.ref_no,
        }
        otherMaterialsUsedSubIssueDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllOtherMaterialsUsedData(getFilterDate, payload);
                reset({ "purposeOfUse": "", "quantity": "", "grossCalorificValue": "", })
                setType("");
                setName("");
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddOtherMaterialsUsed({ action: false, otherMaterialsUsedTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddOtherMaterialsUsed.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddOtherMaterialsUsed.otherMaterialsUsedTableData ? "Edit Other Materials Used" : "Add Other Materials Used"}</material.DialogTitle>
                <material.DialogContent>
                    <form>
                        <div>
                            {openAddOtherMaterialsUsed.otherMaterialsUsedTableData ? null : (
                                <DatePeriod
                                    register={register}
                                    control={control}
                                />
                            )}
                        </div>
                        <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                            <div className='row'>
                                <div className='mb-2'>
                                    <material.Typography className='fw-bold'>Other Materials Used</material.Typography>
                                </div>
                                <div className='col-6'>
                                    <material.FormControl variant="standard" fullWidth>
                                        <material.InputLabel id="demo-simple-select-standard-label" required>Type</material.InputLabel>
                                        <material.Select
                                            {...register("type", { required: true, })}
                                            required
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={type}
                                            onChange={selectType}
                                            label="Material Name"
                                        >
                                            <material.MenuItem value={'Refrigerant'}>Refrigerant</material.MenuItem>
                                            <material.MenuItem value={'Other Materials'}>Other Materials</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                                <div className='col-6'>
                                    <material.TextField
                                        {...register("purposeOfUse", { required: true, maxLength: 20 })}
                                        required
                                        label="Purpose of Use"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                        multiline
                                    />
                                    <p className='form-text text-danger'>{errors.purposeOfUse?.type === "required" && 'This field is required'}</p>
                                    {(errors?.purposeOfUse?.type === "maxLength") && (
                                        <p className='text-danger'>Maximum 20 characters</p>
                                    )}
                                </div>
                                {type === 'Refrigerant' ? (
                                    <div className='col-6 mt-2'>
                                        <material.FormControl variant="standard" fullWidth>
                                            <material.InputLabel id="demo-simple-select-standard-label" required>Name</material.InputLabel>
                                            <material.Select
                                                {...register("name", { required: true, })}
                                                required
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={name}
                                                onChange={selectName}
                                                label="Material Name"
                                            >
                                                {nameData?.map((item, i) => (
                                                    <material.MenuItem value={item} key={i}>{item}</material.MenuItem>
                                                ))}
                                            </material.Select>
                                        </material.FormControl>
                                    </div>
                                ) : (
                                    <div className='col-6 mt-2'>
                                        <material.TextField
                                            {...register("name", { required: true, maxLength: 50 })}
                                            required
                                            label="Name"
                                            id="userName"
                                            variant="standard"
                                            type='text'
                                            size="small"
                                            fullWidth
                                            multiline
                                        />
                                        <p className='form-text text-danger'>{errors.name?.type === "required" && 'This field is required'}</p>
                                        {(errors?.name?.type === "maxLength") && (
                                            <p className='text-danger'>Maximum 50 characters</p>
                                        )}
                                    </div>
                                )}
                                <div className='col-6 mt-2'>
                                    <material.TextField
                                        {...register("quantity", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                        required
                                        label="Quantity"
                                        id="userName"
                                        variant="standard"
                                        type='number'
                                        size="small"
                                        fullWidth
                                    />
                                    <p className='form-text text-danger'>{errors.quantity?.type === "required" && 'This field is required'}</p>
                                    {errors.quantity && errors.quantity.type === "pattern" && (
                                        <p className="errorMsg text-danger">Allowed only number & also 2nd digit after decimal</p>
                                    )}
                                </div>
                                <div className='col-6 mt-2'>
                                    <material.FormControl variant="standard" fullWidth>
                                        <material.InputLabel id="demo-simple-select-standard-label" required>Unit</material.InputLabel>
                                        <material.Select
                                            {...register("unit", { required: true, })}
                                            required
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={unit}
                                            onChange={selectUnit}
                                            label="Material Name"
                                        >
                                            {type === 'Refrigerant' ?
                                                refrigerantUnit.map((ele, i) => (
                                                    <material.MenuItem value={ele} key={i}>{ele}</material.MenuItem>
                                                )) : type === 'Other Materials' ?
                                                    otherMaterialUnit.map((ele, i) => (
                                                        <material.MenuItem value={ele} key={i}>{ele}</material.MenuItem>
                                                    )) : null}
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                                <div className='col-6 mt-2'>
                                    <material.TextField
                                        {...register("standardDensity", { required: true, pattern: /^\d*\.?\d{0,2}$/ })}
                                        required
                                        label="Standard Density"
                                        id="density"
                                        variant="standard"
                                        type='number'
                                        size="small"
                                        fullWidth
                                        InputProps={{ readOnly: unit === "Tonne" ? true : false }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <p className='form-text text-danger'>{errors.standardDensity?.type === "required" && 'This field is required'}</p>
                                    {errors.standardDensity && errors.standardDensity.type === "pattern" && (
                                        <p className="errorMsg text-danger">Allowed only number & also 2nd digit after decimal</p>
                                    )}
                                </div>
                                {type === 'Other Materials' ? (
                                    <div className='col-6 mt-2'>
                                        <material.TextField
                                            {...register("grossCalorificValue", { required: true, pattern: /^\d*\.?\d{0,2}$/ })}
                                            required
                                            label="GWP/EF"
                                            id="userName"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                        <p className='form-text text-danger'>{errors.grossCalorificValue?.type === "required" && 'This field is required'}</p>
                                        {errors.grossCalorificValue && errors.grossCalorificValue.type === "pattern" && (
                                            <p className="errorMsg text-danger">Allowed only number & also 2nd digit after decimal</p>
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        </material.Card>
                    </form>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddOtherMaterialsUsed.otherMaterialsUsedTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateOtherMaterialsUsed)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddOtherMaterialsUsed.otherMaterialsUsedTableData.rejection_note === null) || (openAddOtherMaterialsUsed.otherMaterialsUsedTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(submitOtherMaterialsUsed)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddOtherMaterialsUsed;