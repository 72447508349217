import React, { useEffect, useState } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';
import { addCorporate, getCorporateDetailsByRefNo } from '../services/CorporateService';
import { useSelector } from 'react-redux';
import { State, Country, City } from 'country-state-city';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import AddContact from '../dialogs/AddContact';
import { StyledTableCell } from '../shared/TableHeaderStyle';
import Snackbar from '../toaster-message/Snackbar';
import moment from 'moment';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './CorporateGenInfo.css';

var allCountry = [];
var contactData = [];

function CorporateGenInfo(props) {
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [corporateData, setCorporateData] = useState({});
    const [ownershipData, setOwnershipData] = useState("");
    const [editDetails, setEditDetails] = useState(false);
    const [editAddress, setEditAddress] = useState(false);
    const [allCountryName, setAllCountryName] = useState([]);
    const [states, setStates] = useState([]);
    const [selectStateValue, setSelectStateValue] = useState("");
    const [value, setValue] = React.useState(null);
    const [openAddContact, setOpenAddContact] = React.useState({ action: false, contactData: null });
    const [countryName, setCountryName] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const [phone, setPhone] = useState("");

    useEffect(() => {
        getCorporateDetails()
        allCountry = Country.getAllCountries();
        // let countryName = allCountry.map((country) => country.name)
        setAllCountryName(allCountry);
        setValue(corporateData?.yearOfIncorporation ? dayjs(moment(corporateData.yearOfIncorporation).format("YYYY")) : "");
    }, []);
    const getCorporateDetails = async () => {
        await getCorporateDetailsByRefNo(userDetails.corporateRefNo)
            .then((resp) => {
                setCorporateData(resp.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    };
    const selectOwnership = (event) => {
        setOwnershipData(event.target.value)
    };
    const editCorporateDetails = () => {
        setEditDetails(true);
        setOwnershipData(corporateData?.natureOfOwnership ? corporateData.natureOfOwnership : "")
        setValue(corporateData?.yearOfIncorporation ? dayjs(moment(corporateData.yearOfIncorporation).format("YYYY")) : "");
    };
    const editCorporateAddress = () => {
        setEditAddress(true)
        setCountryName(corporateData?.country ? corporateData.country : "");
        let countryCode = allCountry.filter((country) => country.name === corporateData?.country ? corporateData.country : "")
        setStates(State.getStatesOfCountry(countryCode.length ? countryCode[0].isoCode : []));
        setSelectStateValue(corporateData?.state ? corporateData.state : "");
        setPhone(corporateData.phone);
        reset({ "city": corporateData.city, "registeredAddress": corporateData.registeredAddress })
    };
    const selectCountry = (event, value) => {
        setCountryName(event.target.value)
        reset({ "state": "", "city": "", "registeredAddress": "" })
        let countryCode = allCountry.filter((country) => country.name === event.target.value);
        setStates(State.getStatesOfCountry(countryCode.length ? countryCode[0].isoCode : []));
    };
    const selectState = (event) => {
        setSelectStateValue(event.target.value);
    };
    const saveCorporateDetails = async (data) => {
        let newData = { ...data, phone: phone };
        await addCorporate({ ...newData, refNo: corporateData.refNo, yearOfIncorporation: value.$y })
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Save successfully",
                })
                setEditDetails(false)
                setEditAddress(false)
                getCorporateDetails()
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    };
    const addContact = () => {
        setOpenAddContact({ action: true, contactData: "" })
    };
    const editContact = (contactData) => {
        setOpenAddContact({ action: true, contactData: contactData })
    };
    const closeAddress = () => {
        reset({ "city": "", "registeredAddress": "" })
        setEditAddress(false)
    };
    const closeCorporateDetails = () => {
        setEditDetails(false);
    };

    return (
        <div>
            <material.Paper elevation={3} sx={{ p: 3 }}>
                <div>
                    <form className='mb-2'>
                        <material.Accordion>
                            <material.AccordionSummary
                                expandIcon={<material.ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <material.Typography sx={{ fontSize: 20, fontWeight: "bold" }} className='headingText'>Corporate Name : {corporateData.corporateName}</material.Typography>
                            </material.AccordionSummary>
                            <material.AccordionDetails>
                                <material.Typography>
                                    <div className="row">
                                        <div className="col-6"></div>
                                        <div className="col-6">
                                            <span className='float-end'>
                                                {editDetails ? (
                                                    <>
                                                        <material.IconButton sx={{ mt: -1 }} title='Save Corporate Details' aria-label="create" size="large" onClick={handleSubmit(saveCorporateDetails)}>
                                                            <material.SaveIcon color='success' />
                                                        </material.IconButton>
                                                        <material.IconButton sx={{ mt: -1 }} title='Close' aria-label="create" size="large" onClick={closeCorporateDetails}>
                                                            <material.CloseIcon color='error' />
                                                        </material.IconButton>
                                                    </>
                                                ) : (
                                                    <material.IconButton sx={{ mt: -1 }} title='Edit Corporate Details' aria-label="create" size="large" onClick={() => editCorporateDetails()}>
                                                        <material.CreateIcon color='success' />
                                                    </material.IconButton>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </material.Typography>
                                {editDetails ? (
                                    <material.Typography sx={{ pb: 4 }}>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6">
                                                <material.FormControl variant="standard" fullWidth required>
                                                    <material.InputLabel required id="userType">Nature of Ownership</material.InputLabel>
                                                    <material.Select
                                                        {...register("natureOfOwnership", { required: true })}
                                                        id="userType"
                                                        value={ownershipData}
                                                        onChange={selectOwnership}
                                                    >
                                                        <material.MenuItem value="Public entity">Public entity</material.MenuItem>
                                                        <material.MenuItem value="Private entity">Private entity</material.MenuItem>
                                                        <material.MenuItem value="Public-Private Partnership (PPP) entity"> Public-Private Partnership (PPP) entity</material.MenuItem>
                                                        <material.MenuItem value="Non-profit entity">Non-profit entity</material.MenuItem>
                                                        <material.MenuItem value="Government entity">Government entity</material.MenuItem>
                                                    </material.Select>
                                                </material.FormControl>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <material.TextField
                                                    required
                                                    label="Corporate Identity No"
                                                    id="corporateIdentityNo"
                                                    variant="standard"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={corporateData.corporateIdentityNo}
                                                    {...register("corporateIdentityNo", { required: true })}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <material.DatePicker
                                                        openTo="year"
                                                        label="Year of Incorporation *"
                                                        format='YYYY'
                                                        views={['year']}
                                                        minDate={dayjs(new Date("01-01-1700"))}
                                                        value={value}
                                                        onChange={(newValue) => setValue(newValue)}
                                                        slotProps={{ textField: { variant: 'standard', color: "success" } }}
                                                        sx={{ width: "100%" }}
                                                        disableFuture
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.TextField
                                                    error={errors.paidUpCapital?.type === "required"}
                                                    required
                                                    label="Paid up Capital in Crore (INR)"
                                                    id="paidUpCapital"
                                                    variant="standard"
                                                    type="number"
                                                    size="small"
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={corporateData.paidUpCapital}
                                                    {...register("paidUpCapital", { required: true, pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/i })}
                                                />
                                                {(errors?.paidUpCapital?.type === "pattern") && (
                                                    <p className='text-danger'>Invalid input, please input only number</p>
                                                )}
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.TextField
                                                    required
                                                    label="Name of Stock Exchanges"
                                                    id="nameOfStockExchanges"
                                                    variant="standard"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={corporateData.nameOfStockExchanges}
                                                    {...register("nameOfStockExchanges", { required: true })}
                                                />
                                            </div>
                                        </div>
                                    </material.Typography>
                                ) : (
                                    <material.Typography>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className='col-4'>Nature of Ownership</td>
                                                            <td className='col-8'>{corporateData.natureOfOwnership}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4'>Corporate Identity No</td>
                                                            <td className='col-8'>{corporateData.corporateIdentityNo}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4'>Year of Incorporation</td>
                                                            <td className='col-8'>{corporateData.yearOfIncorporation ? moment(corporateData.yearOfIncorporation).format("YYYY") : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4'>Paid up Capital in Crore (INR)</td>
                                                            <td className='col-8'>{corporateData.paidUpCapital}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4'>Name of Stock Exchanges</td>
                                                            <td className='col-8'>{corporateData.nameOfStockExchanges}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </material.Typography>
                                )}
                            </material.AccordionDetails>
                        </material.Accordion>
                        <material.Accordion sx={{ mt: 2 }}>
                            <material.AccordionSummary
                                expandIcon={<material.ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>Corporate Address</material.Typography>
                            </material.AccordionSummary>
                            <material.AccordionDetails>
                                <material.Typography>
                                    <div className="row">
                                        <div className="col-6"></div>
                                        <div className="col-6">
                                            <span className='float-end'>
                                                {editAddress ? (
                                                    <>
                                                        <material.IconButton sx={{ mt: -1 }} title='Save Corporate Details' aria-label="create" size="large" onClick={handleSubmit(saveCorporateDetails)}>
                                                            <material.SaveIcon color='success' />
                                                        </material.IconButton>
                                                        <material.IconButton sx={{ mt: -1 }} title='Close' aria-label="create" size="large" onClick={closeAddress}>
                                                            <material.CloseIcon color='error' />
                                                        </material.IconButton>
                                                    </>
                                                ) : (
                                                    <material.IconButton sx={{ mt: -1 }} title='Edit Corporate Details' aria-label="create" size="large" onClick={() => editCorporateAddress()}>
                                                        <material.CreateIcon color='success' />
                                                    </material.IconButton>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </material.Typography>
                                {editAddress ? (
                                    <material.Typography>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.FormControl variant="standard" fullWidth required>
                                                    <material.InputLabel id="userType">Country</material.InputLabel>
                                                    <material.Select
                                                        {...register("country", { required: true })}
                                                        id="country"
                                                        value={countryName}
                                                        onChange={selectCountry}
                                                    >
                                                        {allCountryName?.map((country, i) => (
                                                            <material.MenuItem value={country.name} key={i}>{country.name}</material.MenuItem>
                                                        ))}
                                                    </material.Select>
                                                </material.FormControl>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.FormControl variant="standard" fullWidth required>
                                                    <material.InputLabel id="State">State</material.InputLabel>
                                                    <material.Select
                                                        {...register("state", { required: true })}
                                                        id="State"
                                                        value={selectStateValue}
                                                        onChange={selectState}
                                                    >
                                                        {states?.map((state, i) => (
                                                            <material.MenuItem value={state.name} key={i}>{state.name}</material.MenuItem>
                                                        ))}
                                                    </material.Select>
                                                </material.FormControl>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.TextField
                                                    required
                                                    label="City"
                                                    id="city"
                                                    variant="standard"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={corporateData.city}
                                                    {...register("city", { required: true })}
                                                />
                                            </div>
                                            <div className="col-lg-12 col-md-12 mt-3">
                                                <material.TextField
                                                    required
                                                    label="Registered Address"
                                                    id="registeredAddress"
                                                    variant="standard"
                                                    type="text"
                                                    size="small"
                                                    multiline
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={corporateData.registeredAddress}
                                                    {...register("registeredAddress", { required: true })}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.TextField
                                                    required
                                                    label="Pin Code"
                                                    id="pin"
                                                    variant="standard"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={corporateData.pin}
                                                    {...register("pin", { required: true })}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-4">
                                                <PhoneInput
                                                    required
                                                    placeholder="Enter phone number"
                                                    defaultCountry="IN"
                                                    inputRef={register}
                                                    enableSearch={true}
                                                    value={phone}
                                                    control={control}
                                                    international
                                                    rules={{ required: true }}
                                                    onChange={(phone) => setPhone(phone)}
                                                    error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.TextField
                                                    error={errors.fax?.type === "required"}
                                                    required
                                                    label="Fax"
                                                    id="fax"
                                                    variant="standard"
                                                    type="number"
                                                    size="small"
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={corporateData.fax}
                                                    {...register("fax", { required: true, pattern: /^[+]?([0-9]+)$/i })}
                                                />
                                                {(errors?.fax?.type === "pattern") && (
                                                    <p className='text-danger'>Invalid input, please input only number</p>
                                                )}
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.TextField
                                                    required
                                                    label="Email"
                                                    id="email"
                                                    variant="standard"
                                                    type="email"
                                                    size="small"
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={corporateData.email}
                                                    {...register("email", { required: true })}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 mt-3">
                                                <material.TextField
                                                    required
                                                    label="Website URL"
                                                    id="website"
                                                    variant="standard"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    color='success'
                                                    defaultValue={corporateData.website}
                                                    {...register("website", { required: true, pattern: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/ })}
                                                />
                                                {errors.website && errors.website.type === "pattern" && (
                                                    <p className="errorMsg text-danger">Invalid url</p>
                                                )}
                                            </div>
                                        </div>
                                    </material.Typography>
                                ) : (
                                    <material.Typography>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className='col-4 headingText'>Country</td>
                                                            <td className='col-8'>{corporateData.country}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4 headingText'>State</td>
                                                            <td className='col-8'>{corporateData.state}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4 headingText'>City</td>
                                                            <td className='col-8'>{corporateData.city}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4 headingText'>Registered Address</td>
                                                            <td className='col-8'>{corporateData.registeredAddress}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4 headingText'>Pin Code</td>
                                                            <td className='col-8'>{corporateData.pin}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4 headingText'>Phone</td>
                                                            <td className='col-8'>{corporateData.phone}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4 headingText'>Fax</td>
                                                            <td className='col-8'>{corporateData.fax}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4 headingText'>Email</td>
                                                            <td className='col-8'>{corporateData.email}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-4 headingText'>Website</td>
                                                            <td className='col-8'>{corporateData.website}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </material.Typography>
                                )}
                            </material.AccordionDetails>
                        </material.Accordion>
                    </form>
                    <material.Accordion sx={{ mt: 2 }}>
                        <material.AccordionSummary
                            expandIcon={<material.ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>Point of Contacts</material.Typography>
                        </material.AccordionSummary>
                        <material.AccordionDetails>
                            <div className="row pb-2">
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <span className='float-end'>
                                        <material.Button variant="contained" color='success' sx={{ textTransform: "none" }}
                                            startIcon={<material.AddIcon />} onClick={addContact} hidden={corporateData.pointOfContactsDto?.length === 3}>Add-Contact</material.Button>
                                    </span>
                                </div>
                            </div>
                            <material.Typography>
                                <material.TableContainer>
                                    <material.Table>
                                        <material.TableHead>
                                            <material.TableRow>
                                                <StyledTableCell>Name</StyledTableCell>
                                                <StyledTableCell align='right'>Designation</StyledTableCell>
                                                <StyledTableCell align='right'>Phone No.</StyledTableCell>
                                                <StyledTableCell align='right'>Email</StyledTableCell>
                                                <StyledTableCell align='right'>Action</StyledTableCell>
                                            </material.TableRow>
                                        </material.TableHead>
                                        <material.TableBody>
                                            {corporateData.pointOfContactsDto?.length ? corporateData.pointOfContactsDto.map((item, i) => (
                                                <material.TableRow key={i}>
                                                    <material.TableCell>{item.name}</material.TableCell>
                                                    <material.TableCell align='right'>{item.designation}</material.TableCell>
                                                    <material.TableCell align='right'>{item.phNo}</material.TableCell>
                                                    <material.TableCell align='right'>{item.email}</material.TableCell>
                                                    <material.TableCell align='right'>
                                                        <material.IconButton title='Edit Corporate Details' aria-label="create" size="large" onClick={() => editContact(item)}>
                                                            <material.CreateIcon color='success' />
                                                        </material.IconButton>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )) : null}
                                        </material.TableBody>
                                    </material.Table>
                                </material.TableContainer>
                            </material.Typography>
                        </material.AccordionDetails>
                    </material.Accordion>
                </div>
            </material.Paper>
            <AddContact
                openAddContact={openAddContact}
                setOpenAddContact={setOpenAddContact}
                getCorporateDetails={getCorporateDetails}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
};

export default CorporateGenInfo;