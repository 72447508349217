import React, { useState, useEffect } from 'react';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { material } from '../../../library/Material';
import { useSelector } from 'react-redux';
import DateFilter from '../../../shared/DateFilter';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import SpecificDisclosure from '../../specific-disclosure/SpecificDisclosure';
import SpecificDisclosureHistory from '../../specific-disclosure/SpecificDisclosureHistory';
import { getSubIssueRecordData, saveSubIssueData, updateSubIssueData } from '../../../services/sub-issue-services/AddSubissueService';
import Pagination from '../../../shared/pagination/Pagination';
import ApplicableDialog from '../../../dialogs/ApplicableDialog';
import Snackbar from '../../../toaster-message/Snackbar';
import RejectionNote from '../../../dialogs/RejectionNote';
import AddWaterDischargeNew from '../../../sub-issues-dialogs/water-sub-issue-dilog/AddWaterDischargeNew';
import moment from 'moment/moment';




const label = { inputProps: { "aria-label": "Color switch demo" } };
var usersRole;
var recordRefNo = [];

function WaterDischargeNew(props) {
    const location = useLocation();
    const treeViewData = location?.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [waterDischargeTab, setWaterDischargeTab] = useState("Water Discharge");
    const [openWaterDischarge, setOpenWaterDischarge] = useState({ action: false, waterDischargeTableData: null });
    const [waterDischargeTableData, setWaterDischargeTableData] = useState([]);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [getFilterDate, setGetFilterDate] = useState(null);

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        getAllWaterDischarge()
    }, [waterDischargeTab]);

    const handleChangeWaterDischargeTab = (event, newValue) => {
        setWaterDischargeTab(newValue);
    };

    const getFormDateToDate = (data) => {
        setGetFilterDate(data);
        getAllWaterDischarge(data, paginationData);
    };

    const getAllWaterDischarge = (filterDate, paginationValue) => {
        if (waterDischargeTab === "Water Discharge") {
            let forApproval = userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? true : null;
            let approveHistory = false;
            let typeOfRecord = "Water_Discharge";
            const payload = {
                subissueName: treeViewData.subIssue,
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                typeOfRecord,
                forApproval,
                approveHistory,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            getSubIssueRecordData(payload)
                .then((resp) => {
                    setWaterDischargeTableData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setApplicableRefNo(resp.data.applicableRecord?.ref_no ? resp.data.applicableRecord.ref_no : null);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else if (waterDischargeTab === "Water Discharge History") {
            let forApproval = true;
            let approveHistory = true;
            let typeOfRecord = "Water_Discharge";
            const payload = {
                subissueName: treeViewData.subIssue,
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                typeOfRecord,
                forApproval,
                approveHistory,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            getSubIssueRecordData(payload)
                .then((resp) => {
                    setWaterDischargeTableData(resp.data.records.content);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const addWaterDischarge = () => {
        setOpenWaterDischarge({ action: true, waterDischargeTableData: null });
    };

    const editWaterDischarge = (rowData) => {
        setOpenWaterDischarge({ action: true, waterDischargeTableData: rowData });
    };

    const clickCheckBox = (value) => {
        setOpenWaterDischarge({ action: false, waterDischargeTableData: null });
        if (recordRefNo.includes(value)) {
            recordRefNo = recordRefNo.filter(item => item !== value);
        } else {
            recordRefNo.push(value);
        }
    };

    const sendForApproval = () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true,
                "type_of_record": "Water_Discharge",
            }
            updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllWaterDischarge(getFilterDate, paginationData);
                    recordRefNo = [];
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const approved = () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true,
                "approved_by": userDetails.username,
                "approved_date": moment().format("YYYY-MM-DD"),
                "approved_status": "APPROVED",
                "rejection_note": "",
                "type_of_record": "Water_Discharge",
            }
            updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllWaterDischarge(getFilterDate, paginationData);
                    recordRefNo = [];
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    const rejectRecord = () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        }
    };

    const getRejectionNoteData = async (noteData) => {
        const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
        const subissueName = treeViewData.subIssue;
        const payload = {
            send_for_approval: false,
            "rejection_note": noteData.rejectionNote,
            "approved_status": "",
            "type_of_record": "Water_Discharge",
        }
        updateSubIssueData(payload, refNo, subissueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllWaterDischarge(getFilterDate, paginationData);
                recordRefNo = [];
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };

    const getApplicableData = (data) => {
        if (applicableRefNo === null) {
            const subIssueName = treeViewData?.subIssue;
            const payload = {
                applicable: false,
                applicable_record: true,
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "type_of_record": "Water_Discharge",
            }
            saveSubIssueData(payload, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllWaterDischarge(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else {
            const subIssueName = treeViewData?.subIssue;
            let refNo = applicableRefNo;
            const payload = {
                applicable: applicable ? false : true,
                "type_of_record": "Water_Discharge",
            }
            updateSubIssueData(payload, refNo, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllWaterDischarge(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    const getPaginationData = (filterData, paginationData) => {
        setRowsPerPage(paginationData.size);
        setPage(paginationData.page);
        getAllWaterDischarge(filterData, paginationData);
    };

    return (
        <div>
            <material.Box sx={{ display: "flex" }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7, width: 100 }}>
                    <div className="row">
                        <span className="col-6"> <material.Typography variant="h5" className='text-capitalize'>{treeViewData?.subIssue} </material.Typography> </span>
                        <div className="col-6">
                            {usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR" ? null : (
                                <span className="float-end">
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} checked={applicable} color="secondary" onClick={applicableNotApplicable} />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>
                    <div>
                        <material.Box sx={{ width: "100%", typography: "body1" }}>
                            <material.TabContext value={waterDischargeTab}>
                                <material.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <material.TabList onChange={handleChangeWaterDischargeTab} aria-label="lab API tabs example">
                                        <material.Tab className="text-capitalize" label="Water Discharge" value="Water Discharge" />
                                        <material.Tab className="text-capitalize" label="Water Discharge History" value="Water Discharge History" />
                                        <material.Tab className="text-capitalize" label="Specific Disclosure" value="Specific Disclosure" />
                                        <material.Tab className="text-capitalize" label="Specific Disclosure History" value="Specific Disclosure History" />
                                    </material.TabList>
                                </material.Box>
                                <material.TabPanel value="Water Discharge">
                                    <div className="row mt-2 mb-2 align-items-center">
                                        <div className="col-6">
                                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                <DateFilter
                                                    getFormDateToDate={getFormDateToDate}
                                                />
                                            )}
                                        </div>
                                        <div className="col-6" hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                            <span className='float-end'>
                                                {waterDischargeTableData?.length ? (
                                                    <div>
                                                        <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")} color='success'
                                                            disabled={!applicable || !recordRefNo.length}
                                                            sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approved}>Approve</material.Button>
                                                        <material.Button sx={{ textTransform: "none" }} hidden={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")}
                                                            disabled={!applicable || !recordRefNo.length}
                                                            variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectRecord}>Reject</material.Button>
                                                    </div>
                                                ) : null}
                                                <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_APPROVER")} color='success'
                                                    disabled={!applicable}
                                                    sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendForApproval}>Send for Approval</material.Button>
                                                <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_APPROVER")} color='success'
                                                    disabled={!applicable}
                                                    sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addWaterDischarge}>Add Water Discharge</material.Button>
                                            </span>
                                        </div>
                                    </div>
                                    <material.Accordion className='mb-3'>
                                        <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                                            <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Water Discharge Quality</material.Typography>
                                        </material.AccordionSummary>
                                        <material.AccordionDetails>
                                            <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <material.TableContainer>
                                                            <material.Table>
                                                                <material.TableHead>
                                                                    <material.TableRow>
                                                                        <StyledTableCell>Discharge Point</StyledTableCell>
                                                                        <StyledTableCell>Pollutant Suite</StyledTableCell>
                                                                        <StyledTableCell>Pollutant Parameter</StyledTableCell>
                                                                        <StyledTableCell>Measured Value</StyledTableCell>
                                                                        <StyledTableCell>Unit</StyledTableCell>
                                                                        <StyledTableCell>From Date</StyledTableCell>
                                                                        <StyledTableCell>To Date</StyledTableCell>
                                                                        <StyledTableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>{userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                                        {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                            <StyledTableCell>Rejection Note</StyledTableCell>
                                                                        )}
                                                                    </material.TableRow>
                                                                </material.TableHead>
                                                                <material.TableBody>
                                                                    {waterDischargeTableData.length ? waterDischargeTableData.map((item, k) => (
                                                                        <material.TableRow key={k}
                                                                            sx={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                            onClick={((userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")) && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true) ?
                                                                                () => editWaterDischarge(item) : null}
                                                                        >
                                                                            <material.TableCell>{item.discharge_point}</material.TableCell>
                                                                            <material.TableCell>{item.quality_pollutant_suit}</material.TableCell>
                                                                            <material.TableCell>{item.quality_pollutant_parameter}</material.TableCell>
                                                                            <material.TableCell>{item.quality_pollutant_value}</material.TableCell>
                                                                            <material.TableCell>{item.quality_unit}</material.TableCell>
                                                                            <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                                                                {userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") ? (
                                                                                    <material.Checkbox {...label} disabled={!applicable || item.send_for_approval} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                                                ) : (
                                                                                    <material.Checkbox {...label} disabled={!applicable || item.approved_status === "APPROVED"} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                                                )}
                                                                            </material.TableCell>
                                                                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                                <material.TableCell>{(item.rejection_note === null) || (item.rejection_note === "") ? "No" : (
                                                                                    <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                                )}
                                                                                </material.TableCell>
                                                                            )}
                                                                        </material.TableRow>
                                                                    )) : (
                                                                        <material.TableRow>
                                                                            <material.TableCell colSpan={12}>
                                                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                            </material.TableCell>
                                                                        </material.TableRow>
                                                                    )}
                                                                </material.TableBody>
                                                            </material.Table>
                                                        </material.TableContainer>
                                                        <Pagination
                                                            getPaginationData={getPaginationData}
                                                            totalElements={totalElements}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            getFilterDate={getFilterDate}
                                                        />
                                                    </div>
                                                </div>
                                            </material.Paper>
                                        </material.AccordionDetails>
                                    </material.Accordion>
                                    <material.Accordion>
                                        <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                                            <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Water Discharge Receptor Quality</material.Typography>
                                        </material.AccordionSummary>
                                        <material.AccordionDetails>
                                            <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <material.TableContainer>
                                                            <material.Table>
                                                                <material.TableHead>
                                                                    <material.TableRow>
                                                                        <StyledTableCell>Discharge Point</StyledTableCell>
                                                                        <StyledTableCell>Monitoring Location (latitude)</StyledTableCell>
                                                                        <StyledTableCell>Monitoring Location (longitude)</StyledTableCell>
                                                                        <StyledTableCell>Pollutant Suite</StyledTableCell>
                                                                        <StyledTableCell>Pollutant Parameter</StyledTableCell>
                                                                        <StyledTableCell>Other Pollutant (Specify)</StyledTableCell>
                                                                        <StyledTableCell>Measured Value</StyledTableCell>
                                                                        <StyledTableCell>Unit</StyledTableCell>
                                                                        <StyledTableCell>From Date</StyledTableCell>
                                                                        <StyledTableCell>To Date</StyledTableCell>
                                                                        {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                            <StyledTableCell>Approval Date</StyledTableCell>
                                                                        )}
                                                                        {/* <StyledTableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>{userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? "Approve" : "Send for Approval"}</StyledTableCell> */}
                                                                        {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                            <StyledTableCell>Rejection Note</StyledTableCell>
                                                                        )}
                                                                    </material.TableRow>
                                                                </material.TableHead>
                                                                <material.TableBody>
                                                                    {waterDischargeTableData.length ? waterDischargeTableData.map((item, k) => (
                                                                        <material.TableRow
                                                                            key={k}
                                                                        //     sx={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                        //     onClick={((userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")) && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true) ?
                                                                        //         () => editWaterDischarge(item) : null}
                                                                        >
                                                                            <material.TableCell>{item.discharge_point}</material.TableCell>
                                                                            <material.TableCell>{item.discharge_location_lat}</material.TableCell>
                                                                            <material.TableCell>{item.discharge_location_long}</material.TableCell>
                                                                            <material.TableCell>{item.receptor_quality_pollutant_suit}</material.TableCell>
                                                                            <material.TableCell>{item.receptor_quality_pollutant_parameter}</material.TableCell>
                                                                            <material.TableCell>{(item.other_specify_receptor_pollutant === null || item.other_specify_receptor_pollutant === "") ? "N/A" : item.other_specify_receptor_pollutant}</material.TableCell>
                                                                            <material.TableCell>{item.receptor_quality_pollutant_value}</material.TableCell>
                                                                            <material.TableCell>{item.receptor_quality_unit}</material.TableCell>
                                                                            <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                                <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            )}
                                                                            {/* <material.TableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                                                                {userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") ? (
                                                                                    <material.Checkbox {...label} disabled={!applicable || item.send_for_approval} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                                                ) : (
                                                                                    <material.Checkbox {...label} disabled={!applicable || item.approved_status === "APPROVED"} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                                                )}
                                                                            </material.TableCell> */}
                                                                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                                <material.TableCell>{(item.rejection_note === null) || (item.rejection_note === "") ? "No" : (
                                                                                    <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                                )}
                                                                                </material.TableCell>
                                                                            )}
                                                                        </material.TableRow>
                                                                    )) : (
                                                                        <material.TableRow>
                                                                            <material.TableCell colSpan={12}>
                                                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                            </material.TableCell>
                                                                        </material.TableRow>
                                                                    )}
                                                                </material.TableBody>
                                                            </material.Table>
                                                        </material.TableContainer>
                                                        <Pagination
                                                            getPaginationData={getPaginationData}
                                                            totalElements={totalElements}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            getFilterDate={getFilterDate}
                                                        />
                                                    </div>
                                                </div>
                                            </material.Paper>
                                        </material.AccordionDetails>
                                    </material.Accordion>
                                </material.TabPanel>
                                <material.TabPanel value="Water Discharge History">
                                    <div className="row mb-2">
                                        <div className="col-8">
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        </div>
                                    </div>
                                    <material.Accordion className='mb-3'>
                                        <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                                            <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Water Discharge Quality</material.Typography>
                                        </material.AccordionSummary >
                                        <material.AccordionDetails>
                                            <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <material.TableContainer>
                                                            <material.Table>
                                                                <material.TableHead>
                                                                    <material.TableRow>
                                                                        <StyledTableCell>Discharge Point</StyledTableCell>
                                                                        <StyledTableCell>Pollutant Suite</StyledTableCell>
                                                                        <StyledTableCell>Pollutant Parameter</StyledTableCell>
                                                                        <StyledTableCell>Measured Value</StyledTableCell>
                                                                        <StyledTableCell>Unit</StyledTableCell>
                                                                        <StyledTableCell>From Date</StyledTableCell>
                                                                        <StyledTableCell>To Date</StyledTableCell>
                                                                        <StyledTableCell>Approval Date</StyledTableCell>
                                                                        <StyledTableCell>Status</StyledTableCell>
                                                                    </material.TableRow>
                                                                </material.TableHead>
                                                                <material.TableBody>
                                                                    {waterDischargeTableData?.length ? waterDischargeTableData.map((item, i) => (
                                                                        <material.TableRow key={i}>
                                                                            <material.TableCell>{item.discharge_point}</material.TableCell>
                                                                            <material.TableCell>{item.quality_pollutant_suit}</material.TableCell>
                                                                            <material.TableCell>{item.quality_pollutant_parameter}</material.TableCell>
                                                                            <material.TableCell>{item.quality_pollutant_value}</material.TableCell>
                                                                            <material.TableCell>{item.quality_unit}</material.TableCell>
                                                                            <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell>
                                                                                {item.approved_status === "APPROVED" ? <span className="badge bg-success">
                                                                                    APPROVED
                                                                                </span> : null}
                                                                            </material.TableCell>
                                                                        </material.TableRow>
                                                                    )) : (
                                                                        <material.TableRow >
                                                                            <material.TableCell colSpan={12}>
                                                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                            </material.TableCell>
                                                                        </material.TableRow>
                                                                    )}
                                                                </material.TableBody>
                                                            </material.Table>
                                                        </material.TableContainer>
                                                        <Pagination
                                                            getPaginationData={getPaginationData}
                                                            totalElements={totalElements}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            getFilterDate={getFilterDate}
                                                        />
                                                    </div>
                                                </div>
                                            </material.Paper>
                                        </material.AccordionDetails>
                                    </material.Accordion>
                                    <material.Accordion>
                                        <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                                            <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Water Discharge Receptor Quality</material.Typography>
                                        </material.AccordionSummary>
                                        <material.AccordionDetails>
                                            <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <material.TableContainer>
                                                            <material.Table>
                                                                <material.TableHead>
                                                                    <material.TableRow>
                                                                        <StyledTableCell>Discharge Point</StyledTableCell>
                                                                        <StyledTableCell>Monitoring Location (latitude)</StyledTableCell>
                                                                        <StyledTableCell>Monitoring Location (longitude)</StyledTableCell>
                                                                        <StyledTableCell>Pollutant Suite</StyledTableCell>
                                                                        <StyledTableCell>Pollutant Parameter</StyledTableCell>
                                                                        <StyledTableCell>Measured Value</StyledTableCell>
                                                                        <StyledTableCell>Unit</StyledTableCell>
                                                                        <StyledTableCell>From Date</StyledTableCell>
                                                                        <StyledTableCell>To Date</StyledTableCell>
                                                                        <StyledTableCell>Approval Date</StyledTableCell>
                                                                        <StyledTableCell>Status</StyledTableCell>
                                                                    </material.TableRow>
                                                                </material.TableHead>
                                                                <material.TableBody>
                                                                    {waterDischargeTableData?.length ? waterDischargeTableData.map((item, i) => (
                                                                        <material.TableRow key={i}>
                                                                            <material.TableCell>{item.discharge_point}</material.TableCell>
                                                                            <material.TableCell>{item.discharge_location_lat}</material.TableCell>
                                                                            <material.TableCell>{item.discharge_location_long}</material.TableCell>
                                                                            <material.TableCell>{item.receptor_quality_pollutant_suit}</material.TableCell>
                                                                            <material.TableCell>{item.receptor_quality_pollutant_parameter}</material.TableCell>
                                                                            <material.TableCell>{item.receptor_quality_pollutant_value}</material.TableCell>
                                                                            <material.TableCell>{item.receptor_quality_unit}</material.TableCell>
                                                                            <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell>
                                                                                {item.approved_status === "APPROVED" ? <span className="badge bg-success">
                                                                                    APPROVED
                                                                                </span> : null}
                                                                            </material.TableCell>
                                                                        </material.TableRow>
                                                                    )) : (
                                                                        <material.TableRow >
                                                                            <material.TableCell colSpan={12}>
                                                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                            </material.TableCell>
                                                                        </material.TableRow>
                                                                    )}
                                                                </material.TableBody>
                                                            </material.Table>
                                                        </material.TableContainer>
                                                        <Pagination
                                                            getPaginationData={getPaginationData}
                                                            totalElements={totalElements}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            getFilterDate={getFilterDate}
                                                        />
                                                    </div>
                                                </div>
                                            </material.Paper>
                                        </material.AccordionDetails>
                                    </material.Accordion>
                                </material.TabPanel>
                                <material.TabPanel value="Specific Disclosure">
                                    <SpecificDisclosure
                                        treeViewData={treeViewData}
                                    />
                                </material.TabPanel>
                                <material.TabPanel value="Specific Disclosure History">
                                    <SpecificDisclosureHistory
                                        treeViewData={treeViewData}
                                    />
                                </material.TabPanel>
                            </material.TabContext>
                        </material.Box>
                    </div>
                </material.Box >
            </material.Box >
            <AddWaterDischargeNew
                openWaterDischarge={openWaterDischarge}
                setOpenWaterDischarge={setOpenWaterDischarge}
                getAllWaterDischarge={getAllWaterDischarge}
                treeViewData={treeViewData}
                page={page}
                rowsPerPage={rowsPerPage}
                getFilterDate={getFilterDate}
            />
            <ApplicableDialog
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getApplicableData={getApplicableData}
                applicable={applicable}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </div >
    )
}

export default WaterDischargeNew;