import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import DatePeriod from '../../shared/DatePeriod';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';



function AddSupplierChainAwareness(props) {
    const { openAddSupplierChainAwareness, setOpenAddSupplierChainAwareness, gaeAllSupplierChainAwarenessData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [topicsAwarenessProgram, setTopicsAwarenessProgram] = useState("");
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openAddSupplierChainAwareness.action && openAddSupplierChainAwareness.supplierChainAwarenessTableData) {
            setTopicsAwarenessProgram(openAddSupplierChainAwareness.supplierChainAwarenessTableData.topics_of_awareness_program);
            reset(openAddSupplierChainAwareness.supplierChainAwarenessTableData);
        }
    }, [openAddSupplierChainAwareness.action === true]);


    const selectTopicsAwarenessProgram = (event) => {
        setTopicsAwarenessProgram(event.target.value)
    };

    const handleClose = () => {
        reset({ total_number_of_supplier: "", supplier_participated_in_awareness_program: "", number_of_awareness_program: "", other_specify: "", });
        setTopicsAwarenessProgram("");
        setOpenAddSupplierChainAwareness({ action: false, supplierChainAwarenessTableData: null });
    };

    const submitSupplierChainAwareness = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                // "total_number_of_supplier": data.total_number_of_supplier,
                "supplier_participated_in_awareness_program": data.supplier_participated_in_awareness_program,
                "number_of_awareness_program": data.number_of_awareness_program,
                "topics_of_awareness_program": data.topics_of_awareness_program,
                "other_specify": data.other_specify,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    gaeAllSupplierChainAwarenessData(getFilterDate, payloadData);
                    reset({ total_number_of_supplier: "", supplier_participated_in_awareness_program: "", number_of_awareness_program: "", other_specify: "", });
                    setTopicsAwarenessProgram("");
                    setOpenAddSupplierChainAwareness({ action: false, supplierChainAwarenessTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateSupplierChainAwareness = async (data) => {
        const refNo = openAddSupplierChainAwareness.supplierChainAwarenessTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            // "total_number_of_supplier": data.total_number_of_supplier,
            "supplier_participated_in_awareness_program": data.supplier_participated_in_awareness_program,
            "number_of_awareness_program": data.number_of_awareness_program,
            "topics_of_awareness_program": data.topics_of_awareness_program,
            "other_specify": topicsAwarenessProgram === "Other (Specify)" ? data.other_specify : "",
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                gaeAllSupplierChainAwarenessData(getFilterDate, payloadData);
                reset({ total_number_of_supplier: "", supplier_participated_in_awareness_program: "", number_of_awareness_program: "", other_specify: "", });
                setTopicsAwarenessProgram("");
                setOpenAddSupplierChainAwareness({ action: false, supplierChainAwarenessTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAddSupplierChainAwareness.supplierChainAwarenessTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ total_number_of_supplier: "", supplier_participated_in_awareness_program: "", number_of_awareness_program: "", other_specify: "", });
                setTopicsAwarenessProgram("");
                gaeAllSupplierChainAwarenessData(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddSupplierChainAwareness({ action: false, supplierChainAwarenessTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddSupplierChainAwareness.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddSupplierChainAwareness.supplierChainAwarenessTableData ? "Edit Supplier Chain Governance Awareness" : "Add Supplier Chain Governance Awareness"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddSupplierChainAwareness.supplierChainAwarenessTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Supplier Chain Governance Awareness</material.Typography>
                            </div>
                            {/* <div className='col-6'>
                                <material.TextField
                                    {...register("total_number_of_supplier", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Total Number of Supplier"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.total_number_of_supplier?.type === "required" && 'This field is required'}</p>
                                {errors.total_number_of_supplier && errors.total_number_of_supplier.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div> */}
                            <div>
                                <material.TextField
                                    {...register("supplier_participated_in_awareness_program", { required: true, maxLength: 20 })}
                                    required
                                    label="Supplier Participated in Awareness Program"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.supplier_participated_in_awareness_program?.type === "required" && 'This field is required'}</p>
                                {(errors?.supplier_participated_in_awareness_program?.type === "maxLength") && (
                                    <p className='text-danger'>Maximum 20 characters</p>
                                )}
                            </div>
                            <div className='mt-3'>
                                <material.TextField
                                    {...register("number_of_awareness_program", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number of Awareness Program"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.number_of_awareness_program?.type === "required" && 'This field is required'}</p>
                                {errors.number_of_awareness_program && errors.number_of_awareness_program.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='mt-3'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Topics of Awareness Program</material.InputLabel>
                                    <material.Select
                                        {...register("topics_of_awareness_program", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={topicsAwarenessProgram}
                                        onChange={selectTopicsAwarenessProgram}
                                        label="Type of material"
                                    >
                                        <material.MenuItem value={"P1"}>P1</material.MenuItem>
                                        <material.MenuItem value={"P2"}>P2</material.MenuItem>
                                        <material.MenuItem value={"P3"}>P3</material.MenuItem>
                                        <material.MenuItem value={"P4"}>P4</material.MenuItem>
                                        <material.MenuItem value={"P5"}>P5</material.MenuItem>
                                        <material.MenuItem value={"P6"}>P6</material.MenuItem>
                                        <material.MenuItem value={"P7"}>P7</material.MenuItem>
                                        <material.MenuItem value={"P8"}>P8</material.MenuItem>
                                        <material.MenuItem value={"P9"}>P9</material.MenuItem>
                                        <material.MenuItem value={"POSH"}>POSH</material.MenuItem>
                                        <material.MenuItem value={"Health & Safety"}>Health & Safety</material.MenuItem>
                                        <material.MenuItem value={"Human Rights"}>Human Rights</material.MenuItem>
                                        <material.MenuItem value={"ESG"}>ESG</material.MenuItem>
                                        <material.MenuItem value={"Other (Specify)"}>Other (Specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {topicsAwarenessProgram === "Other (Specify)" ? (
                                <div className='mt-3'>
                                    <material.TextField
                                        {...register("other_specify", { required: true, maxLength: 20 })}
                                        required
                                        label="Other (Specify)"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    <p className='form-text text-danger'>{errors.other_specify?.type === "required" && 'This field is required'}</p>
                                    {(errors?.other_specify?.type === "maxLength") && (
                                        <p className='text-danger'>Maximum 20 characters</p>
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddSupplierChainAwareness.supplierChainAwarenessTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateSupplierChainAwareness)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddSupplierChainAwareness.supplierChainAwarenessTableData.rejection_note === null) || (openAddSupplierChainAwareness.supplierChainAwarenessTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(submitSupplierChainAwareness)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddSupplierChainAwareness;