import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import DatePeriod from '../../shared/DatePeriod';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';

function AddProductOrServiceComplaints(props) {
    const { openAddProductServiceHSComplaints, setOpenAddProductServiceHSComplaints, getAllProductServiceHSComplaintsData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [incidentsOfNonCompliance, setIncidentsOfNonCompliance] = useState("");
    const [correctiveAction, setCorrectiveAction] = useState("");
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openAddProductServiceHSComplaints.action && openAddProductServiceHSComplaints.productServiceHSComplaintsTableData) {
            setIncidentsOfNonCompliance(openAddProductServiceHSComplaints.productServiceHSComplaintsTableData.incidents_of_non_compliance);
            setCorrectiveAction(openAddProductServiceHSComplaints.productServiceHSComplaintsTableData.corrective_action);
            reset(openAddProductServiceHSComplaints.productServiceHSComplaintsTableData);
        };
    }, [openAddProductServiceHSComplaints.action === true]);

    const selectIncidentsOfNonCompliance = (event) => {
        setIncidentsOfNonCompliance(event.target.value)
    };

    const selectCorrectiveAction = (event) => {
        setCorrectiveAction(event.target.value)
    };

    const handleClose = () => {
        reset({ incidents_other_specify: "", number_of_incident: "", corrective_other_specify: "", number_of_corrective_actions: "", remarks: "", });
        setIncidentsOfNonCompliance("");
        setCorrectiveAction("");
        setOpenAddProductServiceHSComplaints({ action: false, productServiceHSComplaintsTableData: null });
    };

    const submitProductServiceHSComplaints = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = "Product or Service H&S Complaints";
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "incidents_of_non_compliance": data.incidents_of_non_compliance,
                "incidents_other_specify": data.incidents_other_specify,
                "number_of_incident": data.number_of_incident,
                "corrective_action": data.corrective_action,
                "corrective_other_specify": data.corrective_other_specify,
                "number_of_corrective_actions": data.number_of_corrective_actions,
                "remarks": data.remarks,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllProductServiceHSComplaintsData(getFilterDate, payloadData);
                    reset({ incidents_other_specify: "", number_of_incident: "", corrective_other_specify: "", number_of_corrective_actions: "", remarks: "", });
                    setIncidentsOfNonCompliance("");
                    setCorrectiveAction("");
                    setOpenAddProductServiceHSComplaints({ action: false, productServiceHSComplaintsTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateProductServiceHSComplaints = async (data) => {
        const refNo = openAddProductServiceHSComplaints.productServiceHSComplaintsTableData.ref_no;
        const subIssueName = "Product or Service H&S Complaints";
        let obj = {
            "incidents_of_non_compliance": data.incidents_of_non_compliance,
            "incidents_other_specify": incidentsOfNonCompliance === "Other (Specify)" ? data.incidents_other_specify : "",
            "number_of_incident": data.number_of_incident,
            "corrective_action": data.corrective_action,
            "corrective_other_specify": correctiveAction === "Other (Specify)" ? data.corrective_other_specify : "",
            "number_of_corrective_actions": data.number_of_corrective_actions,
            "remarks": data.remarks,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllProductServiceHSComplaintsData(getFilterDate, payloadData);
                reset({ incidents_other_specify: "", number_of_incident: "", corrective_other_specify: "", number_of_corrective_actions: "", remarks: "", });
                setIncidentsOfNonCompliance("");
                setCorrectiveAction("");
                setOpenAddProductServiceHSComplaints({ action: false, productServiceHSComplaintsTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAddProductServiceHSComplaints.productServiceHSComplaintsTableData.ref_no,
            subissueName: "Product or Service H&S Complaints",
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ incidents_other_specify: "", number_of_incident: "", corrective_other_specify: "", number_of_corrective_actions: "", remarks: "", });
                setIncidentsOfNonCompliance("");
                setCorrectiveAction("");
                getAllProductServiceHSComplaintsData(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddProductServiceHSComplaints({ action: false, productServiceHSComplaintsTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddProductServiceHSComplaints.action} maxWidth="lg" fullWidth >
                <material.DialogTitle className='fw-bold'>{openAddProductServiceHSComplaints.productServiceHSComplaintsTableData ? "Edit Product or Service H&S Complaints" : "Add Product or Service H&S Complaints"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddProductServiceHSComplaints.productServiceHSComplaintsTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Product or Service H&S Complaints</material.Typography>
                            </div>
                            <div className='col-4'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Incidents of Non Compliance</material.InputLabel>
                                    <material.Select
                                        {...register("incidents_of_non_compliance", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={incidentsOfNonCompliance}
                                        onChange={selectIncidentsOfNonCompliance}
                                        label="Material Sourced"
                                    >
                                        <material.MenuItem value={"Incidents of Non Compliance with Regulations Resulting in a Fine or Penalty"}>Incidents of Non Compliance with Regulations Resulting in a Fine or Penalty</material.MenuItem>
                                        <material.MenuItem value={"Incidents of Non Compliance with Regulations Resulting In a Warning"}>Incidents of Non Compliance with Regulations Resulting In a Warning</material.MenuItem>
                                        <material.MenuItem value={"Incidents of Non Compliance with Voluntary Codes"}>Incidents of Non Compliance with Voluntary Codes</material.MenuItem>
                                        <material.MenuItem value={"Other (Specify)"}>Other (Specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {incidentsOfNonCompliance === "Other (Specify)" ? (
                                <div className='col-4'>
                                    <material.TextField
                                        {...register("incidents_other_specify", { required: true, maxLength: 20 })}
                                        required
                                        label="Incidents of Non Compliance Other (Specify)"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors?.incidents_other_specify?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-4'>
                                <material.TextField
                                    {...register("number_of_incident", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number of Incidents"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.number_of_incident && errors.number_of_incident.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-4'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Corrective Action</material.InputLabel>
                                    <material.Select
                                        {...register("corrective_action", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={correctiveAction}
                                        onChange={selectCorrectiveAction}
                                        label="Material Sourced"
                                    >
                                        <material.MenuItem value={"Voluntary Recalls"}>Voluntary Recalls</material.MenuItem>
                                        <material.MenuItem value={"Forced Recalls"}>Forced Recalls</material.MenuItem>
                                        <material.MenuItem value={"Other (Specify)"}>Other (Specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {correctiveAction === "Other (Specify)" ? (
                                <div className='col-4'>
                                    <material.TextField
                                        {...register("corrective_other_specify", { required: true, maxLength: 20 })}
                                        required
                                        label="Corrective Action other (specify)"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors?.corrective_other_specify?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-4'>
                                <material.TextField
                                    {...register("number_of_corrective_actions", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number of Corrective Action"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.numberOfCorrectiveAction && errors.numberOfCorrectiveAction.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-12'>
                                <material.TextField
                                    {...register("remarks", { required: true, maxLength: 200 })}
                                    required
                                    label="Remarks"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                                <p className='form-text text-danger'>{errors.remarks?.type === "required" && 'This field is required'}</p>
                                {errors.remarks && (errors.remarks.type === "minLength" || errors.remarks.type === "maxLength") && (
                                    <p className="errorMsg text-danger">Describe The Remarks Issues should be between 2 to 200 character.</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddProductServiceHSComplaints.productServiceHSComplaintsTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateProductServiceHSComplaints)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddProductServiceHSComplaints.productServiceHSComplaintsTableData.rejection_note === null) || (openAddProductServiceHSComplaints.productServiceHSComplaintsTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(submitProductServiceHSComplaints)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddProductOrServiceComplaints;