import React, { useState } from 'react';
import { material } from '../library/Material';
import { saveMaterialUsedData } from '../services/sub-issue-services/MaterialUsedService';
import { useSelector } from 'react-redux';
import Snackbar from '../toaster-message/Snackbar';
import { saveOrUpdateAirEmissionPointSourceData } from '../services/sub-issue-services/air-service/AirEmissionPointSourceService';
import { saveOrUpdateEnergyData } from '../services/sub-issue-services/energy/EnergyConsumptionWithinOrgService';
import { saveOrUpdateDiversity } from '../services/sub-issue-services/DiversityOfGovernanceBodiesService';
import { saveOrUpdateDirectGhgEmissionData } from '../services/sub-issue-services/GhgScope1Services';
import { saveOrUpdateIndirectGhgEmissionData } from '../services/sub-issue-services/GhgScope2Service';
import { saveOrUpdateInjuriesData } from '../services/sub-issue-services/InjuriesServices';
import { saveOrUpdateCoverageOfTraining } from '../services/sub-issue-services/CoverageOfTrainingService';
import { saveOrUpdateGrievanceRedressalData } from '../services/sub-issue-services/GrievanceService';
import { saveWaterWithdrawalData } from '../services/sub-issue-services/WaterWithdralService';
import { saveOrUpdateWorkRelatedIllHealthData } from '../services/sub-issue-services/WorkRelatedIllHealthService';
import { saveOrUpdateEmployeeAndWorker } from '../services/sub-issue-services/EmployeeAndWorkerService';
import { saveOrUpdateDifferentlyAbledEmployeesWorkersData } from '../services/sub-issue-services/DifferentlyAbledEmployeesWorkersService';
import { saveOrUpdateWaterConsumptionSupplierData } from '../services/sub-issue-services/WaterConsumptionSupplierService';
import { saveOrUpdateEmployeeHiringData } from '../services/sub-issue-services/EmployeeHiringService';
import { saveOrUpdateWasteGenerationData } from '../services/sub-issue-services/WasteGenerationService';
import { saveOrUpdateWasteRecoveryData } from '../services/sub-issue-services/WasteRecoveryService';
import { saveOrUpdateWasteDisposalData } from '../services/sub-issue-services/WasteDisposalService';
import { saveOrUpdateWaterDischargeData } from '../services/sub-issue-services/WaterDischargeService';
import { saveOrUpdateEnergyOutsideData } from '../services/sub-issue-services/energy/EnergyConsumptionOutsideService';
import { saveOrUpdateProductionEfficiencyData } from '../services/sub-issue-services/energy/EnergyEfficiencyProductionService';
import { saveOrUpdateEnergyEfficiencyProductsServiceData } from '../services/sub-issue-services/energy/EnergyEfficiencyProductsServicesService';
import { saveOrUpdateBiodiversityValueOfSiteData } from '../services/sub-issue-services/biodiversity-services/BiodiversityValueOfSiteService';
import { saveOrUpdateSensitiveSpeciesAffectedData } from '../services/sub-issue-services/biodiversity-services/SensitiveSpeciesAffectedService';
import { saveOrUpdateHabitatsProtectedOrRestoredData } from '../services/sub-issue-services/biodiversity-services/HabitatsProtectedOrRestoredService';
import { saveOrUpdateSocialScreeningData } from '../services/sub-issue-services/social-screening-service/SocialScreeningService';
import { saveOrUpdateSecurityPracticesData } from '../services/sub-issue-services/human-rights-services/SecurityPracticesService';
import { saveOrUpdateEnvironmentalCriteriaData } from '../services/sub-issue-services/supplier-chain-environment-service/EnvironmentalCriteriaService';
import { saveOrUpdateEHSApprovalStatusData } from '../services/sub-issue-services/environmental-compliance-service/EHSApprovalStatus';
import { saveOrUpdateDiscriminationIncidentsData } from '../services/sub-issue-services/non-discrimination service/DiscriminationIncidentsService';
import { saveOrUpdateRetirementBenefitData } from '../services/sub-issue-services/employee-services/RetirementBenefitService';
import { saveOrUpdateGhgScopeData } from '../services/sub-issue-services/ghg-scope-common-services/GhgScopeCommonService';
import { saveOrUpdateParentalLeaveData } from '../services/sub-issue-services/parental-leave-service/ParentalLeaveService';
import { saveOrUpdateEmployeesWorkersWellBeingData } from '../services/sub-issue-services/employees-workers-well-being-service/EmployeesWorkers-WellBeingService';
import { saveOrUpdateBiodiversityImpactsData } from '../services/sub-issue-services/biodiversity-services/BiodiversityImpactsService';
import { saveOrUpdateChildAndForcedLabourData } from '../services/sub-issue-services/human-rights-services/ChildAndForcedLabourService';
import { saveOrUpdateIndigenousPeoplesRightsData } from '../services/sub-issue-services/indigenous-peoples-services/IndigenousPeoplesRightsService';
import { saveOrUpdateAirEmissionsInteractionsData } from '../services/sub-issue-services/air-service/AirEmissionsInteractionsService';
import { saveOrUpdateNoticesAndFinesData } from '../services/sub-issue-services/notices-fines-service/NoticesFinesService';
import { saveOrUpdateViolationAndExceedanceData } from '../services/sub-issue-services/violation-exceedance-service/ViolationExceedanceService';
import { saveOrUpdatePerformanceAppraisalData } from '../services/sub-issue-services/performance-appraisal-service/PerformanceAppraisalService';
import { saveOrUpdateFreedomOfAssociationData } from '../services/sub-issue-services/freedom-of-association-services/FreedomOfAssociationService';
import { saveOrUpdateAirEmissionAreaSourceData } from '../services/sub-issue-services/air-service/AirEmissionAreaSourceService';
import { saveStrategyManagementProcess } from '../services/sub-issue-services/StrategyManagementServices';


function ApplicableNotApplicableWarning(props) {

    const { openWarningDialog, setOpenWarningDialog, getMaterialUsedData, applicable, treeViewData, getEnergyData, getDiversityOfGovernanceData,
        getGhgScope1AllData, getGhgScope2AllData, getCoverageOfTrainingData, getGrievanceRedressalData, getInjuriesTableData, getWaterWithdrawalData,
        getWorkRelatedIllHealthData, getEmployeeAndWorkerAllData, getDifferentlyAbledEmployeesWorkersData, waterConsumptionSupplierData, getAllEmployeeHiringData,
        getWasteGenerationData, getAllWasteRecoveryData, getAllWasteDisposalData, getWaterDischargeAllData, getReclaimedProductsData, getRecycledInputData,
        energyConsumptionOutsideData, getAllEnergyEfficiencyProductsServiceData, getEfficiencyProductionData, getAllSensitiveSpeciesAffectedData, getBiodiversityValueOfSiteTableData, getAllHabitatsProtectedData,
        getAllSocialScreeningData, applicableRefNo, getSecurityPracticesAllData, getAllEnvironmentalCriteriaData, getAllEHSApprovalStatusData, getDiscriminationIncidentsAllData, getRetirementBenefitAllData,
        getGhgScope3AllData, getAllParentalLeave, getAllEmployeesWorkersWellBeingData, getBiodiversityImpactsAllData, getChildAndForcedLabourAllData, getIndigenousPeoplesRightsAllData, getAllAirEmissionsInteractionsData,
        getAllNoticesFinesData, getAllViolationExceedanceData, getAllPerformanceAppraisalData, getFreedomOfAssociationAllData, getAllAreaSourceData, allAirEmissionQuantityData, getStrategyManagement } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const save = async () => {
        if (treeViewData?.subIssue === "Materials Used") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "refNo": applicableRefNo !== null ? applicableRefNo : "",
                applicable: applicable ? false : true,
            }
            await saveMaterialUsedData(obj, "MATERIAL_USED")
                .then((resp) => {
                    setOpenWarningDialog(false)
                    getMaterialUsedData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Reclaimed Products") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "refNo": applicableRefNo !== null ? applicableRefNo : "",
                applicable: applicable ? false : true,
            }
            await saveMaterialUsedData(obj, "RECLAIMED_PRODUCT")
                .then((resp) => {
                    setOpenWarningDialog(false)
                    getReclaimedProductsData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Recycled Input") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "refNo": applicableRefNo !== null ? applicableRefNo : "",
                applicable: applicable ? false : true,
            }
            await saveMaterialUsedData(obj, "RECYCLED_INPUT")
                .then((resp) => {
                    setOpenWarningDialog(false)
                    getRecycledInputData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Energy Consumption Within Organization") {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateEnergyData(obj)
                .then((resp) => {
                    setOpenWarningDialog(false);
                    getEnergyData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Energy Consumption Outside Organization") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateEnergyOutsideData(obj)
                .then((resp) => {
                    energyConsumptionOutsideData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Water Withdrawal") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : "",
            }
            await saveWaterWithdrawalData(obj)
                .then((resp) => {
                    setOpenWarningDialog(false);
                    getWaterWithdrawalData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Water Discharge") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : "",
            }
            await saveOrUpdateWaterDischargeData([obj])
                .then((resp) => {
                    setOpenWarningDialog(false);
                    getWaterDischargeAllData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Water Consumption") {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : "",
            }
            await saveOrUpdateWaterConsumptionSupplierData(obj)
                .then((resp) => {
                    setOpenWarningDialog(false)
                    waterConsumptionSupplierData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "GHG Scope 1") {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            saveOrUpdateGhgScopeData(obj, "GHG_SCOPE1")
                .then((resp) => {
                    setOpenWarningDialog(false);
                    getGhgScope1AllData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "GHG Scope 2") {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateGhgScopeData(obj, "GHG_SCOPE2")
                .then((resp) => {
                    setOpenWarningDialog(false);
                    getGhgScope2AllData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "GHG Scope 3") {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateGhgScopeData(obj, "GHG_SCOPE3")
                .then((resp) => {
                    setOpenWarningDialog(false);
                    getGhgScope3AllData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Air Emissions - Point Source") {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateAirEmissionPointSourceData(obj)
                .then((resp) => {
                    setOpenWarningDialog(false)
                    allAirEmissionQuantityData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Air Emissions - Area Source") {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateAirEmissionAreaSourceData(obj)
                .then((resp) => {
                    setOpenWarningDialog(false)
                    getAllAreaSourceData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Air Emissions & Interactions") {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateAirEmissionsInteractionsData(obj)
                .then((resp) => {
                    setOpenWarningDialog(false)
                    getAllAirEmissionsInteractionsData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Diversity of Governance Bodies") {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : "",
            }
            await saveOrUpdateDiversity(obj)
                .then((resp) => {
                    setOpenWarningDialog(false)
                    getDiversityOfGovernanceData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Coverage of Training") {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : "",
            }
            await saveOrUpdateCoverageOfTraining(obj)
                .then((resp) => {
                    setOpenWarningDialog(false)
                    getCoverageOfTrainingData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Grievances Redressal System") {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : "",
            }
            await saveOrUpdateGrievanceRedressalData(obj)
                .then((resp) => {
                    setOpenWarningDialog(false)
                    getGrievanceRedressalData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Work Related Injuries") {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : "",
            }
            await saveOrUpdateInjuriesData(obj)
                .then((resp) => {
                    setOpenWarningDialog(false)
                    getInjuriesTableData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Work Related Ill Health") {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : "",
            }
            await saveOrUpdateWorkRelatedIllHealthData(obj)
                .then((resp) => {
                    setOpenWarningDialog(false)
                    getWorkRelatedIllHealthData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Employee & Workers") {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : "",
            }
            await saveOrUpdateEmployeeAndWorker(obj, "EMPLOYEE_AND_WORKER")
                .then((resp) => {
                    setOpenWarningDialog(false)
                    getEmployeeAndWorkerAllData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Differently Abled Employees & Workers") {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : "",
            }
            await saveOrUpdateDifferentlyAbledEmployeesWorkersData(obj, "DIFFERENTLY_ABLED_EMPLOYEES_WORKERS")
                .then((resp) => {
                    setOpenWarningDialog(false)
                    getDifferentlyAbledEmployeesWorkersData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "New Hiring & Turnover") {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : "",
                typeOfRecord: "New_Hiring_Turnover"
            }
            await saveOrUpdateEmployeeHiringData(obj)
                .then((resp) => {
                    setOpenWarningDialog(false)
                    getAllEmployeeHiringData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Waste Generation") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : "",
            }
            await saveOrUpdateWasteGenerationData(obj)
                .then((resp) => {
                    getWasteGenerationData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Waste Recovery") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : "",
            }
            await saveOrUpdateWasteRecoveryData(obj)
                .then((resp) => {
                    getAllWasteRecoveryData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Waste Disposal") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : "",
            }
            await saveOrUpdateWasteDisposalData(obj)
                .then((resp) => {
                    getAllWasteDisposalData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Energy Efficiency - Production") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateProductionEfficiencyData(obj)
                .then((resp) => {
                    getEfficiencyProductionData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Energy Efficiency - Products & Services") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateEnergyEfficiencyProductsServiceData(obj)
                .then((resp) => {
                    getAllEnergyEfficiencyProductsServiceData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Biodiversity Value of Site") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateBiodiversityValueOfSiteData(obj)
                .then((resp) => {
                    getBiodiversityValueOfSiteTableData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Sensitive Species Affected") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateSensitiveSpeciesAffectedData(obj)
                .then((resp) => {
                    getAllSensitiveSpeciesAffectedData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Habitats Protected or Restored") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateHabitatsProtectedOrRestoredData(obj)
                .then((resp) => {
                    getAllHabitatsProtectedData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Biodiversity Service Impacted") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateBiodiversityImpactsData(obj)
                .then((resp) => {
                    getBiodiversityImpactsAllData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Social Screening") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateSocialScreeningData(obj)
                .then((resp) => {
                    getAllSocialScreeningData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Security Practices") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateSecurityPracticesData(obj)
                .then((resp) => {
                    getSecurityPracticesAllData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Environmental Criteria") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateEnvironmentalCriteriaData(obj)
                .then((resp) => {
                    getAllEnvironmentalCriteriaData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "EHS Approval Status") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateEHSApprovalStatusData(obj)
                .then((resp) => {
                    getAllEHSApprovalStatusData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Discrimination Incidents") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateDiscriminationIncidentsData(obj)
                .then((resp) => {
                    getDiscriminationIncidentsAllData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Retirement Benefit") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateRetirementBenefitData(obj)
                .then((resp) => {
                    getRetirementBenefitAllData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Parental Leave") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateParentalLeaveData(obj)
                .then((resp) => {
                    getAllParentalLeave()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Employees & Workers Well-Being") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateEmployeesWorkersWellBeingData(obj)
                .then((resp) => {
                    getAllEmployeesWorkersWellBeingData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Child & Forced Labour") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateChildAndForcedLabourData(obj)
                .then((resp) => {
                    getChildAndForcedLabourAllData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Indigenous Peoples Rights") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateIndigenousPeoplesRightsData(obj)
                .then((resp) => {
                    getIndigenousPeoplesRightsAllData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Notices & Fines") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateNoticesAndFinesData(obj)
                .then((resp) => {
                    getAllNoticesFinesData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Violation & Exceedance") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateViolationAndExceedanceData(obj)
                .then((resp) => {
                    getAllViolationExceedanceData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Performance Appraisal") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdatePerformanceAppraisalData(obj)
                .then((resp) => {
                    getAllPerformanceAppraisalData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "Freedom of Association") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveOrUpdateFreedomOfAssociationData(obj)
                .then((resp) => {
                    getFreedomOfAssociationAllData()
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (treeViewData?.subIssue === "ESG Strategy-Management") {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "applicable": applicable ? false : true,
                "refNo": applicableRefNo !== null ? applicableRefNo : ""
            }
            await saveStrategyManagementProcess(obj)
                .then((resp) => {
                    getStrategyManagement();
                    setOpenWarningDialog(false)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        };
    };

    const handleClickClose = () => {
        setOpenWarningDialog(false)
    };

    return (
        <div>
            <material.Dialog open={openWarningDialog} maxWidth="sm" fullWidth>
                <material.DialogTitle>{applicable ? "Are you sure, you want to change the mode Applicable to Not Applicable ?" : "Are you sure, you want to change the mode Not Applicable to Applicable ?"}</material.DialogTitle>
                <material.DialogActions className='m-3'>
                    <material.Button onClick={handleClickClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>No</material.Button>
                    <material.Button color='success' variant="outlined" onClick={save} sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />}>Yes</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default ApplicableNotApplicableWarning;