import React from 'react';
import { material } from '../../library/Material';
import NavBar from '../navbar/NavBar';
import SideBar from '../side-bar/SideBar';
import { styled, useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { drawerAction } from '../../store/slice/DrawerSlice';

const drawerWidth = 300;
const height = "100vh";

const openedMixin = (theme) => ({
    width: drawerWidth,
    height:height,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const Drawer = styled(material.MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        height:height,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function SwipeDrawer(props) {

    const theme = useTheme();
    const dispatch = useDispatch();
    const drawerActionData = useSelector((state) => state.DrawerSlice.data);
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
        dispatch(drawerAction(true))
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
        dispatch(drawerAction(false))
    };


    return (
        <material.Box sx={{ display: 'flex' }}>
            <NavBar handleDrawerOpen={handleDrawerOpen} openDrawer={drawerActionData ? drawerActionData : openDrawer} setOpenDrawer={setOpenDrawer} />
            <Drawer variant="permanent" open={drawerActionData ? drawerActionData : openDrawer}>
                <DrawerHeader>
                    <material.Typography sx={{ fontWeight: "bold", fontSize: 15, mr:5 }}>ESG Reporting Tool</material.Typography>
                    <material.IconButton onClick={handleDrawerClose} title='Close Menu'>
                        {theme.direction === 'rtl' ? <material.ChevronRightIcon /> : <material.ChevronLeftIcon />}
                    </material.IconButton>
                </DrawerHeader>
                <SideBar openDrawer={drawerActionData ? drawerActionData : openDrawer} />
            </Drawer>
        </material.Box>
    );
}

export default SwipeDrawer;