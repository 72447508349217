import React, { useState, useEffect } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import ColumnChart from '../../../shared/reporting/ColumnChart';
import { MoreOptionMenu } from '../Dashboard';
import LineChart from '../../../shared/reporting/LineChart';
import PieChart from '../../../shared/reporting/PieChart';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import RecyclingPlant from '../../../assets/recycle.png';
import StockDown from '../../../assets/trending.png';
import FilterImage from '../../../assets/filter.png';
import GlobalFilter from '../GlobalFilter';
import { getFuelUsedData, getMaterialReclaimedData, getMaterialRecycledData, getMaterialReportData, getMaterialSourcedData, getOtherMaterialUsedData } from '../../../services/reporting-services/MaterialService';
import { fuelConsumptionTableColumns, fuelUsedDerivedColumns, fuelUsedFacilityWiseDerivedColumns, materialSourcedColumns, materialSourcedDerivedColumns, materialSourcedFacilityWiseColumns, otherMaterialUsedDerivedColumns, otherMaterialUsedFacilityWiseDerivedColumns, otherMaterialUsedTableColumns, tableHeaderContent } from '../../../shared/reporting/TableHeader';
import BarsLoader from '../../../shared/loader/BarsLoader';
import Snackbar from '../../../toaster-message/Snackbar';
import { percentageCalculation, sum } from '../../../shared/Shared';
import moment from 'moment';


const yearData = dayjs(new Date());
var reclaimedPercentage = 0;
var recyclePercentage = 0;

const currentYear = moment().format('YYYY');

function Material(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [isBarsLoader, setIsBarsLoader] = useState(true);
    const [value, setValue] = React.useState(dayjs(new Date()));
    const [openFilterDialog, setOpenFilterDialog] = useState({ action: false, userData: null });
    const [materialChartData, setMaterialChartData] = useState([]);

    const year = localStorage.getItem("year");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [pieChartDataSource, setPieChartDataSource] = useState(null);
    const [columnChartData, setColumnChartData] = useState(null);
    const [materialTableData, setMaterialTableData] = useState([]);
    const [fuelChartData, setFuelChartData] = useState(null);
    const [otherMaterialChartData, setOtherMaterialChartData] = useState(null);
    const [recycleAndReclaimedPercentage, setRecycleAndReclaimedPercentage] = useState(null);
    const [fuelTableData, setFuelTableData] = useState([]);
    const [otherMaterialUsedTableData, setOtherMaterialUsedTableData] = useState([]);
    const [filterData, setFilterData] = useState(null);
    const [materialSourceChartData, setMaterialSourceChartData] = useState(null);
    const [materialSourceTableData, setMaterialSourceTableData] = useState([]);
    // const [reclaimedPercentage, setReclaimedPercentage] = useState(0);
    // const [recyclePercentage, setRecyclePercentage] = useState(0);

    const options = {
        title: "Material Used",
        titleTextStyle: {
            fontName: 'Arial', // Font family of the title
            fontSize: 18,      // Size of the title text
            bold: true,        // Make the title text bold
            italic: false      // Title text style is not italic
        },
        colors: ["#00551F", "#541F00"],
        hAxis: {
            title: 'Types of Source',
        },
        vAxis: {
            title: 'Unit MT',
        },
        legend: {
            position: "top",
            alignment: 'end'
        },
        chartArea: { width: "80%" },
    };

    const fuelUsedOptions = {
        title: "Fuel Used",
        isStacked: true,
        titleTextStyle: {
            fontName: 'Arial', // Font family of the title
            fontSize: 18,      // Size of the title text
            bold: true,        // Make the title text bold
            italic: false      // Title text style is not italic
        },
        colors: ["#00551F", "#541F00"],
        hAxis: {
            title: 'Types of Fuel',
        },
        vAxis: {
            title: 'Unit Tonne',
        },
        legend: {
            position: "bottom",
            alignment: 'center'
        },
        chartArea: { width: "80%" },
    };

    const otherMaterialUsedOptions = {
        title: "Other Material Used",
        isStacked: true,
        titleTextStyle: {
            fontName: 'Arial', // Font family of the title
            fontSize: 18,      // Size of the title text
            bold: true,        // Make the title text bold
            italic: false      // Title text style is not italic
        },
        colors: ["#ffddbf", "#f46b1c"],
        hAxis: {
            title: 'Type of Material',
        },
        vAxis: {
            title: 'Unit Tonne',
        },
        legend: {
            position: "bottom",
            alignment: 'center'
        },
        chartArea: { width: "80%" },
    };

    const materialSourceOptions = {
        title: "Material Sourced form MSME & Small Product",
        isStacked: true,
        titleTextStyle: {
            fontName: 'Arial', // Font family of the title
            fontSize: 18,      // Size of the title text
            bold: true,        // Make the title text bold
            italic: false      // Title text style is not italic
        },
        colors: ["#ffddbf", "#f46b1c"],
        vAxis: {
            title: 'Amount in INR',
        },
        hAxis: {
            title: 'Material Sourced',
        },
        legend: {
            position: "bottom",
            alignment: 'center'
        },
        chartArea: { width: "80%" },
    };

    const pieChartOptions = {
        title: "Material Used Percentage",
        titleTextStyle: {
            fontName: 'Arial', // Font family of the title
            fontSize: 18,      // Size of the title text
            bold: true,        // Make the title text bold
            italic: false      // Title text style is not italic
        },
        colors: ["#35A682", "#A66F35"],
        legend: {
            position: "bottom",
        },
        chartArea: { width: "80%" },
    };

    useEffect(() => {
        localStorage.setItem("year", yearData.$y);
        let facilities = userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? null : [userDetails.facilityRefNo];
        getMaterialReportingData(facilities, year);
        getAllMaterialUsedData(facilities, year)
        getFuelUsedReportingData();
        getOtherMaterialUsedReportingData();
        getMaterialSourcedReportingData();
    }, []);

    const getFilterData = (filterData) => {
        setFilterData(filterData);
        getFuelUsedReportingData(filterData);
        getMaterialSourcedReportingData(filterData);
        getOtherMaterialUsedReportingData(filterData)
        getMaterialReportingData(filterData.facilityRefNo, filterData?.yearList[0]);
        getAllMaterialUsedData(filterData.facilityRefNo, filterData?.yearList[0]);
    };

    const getMaterialReportingData = async (facilities, year) => {
        const array = [];
        setIsBarsLoader(true)
        let payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityList: facilities?.length > 0 ? facilities.join(',') : null,
            YearList: year
        }
        await getMaterialReportData(payload)
            .then((resp) => {
                const columnChartDataSource = [
                    ["Material Type", "Amount", { role: "style" }],
                    ["Renewable", resp.data.corporateTotalRenewableMaterialsUsed, "#00551F"],
                    ["Non-renewable", resp.data.corporateTotalNonRenewableMaterialsUsed, "#541F00"],
                ];
                setColumnChartData(columnChartDataSource)
                const pieChartDataSource = [
                    ["Material Type", "Amount", { role: "style" }],
                    ["Renewable", resp.data.corporateTotalRenewableMaterialUsedPercentage, "#35A682"],
                    ["Non-renewable", resp.data.corporateTotalNonRenewableMaterialUsedPercentage, "#A66F35"],
                ];
                setPieChartDataSource(pieChartDataSource)

                if (resp.data.facilityDetails) {
                    const percentage = {
                        materialName: "Percentage",
                        nonRenewable: resp.data.corporateTotalNonRenewableMaterialUsedPercentage.toFixed(2),
                        renewable: resp.data.corporateTotalRenewableMaterialUsedPercentage.toFixed(2),
                        totalMaterialsUsed: 100
                    };
                    const total = {
                        materialName: "Total",
                        nonRenewable: resp.data.corporateTotalNonRenewableMaterialsUsed.toFixed(2),
                        renewable: resp.data.corporateTotalRenewableMaterialsUsed.toFixed(2),
                        totalMaterialsUsed: resp.data.corporateTotalMaterialsUsed.toFixed(2)
                    };
                    resp.data.facilityDetails.forEach(facility => {
                        const facilityName = facility.facilityName;

                        facility.materialUsedDetails.forEach(material => {
                            const row = {
                                facilityName,
                                materialName: material.materialName,
                                nonRenewable: material.nonRenewable.toFixed(2),
                                renewable: material.renewable.toFixed(2),
                                totalMaterialsUsed: material.totalMaterialsUsed.toFixed(2),
                            };
                            array.push(row);
                        });
                    });
                    array.push(total);
                    array.push(percentage);
                    setMaterialChartData(array);
                } else {
                    setMaterialChartData([]);
                    setColumnChartData(null);
                    setPieChartDataSource(null);
                }
                setIsBarsLoader(false);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    };

    const getAllMaterialUsedData = async (facilities, year) => {
        setIsBarsLoader(true);
        let payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityList: facilities?.length > 0 ? facilities.join(',') : null,
            YearList: year
        }
        try {
            await Promise.all([
                getMaterialReportData(payload),
                getMaterialReclaimedData(payload),
                getMaterialRecycledData(payload)
            ])
                .then((resp) => {
                    const materialUsedData = resp[0].data;
                    const reclaimedData = resp[1].data;
                    const recycleData = resp[2].data;
                    const materialTableData = [];
                    const reclaimedTableData = [];
                    const recycleTableData = [];
                    if (!(reclaimedData.corporateName && recycleData.corporateName)) {
                        reclaimedPercentage = 0;
                        recyclePercentage = 0;
                    }
                    const totalMaterialUsed = {
                        facilityName: "Total",
                        nonRenewable: materialUsedData.corporateTotalNonRenewableMaterialsUsed?.toFixed(2),
                        renewable: materialUsedData.corporateTotalRenewableMaterialsUsed?.toFixed(2),
                        totalMaterialsUsed: materialUsedData.corporateTotalMaterialsUsed?.toFixed(2)
                    };

                    const percentageMaterialUsed = {
                        facilityName: "Percentage",
                        nonRenewable: materialUsedData.corporateTotalNonRenewableMaterialUsedPercentage?.toFixed(2),
                        renewable: materialUsedData.corporateTotalRenewableMaterialUsedPercentage?.toFixed(2),
                        totalMaterialsUsed: 100
                    };

                    materialUsedData?.facilityDetails?.forEach(facilities => {
                        const facilityName = facilities.facilityName;
                        facilities.materialUsedDetails.forEach(material => {
                            const row = {
                                facilityName,
                                "materialName": material.materialName,
                                "renewable": material.renewable ? material.renewable?.toFixed(2) : 0,
                                "nonRenewable": material.nonRenewable ? material.nonRenewable?.toFixed(2) : 0,
                                "totalMaterialsUsed": material.totalMaterialsUsed ? material.totalMaterialsUsed?.toFixed(2) : 0
                            };
                            materialTableData.push(row);
                        })
                    })
                    materialTableData.push(totalMaterialUsed);
                    materialTableData.push(percentageMaterialUsed);

                    reclaimedData?.facilityDetails?.forEach(facilities => {
                        const facilityName = facilities.facilityName;
                        const totalTypes = facilities.typeTotals;
                        facilities.materialUsedDetails.forEach(material => {
                            const row = {
                                facilityName,
                                materialName: material.materialName,
                                "safelyDisposed": material.typeDetails["Safely Disposed"] ? material.typeDetails["Safely Disposed"]?.toFixed(2) : 0,
                                "reUsed": material.typeDetails["Reused"] ? material.typeDetails["Reused"]?.toFixed(2) : 0,
                                recycled: material.typeDetails.Recycled ? material.typeDetails.Recycled?.toFixed(2) : 0,
                                totalMaterialsUsed: material.totalMaterialsUsed?.toFixed(2)
                            };
                            reclaimedTableData.push(row);
                        })
                        const totalReclaimed = {
                            facilityName: "Total",
                            "safelyDisposed": totalTypes["Safely Disposed"] ? totalTypes["Safely Disposed"]?.toFixed(2) : 0,
                            "reUsed": totalTypes["Reused"] ? totalTypes["Reused"]?.toFixed(2) : 0,
                            recycled: totalTypes.Recycled ? totalTypes.Recycled?.toFixed(2) : 0,
                            totalMaterialsUsed: reclaimedData.corporateTotalMaterialsUsed?.toFixed(2)
                        };
                        reclaimedPercentage = percentageCalculation(Number(materialUsedData.corporateTotalMaterialsUsed), Number(reclaimedData.corporateTotalMaterialsUsed));
                        reclaimedTableData.push(totalReclaimed);
                    });

                    const newReclaimedData = reclaimedTableData.filter(obj => obj.facilityName !== "Total");
                    const totalReclaimedValues = {
                        safelyDisposed: newReclaimedData.reduce((acc, obj) => acc + parseFloat(obj.safelyDisposed || 0), 0),
                        reUsed: newReclaimedData.reduce((acc, obj) => acc + parseFloat(obj.reUsed || 0), 0),
                        recycled: newReclaimedData.reduce((acc, obj) => acc + parseFloat(obj.recycled || 0), 0),
                        totalMaterialsUsed: newReclaimedData.reduce((acc, obj) => acc + parseFloat(obj.totalMaterialsUsed || 0), 0)
                    };

                    newReclaimedData.push({ facilityName: "Total", ...totalReclaimedValues });
                    recycleData?.facilityDetails?.forEach(facilities => {
                        const facilityName = facilities.facilityName;
                        const totalTypes = facilities.typeTotals;
                        facilities.materialUsedDetails.forEach(material => {
                            const row = {
                                facilityName,
                                materialName: material.materialName,
                                "safelyDisposed": material.typeDetails["Safely Disposed"] ? material.typeDetails["Safely Disposed"]?.toFixed(2) : 0,
                                "reUsed": material.typeDetails["Reused"] ? material.typeDetails["Reused"]?.toFixed(2) : 0,
                                recycled: material.typeDetails.Recycled ? material.typeDetails.Recycled?.toFixed(2) : 0,
                                totalMaterialsUsed: material.totalMaterialsUsed?.toFixed(2)
                            };
                            recycleTableData.push(row);
                        })
                        const totalRecycle = {
                            facilityName: "Total",
                            "safelyDisposed": totalTypes["Safely Disposed"] ? totalTypes["Safely Disposed"]?.toFixed(2) : 0,
                            "reUsed": totalTypes["Reused"] ? totalTypes["Reused"]?.toFixed(2) : 0,
                            recycled: totalTypes.Recycled ? totalTypes.Recycled?.toFixed(2) : 0,
                        };

                        recyclePercentage = percentageCalculation(Number(materialUsedData.corporateTotalMaterialsUsed), Number(recycleData.corporateTotalMaterialsUsed));
                        recycleTableData.push(totalRecycle);
                    });

                    const newData = recycleTableData.filter(obj => obj.facilityName !== "Total");
                    const totalValues = {
                        safelyDisposed: newData.reduce((acc, obj) => acc + parseFloat(obj.safelyDisposed || 0), 0),
                        reUsed: newData.reduce((acc, obj) => acc + parseFloat(obj.reUsed || 0), 0),
                        recycled: newData.reduce((acc, obj) => acc + parseFloat(obj.recycled || 0), 0),
                        totalMaterialsUsed: newData.reduce((acc, obj) => acc + parseFloat(obj.totalMaterialsUsed || 0), 0)
                    };

                    newData.push({ facilityName: "Total", ...totalValues });
                    setRecycleAndReclaimedPercentage(Number(reclaimedPercentage) + Number(recyclePercentage));

                    const deriveData = [
                        {
                            totalMaterialUsed: materialUsedData.corporateTotalMaterialsUsed?.toFixed(2),
                            totalReclaimed: reclaimedData.corporateTotalMaterialsUsed?.toFixed(2),
                            totalRecycle: recycleData.corporateTotalMaterialsUsed?.toFixed(2)
                        },
                        {
                            totalMaterialUsed: "Percentage",
                            totalReclaimed: reclaimedPercentage,
                            totalRecycle: recyclePercentage
                        },
                    ];

                    const materialUsedTableData = [
                        { columnName: tableHeaderContent[15].materialUsedTableColumns, tableData: materialTableData, tableName: "Material Use in Metric Tons" },
                        { columnName: tableHeaderContent[16].recycleInputColumns, tableData: newReclaimedData, tableName: "Reclaimed Product in Metric Tons" },
                        { columnName: tableHeaderContent[16].recycleInputColumns, tableData: newData, tableName: "Recycle Input in Metric Tons" },
                        { columnName: tableHeaderContent[17].derivedColumns, tableData: deriveData, tableName: "Derived Data" },
                    ];
                    setMaterialTableData(materialUsedTableData);
                    setIsBarsLoader(false);
                })
        } catch (error) {
            if (error.response?.status === 401) {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "You are not authorized to perform this action. Redirected to login",
                })
            } else {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message,
                })
            };
        }
    };

    // Fuel used

    const getFuelUsedReportingData = (filterData) => {
        let payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? filterData?.facilityRefNo?.length > 0 ? filterData.facilityRefNo.join(',') : "" : userDetails.facilityRefNo,
            dataRangeDetails: filterData ? filterData.dataRangeDetails : 0,
            dataRange: filterData ? filterData.dataRange : "YEARLY",
            yearList: filterData ? filterData.yearList : [Number(currentYear)]
        }
        getFuelUsedData(payload)
            .then((resp) => {
                const forCorporate = [
                    ["Year", "Non Renewable", "Renewable"]
                ];
                const forFacility = [
                    ["Facility", "Non Renewable", "Renewable"]
                ];
                let renewableValue = 0;
                let nonRenewableValue = 0;
                const array = [];
                const deriveData = [];
                const facilityWiseDerivedData = [];
                let facilityName;
                if (resp.data.facilityData) {
                    resp.data.facilityData.forEach(yearData => {
                        if (yearData.value?.facilityDetails) {
                            // for chart
                            if (filterData?.facilityRefNo.length) {
                                yearData.value.facilityDetails.forEach(facility => {
                                    facility.value.forEach(fuelData => {
                                        if (fuelData.typeOfSource === "Renewable") {
                                            renewableValue = fuelData.totalQuantityOfMaterials
                                        } else {
                                            nonRenewableValue = fuelData.totalQuantityOfMaterials
                                        }
                                    })
                                    const chartData = [facility.facilityName + " " + " " + `(${yearData.year.toString()})`, nonRenewableValue, renewableValue];
                                    forFacility.push(chartData)
                                    setFuelChartData(forFacility)
                                })
                            } else {
                                const chartData = [yearData.year.toString(), yearData.value.corporateTotalNonRenewableQuantityOfMaterials, yearData.value.corporateTotalRenewableQuantityOfMaterials];
                                forCorporate.push(chartData);
                                setFuelChartData(forCorporate)
                            }

                            //for table 
                            const year = yearData.year;
                            yearData.value.facilityDetails.forEach(facility => {
                                facilityName = facility.facilityName;
                                const mappingData = [];
                                const facilityWiseRenewableAndNonRenewableData = [];
                                facility.value.forEach(source => {
                                    const typeOfSource = source.typeOfSource;
                                    source.data.forEach(fuel => {
                                        const fuelName = fuel.fuelName;
                                        fuel.data.forEach(fuelDetails => {
                                            const row = {
                                                typeOfSource,
                                                fuelName,
                                                purposeOfUse: fuelDetails.purposeOfUse,
                                                quantity: fuelDetails.quantity?.toFixed(2),
                                                standardDensity: fuelDetails.standardDensity,
                                                quantityOfMaterials: fuelDetails.quantityOfMaterials?.toFixed(2),
                                                grossCalorificValue: fuelDetails.grossCalorificValue,
                                                unitConversion: fuelDetails.unitConversion?.toFixed(4),
                                                energyUsesFromFuel: fuelDetails.energyUsesFromFuel?.toFixed(4),
                                            }
                                            mappingData.push(row);
                                        })
                                    })
                                    if (source.typeOfSource === "Renewable") {
                                        mappingData.push({
                                            typeOfSource: "Total Renewable",
                                            quantityOfMaterials: source.totalQuantityOfMaterials,
                                            energyUsesFromFuel: source.totalEnergyFromFuel?.toFixed(4)
                                        });
                                        facilityWiseRenewableAndNonRenewableData.push({
                                            typeOfSource: "Renewable",
                                            quantityOfMaterials: source.totalQuantityOfMaterials?.toFixed(2),
                                        })
                                    } else {
                                        mappingData.push({
                                            typeOfSource: "Total Non Renewable",
                                            quantityOfMaterials: source.totalQuantityOfMaterials,
                                            energyUsesFromFuel: source.totalEnergyFromFuel?.toFixed(4)
                                        });
                                        facilityWiseRenewableAndNonRenewableData.push({
                                            typeOfSource: "Non Renewable",
                                            quantityOfMaterials: source.totalQuantityOfMaterials?.toFixed(2),
                                        })
                                    }
                                })
                                array.push({ year, facilityName, rowData: mappingData });
                                facilityWiseDerivedData.push({ year: yearData.year, facilityName: facility.facilityName, rowData: facilityWiseRenewableAndNonRenewableData })
                            })
                            deriveData.push({ year: yearData.year, totalNonRenewable: yearData.value.corporateTotalNonRenewableQuantityOfMaterials?.toFixed(2), totalRenewable: yearData.value.corporateTotalRenewableQuantityOfMaterials?.toFixed(2) });
                            const fuelUsedTableData = [
                                { columnName: fuelConsumptionTableColumns, tableData: array, tableName: "Fuel Used in Tonne", dataType: "nested" },
                                { columnName: fuelUsedFacilityWiseDerivedColumns, tableData: facilityWiseDerivedData, tableName: "Facility Wise Derived", dataType: "nested" },
                                { columnName: fuelUsedDerivedColumns, tableData: deriveData, tableName: "Fuel Used Derived", dataType: "normal" },
                            ]
                            setFuelTableData(fuelUsedTableData)
                        }
                        // else {
                        //     setFuelTableData([]);
                        //     setFuelChartData(null);
                        // }
                    })
                }
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    };

    // other material used

    const getOtherMaterialUsedReportingData = (filterData) => {
        let payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? filterData?.facilityRefNo?.length > 0 ? filterData.facilityRefNo.join(',') : "" : userDetails.facilityRefNo,
            dataRangeDetails: filterData ? filterData.dataRangeDetails : 0,
            dataRange: filterData ? filterData.dataRange : "YEARLY",
            yearList: filterData ? filterData.yearList : [Number(currentYear)]
        }
        getOtherMaterialUsedData(payload)
            .then((resp) => {
                const forCorporate = [
                    ["Year", "Refrigerant", "Other Materials"]
                ];
                const forFacility = [
                    ["Facility", "Refrigerant", "Other Materials"]
                ];
                const array = [];
                const deriveData = [];
                const facilityWiseDerivedData = [];
                let totalRefrigerant = 0;
                let totalOtherMaterial = 0;
                if (resp.data.facilityData) {
                    resp.data.facilityData.forEach(yearData => {
                        if (yearData.value?.facilityDetails) {
                            // for chart
                            if (filterData?.facilityRefNo.length) {
                                yearData.value.facilityDetails.forEach(facility => {
                                    facility.value.forEach(otherMaterialDetails => {
                                        if (otherMaterialDetails.type === "Refrigerant") {
                                            totalRefrigerant = otherMaterialDetails.totalQuantity
                                        } else {
                                            totalOtherMaterial = otherMaterialDetails.totalQuantity
                                        }
                                    })
                                    const chartData = [facility.facilityName + " " + " " + `(${yearData.year.toString()})`, totalRefrigerant, totalOtherMaterial];
                                    forFacility.push(chartData)
                                    setOtherMaterialChartData(forFacility)
                                })
                            } else {
                                const chartData = [yearData.year.toString(), yearData.value.corporateTotalRefrigerantQuantity, yearData.value.corporateTotalOtherMaterialsQuantity];
                                forCorporate.push(chartData);
                                setOtherMaterialChartData(forCorporate)
                            }
                            //for Table Data
                            yearData.value.facilityDetails.forEach(facility => {
                                const mappingData = [];
                                const facilityWiseRefrigerantAndOtherMaterialData = [];
                                facility.value.forEach(otherMaterialDetails => {
                                    const type = otherMaterialDetails.type;
                                    otherMaterialDetails.data.forEach(otherMaterial => {
                                        const purpose = otherMaterial.purpose;
                                        otherMaterial.data.forEach(material => {
                                            const row = {
                                                type,
                                                purpose,
                                                ...material
                                            }
                                            mappingData.push(row);
                                        })
                                    })
                                    if (otherMaterialDetails.type === "Refrigerant") {
                                        mappingData.push({
                                            type: "Total Refrigerant",
                                            quantity: otherMaterialDetails.totalQuantity?.toFixed(2),
                                        });
                                        facilityWiseRefrigerantAndOtherMaterialData.push({
                                            type: "Refrigerant",
                                            quantity: otherMaterialDetails.totalQuantity?.toFixed(2)
                                        })
                                    } else {
                                        mappingData.push({
                                            type: "Total Other Material",
                                            quantity: otherMaterialDetails.totalQuantity?.toFixed(2),
                                        });
                                        facilityWiseRefrigerantAndOtherMaterialData.push({
                                            type: "Other Material",
                                            quantity: otherMaterialDetails.totalQuantity?.toFixed(2)
                                        })
                                    }
                                })
                                array.push({ year: yearData.year, facilityName: facility.facilityName, rowData: mappingData });
                                facilityWiseDerivedData.push({ year: yearData.year, facilityName: facility.facilityName, rowData: facilityWiseRefrigerantAndOtherMaterialData })
                            })
                            deriveData.push({ year: yearData.year, totalCorporateRefrigerantQuantity: yearData.value.corporateTotalRefrigerantQuantity?.toFixed(2), totalCorporateOtherMaterialQuantity: yearData.value.corporateTotalOtherMaterialsQuantity?.toFixed(2) })
                            const otherMaterialUsedTableData = [
                                { columnName: otherMaterialUsedTableColumns, tableData: array, tableName: "Other Material Used in Tonne", dataType: "nested" },
                                { columnName: otherMaterialUsedFacilityWiseDerivedColumns, tableData: facilityWiseDerivedData, tableName: "Facility Wise Derived", dataType: "nested" },
                                { columnName: otherMaterialUsedDerivedColumns, tableData: deriveData, tableName: "Other Material Used Derived", dataType: "normal" },
                            ]
                            setOtherMaterialUsedTableData(otherMaterialUsedTableData)
                        }
                        //  else {
                        //     setOtherMaterialUsedTableData([]);
                        //     setOtherMaterialChartData(null);
                        // }
                    })
                }
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    };

    // Material Sourced

    const getMaterialSourcedReportingData = (filterData) => {
        let payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? filterData?.facilityRefNo?.length > 0 ? filterData.facilityRefNo.join(',') : "" : userDetails.facilityRefNo,
            dataRangeDetails: filterData ? filterData.dataRangeDetails : 0,
            dataRange: filterData ? filterData.dataRange : "YEARLY",
            yearList: filterData ? filterData.yearList : [Number(currentYear)]
        }
        getMaterialSourcedData(payload)
            .then((resp) => {
                const deriveData = [];
                const array = [];
                const facilityWiseDerivedData = [];
                const forCorporate = [
                    ["Year", "Material Sourced from MSMEs/ Small Producers", "Material Imported"]
                ];
                const forFacility = [
                    ["Facility", "Material Sourced from MSMEs/ Small Producers", "Material Imported"]
                ];
                if (resp.data.facilityData) {
                    resp.data.facilityData.forEach(yearData => {
                        if (yearData.value?.facilityDetails) {
                            yearData.value.facilityDetails.forEach(facility => {
                                const facilityWiseImportedAndExportedData = [];
                                const mappingData = [];
                                const chartData = [facility.facilityName + " " + " " + `(${yearData.year.toString()})`, facility.totalDirectlySourcedMaterials, facility.totalImportedMaterials];
                                forFacility.push(chartData);
                                // if (filterData?.facilityRefNo.length) {
                                //     const chartData = [facility.facilityName + " " + `(${yearData.year.toString()})`, facility.totalDirectlySourcedMaterials, facility.totalImportedMaterials];
                                //     forFacility.push(chartData);
                                //     setMaterialSourceChartData(forFacility)
                                // } 
                                // else {
                                //     const chartData = [yearData.year.toString(), yearData.value.corporateTotalDirectlySourcedMaterials, yearData.value.corporateTotalImportedMaterials];
                                //     forCorporate.push(chartData);
                                //     setMaterialSourceChartData(forCorporate)
                                // }
                                const facilityName = facility.facilityName;
                                facility.value.forEach(sourceDetails => {
                                    sourceDetails.value.forEach(source => {
                                        source.details.forEach(item => {
                                            mappingData.push({
                                                materialName: source.materialsName,
                                                isImported: sourceDetails.isImported,
                                                amount: item.amount?.toFixed(2),
                                                materialsSourced: item.materialsSourced
                                            })
                                        })
                                    })
                                })
                                mappingData.push({
                                    materialName: "Total Directly Sourced from MSMEs/ Small Producers",
                                    amount: facility.totalDirectlySourcedMaterials?.toFixed(2),
                                });
                                mappingData.push({
                                    materialName: "Total Material Imported",
                                    amount: facility.totalImportedMaterials?.toFixed(2),
                                });
                                facilityWiseImportedAndExportedData.push({
                                    materialName: "Material Sourced from MSMEs/ Small Producers",
                                    amount: facility.totalDirectlySourcedMaterials?.toFixed(2)
                                });
                                facilityWiseImportedAndExportedData.push({
                                    materialName: "Material Imported",
                                    amount: facility.totalImportedMaterials?.toFixed(2)
                                })
                                array.push({ year: yearData.year, facilityName: facility.facilityName, rowData: mappingData });
                                facilityWiseDerivedData.push({ year: yearData.year, facilityName: facility.facilityName, rowData: facilityWiseImportedAndExportedData })
                            })
                            if (filterData?.facilityRefNo.length) {
                                setMaterialSourceChartData(forFacility)
                            } else {
                                const chartData = [yearData.year.toString(), yearData.value.corporateTotalDirectlySourcedMaterials, yearData.value.corporateTotalImportedMaterials];
                                forCorporate.push(chartData);
                                setMaterialSourceChartData(forCorporate)
                            }
                            deriveData.push({ year: yearData.year, totalDirectlySourcedMaterials: yearData.value.corporateTotalDirectlySourcedMaterials?.toFixed(2), totalImportedMaterials: yearData.value.corporateTotalImportedMaterials?.toFixed(2) })
                            const otherMaterialUsedTableData = [
                                { columnName: materialSourcedColumns, tableData: array, tableName: "Material Sourced in INR", dataType: "nested" },
                                { columnName: materialSourcedFacilityWiseColumns, tableData: facilityWiseDerivedData, tableName: "Facility Wise Derived", dataType: "nested" },
                                { columnName: materialSourcedDerivedColumns, tableData: deriveData, tableName: "Material Sourced Derived", dataType: "normal" },
                            ]
                            setMaterialSourceTableData(otherMaterialUsedTableData);
                        }
                    })
                }
                else {
                    setMaterialSourceChartData(null);
                    setMaterialSourceTableData([]);
                }
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    };

    const handleChangeYear = (newValue) => {
        setValue(newValue);
    };

    const refreshData = () => {
        getFuelUsedReportingData();
        getOtherMaterialUsedReportingData();
        getMaterialSourcedReportingData();
        const facilities = userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? null : [userDetails.facilityRefNo];
        getMaterialReportingData(facilities, yearData.$y);
        getAllMaterialUsedData(facilities, yearData.$y);
        localStorage.setItem("year", yearData.$y);
        reclaimedPercentage = 0;
        recyclePercentage = 0;
    };

    const handleMenu = () => {
        setOpenFilterDialog({ action: true, userData: userDetails });
    };


    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 1, mt: 7 }}>
                    <div className="container-fluid">
                        <div className="row p-2">
                            <div className="col-lg-6 col-md-6">
                                <span><material.Typography variant="h5" className='text-capitalize'>Material</material.Typography></span>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className='float-end'>
                                    <span>
                                        <material.IconButton
                                            aria-label="more"
                                            id="long-button"
                                            onClick={handleMenu}
                                            title='Filter'
                                            color='info'
                                        >
                                            <img
                                                src={FilterImage}
                                                height={25}
                                                width={25}
                                            />
                                        </material.IconButton>
                                    </span>
                                    <span>
                                        <material.IconButton title='Refresh' color='success' onClick={refreshData}>
                                            <material.RefreshIcon />
                                        </material.IconButton>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 mt-3">
                                <material.Card elevation={3} className='column-chart'>
                                    <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                        <span>
                                            <MoreOptionMenu
                                                chartName="column-chart"
                                                options={options}
                                                columnChartData={columnChartData}
                                                tableData={materialChartData}
                                                year={year}
                                                columnName={tableHeaderContent[4].materialTableColumns}
                                                tableHeading="Material Use in Metric Tons"
                                            />
                                        </span>
                                    </div>
                                    <ColumnChart
                                        options={options}
                                        columnChartData={columnChartData}
                                    />
                                </material.Card>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <material.Card elevation={3} className='column-chart'>
                                    <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                        <span>
                                            <MoreOptionMenu
                                                chartName="column-chart"
                                                options={fuelUsedOptions}
                                                columnChartData={fuelChartData}
                                                tableData={fuelTableData}
                                                callFrom="multiple-table-GHG"
                                                dialogTitle="Fuel Used Reporting Data"
                                            />
                                        </span>
                                    </div>
                                    <ColumnChart
                                        options={fuelUsedOptions}
                                        columnChartData={fuelChartData}
                                    />
                                </material.Card>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <material.Card elevation={3} className='column-chart'>
                                    <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                        <span>
                                            <MoreOptionMenu
                                                chartName="column-chart"
                                                options={otherMaterialUsedOptions}
                                                columnChartData={otherMaterialChartData}
                                                tableData={otherMaterialUsedTableData}
                                                tableHeading="Other Material Used in Tonne"
                                                callFrom="multiple-table-GHG"
                                                dialogTitle="Other Material Used Reporting Data"
                                            />
                                        </span>
                                    </div>
                                    <ColumnChart
                                        options={otherMaterialUsedOptions}
                                        columnChartData={otherMaterialChartData}
                                    />
                                </material.Card>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <material.Card elevation={3} className='column-chart'>
                                    <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                        <span>
                                            <MoreOptionMenu
                                                chartName="column-chart"
                                                options={materialSourceOptions}
                                                columnChartData={materialSourceChartData}
                                                tableData={materialSourceTableData}
                                                tableHeading="Material Sourced"
                                                callFrom="multiple-table-GHG"
                                                dialogTitle="Material Sourced Reporting Data"
                                            />
                                        </span>
                                    </div>
                                    <ColumnChart
                                        options={materialSourceOptions}
                                        columnChartData={materialSourceChartData}
                                    />
                                </material.Card>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <material.Card elevation={3} className='column-chart'>
                                    <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                        <span>
                                            <MoreOptionMenu
                                                chartName="pie-chart"
                                                options={pieChartOptions}
                                                pieChartData={pieChartDataSource}
                                                tableData={materialChartData}
                                                year={year}
                                                columnName={tableHeaderContent[4].materialTableColumns}
                                                tableHeading="Material Use in Metric Tons"
                                            />
                                        </span>
                                    </div>
                                    {/* {isBarsLoader ? (
                                        <BarsLoader />
                                    ) : ( */}
                                    <PieChart
                                        options={pieChartOptions}
                                        pieChartData={pieChartDataSource}
                                    />
                                    {/* )} */}
                                </material.Card>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <material.Card elevation={3} sx={{ height: 430 }} className='column-chart'>
                                    <div className='d-flex flex-row-reverse' style={{ marginBottom: -15 }}>
                                        <span>
                                            <MoreOptionMenu
                                                tableData={materialTableData}
                                                year={year}
                                                callFrom="multiple-table"
                                                hideFullScreen={true}
                                            />
                                        </span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "10px" }}>
                                        {isBarsLoader ? (
                                            <BarsLoader />
                                        ) : (
                                            <span className='recycle-reclaimed-chart'>
                                                <span className='text-center'>
                                                    <material.Typography variant="h5" sx={{ mt: 2, fontWeight: "bold" }} className='text-capitalize'>Recycle & Reclaimed Input</material.Typography>
                                                </span>
                                                <div className='text-center d-flex flex-column mt-5'>
                                                    <material.Typography variant="h5" sx={{ mt: 1, color: "brown", fontWeight: "bold" }} className='text-capitalize'>{recycleAndReclaimedPercentage?.toFixed(2)}</material.Typography>
                                                </div>
                                                <span style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "60px" }}>
                                                    <img
                                                        width="15%"
                                                        height="80px"
                                                        src={RecyclingPlant}
                                                        alt='RecyclingPlant'
                                                    />
                                                </span>
                                            </span>
                                        )}
                                    </div>
                                </material.Card>
                            </div>
                        </div>
                    </div>
                </material.Box>
            </material.Box>
            <GlobalFilter
                openFilterDialog={openFilterDialog}
                setOpenFilterDialog={setOpenFilterDialog}
                getFilterData={getFilterData}
                callFrom="issue-dashboard"
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default Material;