import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import moment from 'moment';


function AddIntellectualPropertyBenefit(props) {

    const { treeViewData, getIntellectualPropertyBenefit, openIntellectualPropertyBenefit, setOpenIntellectualPropertyBenefit, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [categoryData, setCategoryData] = useState('');
    const [benefitSharedData, setBenefitSharedData] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openIntellectualPropertyBenefit.action && openIntellectualPropertyBenefit.intellectualPropertyBenefitTableData) {
            setCategoryData(openIntellectualPropertyBenefit.intellectualPropertyBenefitTableData.category);
            setBenefitSharedData(openIntellectualPropertyBenefit.intellectualPropertyBenefitTableData.benefit_shared);
            reset(openIntellectualPropertyBenefit.intellectualPropertyBenefitTableData);
        }
    }, [openIntellectualPropertyBenefit.action === true]);

    const handleClose = () => {
        setCategoryData("");
        setBenefitSharedData("");
        reset({ intellectual_property: "", basis_of_calculating_benefit: "", });
        setOpenIntellectualPropertyBenefit({ action: false, intellectualPropertyBenefitTableData: null });
    };

    const saveIntellectualPropertyBenefit = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "intellectual_property": data.intellectual_property,
                "category": data.category,
                "benefit_shared": data.benefit_shared,
                "basis_of_calculating_benefit": data.basis_of_calculating_benefit,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getIntellectualPropertyBenefit(getFilterDate, payloadData);
                    setCategoryData("");
                    setBenefitSharedData("");
                    reset({ intellectual_property: "", basis_of_calculating_benefit: "", });
                    setOpenIntellectualPropertyBenefit({ action: false, intellectualPropertyBenefitTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateIntellectualPropertyBenefit = async (data) => {
        const refNo = openIntellectualPropertyBenefit.intellectualPropertyBenefitTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "intellectual_property": data.intellectual_property,
            "category": data.category,
            "benefit_shared": data.benefit_shared,
            "basis_of_calculating_benefit": data.basis_of_calculating_benefit,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getIntellectualPropertyBenefit(getFilterDate, payloadData);
                setCategoryData("");
                setBenefitSharedData("");
                reset({ intellectual_property: "", basis_of_calculating_benefit: "", });
                setOpenIntellectualPropertyBenefit({ action: false, intellectualPropertyBenefitTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const changeCategory = (event) => {
        setCategoryData(event.target.value);
    };
    const changeBenefitShared = (event) => {
        setBenefitSharedData(event.target.value);
    }

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openIntellectualPropertyBenefit.intellectualPropertyBenefitTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setCategoryData("");
                setBenefitSharedData("");
                reset({ intellectual_property: "", basis_of_calculating_benefit: "", });
                getIntellectualPropertyBenefit(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenIntellectualPropertyBenefit({ action: false, intellectualPropertyBenefitTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openIntellectualPropertyBenefit.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openIntellectualPropertyBenefit.intellectualPropertyBenefitTableData ? "Edit Intellectual Property Benefit" : "Add Intellectual Property Benefit"}</material.DialogTitle>
                <material.DialogContent>
                    {openIntellectualPropertyBenefit.intellectualPropertyBenefitTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Intellectual Property Benefit</material.Typography>
                            </div>
                            <div className='col-12'>
                                <material.TextField
                                    {...register("intellectual_property", { required: true, minLength: 2, maxLength: 200 })}
                                    required
                                    label="Intellectual Property Based on Traditional Knowledge"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    multiline
                                    fullWidth
                                    rows={3}
                                />
                                <p className='form-text text-danger'>{errors.intellectual_property?.type === "required" && 'This field is required'}</p>
                                {errors.intellectual_property && (errors.intellectual_property.type === "minLength" || errors.intellectual_property.type === "maxLength") && (
                                    <p className="errorMsg text-danger">Describe The Intellectual Property Based on Traditional Knowledge Issues should be between 2 to 200 character.</p>
                                )}
                            </div>
                            <div className='col-4 mt-3'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Category</material.InputLabel>
                                    <material.Select
                                        {...register("category", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={categoryData}
                                        onChange={changeCategory}
                                        label="Category"
                                    >
                                        <material.MenuItem value={"Owned"}>Owned</material.MenuItem>
                                        <material.MenuItem value={"Acquired"}>Acquired</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4 mt-3'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Benefit Shared</material.InputLabel>
                                    <material.Select
                                        {...register("benefit_shared", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={benefitSharedData}
                                        onChange={changeBenefitShared}
                                        label="Benefit Shared"
                                    >
                                        <material.MenuItem value={"Yes"}>Yes</material.MenuItem>
                                        <material.MenuItem value={"No"}>No</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4 mt-3'>
                                <material.TextField
                                    {...register("basis_of_calculating_benefit", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Basis of Calculating Benefit"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.basis_of_calculating_benefit?.type === "required" && 'This field is required'}</p>
                                {errors.basis_of_calculating_benefit && errors.basis_of_calculating_benefit.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openIntellectualPropertyBenefit.intellectualPropertyBenefitTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateIntellectualPropertyBenefit)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openIntellectualPropertyBenefit.intellectualPropertyBenefitTableData.rejection_note === null) || (openIntellectualPropertyBenefit.intellectualPropertyBenefitTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveIntellectualPropertyBenefit)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddIntellectualPropertyBenefit; 