
export const sum = (male, female, other = 0) => {
    return (male + female + other);
};

export const percentageCalculation = (totalValue, partValue) => {
    let parentageValue = (partValue / totalValue) * 100;
    return (parentageValue.toFixed(2));
};

export const multiplication = (valueOne, valueTwo) => {
    return (valueOne * valueTwo)
};

export const checkCriteriaForNoTypeAnswer = (data) => {
    return (Number(data.male) + Number(data.female) + Number(data.other)) === Number(data.total);
};
export const checkCriteriaForYesTypeAnswer = (data) => {
    return ((Number(data.male_under_30) + Number(data.male_30_to_50) + Number(data.male_above_50)) === Number(data.male)) &&
        ((Number(data.female_under_30) + Number(data.female_30_to_50) + Number(data.female_above_50)) === Number(data.female)) &&
        ((Number(data.other_under_30) + Number(data.other_30_to_50) + Number(data.other_above_50)) === Number(data.other))
};

export const transformData = (data, typeMapping, typeList) => {
    // const result = [];
    // const totals = {};

    // // Initialize totals object based on typeMapping
    // Object.values(typeMapping).forEach(key => {
    //     totals[key] = 0;
    // });

    // // Loop through each data item
    // data.forEach(item => {
    //     const { typeOfMaterial, totalAmount } = item;

    //     // Initialize newObj dynamically based on typeMapping
    //     const newObj = {
    //         material: item.material,
    //         total: totalAmount, // Initialize total with totalAmount
    //     };

    //     // Update newObj properties based on typeMapping
    //     Object.keys(typeMapping).forEach(type => {
    //         newObj[typeMapping[type]] = typeOfMaterial === type ? totalAmount : 0;
    //         totals[typeMapping[type]] += newObj[typeMapping[type]];
    //     });

    //     // Push the new object into the result array
    //     result.push(newObj);
    // });

    // // Calculate the total across all types
    // totals.total = Object.values(totals).reduce((acc, curr) => acc + curr, 0);

    // // Create a separate object for the totals
    // const totalsObj = {
    //     material: 'Totals',
    //     ...totals
    // };

    // // Calculate percentages based on the total amount
    // const percentagesObj = {
    //     material: 'Percentages'
    // };

    // Object.keys(totals).forEach(key => {
    //     if (key !== 'total') {
    //         const percentage = ((totals[key] / totals.total) * 100).toFixed(2);
    //         percentagesObj[key] = percentage;
    //     } else {
    //         percentagesObj[key] = '100';
    //     }
    // });

    // // Push the totals object and percentages object into the result array
    // result.push(totalsObj);
    // result.push(percentagesObj);

    // return result;

    const result = [];
    const totals = {};

    // Initialize totals object based on typeMapping
    Object.values(typeMapping).forEach(key => {
        totals[key] = 0;
    });

    // Loop through each data item
    data.forEach(item => {
        const { typeOfMaterial, totalAmount } = item;

        // Initialize newObj dynamically based on typeMapping
        const newObj = {
            material: item.material,
            total: (typeList && typeList.includes(typeOfMaterial)) ? totalAmount : 0,
        };

        // Update the newObj properties based on typeMapping
        Object.keys(typeMapping).forEach(type => {
            newObj[typeMapping[type]] = typeOfMaterial === type ? totalAmount : 0;
            totals[typeMapping[type]] += newObj[typeMapping[type]];
        });

        // Push the new object into the result array
        result.push(newObj);
    });

    // Calculate the total across all types
    totals.total = Object.values(totals).reduce((acc, curr) => acc + curr, 0);

    // Create a separate object for the totals
    const totalsObj = {
        material: 'Totals',
        ...totals
    };

    // Calculate percentages based on the total amount
    const percentagesObj = {
        material: 'Percentages'
    };

    Object.keys(totals).forEach(key => {
        if (key !== 'total') {
            const percentage = ((totals[key] / totals.total) * 100).toFixed(2);
            percentagesObj[key] = percentage;
        } else {
            percentagesObj[key] = '100';
        }
    });

    // Push the totals object and percentages object into the result array
    result.push(totalsObj);
    result.push(percentagesObj);

    return result;

};