import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import DatePeriod from '../../shared/DatePeriod';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { useSelector } from 'react-redux';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';

function AddAssessmentHSImpacts(props) {
    const { openAddAssessmentOfHSImpacts, setOpenAddAssessmentOfHSImpacts, getAllAssessmentOfHSImpactsData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [category, setCategory] = useState("");
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openAddAssessmentOfHSImpacts.action && openAddAssessmentOfHSImpacts.assessmentOfHSImpactsTableData) {
            setCategory(openAddAssessmentOfHSImpacts.assessmentOfHSImpactsTableData.category);
            reset(openAddAssessmentOfHSImpacts.assessmentOfHSImpactsTableData);
        }
    }, [openAddAssessmentOfHSImpacts.action === true]);

    const selectCategory = (event) => {
        setCategory(event.target.value);
    };

    const handleClose = () => {
        reset({ "number_of_product_service": "", "health_and_safety_impacts_conducted": "", })
        setCategory("");
        setOpenAddAssessmentOfHSImpacts({ action: false, assessmentOfHSImpactsTableData: null });
    };

    const submitProductOrServiceHSImpacts = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "category": data.category,
                "number_of_product_service": data.number_of_product_service,
                "health_and_safety_impacts_conducted": data.health_and_safety_impacts_conducted,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllAssessmentOfHSImpactsData(getFilterDate, payloadData);
                    reset({ "number_of_product_service": "", "health_and_safety_impacts_conducted": "", })
                    setCategory("");
                    setOpenAddAssessmentOfHSImpacts({ action: false, assessmentOfHSImpactsTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateProductOrServiceHSImpacts = async (data) => {
        const refNo = openAddAssessmentOfHSImpacts.assessmentOfHSImpactsTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "category": data.category,
            "number_of_product_service": data.number_of_product_service,
            "health_and_safety_impacts_conducted": data.health_and_safety_impacts_conducted,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllAssessmentOfHSImpactsData(getFilterDate, payloadData);
                reset({ "number_of_product_service": "", "health_and_safety_impacts_conducted": "", })
                setCategory("");
                setOpenAddAssessmentOfHSImpacts({ action: false, assessmentOfHSImpactsTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAddAssessmentOfHSImpacts.assessmentOfHSImpactsTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ "number_of_product_service": "", "health_and_safety_impacts_conducted": "", })
                setCategory("");
                getAllAssessmentOfHSImpactsData(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddAssessmentOfHSImpacts({ action: false, assessmentOfHSImpactsTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddAssessmentOfHSImpacts.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddAssessmentOfHSImpacts.assessmentOfHSImpactsTableData ? "Edit Assessment of H&S Impacts" : "Add Assessment of H&S Impacts"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddAssessmentOfHSImpacts.assessmentOfHSImpactsTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Assessment of H&S Impacts</material.Typography>
                            </div>
                            <div className='col-4'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Category</material.InputLabel>
                                    <material.Select
                                        {...register("category", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={category}
                                        onChange={selectCategory}
                                        label="Material Sourced"
                                    >
                                        <material.MenuItem value={"Product"}>Product</material.MenuItem>
                                        <material.MenuItem value={"Service Categories"}>Service Categories</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("number_of_product_service", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number of Product/Service"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.number_of_product_service && errors.number_of_product_service.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("health_and_safety_impacts_conducted", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Health & Safety Impacts for Product/Service"
                                    // label="Health & Safety Impacts Conducted for Product/Service"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.health_and_safety_impacts_conducted && errors.health_and_safety_impacts_conducted.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddAssessmentOfHSImpacts.assessmentOfHSImpactsTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateProductOrServiceHSImpacts)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddAssessmentOfHSImpacts.assessmentOfHSImpactsTableData.rejection_note === null) || (openAddAssessmentOfHSImpacts.assessmentOfHSImpactsTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(submitProductOrServiceHSImpacts)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddAssessmentHSImpacts;