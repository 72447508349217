
import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { saveOrUpdateSensitiveSpeciesAffectedData } from '../../services/sub-issue-services/biodiversity-services/SensitiveSpeciesAffectedService';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddsensitiveSpeciesAffected(props) {
    const { addOpenSensitiveSpeciesAffected, setAddOpenSensitiveSpeciesAffected, getAllSensitiveSpeciesAffectedData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [iucnRedListSpeciesCategory, setIucnRedListSpeciesCategory] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (addOpenSensitiveSpeciesAffected.action && addOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData) {
            setIucnRedListSpeciesCategory(addOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData.iucn_red_list_species_category);
            reset(addOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData);
        }
    }, [addOpenSensitiveSpeciesAffected.action === true]);

    const selectIucnRedListSpeciesCategory = (event) => {
        setIucnRedListSpeciesCategory(event.target.value);
    };

    const handleClose = () => {
        reset({ number: "", name: "", });
        setIucnRedListSpeciesCategory("");
        setAddOpenSensitiveSpeciesAffected({ action: false, sensitiveSpeciesAffectedTableData: null });
    };

    const saveSensitiveSpeciesAffected = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "iucn_red_list_species_category": data.iucn_red_list_species_category,
                "number": data.number,
                "name": data.name,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(object, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllSensitiveSpeciesAffectedData(getFilterDate, paginationData);
                    reset({ number: "", name: "", });
                    setIucnRedListSpeciesCategory("");
                    setAddOpenSensitiveSpeciesAffected({ action: false, sensitiveSpeciesAffectedTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        }
    };

    const updateSensitiveSpeciesAffected = async (data) => {
        const refNo = addOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "iucn_red_list_species_category": data.iucn_red_list_species_category,
            "number": data.number,
            "name": data.name,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllSensitiveSpeciesAffectedData(getFilterDate, paginationData);
                reset({ number: "", name: "", });
                setIucnRedListSpeciesCategory("");
                setAddOpenSensitiveSpeciesAffected({ action: false, sensitiveSpeciesAffectedTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };



    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: addOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllSensitiveSpeciesAffectedData(getFilterDate, paginationData);
                reset({ number: "", name: "", });
                setIucnRedListSpeciesCategory("");
                setOpenDeleteRecordConfirmationDialog(false);
                setAddOpenSensitiveSpeciesAffected({ action: false, sensitiveSpeciesAffectedTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={addOpenSensitiveSpeciesAffected.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{addOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData ? "Edit Sensitive Species Affected" : "Add Sensitive Species Affected"}</material.DialogTitle>
                <material.DialogContent>
                    {addOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Sensitive Species Affected</material.Typography>
                            </div>
                            <div className='col-lg-6 py-1'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>IUCN Red List Species Category</material.InputLabel>
                                    <material.Select
                                        {...register("iucn_red_list_species_category", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={iucnRedListSpeciesCategory}
                                        onChange={selectIucnRedListSpeciesCategory}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Critically Endangered"}>Critically Endangered</material.MenuItem>
                                        <material.MenuItem value={"Endangered Species"}>Endangered Species</material.MenuItem>
                                        <material.MenuItem value={"Near Threatened Species"}>Near Threatened Species</material.MenuItem>
                                        <material.MenuItem value={"Vulnerable Species"}>Vulnerable Species</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-6 py-1'>
                                <material.TextField
                                    {...register("number", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    // className='mt-3'
                                    margin="dense"
                                    label="Number"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.number && errors.number.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-lg-6 py-1'>
                                <material.TextField
                                    {...register("name", { required: true, })}
                                    required
                                    // className='mt-3'
                                    margin="dense"
                                    label="Name"
                                    id="userName"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                />
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateSensitiveSpeciesAffected)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData.rejection_note === null) || (addOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveSensitiveSpeciesAffected)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddsensitiveSpeciesAffected;
