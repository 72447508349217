import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import Snackbar from '../../toaster-message/Snackbar';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { saveOrUpdateEmployeeHiringData } from '../../services/sub-issue-services/EmployeeHiringService';
import { checkCriteriaForYesTypeAnswer } from '../../shared/Shared'
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddEmployeeHiring(props) {
    const { openAddEmployeeHiring, setOpenAddEmployeeHiring, getAllEmployeeHiringData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, watch, control, setValue, getValues, formState: { errors }, } = useForm({ mode: "onTouched" });
    // const [ageBasedDataChange, setAgeBasedDataChange] = useState("No");
    const [isEmployeeAgeBasedDataPresent, setIsEmployeeAgeBasedDataPresent] = useState("No");
    const [categoryOfWorker, setCategoryOfWorker] = useState('');
    const [category, setSelectCategory] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const payload = {
        page,
        size: rowsPerPage
    };

    const onChangeMale = watch('male');
    const onChangeFemale = watch('female');
    const onChangeOther = watch('other');

    useEffect(() => {
        totalCalculation();
    }, [onChangeMale, onChangeFemale, onChangeOther]);

    const totalCalculation = () => {
        let val1 = getValues('male');
        let val2 = getValues('female');
        let val3 = getValues('other');
        if (val1 && val2 && val3) {
            let totalValue = Number(val1) + Number(val2) + Number(val3);
            setValue("total", totalValue);
        };
    };

    useEffect(() => {
        if (openAddEmployeeHiring.action && openAddEmployeeHiring.employeeHiringTableData) {
            setCategoryOfWorker(openAddEmployeeHiring.employeeHiringTableData.category_of_worker);
            setIsEmployeeAgeBasedDataPresent(openAddEmployeeHiring.employeeHiringTableData.is_age_based_data_present ? "Yes" : "No");
            setSelectCategory(openAddEmployeeHiring.employeeHiringTableData.category)
            reset(openAddEmployeeHiring.employeeHiringTableData);
        }
    }, [openAddEmployeeHiring.action === true]);

    const categoryOfWorkerChange = (event) => {
        setCategoryOfWorker(event.target.value);
    };

    const handleChangeCategory = (event) => {
        setSelectCategory(event.target.value)
    };

    const ageBasedData = (event) => {
        setIsEmployeeAgeBasedDataPresent(event.target.value)
    };

    const handleClose = () => {
        reset({ male: "", female: "", other: "", total: "", male_under_30: "", male_30_to_50: "", male_above_50: "", female_under_30: "", female_30_to_50: "", female_above_50: "", vulnerable: "", other_under_30: "", other_30_to_50: "", other_above_50: "" });
        setCategoryOfWorker("");
        setSelectCategory("");
        setOpenAddEmployeeHiring({ action: false, employeeHiringTableData: null })
    };

    // const postSaveUpdateEmployeeHiringData = (obj) => {
    //     saveOrUpdateEmployeeHiringData(obj)
    //         .then((resp) => {
    //             setOpenSnackBar({
    //                 "action": true,
    //                 "type": "success",
    //                 "message": resp.data,
    //             })
    //             getAllEmployeeHiringData();
    //             reset({ male: "", female: "", other: "", total: "", maleUnder30: "", male30To50: "", maleAbove50: "", femaleUnder30: "", female30To50: "", femaleAbove50: "", vulnerable: "", otherUnder30: "", other30To50: "", otherAbove50: "" });
    //             setCategoryOfWorker("");
    //             setOpenAddEmployeeHiring({ action: false, employeeHiringTableData: null })
    //         })
    //         .catch((error) => {
    //             if (error.response.status === 401) {
    //                 setOpenSnackBar({
    //                     "action": true,
    //                     "type": "error",
    //                     "message": "You are not authorized to perform this action. Redirected to login",
    //                 })
    //             } else {
    //                 setOpenSnackBar({
    //                     "action": true,
    //                     "type": "error",
    //                     "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
    //                 })
    //             };
    //         })
    // };

    const saveEmployeeHiring = async (data) => {
        // let typeOfRecord = "New_Hiring_Turnover"
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "type_of_record": "NEW_HIRING_TURNOVER",
                "category_of_worker": data.category_of_worker,
                "category": data.category,
                "male": Number(data.male),
                "female": Number(data.female),
                "other": Number(data.other),
                "total": Number(data.total),
                "male_under_30": Number(data.male_under_30 ? data.male_under_30 : 0),
                "male_30_to_50": Number(data.male_30_to_50 ? data.male_30_to_50 : 0),
                "male_above_50": Number(data.male_above_50 ? data.male_above_50 : 0),
                "female_under_30": Number(data.female_under_30 ? data.female_under_30 : 0),
                "female_30_to_50": Number(data.female_30_to_50 ? data.female_30_to_50 : 0),
                "female_above_50": Number(data.female_above_50 ? data.female_above_50 : 0),
                "other_under_30": Number(data.other_under_30 ? data.other_under_30 : 0),
                "other_30_to_50": Number(data.other_30_to_50 ? data.other_30_to_50 : 0),
                "other_above_50": Number(data.other_above_50 ? data.other_above_50 : 0),
                "vulnerable": Number(data.vulnerable),
                "is_age_based_data_present": isEmployeeAgeBasedDataPresent === "Yes" ? true : false,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            };
            if (isEmployeeAgeBasedDataPresent === "Yes") {
                if (checkCriteriaForYesTypeAnswer(data)) {
                    if (Number(data.total) >= Number(data.vulnerable)) {
                        await saveSubIssueData(obj, subissueName)
                            .then((resp) => {
                                setOpenSnackBar({
                                    "action": true,
                                    "type": "success",
                                    "message": resp.data,
                                })
                                getAllEmployeeHiringData(getFilterDate, payload);
                                setCategoryOfWorker("");
                                setSelectCategory("");
                                reset({ male: "", female: "", other: "", total: "", male_under_30: "", male_30_to_50: "", male_above_50: "", female_under_30: "", female_30_to_50: "", female_above_50: "", vulnerable: "", other_under_30: "", other_30_to_50: "", other_above_50: "" })
                                setOpenAddEmployeeHiring({ action: false, employeeHiringTableData: null });
                            })
                            .catch((error) => {
                                if (error.response?.status === 401) {
                                    setOpenSnackBar({
                                        "action": true,
                                        "type": "error",
                                        "message": "You are not authorized to perform this action. Redirected to login",
                                    })
                                } else {
                                    setOpenSnackBar({
                                        "action": true,
                                        "type": "error",
                                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                                    })
                                };
                            })
                    }
                    else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "The total number of Vulnerable count cannot be greater than the total number of Total Derived count",
                        })
                    }
                }
                else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "Sum of age based count not matching with total count",
                    })
                };
            } else if (isEmployeeAgeBasedDataPresent === "No") {
                if (Number(data.total) >= Number(data.vulnerable)) {
                    await saveSubIssueData(obj, subissueName)
                        .then((resp) => {
                            setOpenSnackBar({
                                "action": true,
                                "type": "success",
                                "message": resp.data,
                            })
                            getAllEmployeeHiringData(getFilterDate, payload);
                            setCategoryOfWorker("");
                            setSelectCategory("");
                            reset({ male: "", female: "", other: "", total: "", male_under_30: "", male_30_to_50: "", male_above_50: "", female_under_30: "", female_30_to_50: "", female_above_50: "", vulnerable: "", other_under_30: "", other_30_to_50: "", other_above_50: "" });
                            setOpenAddEmployeeHiring({ action: false, employeeHiringTableData: null });
                        })
                        .catch((error) => {
                            if (error.response.status === 401) {
                                setOpenSnackBar({
                                    "action": true,
                                    "type": "error",
                                    "message": "You are not authorized to perform this action. Redirected to login",
                                })
                            } else {
                                setOpenSnackBar({
                                    "action": true,
                                    "type": "error",
                                    "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                                })
                            };
                        })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "The total number of Vulnerable count cannot be greater than the total number of Total Derived count",
                    })
                }
            };
        }
        else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateEmployeeHiring = async (data) => {
        const refNo = openAddEmployeeHiring.employeeHiringTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "type_of_record": "NEW_HIRING_TURNOVER",
            "category_of_worker": data.category_of_worker,
            "category": data.category,
            "male": Number(data.male),
            "female": Number(data.female),
            "other": Number(data.other),
            "total": Number(data.total),
            "male_under_30": Number(data.male_under_30),
            "male_30_to_50": Number(data.male_30_to_50),
            "male_above_50": Number(data.male_above_50),
            "female_under_30": Number(data.female_under_30),
            "female_30_to_50": Number(data.female_30_to_50),
            "female_above_50": Number(data.female_above_50),
            "other_under_30": Number(data.other_under_30),
            "other_30_to_50": Number(data.other_30_to_50),
            "other_above_50": Number(data.other_above_50),
            "vulnerable": Number(data.vulnerable),
            "is_age_based_data_present": isEmployeeAgeBasedDataPresent === "Yes" ? true : false,
        };
        if (isEmployeeAgeBasedDataPresent === "Yes") {
            if (checkCriteriaForYesTypeAnswer(data)) {
                if (Number(data.total) >= Number(data.vulnerable)) {
                    await updateSubIssueData(obj, refNo, subIssueName)
                        .then((resp) => {
                            setOpenSnackBar({
                                "action": true,
                                "type": "success",
                                "message": resp.data,
                            })
                            getAllEmployeeHiringData(getFilterDate, payload);
                            setCategoryOfWorker("")
                            reset({ male: "", female: "", other: "", total: "", male_under_30: "", male_30_to_50: "", male_above_50: "", female_under_30: "", female_30_to_50: "", female_above_50: "", vulnerable: "", other_under_30: "", other_30_to_50: "", other_above_50: "" });
                            setOpenAddEmployeeHiring({ action: false, employeeHiringTableData: null });
                        })
                        .catch((error) => {
                            if (error.response.status === 401) {
                                setOpenSnackBar({
                                    "action": true,
                                    "type": "error",
                                    "message": "You are not authorized to perform this action. Redirected to login",
                                })
                            } else {
                                setOpenSnackBar({
                                    "action": true,
                                    "type": "error",
                                    "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                                })
                            };
                        })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "The total number of Vulnerable count cannot be greater than the total number of Total Derived count",
                    })
                }
            }
            else {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "Sum of age based count not matching with total count",
                })
            };
        } else if (isEmployeeAgeBasedDataPresent === "No") {
            if (Number(data.total) >= Number(data.vulnerable)) {
                // await saveOrUpdateEmployeeAndWorker(obj, "EMPLOYEE_AND_WORKER")
                await updateSubIssueData(obj, refNo, subIssueName)
                    .then((resp) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "success",
                            "message": resp.data,
                        })
                        getAllEmployeeHiringData(getFilterDate, payload);
                        setCategoryOfWorker("")
                        reset({ male: "", female: "", other: "", total: "", male_under_30: "", male_30_to_50: "", male_above_50: "", female_under_30: "", female_30_to_50: "", female_above_50: "", vulnerable: "", other_under_30: "", other_30_to_50: "", other_above_50: "" });
                        setOpenAddEmployeeHiring({ action: false, employeeHiringTableData: null });
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": "You are not authorized to perform this action. Redirected to login",
                            })
                        } else {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                            })
                        };
                    })
            } else {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "The total number of Vulnerable count cannot be greater than the total number of Total Derived count",
                })
            }
        }
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAddEmployeeHiring.employeeHiringTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllEmployeeHiringData(getFilterDate, payload);
                setCategoryOfWorker("")
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ male: "", female: "", other: "", total: "", male_under_30: "", male_30_to_50: "", male_above_50: "", female_under_30: "", female_30_to_50: "", female_above_50: "", vulnerable: "", other_under_30: "", other_30_to_50: "", other_above_50: "" });
                setOpenAddEmployeeHiring({ action: false, employeeHiringTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <>
            <material.Dialog open={openAddEmployeeHiring.action} maxWidth="lg" fullWidth >
                <material.DialogTitle id="alert-dialog-title" className='fw-bold headingText'>
                    {openAddEmployeeHiring.employeeHiringTableData ? "Edit Employee New Hiring & Turnover" : "Add Employee New Hiring & Turnover"}
                </material.DialogTitle>
                <material.DialogContent>
                    <material.DialogContentText id="alert-dialog-description">
                        <div className='row'>
                            <div className='col-12 d-flex flex-row align-items-center'>
                                <div className='me-2 fw-bold text-dark'>Is employee age based data present? :</div>
                                <material.FormControl>
                                    <material.RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={isEmployeeAgeBasedDataPresent}
                                        onChange={ageBasedData}
                                    >
                                        <material.FormControlLabel value="Yes" control={<material.Radio color='secondary' />} label="Yes" />
                                        <material.FormControlLabel value="No" control={<material.Radio color='secondary' />} label="No" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </div>
                        </div>
                        <div className='my-3'>
                            {openAddEmployeeHiring.employeeHiringTableData ? null : (
                                <DatePeriod
                                    register={register}
                                    control={control}
                                />
                            )}
                        </div>
                        <material.Card elevation={1} sx={{ p: 2, mt: 1, border: 1 }}>
                            <div className='row'>
                                <div className='py-1'>
                                    <material.Typography><h5 className='fw-bold headingText'>Employee & worker</h5></material.Typography>
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.FormControl variant="standard" className='mt-3' fullWidth>
                                        <material.InputLabel id="demo-simple-select-standard-label" >Employee & worker</material.InputLabel>
                                        <material.Select
                                            {...register("category_of_worker", { required: true, })}
                                            required
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={categoryOfWorker}
                                            onChange={categoryOfWorkerChange}
                                        >
                                            <material.MenuItem value={"Employee Permanent"}>Employee Permanent</material.MenuItem>
                                            <material.MenuItem value={"Employee other than Permanent"}>Employee other than Permanent</material.MenuItem>
                                            <material.MenuItem value={"Worker Permanent"}>Worker Permanent</material.MenuItem>
                                            <material.MenuItem value={"Worker other than permanent"}>Worker other than Permanent</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.FormControl variant="standard" className='mt-3' fullWidth required>
                                        <material.InputLabel id="demo-simple-select-standard-label" >Category</material.InputLabel>
                                        <material.Select
                                            {...register("category", { required: true, })}
                                            id="demo-simple-select-standard"
                                            value={category}
                                            onChange={handleChangeCategory}
                                        >
                                            <material.MenuItem value={"Turnover"}>Turnover</material.MenuItem>
                                            <material.MenuItem value={"New Hiring"}>New Hiring</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("male", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        className='mt-3'
                                        label="Male"
                                        id="userName"
                                        variant="standard"
                                        type='number'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.male && errors.male.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed.</p>
                                    )}
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("female", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        className='mt-3'
                                        label="Female"
                                        id="userName"
                                        variant="standard"
                                        type='number'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.female && errors.female.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed.</p>
                                    )}
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("other", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        className='mt-3'
                                        label="Other"
                                        id="userName"
                                        variant="standard"
                                        type='number'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.other && errors.other.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed.</p>
                                    )}
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("total", { required: true, })}
                                        required
                                        className='mt-3'
                                        label="Total"
                                        id="userName"
                                        variant="standard"
                                        type='number'
                                        size="small"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ readOnly: true }}
                                    />
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("vulnerable", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        className='mt-3'
                                        label="Vulnerable"
                                        id="userName"
                                        variant="standard"
                                        type='number'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.vulnerable && errors.vulnerable.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed.</p>
                                    )}
                                </div>
                            </div>
                        </material.Card>
                        {isEmployeeAgeBasedDataPresent === "Yes" ? (
                            <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                                <div className='row'>
                                    <div className='py-1'>
                                        <material.Typography><h5 className='fw-bold headingText'>Employee age based data</h5></material.Typography>
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("male_under_30", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            className='mt-3'
                                            label="Male under 30"
                                            id="userName"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.male_under_30 && errors.male_under_30.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed.</p>
                                        )}
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("male_30_to_50", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            className='mt-3'
                                            label="Male 30 to 50"
                                            id="userName"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.male_30_to_50 && errors.male_30_to_50.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed.</p>
                                        )}
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("male_above_50", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            className='mt-3'
                                            label="Male above 50"
                                            id="userName"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.male_above_50 && errors.male_above_50.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed.</p>
                                        )}
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("female_under_30", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            className='mt-3'
                                            label="Female under 30"
                                            id="userName"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.female_under_30 && errors.female_under_30.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed.</p>
                                        )}
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("female_30_to_50", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            className='mt-3'
                                            label="Female 30 to 50"
                                            id="userName"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.female_30_to_50 && errors.female_30_to_50.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed.</p>
                                        )}
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("female_above_50", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            className='mt-3'
                                            label="Female above 50"
                                            id="userName"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.female_above_50 && errors.female_above_50.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed.</p>
                                        )}
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("other_under_30", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            margin="dense"
                                            label="Other Under 30"
                                            id="femaleUnder30"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.other_under_30 && errors.other_under_30.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed</p>
                                        )}
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("other_30_to_50", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            margin="dense"
                                            label="Other 30 to 50"
                                            id="other_30_to_50"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.other_30_to_50 && errors.other_30_to_50.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed</p>
                                        )}
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("other_above_50", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            margin="dense"
                                            label="Other Above 50"
                                            id="other_above_50"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.other_above_50 && errors.other_above_50.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed</p>
                                        )}
                                    </div>
                                </div>
                            </material.Card>
                        ) : null}
                    </material.DialogContentText>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddEmployeeHiring.employeeHiringTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateEmployeeHiring)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddEmployeeHiring.employeeHiringTableData.rejection_note === null) || (openAddEmployeeHiring.employeeHiringTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveEmployeeHiring)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </>
    )
}

export default AddEmployeeHiring;