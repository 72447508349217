import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getTotalEmployeeLinkData, saveOrUpdatePerformanceAppraisalData } from '../../services/sub-issue-services/performance-appraisal-service/PerformanceAppraisalService';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddPerformanceApprisal(props) {
    const { addOpenPerformanceApprisal, setAddOpenPerformanceApprisal, getAllPerformanceAppraisalData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, setValue, watch, getValues, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [categoryOfEmployees, setCategoryOfEmployees] = useState("");
    const [gender, setGender] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    // const onChangeTotalEmployees = watch('totalEmployees');
    // const onChangeNumberOfEmployeesPerformanceReviewed = watch('numberOfEmployeesPerformanceReviewed');

    // useEffect(() => {
    //     totalCalculation();
    // }, [onChangeTotalEmployees, onChangeNumberOfEmployeesPerformanceReviewed]);

    // const totalCalculation = () => {
    //     let totalEmployeesValue = getValues('totalEmployees');
    //     let numberOfEmployeesPerformanceReviewedValue = getValues('numberOfEmployeesPerformanceReviewed');

    //     if (Number(numberOfEmployeesPerformanceReviewedValue) > Number(totalEmployeesValue)) {
    //         setOpenSnackBar({
    //             "action": true,
    //             "type": "warning",
    //             "message": "Number of employee performance reviewed value not greater than total number of employee value",
    //         })
    //         setButtonDisable(true)
    //     } else {
    //         setButtonDisable(false)
    //     }
    // };

    useEffect(() => {
        if (addOpenPerformanceApprisal.action && addOpenPerformanceApprisal.performanceApprisalTableData) {
            setCategoryOfEmployees(addOpenPerformanceApprisal.performanceApprisalTableData.category_of_employees);
            setGender(addOpenPerformanceApprisal.performanceApprisalTableData.gender);
            reset(addOpenPerformanceApprisal.performanceApprisalTableData);
        }
    }, [addOpenPerformanceApprisal.action === true]);

    const handleClose = () => {
        reset({ number_of_employees_performance_reviewed: "", });
        setCategoryOfEmployees("");
        setGender("");
        setAddOpenPerformanceApprisal({ action: false, performanceApprisalTableData: null });
    };

    const savePerformanceApprisal = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "category_of_employees": data.category_of_employees,
                "gender": data.gender,
                "number_of_employees_performance_reviewed": data.number_of_employees_performance_reviewed,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(object, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllPerformanceAppraisalData(getFilterDate, payloadData);
                    reset({ number_of_employees_performance_reviewed: "", });
                    setCategoryOfEmployees("");
                    setGender("");
                    setAddOpenPerformanceApprisal({ action: false, performanceApprisalTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updatePerformanceApprisal = async (data) => {
        const refNo = addOpenPerformanceApprisal.performanceApprisalTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let object = {
            "category_of_employees": data.category_of_employees,
            "gender": data.gender,
            "number_of_employees_performance_reviewed": data.number_of_employees_performance_reviewed,
        }
        await updateSubIssueData(object, refNo, subIssueName)
            .then((resp) => {
                getAllPerformanceAppraisalData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllPerformanceAppraisalData(getFilterDate, payloadData);
                reset({ number_of_employees_performance_reviewed: "", });
                setCategoryOfEmployees("");
                setGender("");
                setAddOpenPerformanceApprisal({ action: false, performanceApprisalTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const selectCategoryOfEmployees = (event) => {
        setCategoryOfEmployees(event.target.value);
    };

    const selectGender = (event) => {
        setGender(event.target.value);
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: addOpenPerformanceApprisal.performanceApprisalTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllPerformanceAppraisalData(getFilterDate, payloadData);
                reset({ number_of_employees_performance_reviewed: "", });
                setCategoryOfEmployees("");
                setGender("");
                setOpenDeleteRecordConfirmationDialog(false);
                setAddOpenPerformanceApprisal({ action: false, performanceApprisalTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={addOpenPerformanceApprisal.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{addOpenPerformanceApprisal.performanceApprisalTableData ? "Edit Performance Appraisal" : "Add Performance Appraisal"}</material.DialogTitle>
                <material.DialogContent>
                    <div className='my-3'>
                        {addOpenPerformanceApprisal.performanceApprisalTableData ? null : (
                            <DatePeriod
                                register={register}
                                control={control}
                            />
                        )}
                    </div>
                    <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Performance Appraisal</material.Typography>
                            </div>
                            <div className='col-6'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Category of Employee & Worker</material.InputLabel>
                                    <material.Select
                                        {...register("category_of_employees", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={categoryOfEmployees}
                                        onChange={selectCategoryOfEmployees}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Employee Permanent"}>Employee Permanent</material.MenuItem>
                                        <material.MenuItem value={"Employee other than Permanent"}>Employee other than Permanent</material.MenuItem>
                                        <material.MenuItem value={"Worker Permanent"}>Worker Permanent</material.MenuItem>
                                        <material.MenuItem value={"Worker other than Permanent"}>Worker other than Permanent</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Gender</material.InputLabel>
                                    <material.Select
                                        {...register("gender", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={gender}
                                        onChange={selectGender}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Male"}>Male</material.MenuItem>
                                        <material.MenuItem value={"Female"}>Female</material.MenuItem>
                                        <material.MenuItem value={"Other"}>Other</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6'>
                                <material.TextField
                                    {...register("number_of_employees_performance_reviewed", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    className='mt-3'
                                    margin="dense"
                                    label="Number of Employee Performance Reviewed"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.number_of_employees_performance_reviewed && errors.number_of_employees_performance_reviewed.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenPerformanceApprisal.performanceApprisalTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updatePerformanceApprisal)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenPerformanceApprisal.performanceApprisalTableData.rejection_note === null) || (addOpenPerformanceApprisal.performanceApprisalTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(savePerformanceApprisal)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddPerformanceApprisal;