import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';

function AddWaterTreatmentReuse(props) {
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, watch, control, setValue, getValues, formState: { errors }, } = useForm({ mode: "onTouched" });
    const { openWasteWaterTreatmentReuse, setOpenWasteWaterTreatmentReuse, getAllWasteWaterTreatmentReuse, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const [recycleOrReuseKLD, setRecycleOrReuseKLD] = useState("No");
    const [levelOfTreatment, setLevelOfTreatment] = useState("");
    const [category, setCategory] = useState("");
    const [purposeOfReuseRecycle, setPurposeOfReuseRecycle] = useState("");
    const [dischargeDestinationType, setDischargeDestinationType] = useState("");
    const [destinationCategory, setDestinationCategory] = useState("");
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const payload = {
        page,
        size: rowsPerPage
    };

    const selectRecycleOrReuseKLD = (event) => {
        setRecycleOrReuseKLD(event.target.value);
    };

    const selectLevelOfTreatment = (event) => {
        setLevelOfTreatment(event.target.value);
    };

    const selectCategory = (event) => {
        setCategory(event.target.value);
    };

    const selectPurposeOfReuseRecycle = (event) => {
        setPurposeOfReuseRecycle(event.target.value);
    };

    const selectDischargeDestinationType = (event) => {
        setDischargeDestinationType(event.target.value);
    };

    const selectDestinationCategory = (event) => {
        setDestinationCategory(event.target.value);
    };

    const dischargePoint = (event) => {
        const value = event.target.value;
        setValue("discharge_point", value);
    };

    useEffect(() => {
        if (openWasteWaterTreatmentReuse.action && openWasteWaterTreatmentReuse.wasteWaterTreatmentReuseTableData) {
            setLevelOfTreatment(openWasteWaterTreatmentReuse.wasteWaterTreatmentReuseTableData.level_of_treatment);
            setCategory(openWasteWaterTreatmentReuse.wasteWaterTreatmentReuseTableData.category);
            setPurposeOfReuseRecycle(openWasteWaterTreatmentReuse.wasteWaterTreatmentReuseTableData.purpose_of_reuse_or_recycle);
            setDischargeDestinationType(openWasteWaterTreatmentReuse.wasteWaterTreatmentReuseTableData.discharge_destination_type);
            setDestinationCategory(openWasteWaterTreatmentReuse.wasteWaterTreatmentReuseTableData.destination_category);
            setRecycleOrReuseKLD(openWasteWaterTreatmentReuse.wasteWaterTreatmentReuseTableData.does_the_plant_reuse_or_recycle_part_of_its_wastewater ? "Yes" : "No");
            reset(openWasteWaterTreatmentReuse.wasteWaterTreatmentReuseTableData);
        };
    }, [openWasteWaterTreatmentReuse.action === true]);

    const handleClose = () => {
        setLevelOfTreatment("");
        setCategory("");
        setPurposeOfReuseRecycle("");
        setDischargeDestinationType("");
        setDestinationCategory("");
        reset({ output_source: "", quantity_reused_or_recycled: "", final_discharge: "", discharge_point: "", discharge_location_lat: "", discharge_location_long: "", discharge_destination: "", });
        setOpenWasteWaterTreatmentReuse({ action: false, wasteWaterTreatmentReuseTableData: null });
    };

    const addWasteWaterTreatmentReuse = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = "Water Discharge";
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "output_source": data.output_source,
                "level_of_treatment": data.level_of_treatment,
                "category": data.category,
                "quantity_reused_or_recycled": data.quantity_reused_or_recycled,
                "purpose_of_reuse_or_recycle": data.purpose_of_reuse_or_recycle,
                "final_discharge": data.final_discharge,
                "discharge_point": data.discharge_point,
                "discharge_location_lat": data.discharge_location_lat,
                "discharge_location_long": data.discharge_location_long,
                "discharge_destination": data.discharge_destination,
                "discharge_destination_type": data.discharge_destination_type,
                "destination_category": data.destination_category,
                "does_the_plant_reuse_or_recycle_part_of_its_wastewater": recycleOrReuseKLD === "Yes" ? true : false,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
                "type_of_record": "Waste_Water_Treatment_Reuse",
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllWasteWaterTreatmentReuse(getFilterDate, payload);
                    setRecycleOrReuseKLD("");
                    setLevelOfTreatment("");
                    setCategory("");
                    setPurposeOfReuseRecycle("");
                    setDischargeDestinationType("");
                    setDestinationCategory("");
                    reset({ output_source: "", quantity_reused_or_recycled: "", final_discharge: "", discharge_point: "", discharge_location_lat: "", discharge_location_long: "", discharge_destination: "", });
                    setOpenWasteWaterTreatmentReuse({ action: false, wasteWaterTreatmentReuseTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        }
    };

    const updateWasteWaterTreatmentReuse = async (data) => {
        const refNo = openWasteWaterTreatmentReuse.wasteWaterTreatmentReuseTableData.ref_no;
        let subIssueName = "Water Discharge";
        let obj = {
            "output_source": data.output_source,
            "level_of_treatment": data.level_of_treatment,
            "category": recycleOrReuseKLD === "Yes" ? data.category : "",
            "quantity_reused_or_recycled": recycleOrReuseKLD === "Yes" ? data.quantity_reused_or_recycled : "",
            "purpose_of_reuse_or_recycle": recycleOrReuseKLD === "Yes" ? data.purpose_of_reuse_or_recycle : "",
            "final_discharge": data.final_discharge,
            "discharge_point": data.discharge_point,
            "discharge_location_lat": data.discharge_location_lat,
            "discharge_location_long": data.discharge_location_long,
            "discharge_destination": data.discharge_destination,
            "discharge_destination_type": data.discharge_destination_type,
            "destination_category": data.destination_category,
            "does_the_plant_reuse_or_recycle_part_of_its_wastewater": recycleOrReuseKLD === "Yes" ? true : false,
            "type_of_record": "Waste_Water_Treatment_Reuse",
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllWasteWaterTreatmentReuse(getFilterDate, payload);
                setRecycleOrReuseKLD("");
                setLevelOfTreatment("");
                setCategory("");
                setPurposeOfReuseRecycle("");
                setDischargeDestinationType("");
                setDestinationCategory("");
                reset({ output_source: "", quantity_reused_or_recycled: "", final_discharge: "", discharge_point: "", discharge_location_lat: "", discharge_location_long: "", discharge_destination: "", });
                setOpenWasteWaterTreatmentReuse({ action: false, wasteWaterTreatmentReuseTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openWasteWaterTreatmentReuse.wasteWaterTreatmentReuseTableData.ref_no,
            subissueName: "Water Discharge",
            "type_of_record": "Waste_Water_Treatment_Reuse",
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                getAllWasteWaterTreatmentReuse(getFilterDate, payload);
                setRecycleOrReuseKLD("");
                setLevelOfTreatment("");
                setCategory("");
                setPurposeOfReuseRecycle("");
                setDischargeDestinationType("");
                setDestinationCategory("");
                reset({ output_source: "", quantity_reused_or_recycled: "", final_discharge: "", discharge_point: "", discharge_location_lat: "", discharge_location_long: "", discharge_destination: "", });
                setOpenWasteWaterTreatmentReuse({ action: false, wasteWaterTreatmentReuseTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openWasteWaterTreatmentReuse.action} maxWidth="lg" fullWidth >
                <material.DialogTitle id="alert-dialog-title" className='fw-bold headingText'>
                    {openWasteWaterTreatmentReuse.wasteWaterTreatmentReuseTableData ? "Edit Waste Water Treatment Reuse" : "Add Waste Water Treatment Reuse"}
                </material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div className='col-12 d-flex flex-row align-items-center'>
                            <div className='me-2 fw-bold text-dark'>Does the plant reuse or recycle part of its wastewater ? :</div>
                            <material.FormControl>
                                <material.RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={recycleOrReuseKLD}
                                    onChange={selectRecycleOrReuseKLD}
                                >
                                    <material.FormControlLabel value="Yes" control={<material.Radio color='secondary' />} label="Yes" />
                                    <material.FormControlLabel value="No" control={<material.Radio color='secondary' />} label="No" />
                                </material.RadioGroup>
                            </material.FormControl>
                        </div>
                    </div>
                    {openWasteWaterTreatmentReuse.wasteWaterTreatmentReuseTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 1, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography><h5 className='fw-bold headingText'>Water Discharge Recycle or Reuse KLD</h5></material.Typography>
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("output_source", { required: true, })}
                                    required
                                    label="Output Source"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                            </div>
                            <div className='col-4'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label">Level of Treatment</material.InputLabel>
                                    <material.Select
                                        {...register("level_of_treatment", { required: true, })}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={levelOfTreatment}
                                        onChange={selectLevelOfTreatment}
                                        label="Level of Treatment"
                                    >
                                        <material.MenuItem value={"No treatment"}>No Treatment</material.MenuItem>
                                        <material.MenuItem value={"Primary Treatment"}>Primary Treatment</material.MenuItem>
                                        <material.MenuItem value={"Secondary Treatment"}>Secondary Treatment</material.MenuItem>
                                        <material.MenuItem value={"Tertiary Treatment"}>Tertiary Treatment</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {recycleOrReuseKLD === "Yes" ? (
                                <>
                                    <div className='col-4'>
                                        <material.FormControl variant="standard" fullWidth required>
                                            <material.InputLabel id="demo-simple-select-standard-label">Category</material.InputLabel>
                                            <material.Select
                                                {...register("category", { required: true, })}
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={category}
                                                onChange={selectCategory}
                                                label="Category"
                                            >
                                                <material.MenuItem value={"Reuse"}>Reuse</material.MenuItem>
                                                <material.MenuItem value={"Recycle"}>Recycle</material.MenuItem>
                                            </material.Select>
                                        </material.FormControl>
                                    </div>
                                    <div className='col-4 mt-2'>
                                        <material.TextField
                                            {...register("quantity_reused_or_recycled", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                            required
                                            label="Quantity Reused/Recycled (KL)"
                                            id="userName"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.quantity_reused_or_recycled && errors.quantity_reused_or_recycled.type === "pattern" && (
                                            <p className="errorMsg text-danger">Allowed only number & also 2 digit after decimal</p>
                                        )}
                                    </div>
                                    <div className='col-4 mt-2'>
                                        <material.FormControl variant="standard" fullWidth required>
                                            <material.InputLabel id="demo-simple-select-standard-label">Purpose of Reuse/Recycle</material.InputLabel>
                                            <material.Select
                                                {...register("purpose_of_reuse_or_recycle", { required: true, })}
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={purposeOfReuseRecycle}
                                                onChange={selectPurposeOfReuseRecycle}
                                                label="Category"
                                            >
                                                <material.MenuItem value={"Process"}>Process</material.MenuItem>
                                                <material.MenuItem value={"Cooling"}>Cooling</material.MenuItem>
                                                <material.MenuItem value={"Washing"}>Washing</material.MenuItem>
                                                <material.MenuItem value={"Plantation"}>Plantation</material.MenuItem>
                                                <material.MenuItem value={"Others"}>Others</material.MenuItem>
                                            </material.Select>
                                        </material.FormControl>
                                    </div>
                                </>
                            ) : null}
                            <div className='col-4 mt-2'>
                                <material.TextField
                                    {...register("final_discharge", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Final Discharge Quantity (KL)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.final_discharge && errors.final_discharge.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 2 digit after decimal</p>
                                )}
                            </div>
                            <div className='col-4 mt-2'>
                                <material.TextField
                                    {...register("discharge_point", { required: true, })}
                                    required
                                    label="Discharge Point"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    onChange={dischargePoint}
                                />
                                {/* {errors?.discharge_point?.type === "maxLength" && (
                                    <p className='text-danger'>Maximum length 20 character</p>
                                )} */}
                            </div>
                        </div>
                    </material.Card>
                    <material.Card elevation={1} sx={{ p: 2, mt: 1, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography><h5 className='fw-bold headingText'>Water Discharge Effluent Destination</h5></material.Typography>
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("discharge_point", { required: true, })}
                                    required
                                    label="Discharge Point"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("discharge_location_lat", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    required
                                    label="Discharge Location (Latitude)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.discharge_location_lat && errors.discharge_location_lat.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("discharge_location_long", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    required
                                    label="Discharge Location (Longitude)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.discharge_location_long && errors.discharge_location_long.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 mt-2'>
                                <material.TextField
                                    {...register("discharge_destination", { required: true, })}
                                    required
                                    label="Discharge Destination"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                {errors?.discharge_destination?.type === "maxLength" && (
                                    <p className='text-danger'>Maximum length 20 character</p>
                                )}
                            </div>
                            <div className='col-4 mt-2'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label">Discharge Destination Type</material.InputLabel>
                                    <material.Select
                                        {...register("discharge_destination_type", { required: true, })}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={dischargeDestinationType}
                                        onChange={selectDischargeDestinationType}
                                        label="Category"
                                    >
                                        <material.MenuItem value={"Surface Water"}>Surface Water</material.MenuItem>
                                        <material.MenuItem value={"Ground Water"}>Ground Water</material.MenuItem>
                                        <material.MenuItem value={"Sea Water"}>Sea Water</material.MenuItem>
                                        <material.MenuItem value={"Third Party"}>Third Party</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4 mt-2'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label">Destination Category</material.InputLabel>
                                    <material.Select
                                        {...register("destination_category", { required: true, })}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={destinationCategory}
                                        onChange={selectDestinationCategory}
                                        label="Category"
                                    >
                                        <material.MenuItem value={"Freshwater (≤1,000 mg/L TDS)"}>{"Freshwater (≤1,000 mg/L TDS)"}</material.MenuItem>
                                        <material.MenuItem value={"Other water (>1,000 mg/L TDS)"}>{"Other water (>1,000 mg/L TDS)"}</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openWasteWaterTreatmentReuse.wasteWaterTreatmentReuseTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateWasteWaterTreatmentReuse)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openWasteWaterTreatmentReuse.wasteWaterTreatmentReuseTableData.rejection_note === null) || (openWasteWaterTreatmentReuse.wasteWaterTreatmentReuseTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(addWasteWaterTreatmentReuse)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddWaterTreatmentReuse;