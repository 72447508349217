import React, { useState } from 'react';
import './Login.css';
import { material } from '../../library/Material';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { loginAction } from '../../store/slice/LoginSlice';
import { useDispatch } from 'react-redux';
import { userLogin } from '../../services/LoginService';
import Snackbar from '../../toaster-message/Snackbar';
import ForgetPassword from '../../dialogs/ForgetPassword';

function Login(props) {

    const navigate = useNavigate();
    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm();
    const dispatch = useDispatch();
    const [isVisible, setIsVisible] = useState(false);
    const [type, setType] = useState("password");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openForgetPasswordDialog, setOpenForgetPasswordDialog] = useState(false);

    function showPassword() {
        setIsVisible(!isVisible)
        setType("text")
    };

    function hidePassword() {
        setIsVisible(!isVisible)
        setType("password")
    };

    const login = async (userData) => {
        await userLogin(userData)
            .then((resp) => {
                dispatch(loginAction(resp.data))
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "You have successfully loggedin",
                })
                for (let i = 0; i < resp.data?.roles?.length; i++) {
                    if (resp.data.roles[i] === "ROLE_ADMIN") {
                        navigate("/user-management");
                    } else if (resp.data.roles[i] === "ROLE_CORPORATE_EDITOR" || resp.data.roles[i] === "ROLE_FACILITY_EDITOR" || resp.data.roles[i] === "ROLE_FACILITY_ESG_DATA") {
                        navigate("/dashboard");
                    } else if (resp.data.roles[i] === "ROLE_FACILITY_APPROVER") {
                        navigate("/facility");
                    }
                };
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                })
            })
    };

    const forgetPassword = () => {
        setOpenForgetPasswordDialog(true)
    };

    return (
        <div>
            <div>
                {/* <div className="bg-image"> */}
                <div className='row d-flex alignItems-center justify-content-center'>
                    <div className='col-lg-4 col-md-6 col-sm-6 ms-5'>
                        <material.Paper elevation={7} sx={{ maxHeight: "450px" }}>
                            <form>
                                <div className='login-form-body p-5'>
                                    <h5 className='text-center'>ESG Tool</h5>
                                    <material.Box
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                        sx={{ mt: 4 }}
                                    >
                                        {/* <form> */}
                                        <div className="mt-3">
                                            <material.TextField
                                                error={errors.username?.type === "required"}
                                                required
                                                id="outlined-basic"
                                                label="User Name"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                color='success'
                                                {...register("username", { required: true })}
                                            />
                                            <p className='form-text text-danger'>{errors.username?.type === "required" && 'This field is required'}</p>
                                            {(errors?.email?.type === "pattern") && (
                                                <p className='text-danger'>This is not a valid Email</p>
                                            )}
                                        </div>
                                        <div className="mt-5 pass-wrapper">
                                            <material.TextField
                                                {...register("password", { required: true, })}
                                                required
                                                label="Password"
                                                id="password"
                                                variant="outlined"
                                                type={type}
                                                color='success'
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <material.InputAdornment position="end">
                                                            {isVisible === false ? (<material.VisibilityIcon onClick={showPassword} sx={{ cursor: "pointer" }} />) : (<material.VisibilityOffIcon onClick={hidePassword} sx={{ cursor: "pointer" }} />)}
                                                        </material.InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <p className='form-text text-danger'>{errors.password?.type === "required" && 'This field is required'}</p>
                                        </div>
                                        <div className='mt-5'>
                                            {/* <material.Button type='button' variant="contained" color='success' sx={{ textTransform: "none", width: "100%" }} onClick={handleSubmit(login)}>Login</material.Button> */}
                                            <button className="btn btn-success" style={{ width: "100%" }} onClick={handleSubmit(login)} disabled={!isValid}>Login</button>
                                        </div>
                                        <div className="mt-2 float-end"><a href="#" onClick={forgetPassword}>Forgot Password?</a></div>
                                    </material.Box>
                                </div>
                            </form>
                            <Snackbar
                                openSnackBar={openSnackBar}
                                setOpenSnackBar={setOpenSnackBar}
                            />
                        </material.Paper>
                    </div>
                </div>
                {/* </div> */}
            </div>
            <ForgetPassword
                openForgetPasswordDialog={openForgetPasswordDialog}
                setOpenForgetPasswordDialog={setOpenForgetPasswordDialog}
            />
        </div>
    );
}

export default Login;