import React, { useEffect, useState } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { addSiteByCorporateRefNo } from '../services/SiteService';
import Snackbar from '../toaster-message/Snackbar';
import { sum } from '../shared/Shared';

function GenOperation(props) {
    const { callFrom, openDialog, setOpenDialog, getCorporateWithFacilities } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [participatesInBusinessResponsibility, setParticipatesInBusinessResponsibility] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openDialog.action === true) {
            setParticipatesInBusinessResponsibility(openDialog.data?.participatesInBusinessResponsibility ? openDialog.data.participatesInBusinessResponsibility : "")
            // reset({ assetName: openDialog.data.assetName, affiliation: openDialog.data.affiliation, percentageOfShare: openDialog.data.percentageOfShare, projectActivity: openDialog.data.nicDto.projectActivity, activityDetails: openDialog.data.activityDetails, totalTurnOverContributed: openDialog.data.totalTurnOverContributed })
            reset(openDialog.data);
        }
    }, [openDialog.action === true]);
    const close = () => {
        setOpenDialog({ action: false, data: "" })
        reset({ assetName: "", affiliation: "", percentageOfShare: "", projectActivity: "", activityDetails: "", totalTurnOverContributed: "" })
    };
    const selectParticipatesInBusinessResponsibility = (value) => {
        setParticipatesInBusinessResponsibility(value)
    };
    const update = async (data) => {
        let array = [];
        let obj;
        if (callFrom === "facilityDetails") {
            if (parseFloat(data.percentageOfShare) <= 100) {
                obj = {
                    refNo: openDialog.data.refNo,
                    percentageOfShare: data.percentageOfShare,
                    participatesInBusinessResponsibility: participatesInBusinessResponsibility
                }
            } else {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "Total exceeding 100",
                })
            }
        } else if (callFrom === "businessActivities") {
            if (parseFloat(data.totalTurnOverContributed) <= 100) {
                obj = {
                    refNo: openDialog.data.refNo,
                    totalTurnOverContributed: data.totalTurnOverContributed,
                }
            } else {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "Total exceeding 100",
                })
            }
        }
        if (obj) {
            array.push(obj)
        };
        if (array.length) {
            await addSiteByCorporateRefNo(userDetails.corporateRefNo, array)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Save successfully",
                    })
                    getCorporateWithFacilities()
                    reset({ assetName: "", affiliation: "", percentageOfShare: "", projectActivity: "", activityDetails: "", totalTurnOverContributed: "" })
                    setOpenDialog({ action: false, data: "" })
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                })
        }
    };

    return (
        <div>
            <material.Dialog open={openDialog.action} maxWidth="sm" fullWidth>
                <material.DialogTitle className='headingText'>
                    {callFrom === "facilityDetails" ? "Edit facility details" : callFrom === "businessActivities" ? "Edit Business Activities" : ""}
                </material.DialogTitle>
                <material.DialogContent>
                    {callFrom === "facilityDetails" || callFrom === "businessActivities" ? (
                        <div className="row">
                            <div>
                                <material.TextField
                                    label="Facility Name"
                                    id="assetName"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    color='success'
                                    // defaultValue={openDialog.data ? openDialog.data.assetName : ""}
                                    InputProps={{ readOnly: true }}
                                    {...register("assetName")}
                                />
                            </div>
                            {callFrom === "facilityDetails" ? (
                                <>
                                    <div className='mt-2'>
                                        <material.TextField
                                            label="Affiliation / association with Corporate"
                                            id="affiliation"
                                            variant="standard"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            color='success'
                                            InputProps={{ readOnly: true }}
                                            // defaultValue={openDialog.data ? openDialog.data.affiliation : ""}
                                            {...register("affiliation")}
                                        />
                                    </div>
                                    <div className='mt-2'>
                                        <material.TextField
                                            error={errors.percentageOfShare?.type === "required"}
                                            label="Shares with Corporate (%)"
                                            id="percentageOfShare"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            fullWidth
                                            color='success'
                                            // defaultValue={openDialog.data ? openDialog.data.percentageOfShare : ""}
                                            {...register("percentageOfShare", { required: true, pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/i })}
                                        />
                                        {(errors?.percentageOfShare?.type === "pattern") && (
                                            <p className='text-danger'>Invalid input, please input only number</p>
                                        )}
                                    </div>
                                    <div className='mt-3'>
                                        <material.FormControl>
                                            <material.Typography sx={{ fontSize: 18 }}>Participate in the Business Responsibility initiatives of the listed entity?</material.Typography>
                                            <material.RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                onChange={event => selectParticipatesInBusinessResponsibility(event.target.value)}
                                                value={participatesInBusinessResponsibility}
                                            >
                                                <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                            </material.RadioGroup>
                                        </material.FormControl>
                                    </div>
                                </>
                            ) : callFrom === "businessActivities" ? (
                                <div>
                                    <div className='mt-2'>
                                        <material.TextField
                                            label="Description of Main Activity"
                                            id="projectActivity"
                                            variant="standard"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            color='success'
                                            // defaultValue={openDialog.data ? openDialog.data.nicDto.projectActivity : ""}
                                            InputProps={{ readOnly: true }}
                                            {...register("primaryActivity")}
                                        />
                                    </div>
                                    <div className='mt-2'>
                                        <material.TextField
                                            label="Description of Business Activity"
                                            id="activityDetails"
                                            variant="standard"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            color='success'
                                            // defaultValue={openDialog.data ? openDialog.data.activityDetails : ""}
                                            InputProps={{ readOnly: true }}
                                            {...register("activityDetails")}
                                        />
                                    </div>
                                    <div className='mt-2'>
                                        <material.TextField
                                            error={errors.totalTurnOverContributed?.type === "required"}
                                            label="Percentage of Total Turnover Contributed"
                                            id="totalTurnOverContributed"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            fullWidth
                                            color='success'
                                            // defaultValue={openDialog.data ? openDialog.data.totalTurnOverContributed : ""}
                                            {...register("totalTurnOverContributed", { required: true, pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/i })}
                                        />
                                        {(errors?.totalTurnOverContributed?.type === "pattern") && (
                                            <p className='text-danger'>Invalid input, please input only number</p>
                                        )}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="outlined" color='error' sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />} onClick={close}>Close</material.Button>
                    <material.Button variant="outlined" color='success' sx={{ textTransform: "none" }} onClick={handleSubmit(update)}>Update</material.Button>
                </material.DialogActions>
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default GenOperation;