import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DateFilter from '../../../shared/DateFilter';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import AddODSEmissionProduction from '../../../sub-issues-dialogs/climate-change-dialog/AddODSEmissionProduction';
import Snackbar from '../../../toaster-message/Snackbar';
import RejectionNote from '../../../dialogs/RejectionNote';
import { getSubIssueRecordData, saveSubIssueData, updateSubIssueData } from '../../../services/sub-issue-services/AddSubissueService';
import moment from 'moment';
import ApplicableDialog from '../../../dialogs/ApplicableDialog';
import { getDataFilterByCurrentApprovedDataAndNotApprovedDate } from '../../../shared/Filter';
import Pagination from '../../../shared/pagination/Pagination';


const label = { inputProps: { "aria-label": "Color switch demo" } };
var usersRole;
var recordRefNo = [];

function EmissionProduction(props) {
    const location = useLocation();
    const treeViewData = location.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [oDSEmissionProductionTabs, setODSEmissionProductionTabs] = useState("ODS Production");
    const [oDSEmissionProductionTableData, setODSEmissionProductionTableData] = useState([]);
    const [openAddODSEmissionProduction, setOpenAddODSEmissionProduction] = useState({ action: false, oDSEmissionProductionTableData: null });
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = React.useState(0);
    const [getFilterDate, setGetFilterDate] = useState(null);

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        getAllODSEmissionProductionData();
    }, [oDSEmissionProductionTabs]);

    const getAllODSEmissionProductionData = (filterDate, paginationValue) => {
        if (oDSEmissionProductionTabs === "ODS Production") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            const payload = {
                subissueName: treeViewData.subIssue,
                typeOfRecord: "ODS_Emission_Production",
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                forApproval,
                approveHistory,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            getSubIssueRecordData(payload)
                .then((resp) => {
                    // const FilterData = getDataFilterByCurrentApprovedDataAndNotApprovedDate(resp.data.records.content);
                    setODSEmissionProductionTableData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setApplicableRefNo(resp.data.applicableRecord?.ref_no ? resp.data.applicableRecord.ref_no : null);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else if (oDSEmissionProductionTabs === "ODS Production History") {
            let forApproval = true;
            let approveHistory = true;
            const payload = {
                subissueName: treeViewData.subIssue,
                typeOfRecord: "ODS_Emission_Production",
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                forApproval,
                approveHistory,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            getSubIssueRecordData(payload)
                .then((resp) => {
                    setODSEmissionProductionTableData(resp.data.records.content);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    const oDSEmissionProductionTabChange = (event, newValue) => {
        setODSEmissionProductionTabs(newValue)
    };

    const getFormDateToDate = (data) => {
        setGetFilterDate(data);
        getAllODSEmissionProductionData(data, paginationData);
    };

    const addODSEmissionProduction = () => {
        setOpenAddODSEmissionProduction({ action: true, oDSEmissionProductionTableData: null });
    };

    const editODSEmissionProduction = (recordData) => {
        setOpenAddODSEmissionProduction({ action: true, oDSEmissionProductionTableData: recordData });
    };

    const clickCheckBox = (value) => {
        setOpenAddODSEmissionProduction({ action: false, oDSEmissionProductionTableData: null });
        if (recordRefNo.includes(value)) {
            recordRefNo = recordRefNo.filter(item => item !== value);
        } else {
            recordRefNo.push(value);
        }
    };

    const sendApprovalODSEmissionProduction = () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true
            }
            updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllODSEmissionProductionData(getFilterDate, paginationData);
                    recordRefNo = [];
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    const approvedODSEmissionProduction = () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true,
                "approved_by": userDetails.username,
                "approved_date": moment().format("YYYY-MM-DD"),
                "approved_status": "APPROVED",
                "rejection_note": "",
            }
            updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllODSEmissionProductionData(getFilterDate, paginationData);
                    recordRefNo = [];
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    const rejectODSEmissionProduction = () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        };
    };

    const getRejectionNoteData = async (noteData) => {
        const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
        const subissueName = treeViewData.subIssue;
        const payload = {
            send_for_approval: false,
            "rejection_note": noteData.rejectionNote,
            "approved_status": "",
        }
        updateSubIssueData(payload, refNo, subissueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllODSEmissionProductionData(getFilterDate, paginationData);
                recordRefNo = [];
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };

    const getApplicableData = (data) => {
        if (applicableRefNo === null) {
            let subIssueName = treeViewData?.subIssue;
            const payload = {
                applicable: false,
                applicable_record: true,
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
            }
            saveSubIssueData(payload, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllODSEmissionProductionData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else {
            let subIssueName = treeViewData?.subIssue;
            let refNo = applicableRefNo;
            const payload = {
                applicable: applicable ? false : true,
            }
            updateSubIssueData(payload, refNo, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllODSEmissionProductionData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const getPaginationData = (filterData, paginationData) => {
        setRowsPerPage(paginationData.size);
        setPage(paginationData.page);
        getAllODSEmissionProductionData(filterData, paginationData);
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7, width: "75%" }}>
                    <div className="row">
                        <span className="col-6"><material.Typography variant="h5" className='headingText'>ODS Production</material.Typography></span>
                        <div className="col-6">
                            {usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR" ? null : (
                                <span className="float-end">
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} onChange={applicableNotApplicable}
                                            checked={applicable}
                                            color="secondary" />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="mt-3">
                        <material.TabContext value={oDSEmissionProductionTabs}>
                            <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <material.TabList onChange={oDSEmissionProductionTabChange} aria-label="lab API tabs example">
                                    <material.Tab className="text-capitalize" label="ODS Production" value="ODS Production" />
                                    <material.Tab className="text-capitalize" label="ODS Production History" value="ODS Production History" />
                                </material.TabList>
                            </material.Box>
                            <material.TabPanel value="ODS Production">
                                <div className="row mt-2 align-items-center">
                                    <div className="col-lg-6">
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        )}
                                    </div>
                                    <div className="col-lg-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                        <span className='float-end'>
                                            {oDSEmissionProductionTableData?.length ? (
                                                <>
                                                    <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} color='success'
                                                        disabled={!applicable || !recordRefNo.length}
                                                        sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approvedODSEmissionProduction}>Approve</material.Button>
                                                    <material.Button sx={{ textTransform: "none" }} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"}
                                                        disabled={!applicable || !recordRefNo.length}
                                                        variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectODSEmissionProduction}>Reject</material.Button>
                                                </>
                                            ) : null}
                                            <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success'
                                                disabled={!applicable}
                                                sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendApprovalODSEmissionProduction}>Send for Approval</material.Button>
                                            <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success'
                                                disabled={!applicable}
                                                sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addODSEmissionProduction}>Add ODS Production</material.Button>
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                        <div className='row'>
                                            <div className="col-12">
                                                <material.TableContainer>
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow>
                                                                <StyledTableCell>Annex/Group</StyledTableCell>
                                                                <StyledTableCell>Substances</StyledTableCell>
                                                                <StyledTableCell>Total Production for All Uses</StyledTableCell>
                                                                <StyledTableCell>Unit CFC</StyledTableCell>
                                                                <StyledTableCell>Exempted Uses for Feedstock</StyledTableCell>
                                                                <StyledTableCell>Exempted for External Uses</StyledTableCell>
                                                                <StyledTableCell>Production for Supply to Arti. Countries (Art. 2A-2H & 5)</StyledTableCell>
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                {/* {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                                )} */}
                                                                <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Rejection Note</StyledTableCell>
                                                                )}
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {oDSEmissionProductionTableData.length ? oDSEmissionProductionTableData.map((item, k) => (
                                                                <material.TableRow key={k}
                                                                    sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                    onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true) ?
                                                                        () => editODSEmissionProduction(item) : null}
                                                                >
                                                                    <material.TableCell className='text-break' size='small' style={{ width: "10rem" }}>{item.annex}</material.TableCell>
                                                                    <material.TableCell className='text-break' size='small' style={{ width: "10rem" }}>{item.substances}</material.TableCell>
                                                                    <material.TableCell>{item.total_production_for_all_uses}</material.TableCell>
                                                                    <material.TableCell>{item.unit_cfc}</material.TableCell>
                                                                    <material.TableCell>{item.exempted_uses_for_feedstock}</material.TableCell>
                                                                    <material.TableCell>{item.exempted_for_external_uses}</material.TableCell>
                                                                    <material.TableCell>{item.production_for_supply_to_article_5_countries}</material.TableCell>
                                                                    <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    {/* {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    )} */}
                                                                    <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                                        {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.send_for_approval} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                                        ) : (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.approved_status === "APPROVED"} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                                        )}
                                                                    </material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{(item.rejection_note === null) || (item.rejection_note === "") ? "No" : (
                                                                            <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                        )}
                                                                        </material.TableCell>
                                                                    )}
                                                                </material.TableRow>
                                                            )) : (
                                                                <material.TableRow>
                                                                    <material.TableCell colSpan={12}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )}
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                                <Pagination
                                                    getPaginationData={getPaginationData}
                                                    totalElements={totalElements}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    getFilterDate={getFilterDate}
                                                />
                                            </div>
                                        </div>
                                    </material.Paper>
                                </div>
                            </material.TabPanel>
                            <material.TabPanel value="ODS Production History">
                                <div className="row">
                                    <div className='col-lg-12 mb-3'>
                                        <DateFilter
                                            getFormDateToDate={getFormDateToDate}
                                        />
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                        <div className='row'>
                                            <div className="col-12">
                                                <material.TableContainer>
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow>
                                                                <StyledTableCell>Annex/Group</StyledTableCell>
                                                                <StyledTableCell>Substances</StyledTableCell>
                                                                <StyledTableCell>Total Production for All Uses</StyledTableCell>
                                                                <StyledTableCell>Unit CFC</StyledTableCell>
                                                                <StyledTableCell>Exempted Uses for Feedstock</StyledTableCell>
                                                                <StyledTableCell>Exempted for External Uses</StyledTableCell>
                                                                <StyledTableCell>Production for Supply to Arti. Countries (Art. 2A-2H & 5)</StyledTableCell>
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                <StyledTableCell>Approval Date</StyledTableCell>
                                                                <StyledTableCell>Status</StyledTableCell>
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {oDSEmissionProductionTableData.length ? oDSEmissionProductionTableData.map((item, k) => (
                                                                <material.TableRow key={k}>
                                                                    <material.TableCell className='text-break' size='small' style={{ width: "10rem" }}>{item.annex}</material.TableCell>
                                                                    <material.TableCell className='text-break' size='small' style={{ width: "10rem" }}>{item.substances}</material.TableCell>
                                                                    <material.TableCell>{item.total_production_for_all_uses}</material.TableCell>
                                                                    <material.TableCell>{item.unit_cfc}</material.TableCell>
                                                                    <material.TableCell>{item.exempted_uses_for_feedstock}</material.TableCell>
                                                                    <material.TableCell>{item.exempted_for_external_uses}</material.TableCell>
                                                                    <material.TableCell>{item.production_for_supply_to_article_5_countries}</material.TableCell>
                                                                    <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>
                                                                        {item.approved_status === "APPROVED" ? <span className="badge bg-success">
                                                                            APPROVED
                                                                        </span> : null}
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )) : (
                                                                <material.TableRow>
                                                                    <material.TableCell colSpan={12}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )}
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                                <Pagination
                                                    getPaginationData={getPaginationData}
                                                    totalElements={totalElements}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    getFilterDate={getFilterDate}
                                                />
                                            </div>
                                        </div>
                                    </material.Paper>
                                </div>
                            </material.TabPanel>
                        </material.TabContext>
                    </div >
                </material.Box >

            </material.Box >
            <AddODSEmissionProduction
                openAddODSEmissionProduction={openAddODSEmissionProduction}
                setOpenAddODSEmissionProduction={setOpenAddODSEmissionProduction}
                getAllODSEmissionProductionData={getAllODSEmissionProductionData}
                treeViewData={treeViewData}
                page={page}
                rowsPerPage={rowsPerPage}
                getFilterDate={getFilterDate}
            />
            <ApplicableDialog
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getApplicableData={getApplicableData}
                applicable={applicable}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </div>
    );
}

export default EmissionProduction;