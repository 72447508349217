import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { getSitesByCorporateRefNo } from '../../services/SiteService';
import { saveAssessment } from '../../services/AssessmentService';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Snackbar from '../../toaster-message/Snackbar';
import dayjs from 'dayjs';

const icon = <material.CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <material.CheckBoxIcon fontSize="small" />;

function SetUpAssessmentDialog(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, getValues, setValue, watch, control, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const { openAssessmentDialog, setOpenAssessmentDialog, getAssessmentByCorporateRefNo } = props;

    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [facilityList, setFacilityList] = useState([]);
    const [facilityName, setFacilityName] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [facilityRefNo, setFacilityRefNo] = useState([]);
    const [getFilterFacility, setGetFilterFacility] = useState([]);

    const currentDate = moment().format("YYYY-MM-DD");

    useEffect(() => {
        if (openAssessmentDialog.action) {
            getFacilityByCorporateRefNo();
        }
        if (openAssessmentDialog.action && openAssessmentDialog.assessmentData !== null) {
            reset({
                assessmentName: openAssessmentDialog.assessmentData.assessmentName,
                startDate: dayjs(openAssessmentDialog.assessmentData.startDate),
                endDate: dayjs(openAssessmentDialog.assessmentData.endDate)
            });
            setStartDate(dayjs(openAssessmentDialog.assessmentData.startDate));
            setEndDate(dayjs(openAssessmentDialog.assessmentData.endDate));
            let facilityName = openAssessmentDialog.assessmentData.facilityList.map((facility) => facility.facilityName);
            let facilityRefNo = openAssessmentDialog.assessmentData.facilityList.map((facility) => facility.facilityRefNo);
            setFacilityRefNo(facilityRefNo);
            setValue("assetName", facilityName);
            setGetFilterFacility(facilityName);
        }
    }, [openAssessmentDialog.action === true]);

    const getFacilityByCorporateRefNo = async () => {
        await getSitesByCorporateRefNo(userDetails.corporateRefNo)
            .then((resp) => {
                setFacilityList(resp.data);
                let facilityName = resp.data?.map((ele) => ele.assetName);
                setFacilityName(facilityName)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const getFacilityRefNo = (facilityName) => {
        let facilityRefNo = facilityName?.map((name) => {
            let refNo = facilityList?.filter((ele) => ele.assetName == name)?.map((resp) => resp.refNo)[0];
            return refNo;
        })
        setFacilityRefNo(facilityRefNo);
    };

    const saveAssessmentSetUp = (formData) => {
        const payload = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNos": facilityRefNo,
            "assessmentName": formData.assessmentName,
            "startDate": moment(formData.startDate?.$d).format("YYYY-MM-DD"),
            "endDate": moment(formData.endDate?.$d).format("YYYY-MM-DD"),
            assessmentRefNo: openAssessmentDialog.assessmentData !== null ? openAssessmentDialog.assessmentData.assessmentRefNo : ""
        }
        if (moment(formData.startDate?.$d).format("YYYY-MM-DD") < moment(formData.endDate?.$d).format("YYYY-MM-DD")) {
            saveAssessment(payload)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAssessmentByCorporateRefNo();
                    setGetFilterFacility([]);
                    reset({ assessmentName: "", assetName: "", startDate: "", endDate: "" });
                    setStartDate("");
                    setEndDate("");
                    setFacilityRefNo([]);
                    setOpenAssessmentDialog({ action: false, assessmentData: null })
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Start date must be prior to end date.",
            })
        };
    };

    const handleClose = () => {
        reset({ assessmentName: "", assetName: "", startDate: "", endDate: "" });
        setGetFilterFacility([]);
        setFacilityRefNo([]);
        setStartDate(null);
        setEndDate(null);
        setOpenAssessmentDialog({ action: false, assessmentData: null })
    };

    return (
        <div>
            <material.Dialog open={openAssessmentDialog.action} maxWidth="md" fullWidth>
                <material.DialogTitle className='fw-bold'>{openAssessmentDialog.assessmentData !== null ? "Edit Assessment" : "Add Assessment"}</material.DialogTitle>
                <material.DialogContent>
                    <form>
                        <div className="row">
                            <div className="col-12">
                                <material.TextField
                                    required
                                    label="Assessment Name"
                                    id="corporateIdentityNo"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    color='success'
                                    InputProps={{ readOnly: currentDate >= openAssessmentDialog.assessmentData?.startDate && currentDate <= openAssessmentDialog.assessmentData?.endDate ? true : false }}
                                    {...register("assessmentName", { required: true })}
                                />
                            </div>
                            <div className="col-12 mt-3">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Controller
                                        name="startDate"
                                        control={control}
                                        defaultValue={null}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <material.DatePicker
                                                label="Start Date *"
                                                format='DD-MM-YYYY'
                                                value={startDate}
                                                disabled={currentDate >= openAssessmentDialog.assessmentData?.startDate && currentDate <= openAssessmentDialog.assessmentData?.endDate}
                                                onChange={(newValue) => {
                                                    setStartDate(newValue);
                                                    field.onChange(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <material.TextField
                                                        {...params}
                                                        variant="standard"
                                                        color="success"
                                                        {...field}
                                                    />
                                                )}
                                                slotProps={{ textField: { variant: 'standard', color: 'success', readOnly: true } }}
                                                sx={{ width: '100%' }}
                                                disablePast
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="col-12 mt-3">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Controller
                                        name="endDate"
                                        control={control}
                                        defaultValue={null}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <material.DatePicker
                                                label="End Date *"
                                                format='DD-MM-YYYY'
                                                value={endDate}
                                                onChange={(newValue) => {
                                                    setEndDate(newValue);
                                                    field.onChange(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <material.TextField
                                                        {...params}
                                                        variant="standard"
                                                        color="success"
                                                        {...field}
                                                    />
                                                )}
                                                slotProps={{ textField: { variant: 'standard', color: 'success', readOnly: true } }}
                                                sx={{ width: '100%' }}
                                                disablePast
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="col-12">
                                <Controller
                                    name="assetName"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <material.Autocomplete
                                            {...field}
                                            multiple
                                            options={facilityName}
                                            // getOptionLabel={(option) => option.assetName}
                                            // value={field.value || getFilterFacility}
                                            value={[
                                                ...getFilterFacility, // Add predefined items here
                                                ...(Array.isArray(field.value) ? field.value.filter(item => !getFilterFacility.includes(item)) : []),
                                            ]}
                                            disableCloseOnSelect
                                            disableClearable
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <material.Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected || getFilterFacility.includes(option)}
                                                        disabled={getFilterFacility.includes(option)}
                                                    />
                                                    {option}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    margin="dense"
                                                    {...params}
                                                    variant="standard"
                                                    label="Facility / Supplier"
                                                    fullWidth
                                                />
                                            )}
                                            onChange={(_, data) => {
                                                // field.onChange(data);
                                                // const assetName = getValues("assetName");
                                                // let updateDate = getFacilityRefNo(assetName);
                                                const filteredData = data.filter(item => !getFilterFacility.includes(item));
                                                field.onChange([...getFilterFacility, ...filteredData]);
                                                const assetName = getValues("assetName");
                                                let updateDate = getFacilityRefNo(assetName);
                                            }}
                                        />
                                    )}
                                />
                                {/* <material.Autocomplete
                                margin="dense"
                                id="sitesName"
                                onChange={selectFacility}
                                options={facilityList}
                                disableCloseOnSelect
                                multiple
                                getOptionLabel={(option) => option.assetName}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <material.Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.assetName}
                                    </li>
                                )}
                                renderInput={(params) => <material.TextField {...params} variant="standard" label="Facility Name"
                                    fullWidth
                                    required
                                    {...register("assetName", { required: true })}
                                />}
                            /> */}
                            </div>
                        </div>
                    </form>
                </material.DialogContent>
                <material.DialogActions>
                    <span className='float-end m-2'>
                        <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                        {openAssessmentDialog.assessmentData === null ? (
                            <material.Button variant="outlined" color='success' sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} onClick={handleSubmit(saveAssessmentSetUp)} disabled={!isValid}>Submit</material.Button>
                        ) : (
                            <material.Button variant="outlined" color='success' sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} onClick={handleSubmit(saveAssessmentSetUp)} disabled={!isValid}>Update</material.Button>
                        )}
                    </span>
                </material.DialogActions>
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default SetUpAssessmentDialog;