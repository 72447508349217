import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import SwipeDrawer from '../drawer/SwipeDrawer';
import ColumnChart from '../../shared/reporting/ColumnChart';
import PieChart from '../../shared/reporting/PieChart';
import "./Dashboard.css";
import FullScreenChartDialog from './FullScreenChartDialog';
import LineChart from '../../shared/reporting/LineChart';
import { useSelector } from 'react-redux';
import DonutChart from '../../shared/reporting/DonutChart';
import ComboChart from '../../shared/reporting/ComboChart';
import { getSitesByCorporateRefNo } from '../../services/SiteService';
import Snackbar from '../../toaster-message/Snackbar';
import { getAirPointSourceReportData, getEnergyConsumptionWithinOrgReportData, getGhgEmissionReportingData, getScope1ReportData, getScope2ReportData, getScope3ReportData, getWasteDerivedReportData, getWasteDisposalReportData, getWasteGenerationReportData, getWasteRecoveryAndDisposalReportData, getWasteRecoveryReportData, getWaterDerivedReportData, getWaterDischargeData, getWaterWithDrawlData } from '../../services/reporting-services/ReportingService';
import SpeedometerChart from '../../shared/reporting/SpeedometerChart';
import TableWidgetDialog from './TableWidgetDialog';
import BarsLoader from '../../shared/loader/BarsLoader';
import FilterImage from '../../assets/filter.png';
import HalfDonutChart from '../../shared/reporting/HalfDonutChart';
import GlobalFilter from './GlobalFilter';
import dayjs from 'dayjs';
import { energyAndFuelConsumptionFacilityWiseColumns, energyAndFuelDerivedColumns, energyConsumptionTableColumns, fuelConsumptionTableColumns, ghgScope1Columns, ghgScope1FacilityWiseDerivedColumns, ghgScope2Columns, ghgScope2FacilityWiseDerivedColumns, tableHeaderContent } from '../../shared/reporting/TableHeader';
import { getMaterialReportData } from '../../services/reporting-services/MaterialService';
import MultipleTable from './MultipleTable';
import WaterReportingChart from '../../shared/reporting/WaterReportingChart';
import ReportingTable from './materials/ReportingTable';
import moment from 'moment';



const wasteRecoveryAndDisposalOptions = {
    title: "Waste Recovered & Disposed",
    titleTextStyle: {
        fontName: 'Arial', // Font family of the title
        fontSize: 18,      // Size of the title text
        bold: true,        // Make the title text bold
        italic: false      // Title text style is not italic
    },
    colors: ['#dbcccc', '#8faab3', '#9fc164'],
    chartArea: { width: "75%" },
    legend: { position: "top" },
};

const donutChartOptions = {
    title: "CO2 Emission Share by Scope",
    titleTextStyle: {
        fontName: 'Arial', // Font family of the title
        fontSize: 18,      // Size of the title text
        bold: true,        // Make the title text bold
        italic: false      // Title text style is not italic
    },
    colors: ['#9fc164', '#d8c596', '#451B04'],
    pieHole: 0.4,
    is3D: false,
    legend: { position: "top" },
    chartArea: { width: "90%" },
};

const yearData = dayjs(new Date());
const currentYear = moment().format('YYYY');

function Dashboard(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [wasteGenerationChartData, setWasteGenerationChartData] = useState(null);
    const [wasteGenerationTableData, setWasteGenerationTableData] = useState([]);
    const [wasteRecoveryAndDisposalChartData, setWasteRecoveryAndDisposalChartData] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [isLoading, setIsLoading] = useState(null);
    const [columnChartLoading, setColumnChartLoading] = useState(true);
    const [openFilterDialog, setOpenFilterDialog] = useState({ action: false, userData: null });
    const [airEmissionChartData, setAirEmissionChartData] = useState(null);
    const [airEmissionTableData, setAirEmissionTableData] = useState([]);
    const [materialsChartData, setMaterialsChartData] = useState(null);
    const [materialTableData, setMaterialTableData] = useState([]);
    const [energyAndFuelConsumptionWithinOrgReportData, setEnergyAndFuelConsumptionWithinOrgReportData] = useState(null);
    const [energyAndFuelConsumptionWithinOrgTableData, setEnergyAndFuelConsumptionWithinOrgTableData] = useState([]);
    const [loadingLineChart, setLOadingLineChart] = useState(true);
    const [loadingGaugeChart, setLoadingGaugeChart] = useState(true);
    const [loadingPieChart, setLoadingPieChart] = useState(true);
    const [ghgScope1ChartData, setGhgScope1ChartData] = useState(null);
    const [ghgScope1TableData, setGhgScope1TableData] = useState([]);
    const [ghgScope2ChartData, setGhgScope2ChartData] = useState(null);
    const [ghgScope2TableData, setGhgScope2TableData] = useState([]);
    const [wasteDerivedTableData, setWasteDerivedTableData] = useState([]);
    const [co2EmissionChartData, setCo2EmissionChartData] = useState(null);
    const [loadingGhgEmission, setLoadingGhgEmission] = useState(true);
    const [loadingHalfDonutChart, setLoadingHalfDonutChart] = useState(true);
    const [waterAndWasterChartData, setWaterAndWasterChartData] = useState(null);
    const [waterAndWasterData, setWaterAndWasterData] = useState([]);
    const [filterData, setFilterData] = useState(null);
    const year = localStorage.getItem("year");

    const ghgEmission = {
        title: "GHG Scope 1",
        titleTextStyle: {
            fontName: 'Arial', // Font family of the title
            fontSize: 18,      // Size of the title text
            bold: true,        // Make the title text bold
            italic: false      // Title text style is not italic
        },
        hAxis: {
            title: filterData?.facilityRefNo.length ? 'Facility Name' : "",
        },
        vAxis: {
            title: 'Unit tCO2e',
        },
        legend: {
            position: "bottom",
            alignment: 'center'
        },
        chartArea: { width: "75%" },
        // seriesType: userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? "bars" : "",
    };

    const ghgScope2Option = {
        title: "GHG Scope 2",
        titleTextStyle: {
            fontName: 'Arial', // Font family of the title
            fontSize: 18,      // Size of the title text
            bold: true,        // Make the title text bold
            italic: false      // Title text style is not italic
        },
        hAxis: {
            title: filterData?.facilityRefNo.length ? 'Facility Name' : "",
        },
        vAxis: {
            title: 'Unit tCO2e',
        },
        legend: {
            position: "bottom",
            alignment: 'center'
        },
        chartArea: { width: "75%" },
        // seriesType: userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? "bars" : "",
    };

    const wasteGenerationOptions = {
        title: "Waste Generation",
        titleTextStyle: {
            fontName: 'Arial', // Font family of the title
            fontSize: 18,      // Size of the title text
            bold: true,        // Make the title text bold
            italic: false      // Title text style is not italic
        },
        colors: ['#bd806d', '#43BFC7', '#22CE83'],
        vAxis: { title: "Unit MT" },
        hAxis: { title: userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? "Facility Name" : "Waste Category" },
        legend: userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? {
            position: "top",
            alignment: 'end'
        } : { position: "top" },
        chartArea: { width: "75%" },
        isStacked: userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? true : false,
    };
    const airEmissionOptions = {
        title: "Air Emission",
        titleTextStyle: {
            fontName: 'Arial', // Font family of the title
            fontSize: 18,      // Size of the title text
            bold: true,        // Make the title text bold
            italic: false      // Title text style is not italic
        },
        vAxis: { title: "Unit MT/Day" },
        hAxis: { title: userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? "Facility Name" : "Pollutant Name" },
        seriesType: "bars",
        chartArea: { width: "75%" },
        legend: { position: "top" },
    };
    const energyConsumptionOptions = {
        title: 'Energy Consumption (Electricity & Fuel)',
        titleTextStyle: {
            fontName: 'Arial', // Font family of the title
            fontSize: 18,      // Size of the title text
            bold: true,        // Make the title text bold
            italic: false      // Title text style is not italic
        },
        colors: ['#FFD700', '#008000'],
        hAxis: {
            title: "Type of Energy"
        },
        vAxis: {
            title: 'Unit Gj'
        },
        chartArea: { width: "70%" },
        legend: { position: "top" },
    };

    useEffect(() => {
        localStorage.setItem("year", yearData.$y)
        const facilities = userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? null : [userDetails.facilityRefNo];
        // getPillarIssueSubIssueList();
        getWasteGenerationReportingData(facilities, year);
        getWasteRecoveryAndDisposalReportingData(facilities, year);
        getEnergyConsumptionWithinOrgReportingData();
        getAirPointSourceReportingData(facilities, year);
        getMaterialsReportingData(facilities, year);
        getGhgScope1ReportData();
        getWaterDischargeDerivedReportData(facilities, year);
        getGhgScope2ReportData();
    }, []);

    const getWasteGenerationReportingData = (facilities, year) => {
        let array = [];
        setColumnChartLoading(true);
        let obj = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityList: facilities?.length > 0 ? facilities.join(',') : null,
            YearList: year
        }
        getWasteGenerationReportData(obj)
            .then((resp) => {
                const forFacility = [["Waste Category", "Hazardous", "Non-hazardous", "Other"]]
                if (resp.data.facilityDetails) {
                    resp.data.facilityDetails.forEach(facility => {
                        const facilityName = facility.facilityName;
                        facility.wasteDetails.forEach(waste => {
                            const row = {
                                facilityName,
                                hazardous: waste.hazardous.toFixed(2),
                                nonHazardous: waste.nonHazardous.toFixed(2),
                                other: waste.other.toFixed(2),
                                total: waste.total.toFixed(2),
                                wasteCategory: waste.wasteCategory,
                            };
                            array.push(row);
                            forFacility.push([waste.wasteCategory, waste.hazardous, waste.nonHazardous, waste.other]);
                        });
                    });
                    setWasteGenerationTableData(array);
                    const transformedData = [["Facility Name", "Hazardous", "Non-hazardous", "Other"]];
                    resp.data.facilityDetails.forEach(item => {
                        transformedData.push([item.facilityName, item.facilityTotalHazardousWaste, item.facilityTotalNonHazardousWaste, item.facilityTotalOtherWaste])
                    })
                    if (userDetails.roles.includes("ROLE_CORPORATE_EDITOR")) {
                        setWasteGenerationChartData(transformedData);
                    } else {
                        setWasteGenerationChartData(forFacility);
                    };
                } else {
                    setWasteGenerationChartData(null);
                    setWasteGenerationTableData([]);
                }
                setColumnChartLoading(false);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data,
                    })
                };
            })
    };

    const getEnergyConsumptionWithinOrgReportingData = (filterData) => {
        const payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: filterData?.facilityRefNo?.length > 0 ? filterData.facilityRefNo.join(',') : "",
            dataRangeDetails: filterData ? filterData.dataRangeDetails : 0,
            dataRange: filterData ? filterData.dataRange : "YEARLY",
            yearList: filterData ? filterData.yearList : [Number(currentYear)]
        }
        getEnergyConsumptionWithinOrgReportData(payload)
            .then((resp) => {
                const forCorporate = [
                    [
                        "Year",
                        "Non Renewable",
                        { role: "annotation" },
                        "Renewable",
                        { role: "annotation" }
                    ]
                ];
                const forFacility = [
                    [
                        "Facility",
                        "Non Renewable",
                        { role: "annotation" },
                        "Renewable",
                        { role: "annotation" },
                    ]
                ];
                if (resp.data.facilityData) {
                    resp.data.facilityData.forEach(yearData => {
                        if (yearData.value?.facilityDetails) {
                            // for chart
                            if (filterData?.facilityRefNo.length) {
                                yearData.value.facilityDetails.forEach(facility => {
                                    const chartData = [
                                        facility.facilityName + " " + " " + `(${yearData.year.toString()})`,
                                        facility.totalNonRenewable,
                                        facility.totalNonRenewable,
                                        facility.totalRenewable,
                                        facility.totalRenewable
                                    ];
                                    forFacility.push(chartData)
                                    setEnergyAndFuelConsumptionWithinOrgReportData(forFacility)
                                })
                            } else {
                                const chartData = [
                                    yearData.year.toString(),
                                    yearData.value.totalNonRenewable,
                                    yearData.value.totalNonRenewable,
                                    yearData.value.totalRenewable,
                                    yearData.value.totalRenewable
                                ];
                                forCorporate.push(chartData);
                                setEnergyAndFuelConsumptionWithinOrgReportData(forCorporate)
                            }
                            //for fuel table
                            const fuelTableData = [];
                            yearData.fuelData.facilityDetails.forEach(facilities => {
                                const mappingDataForFuel = [];
                                facilities.value.forEach(fuelSource => {
                                    const typeOfSource = fuelSource.typeOfSource;
                                    fuelSource.data.forEach(fuel => {
                                        const fuelName = fuel.fuelName;
                                        fuel.data.forEach(fuelData => {
                                            const row = {
                                                typeOfSource,
                                                fuelName,
                                                "quantity": fuelData.quantity?.toFixed(2),
                                                "standardDensity": fuelData.standardDensity,
                                                "purposeOfUse": fuelData.purposeOfUse,
                                                "unitConversion": fuelData.unitConversion,
                                                "grossCalorificValue": fuelData.grossCalorificValue,
                                                "quantityOfMaterials": fuelData.quantityOfMaterials?.toFixed(4),
                                                "energyUsesFromFuel": fuelData.energyUsesFromFuel?.toFixed(4)
                                            }
                                            mappingDataForFuel.push(row)
                                        })
                                    })
                                    if (fuelSource.typeOfSource === "Renewable") {
                                        mappingDataForFuel.push({
                                            typeOfSource: "Total Renewable",
                                            energyUsesFromFuel: fuelSource.totalEnergyFromFuel?.toFixed(4)
                                        });
                                    } else {
                                        mappingDataForFuel.push({
                                            typeOfSource: "Total Non Renewable",
                                            energyUsesFromFuel: fuelSource.totalEnergyFromFuel?.toFixed(4)
                                        });
                                    }
                                })
                                fuelTableData.push({ year: yearData.year, facilityName: facilities.facilityName, rowData: mappingDataForFuel });
                            });
                            //for energy table
                            const energyTableData = [];
                            const facilityWiseDerivedData = [];
                            const deriveData = [];
                            yearData.value.facilityDetails.forEach(facilities => {
                                const mappingDataForEnergy = [];
                                const facilityWiseRenewableAndNonRenewableData = [];
                                facilities.data.forEach(energySource => {
                                    const sourceType = energySource.sourceType;
                                    energySource.data.forEach(energyPurpose => {
                                        const purposeOfUse = energyPurpose.purposeOfUse;
                                        energyPurpose.data.forEach(energyCategory => {
                                            const sourceCategory = energyCategory.sourceCategory;
                                            energyCategory.data.forEach(energyData => {
                                                const row = {
                                                    sourceType,
                                                    purposeOfUse,
                                                    sourceCategory,
                                                    "firstUnitConversion": energyData.firstUnitConversion?.toFixed(4),
                                                    "unit": energyData.unit,
                                                    "quantity": energyData.quantity?.toFixed(2)
                                                }
                                                mappingDataForEnergy.push(row)
                                            })
                                        })
                                    })
                                })
                                mappingDataForEnergy.push({
                                    purposeOfUse: "Total Renewable",
                                    firstUnitConversion: facilities.totalRenewableEnergy?.toFixed(4)
                                });
                                mappingDataForEnergy.push({
                                    purposeOfUse: "Total Non Renewable",
                                    firstUnitConversion: facilities.totalNonRenewableEnergy?.toFixed(4)
                                });
                                facilityWiseRenewableAndNonRenewableData.push({
                                    sourceType: "Total Renewable",
                                    totalQuantityEnergy: facilities.totalRenewableEnergy?.toFixed(4),
                                    totalQuantityFuel: facilities.totalRenewableFuel?.toFixed(4),
                                    totalQuantity: facilities.totalRenewable?.toFixed(2)
                                });
                                facilityWiseRenewableAndNonRenewableData.push({
                                    sourceType: "Total Non Renewable",
                                    totalQuantityEnergy: facilities.totalNonRenewableEnergy?.toFixed(4),
                                    totalQuantityFuel: facilities.totalNonRenewableFuel?.toFixed(4),
                                    totalQuantity: facilities.totalNonRenewable?.toFixed(2)
                                });
                                energyTableData.push({ year: yearData.year, facilityName: facilities.facilityName, rowData: mappingDataForEnergy });
                                facilityWiseDerivedData.push({ year: yearData.year, facilityName: facilities.facilityName, rowData: facilityWiseRenewableAndNonRenewableData });
                                deriveData.push({ year: yearData.year, totalNonRenewable: facilities.totalNonRenewable?.toFixed(2), totalRenewable: facilities.totalRenewable?.toFixed(2) });
                            })
                            const energyAndFuelConsumptionTableData = [
                                { columnName: fuelConsumptionTableColumns, tableData: fuelTableData, tableName: "Energy Use from Fuel in GJ", dataType: "nested" },
                                { columnName: energyConsumptionTableColumns, tableData: energyTableData, tableName: "Energy Consumption in GJ", dataType: "nested" },
                                { columnName: energyAndFuelConsumptionFacilityWiseColumns, tableData: facilityWiseDerivedData, tableName: "Facility Wise Derived", dataType: "nested" },
                                { columnName: energyAndFuelDerivedColumns, tableData: deriveData, tableName: "Energy Consumption (Electricity & Fuel) Derived", dataType: "normal" },
                            ]
                            setEnergyAndFuelConsumptionWithinOrgTableData(energyAndFuelConsumptionTableData);
                        }
                        //  else {
                        //     setEnergyAndFuelConsumptionWithinOrgReportData(null);
                        //     setEnergyAndFuelConsumptionWithinOrgTableData([])
                        // }
                    })
                }

            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data,
                    })
                };
            })
    };

    const getAirPointSourceReportingData = (facilities, year) => {
        setIsLoading(true);
        const forFacility = [["Pollutant Name", "Load"]];
        let array = [];
        let payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityList: facilities?.length > 0 ? facilities.join(',') : null,
            YearList: year
        }
        getAirPointSourceReportData(payload)
            .then((resp) => {
                if (resp.data.facilityDetails) {
                    resp.data.facilityDetails.forEach(facility => {
                        const facilityName = facility.facilityName;
                        facility.pollutantData.forEach(pollutantData => {
                            const sourceId = pollutantData.sourceId;
                            pollutantData.pollutantInfo.forEach(info => {
                                const row = {
                                    facilityName,
                                    sourceId,
                                    stackHeight: info.stackHeight,
                                    stackDiameter: info.stackDiameter,
                                    stackVelocity: info.stackVelocity,
                                    pollutantName: info.pollutantName,
                                    concentration: info.concentration,
                                    rate: info.rate.toFixed(2),
                                    load: info.load.toFixed(2)
                                };
                                array.push(row);
                                forFacility.push([info.pollutantName, info.load])
                            });
                        });
                    });
                };
                setAirEmissionTableData(array)
                const pollutants = ["PM10", "NOx", "SOx", "CO", "VOC", "CH4", "POP", "HAP", "Other Pollutant (Specify)"];
                // const result = [
                //     ["Facility Name", ...pollutants]
                // ];
                // const getLoadByPollutant = (loads, name) => {
                //     const load = loads.find(l => l.pollutantName === name);
                //     return load ? load.load : 0;
                // };
                // if (resp.data.facilityDetails) {
                //     resp.data.facilityDetails.forEach(facility => {
                //         const row = [facility.facilityName];
                //         pollutants.forEach(pollutant => {
                //             const load = getLoadByPollutant(facility.pollutionLoad, pollutant);
                //             row.push(load);
                //         });
                //         result.push(row);
                //     });
                // };
                const result = [
                    ["Facility Name", ...pollutants.flatMap(p => [p, { role: "annotation" }])]
                ];
                const getLoadByPollutant = (loads, name) => {
                    const load = loads.find(l => l.pollutantName === name);
                    return load ? load.load : 0;
                };
                if (resp.data.facilityDetails) {
                    resp.data.facilityDetails.forEach(facility => {
                        const row = [facility.facilityName];
                        pollutants.forEach(pollutant => {
                            const load = getLoadByPollutant(facility.pollutionLoad, pollutant);
                            row.push(load, load); // Add pollutant value and annotation
                        });
                        result.push(row);
                    });
                }
                if (userDetails.roles.includes("ROLE_CORPORATE_EDITOR")) {
                    setAirEmissionChartData(result);
                } else {
                    setAirEmissionChartData(forFacility);
                };
                // setTimeout(() => {
                setIsLoading(false);
                // }, 2000);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data,
                    })
                };
            })
    };

    const getWasteRecoveryAndDisposalReportingData = (facilities, year) => {
        const derivedArray = [];
        const recoveryArray = [];
        const disposalArray = [];
        setLoadingPieChart(true);
        let payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityList: facilities?.length > 0 ? facilities.join(',') : null,
            YearList: year
        }
        Promise.all([getWasteDerivedReportData(payload), getWasteRecoveryReportData(payload), getWasteDisposalReportData(payload)])
            .then((resp) => {
                const derivedData = resp[0].data;
                const recoveryData = resp[1].data;
                const disposalData = resp[2].data;
                if (derivedData.corporateName !== null) {
                    const transformedData = [["Issue Name", "Percentage"]];
                    if (derivedData.totalWasteRecovered > 0) {
                        transformedData.push(["Waste Recovered", derivedData.totalWasteRecovered])
                        transformedData.push(["Waste Disposed", derivedData.totalWasteDisposed])
                        transformedData.push(["Waste Recycled", Number(derivedData.totalWasteGenerated) - (Number(derivedData.totalWasteRecovered) + Number(derivedData.totalWasteDisposed))])
                    };
                    setWasteRecoveryAndDisposalChartData(transformedData);
                    if (derivedData.facilityDetails) {
                        const total = {
                            facilityName: "Total",
                            facilityWasteGeneratedTotal: derivedData.totalWasteGenerated.toFixed(2),
                            facilityWasteRecoveredTotal: derivedData.totalWasteRecovered.toFixed(2),
                            facilityWasteDisposedTotal: derivedData.totalWasteDisposed.toFixed(2)
                        };
                        derivedData.facilityDetails.forEach(facilities => {
                            const row = {
                                facilityName: facilities.facilityName,
                                facilityWasteGeneratedTotal: facilities.facilityWasteGeneratedTotal.toFixed(2),
                                facilityWasteRecoveredTotal: facilities.facilityWasteRecoveredTotal.toFixed(2),
                                facilityWasteDisposedTotal: facilities.facilityWasteDisposedTotal.toFixed(2)
                            };
                            derivedArray.push(row);
                        });
                        derivedArray.push(total);
                    };
                    // if (recoveryData.corporateName) {
                    //     let totalOffSite = 0;
                    //     let totalTotal = 0;
                    //     let totalOnSite = 0;
                    //     let facilityName = "Total",
                    //         recoveryData.facilityWiseData.forEach(facilities => {
                    //             facilities.breakDown.forEach(data => {
                    //                 totalOffSite += data.offSite;
                    //                 totalTotal += data.total;
                    //                 totalOnSite += data.onSite;
                    //             })
                    //         })
                    // };
                    if (recoveryData.corporateName) {
                        recoveryData.facilityWiseData.forEach(facilities => {
                            const facilityName = facilities.facilityName;
                            facilities.breakDown.forEach(data => {
                                const row = {
                                    facilityName,
                                    ...data
                                };
                                recoveryArray.push(row);
                            })
                        })
                    };
                    if (disposalData.corporateName) {
                        disposalData.facilityWiseData.forEach(facilities => {
                            const facilityName = facilities.facilityName;
                            facilities.breakDown.forEach(data => {
                                const row = {
                                    facilityName,
                                    ...data
                                };
                                disposalArray.push(row);
                            })
                        })
                    };
                    const wasteTableData = [
                        { columnName: tableHeaderContent[6].wasteDerivedTableColumns, tableData: derivedArray, tableName: "Derived Data" },
                        { columnName: tableHeaderContent[7].wasteRecoveryTableColumns, tableData: recoveryArray, tableName: "Waste Recovery" },
                        { columnName: tableHeaderContent[8].wasteDisposalTableColumns, tableData: disposalArray, tableName: "Waste disposal in Metric Tons" }
                    ];
                    setWasteDerivedTableData(wasteTableData);
                };
                // setTimeout(() => {
                setLoadingPieChart(false);
                // }, 2000);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data,
                    })
                };
            })
    };

    const getMaterialsReportingData = (facilities, year) => {
        setLoadingGaugeChart(true);
        const array = [];
        let payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityList: facilities?.length > 0 ? facilities.join(',') : null,
            YearList: year
        }
        getMaterialReportData(payload)
            .then((resp) => {
                setMaterialsChartData(resp.data.corporateTotalRenewableMaterialUsedPercentage ? (Number(resp.data.corporateTotalRenewableMaterialUsedPercentage) / 100).toFixed(2) : 0);
                if (resp.data.facilityDetails) {
                    const percentage = {
                        materialName: "Percentage",
                        nonRenewable: resp.data.corporateTotalNonRenewableMaterialUsedPercentage.toFixed(2),
                        renewable: resp.data.corporateTotalRenewableMaterialUsedPercentage.toFixed(2),
                        totalMaterialsUsed: 100
                    };
                    const total = {
                        materialName: "Total",
                        nonRenewable: resp.data.corporateTotalNonRenewableMaterialsUsed.toFixed(2),
                        renewable: resp.data.corporateTotalRenewableMaterialsUsed.toFixed(2),
                        totalMaterialsUsed: resp.data.corporateTotalMaterialsUsed.toFixed(2)
                    };
                    resp.data.facilityDetails.forEach(facility => {
                        const facilityName = facility.facilityName;

                        facility.materialUsedDetails.forEach(material => {
                            const row = {
                                facilityName,
                                materialName: material.materialName,
                                nonRenewable: material.nonRenewable.toFixed(2),
                                renewable: material.renewable.toFixed(2),
                                totalMaterialsUsed: material.totalMaterialsUsed.toFixed(2),
                            };
                            array.push(row);
                        });
                    });
                    array.push(total);
                    array.push(percentage);
                };
                setMaterialTableData(array);
                // setTimeout(() => {
                setLoadingGaugeChart(false);
                // }, 2000);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data,
                    })
                };
            })
    };

    // GHG Scope-1

    const transformDataForChart = (data) => {
        // Get unique years
        const years = [...new Set(data.map((entry) => entry.year))].sort((a, b) => a - b);

        // Get all unique facility names
        const facilities = {};
        data.forEach((entry) => {
            if (entry.value.facilityDetails) {
                entry.value.facilityDetails.forEach((facility) => {
                    if (!facilities[facility.facilityName]) {
                        // Initialize with 0s for each year, and a null placeholder for annotations
                        facilities[facility.facilityName] = Array(years.length).fill(0).map(() => [0, null]);
                    }
                    const yearIndex = years.indexOf(entry.year);
                    facilities[facility.facilityName][yearIndex] = [facility.totalGhgQuantityFacilityWise, facility.totalGhgQuantityFacilityWise];
                });
            }
        });

        // Prepare the final data array for the chart
        const chartData = [
            ["Facility Name", ...years.flatMap(year => [year.toString(), { role: "annotation" }])] // Header row
        ];

        // Push facility data into the chart data
        for (const [facilityName, quantities] of Object.entries(facilities)) {
            chartData.push([facilityName, ...quantities.flat()]);
        }
        return chartData;
    };

    function transformData(data) {
        const header = ["Year"];
        const values = ["Year"];

        data.forEach(item => {
            const year = item.year.toString();
            const corporateTotal = item.value.corporateTotalGhgQuantity || 0;

            header.push(year, { role: "annotation" });
            values.push(corporateTotal, corporateTotal);
        });
        return [header, values];
    };

    const getGhgScope1ReportData = (filterData) => {
        let payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: filterData?.facilityRefNo?.length > 0 ? filterData.facilityRefNo.join(',') : "",
            dataRangeDetails: filterData ? filterData.dataRangeDetails : 0,
            dataRange: filterData ? filterData.dataRange : "YEARLY",
            yearList: filterData ? filterData.yearList : [Number(currentYear)]
        }
        getScope1ReportData(payload)
            .then((resp) => {
                const array = [];
                const facilityWiseDerivedData = [];
                if (resp.data.facilityData) {
                    resp.data.facilityData.forEach(yearData => {
                        if (yearData.value?.facilityDetails) {
                            if (filterData?.facilityRefNo.length) {
                                setGhgScope1ChartData(transformDataForChart(resp.data.facilityData))
                            } else {
                                setGhgScope1ChartData(transformData(resp.data.facilityData))
                            }
                            //for Table data
                            yearData.value.facilityDetails.forEach(facility => {
                                const mappingData = [];
                                const facilityWiseTotalData = [];
                                facility.value.forEach(ghgData => {
                                    const typeOfSource = ghgData.typeOfSource
                                    ghgData.data.forEach(ghgDetails => {
                                        const row = {
                                            typeOfSource,
                                            name: ghgDetails.name,
                                            quantity: ghgDetails.quantity?.toFixed(2),
                                            emission_factor: ghgDetails.emission_factor,
                                            gwp: ghgDetails.gwp,
                                            ghgQuantity: ghgDetails.ghgQuantity?.toFixed(2),
                                        }
                                        mappingData.push(row);
                                    })
                                })
                                mappingData.push({
                                    typeOfSource: "Total GHG Scop 1",
                                    ghgQuantity: facility.totalGhgQuantityFacilityWise?.toFixed(2)
                                });
                                facilityWiseTotalData.push({
                                    ghgQuantity: facility.totalGhgQuantityFacilityWise?.toFixed(2)
                                });
                                array.push({ year: yearData.year, facilityName: facility.facilityName, rowData: mappingData });
                                facilityWiseDerivedData.push({ year: yearData.year, facilityName: facility.facilityName, rowData: facilityWiseTotalData })
                            })
                            const ghgScope1TableData = [
                                { columnName: ghgScope1Columns, tableData: array, tableName: "GHG Scope 1", dataType: "nested" },
                                { columnName: ghgScope1FacilityWiseDerivedColumns, tableData: facilityWiseDerivedData, tableName: "Facility Wise Derived", dataType: "nested" },
                            ];
                            setGhgScope1TableData(ghgScope1TableData);
                        }
                        // else {
                        //     setGhgScope1TableData([]);
                        //     setGhgScope1ChartData(null);
                        // }
                    })
                }
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data,
                    })
                };
            })
    };

    //GHG Scope-2

    const transformDataForGHG2Chart = (data) => {
        // Get unique years
        const years = [...new Set(data.map((entry) => entry.year))].sort((a, b) => a - b);

        // Get all unique facility names
        const facilities = {};
        data.forEach((entry) => {
            if (entry.value.facilityDetails) {
                entry.value.facilityDetails.forEach((facility) => {
                    if (!facilities[facility.facilityName]) {
                        // Initialize with [0, null] pairs for each year to include annotations
                        facilities[facility.facilityName] = Array(years.length).fill(0).map(() => [0, null]);
                    }
                    const yearIndex = years.indexOf(entry.year);
                    facilities[facility.facilityName][yearIndex] = [facility.totalQuantityFacilityWise, facility.totalQuantityFacilityWise];
                });
            }
        });

        // Prepare the final data array for the chart
        const chartData = [
            ["Facility Name", ...years.flatMap(year => [year.toString(), { role: "annotation" }])] // Header row with annotations
        ];

        // Push facility data into the chart data
        for (const [facilityName, quantities] of Object.entries(facilities)) {
            chartData.push([facilityName, ...quantities.flat()]);
        }
        return chartData;
    };

    function transformDataForCorporate(data) {
        const header = ["Year"];
        const values = ["Year"];

        data.forEach(item => {
            const year = item.year.toString();
            const corporateTotal = item.value.corporateTotalQuantity || 0;

            header.push(year, { role: "annotation" });
            values.push(corporateTotal, corporateTotal);
        });
        return [header, values];
    };

    const getGhgScope2ReportData = (filterData) => {
        let payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: filterData?.facilityRefNo?.length > 0 ? filterData.facilityRefNo.join(',') : "",
            dataRangeDetails: filterData ? filterData.dataRangeDetails : 0,
            dataRange: filterData ? filterData.dataRange : "YEARLY",
            yearList: filterData ? filterData.yearList : [Number(currentYear)]
        }
        getScope2ReportData(payload)
            .then((resp) => {
                const array = [];
                const facilityWiseDerivedData = [];
                if (resp.data.facilityData) {
                    resp.data.facilityData?.forEach(yearData => {
                        if (yearData.value?.facilityDetails) {
                            //for chart
                            if (filterData?.facilityRefNo.length) {
                                setGhgScope2ChartData(transformDataForGHG2Chart(resp.data.facilityData))
                            } else {
                                setGhgScope2ChartData(transformDataForCorporate(resp.data.facilityData))
                            }
                            //for Table data
                            yearData.value.facilityDetails.forEach(facility => {
                                const mappingData = [];
                                const facilityWiseTotalData = [];
                                facility.value.forEach(ghgData => {
                                    mappingData.push({
                                        activity: ghgData.activity,
                                        quantity: ghgData.quantity?.toFixed(2)
                                    })
                                })
                                mappingData.push({
                                    activity: "Total GHG Scope 2",
                                    quantity: facility.totalQuantityFacilityWise?.toFixed(2)
                                });
                                facilityWiseTotalData.push({
                                    quantity: facility.totalQuantityFacilityWise?.toFixed(2)
                                });
                                array.push({ year: yearData.year, facilityName: facility.facilityName, rowData: mappingData });
                                facilityWiseDerivedData.push({ year: yearData.year, facilityName: facility.facilityName, rowData: facilityWiseTotalData })
                            })
                            const ghgScope1TableData = [
                                { columnName: ghgScope2Columns, tableData: array, tableName: "GHG Scope 2", dataType: "nested" },
                                { columnName: ghgScope2FacilityWiseDerivedColumns, tableData: facilityWiseDerivedData, tableName: "Facility Wise Derived", dataType: "nested" },
                            ];
                            setGhgScope2TableData(ghgScope1TableData);
                        }
                        // else {
                        //     setGhgScope2ChartData(null);
                        //     setGhgScope2TableData([]);
                        // }
                    })
                }
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data,
                    })
                };
            })
    };

    const getWaterDischargeDerivedReportData = (facilities, year) => {
        setLoadingHalfDonutChart(true);
        let payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityList: facilities?.length > 0 ? facilities.join(',') : null,
            YearList: year
        }
        Promise.all([getWaterDerivedReportData(payload), getWaterDischargeData(payload), getWaterWithDrawlData(payload)])
            .then((resp) => {
                const derivedData = resp[0]?.data;
                const dischargeData = resp[1]?.data;
                const withDrawlData = resp[2]?.data;
                const waterDischargePercentage = (Number(derivedData?.corporateTotalDischarge / derivedData?.corporateTotalWithdrawal) * 100).toFixed(2);
                const totalConsumption = [{
                    category: "Total Water Consumption (KL)",
                    totalConsumption: derivedData?.corporateTotalConsumption
                }];
                const derivedTable = [{
                    category: "Percentage",
                    consumptionPercentage: Number(100 - waterDischargePercentage).toFixed(2),
                    dischargePercentage: waterDischargePercentage
                }];
                setWaterAndWasterChartData(derivedData);

                const combinedWithdrawalData = withDrawlData?.facilityDetails?.flatMap(facility => {
                    return facility.waterTypes.flatMap(waterType => {
                        return waterType.sources.map(source => ({
                            facilityName: facility.facilityName,
                            type_of_source: source.type_of_source,
                            totalWaterTypeWithdrawal: source.totalAmount,
                            otherWaterTotal: waterType.type_of_water.includes("Other Water Amount") ? source.totalAmount : 0,
                            freshWaterTotal: waterType.type_of_water.includes("Fresh Water - Amount") ? source.totalAmount : 0,
                            totalWater: 0,  // We will calculate this later
                        }));
                    });
                }).reduce((acc, item) => {
                    const existing = acc?.find(entry => entry.facilityName === item.facilityName && entry.type_of_source === item.type_of_source);
                    if (existing) {
                        existing.totalWaterTypeWithdrawal += item.totalWaterTypeWithdrawal;
                        existing.otherWaterTotal += item.otherWaterTotal;
                        existing.freshWaterTotal += item.freshWaterTotal;
                    } else {
                        acc.push(item);
                    }
                    return acc;
                }, []).map(item => {
                    // Calculate the total water after summing to avoid partial additions
                    item.totalWater = item.otherWaterTotal + item.freshWaterTotal;
                    return item;
                });

                const transformedDischargeData = dischargeData?.facilityDetails?.flatMap(facility => {
                    return facility.outputSources.flatMap(source => {
                        return source.treatmentLevels.flatMap(level => {
                            return level.categories.flatMap(category => {
                                return category.purposes.map(purpose => ({
                                    facilityName: facility.facilityName,
                                    outputSource: source.outputSource,
                                    levelOfTreatment: level.levelOfTreatment,
                                    category: category.category,
                                    dischargePoint: purpose.dischargePoint,
                                    destinationCategory: purpose.destinationCategory,
                                    purposeOfReuseOrRecycle: purpose.purposeOfReuseOrRecycle,
                                    dischargeDestination: purpose.dischargeDestination,
                                    dischargeLocationLat: purpose.dischargeLocationLat,
                                    totalRecycleQuantity: purpose.totalRecycleQuantity,
                                    totalDischargeQuantity: purpose.totalDischargeQuantity,
                                    dischargeDestinationType: purpose.dischargeDestinationType,
                                    dischargeLocationLong: purpose.dischargeLocationLong
                                }));
                            });
                        });
                    });
                });
                const waterAndWasterWaterTableData = [
                    { columnName: tableHeaderContent[11].waterWithDrawlTableColumns, tableData: combinedWithdrawalData, tableName: "Water withdrawal In KL/month" },
                    { columnName: tableHeaderContent[12].waterDischargeTableColumns, tableData: transformedDischargeData, tableName: "Water Discharge-Recycle Or Reuse & Effluent  Destination In KL" },
                    { columnName: tableHeaderContent[13].totalConsumptionTableColumn, tableData: totalConsumption, tableName: "Total Water Consumption" },
                    { columnName: tableHeaderContent[14].percentageTableColumn, tableData: derivedTable, tableName: "Derived Data" },
                ];
                setWaterAndWasterData(waterAndWasterWaterTableData);
                setLoadingHalfDonutChart(false);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data,
                    })
                };
            })
    };

    const refreshData = () => {
        setFilterData(null);
        const facilities = userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? null : [userDetails.facilityRefNo];
        getWasteGenerationReportingData(facilities, yearData.$y);
        getWasteRecoveryAndDisposalReportingData(facilities, yearData.$y);
        getEnergyConsumptionWithinOrgReportingData();
        getAirPointSourceReportingData(facilities, yearData.$y);
        getMaterialsReportingData(facilities, yearData.$y);
        getGhgScope1ReportData();
        getWaterDischargeDerivedReportData(facilities, yearData.$y);
        getGhgScope2ReportData();
        localStorage.setItem("year", yearData.$y);
    };

    const handleMenu = () => {
        setOpenFilterDialog({ action: true, userData: userDetails });
    };

    const getFilterData = (filterData) => {
        setFilterData(filterData);
        getAirPointSourceReportingData(filterData.facilityRefNo, filterData?.yearList[0]);
        getMaterialsReportingData(filterData.facilityRefNo, filterData?.yearList[0]);
        getWasteGenerationReportingData(filterData.facilityRefNo, filterData?.yearList[0]);
        getEnergyConsumptionWithinOrgReportingData(filterData);
        getWasteRecoveryAndDisposalReportingData(filterData.facilityRefNo, filterData?.yearList[0]);
        getGhgScope1ReportData(filterData);
        getGhgScope2ReportData(filterData);
        getWaterDischargeDerivedReportData(filterData.facilityRefNo, filterData?.yearList[0]);
        localStorage.setItem("year", filterData?.yearList[0]);
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 1, mt: 7 }}>
                    <div className="row p-2">
                        <div className="col-lg-10">
                            <span><material.Typography variant="h5" className='text-capitalize'>{userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? `Environment Dashboard : ${userDetails.corporateName}` : `Environment Dashboard : ${userDetails.facilityName}`}</material.Typography></span>
                        </div>
                        <div className="col-lg-2">
                            <div className='float-end'>
                                <span>
                                    <material.IconButton
                                        aria-label="more"
                                        id="long-button"
                                        onClick={handleMenu}
                                        title='Filter'
                                        color='info'
                                    >
                                        <img
                                            src={FilterImage}
                                            height={25}
                                            width={25}
                                        />
                                    </material.IconButton>
                                </span>
                                <span>
                                    <material.IconButton title='Refresh' color='success' onClick={refreshData}>
                                        <material.RefreshIcon />
                                    </material.IconButton>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 mt-3">
                            <material.Card elevation={4} sx={{ p: 1 }} className='column-chart'>
                                <div>
                                    <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                        {userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? (
                                            <span>
                                                <FilterComponent
                                                    userDetails={userDetails}
                                                    applyFilterByFacility={getWasteGenerationReportingData}
                                                    year={year}
                                                />
                                            </span>
                                        ) : null}
                                        <span>
                                            <MoreOptionMenu
                                                chartName="column-chart"
                                                columnChartData={wasteGenerationChartData}
                                                options={wasteGenerationOptions}
                                                tableData={wasteGenerationTableData}
                                                year={year}
                                                columnName={tableHeaderContent[1].wasteGenerationTableColumns}
                                                tableHeading="Waste Generation in Metric Tons"
                                            />
                                        </span>
                                    </div>
                                    <ColumnChart
                                        columnChartData={wasteGenerationChartData}
                                        options={wasteGenerationOptions}
                                    />
                                </div>
                            </material.Card>
                        </div>
                        <div className="col-lg-4 mt-3">
                            <material.Card elevation={4} sx={{ p: 1 }} className='column-chart'>
                                <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                    {userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? (
                                        <span>
                                            <FilterComponent
                                                userDetails={userDetails}
                                                applyFilterByFacility={getEnergyConsumptionWithinOrgReportingData}
                                                year={year}
                                            />
                                        </span>
                                    ) : null}
                                    <span>
                                        <MoreOptionMenu
                                            chartName="column-chart"
                                            columnChartData={energyAndFuelConsumptionWithinOrgReportData}
                                            options={energyConsumptionOptions}
                                            tableData={energyAndFuelConsumptionWithinOrgTableData}
                                            tableHeading="Energy Consumption Within Organization Electricity (GJ)"
                                            callFrom="multiple-table-GHG"
                                            dialogTitle="Energy Consumption and Fuel Consumption Reporting Data"
                                        />
                                    </span>
                                </div>
                                <ColumnChart
                                    columnChartData={energyAndFuelConsumptionWithinOrgReportData}
                                    options={energyConsumptionOptions}
                                />
                            </material.Card>
                        </div>

                        <div className="col-lg-4 mt-3">
                            <material.Card elevation={4} sx={{ p: 1 }} className='column-chart'>
                                <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                    {userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? (
                                        <span>
                                            <FilterComponent
                                                userDetails={userDetails}
                                                applyFilterByFacility={getGhgScope1ReportData}
                                                filterData={filterData}
                                            />
                                        </span>
                                    ) : null}
                                    <span>
                                        <MoreOptionMenu
                                            chartName="column-chart"
                                            columnChartData={ghgScope1ChartData}
                                            options={ghgEmission}
                                            tableData={ghgScope1TableData}
                                            callFrom="multiple-table-GHG"
                                            dialogTitle="GHG Scope 1 Reporting Data"
                                        />
                                    </span>
                                </div>
                                <ColumnChart
                                    columnChartData={ghgScope1ChartData}
                                    options={ghgEmission}
                                />
                            </material.Card>
                        </div>
                        <div className="col-lg-4 mt-3">
                            <material.Card elevation={4} sx={{ p: 1 }} className='column-chart'>
                                <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                    {userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? (
                                        <span>
                                            <FilterComponent
                                                userDetails={userDetails}
                                                applyFilterByFacility={getGhgScope2ReportData}
                                                filterData={filterData}
                                            />
                                        </span>
                                    ) : null}
                                    <span>
                                        <MoreOptionMenu
                                            chartName="column-chart"
                                            columnChartData={ghgScope2ChartData}
                                            options={ghgScope2Option}
                                            tableData={ghgScope2TableData}
                                            callFrom="multiple-table-GHG"
                                            dialogTitle="GHG Scope 2 Reporting Data"
                                        />
                                    </span>
                                </div>
                                <ColumnChart
                                    columnChartData={ghgScope2ChartData}
                                    options={ghgScope2Option}
                                />
                            </material.Card>
                        </div>
                        <div className="col-lg-4 mt-3">
                            <material.Paper elevation={4} sx={{ p: 1 }} className='column-chart'>
                                <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                    {userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? (
                                        <span>
                                            <FilterComponent
                                                applyFilterByFacility={getWasteRecoveryAndDisposalReportingData}
                                                userDetails={userDetails}
                                                year={year}
                                            />
                                        </span>
                                    ) : null}
                                    <span>
                                        <MoreOptionMenu
                                            chartName="pie-chart"
                                            pieChartData={wasteRecoveryAndDisposalChartData}
                                            options={wasteRecoveryAndDisposalOptions}
                                            tableData={wasteDerivedTableData}
                                            year={year}
                                            callFrom="multiple-table"
                                        />
                                    </span>
                                </div>
                                <PieChart
                                    pieChartData={wasteRecoveryAndDisposalChartData}
                                    options={wasteRecoveryAndDisposalOptions}
                                />
                            </material.Paper>
                        </div>
                        <div className="col-lg-4 mt-3">
                            <material.Card elevation={4} sx={{ p: 1 }} className='column-chart'>
                                <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                    {userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? (
                                        <span>
                                            <FilterComponent
                                                userDetails={userDetails}
                                                year={year}
                                                applyFilterByFacility={getAirPointSourceReportingData}
                                            />
                                        </span>
                                    ) : null}
                                    <span>
                                        <MoreOptionMenu
                                            chartName="combo-chart"
                                            chartData={airEmissionChartData}
                                            options={airEmissionOptions}
                                            tableData={airEmissionTableData}
                                            columnName={tableHeaderContent[0].airEmissionTableColumns}
                                            year={year}
                                            tableHeading="Air Emission Point Source"
                                        />
                                    </span>
                                </div>
                                {/* {isLoading ? (
                                    <BarsLoader />
                                ) : ( */}
                                <ComboChart
                                    chartData={airEmissionChartData}
                                    options={airEmissionOptions}
                                />
                                {/* )} */}
                            </material.Card>
                        </div>
                        <div className="col-lg-4 mt-3">
                            <material.Card elevation={4} sx={{ p: 1 }} className='column-chart'>
                                <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                    {userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? (
                                        <span>
                                            <FilterComponent
                                                userDetails={userDetails}
                                                applyFilterByFacility={getMaterialsReportingData}
                                                year={year}
                                            />
                                        </span>
                                    ) : null}
                                    <span>
                                        <MoreOptionMenu
                                            chartName="gauge-chart"
                                            chartData={materialsChartData}
                                            // options={wasteGenerationOptions}
                                            tableData={materialTableData}
                                            year={year}
                                            columnName={tableHeaderContent[4].materialTableColumns}
                                            tableHeading="Material Use in Metric Tons"
                                        />
                                    </span>
                                </div>
                                {loadingGaugeChart ? (
                                    <BarsLoader />
                                ) : (
                                    <SpeedometerChart
                                        chartData={materialsChartData}
                                    />
                                )}
                            </material.Card>
                        </div>
                        <div className="col-lg-8 mt-3">
                            <material.Card elevation={4} sx={{ p: 1 }} className='column-chart'>
                                <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                    {userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? (
                                        <span>
                                            <FilterComponent
                                                userDetails={userDetails}
                                                applyFilterByFacility={getWaterDischargeDerivedReportData}
                                                year={year}
                                            />
                                        </span>
                                    ) : null}
                                    <span>
                                        <MoreOptionMenu
                                            chartName="half-donut-chart"
                                            chartData={waterAndWasterChartData}
                                            tableData={waterAndWasterData}
                                            year={year}
                                            callFrom="multiple-table"
                                        />
                                    </span>
                                </div>
                                {loadingHalfDonutChart ? (
                                    <BarsLoader />
                                ) : (
                                    <WaterReportingChart
                                        chartData={waterAndWasterChartData}
                                    />
                                )}
                            </material.Card>
                        </div>
                    </div>
                    {/* )} */}

                </material.Box>
            </material.Box>
            <GlobalFilter
                openFilterDialog={openFilterDialog}
                setOpenFilterDialog={setOpenFilterDialog}
                getFilterData={getFilterData}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export const MoreOptionMenu = (props) => {

    const { chartName, columnChartData, pieChartData, options, lineChartData, year, columnName, tableData, chartData, tableHeading, callFrom, dialogTitle } = props;
    const [choseOption, setChoseOption] = React.useState(null);
    const [openFullScreenChartDialog, setOpenFullScreenChartDialog] = useState({ action: false, chartData: null, chartName: null });
    const [openTableWidgetDialog, setOpenTableWidgetDialog] = useState({ action: false, tableData: [] });
    // const [openTable, setOpenTable] = useState({ action: false, tableData: [] });

    const handleMenu = (event) => {
        setChoseOption(event.currentTarget);
    };

    const handleClose = () => {
        setChoseOption(null);
    };

    const fullScreenColumnChart = () => {
        setChoseOption(null);
        setOpenFullScreenChartDialog({ action: true, chartData: chartData, chartName: chartName })
    };

    const viewChartData = () => {
        setChoseOption(null);
        setOpenTableWidgetDialog({ action: true, tableData: tableData });
    };

    return (
        <div>
            <material.IconButton
                aria-label="more"
                id="long-button"
                aria-controls={choseOption ? 'long-menu' : undefined}
                aria-expanded={choseOption ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleMenu}
                title='More'
                color='info'
            >
                <material.TuneIcon color='info' />
            </material.IconButton>
            <material.Menu
                id="menu-appbar"
                anchorEl={choseOption}
                keepMounted
                open={Boolean(choseOption)}
                onClose={handleClose}
            >
                <material.MenuItem className="nav-content" onClick={fullScreenColumnChart}><material.FullscreenIcon sx={{ mr: 1 }} color='info' />View Full Screen</material.MenuItem>
                <material.MenuItem className="nav-content" onClick={viewChartData}><material.VisibilityIcon sx={{ mr: 1 }} color='info' />View Chart Data</material.MenuItem>
                {/* <material.MenuItem className="nav-content"><material.DownloadIcon sx={{ mr: 1 }} color='info' />Download Data</material.MenuItem> */}
            </material.Menu>
            <FullScreenChartDialog
                openFullScreenChartDialog={openFullScreenChartDialog}
                setOpenFullScreenChartDialog={setOpenFullScreenChartDialog}
                columnChartData={columnChartData}
                pieChartData={pieChartData}
                options={options}
                lineChartData={lineChartData}
            />
            {/* <TableWidgetDialog
                openTableWidgetDialog={openTableWidgetDialog}
                setOpenTableWidgetDialog={setOpenTableWidgetDialog}
                year={year}
                columnName={columnName}
                tableHeading={tableHeading}
            /> */}
            {callFrom === "multiple-table" ? (
                <MultipleTable
                    openTableWidgetDialog={openTableWidgetDialog}
                    setOpenTableWidgetDialog={setOpenTableWidgetDialog}
                    year={year}
                />
            ) : callFrom === "multiple-table-GHG" ? (
                <ReportingTable
                    openTableWidgetDialog={openTableWidgetDialog}
                    setOpenTableWidgetDialog={setOpenTableWidgetDialog}
                    dialogTitle={dialogTitle}
                />
            ) : (
                <TableWidgetDialog
                    openTableWidgetDialog={openTableWidgetDialog}
                    setOpenTableWidgetDialog={setOpenTableWidgetDialog}
                    year={year}
                    columnName={columnName}
                    tableHeading={tableHeading}
                />
            )}

        </div>
    )
};

export const FilterComponent = (props) => {

    const { userDetails, year, applyFilterByFacility, filterData } = props;
    const [choseOption, setChoseOption] = React.useState(null);
    const [facilityName, setFacilityName] = useState([]);
    const [selectedFacilities, setSelectedFacilities] = useState([]);

    useEffect(() => {
        getFacilityByCorporateRefNo()
    }, []);

    const getFacilityByCorporateRefNo = () => {
        getSitesByCorporateRefNo(userDetails.corporateRefNo)
            .then((resp) => {
                setFacilityName(resp.data)
            })
            .catch((error) => {

            })
    };

    const handleMenu = (event) => {
        setChoseOption(event.currentTarget);
    };

    const handleClose = () => {
        setChoseOption(null);
        setSelectedFacilities([]);
    };

    const selectFacility = (refNo) => {
        setSelectedFacilities((prevSelected) => {
            const isSelected = prevSelected.includes(refNo);
            if (isSelected) {
                return prevSelected.filter(id => id !== refNo);
            } else {
                return [...prevSelected, refNo];
            }
        });
        // if (facilityRefNo.includes(refNo)) {
        //     // facilityRefNo.pop(value)
        //     facilityRefNo = facilityRefNo.filter(item => item !== refNo);
        // } else {
        //     facilityRefNo.push(refNo)
        // };
    };

    const applyFilter = () => {
        const payload = {
            facilityRefNo: selectedFacilities,
            dataRangeDetails: filterData ? filterData.dataRangeDetails : 0,
            dataRange: filterData ? filterData.dataRange : "YEARLY",
            yearList: filterData ? filterData.yearList : [Number(currentYear)],
        }
        applyFilterByFacility(payload);
        setSelectedFacilities([]);
        setChoseOption(null);
    };

    return (
        <div>
            <material.IconButton
                aria-label="more"
                id="long-button"
                aria-controls={choseOption ? 'long-menu' : undefined}
                aria-expanded={choseOption ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleMenu}
                title='Filter by Facility Name'
                color='info'
            >
                {/* <material.FilterAltIcon /> */}
                <img
                    src={FilterImage}
                    height={25}
                    width={25}
                />
            </material.IconButton>
            <material.Menu
                id="menu-appbar"
                anchorEl={choseOption}
                keepMounted
                open={Boolean(choseOption)}
                onClose={handleClose}
            >
                {facilityName.map((facility, k) => (
                    <material.MenuItem className="nav-content" key={k}>
                        <material.FormGroup>
                            <material.FormControlLabel required control={<material.Checkbox checked={selectedFacilities.includes(facility.refNo)} onClick={() => selectFacility(facility.refNo)} color='secondary' />} label={facility.assetName} />
                        </material.FormGroup>
                    </material.MenuItem>
                ))}
                <material.MenuItem className='float-end'>
                    <span>
                        <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.CloseIcon />} onClick={handleClose}>Cancel</material.Button>
                        <material.Button variant="outlined" color='info' sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} disabled={!selectedFacilities.length} onClick={applyFilter}>Apply</material.Button>
                    </span>
                </material.MenuItem>
            </material.Menu>
        </div>
    )
}

export default Dashboard;