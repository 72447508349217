import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateSecurityPracticesData } from '../../services/sub-issue-services/human-rights-services/SecurityPracticesService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddSecurityPractices(props) {
    const { addOpenSecurityPractices, setAddOpenSecurityPractices, getSecurityPracticesAllData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const { register, handleSubmit, reset, control, resetField, watch, getValues, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    const onChangeTotalNumberOfSecurityPersonnelFacility = watch('total_number_of_security_personnel_facility');
    const onChangeReceivedTrainingOnHumanRightsFacility = watch('received_training_on_human_rights_facility');
    const onChangeSupplyChainAssessedThirdParty = watch('total_number_of_security_personnel_third_party');
    const onChangeReceivedTrainingOnHumanRightsThirdParty = watch('received_training_on_human_rights_third_party');

    useEffect(() => {
        totalCalculation();
    }, [onChangeTotalNumberOfSecurityPersonnelFacility, onChangeReceivedTrainingOnHumanRightsFacility]);

    useEffect(() => {
        totalSecurityThirdPartyValue();
    }, [onChangeReceivedTrainingOnHumanRightsThirdParty, onChangeSupplyChainAssessedThirdParty]);

    const totalCalculation = () => {
        let totalNumberOfSecurityPersonnelFacilityValue = getValues('total_number_of_security_personnel_facility');
        let receivedTrainingOnHumanRightsFacilityValue = getValues('received_training_on_human_rights_facility');

        if (Number(receivedTrainingOnHumanRightsFacilityValue) > Number(totalNumberOfSecurityPersonnelFacilityValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Nos. of security personnel received training on human rights facility value not greater than total number of security personnel(facility) value",
            })
            setButtonDisable(true)
        } else {
            // setOpenSnackBar({
            //     "action": true,
            //     "type": "warning",
            //     "message": "Nos. of security personnel received training on human rights facility value not greater than total number of security personnel(facility) value",
            // })
            setButtonDisable(false)
        }
    };

    const totalSecurityThirdPartyValue = () => {
        let supplyChainAssessedThirdPartyValue = getValues('total_number_of_security_personnel_third_party');
        let receivedTrainingOnHumanRightsThirdPartyValue = getValues('received_training_on_human_rights_third_party');

        if (Number(receivedTrainingOnHumanRightsThirdPartyValue) > Number(supplyChainAssessedThirdPartyValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Nos. of security personnel received training on human rights facility value not greater than total number of security personnel (third party) value",
            })
            setButtonDisable(true)
        } else {
            // setOpenSnackBar({
            //     "action": true,
            //     "type": "warning",
            //     "message": "Nos. of security personnel received training on human rights facility value not greater than total number of security personnel (third party) value",
            // })
            setButtonDisable(false)
        }
    };

    useEffect(() => {
        if (addOpenSecurityPractices.action && addOpenSecurityPractices.securityPracticesTableData) {
            reset(addOpenSecurityPractices.securityPracticesTableData);
        }
    }, [addOpenSecurityPractices.action === true]);

    const handleClose = () => {
        reset({ total_number_of_security_personnel_facility: "", received_training_on_human_rights_facility: "", total_number_of_security_personnel_third_party: "", received_training_on_human_rights_third_party: "", })
        setAddOpenSecurityPractices({ action: false, securityPracticesTableData: null });
    };

    const saveAddSecurityPractices = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "total_number_of_security_personnel_facility": Number(data.total_number_of_security_personnel_facility),
                "received_training_on_human_rights_facility": Number(data.received_training_on_human_rights_facility),
                "total_number_of_security_personnel_third_party": Number(data.total_number_of_security_personnel_third_party),
                "received_training_on_human_rights_third_party": Number(data.received_training_on_human_rights_third_party),
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(object, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getSecurityPracticesAllData(getFilterDate, payloadData);
                    reset({ total_number_of_security_personnel_facility: "", received_training_on_human_rights_facility: "", total_number_of_security_personnel_third_party: "", received_training_on_human_rights_third_party: "", })
                    setAddOpenSecurityPractices({ action: false, securityPracticesTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateAddSecurityPractices = async (data) => {
        const refNo = addOpenSecurityPractices.securityPracticesTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let object = {
            "total_number_of_security_personnel_facility": Number(data.total_number_of_security_personnel_facility),
            "received_training_on_human_rights_facility": Number(data.received_training_on_human_rights_facility),
            "total_number_of_security_personnel_third_party": Number(data.total_number_of_security_personnel_third_party),
            "received_training_on_human_rights_third_party": Number(data.received_training_on_human_rights_third_party),
        }
        await updateSubIssueData(object, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getSecurityPracticesAllData(getFilterDate, payloadData);
                reset({ total_number_of_security_personnel_facility: "", received_training_on_human_rights_facility: "", total_number_of_security_personnel_third_party: "", received_training_on_human_rights_third_party: "", });
                setAddOpenSecurityPractices({ action: false, securityPracticesTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: addOpenSecurityPractices.securityPracticesTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                getSecurityPracticesAllData(getFilterDate, payloadData);
                reset({ total_number_of_security_personnel_facility: "", received_training_on_human_rights_facility: "", total_number_of_security_personnel_third_party: "", received_training_on_human_rights_third_party: "", });
                setAddOpenSecurityPractices({ action: false, securityPracticesTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={addOpenSecurityPractices.action} maxWidth="lg" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{addOpenSecurityPractices.securityPracticesTableData ? "Edit Security Practices" : "Add Security Practices"}</material.DialogTitle>
                <material.DialogContent>
                    <div className='my-3'>
                        {addOpenSecurityPractices.securityPracticesTableData ? null : (
                            <DatePeriod
                                register={register}
                                control={control}
                            />
                        )}
                    </div>
                    <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Security Practices</material.Typography>
                            </div>
                            <div className='col-6'>
                                <material.TextField
                                    {...register("total_number_of_security_personnel_facility", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    className='mt-3'
                                    margin="dense"
                                    label="Total Number of Security Personnel (Facility)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.total_number_of_security_personnel_facility?.type === "required" && 'This field is required'}</p>
                                {errors.total_number_of_security_personnel_facility?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-6'>
                                <material.TextField
                                    {...register("received_training_on_human_rights_facility", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    className='mt-3'
                                    margin="dense"
                                    label="Number of Security Personnel Received Training on Human Rights Facility"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.received_training_on_human_rights_facility?.type === "required" && 'This field is required'}</p>
                                {errors.received_training_on_human_rights_facility?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-6'>
                                <material.TextField
                                    {...register("total_number_of_security_personnel_third_party", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    className='mt-3'
                                    margin="dense"
                                    label="Total Number of Security Personnel (3rd Party)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.total_number_of_security_personnel_third_party?.type === "required" && 'This field is required'}</p>
                                {errors.total_number_of_security_personnel_third_party?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-6'>
                                <material.TextField
                                    {...register("received_training_on_human_rights_third_party", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    className='mt-3'
                                    margin="dense"
                                    label="Number of Security Personnel Received Training on Human Rights (3rd Party)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.received_training_on_human_rights_third_party?.type === "required" && 'This field is required'}</p>
                                {errors.received_training_on_human_rights_third_party?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenSecurityPractices.securityPracticesTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} disabled={buttonDisable} onClick={handleSubmit(updateAddSecurityPractices)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenSecurityPractices.securityPracticesTableData.rejection_note === null) || (addOpenSecurityPractices.securityPracticesTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} disabled={buttonDisable} onClick={handleSubmit(saveAddSecurityPractices)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div >
    )
}

export default AddSecurityPractices; 