import React from 'react';
import { material } from '../../../library/Material';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <material.Slide direction="up" ref={ref} {...props} />;
});

function ReportingTable(props) {

    const { setOpenTableWidgetDialog, openTableWidgetDialog, year, columnName, tableHeading, dialogTitle } = props;

    const handleClose = () => {
        setOpenTableWidgetDialog({ action: false, tableData: [] })
    };

    return (
        <div>
            <material.Dialog open={openTableWidgetDialog.action} maxWidth="xl" fullWidth TransitionComponent={Transition}>
                <material.DialogTitle className='fw-bold'>{dialogTitle}
                    <span className='float-end'>
                        <material.IconButton title='Close' color='error' onClick={handleClose}>
                            <material.CloseIcon sx={{ cursor: "pointer" }} />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    {openTableWidgetDialog.tableData?.map((table, i) => (
                        <material.Paper elevation={3} sx={{ p: 1, boxShadow: "0px 0px 15px 5px rgba(72, 198, 223, 0.3)", mt: 1 }}>
                            <material.TableContainer sx={{ maxHeight: "70vh" }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead>
                                        <material.TableRow>
                                            <material.TableCell sx={{ mb: 3, fontSize: 18 }} colSpan="6">{table.tableName}</material.TableCell></material.TableRow>
                                        <StyledTableCell size='small' hidden={table.dataType === "normal"}>Year</StyledTableCell>
                                        <StyledTableCell size='small' hidden={table.dataType === "normal"}>Facility Name</StyledTableCell>
                                        {table.columnName?.map((column, cInd) =>
                                            <StyledTableCell size='small' key={cInd}>{column.headerName}</StyledTableCell>
                                        )}
                                    </material.TableHead>
                                    <material.TableBody>
                                        {table.tableData?.length ? table.tableData.map((item) =>
                                            item.rowData?.length ? item.rowData.map((ele, rowIndex) => (
                                                <material.TableRow key={rowIndex}>
                                                    {rowIndex === 0 && (
                                                        <>
                                                            <material.TableCell rowSpan={item.rowData.length}>
                                                                {item.year}</material.TableCell>
                                                            <material.TableCell rowSpan={item.rowData.length}>
                                                                {item.facilityName}</material.TableCell>
                                                        </>
                                                    )}
                                                    {table.columnName.map((column, colIndex) => (
                                                        <material.TableCell key={colIndex}>{ele[column.key]}</material.TableCell>
                                                    ))}
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow>
                                                    {table.columnName.map((column, colIndex) => (
                                                        <material.TableCell key={colIndex}>{item[column.key]}</material.TableCell>
                                                    ))}
                                                </material.TableRow>
                                            )
                                        ) : (
                                            <material.TableRow >
                                                <material.TableCell colSpan={12}>
                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                </material.TableCell>
                                            </material.TableRow>
                                        )}
                                    </material.TableBody>
                                    {/* <material.TableBody>
                                        {table.tableData.map((facility) =>
                                            facility.rowData.map((fuel, index) => (
                                                <material.TableRow key={index}
                                                    sx={{ backgroundColor: fuel.typeOfSource.includes('Total') ? "#b18a77" : "" }}
                                                >
                                                    {index === 0 && (
                                                        <>
                                                            <material.TableCell rowSpan={facility.rowData.length}>{console.log(facility.rowData.length)}
                                                                {facility.year}
                                                            </material.TableCell>
                                                            <material.TableCell rowSpan={facility.rowData.length}>
                                                                {facility.facilityName}
                                                            </material.TableCell>
                                                        </>
                                                    )}
                                                    <material.TableCell>{fuel.typeOfSource}</material.TableCell>
                                                    <material.TableCell>{fuel.fuelName}</material.TableCell>
                                                    <material.TableCell>{fuel.purposeOfUse}</material.TableCell>
                                                    <material.TableCell>{fuel.quantity?.toFixed(2)}</material.TableCell>
                                                    <material.TableCell>{fuel.standardDensity}</material.TableCell>
                                                    <material.TableCell>{fuel.quantityOfMaterials?.toFixed(2)}</material.TableCell>
                                                    <material.TableCell>{fuel.energyUsesFromFuel?.toFixed(4)}</material.TableCell>
                                                </material.TableRow>
                                            ))
                                        )}
                                    </material.TableBody> */}
                                </material.Table>
                            </material.TableContainer>
                        </material.Paper>
                    ))}
                </material.DialogContent>
            </material.Dialog>
        </div>
    );
}

export default ReportingTable;