import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import SpecificDisclosure from '../../specific-disclosure/SpecificDisclosure';
import SpecificDisclosureHistory from '../../specific-disclosure/SpecificDisclosureHistory';
import Snackbar from '../../../toaster-message/Snackbar';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import AddNoticesFines from '../../../sub-issues-dialogs/add-notices-fines/AddNoticesFines';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';
import { getSubIssueRecordData, saveSubIssueData, updateSubIssueData } from '../../../services/sub-issue-services/AddSubissueService';
import ApplicableDialog from '../../../dialogs/ApplicableDialog';
import Pagination from '../../../shared/pagination/Pagination';


var recordRefNo = [];
var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };

function NoticesAndFines(props) {

    const location = useLocation();
    const treeViewData = location?.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [noticesFinesTabs, setNoticesFinesTabs] = useState('Notices & Fines');
    const [addOpenNoticesAndFines, setAddOpenNoticesAndFines] = useState({ action: false, noticesFinesTableData: null });
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [noticesFinesTableData, setNoticesFinesTableData] = useState([]);
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [getFilterDate, setGetFilterDate] = useState(null);

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        getAllNoticesFinesData()
    }, [noticesFinesTabs]);

    const handleChange = (event, newValue) => {
        setNoticesFinesTabs(newValue);
    };

    const getFormDateToDate = (data) => {
        setGetFilterDate(data);
        getAllNoticesFinesData(data, paginationData);
    };

    const getAllNoticesFinesData = async (filterDate, paginationValue) => {
        if (noticesFinesTabs === "Notices & Fines") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let payloadData = {
                subissueName: treeViewData.subIssue,
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                forApproval,
                approveHistory,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            await getSubIssueRecordData(payloadData)
                .then((resp) => {
                    setNoticesFinesTableData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setApplicableRefNo(resp.data.applicableRecord?.ref_no ? resp.data.applicableRecord.ref_no : null);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        }
        else if (noticesFinesTabs === "Notices & Fines History") {
            let forApproval = true;
            let approveHistory = true;
            let payloadData = {
                subissueName: treeViewData.subIssue,
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                forApproval,
                approveHistory,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            await getSubIssueRecordData(payloadData)
                .then((resp) => {
                    setNoticesFinesTableData(resp.data.records.content);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        }
    };

    const addNoticesAndFines = () => {
        setAddOpenNoticesAndFines({ action: true, noticesFinesTableData: null });
    };

    const editNoticesAndFines = (rowData) => {
        setAddOpenNoticesAndFines({ action: true, noticesFinesTableData: rowData });
    };

    const clickCheckBox = (value) => {
        setAddOpenNoticesAndFines({ action: false, noticesFinesTableData: null });
        if (recordRefNo.includes(value)) {
            // recordRefNo.pop(value);
            recordRefNo = recordRefNo.filter(item => item !== value);
        } else {
            recordRefNo.push(value)
        };
    };

    const sendForApproval = async () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true
            }
            await updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllNoticesFinesData(getFilterDate, paginationData);
                    recordRefNo = [];
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const approve = async () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true,
                "approved_by": userDetails.username,
                "approved_date": moment().format("YYYY-MM-DD"),
                "approved_status": "APPROVED",
                "rejection_note": "",
            }
            await updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllNoticesFinesData(getFilterDate, paginationData);
                    recordRefNo = [];
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const reject = async () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        };
    };

    const getRejectionNoteData = async (noteData) => {
        const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
        const subissueName = treeViewData.subIssue;
        const payload = {
            send_for_approval: false,
            "rejection_note": noteData.rejectionNote,
            "approved_status": "",
        }
        await updateSubIssueData(payload, refNo, subissueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllNoticesFinesData(getFilterDate, paginationData);
                recordRefNo = [];
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };

    const getApplicableData = (data) => {
        if (applicableRefNo === null) {
            let subIssueName = treeViewData?.subIssue;
            const payload = {
                applicable: false,
                applicable_record: true,
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
            }
            saveSubIssueData(payload, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllNoticesFinesData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else {
            let subIssueName = treeViewData?.subIssue;
            let refNo = applicableRefNo;
            const payload = {
                applicable: applicable ? false : true,
            }
            updateSubIssueData(payload, refNo, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllNoticesFinesData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    const getPaginationData = (filterData, paginationData) => {
        setRowsPerPage(paginationData.size);
        setPage(paginationData.page);
        getAllNoticesFinesData(filterData, paginationData);
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7, width: 100 }}>
                    <div className='row'>
                        <div className='col-6'>
                            <span><material.Typography variant="h5" className='text-capitalize'>{treeViewData?.subIssue}</material.Typography></span>
                        </div>
                        <div className='col-6'>
                            <span className='float-end' hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}>
                                <material.Stack direction="row" spacing={1} alignItems="center">
                                    <material.Typography>Not applicable</material.Typography>
                                    <material.Switch {...label} onChange={applicableNotApplicable} checked={applicable} color="secondary" />
                                    <material.Typography>Applicable</material.Typography>
                                </material.Stack>
                            </span>
                        </div>
                    </div>
                    <material.Box sx={{ width: '100%', typography: 'body1' }}>
                        <material.TabContext value={noticesFinesTabs}>
                            <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <material.TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <material.Tab className='text-capitalize' label="Notices & Fines" value="Notices & Fines" />
                                    <material.Tab className='text-capitalize' label="Notices & Fines History" value="Notices & Fines History" />
                                    <material.Tab className='text-capitalize' label="Specific Disclosure" value="Specific Disclosure" />
                                    <material.Tab className='text-capitalize' label="Specific Disclosure History" value="Specific Disclosure History" />
                                </material.TabList>
                            </material.Box>
                            <material.TabPanel value="Notices & Fines">
                                <div className='row mt-2 align-items-center'>
                                    <div className="col-7 pb-2">
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        )}
                                    </div>
                                    <div className="col-5 my-2" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? (
                                            <span className='float-end'>
                                                {noticesFinesTableData?.length ? (
                                                    <>
                                                        <material.Button variant="contained" color='success' sx={{ textTransform: "none", mr: 1 }} onClick={approve}
                                                            disabled={!applicable || !recordRefNo.length} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} startIcon={<material.DoneIcon />}>Approve</material.Button>
                                                        <material.Button variant="contained" color='error' disabled={!applicable || !recordRefNo.length} startIcon={<material.PriorityHighIcon />}
                                                            hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} sx={{ textTransform: "none" }} onClick={reject}>Reject</material.Button>
                                                    </>
                                                ) : null}
                                            </span>
                                        ) : (
                                            <span className='float-end'>
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' sx={{ textTransform: "none", mr: 1 }}
                                                    disabled={!applicable} startIcon={<material.SendIcon />} onClick={sendForApproval}>Send for Approval</material.Button>
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' sx={{ textTransform: "none" }}
                                                    startIcon={<material.AddIcon />} disabled={!applicable} onClick={addNoticesAndFines}>Add Notices & Fines</material.Button>
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                    <material.TableContainer>
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow >
                                                    <StyledTableCell>Non Compliance Aspect</StyledTableCell>
                                                    <StyledTableCell>Other (Specify)</StyledTableCell>
                                                    <StyledTableCell>Number of Non Compliance</StyledTableCell>
                                                    <StyledTableCell>Monetary Value Fine (INR Lakh)</StyledTableCell>
                                                    <StyledTableCell>Non Monetary Sanctions</StyledTableCell>
                                                    <StyledTableCell>Dispute Resolution Case</StyledTableCell>
                                                    <StyledTableCell>From Date</StyledTableCell>
                                                    <StyledTableCell>To Date</StyledTableCell>
                                                    <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                        <StyledTableCell>Rejection Note</StyledTableCell>
                                                    )}
                                                </material.TableRow>
                                            </material.TableHead>
                                            <material.TableBody>
                                                {noticesFinesTableData.length ? noticesFinesTableData.map((item, k) => (
                                                    <material.TableRow key={k}
                                                        sx={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                        onClick={((userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")) && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true) ?
                                                            () => editNoticesAndFines(item) : null}
                                                    >
                                                        <material.TableCell>{item.non_compliance_aspect}</material.TableCell>
                                                        <material.TableCell>{(item.other_specify === "" || item.other_specify === null) ? "N/A" : item.other_specify}</material.TableCell>
                                                        <material.TableCell>{item.no_of_non_compliance}</material.TableCell>
                                                        <material.TableCell>{item.monetary_value_fine_inr_lakh}</material.TableCell>
                                                        <material.TableCell>{item.non_monetary_sanctions}</material.TableCell>
                                                        <material.TableCell>{item.dispute_resolution_case}</material.TableCell>
                                                        <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                                            {userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") ? (
                                                                <material.Checkbox {...label} disabled={!applicable || item.send_for_approval} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                            ) : (
                                                                <material.Checkbox {...label} disabled={!applicable || item.approved_status === "APPROVED"} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                            )}
                                                        </material.TableCell>
                                                        {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                            <material.TableCell>{(item.rejection_note === null) || (item.rejection_note === "") ? "No" : (
                                                                <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                            )}
                                                            </material.TableCell>
                                                        )}
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow>
                                                        <material.TableCell colSpan={12}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                    <Pagination
                                        getPaginationData={getPaginationData}
                                        totalElements={totalElements}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        getFilterDate={getFilterDate}
                                    />
                                </material.Paper>
                            </material.TabPanel>
                            <material.TabPanel value="Notices & Fines History">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <DateFilter
                                            getFormDateToDate={getFormDateToDate}
                                        />
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                    <material.TableContainer>
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow>
                                                    <StyledTableCell>Non Compliance Aspect</StyledTableCell>
                                                    <StyledTableCell>Other (Specify)</StyledTableCell>
                                                    <StyledTableCell>Number of Non Compliance</StyledTableCell>
                                                    <StyledTableCell>Monetary Value Fine (INR Lakh)</StyledTableCell>
                                                    <StyledTableCell>Non Monetary Sanctions</StyledTableCell>
                                                    <StyledTableCell>Dispute Resolution Case</StyledTableCell>
                                                    <StyledTableCell>From Date</StyledTableCell>
                                                    <StyledTableCell>To Date</StyledTableCell>
                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                    <StyledTableCell>Status</StyledTableCell>
                                                </material.TableRow>
                                            </material.TableHead>

                                            <material.TableBody>
                                                {noticesFinesTableData?.length ? noticesFinesTableData.map((item, ind) => (
                                                    <material.TableRow key={ind} >
                                                        <material.TableCell>{item.non_compliance_aspect}</material.TableCell>
                                                        <material.TableCell>{(item.other_specify === "" || item.other_specify === null) ? "N/A" : item.other_specify}</material.TableCell>
                                                        <material.TableCell>{item.no_of_non_compliance}</material.TableCell>
                                                        <material.TableCell>{item.monetary_value_fine_inr_lakh}</material.TableCell>
                                                        <material.TableCell>{item.non_monetary_sanctions}</material.TableCell>
                                                        <material.TableCell>{item.dispute_resolution_case}</material.TableCell>
                                                        <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>
                                                            {item.approved_status === "APPROVED" ? <span className="badge bg-success">APPROVED</span> : null}
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={12}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                    <Pagination
                                        getPaginationData={getPaginationData}
                                        totalElements={totalElements}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        getFilterDate={getFilterDate}
                                    />
                                </material.Paper>
                            </material.TabPanel>
                            <material.TabPanel value="Specific Disclosure">
                                <SpecificDisclosure
                                    treeViewData={treeViewData} />
                            </material.TabPanel>
                            <material.TabPanel value="Specific Disclosure History">
                                <SpecificDisclosureHistory
                                    treeViewData={treeViewData} />
                            </material.TabPanel>
                        </material.TabContext>
                    </material.Box>
                </material.Box>
            </material.Box>
            <AddNoticesFines
                addOpenNoticesAndFines={addOpenNoticesAndFines}
                setAddOpenNoticesAndFines={setAddOpenNoticesAndFines}
                getAllNoticesFinesData={getAllNoticesFinesData}
                treeViewData={treeViewData}
                page={page}
                rowsPerPage={rowsPerPage}
                getFilterDate={getFilterDate}
            />
            <ApplicableDialog
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getApplicableData={getApplicableData}
                applicable={applicable}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </div>
    );
}

export default NoticesAndFines;