import React, { useEffect, useState } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';

function RejectionNote(props) {

    const { getRejectionNoteData, openRejectionNoteDialog, setOpenRejectionNoteDialog, callFrom, resetCheckboxes } = props;
    const { register, handleSubmit, reset, watch, getValues, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [errorMessage, setErrorMessage] = useState(true);

    const onChangeValue = watch(["rejectionNote", "rejection_note"]);

    useEffect(() => {
        characterCount();
    }, [onChangeValue]);

    const characterCount = () => {
        let value = null;
        value = getValues("rejectionNote");
        if (value != "" && value?.length > 20) {
            setErrorMessage(false)
        } else {
            setErrorMessage(true)
        };
    };

    useEffect(() => {
        if (openRejectionNoteDialog.action && openRejectionNoteDialog.rejectionData) {
            const rejectionNoteValue = openRejectionNoteDialog.rejectionData.rejectionNote || openRejectionNoteDialog.rejectionData.rejection_note;
            reset({ rejectionNote: rejectionNoteValue, rejection_note: rejectionNoteValue });
        }
    }, [openRejectionNoteDialog.action]);


    const handleClose = () => {
        setOpenRejectionNoteDialog({ action: false, rejectionData: null });
        reset({ rejectionNote: "", rejection_note: "" });
    };

    const saveNote = (formData) => {
        if (formData.rejectionNote) {
            formData.rejection_note = formData.rejectionNote;
        }
        getRejectionNoteData(formData);
        setOpenRejectionNoteDialog({ action: false, rejectionData: null });
        reset({ rejectionNote: "", rejection_note: "" });
        if(callFrom === "strategy-management"){
            resetCheckboxes();
        }
    };

    return (
        <div>
            <material.Dialog maxWidth="md" fullWidth open={openRejectionNoteDialog.action}>
                <material.DialogTitle className='fw-bold'>Rejection Note</material.DialogTitle>
                <material.DialogContent>
                    <div>
                        <material.TextField
                            required
                            label="Rejection Note"
                            id="issue"
                            variant="standard"
                            type="text"
                            fullWidth
                            multiline
                            color='success'
                            InputProps={{ readOnly: openRejectionNoteDialog.rejectionData ? true : false }}
                            rows={6}
                            {...register("rejectionNote", { required: true, minLength: 20, maxLength: 4000 })}
                        />
                        <p className='form-text text-danger'>{errors.rejectionNote?.type === "required" && 'This field is required'}</p>
                        {/* {(errors?.rejectionNote?.type === "minLength") && (
                            <p className='text-danger'>Minimum 20 characters</p>
                        )} */}
                        {errorMessage ? (
                            <p className='text-danger'>Minimum 20 characters</p>
                        ) : null}
                        {(errors?.rejectionNote?.type === "maxLength") && (
                            <p className='text-danger'>Maximum 4000 characters</p>
                        )}
                    </div>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    {openRejectionNoteDialog.rejectionData ? (
                        <material.Button onClick={handleClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Close</material.Button>
                    ) : (
                        <material.Button onClick={handleClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    )}
                    {openRejectionNoteDialog.rejectionData ? null : (
                        <material.Button color='success' variant="outlined" onClick={handleSubmit(saveNote)} sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} disabled={!isValid}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
        </div>
    );
}

export default RejectionNote;