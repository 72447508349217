import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { material } from '../../library/Material';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { getSiteByRefNo } from '../../services/SiteService';


function AddJobCreation(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { openJobCreation, setOpenJobCreation, getAllJobCreationData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const { register, handleSubmit, control, reset, setValue, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [categoryEmployeeWorker, setCategoryEmployeeWorker] = useState('');
    const [gender, setGender] = useState('');
    const [location, setLocation] = useState('');

    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [currencyCode, setCurrencyCode] = useState(true);

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openJobCreation.action && openJobCreation.jobCreationTableData) {
            setCategoryEmployeeWorker(openJobCreation.jobCreationTableData.category_of_employee_worker);
            setGender(openJobCreation.jobCreationTableData.gender);
            setLocation(openJobCreation.jobCreationTableData.location)
            reset(openJobCreation.jobCreationTableData)
        } else if (openJobCreation.action && openJobCreation.jobCreationTableData === null) {
            getFacilityDetails();
        };
    }, [openJobCreation.action === true]);

    const getFacilityDetails = () => {
        getSiteByRefNo(userDetails.facilityRefNo)
            .then((resp) => {
                setValue("currency_code", resp.data.turnOverUnit);
                if (resp.data.turnOverUnit) {
                    setCurrencyCode(true)
                } else {
                    setCurrencyCode(false)
                }
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const categoryOfEmployeeWorker = (event) => {
        setCategoryEmployeeWorker(event.target.value);
    };

    const selectGender = (event) => {
        setGender(event.target.value);
    };

    const selectLocation = (event) => {
        setLocation(event.target.value);
    };

    const handleClose = () => {
        reset({ number: "", wages: "" },);
        setCategoryEmployeeWorker("");
        setGender("");
        setLocation("");
        setOpenJobCreation({ action: false, jobCreationTableData: null });
    };

    const saveJobCreationData = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue);
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "category_of_employee_worker": data.category_of_employee_worker,
                "gender": data.gender,
                "location": data.location,
                "number": data.number,
                "wages": data.wages,
                "currency_code": data.currency_code,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllJobCreationData(getFilterDate, paginationData);
                    reset({ number: "", wages: "" },);
                    setCategoryEmployeeWorker("");
                    setGender("");
                    setLocation("");
                    setOpenJobCreation({ action: false, jobCreationTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateJobCreationData = async (data) => {
        const refNo = openJobCreation.jobCreationTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "category_of_employee_worker": data.category_of_employee_worker,
            "gender": data.gender,
            "location": data.location,
            "number": data.number,
            "wages": data.wages,
            "currency_code": data.currency_code,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllJobCreationData(getFilterDate, paginationData);
                reset({ number: "", wages: "" },);
                setCategoryEmployeeWorker("");
                setGender("");
                setLocation("");
                setOpenJobCreation({ action: false, jobCreationTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openJobCreation.jobCreationTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ number: "", wages: "" },);
                setCategoryEmployeeWorker("");
                setGender("");
                setLocation("");
                getAllJobCreationData(getFilterDate, paginationData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenJobCreation({ action: false, jobCreationTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openJobCreation.action} maxWidth="lg" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openJobCreation.jobCreationTableData ? "Edit Job Creation" : "Add Job Creation"}</material.DialogTitle>
                <material.DialogContent>
                    {openJobCreation.jobCreationTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Job Creation</material.Typography>
                            </div>
                            <div className='col-4 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Category of Employee & Worker</material.InputLabel>
                                    <material.Select
                                        {...register("category_of_employee_worker", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={categoryEmployeeWorker}
                                        onChange={categoryOfEmployeeWorker}
                                        label="Category of Employee & Worker"
                                        disabled={!currencyCode}
                                    >
                                        <material.MenuItem value={"Employee Permanent"}>Employee Permanent</material.MenuItem>
                                        <material.MenuItem value={"Employee other than Permanent"}>Employee other than Permanent</material.MenuItem>
                                        <material.MenuItem value={"Worker Permanent"}>Worker Permanent</material.MenuItem>
                                        <material.MenuItem value={"Worker other than Permanent"}>Worker other than Permanent</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Gender</material.InputLabel>
                                    <material.Select
                                        {...register("gender", { required: true, })}
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={gender}
                                        onChange={selectGender}
                                        label="Gender"
                                        disabled={!currencyCode}
                                    >
                                        <material.MenuItem value={"Male"}>Male</material.MenuItem>
                                        <material.MenuItem value={"Female"}>Female</material.MenuItem>
                                        <material.MenuItem value={"Other"}>Other</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Location</material.InputLabel>
                                    <material.Select
                                        {...register("location", { required: true, })}
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={location}
                                        onChange={selectLocation}
                                        label="Location"
                                        disabled={!currencyCode}
                                    >
                                        <material.MenuItem value={"Rural"}>Rural</material.MenuItem>
                                        <material.MenuItem value={"Semi-urban"}>Semi-urban</material.MenuItem>
                                        <material.MenuItem value={"Urban"}>Urban</material.MenuItem>
                                        <material.MenuItem value={"Metropolitan"}>Metropolitan</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>

                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("number", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                    disabled={!currencyCode}
                                />
                                <p className='form-text text-danger'>{errors.number?.type === "required" && 'This field is required'}</p>
                                {errors.number && errors.number.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("wages", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Wages (in Million)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                    disabled={!currencyCode}
                                />
                                <p className='form-text text-danger'>{errors.wages?.type === "required" && 'This field is required'}</p>
                                {errors.wages && errors.wages.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("currency_code", { required: true, })}
                                    required
                                    label="Currency Code"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={!currencyCode}
                                />
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openJobCreation.jobCreationTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateJobCreationData)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openJobCreation.jobCreationTableData.rejection_note === null) || (openJobCreation.jobCreationTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveJobCreationData)} disabled={!currencyCode} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddJobCreation