import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";


export const saveOrUpdateAirEmissionPointSourceData = async (airEmissionQuantityData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/air-emissions-point-source/save-or-update`, airEmissionQuantityData)
};

export const getAirEmissionPointSourceData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/air-emissions-point-source/get-all`, { params: { ...payload } })
};

export const sendForApprovalAirEmissionPointSourceRecords = async (recordId) => {
    return await axiosInstance.put(`${rootApiUrl}/api/air-emissions-point-source/send-for-approval`, recordId)
};

export const approveOrRejectAirEmissionPointSourceRecords = async (payload) => {
    return await axiosInstance.put(`${rootApiUrl}/api/air-emissions-point-source/approve`, payload)
};

export const updatePollutantData = async (airEmissionQuantityData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/air-emissions-point-source/update-pollutant-data`, airEmissionQuantityData)
};
