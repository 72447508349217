import React, { useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import SpecificDisclosure from '../../specific-disclosure/SpecificDisclosure';
import AddGovernanceBodyStructure from '../../../sub-issues-dialogs/add-governance-body-structure/AddGovernanceBodyStructure';
import SpecificDisclosureHistory from '../../specific-disclosure/SpecificDisclosureHistory';


var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };

function GovernanceBodyStructure(props) {
    const location = useLocation();
    const treeViewData = location.state;
    const [governanceBodyStructureTab, setGovernanceBodyStructureTab] = useState('Specific Disclosure');
    const [applicable, setApplicable] = useState(true);
    const [openAddGovernanceBodyStructure, setOpenAddGovernanceBodyStructure] = useState(false);


    const handleChangeGovernanceBodyStructureTab = (event, newValue) => {
        setGovernanceBodyStructureTab(newValue);
    };


    return (
        <material.Box sx={{ display: 'flex' }}>
            <SwipeDrawer />
            <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                <div className="row">
                    <span className="col-6"><material.Typography variant="h5" className='headingText'>{treeViewData.subIssue}</material.Typography></span>
                    <div className="col-6">
                    </div>
                </div>
                <div>
                    <material.Box sx={{ width: '100%', typography: 'body1' }}>
                        <material.TabContext value={governanceBodyStructureTab}>
                            <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <material.TabList onChange={handleChangeGovernanceBodyStructureTab} aria-label="lab API tabs example">
                                    <material.Tab className="text-capitalize" label="Specific Disclosure" value="Specific Disclosure" />
                                    <material.Tab className="text-capitalize" label="Specific Disclosure History" value="Specific Disclosure History" />
                                </material.TabList>
                            </material.Box>
                            <material.TabPanel value="Specific Disclosure">
                                <SpecificDisclosure
                                    treeViewData={treeViewData}
                                />
                            </material.TabPanel>
                            <material.TabPanel value="Specific Disclosure History">
                                <SpecificDisclosureHistory
                                    treeViewData={treeViewData}
                                />
                            </material.TabPanel>
                        </material.TabContext>
                    </material.Box>
                </div>
            </material.Box>
            <AddGovernanceBodyStructure
                openAddGovernanceBodyStructure={openAddGovernanceBodyStructure}
                setOpenAddGovernanceBodyStructure={setOpenAddGovernanceBodyStructure}
                treeViewData={treeViewData} />
        </material.Box>
    );
}

export default GovernanceBodyStructure;