import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateParentalLeaveData } from '../../services/sub-issue-services/parental-leave-service/ParentalLeaveService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddParentalLeave(props) {
    const { openParentalLeave, setOpenParentalLeave, getAllParentalLeave, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, watch, control, getValues, setValue, formState: { errors }, } = useForm({ mode: "onTouched" });
    const [gender, setGender] = useState("");
    const [Category, setCategory] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openParentalLeave.action && openParentalLeave.parentalLeaveTableData) {
            setCategory(openParentalLeave.parentalLeaveTableData.category_of_employee_and_worker);
            setGender(openParentalLeave.parentalLeaveTableData.gender);
            reset(openParentalLeave.parentalLeaveTableData);
        }
    }, [openParentalLeave.action === true]);

    const changeCategory = (event) => {
        setCategory(event.target.value);
    };

    const changeGender = (event) => {
        setGender(event.target.value);
    };

    const handleClose = () => {
        reset({ no_of_employee_entitled_to_parental_leave: "", no_of_employee_took_parental_leave: "", no_of_employee_returned_work_after_parental_leave: "", no_of_employee_retained_twelve_month_after_parental_leave: "", retentionRates: "", });
        setGender("");
        setCategory("");
        setOpenParentalLeave({ action: false, parentalLeaveTableData: null });
    };

    const saveParentalLeave = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "category_of_employee_and_worker": data.category_of_employee_and_worker,
                "gender": data.gender,
                "no_of_employee_entitled_to_parental_leave": Number(data.no_of_employee_entitled_to_parental_leave),
                "no_of_employee_took_parental_leave": Number(data.no_of_employee_took_parental_leave),
                "no_of_employee_returned_work_after_parental_leave": Number(data.no_of_employee_returned_work_after_parental_leave),
                "no_of_employee_retained_twelve_month_after_parental_leave": Number(data.no_of_employee_retained_twelve_month_after_parental_leave),
                // "retention_rates": parseFloat(data.retention_rates),
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllParentalLeave(getFilterDate, payloadData);
                    reset({ no_of_employee_entitled_to_parental_leave: "", no_of_employee_took_parental_leave: "", no_of_employee_returned_work_after_parental_leave: "", no_of_employee_retained_twelve_month_after_parental_leave: "", retentionRates: "", });
                    setGender("");
                    setCategory("");
                    setOpenParentalLeave({ action: false, parentalLeaveTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateParentalLeave = async (data) => {
        const refNo = openParentalLeave.parentalLeaveTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "category_of_employee_and_worker": data.category_of_employee_and_worker,
            "gender": data.gender,
            "no_of_employee_entitled_to_parental_leave": Number(data.no_of_employee_entitled_to_parental_leave),
            "no_of_employee_took_parental_leave": Number(data.no_of_employee_took_parental_leave),
            "no_of_employee_returned_work_after_parental_leave": Number(data.no_of_employee_returned_work_after_parental_leave),
            "no_of_employee_retained_twelve_month_after_parental_leave": Number(data.no_of_employee_retained_twelve_month_after_parental_leave),
            // "retention_rates": parseFloat(data.retention_rates),
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                getAllParentalLeave()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllParentalLeave(getFilterDate, payloadData);
                reset({ no_of_employee_entitled_to_parental_leave: "", no_of_employee_took_parental_leave: "", no_of_employee_returned_work_after_parental_leave: "", no_of_employee_retained_twelve_month_after_parental_leave: "", retentionRates: "", });
                setGender("");
                setCategory("");
                setOpenParentalLeave({ action: false, parentalLeaveTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openParentalLeave.parentalLeaveTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllParentalLeave(getFilterDate, payloadData);
                reset({ no_of_employee_entitled_to_parental_leave: "", no_of_employee_took_parental_leave: "", no_of_employee_returned_work_after_parental_leave: "", no_of_employee_retained_twelve_month_after_parental_leave: "", retentionRates: "", });
                setGender("");
                setCategory("");
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenParentalLeave({ action: false, parentalLeaveTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <>
            <material.Dialog open={openParentalLeave.action} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold headingText'>
                    {openParentalLeave.parentalLeaveTableData ? "Edit Parental Leave" : "Add Parental Leave"}
                </material.DialogTitle>
                <material.DialogContent>
                    <div className='my-3'>
                        {openParentalLeave.parentalLeaveTableData ? null : (
                            <DatePeriod
                                register={register}
                                control={control}
                            />
                        )}
                    </div>
                    <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography><h5 className='fw-bold headingText'>Parental Leave</h5></material.Typography>
                            </div>
                            <div className='py-2 col-4'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Category of Employee & Worker</material.InputLabel>
                                    <material.Select
                                        {...register("category_of_employee_and_worker", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={Category}
                                        onChange={changeCategory}
                                        label="Category"
                                    >
                                        <material.MenuItem value={"Employee Permanent"}>Employee Permanent</material.MenuItem>
                                        <material.MenuItem value={"Employee other than Permanent"}>Employee other than Permanent</material.MenuItem>
                                        <material.MenuItem value={"Worker Permanent"}>Worker Permanent</material.MenuItem>
                                        <material.MenuItem value={"Worker other than Permanent"}>Worker other than Permanent</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='py-2 col-4'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Gender</material.InputLabel>
                                    <material.Select
                                        {...register("gender", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={gender}
                                        onChange={changeGender}
                                        label="Gender"
                                    >
                                        <material.MenuItem value={"Male"}>Male</material.MenuItem>
                                        <material.MenuItem value={"Female"}>Female</material.MenuItem>
                                        <material.MenuItem value={"Others"}>Others</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='py-2 col-4'>
                                <material.TextField
                                    {...register("no_of_employee_entitled_to_parental_leave", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number of Employee Entitled to Parental Leave"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.no_of_employee_entitled_to_parental_leave && errors.no_of_employee_entitled_to_parental_leave.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='py-2 col-4'>
                                <material.TextField
                                    {...register("no_of_employee_took_parental_leave", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number of Employee Took Parental Leave"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.no_of_employee_took_parental_leave && errors.no_of_employee_took_parental_leave.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='py-2 col-4'>
                                <material.TextField
                                    {...register("no_of_employee_returned_work_after_parental_leave", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number of Employee Returned Work After Parental Leave"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.no_of_employee_returned_work_after_parental_leave && errors.no_of_employee_returned_work_after_parental_leave.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='py-2 col-4'>
                                <material.TextField
                                    {...register("no_of_employee_retained_twelve_month_after_parental_leave", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number of Employee Retained 12 Month After Parental Leave"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.no_of_employee_retained_twelve_month_after_parental_leave && errors.no_of_employee_retained_twelve_month_after_parental_leave.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            {/* <div className='py-2 col-4'>
                                <material.TextField
                                    {...register("retentionRates", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Retention Rates"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.retentionRates && errors.retentionRates.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div> */}
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openParentalLeave.parentalLeaveTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateParentalLeave)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openParentalLeave.parentalLeaveTableData.rejection_note === null) || (openParentalLeave.parentalLeaveTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveParentalLeave)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </>
    )
}

export default AddParentalLeave;