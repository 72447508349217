import React, { useState, useEffect } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import moment from 'moment';
import Snackbar from '../../../toaster-message/Snackbar';
import AddEnergyConsumptionOutside from '../../../sub-issues-dialogs/energy-dialogs/AddEnergyConsumptionOutside';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';
import { getSubIssueRecordData, saveSubIssueData, updateSubIssueData } from '../../../services/sub-issue-services/AddSubissueService';
import Pagination from '../../../shared/pagination/Pagination';
import ApplicableDialog from '../../../dialogs/ApplicableDialog';


var recordRefNos = [];
var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };

function EnergyConsumptionOutsideOrg(props) {
    const location = useLocation();
    const treeViewData = location?.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };
    const [openEnergyConsumptionOutside, setOpenEnergyConsumptionOutside] = useState({ action: false, energyConsumptionOutsideData: null })
    const [energyConsumptionOutsideTableData, setEnergyConsumptionOutsideTableData] = useState([]);
    const [value, setValue] = useState('Energy Consumption Outside Organization');
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = React.useState(0);
    const [getFilterDate, setGetFilterDate] = useState(null);

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        energyConsumptionOutsideData()
    }, [value]);

    const energyConsumptionOutsideData = async (filterDate, paginationValue) => {
        if (value === "Energy Consumption Outside Organization") {
            let forApproval = userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? true : null;
            let approveHistory = false;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                subissueName: treeViewData.subIssue,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            await getSubIssueRecordData(payloadData)
                .then((resp) => {
                    setEnergyConsumptionOutsideTableData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setApplicableRefNo(resp.data.applicableRecord?.ref_no ? resp.data.applicableRecord.ref_no : null);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else if (value === "Energy Consumption Outside Organization History") {
            let forApproval = true;
            let approveHistory = true;
            let payloadData = {
                subissueName: treeViewData.subIssue,
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            await getSubIssueRecordData(payloadData)
                .then((resp) => {
                    setEnergyConsumptionOutsideTableData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        };
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const clickCheckBox = (value) => {
        setOpenEnergyConsumptionOutside({ action: false, energyConsumptionOutsideData: null });
        if (recordRefNos.includes(value)) {
            // recordRefNos.pop(value);
            recordRefNos = recordRefNos.filter(item => item !== value);
        } else {
            recordRefNos.push(value)
        }
    }
    const addEnergyConsumptionOutside = () => {
        setOpenEnergyConsumptionOutside({ action: true, energyConsumptionOutsideData: null });
    };
    const editEnergyConsumptionOutsideData = (data) => {
        setOpenEnergyConsumptionOutside({ action: true, energyConsumptionOutsideData: data });
    };
    const sendApprovalEnergyConsumptionOutside = async () => {
        if (recordRefNos.length) {
            const refNo = recordRefNos.length > 0 ? recordRefNos.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true
            }
            await updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    recordRefNos = [];
                    energyConsumptionOutsideData(getFilterDate, paginationData);
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        }
    };
    const approvedEnergyConsumptionOutside = async () => {
        if (recordRefNos.length) {
            const refNo = recordRefNos.length > 0 ? recordRefNos.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true,
                "approved_by": userDetails.username,
                "approved_date": moment().format("YYYY-MM-DD"),
                "approved_status": "APPROVED",
                "rejection_note": "",
            }
            await updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    recordRefNos = [];
                    energyConsumptionOutsideData(getFilterDate, paginationData);
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        };
    };
    const rejectEnergyConsumptionOutside = async () => {
        if (recordRefNos.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        };
    };

    const getRejectionNoteData = async (noteData) => {
        const refNo = recordRefNos.length > 0 ? recordRefNos.join(',') : null;
        const subissueName = treeViewData.subIssue;
        const payload = {
            send_for_approval: false,
            "rejection_note": noteData.rejectionNote,
            "approved_status": "",
        }
        await updateSubIssueData(payload, refNo, subissueName)
            .then((resp) => {
                recordRefNos = [];
                energyConsumptionOutsideData(getFilterDate, paginationData);
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const getFormDateToDate = (data) => {
        setGetFilterDate(data);
        energyConsumptionOutsideData(data, paginationData);
    };

    const getApplicableData = () => {
        if (applicableRefNo === null) {
            let subIssueName = treeViewData?.subIssue;
            const payload = {
                applicable: false,
                applicable_record: true,
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
            }
            saveSubIssueData(payload, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    energyConsumptionOutsideData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else {
            let subIssueName = treeViewData?.subIssue;
            let refNo = applicableRefNo;
            const payload = {
                applicable: applicable ? false : true,
            }
            updateSubIssueData(payload, refNo, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    energyConsumptionOutsideData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const getPaginationData = (filterData, paginationData) => {
        setRowsPerPage(paginationData.size);
        setPage(paginationData.page);
        energyConsumptionOutsideData(filterData, paginationData);
    };

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className="row">
                        <span className="col-6"><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
                        <div className="col-6">
                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") || userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? null : (
                                <span className="float-end">
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} onChange={applicableNotApplicable}
                                            checked={applicable}
                                            color="secondary" />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="mt-3">
                        <material.Box sx={{ width: '100%', typography: 'body1' }}>
                            <material.TabContext value={value}>
                                <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <material.TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <material.Tab className="text-capitalize" label="Energy Consumption Outside Organization" value="Energy Consumption Outside Organization" />
                                        <material.Tab className="text-capitalize p-2" label="Energy Consumption Outside Organization History" value="Energy Consumption Outside Organization History" />
                                    </material.TabList>
                                </material.Box>
                                <material.TabPanel value="Energy Consumption Outside Organization">
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                <DateFilter
                                                    getFormDateToDate={getFormDateToDate}
                                                />
                                            )}
                                        </div>
                                        <div className="col-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                            {usersRole === "ROLE_FACILITY_APPROVER" ? (
                                                <span className="float-end">
                                                    {energyConsumptionOutsideTableData.length ? (
                                                        <>
                                                            <material.Button variant="contained" color='success' disabled={!applicable || !recordRefNos.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approvedEnergyConsumptionOutside}>Approve</material.Button>
                                                            <material.Button sx={{ textTransform: "none" }} disabled={!applicable || !recordRefNos.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectEnergyConsumptionOutside}>Reject</material.Button>
                                                        </>
                                                    ) : null}
                                                </span>
                                            ) : (
                                                <span className="float-end">
                                                    <material.Button variant="contained" color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendApprovalEnergyConsumptionOutside}>Send for Approval</material.Button>
                                                    <material.Button variant="contained" color="success" disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addEnergyConsumptionOutside}>Add Energy Consumption Outside</material.Button>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <material.TableContainer>
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow>
                                                                <StyledTableCell>Parameter</StyledTableCell>
                                                                <StyledTableCell>Category</StyledTableCell>
                                                                <StyledTableCell>Quantity</StyledTableCell>
                                                                <StyledTableCell>Unit</StyledTableCell>
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Rejection Note</StyledTableCell>
                                                                )}
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {energyConsumptionOutsideTableData?.length ? energyConsumptionOutsideTableData.map((item, ind) => (
                                                                <material.TableRow key={ind}
                                                                    sx={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                    onClick={((userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")) && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true) ?
                                                                        () => editEnergyConsumptionOutsideData(item) : null}
                                                                >
                                                                    <material.TableCell>{item.parameter}</material.TableCell>
                                                                    <material.TableCell>{item.category}</material.TableCell>
                                                                    <material.TableCell>{item.amount}</material.TableCell>
                                                                    <material.TableCell>{item.unit}</material.TableCell>
                                                                    <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                                        {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.send_for_approval} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                                        ) : (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.approved_status === "APPROVED"} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                                        )}
                                                                    </material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{(item.rejection_note === null) || (item.rejection_note === "") ? "No" : (
                                                                            <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                        )}
                                                                        </material.TableCell>
                                                                    )}
                                                                </material.TableRow>
                                                            )) : (
                                                                <material.TableRow >
                                                                    <material.TableCell colSpan={9}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )}
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                                <Pagination
                                                    getPaginationData={getPaginationData}
                                                    totalElements={totalElements}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    getFilterDate={getFilterDate}
                                                />
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                                <material.TabPanel value="Energy Consumption Outside Organization History">
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                    <DateFilter
                                                        getFormDateToDate={getFormDateToDate}
                                                    />
                                                )}
                                            </div>
                                            <div className="col-12 mt-2">
                                                <material.TableContainer>
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow>
                                                                <StyledTableCell>Parameter</StyledTableCell>
                                                                <StyledTableCell>Category</StyledTableCell>
                                                                <StyledTableCell>Quantity</StyledTableCell>
                                                                <StyledTableCell>Unit</StyledTableCell>
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                <StyledTableCell>Approval Date</StyledTableCell>
                                                                <StyledTableCell>Status</StyledTableCell>
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {energyConsumptionOutsideTableData?.length ? energyConsumptionOutsideTableData.map((item, ind) => (
                                                                <material.TableRow key={ind}>
                                                                    <material.TableCell>{item.parameter}</material.TableCell>
                                                                    <material.TableCell>{item.category}</material.TableCell>
                                                                    <material.TableCell>{item.amount}</material.TableCell>
                                                                    <material.TableCell>{item.unit}</material.TableCell>
                                                                    <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>
                                                                        {item.approved_status === "APPROVED" ? <span className="badge bg-success">
                                                                            APPROVED
                                                                        </span> : null}
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )) :
                                                                (
                                                                    <material.TableRow >
                                                                        <material.TableCell colSpan={8}>
                                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                        </material.TableCell>
                                                                    </material.TableRow>
                                                                )
                                                            }
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                                <Pagination
                                                    getPaginationData={getPaginationData}
                                                    totalElements={totalElements}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    getFilterDate={getFilterDate}
                                                />
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                            </material.TabContext>
                        </material.Box>
                    </div>
                </material.Box>
            </material.Box>
            <AddEnergyConsumptionOutside
                energyConsumptionOutsideData={energyConsumptionOutsideData}
                openEnergyConsumptionOutside={openEnergyConsumptionOutside}
                setOpenEnergyConsumptionOutside={setOpenEnergyConsumptionOutside}
                treeViewData={treeViewData}
                page={page}
                rowsPerPage={rowsPerPage}
                getFilterDate={getFilterDate}
            />
            <ApplicableDialog
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getApplicableData={getApplicableData}
                applicable={applicable}
            />
            {/* <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                energyConsumptionOutsideData={energyConsumptionOutsideData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            /> */}
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default EnergyConsumptionOutsideOrg;