import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { material } from '../../library/Material';
import DatePeriod from '../../shared/DatePeriod';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import Snackbar from '../../toaster-message/Snackbar';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';


function AddMembershipAssociation(props) {

    const { openMembershipAssociation, setOpenMembershipAssociation, getMembershipAssociation, page, rowsPerPage, getFilterDate, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [tradeIndustryData, setTradeIndustryData] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openMembershipAssociation.action && openMembershipAssociation.membershipAssociationTableData) {
            setTradeIndustryData(openMembershipAssociation.membershipAssociationTableData.reach_of_trade_and_industry_cham_asso);
            reset(openMembershipAssociation.membershipAssociationTableData);
        }
    }, [openMembershipAssociation.action === true]);

    const handleClose = () => {
        setTradeIndustryData("");
        reset({ s_no: "", name_of_trade_and_industry_chambers: "", });
        setOpenMembershipAssociation({ action: false, membershipAssociationTableData: null });
    };

    const saveMembershipAssociation = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "s_no": data.s_no,
                "name_of_trade_and_industry_chambers": data.name_of_trade_and_industry_chambers,
                "reach_of_trade_and_industry_cham_asso": data.reach_of_trade_and_industry_cham_asso,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getMembershipAssociation(getFilterDate, payloadData);
                    setTradeIndustryData("");
                    reset({ s_no: "", name_of_trade_and_industry_chambers: "", });
                    setOpenMembershipAssociation({ action: false, membershipAssociationTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateMembershipAssociation = async (data) => {
        const refNo = openMembershipAssociation.membershipAssociationTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "s_no": data.s_no,
            "name_of_trade_and_industry_chambers": data.name_of_trade_and_industry_chambers,
            "reach_of_trade_and_industry_cham_asso": data.reach_of_trade_and_industry_cham_asso,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getMembershipAssociation(getFilterDate, payloadData);
                setTradeIndustryData("");
                reset({ s_no: "", name_of_trade_and_industry_chambers: "", });
                setOpenMembershipAssociation({ action: false, membershipAssociationTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const changeTradeIndustry = (event) => {
        setTradeIndustryData(event.target.value);
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openMembershipAssociation.membershipAssociationTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setTradeIndustryData("");
                reset({ s_no: "", name_of_trade_and_industry_chambers: "", });
                getMembershipAssociation(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenMembershipAssociation({ action: false, membershipAssociationTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openMembershipAssociation.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openMembershipAssociation.membershipAssociationTableData ? "Edit Membership Association" : "Add Membership Association"}</material.DialogTitle>
                <material.DialogContent>
                    {openMembershipAssociation.membershipAssociationTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Membership Association</material.Typography>
                            </div>
                            <div>
                                <material.TextField
                                    {...register("s_no", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Serial number"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.s_no?.type === "required" && 'This field is required'}</p>
                                {errors.s_no && errors.s_no.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='mt-3'>
                                <material.TextField
                                    {...register("name_of_trade_and_industry_chambers", { required: true, minLength: 2, maxLength: 200 })}
                                    required
                                    label="Name of Trade and Industry Chambers"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                                <p className='form-text text-danger'>{errors.name_of_trade_and_industry_chambers?.type === "required" && 'This field is required'}</p>
                                {errors.name_of_trade_and_industry_chambers && (errors.name_of_trade_and_industry_chambers.type === "minLength" || errors.name_of_trade_and_industry_chambers.type === "maxLength") && (
                                    <p className="errorMsg text-danger">Describe The Name of Trade and Industry Chambers Issues should be between 2 to 200 character.</p>
                                )}
                            </div>
                            <div className='mt-3'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Reach of Trade and Industry  Chambers  Associations</material.InputLabel>
                                    <material.Select
                                        {...register("reach_of_trade_and_industry_cham_asso", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={tradeIndustryData}
                                        onChange={changeTradeIndustry}
                                        label="Reach of Trade and Industry  Chambers  Associations"
                                    >
                                        <material.MenuItem value={"State"}>State</material.MenuItem>
                                        <material.MenuItem value={"National"}>National</material.MenuItem>
                                        <material.MenuItem value={"International"}>International</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openMembershipAssociation.membershipAssociationTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateMembershipAssociation)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openMembershipAssociation.membershipAssociationTableData.rejection_note === null) || (openMembershipAssociation.membershipAssociationTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveMembershipAssociation)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddMembershipAssociation;