import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { saveOrUpdateDiversity } from '../../services/sub-issue-services/DiversityOfGovernanceBodiesService';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import { checkCriteriaForYesTypeAnswer } from '../../shared/Shared';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddDiversityOfGovernanceBodies(props) {
  const { register, handleSubmit, reset, control, resetField, watch, getValues, setValue, formState: { errors, isValid }, } = useForm({ mode: "onTouched" });
  const { openAddDiversity, setOpenAddDiversity, getDiversityOfGovernanceData, treeViewData, page, rowsPerPage, getFilterDate } = props;
  const userDetails = useSelector((state) => state.LoginSlice.data);
  const [selectCategoryOfWorker, setSelectCategoryOfWorker] = useState("");
  const [isEmployeeAgeBasedDataPresent, setIsEmployeeAgeBasedDataPresent] = useState("No");
  const [openSnackBar, setOpenSnackBar] = useState({
    "action": false,
    "type": "",
    "message": "",
  });
  const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

  const payloadData = {
    page,
    size: rowsPerPage
  };

  const onChangeMale = watch('male');
  const onChangeFemale = watch('female');
  const onChangeOther = watch('other');

  useEffect(() => {
    totalCalculation();
  }, [onChangeMale, onChangeFemale, onChangeOther]);

  const totalCalculation = () => {
    let val1 = getValues('male');
    let val2 = getValues('female');
    let val3 = getValues('other');
    if (val1 && val2 && val3) {
      let totalValue = Number(val1) + Number(val2) + Number(val3);
      setValue("total", totalValue);
    };
  };


  useEffect(() => {
    if (openAddDiversity.action && openAddDiversity.diversityOfGovernanceBodiesData) {
      reset(openAddDiversity.diversityOfGovernanceBodiesData)
      setSelectCategoryOfWorker(openAddDiversity.diversityOfGovernanceBodiesData.category_of_worker);
      setIsEmployeeAgeBasedDataPresent(openAddDiversity.diversityOfGovernanceBodiesData.is_age_based_data_present ? "Yes" : "No")
    }
  }, [openAddDiversity.action === true]);


  const handleSelectCategoryOfWorker = (event) => {
    setSelectCategoryOfWorker(event.target.value)
  };

  const isEmployeeAgeBasedDataPresentYesOrNo = (event) => {
    setIsEmployeeAgeBasedDataPresent(event.target.value)
  };

  const handleClose = () => {
    setOpenAddDiversity({ action: false, diversityOfGovernanceBodiesData: null });
    reset({ male: "", female: "", other: "", total: "", male_under_30: "", male_30_to_50: "", male_above_50: "", female_under_30: "", female_30_to_50: "", female_above_50: "", vulnerable: "", other_under_30: "", other_30_to_50: "", other_above_50: "" });
    setSelectCategoryOfWorker("")
  };

  const saveDiversityOfGovernanceBodies = async (formData) => {
    let payLoad = generateFromDateAndToDate(formData);
    let newDate = moment().format("YYYY-MM-DD");
    let subissueName = (treeViewData?.subIssue)
    if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
      let obj = {
        "corporate_ref_no": userDetails.corporateRefNo,
        "facility_ref_no": userDetails.facilityRefNo,
        // "type_of_record": "DIVERSITY_OF_GOVERNANCE_BODIES",
        "is_age_based_data_present": isEmployeeAgeBasedDataPresent === "Yes" ? true : false,
        "category_of_worker": formData.category_of_worker,
        "male": Number(formData.male),
        "female": Number(formData.female),
        "other": Number(formData.other),
        "total": Number(formData.total),
        "male_under_30": Number(formData.male_under_30),
        "male_30_to_50": Number(formData.male_30_to_50),
        "male_above_50": Number(formData.male_above_50),
        "female_under_30": Number(formData.female_under_30),
        "female_30_to_50": Number(formData.female_30_to_50),
        "female_above_50": Number(formData.female_above_50),
        "other_under_30": Number(formData.other_under_30),
        "other_30_to_50": Number(formData.other_30_to_50),
        "other_above_50": Number(formData.other_above_50),
        "vulnerable": Number(formData.vulnerable),
        "applicable_record": false,
        "applicable": true,
        "from_date": payLoad?.fromDate,
        "to_date": payLoad?.toDate,
      }
      if (isEmployeeAgeBasedDataPresent === "Yes") {
        if (checkCriteriaForYesTypeAnswer(formData)) {
          if (Number(formData.total) >= Number(formData.vulnerable)) {
            await saveSubIssueData(obj, subissueName)
              .then((resp) => {
                setOpenSnackBar({
                  "action": true,
                  "type": "success",
                  "message": resp.data,
                })
                getDiversityOfGovernanceData(getFilterDate, payloadData);
                setSelectCategoryOfWorker("")
                reset({ male: "", female: "", other: "", total: "", male_under_30: "", male_30_to_50: "", male_above_50: "", female_under_30: "", female_30_to_50: "", female_above_50: "", vulnerable: "", other_under_30: "", other_30_to_50: "", other_above_50: "" });
                setOpenAddDiversity({ action: false, diversityOfGovernanceBodiesData: null });
              })
              .catch((error) => {
                if (error.response?.status === 401) {
                  setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "You are not authorized to perform this action. Redirected to login",
                  })
                } else {
                  setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                  })
                };
              })
          }
          else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "The total number of Vulnerable count cannot be greater than the total number of Total Derived count",
            })
          }
        }
        else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "Sum of age based count not matching with total count",
          })
        };
      } else if (isEmployeeAgeBasedDataPresent === "No") {
        if (Number(formData.total) >= Number(formData.vulnerable)) {
          await saveSubIssueData(obj, subissueName)
            .then((resp) => {
              setOpenSnackBar({
                "action": true,
                "type": "success",
                "message": resp.data,
              })
              getDiversityOfGovernanceData(getFilterDate, payloadData);
              setSelectCategoryOfWorker("")
              reset({ male: "", female: "", other: "", total: "", male_under_30: "", male_30_to_50: "", male_above_50: "", female_under_30: "", female_30_to_50: "", female_above_50: "", vulnerable: "", other_under_30: "", other_30_to_50: "", other_above_50: "" });
              setOpenAddDiversity({ action: false, diversityOfGovernanceBodiesData: null });
            })
            .catch((error) => {
              if (error.response.status === 401) {
                setOpenSnackBar({
                  "action": true,
                  "type": "error",
                  "message": "You are not authorized to perform this action. Redirected to login",
                })
              } else {
                setOpenSnackBar({
                  "action": true,
                  "type": "error",
                  "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                })
              };
            })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "The total number of Vulnerable count cannot be greater than the total number of Total Derived count",
          })
        }
      };
    }
    else {
      setOpenSnackBar({
        "action": true,
        "type": "warning",
        "message": "You can't add future records",
      })
    };
  };

  const updateDiversityOfGovernanceBodies = async (formData) => {
    const refNo = openAddDiversity.diversityOfGovernanceBodiesData.ref_no;
    const subIssueName = treeViewData?.subIssue;
    let obj = {
      "is_age_based_data_present": isEmployeeAgeBasedDataPresent === "Yes" ? true : false,
      "category_of_worker": formData.category_of_worker,
      "male": Number(formData.male),
      "female": Number(formData.female),
      "other": Number(formData.other),
      "total": Number(formData.total),
      "male_under_30": Number(formData.male_under_30),
      "male_30_to_50": Number(formData.male_30_to_50),
      "male_above_50": Number(formData.male_above_50),
      "female_under_30": Number(formData.female_under_30),
      "female_30_to_50": Number(formData.female_30_to_50),
      "female_above_50": Number(formData.female_above_50),
      "other_under_30": Number(formData.other_under_30),
      "other_30_to_50": Number(formData.other_30_to_50),
      "other_above_50": Number(formData.other_above_50),
      "vulnerable": Number(formData.vulnerable),
    }
    if (isEmployeeAgeBasedDataPresent === "Yes") {
      if (checkCriteriaForYesTypeAnswer(formData)) {
        if (Number(formData.total) >= Number(formData.vulnerable)) {
          // await saveOrUpdateDiversity(obj)
          await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
              setOpenSnackBar({
                "action": true,
                "type": "success",
                "message": resp.data,
              })
              getDiversityOfGovernanceData(getFilterDate, payloadData);
              setSelectCategoryOfWorker("")
              reset({ male: "", female: "", other: "", total: "", male_under_30: "", male_30_to_50: "", male_above_50: "", female_under_30: "", female_30_to_50: "", female_above_50: "", vulnerable: "", other_under_30: "", other_30_to_50: "", other_above_50: "" });
              setOpenAddDiversity({ action: false, diversityOfGovernanceBodiesData: null });
            })
            .catch((error) => {
              if (error.response.status === 401) {
                setOpenSnackBar({
                  "action": true,
                  "type": "error",
                  "message": "You are not authorized to perform this action. Redirected to login",
                })
              } else {
                setOpenSnackBar({
                  "action": true,
                  "type": "error",
                  "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                })
              };
            });
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "The total number of Vulnerable count cannot be greater than the total number of Total Derived count",
          })
        }
      } else {
        setOpenSnackBar({
          "action": true,
          "type": "error",
          "message": "Sum of age based count not matching with total count",
        });
      };
    } else if (isEmployeeAgeBasedDataPresent === "No") {
      if (Number(formData.total) >= Number(formData.vulnerable)) {
        await updateSubIssueData(obj, refNo, subIssueName)
          .then((resp) => {
            setOpenSnackBar({
              "action": true,
              "type": "success",
              "message": resp.data,
            })
            getDiversityOfGovernanceData(getFilterDate, payloadData);
            setSelectCategoryOfWorker("")
            reset({ male: "", female: "", other: "", total: "", male_under_30: "", male_30_to_50: "", male_above_50: "", female_under_30: "", female_30_to_50: "", female_above_50: "", vulnerable: "", other_under_30: "", other_30_to_50: "", other_above_50: "" });
            setOpenAddDiversity({ action: false, diversityOfGovernanceBodiesData: null });
          })
          .catch((error) => {
            if (error.response.status === 401) {
              setOpenSnackBar({
                "action": true,
                "type": "error",
                "message": "You are not authorized to perform this action. Redirected to login",
              })
            } else {
              setOpenSnackBar({
                "action": true,
                "type": "error",
                "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
              })
            };
          });
      } else {
        setOpenSnackBar({
          "action": true,
          "type": "error",
          "message": "The total number of Vulnerable count cannot be greater than the total number of Total Derived count",
        })
      }
    }
  };

  const deleteConfirmation = () => {
    setOpenDeleteRecordConfirmationDialog(true);
  };

  const deleteRecordByRefNo = () => {
    const payload = {
      refNo: openAddDiversity.diversityOfGovernanceBodiesData.ref_no,
      subissueName: treeViewData.subIssue,
    }
    commonDelete(payload)
      .then((resp) => {
        setOpenSnackBar({
          "action": true,
          "type": "success",
          "message": resp.data,
        })
        getDiversityOfGovernanceData(getFilterDate, payloadData);
        setSelectCategoryOfWorker("")
        setOpenDeleteRecordConfirmationDialog(false);
        reset({ male: "", female: "", other: "", total: "", male_under_30: "", male_30_to_50: "", male_above_50: "", female_under_30: "", female_30_to_50: "", female_above_50: "", vulnerable: "", other_under_30: "", other_30_to_50: "", other_above_50: "" });
        setOpenAddDiversity({ action: false, diversityOfGovernanceBodiesData: null });
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "You are not authorized to perform this action. Redirected to login",
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
          })
        }
      })
  };

  return (
    <div>
      <material.Dialog open={openAddDiversity.action} maxWidth="lg" fullWidth >
        <material.DialogTitle className='fw-bold headingText'>{openAddDiversity.diversityOfGovernanceBodiesData ? "Edit Diversity of Governance Bodies" : "Add Diversity of Governance Bodies"}</material.DialogTitle>
        <material.DialogContent>
          <div className='d-flex flex-row align-items-center'>
            <span className='fw-bold me-2'>
              Is employee age based data present ? :
            </span>
            <material.FormControl>
              <material.RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                value={isEmployeeAgeBasedDataPresent}
                name="row-radio-buttons-group"
                onChange={isEmployeeAgeBasedDataPresentYesOrNo}
              >
                <material.FormControlLabel value="Yes" label="Yes" control={<material.Radio color='secondary' />} />
                <material.FormControlLabel value="No" label="No" control={<material.Radio color='secondary' />} />
              </material.RadioGroup>
            </material.FormControl>
          </div>
          <div className='my-3'>
            {openAddDiversity.diversityOfGovernanceBodiesData ? null : (
              <DatePeriod
                register={register}
                control={control}
              />
            )}
          </div>
          <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
            <div className='py-2'>
              <div className='row mt-3'>
                <div className=''>
                  <material.Typography className='fw-bold headingText'>Diversity of governance Category of Employee & Workers data</material.Typography>
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.FormControl variant="standard" margin="dense" fullWidth>
                    <material.InputLabel id="demo-simple-select-standard-label" required>Category of Employee & Worker</material.InputLabel>
                    <material.Select
                      {...register("category_of_worker", { required: true })}
                      labelId="demo-simple-select-label"
                      variant='standard'
                      id="categoryOfWorker"
                      value={selectCategoryOfWorker}
                      onChange={handleSelectCategoryOfWorker}
                    >
                      <material.MenuItem value={"Board of Director"}>Board of Director</material.MenuItem>
                      <material.MenuItem value={"Key Management Personnel"}>Key Management Personnel</material.MenuItem>
                      <material.MenuItem value={"Governing Body (Other)"}>Governing Body (Other)</material.MenuItem>
                    </material.Select>
                  </material.FormControl>
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("male", { required: true, pattern: /^[0-9]+$/, })}
                    required
                    margin="dense"
                    label="Male"
                    id="male"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                  />
                  {errors.male && errors.male.type === "pattern" && (
                    <p className="errorMsg text-danger">No decimal allowed</p>
                  )}
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("female", { required: true, pattern: /^[0-9]+$/ })}
                    required
                    margin="dense"
                    label="Female"
                    id="female"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                  />
                  {errors.female && errors.female.type === "pattern" && (
                    <p className="errorMsg text-danger">No decimal allowed</p>
                  )}
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("other", { required: true, pattern: /^[0-9]+$/ })}
                    required
                    margin="dense"
                    label="Other"
                    id="other"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                  />
                  {errors.other && errors.other.type === "pattern" && (
                    <p className="errorMsg text-danger">No decimal allowed</p>
                  )}
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("total", { required: true, })}
                    required
                    margin="dense"
                    label="Total"
                    id="total"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ readOnly: true }}
                  />
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("vulnerable", { required: true, pattern: /^[0-9]+$/ })}
                    required
                    margin="dense"
                    label="Vulnerable"
                    id="vulnerable"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                  />
                  {errors.vulnerable && errors.vulnerable.type === "pattern" && (
                    <p className="errorMsg text-danger">No decimal allowed</p>
                  )}
                </div>
              </div>
            </div>
          </material.Card>
          {isEmployeeAgeBasedDataPresent === "Yes" ? (
            <material.Card elevation={1} sx={{ p: 2, border: 1, mt: 2 }}>
              <div className='row mt-2'>
                <div className='py-2'>
                  <material.Typography className='fw-bold headingText'>Diversity of governance bodies age based data</material.Typography>
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("male_under_30", { required: true, pattern: /^[0-9]+$/ })}
                    required
                    margin="dense"
                    label="Male Under 30"
                    id="male_under_30"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                  />
                  {errors.male_under_30 && errors.male_under_30.type === "pattern" && (
                    <p className="errorMsg text-danger">No decimal allowed</p>
                  )}
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("male_30_to_50", { required: true, pattern: /^[0-9]+$/ })}
                    required
                    margin="dense"
                    label="Male 30 to 50"
                    id="male_30_to_50"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                  />
                  {errors.male_30_to_50 && errors.male_30_to_50.type === "pattern" && (
                    <p className="errorMsg text-danger">No decimal allowed</p>
                  )}
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("male_above_50", { required: true, pattern: /^[0-9]+$/ })}
                    required
                    margin="dense"
                    label="Male Above 50"
                    id="male_above_50"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                  />
                  {errors.male_above_50 && errors.male_above_50.type === "pattern" && (
                    <p className="errorMsg text-danger">No decimal allowed</p>
                  )}
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("female_under_30", { required: true, pattern: /^[0-9]+$/ })}
                    required
                    margin="dense"
                    label="Female Under 30"
                    id="female_under_30"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                  />
                  {errors.female_under_30 && errors.female_under_30.type === "pattern" && (
                    <p className="errorMsg text-danger">No decimal allowed</p>
                  )}
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("female_30_to_50", { required: true, pattern: /^[0-9]+$/ })}
                    required
                    margin="dense"
                    label="Female 30 to 50"
                    id="female_30_to_50"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                  />
                  {errors.female_30_to_50 && errors.female_30_to_50.type === "pattern" && (
                    <p className="errorMsg text-danger">No decimal allowed</p>
                  )}
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("female_above_50", { required: true, pattern: /^[0-9]+$/ })}
                    required
                    margin="dense"
                    label="Female Above 50"
                    id="female_above_50"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                  />
                  {errors.female_above_50 && errors.female_above_50.type === "pattern" && (
                    <p className="errorMsg text-danger">No decimal allowed</p>
                  )}
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("other_under_30", { required: true, pattern: /^[0-9]+$/ })}
                    required
                    margin="dense"
                    label="Other Under 30"
                    id="other_under_30"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                  />
                  {errors.other_under_30 && errors.other_under_30.type === "pattern" && (
                    <p className="errorMsg text-danger">No decimal allowed</p>
                  )}
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("other_30_to_50", { required: true, pattern: /^[0-9]+$/ })}
                    required
                    margin="dense"
                    label="Other 30 to 50"
                    id="other_30_to_50"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                  />
                  {errors.other_30_to_50 && errors.other_30_to_50.type === "pattern" && (
                    <p className="errorMsg text-danger">No decimal allowed</p>
                  )}
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("other_above_50", { required: true, pattern: /^[0-9]+$/ })}
                    required
                    margin="dense"
                    label="Other Above 50"
                    id="other_above_50"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                  />
                  {errors.other_above_50 && errors.other_above_50.type === "pattern" && (
                    <p className="errorMsg text-danger">No decimal allowed</p>
                  )}
                </div>
              </div>
            </material.Card>
          ) : null}
        </material.DialogContent>
        <material.DialogActions className="m-3">
          <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
          {openAddDiversity.diversityOfGovernanceBodiesData ? (
            <>
              <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateDiversityOfGovernanceBodies)} startIcon={<material.DoneIcon />}>Update</material.Button>
              {(openAddDiversity.diversityOfGovernanceBodiesData.rejection_note === null) || (openAddDiversity.diversityOfGovernanceBodiesData.rejection_note === "") ? (
                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
              ) : null}
            </>
          ) : (
            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveDiversityOfGovernanceBodies)} startIcon={<material.DoneIcon />}>Save</material.Button>
          )}
        </material.DialogActions>
      </material.Dialog>
      <Snackbar
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
      />
      <DeleteRecordConfirmationDialog
        openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
        setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
        treeViewData={treeViewData}
        deleteRecordByRefNo={deleteRecordByRefNo}
      />
    </div>
  )
}

export default AddDiversityOfGovernanceBodies;