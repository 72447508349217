import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import Snackbar from '../../../toaster-message/Snackbar';
import moment from 'moment';
import { getDataFilterByCurrentApprovedDataAndNotApprovedDate } from '../../../shared/Filter';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';
import { getSubIssueRecordData, saveSubIssueData, updateSubIssueData } from '../../../services/sub-issue-services/AddSubissueService';
import Pagination from '../../../shared/pagination/Pagination';
import ApplicableDialog from '../../../dialogs/ApplicableDialog';


const label = { inputProps: { 'aria-label': 'Color switch demo', 'aria-label2': 'Checkbox demo' } };
// var recordRefNo = [];

function GhgScope1(props) {
    const location = useLocation();
    const treeViewData = location?.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [scope1GhgEmissionTabValue, setScope1GhgEmissionTabValue] = useState('Direct GHG Emission');
    const [scope1TableData, setScope1TableData] = useState([])
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = React.useState(0);
    const [getFilterDate, setGetFilterDate] = useState(null);
    const [recordRefNo, setRecordRefNo] = useState([]);


    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        getGhgScope1AllData();
    }, [scope1GhgEmissionTabValue]);


    const getFormDateToDate = (data) => {
        setGetFilterDate(data);
        getGhgScope1AllData(data, paginationData);
    };

    const getGhgScope1AllData = async (filterDate, paginationValue) => {
        if (scope1GhgEmissionTabValue === "Direct GHG Emission") {
            let forApproval = userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? true : null;
            let approveHistory = false;
            let object = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                subissueName: treeViewData.subIssue,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            await getSubIssueRecordData(object)
                .then((resp) => {
                    // const filterData = getDataFilterByCurrentApprovedDataAndNotApprovedDate(resp.data.records.content);
                    setScope1TableData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setApplicableRefNo(resp.data.applicableRecord?.ref_no ? resp.data.applicableRecord.ref_no : null);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else if (scope1GhgEmissionTabValue === "Direct GHG Emission History") {
            let forApproval = true;
            let approveHistory = true;
            let object = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                subissueName: treeViewData.subIssue,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            await getSubIssueRecordData(object)
                .then((resp) => {
                    setScope1TableData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };
    const changeDirectScope1GhgEmissionTab = (event, newValue) => {
        setScope1GhgEmissionTabValue(newValue);
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };

    const clickCheckBox = (value) => {
        setRecordRefNo(prevRecordRefNo =>
            prevRecordRefNo.includes(value) ? prevRecordRefNo.filter(item => item !== value) : [...prevRecordRefNo, value]
        );
    };

    const sendForApprovalScope1 = async () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true
            }
            await updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    getGhgScope1AllData(getFilterDate, paginationData);
                    setRecordRefNo([]);
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };
    const approveScope1 = async () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true,
                "approved_by": userDetails.username,
                "approved_date": moment().format("YYYY-MM-DD"),
                "approved_status": "APPROVED",
                "rejection_note": "",
            }
            await updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    getGhgScope1AllData(getFilterDate, paginationData);
                    setRecordRefNo([]);
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    const rejectScope1 = () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        }
    };

    const getRejectionNoteData = async (noteData) => {
        const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
        const subissueName = treeViewData.subIssue;
        const payload = {
            send_for_approval: false,
            "rejection_note": noteData.rejectionNote,
            "approved_status": "",
        }
        await updateSubIssueData(payload, refNo, subissueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getGhgScope1AllData(getFilterDate, paginationData);
                setRecordRefNo([]);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const getApplicableData = () => {
        if (applicableRefNo === null) {
            let subIssueName = treeViewData?.subIssue;
            const payload = {
                applicable: false,
                applicable_record: true,
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
            }
            saveSubIssueData(payload, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getGhgScope1AllData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else {
            let subIssueName = treeViewData?.subIssue;
            let refNo = applicableRefNo;
            const payload = {
                applicable: applicable ? false : true,
            }
            updateSubIssueData(payload, refNo, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getGhgScope1AllData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const getPaginationData = (filterData, paginationData) => {
        setRowsPerPage(paginationData.size);
        setPage(paginationData.page);
        getGhgScope1AllData(filterData, paginationData);
    };

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 2, mt: 7 }}>
                    <div className='row'>
                        <div className='col-6'>
                            <span><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
                        </div>
                        <div className='col-6' style={{ display: 'flex', justifyContent: 'end' }}>
                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") || userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? null : (
                                <span className="float-end">
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} onChange={applicableNotApplicable}
                                            checked={applicable}
                                            color="secondary" />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>
                    <material.TabContext value={scope1GhgEmissionTabValue}>
                        <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <material.TabList onChange={changeDirectScope1GhgEmissionTab} aria-label="lab API tabs example">
                                <material.Tab className='text-capitalize' label="GHG Scope 1" value="Direct GHG Emission" />
                                <material.Tab className='text-capitalize' label="GHG Scope 1 History" value="Direct GHG Emission History" />
                            </material.TabList>
                        </material.Box>
                        <material.TabPanel value="Direct GHG Emission">
                            <div className="row">
                                <div className="col-6">
                                    {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                        <DateFilter
                                            getFormDateToDate={getFormDateToDate}
                                        />
                                    )}
                                </div>
                                <div className="col-6" hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                    <span className='float-end'>
                                        {scope1TableData?.length ? (
                                            <div>
                                                <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")} color='success'
                                                    disabled={!applicable || !recordRefNo?.length}
                                                    sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approveScope1}>Approve</material.Button>
                                                <material.Button sx={{ textTransform: "none" }} hidden={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")}
                                                    disabled={!applicable || !recordRefNo?.length}
                                                    variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectScope1}>Reject</material.Button>
                                            </div>
                                        ) : null}
                                        <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_APPROVER")} color='success'
                                            disabled={!applicable}
                                            sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendForApprovalScope1}>Send for Approval</material.Button>
                                    </span>
                                </div>
                            </div>
                            <material.Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                                <material.TableContainer>
                                    <material.Table>
                                        <material.TableHead>
                                            <material.TableRow >
                                                <StyledTableCell>Type of Source</StyledTableCell>
                                                <StyledTableCell>Name</StyledTableCell>
                                                <StyledTableCell>Quantity (Tonne)</StyledTableCell>
                                                <StyledTableCell>CO2 Emission Factor (tCO2e/t-mat.)</StyledTableCell>
                                                <StyledTableCell>GWP (tCO2e)</StyledTableCell>
                                                <StyledTableCell>GHG Quantity (tCO2e)</StyledTableCell>
                                                <StyledTableCell>From Date</StyledTableCell>
                                                <StyledTableCell>To Date</StyledTableCell>
                                                <StyledTableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>{userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                    <StyledTableCell>Rejection Note</StyledTableCell>
                                                )}
                                            </material.TableRow>
                                        </material.TableHead>

                                        <material.TableBody>
                                            {scope1TableData?.length ? scope1TableData.map((item, ind) => (
                                                <material.TableRow key={ind}>
                                                    <material.TableCell sx={{ maxWidth: 200 }}>{item.type_of_source}</material.TableCell>
                                                    <material.TableCell>{item.name}</material.TableCell>
                                                    <material.TableCell>{item.quantity?.toFixed(2)}</material.TableCell>
                                                    <material.TableCell>{item.emission_factor}</material.TableCell>
                                                    <material.TableCell>{item.gwp}</material.TableCell>
                                                    <material.TableCell>{item.ghg_quantity?.toFixed(2)}</material.TableCell>
                                                    <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                    <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                    <material.TableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                                        {userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") ? (
                                                            <material.Checkbox {...label} disabled={!applicable || item.send_for_approval} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                        ) : (
                                                            <material.Checkbox {...label} disabled={!applicable || item.approved_status === "APPROVED"} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                        )}
                                                    </material.TableCell>
                                                    {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                        <material.TableCell>{(item.rejection_note === null) || (item.rejection_note === "") ? "No" : (
                                                            <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                        )}
                                                        </material.TableCell>
                                                    )}
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={9}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </material.TableBody>
                                    </material.Table>
                                </material.TableContainer>
                                <Pagination
                                    getPaginationData={getPaginationData}
                                    totalElements={totalElements}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    getFilterDate={getFilterDate}
                                />
                            </material.Paper>
                        </material.TabPanel>

                        <material.TabPanel value="Direct GHG Emission History">
                            <div className="row">
                                <div className="col-8">
                                    <DateFilter
                                        getFormDateToDate={getFormDateToDate}
                                    />
                                </div>
                            </div>
                            <material.Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                                <material.TableContainer>
                                    <material.Table>
                                        <material.TableHead>
                                            <material.TableRow >
                                                <StyledTableCell>Type of Source</StyledTableCell>
                                                <StyledTableCell>Name</StyledTableCell>
                                                <StyledTableCell>Quantity (Tonne)</StyledTableCell>
                                                <StyledTableCell>CO2 Emission Factor (tCO2e/t-mat.)</StyledTableCell>
                                                <StyledTableCell>GWP (tCO2e)</StyledTableCell>
                                                <StyledTableCell>GHG Quantity (tCO2e)</StyledTableCell>
                                                <StyledTableCell>From Date</StyledTableCell>
                                                <StyledTableCell>To Date</StyledTableCell>
                                                <StyledTableCell>Approval Date</StyledTableCell>
                                                <StyledTableCell>Status</StyledTableCell>
                                            </material.TableRow>
                                        </material.TableHead>
                                        <material.TableBody>
                                            {scope1TableData?.length ? scope1TableData.map((item, ind) => (
                                                <material.TableRow key={ind} >
                                                    <material.TableCell sx={{ maxWidth: 200 }}>{item.type_of_source}</material.TableCell>
                                                    <material.TableCell>{item.name}</material.TableCell>
                                                    <material.TableCell>{item.quantity?.toFixed(2)}</material.TableCell>
                                                    <material.TableCell>{item.emission_factor}</material.TableCell>
                                                    <material.TableCell>{item.gwp}</material.TableCell>
                                                    <material.TableCell>{item.ghg_quantity?.toFixed(2)}</material.TableCell>
                                                    <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                    <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                    <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                    <material.TableCell>
                                                        {item.approved_status === "APPROVED" ? <span className="badge bg-success">
                                                            APPROVED
                                                        </span> : null}
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={9}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </material.TableBody>
                                    </material.Table>
                                </material.TableContainer>
                                <Pagination
                                    getPaginationData={getPaginationData}
                                    totalElements={totalElements}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    getFilterDate={getFilterDate}
                                />
                            </material.Paper>
                        </material.TabPanel>
                    </material.TabContext>
                </material.Box>
            </material.Box>
            <ApplicableDialog
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getApplicableData={getApplicableData}
                applicable={applicable}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default GhgScope1;

