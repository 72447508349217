import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import DatePeriod from '../../shared/DatePeriod';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import { commonDelete, saveSubIssueData, saveUsbIssueData, updateSubIssueData, updateUsbIssueData } from '../../services/sub-issue-services/AddSubissueService';
import moment from 'moment';
import { useSelector } from 'react-redux';

function AddODSEmissionImport(props) {
    const { register, handleSubmit, reset, control, watch, getValues, setValue, formState: { errors }, } = useForm({ mode: "onTouched" });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { openAddODSEmissionImport, setOpenAddODSEmissionImport, getAllODSEmissionImportData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const [unit, setUnit] = useState("");
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openAddODSEmissionImport.action && openAddODSEmissionImport.oDSEmissionImportTableData) {
            setUnit(openAddODSEmissionImport.oDSEmissionImportTableData.unit_cfc);
            reset(openAddODSEmissionImport.oDSEmissionImportTableData);
        }
    }, [openAddODSEmissionImport.action === true]);

    const changeUnit = (event) => {
        setUnit(event.target.value);
    };

    const handleClose = () => {
        setOpenAddODSEmissionImport({ action: false, oDSEmissionImportTableData: null });
        reset({ "annex": "", "substances": "", "total_quant_new_imported_for_all_uses": "", "total_quant_recovered_imported_for_all_uses": "", "quantity_new_substances_imported_as_feedstock": "", "quant_new_substances_imported_essential_uses": "", })
        setUnit("");
    };

    const saveODSEmissionImport = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "annex": data.annex,
                "substances": data.substances,
                "total_quant_new_imported_for_all_uses": data.total_quant_new_imported_for_all_uses,
                "total_quant_recovered_imported_for_all_uses": data.total_quant_recovered_imported_for_all_uses,
                "quantity_new_substances_imported_as_feedstock": data.quantity_new_substances_imported_as_feedstock,
                "quant_new_substances_imported_essential_uses": data.quant_new_substances_imported_essential_uses,
                "unit_cfc": data.unit_cfc,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllODSEmissionImportData(getFilterDate, paginationData);
                    setOpenAddODSEmissionImport({ action: false, oDSEmissionImportTableData: null });
                    reset({ "annex": "", "substances": "", "total_quant_new_imported_for_all_uses": "", "total_quant_recovered_imported_for_all_uses": "", "quantity_new_substances_imported_as_feedstock": "", "quant_new_substances_imported_essential_uses": "", })
                    setUnit("");
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateODSEmissionImport = async (data) => {
        const refNo = openAddODSEmissionImport.oDSEmissionImportTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "annex": data.annex,
            "substances": data.substances,
            "total_quant_new_imported_for_all_uses": data.total_quant_new_imported_for_all_uses,
            "total_quant_recovered_imported_for_all_uses": data.total_quant_recovered_imported_for_all_uses,
            "quantity_new_substances_imported_as_feedstock": data.quantity_new_substances_imported_as_feedstock,
            "quant_new_substances_imported_essential_uses": data.quant_new_substances_imported_essential_uses,
            "unit_cfc": data.unit_cfc,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllODSEmissionImportData(getFilterDate, paginationData);
                setOpenAddODSEmissionImport({ action: false, oDSEmissionImportTableData: null });
                reset({ "annex": "", "substances": "", "total_quant_new_imported_for_all_uses": "", "total_quant_recovered_imported_for_all_uses": "", "quantity_new_substances_imported_as_feedstock": "", "quant_new_substances_imported_essential_uses": "", })
                setUnit("");
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAddODSEmissionImport.oDSEmissionImportTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ "annex": "", "substances": "", "total_quant_new_imported_for_all_uses": "", "total_quant_recovered_imported_for_all_uses": "", "quantity_new_substances_imported_as_feedstock": "", "quant_new_substances_imported_essential_uses": "", })
                setUnit("");
                getAllODSEmissionImportData(getFilterDate, paginationData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddODSEmissionImport({ action: false, oDSEmissionImportTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };


    return (
        <div>
            <material.Dialog open={openAddODSEmissionImport.action} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold headingText'>{openAddODSEmissionImport.oDSEmissionImportTableData ? "Edit ODS Import" : "Add ODS Import"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddODSEmissionImport.oDSEmissionImportTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 1, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>ODS Import</material.Typography>
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("annex", { required: true, })}
                                    required
                                    label="Annex/Group"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    multiline
                                />

                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("substances", { required: true, })}
                                    required
                                    label="Substances Name"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    multiline
                                />

                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("total_quant_new_imported_for_all_uses", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Total Quantity (new) Imported for All Uses"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.total_quant_new_imported_for_all_uses && errors.total_quant_new_imported_for_all_uses.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("total_quant_recovered_imported_for_all_uses", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Recovered & Reclaimed Quantity Imported for All Uses"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.total_quant_recovered_imported_for_all_uses && errors.total_quant_recovered_imported_for_all_uses.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("quantity_new_substances_imported_as_feedstock", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Quantity Used as Feedstock"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.quantity_new_substances_imported_as_feedstock && errors.quantity_new_substances_imported_as_feedstock.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("quant_new_substances_imported_essential_uses", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Quantity  Used as Exempted Essential"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.quant_new_substances_imported_essential_uses && errors.quant_new_substances_imported_essential_uses.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Unit CFC</material.InputLabel>
                                    <material.Select
                                        {...register("unit_cfc", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={unit}
                                        onChange={changeUnit}
                                        label="Unit CFC"
                                    >
                                        <material.MenuItem value={"Metric Tons"}>Metric Tons</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddODSEmissionImport.oDSEmissionImportTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateODSEmissionImport)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddODSEmissionImport.oDSEmissionImportTableData.rejection_note === null) || (openAddODSEmissionImport.oDSEmissionImportTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveODSEmissionImport)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddODSEmissionImport;