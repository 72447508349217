import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import DatePeriod from '../../shared/DatePeriod';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import { fuelUsedSubIssueDelete, saveOrUpdateFuelUsedSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


const renewableNameData = ['Natural Gas', 'Wood / Wood Waste / Agri Waste', 'Black Liqour', 'Other Primary Solid Biomass Fuels', 'Charcoal', 'Biogasoline', 'Biodiesels', 'Other Liquid Biofuels', 'Biogas',];
const nonRenewableNameData = ['Crude Oil', 'Liquefied Natural Gas', 'Petrol', 'Aviation Turbine Fuel', 'Diesel Oil', 'Residual / Heavy Fuel Oil', 'Liquified Petroleum Gases', 'Petroleum Coke', 'Other Petroleum Products', 'Anthracite', 'Coking Coal', 'Bituminous Coal', 'Sub Bituminous Coal / TPP Coal', 'Coke',];

function AddFuelUsed(props) {
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, setValue, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const { openAddFuelUsed, setOpenAddFuelUsed, treeViewData, getAllFuelUsedData, page, rowsPerPage, getFilterDate } = props;
    const [typeOfSourceData, setTypeOfSourceData] = useState("");
    const [nameData, setNameData] = useState("");
    const [purposeOfUseData, setPurposeOfUseData] = useState("");
    const [renewableName, setRenewableName] = useState([]);
    const [unit, setUnit] = useState("");
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const payload = {
        page,
        size: rowsPerPage
    };

    const mapFuelUsedData = (data) => {
        return {
            refNo: data.ref_no,
            applicable: data.applicable,
            typeOfSourced: data.type_of_sourced,
            fuelName: data.fuel_name,
            purposeOfUse: data.purpose_of_use,
            quantity: data.quantity,
            unit: data.unit,
            grossCalorificValue: data.gross_calorific_value,
        };
    };

    useEffect(() => {
        if (openAddFuelUsed.action && openAddFuelUsed.fuelUsedTableData) {
            const mappedData = mapFuelUsedData(openAddFuelUsed.fuelUsedTableData);
            setTypeOfSourceData(mappedData.typeOfSourced);
            if (mappedData.typeOfSourced === 'Renewable') {
                setRenewableName(renewableNameData);
            } else if (mappedData.typeOfSourced === 'Non Renewable') {
                setRenewableName(nonRenewableNameData);
            }
            setNameData(mappedData.fuelName);
            setPurposeOfUseData(mappedData.purposeOfUse);
            setUnit(mappedData.unit);
            reset(mappedData);
        }
    }, [openAddFuelUsed.action === true]);

    const selectTypeOfSource = (event) => {
        const selectTypeOfSourceData = (event.target.value);
        setTypeOfSourceData(selectTypeOfSourceData);
        if (selectTypeOfSourceData === 'Renewable') {
            setRenewableName(renewableNameData);
        } else if (selectTypeOfSourceData === 'Non Renewable') {
            setRenewableName(nonRenewableNameData);
        }
    };

    const selectName = (event) => {
        setNameData(event.target.value);
    };

    const selectPurposeOfUse = (event) => {
        setPurposeOfUseData(event.target.value);
    };

    const selectUnit = (event) => {
        setUnit(event.target.value);
    };

    const handleClose = () => {
        reset({ "quantity": "", "grossCalorificValue": "", });
        setTypeOfSourceData("");
        setNameData("");
        setRenewableName([]);
        setPurposeOfUseData("");
        setUnit("");
        setOpenAddFuelUsed({ action: false, fuelUsedTableData: null });
    };

    const submitFuelUsedData = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            const object = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "fromDate": payLoad?.fromDate,
                "toDate": payLoad?.toDate,
                "applicable": true,
                "quantity": parseFloat(data.quantity),
                "grossCalorificValue": parseFloat(data.grossCalorificValue),
                ...data
            }
            await saveOrUpdateFuelUsedSubIssueData(object)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllFuelUsedData(getFilterDate, payload);
                    reset({ "quantity": "", "grossCalorificValue": "", });
                    setTypeOfSourceData("");
                    setNameData("");
                    setRenewableName([]);
                    setPurposeOfUseData("");
                    setUnit("");
                    setOpenAddFuelUsed({ action: false, fuelUsedTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        }
    };

    const updateFuelUsedData = async (data) => {
        const payloadData = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "refNo": openAddFuelUsed.fuelUsedTableData.ref_no,
            "applicable": true,
            "typeOfSourced": data.typeOfSourced,
            "fuelName": data.fuelName,
            "purposeOfUse": data.purposeOfUse,
            "quantity": data.quantity,
            "unit": data.unit,
            "grossCalorificValue": data.grossCalorificValue
        }
        await saveOrUpdateFuelUsedSubIssueData(payloadData)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllFuelUsedData(getFilterDate, payload);
                reset({ "quantity": "", "grossCalorificValue": "", })
                setTypeOfSourceData("");
                setNameData("");
                setRenewableName([]);
                setPurposeOfUseData("");
                setUnit("");
                setOpenAddFuelUsed({ action: false, fuelUsedTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAddFuelUsed.fuelUsedTableData.ref_no,
        }
        fuelUsedSubIssueDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllFuelUsedData(getFilterDate, payload);
                reset({ "quantity": "", "grossCalorificValue": "", });
                setTypeOfSourceData("");
                setNameData("");
                setRenewableName([]);
                setPurposeOfUseData("");
                setUnit("");
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddFuelUsed({ action: false, fuelUsedTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddFuelUsed.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddFuelUsed.fuelUsedTableData ? "Edit Fuel Used" : "Add Fuel Used"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddFuelUsed.fuelUsedTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Fuel Used</material.Typography>
                            </div>
                            <div className='col-4'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Type of Source</material.InputLabel>
                                    <material.Select
                                        {...register("typeOfSourced", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={typeOfSourceData}
                                        onChange={selectTypeOfSource}
                                        label="Material Name"
                                    >
                                        <material.MenuItem value={'Renewable'}>Renewable</material.MenuItem>
                                        <material.MenuItem value={'Non Renewable'}>Non Renewable</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Fuel Name</material.InputLabel>
                                    <material.Select
                                        {...register("fuelName", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={nameData}
                                        onChange={selectName}
                                        label="Material Name"
                                    >
                                        {renewableName?.map((item, i) =>
                                            <material.MenuItem key={i} value={item}>{item}</material.MenuItem>
                                        )}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Purpose of Use</material.InputLabel>
                                    <material.Select
                                        {...register("purposeOfUse", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={purposeOfUseData}
                                        onChange={selectPurposeOfUse}
                                        label="Material Name"
                                    >
                                        <material.MenuItem value={'Stationary Combustion'}>Stationary Combustion</material.MenuItem>
                                        <material.MenuItem value={'Physical or Chemical Processing'}>Physical or Chemical Processing</material.MenuItem>
                                        <material.MenuItem value={'Transportation of Materials, Products, Waste, Workers, and Passengers'}>Transportation of Materials, Products, Waste, Workers, and Passengers</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4 mt-2'>
                                <material.TextField
                                    {...register("quantity", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Quantity"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.quantity?.type === "required" && 'This field is required'}</p>
                                {errors.quantity && errors.quantity.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 2nd digit after decimal</p>
                                )}
                            </div>
                            <div className='col-4 mt-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Unit</material.InputLabel>
                                    <material.Select
                                        {...register("unit", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={unit}
                                        onChange={selectUnit}
                                        label="Material Name"
                                    >
                                        <material.MenuItem value={'Tonne'}>Tonne</material.MenuItem>
                                        <material.MenuItem value={'Kl'}>Kl</material.MenuItem>
                                        <material.MenuItem value={'Km3'}>Km3</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4 mt-2'>
                                <material.TextField
                                    {...register("grossCalorificValue", { required: true, pattern: /^\d*\.?\d{0,2}$/ })}
                                    required
                                    label="Gross Calorific Value (kcal)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.grossCalorificValue?.type === "required" && 'This field is required'}</p>
                                {errors.grossCalorificValue && errors.grossCalorificValue.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 2nd digit after decimal</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddFuelUsed.fuelUsedTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateFuelUsedData)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddFuelUsed.fuelUsedTableData.rejection_note === null) || (openAddFuelUsed.fuelUsedTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(submitFuelUsedData)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div >
    )
}

export default AddFuelUsed;