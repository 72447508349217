import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { material } from '../../library/Material';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';

function AddHabitatsProtectedOrRestored(props) {

    const { openHabitatsProtectedRestored, setOpenHabitatsProtectedRestored, getAllHabitatsProtectedData, treeViewData, page, rowsPerPage, getFilterDate } = props;

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, watch, getValues, setValue, formState: { errors }, } = useForm({ mode: "onTouched" });

    const [habitatProtectedOrRestored, setHabitatProtectedOrRestored] = useState("");
    const [protectOrRestoreHabitatBy, setProtectOrRestoreHabitatBy] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openHabitatsProtectedRestored.action && openHabitatsProtectedRestored.HabitatsProtectedRestoredData) {
            setHabitatProtectedOrRestored(openHabitatsProtectedRestored.HabitatsProtectedRestoredData.habitat_protected_or_restored);
            setProtectOrRestoreHabitatBy(openHabitatsProtectedRestored.HabitatsProtectedRestoredData.protect_or_restore_habitat_by);
            reset(openHabitatsProtectedRestored.HabitatsProtectedRestoredData)
        }
    }, [openHabitatsProtectedRestored.action === true])

    const handleClose = () => {
        reset({ size_of_protected_or_restored_area: "", status_of_protected_or_restored_area: "" })
        setHabitatProtectedOrRestored("");
        setProtectOrRestoreHabitatBy("");
        setOpenHabitatsProtectedRestored({ action: false, HabitatsProtectedRestoredData: null });
    };

    const changeHabitatProtectedOrRestored = (event) => {
        setHabitatProtectedOrRestored(event.target.value)
    };

    const changeProtectOrRestoreHabitatBy = (event) => {
        setProtectOrRestoreHabitatBy(event.target.value)
    };

    const saveHabitatsProtectedOrRestored = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "habitat_protected_or_restored": data.habitat_protected_or_restored,
                "size_of_protected_or_restored_area": data.size_of_protected_or_restored_area,
                "protect_or_restore_habitat_by": data.protect_or_restore_habitat_by,
                "status_of_protected_or_restored_area": data.status_of_protected_or_restored_area,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(object, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllHabitatsProtectedData(getFilterDate, paginationData);
                    reset({ size_of_protected_or_restored_area: "", status_of_protected_or_restored_area: "" })
                    setHabitatProtectedOrRestored("");
                    setProtectOrRestoreHabitatBy("");
                    setOpenHabitatsProtectedRestored({ action: false, HabitatsProtectedRestoredData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        }
    };

    const updateHabitatsProtectedOrRestored = async (data) => {
        const refNo = openHabitatsProtectedRestored.HabitatsProtectedRestoredData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "habitat_protected_or_restored": data.habitat_protected_or_restored,
            "size_of_protected_or_restored_area": data.size_of_protected_or_restored_area,
            "protect_or_restore_habitat_by": data.protect_or_restore_habitat_by,
            "status_of_protected_or_restored_area": data.status_of_protected_or_restored_area,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllHabitatsProtectedData(getFilterDate, paginationData);
                reset({ size_of_protected_or_restored_area: "", status_of_protected_or_restored_area: "" })
                setHabitatProtectedOrRestored("");
                setProtectOrRestoreHabitatBy("");
                setOpenHabitatsProtectedRestored({ action: false, HabitatsProtectedRestoredData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openHabitatsProtectedRestored.HabitatsProtectedRestoredData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllHabitatsProtectedData(getFilterDate, paginationData);
                reset({ size_of_protected_or_restored_area: "", status_of_protected_or_restored_area: "" })
                setHabitatProtectedOrRestored("");
                setProtectOrRestoreHabitatBy("");
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenHabitatsProtectedRestored({ action: false, HabitatsProtectedRestoredData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };


    return (
        <div>
            <material.Dialog open={openHabitatsProtectedRestored.action} maxWidth="md" fullWidth>
                <material.DialogTitle className='fw-bold headingText'>
                    {openHabitatsProtectedRestored.HabitatsProtectedRestoredData ? "Edit Habitats Protected or Restored" : "Add Habitats Protected or Restored"}
                </material.DialogTitle>
                <material.DialogContent>
                    {openHabitatsProtectedRestored.HabitatsProtectedRestoredData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Habitats Protected or Restored</material.Typography>
                            </div>
                            <div className='col-lg-6 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Habitat Protected or Restored</material.InputLabel>
                                    <material.Select
                                        {...register("habitat_protected_or_restored", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={habitatProtectedOrRestored}
                                        onChange={changeHabitatProtectedOrRestored}
                                        label="Habitat Protected or Restored"
                                    >
                                        <material.MenuItem value={"Natural Terrestrial"}>Natural Terrestrial</material.MenuItem>
                                        <material.MenuItem value={"Modified Terrestrial"}>Modified Terrestrial</material.MenuItem>
                                        <material.MenuItem value={"Natural Aquatic"}>Natural Aquatic</material.MenuItem>
                                        <material.MenuItem value={"Modified Aquatic"}>Modified Aquatic</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-6 py-2'>
                                <material.TextField
                                    {...register("size_of_protected_or_restored_area", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Size of Protected or Restored  Area (ha)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.size_of_protected_or_restored_area && errors.size_of_protected_or_restored_area.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-lg-6 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Protect or Restore Habitat by</material.InputLabel>
                                    <material.Select
                                        {...register("protect_or_restore_habitat_by", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={protectOrRestoreHabitatBy}
                                        onChange={changeProtectOrRestoreHabitatBy}
                                        label="Protect or Restore Habitat by"
                                    >
                                        <material.MenuItem value={"Owner"}>Owner</material.MenuItem>
                                        <material.MenuItem value={"Govt. Dept."}>Govt. Dept.</material.MenuItem>
                                        <material.MenuItem value={"Partnerships with 3rd Parties"}>Partnerships with 3rd Parties</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-6 py-2'>
                                <material.TextField
                                    {...register("status_of_protected_or_restored_area", { required: true })}
                                    required
                                    label="Status of Protected or Restored Area"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                {/* {errors.amount && errors.amount.type === "pattern" && (
                                <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                            )} */}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openHabitatsProtectedRestored.HabitatsProtectedRestoredData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateHabitatsProtectedOrRestored)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openHabitatsProtectedRestored.HabitatsProtectedRestoredData.rejection_note === null) || (openHabitatsProtectedRestored.HabitatsProtectedRestoredData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveHabitatsProtectedOrRestored)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddHabitatsProtectedOrRestored;