import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import { useSelector } from 'react-redux';
import moment from 'moment';


function AddStakeholderManagement(props) {
    const { register, handleSubmit, reset, control, watch, formState: { errors }, } = useForm();
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { openAddStakeholderManagement, setOpenAddStakeholderManagement, getAllWasteGenerationData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const [stakeholderVulnerableMarginalizedGroup, setStakeholderVulnerableMarginalizedGroup] = useState("");
    const [frequencyEngagement, setFrequencyEngagement] = useState("");
    const [channelsCommunication, setChannelsCommunication] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openAddStakeholderManagement.action && openAddStakeholderManagement.stakeholderManagementTableData) {
            setStakeholderVulnerableMarginalizedGroup(openAddStakeholderManagement.stakeholderManagementTableData.stakeholder_is_vulnerable_marginalized_group);
            setFrequencyEngagement(openAddStakeholderManagement.stakeholderManagementTableData.frequency_of_engagement);
            setChannelsCommunication(openAddStakeholderManagement.stakeholderManagementTableData.channels_of_communication);
            reset(openAddStakeholderManagement.stakeholderManagementTableData);
        }
    }, [openAddStakeholderManagement.action === true]);

    const handleClose = () => {
        reset({ stakeholder_group: "", purpose_scope_key_topics_and_concerns_raised: "", frequency_other_specify: "", channels_other_specify: "", });
        setStakeholderVulnerableMarginalizedGroup("");
        setFrequencyEngagement("");
        setChannelsCommunication("");
        setOpenAddStakeholderManagement({ action: false, stakeholderManagementTableData: null })
    };

    const selectStakeholderVulnerableMarginalizedGroup = (event) => {
        setStakeholderVulnerableMarginalizedGroup(event.target.value)
    };

    const saveStakeholderManagementData = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "stakeholder_group": data.stakeholder_group,
                "stakeholder_is_vulnerable_marginalized_group": data.stakeholder_is_vulnerable_marginalized_group,
                "purpose_scope_key_topics_and_concerns_raised": data.purpose_scope_key_topics_and_concerns_raised,
                "frequency_of_engagement": data.frequency_of_engagement,
                "frequency_other_specify": data.frequency_other_specify,
                "channels_of_communication": data.channels_of_communication,
                "channels_other_specify": data.channels_other_specify,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllWasteGenerationData(getFilterDate, payloadData);
                    reset({ stakeholder_group: "", purpose_scope_key_topics_and_concerns_raised: "", frequency_other_specify: "", channels_other_specify: "", });
                    setStakeholderVulnerableMarginalizedGroup("");
                    setFrequencyEngagement("");
                    setChannelsCommunication("");
                    setOpenAddStakeholderManagement({ action: false, stakeholderManagementTableData: null })
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };

    };

    const updateStakeholderManagementData = async (data) => {
        const refNo = openAddStakeholderManagement.stakeholderManagementTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "stakeholder_group": data.stakeholder_group,
            "stakeholder_is_vulnerable_marginalized_group": data.stakeholder_is_vulnerable_marginalized_group,
            "purpose_scope_key_topics_and_concerns_raised": data.purpose_scope_key_topics_and_concerns_raised,
            "frequency_of_engagement": data.frequency_of_engagement,
            "frequency_other_specify": frequencyEngagement === "Other (Specify)" ? data.frequency_other_specify : "",
            "channels_of_communication": data.channels_of_communication,
            "channels_other_specify": channelsCommunication === "Other (Specify)" ? data.channels_other_specify : "",
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllWasteGenerationData(getFilterDate, payloadData);
                reset({ stakeholder_group: "", purpose_scope_key_topics_and_concerns_raised: "", frequency_other_specify: "", channels_other_specify: "", });
                setStakeholderVulnerableMarginalizedGroup("");
                setFrequencyEngagement("");
                setChannelsCommunication("");
                setOpenAddStakeholderManagement({ action: false, stakeholderManagementTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const selectFrequencyEngagement = (event) => {
        setFrequencyEngagement(event.target.value)
    };

    const selectChannelsCommunication = (event) => {
        setChannelsCommunication(event.target.value)
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        const payload = {
            refNo: openAddStakeholderManagement.stakeholderManagementTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ stakeholder_group: "", purpose_scope_key_topics_and_concerns_raised: "", frequency_other_specify: "", channels_other_specify: "", });
                setStakeholderVulnerableMarginalizedGroup("");
                setFrequencyEngagement("");
                setChannelsCommunication("");
                getAllWasteGenerationData(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddStakeholderManagement({ action: false, stakeholderManagementTableData: null })
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={openAddStakeholderManagement.action} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold headingText'>{openAddStakeholderManagement.stakeholderManagementTableData ? "Edit Stakeholder Management" : "Add Stakeholder Management"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddStakeholderManagement.stakeholderManagementTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Stakeholder Management</material.Typography>
                            </div>
                            <div className='col-6'>
                                <material.TextField
                                    {...register("stakeholder_group", { required: true })}
                                    required
                                    margin="dense"
                                    label="Stakeholder Group"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                            </div>
                            <div className='col-6'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Stakeholder is Vulnerable & Marginalized Group</material.InputLabel>
                                    <material.Select
                                        {...register("stakeholder_is_vulnerable_marginalized_group", { required: true, })}
                                        margin="dense"
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={stakeholderVulnerableMarginalizedGroup}
                                        onChange={selectStakeholderVulnerableMarginalizedGroup}
                                        label="Category of worker"
                                    >
                                        <material.MenuItem value={"Yes"}>Yes</material.MenuItem>
                                        <material.MenuItem value={"No"}>No</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6'>
                                <material.TextField
                                    {...register("purpose_scope_key_topics_and_concerns_raised", { required: true })}
                                    required
                                    margin="dense"
                                    label="Purpose , Scope , Key Topics and Concerns Raised"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                            </div>
                            <div className='col-6'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Frequency of Engagement</material.InputLabel>
                                    <material.Select
                                        {...register("frequency_of_engagement", { required: true, })}
                                        margin="dense"
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={frequencyEngagement}
                                        onChange={selectFrequencyEngagement}
                                        label="Category of worker"
                                    >
                                        <material.MenuItem value={"Annually"}>Annually</material.MenuItem>
                                        <material.MenuItem value={"Half Yearly"}>Half Yearly</material.MenuItem>
                                        <material.MenuItem value={"Quarterly"}>Quarterly</material.MenuItem>
                                        <material.MenuItem value={"Other (Specify)"}>Other (Specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {frequencyEngagement === "Other (Specify)" ? (
                                <div className='col-6'>
                                    <material.TextField
                                        {...register("frequency_other_specify", { required: true, maxLength: 20 })}
                                        required
                                        margin="dense"
                                        label="Other Frequency of Engagement (Specify)"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors?.frequency_other_specify?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-6'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Channels of Communication</material.InputLabel>
                                    <material.Select
                                        {...register("channels_of_communication", { required: true, })}
                                        margin="dense"
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={channelsCommunication}
                                        onChange={selectChannelsCommunication}
                                        label="Category of worker"
                                    >
                                        <material.MenuItem value={"Email"}>Email</material.MenuItem>
                                        <material.MenuItem value={"SMS"}>SMS</material.MenuItem>
                                        <material.MenuItem value={"Newspaper"}>Newspaper</material.MenuItem>
                                        <material.MenuItem value={"Pamphlets"}>Pamphlets</material.MenuItem>
                                        <material.MenuItem value={"Advertisement"}>Advertisement</material.MenuItem>
                                        <material.MenuItem value={"Community Meetings"}>Community Meetings</material.MenuItem>
                                        <material.MenuItem value={"Notice Board"}>Notice Board</material.MenuItem>
                                        <material.MenuItem value={"Website"}>Website</material.MenuItem>
                                        <material.MenuItem value={"Other (Specify)"}>Other (Specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {channelsCommunication === "Other (Specify)" ? (
                                <div className='col-6'>
                                    <material.TextField
                                        {...register("channels_other_specify", { required: true, maxLength: 20 })}
                                        required
                                        margin="dense"
                                        label="Other Channels of Communication (Specify)"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors?.channels_other_specify?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddStakeholderManagement.stakeholderManagementTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateStakeholderManagementData)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddStakeholderManagement.stakeholderManagementTableData.rejection_note === null) || (openAddStakeholderManagement.stakeholderManagementTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveStakeholderManagementData)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddStakeholderManagement;