import React, { useEffect, useState } from 'react'
import Snackbar from '../../toaster-message/Snackbar';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';


function AddCommunicationOfCriticalConcerns(props) {
    const { openAddCommunicationOfCriticalConcerns, setOpenAddCommunicationOfCriticalConcerns, getAllCommunicationOfCriticalConcernsData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openAddCommunicationOfCriticalConcerns.action && openAddCommunicationOfCriticalConcerns.communicationOfCriticalConcernsTableData) {
            reset(openAddCommunicationOfCriticalConcerns.communicationOfCriticalConcernsTableData);
        }
    }, [openAddCommunicationOfCriticalConcerns.action === true]);

    const handleClose = () => {
        setOpenAddCommunicationOfCriticalConcerns({ action: false, communicationOfCriticalConcernsTableData: null });
        reset({ total_number_of_critical_concerns: "", nature_of_critical_concern: "", communicated_to_highest_governance_body: "", });
    };
    const submitCommunicationOfCriticalConcernsData = async (data) => {
        console.log(data)
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "total_number_of_critical_concerns": data.total_number_of_critical_concerns,
                "nature_of_critical_concern": data.nature_of_critical_concern,
                "communicated_to_highest_governance_body": data.communicated_to_highest_governance_body,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllCommunicationOfCriticalConcernsData(getFilterDate, payloadData);
                    setOpenAddCommunicationOfCriticalConcerns({ action: false, communicationOfCriticalConcernsTableData: null });
                    reset({ total_number_of_critical_concerns: "", nature_of_critical_concern: "", communicated_to_highest_governance_body: "", });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateCommunicationOfCriticalConcernsData = async (data) => {
        const refNo = openAddCommunicationOfCriticalConcerns.communicationOfCriticalConcernsTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "total_number_of_critical_concerns": data.total_number_of_critical_concerns,
            "nature_of_critical_concern": data.nature_of_critical_concern,
            "communicated_to_highest_governance_body": data.communicated_to_highest_governance_body,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllCommunicationOfCriticalConcernsData(getFilterDate, payloadData);
                setOpenAddCommunicationOfCriticalConcerns({ action: false, communicationOfCriticalConcernsTableData: null });
                reset({ total_number_of_critical_concerns: "", nature_of_critical_concern: "", communicated_to_highest_governance_body: "", });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })

    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAddCommunicationOfCriticalConcerns.communicationOfCriticalConcernsTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ total_number_of_critical_concerns: "", nature_of_critical_concern: "", communicated_to_highest_governance_body: "", });
                getAllCommunicationOfCriticalConcernsData(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddCommunicationOfCriticalConcerns({ action: false, communicationOfCriticalConcernsTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <>
            <material.Dialog open={openAddCommunicationOfCriticalConcerns.action} maxWidth="md" fullWidth hideBackdrop>
                <material.DialogTitle className='fw-bold headingText'>{openAddCommunicationOfCriticalConcerns.communicationOfCriticalConcernsTableData ? "Edit Communication of Critical Concerns" : "Add Communication of Critical Concerns"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddCommunicationOfCriticalConcerns.communicationOfCriticalConcernsTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className="row">
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Communication of Critical Concerns</material.Typography>
                            </div>
                            <div>
                                <material.TextField
                                    {...register("total_number_of_critical_concerns", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Total Number of Critical Concerns"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.total_number_of_critical_concerns && errors.total_number_of_critical_concerns.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                            <div className='mt-3'>
                                <material.TextField
                                    {...register("nature_of_critical_concern", { required: true, minLength: 2, maxLength: 200 })}
                                    required
                                    label="Nature of Critical Concern"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                                <p className='form-text text-danger'>{errors.nature_of_critical_concern?.type === "required" && 'This field is required'}</p>
                                {errors.nature_of_critical_concern && (errors.nature_of_critical_concern.type === "minLength" || errors.nature_of_critical_concern.type === "maxLength") && (
                                    <p className="errorMsg text-danger">Describe The Nature of Critical Concern Issues should be between 2 to 200 character.</p>
                                )}
                            </div>
                            <div className='mt-2'>
                                <material.TextField
                                    {...register("communicated_to_highest_governance_body", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Total Number of Critical Concerns Communicated to Highest Governance Body"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.communicated_to_highest_governance_body && errors.communicated_to_highest_governance_body.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddCommunicationOfCriticalConcerns.communicationOfCriticalConcernsTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateCommunicationOfCriticalConcernsData)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddCommunicationOfCriticalConcerns.communicationOfCriticalConcernsTableData.rejection_note === null) || (openAddCommunicationOfCriticalConcerns.communicationOfCriticalConcernsTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(submitCommunicationOfCriticalConcernsData)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </>
    )
}

export default AddCommunicationOfCriticalConcerns