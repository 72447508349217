import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { getNicList } from '../../services/materiality-services/MaterialityService';
import Snackbar from '../../toaster-message/Snackbar';
import { useSelector } from 'react-redux';
import { addSiteByCorporateRefNo } from '../../services/SiteService';


function AddNic(props) {
    const { openNic, setOpenNic, getSiteBySiteRefNo, getDistinctNicList } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });

    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedSubClass, setSelectedSubClass] = useState(null);
    const [filteredClass, setFilteredClass] = useState([]);
    const [filteredSubClass, setFilteredSubClass] = useState([]);

    const [nicData, setNicData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openNic.action && openNic.editData === null) {
            getAllNicList()
        } else if (openNic.action && openNic.editData) {
            getAllNicList();
            setSelectedGroup(openNic.editData.groupDescription);
            setSelectedClass(openNic.editData.classDescription);
            setSelectedSubClass(openNic.editData.subclassDescription);
        }
    }, [openNic.action === true]);

    const getAllNicList = () => {
        getNicList()
            .then((resp) => {
                setNicData(resp.data);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    const handleSectorChange = (event, value) => {
        setSelectedGroup(value);
        setFilteredClass([...new Set(nicData.filter(item => item.groupDescription === value).map(item => item.classDescription))]);
        setSelectedClass(null);
        setSelectedSubClass(null);
        setFilteredSubClass([]);
    };

    const handleIndustryChange = (event, value) => {
        setSelectedClass(value);
        setFilteredSubClass(nicData.filter(item => item.classDescription === value));
        setSelectedSubClass(null);
    };

    const handleBasicIndustryChange = (event, value) => {
        setSelectedSubClass(value);
    };

    const handleClose = () => {
        setOpenNic({ action: false, editData: null });
        setSelectedClass(null);
        setSelectedGroup(null);
        setSelectedSubClass(null);
        setFilteredClass([]);
        setFilteredSubClass([]);
    };

    const SaveNic = () => {
        if (selectedSubClass) {
            const selectedData = nicData.find(item => item.subclassDescription === selectedSubClass);
            const payload = [{
                assetName: userDetails.facilityName,
                refNo: userDetails.facilityRefNo,
                nicDtoList: [selectedData]
            }]
            addSiteByCorporateRefNo(userDetails.corporateRefNo, payload)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Save successfully",
                    })
                    getDistinctNicList();
                    getSiteBySiteRefNo();
                    setOpenNic({ action: false, editData: null });
                    setSelectedClass(null);
                    setSelectedGroup(null);
                    setSelectedSubClass(null);
                    setFilteredClass([]);
                    setFilteredSubClass([]);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    // const deleteConfirmation = () => {

    // }

    return (
        <div>
            <material.Dialog open={openNic.action} maxWidth="lg" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openNic.editData ? "Edit NIC" : "Add NIC"}</material.DialogTitle>
                <material.DialogContent>
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='col-4 mt-2'>
                                <material.Autocomplete
                                    options={[...new Set(nicData.map(item => item.groupDescription))]}
                                    value={selectedGroup}
                                    onChange={handleSectorChange}
                                    renderInput={(params) => <material.TextField variant="standard" {...params} label="Group" />}
                                />
                            </div>
                            <div className='col-4 mt-2'>
                                {/* {filteredClass.length > 0 && ( */}
                                <material.Autocomplete
                                    options={filteredClass}
                                    value={selectedClass}
                                    onChange={handleIndustryChange}
                                    renderInput={(params) => <material.TextField variant="standard" {...params} label="Class" />}
                                />
                                {/* )} */}
                            </div>
                            <div className='col-4 mt-2'>
                                {/* {filteredSubClass.length > 0 && ( */}
                                <material.Autocomplete
                                    options={filteredSubClass.map(item => item.subclassDescription)}
                                    value={selectedSubClass}
                                    onChange={handleBasicIndustryChange}
                                    renderInput={(params) => <material.TextField variant="standard" {...params} label="Sub Class" />}
                                />
                                {/* )} */}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(SaveNic)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    {/* {openNic.editData === null ? (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(SaveNic)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    ) : (
                        <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                    )} */}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

export default AddNic