import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddWasteGeneration(props) {
    const { register, handleSubmit, reset, control, watch, formState: { errors }, } = useForm({ mode: "onTouched" });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { openAddWasteGeneration, setOpenAddWasteGeneration, getWasteGenerationData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const [selectWasteCategory, setSelectWasteCategory] = useState("");
    const [selectWasteType, setSelectWasteType] = useState("");
    const [selectActivity, setSelectActivity] = useState("");
    const [selectUnit, setSelectUnit] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openAddWasteGeneration.action && openAddWasteGeneration.wasteGenerationData) {
            reset(openAddWasteGeneration.wasteGenerationData)
            setSelectWasteCategory(openAddWasteGeneration.wasteGenerationData.waste_category)
            setSelectWasteType(openAddWasteGeneration.wasteGenerationData.type)
            setSelectActivity(openAddWasteGeneration.wasteGenerationData.activity)
            setSelectUnit(openAddWasteGeneration.wasteGenerationData.unit)
        }
    }, [openAddWasteGeneration.action === true]);

    const saveWasteGeneration = (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue);
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "waste_category": data.waste_category,
                "type": data.type,
                "activity": data.activity,
                "amount": data.amount,
                "unit": data.unit,
                "other_specify": selectWasteCategory === "Other Waste (Specify)" ? data.other_specify : "",
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            saveSubIssueData(object, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getWasteGenerationData(getFilterDate, paginationData)
                    reset({ amount: "", other_specify: "" })
                    setSelectWasteCategory("")
                    setSelectWasteType("")
                    setSelectActivity("")
                    setSelectUnit("")
                    setOpenAddWasteGeneration({ action: false, wasteGenerationData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateWasteGeneration = (data) => {
        const refNo = openAddWasteGeneration.wasteGenerationData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let object = {
            "waste_category": data.waste_category,
            "type": data.type,
            "activity": data.activity,
            "amount": data.amount,
            "unit": data.unit,
            "other_specify": selectWasteCategory === "Other Waste (Specify)" ? data.other_specify : "",
        }
        updateSubIssueData(object, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getWasteGenerationData(getFilterDate, paginationData)
                reset({ amount: "", other_specify: "" })
                setSelectWasteCategory("")
                setSelectWasteType("")
                setSelectActivity("")
                setSelectUnit("")
                setOpenAddWasteGeneration({ action: false, wasteGenerationData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const handleClose = () => {
        reset({ amount: "", other_specify: "", });
        setSelectWasteCategory("")
        setSelectWasteType("")
        setSelectActivity("")
        setSelectUnit("")
        setOpenAddWasteGeneration({ action: false, wasteGenerationData: null });
    };

    const handleSelectWasteCategory = (event) => {
        setSelectWasteCategory(event.target.value)
    };

    const handleSelectWasteType = (event) => {
        setSelectWasteType(event.target.value)
    };

    const handleSelectActivity = (event) => {
        setSelectActivity(event.target.value)
    };

    const handleSelectUnit = (event) => {
        setSelectUnit(event.target.value)
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openAddWasteGeneration.wasteGenerationData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await commonDelete(payload)
            .then((resp) => {
                getWasteGenerationData(getFilterDate, paginationData);
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ amount: "", other_specify: "", });
                setSelectWasteCategory("");
                setSelectWasteType("");
                setSelectActivity("");
                setSelectUnit("");
                setOpenAddWasteGeneration({ action: false, wasteGenerationData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={openAddWasteGeneration.action} maxWidth="md" fullWidth>
                <material.DialogTitle className='fw-bold headingText'>{openAddWasteGeneration.wasteGenerationData ? "Edit Waste Generation" : "Add Waste Generation"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddWasteGeneration.wasteGenerationData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Waste Generation</material.Typography>
                            </div>
                            <div className='col-lg-6'>
                                <material.FormControl required fullWidth variant="standard">
                                    <material.InputLabel id="demo-simple-select-standard" variant="standard">Waste Category</material.InputLabel>
                                    <material.Select
                                        {...register("waste_category", { required: true })}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        margin="dense"
                                        value={selectWasteCategory}
                                        onChange={handleSelectWasteCategory}
                                    >
                                        <material.MenuItem value="Construction and Demolition">Construction and Demolition</material.MenuItem>
                                        <material.MenuItem value="Municipal Solid Waste">Municipal Solid Waste</material.MenuItem>
                                        <material.MenuItem value="Packaging Waste (other than plastic)">Packaging Waste (other than plastic)</material.MenuItem>
                                        <material.MenuItem value="Plastic Waste">Plastic Waste</material.MenuItem>
                                        <material.MenuItem value="Other Non Hazardous Waste">Other Non Hazardous Waste</material.MenuItem>
                                        <material.MenuItem value="Non Oily (Chemical/ Acid/ Alkali/ Dry Batteries/ Sludge)">Non Oily (Chemical/ Acid/ Alkali/ Dry Batteries/ Sludge)</material.MenuItem>
                                        <material.MenuItem value="Oily Substances (Absorbent Materials, Impacted Soil, Waste Oil & Lubricant)">Oily Substances (Absorbent Materials, Impacted Soil, Waste Oil & Lubricant)</material.MenuItem>
                                        <material.MenuItem value="Other Hazardous Waste">Other Hazardous Waste</material.MenuItem>
                                        <material.MenuItem value="Battery Waste">Battery Waste</material.MenuItem>
                                        <material.MenuItem value="Biomedical Waste">Biomedical Waste</material.MenuItem>
                                        <material.MenuItem value="Radioactive Waste">Radioactive Waste</material.MenuItem>
                                        <material.MenuItem value="E Waste">E Waste</material.MenuItem>
                                        <material.MenuItem value="Other Waste (Specify)">Other Waste (Specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {selectWasteCategory === "Other Waste (Specify)" ? (
                                <div className='col-lg-6'>
                                    <material.TextField
                                        fullWidth
                                        required
                                        variant="standard"
                                        id="demo-simple-select-standard"
                                        label="Other Waste Specify"
                                        margin="dense"
                                        type="text"
                                        {...register("other_specify", { required: true, maxLength: 20 })}
                                    />
                                    {errors?.otherSpecify?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-lg-6'>
                                <material.FormControl required fullWidth variant="standard">
                                    <material.InputLabel id="demo-simple-select-standard" variant="standard">Waste Type</material.InputLabel>
                                    <material.Select
                                        {...register("type", { required: true })}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        margin="dense"
                                        value={selectWasteType}
                                        onChange={handleSelectWasteType}
                                    >
                                        <material.MenuItem value="Non Hazardous Waste">Non Hazardous Waste</material.MenuItem>
                                        <material.MenuItem value="Hazardous Waste">Hazardous Waste</material.MenuItem>
                                        <material.MenuItem value="Other Waste">Other Waste</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-6'>
                                <material.FormControl required fullWidth variant="standard">
                                    <material.InputLabel id="demo-simple-select-standard" variant="standard">Activity</material.InputLabel>
                                    <material.Select
                                        {...register("activity", { required: true })}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        margin="dense"
                                        value={selectActivity}
                                        onChange={handleSelectActivity}
                                    >
                                        <material.MenuItem value="Upstream">Upstream</material.MenuItem>
                                        <material.MenuItem value="Downstream">Downstream</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-6'>
                                <material.TextField
                                    fullWidth
                                    variant="standard"
                                    id="demo-simple-select-standard"
                                    label="Quantity"
                                    margin="dense"
                                    type="number"
                                    {...register("amount", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                />
                                {errors.amount && errors.amount.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-lg-6'>
                                <material.FormControl required fullWidth variant="standard">
                                    <material.InputLabel id="demo-simple-select-standard" variant="standard">Unit</material.InputLabel>
                                    <material.Select
                                        {...register("unit", { required: true })}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        margin="dense"
                                        value={selectUnit}
                                        onChange={handleSelectUnit}
                                    >
                                        <material.MenuItem value="Kg">Kg</material.MenuItem>
                                        <material.MenuItem value="MT">MT</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-2'>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddWasteGeneration.wasteGenerationData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateWasteGeneration)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddWasteGeneration.wasteGenerationData.rejection_note === null) || (openAddWasteGeneration.wasteGenerationData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveWasteGeneration)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddWasteGeneration;