import React, { useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import SpecificDisclosure from '../../specific-disclosure/SpecificDisclosure';
import SpecificDisclosureHistory from '../../specific-disclosure/SpecificDisclosureHistory';


const label = { inputProps: { "aria-label": "Color switch demo" } };
var usersRole;

function InnovativeTechnology(props) {
    const location = useLocation();
    const treeViewData = location.state;
    const [tabsInnovativeTechnology, setTabsInnovativeTechnology] = useState("Specific Disclosure");

    const tabChangeInnovativeTechnology = (event, newValue) => {
        setTabsInnovativeTechnology(newValue);
    };


    return (
        <material.Box sx={{ display: 'flex' }}>
            <SwipeDrawer />
            <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                <div className='row'>
                    <div className='col-12'>
                        <span className="col-6"><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
                    </div>
                </div>
                <div className="mt-3">
                    <material.TabContext value={tabsInnovativeTechnology}>
                        <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <material.TabList onChange={tabChangeInnovativeTechnology} aria-label="lab API tabs example">
                                <material.Tab className="text-capitalize" label="Specific Disclosure" value="Specific Disclosure" />
                                <material.Tab className="text-capitalize" label="Specific Disclosure History" value="Specific Disclosure History" />
                            </material.TabList>
                        </material.Box>
                        <material.TabPanel value="Specific Disclosure">
                            <SpecificDisclosure
                                treeViewData={treeViewData} />
                        </material.TabPanel>
                        <material.TabPanel value="Specific Disclosure History">
                            <SpecificDisclosureHistory
                                treeViewData={treeViewData} />
                        </material.TabPanel>
                    </material.TabContext>
                </div>
            </material.Box>
        </material.Box>
    );
}

export default InnovativeTechnology;