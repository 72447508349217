import React from 'react';
import { Navigate, Route, Routes, Outlet } from 'react-router-dom';
import Login from '../component/login/Login';
import UserManagemant from '../component/user-management/UserManagemant';
import CorporateList from '../component/corporate-list/CorporateList';
import ProtectRouter from '../protect-router/ProtectRouter';
import SiteList from '../component/site/SiteList';
import CorporateDetails from '../component/corporate-deails/CorporateDetails';
import Ranking from '../component/ranking/Ranking';
import GeneralDisclosure from '../component/general-disclosure/GeneralDisclosure';
import MaterialUsed from '../component/sub-issue/MaterialUsed';
import RecycledInput from '../component/sub-issue/RecycledInput';
import ReclaimedProducts from '../component/sub-issue/ReclaimedProducts';
import RAndDAndCAPEX from '../component/sub-issue/RAndDAndCAPEX';
import LifecycleAssessment from '../component/sub-issue/LifecycleAssessment';
import EnergyConsumptionWithinOrg from '../component/sub-issue/energy/EnergyConsumptionWithinOrg';
import EnergyConsumptionOutsideOrg from '../component/sub-issue/energy/EnergyConsumptionOutsideOrg';
import EnergyEfficiencyProduction from '../component/sub-issue/energy/EnergyEfficiencyProduction';
import EnergyEfficiencyProductsServices from '../component/sub-issue/energy/EnergyEfficiencyProductsServices';
import WaterWithdrawal from '../component/sub-issue/water-wastewater/WaterWithdrawal';
import WaterDischarge from '../component/sub-issue/water-wastewater/WaterDischarge';
import WaterConsumption from '../component/sub-issue/water-wastewater/WaterConsumption';
import AirEmissionsInteractions from '../component/sub-issue/air/AirEmissionsInteractions';
import AirEmissionsManagement from '../component/sub-issue/air/AirEmissionsManagement';
import GhgScope1 from '../component/sub-issue/climate-change/GhgScope1';
import GhgScope2 from '../component/sub-issue/climate-change/GhgScope2';
import GhgScope3 from '../component/sub-issue/climate-change/GhgScope3';
import EmissionProduction from '../component/sub-issue/climate-change/EmissionProduction';
import EmissionImport from '../component/sub-issue/climate-change/EmissionImport';
import CoverageOfTraining from '../component/sub-issue/training/CoverageOfTraining';
import PerformanceApprisal from '../component/sub-issue/training/PerformanceApprisal';
import DiversityOfGovernanceBodies from '../component/sub-issue/diversity-inclusion/DiversityOfGovernanceBodies';
import Remuneration from '../component/sub-issue/remuneration-and-awards/Remuneration';
import WorkplaceAccessibility from '../component/sub-issue/diversity-inclusion/WorkplaceAccessibility';
import GrievancesRedressalSystem from '../component/sub-issue/grievances/GrievancesRedressalSystem';
import Health from '../component/sub-issue/health-and-safety/Health';
import Injuries from '../component/sub-issue/health-and-safety/Injuries';
import EmployeeAndWorkers from '../component/sub-issue/employee/EmployeeAndWorkers';
import DifferentlyAbledEmployeesAndWorkers from '../component/sub-issue/employee/DifferentlyAbledEmployeesAndWorkers';
import NewHiringAndTurnover from '../component/sub-issue/employee/NewHiringAndTurnover';
import WasteGeneration from '../component/sub-issue/waste/WasteGeneration';
import WasteRecovery from '../component/sub-issue/waste/WasteRecovery';
import WasteDisposal from '../component/sub-issue/waste/WasteDisposal';
import WasteImpacts from '../component/sub-issue/waste/WasteImpacts';
import WasteManagement from '../component/sub-issue/waste/WasteManagement';
import GovernanceBodyStructure from '../component/sub-issue/governance-structure/GovernanceBodyStructure';
import BiodiversityValueOfSite from '../component/sub-issue/biodiversity/BiodiversityValueOfSite';
import SensitiveSpeciesAffected from '../component/sub-issue/biodiversity/SensitiveSpeciesAffected';
import HabitatsProtectedOrRestored from '../component/sub-issue/biodiversity/HabitatsProtectedOrRestored';
import BiodiversityImpacts from '../component/sub-issue/biodiversity/BiodiversityImpacts';
import BiodiversityCommitments from '../component/sub-issue/biodiversity/BiodiversityCommitments';
import EnvironmentalCriteria from '../component/sub-issue/supplier-chain-environment/EnvironmentalCriteria';
import AdverseImpactAction from '../component/sub-issue/supplier-chain-environment/AdverseImpactAction';
import EHSApprovalStatus from '../component/sub-issue/environmental-compliance/EHSApprovalStatus';
import FinesAndPenalties from '../component/sub-issue/environmental-compliance/FinesAndPenalties';
import ViolationsNotices from '../component/sub-issue/environmental-compliance/ViolationsNotices';
import EmployeesWorkersWellBeing from '../component/sub-issue/employee/EmployeesWorkersWellBeing';
import ParentalLeave from '../component/sub-issue/employee/ParentalLeave';
import RetirementBenefit from '../component/sub-issue/employee/RetirementBenefit';
import DiscriminationIncidents from '../component/sub-issue/non-discrimination/DiscriminationIncidents';
import FreedomOfAssociation from '../component/sub-issue/freedom-of-association/FreedomOfAssociation';
import SecurityPractices from '../component/sub-issue/human-rights/SecurityPractices';
import SocialScreening from '../component/sub-issue/supplier-chain-social/SocialScreening';
import SafetyMeasures from '../component/sub-issue/supplier-chain-social/SafetyMeasures';
import ChildAndForcedLabour from '../component/sub-issue/human-rights/ChildAndForcedLabour';
import IndigenousPeoplesRights from '../component/sub-issue/indigenous_peoples/IndigenousPeoplesRights';
import NoticesAndFines from '../component/sub-issue/non-compliance/NoticesAndFines';
import ViolationAndExceedance from '../component/sub-issue/non-compliance/ViolationAndExceedance';
import AdverseImpactsAndAction from '../component/sub-issue/supplier-chain-social/AdverseImpactsAndAction';
import NoMatch from './NoMatch';
import AirEmissionAreaSource from '../component/sub-issue/air/AirEmissionAreaSource';
import AirEmissionsPointSource from '../component/sub-issue/air/AirEmissionsPointSource';
import Dashboard from '../component/dashboard/Dashboard';

import OhsManagementSystem from '../component/sub-issue/health-and-safety/OhsManagementSystem';
import HazardIdentifications from '../component/sub-issue/health-and-safety/HazardIdentifications';
import HealthServices from '../component/sub-issue/health-and-safety/HealthServices';
import WorkerParticipation from '../component/sub-issue/health-and-safety/WorkerParticipation';
import DelegationOfResponsibility from '../component/sub-issue/governance-structure/DelegationOfResponsibility';
import ConflictOfInterest from '../component/sub-issue/governance-structure/ConflictOfInterest';
import CommunicationOfCriticalConcerns from '../component/sub-issue/governance-structure/CommunicationOfCriticalConcerns';
import StakeholderManagement from '../component/sub-issue/stakeholder-engagement/StakeholderManagement';
import EmissionExport from '../component/sub-issue/climate-change/EmissionExport';
import RiskAndOpportunity from '../component/sub-issue/climate-change/RiskAndOpportunity';
import InnovativeTechnology from '../component/sub-issue/research-and-development/InnovativeTechnology';
import JobCreation from '../component/sub-issue/employee/JobCreation';
import MaterialsSourced from '../component/sub-issue/material/MaterialsSourced';
import MinimumNoticePeriods from '../component/sub-issue/labor/MinimumNoticePeriods';
import LaborStandards from '../component/sub-issue/labor/LaborStandards';
import WorkingConditions from '../component/sub-issue/labor/WorkingConditions';
import CollectiveBargaining from '../component/sub-issue/labor/CollectiveBargaining';
import WorkersCoveredByOHS from '../component/sub-issue/health-and-safety/WorkersCoveredByOHS';
import ERDMP from '../component/sub-issue/health-and-safety/ERDMP';
import WorkRehabilitation from '../component/sub-issue/health-and-safety/WorkRehabilitation';
import CommunityEngagement from '../component/sub-issue/community-engagement/CommunityEngagement';
import StrategyManagement from '../component/sub-issue/esg-management-system/StrategyManagement';
import StrategyNonCompliance from '../component/sub-issue/esg-management-system/StrategyNonCompliance';
import StrategyReview from '../component/sub-issue/esg-management-system/StrategyReview';
import Policies from '../component/sub-issue/esg-management-system/Policies';
import ManagementPrograms from '../component/sub-issue/esg-management-system/ManagementPrograms';
import CollectiveKnowledge from '../component/sub-issue/governance-structure/CollectiveKnowledge';
import CurrentWages from '../component/sub-issue/remuneration-and-awards/CurrentWages';
import EntryLevelWage from '../component/sub-issue/remuneration-and-awards/EntryLevelWage';
import AssessmentImpacts from '../component/sub-issue/consumer-management/AssessmentImpacts';
import ProductOrServiceComplaints from '../component/sub-issue/consumer-management/ProductOrServiceComplaints';
import ConsumerComplaints from '../component/sub-issue/consumer-management/ConsumerComplaints';
import NonComplianceProductOrService from '../component/sub-issue/consumer-management/NonComplianceProductOrService';
import ProductsAndServicesInformationLabeling from '../component/sub-issue/consumer-management/ProductsAndServicesInformationLabeling';
import ResearchAndDevelopmentCapex from '../component/sub-issue/research-and-development/ResearchAndDevelopmentCapex';
import ABCPolicyTraining from '../component/sub-issue/ethical-behaviour/ABCPolicyTraining';
import ABCPolicyCommunication from '../component/sub-issue/ethical-behaviour/ABCPolicyCommunication';
import ABCIncidentsInternal from '../component/sub-issue/ethical-behaviour/ABCIncidentsInternal';
import ABCIncidentExternal from '../component/sub-issue/ethical-behaviour/ABCIncidentExternal';
import PublicPolicy from '../component/sub-issue/trade-and-commerce/PublicPolicy';
import PoliticalContributions from '../component/sub-issue/trade-and-commerce/PoliticalContributions';
import MembershipAssociation from '../component/sub-issue/trade-and-commerce/MembershipAssociation';
import AntiCompetitiveConduct from '../component/sub-issue/trade-and-commerce/AntiCompetitiveConduct';
import IntellectualPropertyBenefit from '../component/sub-issue/trade-and-commerce/IntellectualPropertyBenefit';
import IntellectualPropertyCorrectiveAction from '../component/sub-issue/trade-and-commerce/IntellectualPropertyCorrectiveAction';
import MaterialityOfBusiness from '../component/sub-issue/trade-and-commerce/MaterialityOfBusiness';
import Initiatives from '../component/sub-issue/csr/Initiatives';
import Budget from '../component/sub-issue/csr/Budget';
import GovernanceScreening from '../component/sub-issue/supply-chain-governance/GovernanceScreening';
import SupplierChainAwareness from '../component/sub-issue/supply-chain-governance/SupplierChainAwareness';
import FinancialAssistance from '../component/sub-issue/economic-performance/FinancialAssistance';
import EconomicImpact from '../component/sub-issue/economic-performance/EconomicImpact';
import TaxStrategy from '../component/sub-issue/economic-performance/TaxStrategy';
import ManagementOfTaxConcernsAndReporting from '../component/sub-issue/economic-performance/ManagementOfTaxConcernsAndReporting';
import OpenNessOfBusinessSales from '../component/sub-issue/economic-performance/OpenNessOfBusinessSales';
import OpenNessOfBusinessPurchases from '../component/sub-issue/economic-performance/OpenNessOfBusinessPurchases';


import Material from '../component/dashboard/materials/Material';
import EnergyDashboard from '../component/dashboard/energy/EnergyDashboard';
import WaterAndWastewater from '../component/dashboard/water-and-wastewater/WaterAndWastewater';
import ClimateChangeDashboard from '../component/dashboard/climate-change/ClimateChangeDashboard';
import WasteDashboard from '../component/dashboard/waste/WasteDashboard';
import AirDashboard from '../component/dashboard/air/AirDashboard';
import ManageEntity from '../component/manage-entity/ManageEntity';
import AddEntity from '../component/manage-entity/AddEntity';
import EditEntity from '../component/manage-entity/EditEntity';
import ElectricityConsumption from '../component/sub-issue/energy/ElectricityConsumption';
import FuelConsumption from '../component/sub-issue/energy/FuelConsumption';
import EnergySold from '../component/sub-issue/energy/EnergySold';
import WaterDischargeNew from '../component/sub-issue/water-wastewater/WaterDischargeNew';
import WaterTreatmentReuse from '../component/sub-issue/water-wastewater/WaterTreatmentReuse';
import AssessmentModule from '../component/assessment-module/AssessmentModule';
import EsgAssessmentModule from '../component/assessment-module/EsgAssessmentModule';
import UploadDocument from '../tabs/upload-document/UploadDocument';
import FuelUsed from '../component/sub-issue/material/FuelUsed';
import OtherMaterialsUsed from '../component/sub-issue/material/OtherMaterialsUsed';


function Routing(props) {
    return (
        <div>
            <Routes>
                <Route path='' element={<Navigate to="/login" />} />
                <Route path='/login' element={<Login />} />
                <Route path='/user-management' element={<UserManagemant />} />
                <Route path='/corporate-list' element={<CorporateList />} />
                <Route path='/site-list' element={<SiteList />} />
                <Route path='/corporate' element={<CorporateDetails />} />
                <Route path='/facility' element={<CorporateDetails />} />
                <Route path='/ranking' element={<Ranking />} />
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/manage-entity' element={<ManageEntity />} />
                <Route path='/manage-entity/add-entity' element={<AddEntity />} />
                <Route path='/manage-entity/edit-entity' element={<EditEntity />} />
                <Route path='/documentation' element={<UploadDocument />} />

                {/* 1st phase */}

                <Route path='/Environment/Materials' element={<GeneralDisclosure />} />
                <Route path='/Environment/Materials/Raw_Materials_Used' element={<MaterialUsed />} />
                <Route path='/Environment/Materials/Recycled_Input' element={<RecycledInput />} />
                <Route path='/Environment/Materials/Reclaimed_Products' element={<ReclaimedProducts />} />
                <Route path='/Environment/Energy' element={<GeneralDisclosure />} />
                {/* <Route path='/Environment/Energy/Energy_Consumption_Within_Organization' element={<EnergyConsumptionWithinOrg />} /> */}
                <Route path='Environment/Energy/Energy_Consumption_Within_Organization_-_Indirect' element={<ElectricityConsumption />} />
                <Route path='/Environment/Energy/Fuel_Consumption_Within_Organization' element={<FuelConsumption />} />
                <Route path='/Environment/Energy/Energy_Sold' element={<EnergySold />} />
                <Route path='/Environment/Energy/Energy_Consumption_Outside_Organization' element={<EnergyConsumptionOutsideOrg />} />
                <Route path='/Environment/Water_&_Wastewater' element={<GeneralDisclosure />} />
                <Route path='/Environment/Water_&_Wastewater/Water_Withdrawal' element={<WaterWithdrawal />} />
                {/* <Route path='/Environment/Water_&_Wastewater/Water_Discharge' element={<WaterDischarge />} /> */}
                <Route path='/Environment/Water_&_Wastewater/Waste_Water_Treatment-Reuse' element={<WaterTreatmentReuse />} />
                <Route path='/Environment/Water_&_Wastewater/Water_Discharge' element={<WaterDischargeNew />} />
                <Route path='/Environment/Water_&_Wastewater/Water_Consumption' element={<WaterConsumption />} />
                <Route path='/Environment/Climate_Change' element={<GeneralDisclosure />} />
                <Route path='/Environment/Climate_Change/GHG_Scope_1' element={<GhgScope1 />} />
                <Route path='/Environment/Climate_Change/GHG_Scope_2' element={<GhgScope2 />} />
                <Route path='/Environment/Air' element={<GeneralDisclosure />} />
                <Route path='/Environment/Air/Air_Emissions_-_Point_Source' element={<AirEmissionsPointSource />} />
                <Route path='/Environment/Air/Air_Emissions_-_Area_Source' element={<AirEmissionAreaSource />} />
                <Route path='/Environment/Waste' element={<GeneralDisclosure />} />
                <Route path='/Environment/Waste/Waste_Generation' element={<WasteGeneration />} />
                <Route path='/Environment/Waste/Waste_Recovery' element={<WasteRecovery />} />
                <Route path='/Environment/Waste/Waste_Disposal' element={<WasteDisposal />} />
                <Route path='/Social/Training' element={<GeneralDisclosure />} />
                <Route path='/Social/Training/Coverage_of_Training' element={<CoverageOfTraining />} />
                <Route path='/Social/Diversity_&_Inclusion' element={<GeneralDisclosure />} />
                <Route path='/Social/Diversity_&_Inclusion/Diversity_of_Governance_Bodies' element={<DiversityOfGovernanceBodies />} />
                {/* <Route path='/Social/Diversity_&_Inclusion/Remuneration' element={<Remuneration />} /> */}
                <Route path='/Social/Diversity_&_Inclusion/Workplace_Accessibility' element={<WorkplaceAccessibility />} />
                <Route path='/Social/Occupational_Health_&_Safety' element={<GeneralDisclosure />} />
                <Route path='/Social/Occupational_Health_&_Safety/Work_Related_Ill_Health' element={<Health />} />
                <Route path='/Social/Occupational_Health_&_Safety/Work_Related_Injuries' element={<Injuries />} />
                <Route path='/Social/Employee' element={<GeneralDisclosure />} />
                <Route path='/Social/Employee/Employee_&_Workers' element={<EmployeeAndWorkers />} />
                <Route path='/Social/Employee/Differently_Abled_Employees_&_Workers' element={<DifferentlyAbledEmployeesAndWorkers />} />
                <Route path='/Social/Employee/New_Hiring_&_Turnover' element={<NewHiringAndTurnover />} />
                <Route path='/Governance/Governance_Structure' element={<GeneralDisclosure />} />
                <Route path='/Governance/Governance_Structure/Governance_Body_&_Structure' element={<GovernanceBodyStructure />} />
                <Route path='/Governance/Grievances' element={<GeneralDisclosure />} />
                <Route path='/Governance/Grievances/Grievances_Redressal_System' element={<GrievancesRedressalSystem />} />
                <Route path='/Governance/Non-compliance' element={<GeneralDisclosure />} />

                {/* 2nd phase  */}

                <Route path='/Environment/Materials/R&D_and_CAPEX' element={<RAndDAndCAPEX />} />
                <Route path='/Environment/Materials/Lifecycle_Assessment' element={<LifecycleAssessment />} />
                <Route path='/Social/Employee/Employees_&_Workers_Well-Being' element={<EmployeesWorkersWellBeing />} />
                <Route path='/Social/Employee/Parental_Leave' element={<ParentalLeave />} />
                <Route path='/Social/Employee/Retirement_Benefit' element={<RetirementBenefit />} />
                <Route path='/Social/Non-Discrimination' element={<GeneralDisclosure />} />
                <Route path='/Social/Non-Discrimination/Discrimination_Incidents' element={<DiscriminationIncidents />} />
                <Route path='/Social/Freedom_of_Association' element={<GeneralDisclosure />} />
                <Route path='/Social/Freedom_of_Association/Freedom_of_Association' element={<FreedomOfAssociation />} />
                <Route path='/Social/Human_Rights' element={<GeneralDisclosure />} />
                <Route path='/Social/Human_Rights/Security_Practices' element={<SecurityPractices />} />
                <Route path='/Social/Human_Rights/Child_&_Forced_Labour' element={<ChildAndForcedLabour />} />
                <Route path='/Social/Supplier_Chain-Social' element={<GeneralDisclosure />} />
                <Route path='/Social/Supplier_Chain-Social/Social_Screening' element={<SocialScreening />} />
                <Route path='/Social/Supplier_Chain-Social/Safety_Measures' element={<SafetyMeasures />} />
                <Route path='/Social/Supplier_Chain-Social/Adverse_Impacts_&_Action' element={<AdverseImpactsAndAction />} />
                <Route path='/Social/Indigenous_Peoples' element={<GeneralDisclosure />} />
                <Route path='/Social/Indigenous_Peoples/Indigenous_Peoples_Rights' element={<IndigenousPeoplesRights />} />
                <Route path='/Environment/Energy/Energy_Efficiency_-_Production' element={<EnergyEfficiencyProduction />} />
                <Route path='/Environment/Energy/Energy_Efficiency_-_Products_&_Services' element={<EnergyEfficiencyProductsServices />} />
                <Route path='/Environment/Climate_Change/GHG_Scope_3' element={<GhgScope3 />} />
                <Route path='/Environment/Air/Air_Emissions_&_Interactions' element={<AirEmissionsInteractions />} />
                <Route path='/Environment/Air/Air_Emissions_Management' element={<AirEmissionsManagement />} />
                <Route path='/Environment/Waste/Waste_Impacts' element={<WasteImpacts />} />
                <Route path='/Environment/Waste/Waste_Management' element={<WasteManagement />} />
                {/* <Route path='/Environment/Hazardous_Materials' element={<GeneralDisclosure} /> /> */}
                <Route path='/Environment/Biodiversity' element={<GeneralDisclosure />} />
                <Route path='/Environment/Biodiversity/Biodiversity_Value_of_Site' element={<BiodiversityValueOfSite />} />
                <Route path='/Environment/Biodiversity/Sensitive_Species_Affected' element={<SensitiveSpeciesAffected />} />
                <Route path='/Environment/Biodiversity/Habitats_Protected_or_Restored' element={<HabitatsProtectedOrRestored />} />
                <Route path='/Environment/Biodiversity/Biodiversity_Service_Impacted' element={<BiodiversityImpacts />} />
                <Route path='/Environment/Biodiversity/Biodiversity_Commitments' element={<BiodiversityCommitments />} />
                <Route path='/Environment/Environmental_Compliance' element={<GeneralDisclosure />} />
                <Route path='/Environment/Environmental_Compliance/EHS_Approval_Status' element={<EHSApprovalStatus />} />
                <Route path='/Environment/Environmental_Compliance/Fines_and_Penalties' element={<FinesAndPenalties />} />
                <Route path='/Environment/Environmental_Compliance/Violations_&_Notices' element={<ViolationsNotices />} />
                <Route path='/Environment/Supplier_Chain-Environment' element={<GeneralDisclosure />} />
                <Route path='/Environment/Supplier_Chain-Environment/Environmental_Criteria' element={<EnvironmentalCriteria />} />
                <Route path='/Environment/Supplier_Chain-Environment/Adverse_Impacts_&_Actions' element={<AdverseImpactAction />} />
                <Route path='/Social/Training/Performance_Appraisal' element={<PerformanceApprisal />} />
                <Route path='/Governance/Non-Compliance/Notices_&_Fines' element={<NoticesAndFines />} />
                <Route path='/Governance/Non-Compliance/Violation_&_Exceedance' element={<ViolationAndExceedance />} />

                {/* 2nd phase  */}

                {/* 3rd phase */}
                <Route path='/Social/Occupational_Health_&_Safety/OHS_Management_Systems' element={<OhsManagementSystem />} />
                <Route path='/Social/Occupational_Health_&_Safety/Hazard_Identification' element={<HazardIdentifications />} />
                <Route path='/Social/Occupational_Health_&_Safety/Occupational_Health_Services' element={<HealthServices />} />
                <Route path='/Social/Occupational_Health_&_Safety/Worker_Participation' element={<WorkerParticipation />} />
                <Route path='/Social/Occupational_Health_&_Safety/Workers_Covered_by_OHS' element={<WorkersCoveredByOHS />} />
                <Route path='/Social/Occupational_Health_&_Safety/ERDMP' element={<ERDMP />} />
                <Route path='/Social/Occupational_Health_&_Safety/Work_Rehabilitation' element={<WorkRehabilitation />} />
                <Route path='/Governance/Governance_Structure/Delegation_of_Responsibility' element={<DelegationOfResponsibility />} />
                <Route path='/Governance/Governance_Structure/Conflicts_of_Interest' element={<ConflictOfInterest />} />
                <Route path='/Governance/Governance_Structure/Communication_of_Critical_Concerns' element={<CommunicationOfCriticalConcerns />} />
                <Route path='/Governance/Governance_Structure/Collective_Knowledge' element={<CollectiveKnowledge />} />
                <Route path='/Governance/Non-compliance' element={<GeneralDisclosure />} />
                <Route path='/Governance/Stakeholder_Engagement' element={<GeneralDisclosure />} />
                <Route path='/Governance/Stakeholder_Engagement/Stakeholder_Management' element={<StakeholderManagement />} />
                <Route path='/Environment/Climate_Change/ODS_Emission-Production' element={<EmissionProduction />} />
                <Route path='/Environment/Climate_Change/ODS_Emission-Import' element={<EmissionImport />} />
                <Route path='/Environment/Climate_Change/ODS_Emission-Export' element={<EmissionExport />} />
                <Route path='/Environment/Climate_Change/Climate_Change_Risk_&_Opportunity' element={<RiskAndOpportunity />} />
                <Route path='/Environment/Research_&_Development' element={<GeneralDisclosure />} />
                <Route path='/Environment/Research_&_Development/Innovative_Technology' element={<InnovativeTechnology />} />
                <Route path='/Environment/Research_&_Development/R&D_and_Capex' element={<ResearchAndDevelopmentCapex />} />
                <Route path='/Social/Employee/Job_Creation' element={<JobCreation />} />
                <Route path='/Environment/Materials/Materials_Sourced' element={<MaterialsSourced />} />
                <Route path='/Social/Labor' element={<GeneralDisclosure />} />
                <Route path='/Social/Labor/Minimum_Notice_Periods' element={<MinimumNoticePeriods />} />
                <Route path='/Social/Labor/Labor_Standards' element={<LaborStandards />} />
                <Route path='/Social/Labor/Working_Conditions' element={<WorkingConditions />} />
                <Route path='/Social/Labor/Collective_Bargaining' element={<CollectiveBargaining />} />
                <Route path='/Social/Community_Engagement' element={<GeneralDisclosure />} />
                <Route path='/Social/Community_Engagement/Community_Engagement' element={<CommunityEngagement />} />
                <Route path='/Governance/ESG_Management_System' element={<GeneralDisclosure />} />
                <Route path='/Governance/ESG_Management_System/ESG_Strategy-Management' element={<StrategyManagement />} />
                {/* <Route path='/Governance/ESG_Management_System/ESG_Strategy-Non-compliance' element={<StrategyNonCompliance />} /> */}
                {/* <Route path='/Governance/ESG_Management_System/ESG_Strategy-Review' element={<StrategyReview />} /> */}
                <Route path='/Governance/ESG_Management_System/ESG_Policies' element={<Policies />} />
                <Route path='/Governance/ESG_Management_System/Management_Programs' element={<ManagementPrograms />} />
                <Route path='/Governance/Remuneration_&_Awards' element={<GeneralDisclosure />} />
                <Route path='/Governance/Remuneration_&_Awards/Remuneration' element={<Remuneration />} />
                <Route path='/Governance/Remuneration_&_Awards/Current-Wages' element={<CurrentWages />} />
                <Route path='/Governance/Remuneration_&_Awards/Entry_Level_Wage' element={<EntryLevelWage />} />
                <Route path='/Governance/Consumer_Management' element={<GeneralDisclosure />} />
                <Route path='/Governance/Consumer_Management/Assessment_of_H&S_Impacts' element={<AssessmentImpacts />} />
                <Route path='/Governance/Consumer_Management/Product_or_Service_H&S_Complaints' element={<ProductOrServiceComplaints />} />
                <Route path='/Governance/Consumer_Management/Consumer_Complaints' element={<ConsumerComplaints />} />
                <Route path='/Governance/Consumer_Management/Non-compliance_Product_or_Service' element={<NonComplianceProductOrService />} />
                <Route path='/Governance/Consumer_Management/Products_&_Services_Information_Labeling' element={<ProductsAndServicesInformationLabeling />} />
                <Route path='/Governance/Ethical_Behaviour' element={<GeneralDisclosure />} />
                <Route path='/Governance/Ethical_Behaviour/ABC_Policy_Training' element={<ABCPolicyTraining />} />
                <Route path='/Governance/Ethical_Behaviour/ABC_Policy_Communication' element={<ABCPolicyCommunication />} />
                <Route path='/Governance/Ethical_Behaviour/ABC_Incidents_-_Internal' element={<ABCIncidentsInternal />} />
                <Route path='/Governance/Ethical_Behaviour/ABC_Incident_External' element={<ABCIncidentExternal />} />
                <Route path='/Governance/Trade_&_Commerce' element={<GeneralDisclosure />} />
                <Route path='/Governance/Trade_&_Commerce/Public_Policy' element={<PublicPolicy />} />
                <Route path='/Governance/Trade_&_Commerce/Political_Contributions' element={<PoliticalContributions />} />
                <Route path='/Governance/Trade_&_Commerce/Membership_Association' element={<MembershipAssociation />} />
                <Route path='/Governance/Trade_&_Commerce/Anti_Competitive_Conduct' element={<AntiCompetitiveConduct />} />
                <Route path='/Governance/Trade_&_Commerce/Intellectual_Property-Benefit' element={<IntellectualPropertyBenefit />} />
                <Route path='/Governance/Trade_&_Commerce/Intellectual_Property-Corrective_Action' element={<IntellectualPropertyCorrectiveAction />} />
                <Route path='/Governance/Trade_&_Commerce/Materiality_of_Business' element={<MaterialityOfBusiness />} />
                <Route path='/Governance/CSR' element={<GeneralDisclosure />} />
                <Route path='/Governance/CSR/CSR_Initiatives' element={<Initiatives />} />
                <Route path='/Governance/CSR/CSR_Budget' element={<Budget />} />
                <Route path='/Governance/Supply_Chain-Governance' element={<GeneralDisclosure />} />
                <Route path='/Governance/Supply_Chain-Governance/Governance-Screening' element={<GovernanceScreening />} />
                <Route path='/Governance/Supply_Chain-Governance/Supplier_Chain_Awareness' element={<SupplierChainAwareness />} />
                <Route path='/Governance/Economic_Performance' element={<GeneralDisclosure />} />
                <Route path='/Governance/Economic_Performance/Financial_Assistance' element={<FinancialAssistance />} />
                <Route path='/Governance/Economic_Performance/Economic_Impact' element={<EconomicImpact />} />
                <Route path='/Governance/Economic_Performance/Tax_Strategy' element={<TaxStrategy />} />
                <Route path='/Governance/Economic_Performance/Management_of_Tax_Concerns_&_Reporting' element={<ManagementOfTaxConcernsAndReporting />} />
                <Route path='/Governance/Economic_Performance/Open-ness_of_Business_-_Sales' element={<OpenNessOfBusinessSales />} />
                <Route path='/Governance/Economic_Performance/Open-ness_of_Business_-_Purchases' element={<OpenNessOfBusinessPurchases />} />
                
                <Route path='/Environment/Materials/Fuel_Used' element={<FuelUsed />} />
                <Route path='/Environment/Materials/Other_Materials_Used' element={<OtherMaterialsUsed />} />

                {/* dashboard routing */}

                <Route path='/issue-dashboard/material' element={<Material />} />
                {/* <Route path='/issue-dashboard/energy' element={<EnergyDashboard />} />
                <Route path='/issue-dashboard/water_and_wastewater' element={<WaterAndWastewater />} />
                <Route path='/issue-dashboard/climate_change' element={<ClimateChangeDashboard />} />
                <Route path='/issue-dashboard/waste' element={<WasteDashboard />} />
                <Route path='/issue-dashboard/air' element={<AirDashboard />} /> */}

                {/* Assessment Module */}
                <Route path='/assessment-module' element={<AssessmentModule />} />
                <Route path='/esg-assessment-module' element={<EsgAssessmentModule />} />

                <Route path='*' element={<NoMatch />} />
            </Routes>
        </div>
    );
}

export default Routing;