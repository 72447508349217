import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import SetUpAssessmentDialog from './SetUpAssessmentDialog';
import { getAssessment } from '../../services/AssessmentService';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import moment from 'moment';


function SetUpAssessment(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openAssessmentDialog, setOpenAssessmentDialog] = useState({ action: false, assessmentData: null });
    const [assessmentList, setAssessmentList] = useState([]);
    const currentDate = moment().format("YYYY-MM-DD");

    useEffect(() => {
        getAssessmentByCorporateRefNo();
    }, []);

    const getAssessmentByCorporateRefNo = () => {
        getAssessment({ ...userDetails, filterApplicable: false })
            .then((resp) => {
                setAssessmentList(resp.data);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const addAssessment = () => {
        setOpenAssessmentDialog({ action: true, assessmentData: null })
    };

    const editAssessment = (rowData) => {
        setOpenAssessmentDialog({ action: true, assessmentData: rowData })
    };

    return (
        <div style={{ marginTop: "-15px" }}>
            <material.Paper elevation={3} sx={{ p: 3 }}>
                <div className="row">
                    <div className="col-12">
                        <span className='float-end'>
                            <material.Button onClick={addAssessment} variant="contained" color='success' sx={{ textTransform: "none", mb: 1 }} startIcon={<material.AddIcon />}>
                                Add Assessment
                            </material.Button>
                        </span>
                    </div>
                    <div className="col-12">
                        <material.TableContainer sx={{ maxHeight: "70vh" }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Assessment Name</StyledTableCell>
                                        <StyledTableCell>Start Date</StyledTableCell>
                                        <StyledTableCell>End Date</StyledTableCell>
                                        <StyledTableCell>Facility / Supplier</StyledTableCell>
                                        <StyledTableCell>Action</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {assessmentList.length ? assessmentList.map((assessment, i) => (
                                        <material.TableRow key={i}>
                                            <material.TableCell>{assessment.assessmentName}</material.TableCell>
                                            <material.TableCell>{moment(assessment.startDate).format("DD-MM-YYYY")}</material.TableCell>
                                            <material.TableCell>{moment(assessment.endDate).format("DD-MM-YYYY")}</material.TableCell>
                                            <material.TableCell className='text-break' size='small' style={{ width: "25rem" }}>
                                                {assessment.facilityList?.length ? assessment.facilityList.map((facility, i) => (
                                                    <span key={i}>{facility.facilityName}, </span>
                                                )) : null}
                                            </material.TableCell>
                                            <material.TableCell>
                                                {/* {currentDate >= assessment.startDate && currentDate <= assessment.endDate ? null : ( */}
                                                <material.IconButton title='Edit Assessment' onClick={() => editAssessment(assessment)}>
                                                    <material.CreateIcon color='success' />
                                                </material.IconButton>
                                                {/* )} */}
                                            </material.TableCell>
                                        </material.TableRow>
                                    )) : (
                                        <material.TableRow >
                                            <material.TableCell colSpan={10}>
                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                            </material.TableCell>
                                        </material.TableRow>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                    </div>
                </div>
            </material.Paper>
            <SetUpAssessmentDialog
                openAssessmentDialog={openAssessmentDialog}
                setOpenAssessmentDialog={setOpenAssessmentDialog}
                getAssessmentByCorporateRefNo={getAssessmentByCorporateRefNo}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default SetUpAssessment;