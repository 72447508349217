import React, { useState } from 'react';
import { material } from '../../library/Material';
import SwipeDrawer from '../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import AddRecycledInput from '../../sub-issues-dialogs/material/AddRecycledInput';
import moment from 'moment';
import Snackbar from '../../toaster-message/Snackbar';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import RejectionNote from '../../dialogs/RejectionNote';
import DateFilter from '../../shared/DateFilter';
import { getSubIssueRecordData, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import Pagination from '../../shared/pagination/Pagination';
import ApplicableDialog from '../../dialogs/ApplicableDialog';


var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };
var recordRefNos = [];

function RecycledInput(props) {
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const location = useLocation();
    const treeViewData = location?.state;
    const [recycledInputTabs, setRecycledInputTabs] = useState('Recycled Input');
    const [recycledInputData, setRecycledInputData] = useState([]);
    const [openAddRecycledInput, setOpenAddRecycledInput] = useState({ action: false, recycledInputData: null });
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [applicable, setApplicable] = useState(true);

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = React.useState(0);
    const [getFilterDate, setGetFilterDate] = useState(null);

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        getRecycledInputData();
    }, [recycledInputTabs]);

    const getRecycledInputData = async (filterDate, paginationValue) => {
        if (recycledInputTabs === 'Recycled Input') {
            let forApproval = userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? true : null;
            let approveHistory = false;
            let typeOfRecord = "RECYCLED_INPUT"
            let payload = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                typeOfRecord,
                subissueName: treeViewData.subIssue,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            await getSubIssueRecordData(payload)
                .then((resp) => {
                    setRecycledInputData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setApplicableRefNo(resp.data.applicableRecord?.ref_no ? resp.data.applicableRecord.ref_no : null);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        }
        else if (recycledInputTabs === 'Recycled Input History') {
            let forApproval = true;
            let approveHistory = true;
            let typeOfRecord = "RECYCLED_INPUT"
            const payload = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                typeOfRecord,
                subissueName: treeViewData.subIssue,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            await getSubIssueRecordData(payload)
                .then((resp) => {
                    setRecycledInputData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        };
    };

    const addRecycledInput = () => {
        setOpenAddRecycledInput({ action: true, recycledInputData: null });
    };

    const editRecycledInput = (rowData) => {
        setOpenAddRecycledInput({ action: true, recycledInputData: rowData });
    };

    const handleClickCheckbox = (ids) => {
        setOpenAddRecycledInput({ action: false, recycledInputData: null });
        if (recordRefNos.includes(ids)) {
            // recordRefNos.pop(ids);
            recordRefNos = recordRefNos.filter(item => item !== ids);
        } else {
            recordRefNos.push(ids);
        }
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true)
    };

    const sendApproval = async () => {
        if (recordRefNos.length) {
            const refNo = recordRefNos.length > 0 ? recordRefNos.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true
            }
            await updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    recordRefNos = [];
                    getRecycledInputData(getFilterDate, paginationData)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        };
    };

    const approved = async () => {
        if (recordRefNos.length) {
            const refNo = recordRefNos.length > 0 ? recordRefNos.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true,
                "approved_by": userDetails.username,
                "approved_date": moment().format("YYYY-MM-DD"),
                "approved_status": "APPROVED",
                "rejection_note": "",
                "type_of_record": "RECYCLED_INPUT",
            }
            await updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    recordRefNos = [];
                    getRecycledInputData(getFilterDate, paginationData)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        };
    };

    const rejectRecord = async () => {
        if (recordRefNos.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        };
    };

    const getRejectionNoteData = async (noteData) => {
        const refNo = recordRefNos.length > 0 ? recordRefNos.join(',') : null;
        const subissueName = treeViewData.subIssue;
        const payload = {
            send_for_approval: false,
            "rejection_note": noteData.rejectionNote,
            "approved_status": "",
            "type_of_record": "RECYCLED_INPUT",
        }
        await updateSubIssueData(payload, refNo, subissueName)
            .then((resp) => {
                recordRefNos = [];
                getRecycledInputData(getFilterDate, paginationData)
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const handleRecycledInputTabs = (event, newValue) => {
        setRecycledInputTabs(newValue);
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const getFormDateToDate = (data) => {
        setGetFilterDate(data);
        getRecycledInputData(data, paginationData);
    };

    const getApplicableData = () => {
        if (applicableRefNo === null) {
            let subIssueName = treeViewData?.subIssue;
            const payload = {
                applicable: false,
                applicable_record: true,
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
            }
            saveSubIssueData(payload, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getRecycledInputData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else {
            let subIssueName = treeViewData?.subIssue;
            let refNo = applicableRefNo;
            const payload = {
                applicable: applicable ? false : true,
            }
            updateSubIssueData(payload, refNo, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getRecycledInputData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    const getPaginationData = (filterData, paginationData) => {
        setRowsPerPage(paginationData.size);
        setPage(paginationData.page);
        getRecycledInputData(filterData, paginationData);
    };

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className="row">
                        <div className="col-6">
                            <span><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
                        </div>
                        <div className="col-6">
                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") || userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? null : (
                                <span className="float-end">
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} color="secondary"
                                            checked={applicable} onChange={applicableNotApplicable}
                                        />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>
                    <material.Box sx={{ width: '100%', typography: 'body1' }}>
                        <material.TabContext value={recycledInputTabs}>
                            <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <material.TabList onChange={handleRecycledInputTabs} aria-label="lab API tabs example">
                                    <material.Tab className='text-capitalize' label="Recycled Input" value="Recycled Input" />
                                    <material.Tab className='text-capitalize' label="Recycled Input History" value="Recycled Input History" />
                                </material.TabList>
                            </material.Box>
                            <material.TabPanel value="Recycled Input">
                                <div className="row mt-2 align-items-center">
                                    <div className="col-6">
                                        {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        )}
                                    </div>
                                    <div className="col-6" hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                        <span className='float-end'>
                                            {recycledInputData?.length ? (
                                                <div>
                                                    <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")} color='success'
                                                        disabled={!applicable || !recordRefNos.length}
                                                        sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approved}>Approve</material.Button>
                                                    <material.Button sx={{ textTransform: "none" }} hidden={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")}
                                                        disabled={!applicable || !recordRefNos.length}
                                                        variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectRecord}>Reject</material.Button>
                                                </div>
                                            ) : null}
                                            <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_APPROVER")} color='success'
                                                disabled={!applicable}
                                                sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendApproval}>Send for Approval</material.Button>
                                            <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_APPROVER")} color='success'
                                                disabled={!applicable}
                                                sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addRecycledInput}>Add  Recycled Input</material.Button>
                                        </span>
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                                    <div className='row'>
                                        <div className="col-12">
                                            <material.TableContainer >
                                                <material.Table>
                                                    <material.TableHead>
                                                        <material.TableRow >
                                                            <StyledTableCell>Recycled Material</StyledTableCell>
                                                            <StyledTableCell>Other Waste Specify</StyledTableCell>
                                                            <StyledTableCell>Category of Material</StyledTableCell>
                                                            <StyledTableCell>Quantity</StyledTableCell>
                                                            <StyledTableCell>Unit</StyledTableCell>
                                                            <StyledTableCell>From Date</StyledTableCell>
                                                            <StyledTableCell>To Date</StyledTableCell>
                                                            <StyledTableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>{userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                <StyledTableCell>Rejection Note</StyledTableCell>
                                                            )}
                                                        </material.TableRow>
                                                    </material.TableHead>
                                                    <material.TableBody>
                                                        {recycledInputData?.length ? recycledInputData.map((item, i) => (
                                                            <material.TableRow key={i}

                                                                sx={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                onClick={((userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")) && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true) ?
                                                                    () => editRecycledInput(item) : null}
                                                            >
                                                                <material.TableCell>{item.material}</material.TableCell>
                                                                <material.TableCell>{(item.other_specify === null || item.other_specify === "") ? "N/A" : item.other_specify}</material.TableCell>
                                                                <material.TableCell>{item.type_of_material}</material.TableCell>
                                                                <material.TableCell>{item.amount}</material.TableCell>
                                                                <material.TableCell>{item.unit}</material.TableCell>
                                                                <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                                                    {userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") ? (
                                                                        <material.Checkbox {...label} disabled={!applicable || item.send_for_approval} onChange={() => handleClickCheckbox(item.ref_no)} color="success" />
                                                                    ) : (
                                                                        <material.Checkbox {...label} disabled={!applicable || item.approved_status === "APPROVED"} onChange={() => handleClickCheckbox(item.ref_no)} color="success" />
                                                                    )}
                                                                </material.TableCell>
                                                                {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                    <material.TableCell>{(item.rejection_note === null) || (item.rejection_note === "") ? "No" : (
                                                                        <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                    )}
                                                                    </material.TableCell>
                                                                )}
                                                            </material.TableRow>
                                                        )) : (
                                                            <material.TableRow >
                                                                <material.TableCell colSpan={10}>
                                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )}
                                                    </material.TableBody>
                                                </material.Table>
                                            </material.TableContainer>
                                            <Pagination
                                                getPaginationData={getPaginationData}
                                                totalElements={totalElements}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                getFilterDate={getFilterDate}
                                            />
                                        </div>
                                    </div>
                                </material.Paper>
                            </material.TabPanel>
                            <material.TabPanel value="Recycled Input History">
                                <div className="row">
                                    <div className="col-7">
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        )}
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                                    <div className='row'>
                                        <div className="col-12">
                                            <material.TableContainer >
                                                <material.Table>
                                                    <material.TableHead>
                                                        <material.TableRow >
                                                            <StyledTableCell>Recycled Material</StyledTableCell>
                                                            <StyledTableCell>Other Waste Specify</StyledTableCell>
                                                            <StyledTableCell>Category of Material</StyledTableCell>
                                                            <StyledTableCell>Quantity</StyledTableCell>
                                                            <StyledTableCell>Unit</StyledTableCell>
                                                            <StyledTableCell>From Date</StyledTableCell>
                                                            <StyledTableCell>To Date</StyledTableCell>
                                                            <StyledTableCell>Approval Date</StyledTableCell>
                                                            <StyledTableCell>Status</StyledTableCell>
                                                        </material.TableRow>
                                                    </material.TableHead>
                                                    <material.TableBody>
                                                        {recycledInputData?.length ? recycledInputData.map((item, i) => (
                                                            <material.TableRow key={i} >
                                                                <material.TableCell>{item.material}</material.TableCell>
                                                                <material.TableCell>{(item.other_specify === null || item.other_specify === "") ? "N/A" : item.other_specify}</material.TableCell>
                                                                <material.TableCell>{item.type_of_material}</material.TableCell>
                                                                <material.TableCell>{item.amount}</material.TableCell>
                                                                <material.TableCell>{item.unit}</material.TableCell>
                                                                <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell>
                                                                    {item.approved_status === "APPROVED" ? <span className="badge bg-success">
                                                                        APPROVED
                                                                    </span> : null}
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )) : (
                                                            <material.TableRow >
                                                                <material.TableCell colSpan={9}>
                                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )}
                                                    </material.TableBody>
                                                </material.Table>
                                            </material.TableContainer>
                                            <Pagination
                                                getPaginationData={getPaginationData}
                                                totalElements={totalElements}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                getFilterDate={getFilterDate}
                                            />
                                        </div>
                                    </div>
                                </material.Paper>
                            </material.TabPanel>
                        </material.TabContext>
                    </material.Box>
                </material.Box>
            </material.Box>
            <AddRecycledInput
                openAddRecycledInput={openAddRecycledInput}
                setOpenAddRecycledInput={setOpenAddRecycledInput}
                getRecycledInputData={getRecycledInputData}
                treeViewData={treeViewData}
                page={page}
                rowsPerPage={rowsPerPage}
                getFilterDate={getFilterDate}
            />
            <ApplicableDialog
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getApplicableData={getApplicableData}
                applicable={applicable}
            />
            {/* <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getRecycledInputData={getRecycledInputData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            /> */}
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default RecycledInput;