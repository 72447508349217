import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { saveOrUpdateSocialScreeningData } from '../../services/sub-issue-services/social-screening-service/SocialScreeningService';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddSocialScreening(props) {
    const { addOpenSocialScreening, setAddOpenSocialScreening, getAllSocialScreeningData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, watch, getValues, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    const onChangeTotalNoOfSuppliers = watch('total_no_of_suppliers');
    const onChangeSuppliersScreenedUsingSocialCriteria = watch('suppliers_screened_using_social_criteria');
    const onChangeSuppliersAssessedForSocialImpact = watch('suppliers_assessed_for_social_impact');
    const onChangeSuppliersHavingSignificantNegativeImpact = watch('suppliers_having_significant_negative_impact');
    const onChangeCorrectiveMeasureGivenToSupplier = watch('corrective_measure_given_to_supplier');
    const onChangeRelationshipTerminatedForNegativeSocialImpact = watch('relationship_terminated_for_negative_social_impact');

    useEffect(() => {
        totalCalculation();
    }, [onChangeTotalNoOfSuppliers, onChangeSuppliersScreenedUsingSocialCriteria, onChangeSuppliersAssessedForSocialImpact, onChangeSuppliersHavingSignificantNegativeImpact, onChangeCorrectiveMeasureGivenToSupplier, onChangeRelationshipTerminatedForNegativeSocialImpact]);

    // const totalCalculation = () => {
    //     let totalNoOfSuppliersValue = getValues('total_no_of_suppliers');
    //     let suppliersScreenedUsingSocialCriteriaValue = getValues('suppliers_screened_using_social_criteria');
    //     let suppliersAssessedForSocialImpactValue = getValues('suppliers_assessed_for_social_impact');
    //     let suppliersHavingSignificantNegativeImpactValue = getValues('suppliers_having_significant_negative_impact');
    //     let correctiveMeasureGivenToSupplierValue = getValues('corrective_measure_given_to_suppliers');
    //     let relationshipTerminatedForNegativeSocialImpactValue = getValues('relationship_terminated_for_negative_social_impact');

    //     if (Number(suppliersScreenedUsingSocialCriteriaValue) < Number(totalNoOfSuppliersValue)) {
    //         setOpenSnackBar({
    //             "action": true,
    //             "type": "warning",
    //             "message": "Suppliers screened using social Criteria value not greater than total no. of suppliers value",
    //         })
    //         setButtonDisable(true)
    //     } else if (Number(suppliersAssessedForSocialImpactValue) < Number(totalNoOfSuppliersValue)) {
    //         setOpenSnackBar({
    //             "action": true,
    //             "type": "warning",
    //             "message": "Suppliers assessed for social impact value not greater than total no. of suppliers value",
    //         })
    //         setButtonDisable(true)
    //     } else if (Number(suppliersHavingSignificantNegativeImpactValue) < Number(totalNoOfSuppliersValue)) {
    //         setOpenSnackBar({
    //             "action": true,
    //             "type": "warning",
    //             "message": "Suppliers having significant negative impact value not greater than total no. of suppliers value",
    //         })
    //         setButtonDisable(true)
    //     } else if (Number(correctiveMeasureGivenToSupplierValue) < Number(totalNoOfSuppliersValue)) {
    //         setOpenSnackBar({
    //             "action": true,
    //             "type": "warning",
    //             "message": "Corrective measure given to supplier value not greater than total no. of suppliers value",
    //         })
    //         setButtonDisable(true)
    //     } else if (Number(relationshipTerminatedForNegativeSocialImpactValue) < Number(totalNoOfSuppliersValue)) {
    //         setOpenSnackBar({
    //             "action": true,
    //             "type": "warning",
    //             "message": "Relationship terminated for negative social impact value not greater than total no. of suppliers value",
    //         })
    //         setButtonDisable(true)
    //     } else {
    //         setButtonDisable(false)
    //     }
    // };
    const totalCalculation = () => {
        let totalNoOfSuppliersValue = Number(getValues('total_no_of_suppliers'));
        let suppliersScreenedUsingSocialCriteriaValue = Number(getValues('suppliers_screened_using_social_criteria'));
        let suppliersAssessedForSocialImpactValue = Number(getValues('suppliers_assessed_for_social_impact'));
        let suppliersHavingSignificantNegativeImpactValue = Number(getValues('suppliers_having_significant_negative_impact'));
        let correctiveMeasureGivenToSupplierValue = Number(getValues('corrective_measure_given_to_supplier'));
        let relationshipTerminatedForNegativeSocialImpactValue = Number(getValues('relationship_terminated_for_negative_social_impact'));

        if (
            suppliersScreenedUsingSocialCriteriaValue > totalNoOfSuppliersValue ||
            suppliersAssessedForSocialImpactValue > totalNoOfSuppliersValue ||
            suppliersHavingSignificantNegativeImpactValue > totalNoOfSuppliersValue ||
            correctiveMeasureGivenToSupplierValue > totalNoOfSuppliersValue ||
            relationshipTerminatedForNegativeSocialImpactValue > totalNoOfSuppliersValue
        ) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Some value is not greater than total no. of suppliers value",
            })
            setButtonDisable(true);
        } else {
            // setOpenSnackBar({
            //     "action": true,
            //     "type": "warning",
            //     "message": "Some value is not greater than total no. of suppliers value",
            // })
            setButtonDisable(false);
        }
    };

    useEffect(() => {
        if (addOpenSocialScreening.action && addOpenSocialScreening.socialScreeningTableData) {
            reset(addOpenSocialScreening.socialScreeningTableData);
        }
    }, [addOpenSocialScreening.action === true]);

    const handleClose = () => {
        reset({ total_no_of_suppliers: "", suppliers_screened_using_social_criteria: "", suppliers_assessed_for_social_impact: "", suppliers_having_significant_negative_impact: "", corrective_measure_given_to_supplier: "", relationship_terminated_for_negative_social_impact: "", })
        setAddOpenSocialScreening({ action: false, socialScreeningTableData: null });
    };

    const saveSocialScreening = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "total_no_of_suppliers": Number(data.total_no_of_suppliers),
                "suppliers_screened_using_social_criteria": Number(data.suppliers_screened_using_social_criteria),
                "suppliers_assessed_for_social_impact": Number(data.suppliers_assessed_for_social_impact),
                "suppliers_having_significant_negative_impact": Number(data.suppliers_having_significant_negative_impact),
                "corrective_measure_given_to_supplier": Number(data.corrective_measure_given_to_supplier),
                "relationship_terminated_for_negative_social_impact": Number(data.relationship_terminated_for_negative_social_impact),
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(object, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllSocialScreeningData(getFilterDate, payloadData);
                    reset({ total_no_of_suppliers: "", suppliers_screened_using_social_criteria: "", suppliers_assessed_for_social_impact: "", suppliers_having_significant_negative_impact: "", corrective_measure_given_to_supplier: "", relationship_terminated_for_negative_social_impact: "", })
                    setAddOpenSocialScreening({ action: false, socialScreeningTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateSocialScreening = async (data) => {
        const refNo = addOpenSocialScreening.socialScreeningTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let object = {
            "total_no_of_suppliers": Number(data.total_no_of_suppliers),
            "suppliers_screened_using_social_criteria": Number(data.suppliers_screened_using_social_criteria),
            "suppliers_assessed_for_social_impact": Number(data.suppliers_assessed_for_social_impact),
            "suppliers_having_significant_negative_impact": Number(data.suppliers_having_significant_negative_impact),
            "corrective_measure_given_to_supplier": Number(data.corrective_measure_given_to_supplier),
            "relationship_terminated_for_negative_social_impact": Number(data.relationship_terminated_for_negative_social_impact),
        }
        await updateSubIssueData(object, refNo, subIssueName)
            .then((resp) => {
                getAllSocialScreeningData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllSocialScreeningData(getFilterDate, payloadData);
                reset({ total_no_of_suppliers: "", suppliers_screened_using_social_criteria: "", suppliers_assessed_for_social_impact: "", suppliers_having_significant_negative_impact: "", corrective_measure_given_to_supplier: "", relationship_terminated_for_negative_social_impact: "", })
                setAddOpenSocialScreening({ action: false, socialScreeningTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: addOpenSocialScreening.socialScreeningTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                getAllSocialScreeningData(getFilterDate, payloadData);
                reset({ total_no_of_suppliers: "", suppliers_screened_using_social_criteria: "", suppliers_assessed_for_social_impact: "", suppliers_having_significant_negative_impact: "", corrective_measure_given_to_supplier: "", relationship_terminated_for_negative_social_impact: "", })
                setAddOpenSocialScreening({ action: false, socialScreeningTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={addOpenSocialScreening.action} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold headingText'>{addOpenSocialScreening.socialScreeningTableData ? "Edit Social Screening" : "Add Social Screening"}</material.DialogTitle>
                <material.DialogContent>
                    {addOpenSocialScreening.socialScreeningTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Social Screening</material.Typography>
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("total_no_of_suppliers", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Total Number of Supplier"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.total_no_of_suppliers?.type === "required" && 'This field is required'}</p>
                                {errors.total_no_of_suppliers && errors.total_no_of_suppliers.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("suppliers_screened_using_social_criteria", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Supplier Screened Using Social Criteria"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.suppliers_screened_using_social_criteria?.type === "required" && 'This field is required'}</p>
                                {errors.suppliers_screened_using_social_criteria && errors.suppliers_screened_using_social_criteria.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("suppliers_assessed_for_social_impact", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Supplier Assessed for Social Impact"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.suppliers_assessed_for_social_impact?.type === "required" && 'This field is required'}</p>
                                {errors.suppliers_assessed_for_social_impact && errors.suppliers_assessed_for_social_impact.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-4 mt-3'>
                                <material.TextField
                                    {...register("suppliers_having_significant_negative_impact", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Supplier Having Significant Negative Impact"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.suppliers_having_significant_negative_impact?.type === "required" && 'This field is required'}</p>
                                {errors.suppliers_having_significant_negative_impact && errors.suppliers_having_significant_negative_impact.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-4 mt-3'>
                                <material.TextField
                                    {...register("corrective_measure_given_to_supplier", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Corrective Measure Given to Supplier"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.corrective_measure_given_to_supplier?.type === "required" && 'This field is required'}</p>
                                {errors.corrective_measure_given_to_supplier && errors.corrective_measure_given_to_supplier.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-4 mt-3'>
                                <material.TextField
                                    {...register("relationship_terminated_for_negative_social_impact", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    // className='mt-3'
                                    margin="dense"
                                    label="Relationship Terminated for Negative Social Impact"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.relationship_terminated_for_negative_social_impact?.type === "required" && 'This field is required'}</p>
                                {errors.relationship_terminated_for_negative_social_impact && errors.relationship_terminated_for_negative_social_impact.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenSocialScreening.socialScreeningTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} disabled={buttonDisable} onClick={handleSubmit(updateSocialScreening)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenSocialScreening.socialScreeningTableData.rejection_note === null) || (addOpenSocialScreening.socialScreeningTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} disabled={buttonDisable} onClick={handleSubmit(saveSocialScreening)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddSocialScreening;