import { rootApiUrl } from "../environment/Environment";
import { axiosInstance } from "../shared/AxiosConfig";

export const saveAssessmentQuestion = async (payload) => {
    return await axiosInstance.post(`${rootApiUrl}/api/supplier-question/save`, payload)
};

export const getAllAssessmentQuestion = async (corporateRefNo) => {
    return await axiosInstance.get(`${rootApiUrl}/api/supplier-question/get-all`, { params: { corporateRefNo } })
};

export const getAllAssessmentQuestionMapping = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/assessment-question-mapping/get-questions`, { params: { ...payload } })
};

export const getAllAssessmentAnswer = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/supplier-response/get-all`, { params: { ...payload } })
};

export const saveAssessmentAnswer = async (payload) => {
    return await axiosInstance.post(`${rootApiUrl}/api/supplier-response/save`, payload)
};

export const saveAssessment = async (payload) => {
    return await axiosInstance.post(`${rootApiUrl}/api/assessment/save`, payload)
};

export const getAssessment = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/assessment/get`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, filterApplicable: payload.filterApplicable } })
};