import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import moment from 'moment';


function AddAntiCompetitiveConduct(props) {

    const { treeViewData, openAntiCompetitiveConduct, setOpenAntiCompetitiveConduct, getAntiCompetitiveConduct, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [categoryData, setCategoryData] = useState('');
    const [legalActionStatusData, setLegalActionStatusData] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openAntiCompetitiveConduct.action && openAntiCompetitiveConduct.antiCompetitiveConductTableData) {
            setCategoryData(openAntiCompetitiveConduct.antiCompetitiveConductTableData.category);
            setLegalActionStatusData(openAntiCompetitiveConduct.antiCompetitiveConductTableData.legal_action_status);
            reset(openAntiCompetitiveConduct.antiCompetitiveConductTableData);
        }
    }, [openAntiCompetitiveConduct.action === true]);

    const changeCategory = (event) => {
        setCategoryData(event.target.value);
    };

    const changeLegalActionStatus = (event) => {
        setLegalActionStatusData(event.target.value);
    };

    const handleClose = () => {
        setCategoryData("");
        setLegalActionStatusData("");
        reset({ name_of_authority: "", brief_of_case: "", corrective_action_legal_actions_judgement: "", });
        setOpenAntiCompetitiveConduct({ action: false, antiCompetitiveConductTableData: null });
    };

    const saveAntiCompetitiveConduct = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "category": data.category,
                "name_of_authority": data.name_of_authority,
                "brief_of_case": data.brief_of_case,
                "legal_action_status": data.legal_action_status,
                "corrective_action_legal_actions_judgement": data.corrective_action_legal_actions_judgement,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAntiCompetitiveConduct(getFilterDate, payloadData);
                    setCategoryData("");
                    setLegalActionStatusData("");
                    reset({ name_of_authority: "", brief_of_case: "", corrective_action_legal_actions_judgement: "", });
                    setOpenAntiCompetitiveConduct({ action: false, antiCompetitiveConductTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateAntiCompetitiveConduct = async (data) => {
        const refNo = openAntiCompetitiveConduct.antiCompetitiveConductTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "category": data.category,
            "name_of_authority": data.name_of_authority,
            "brief_of_case": data.brief_of_case,
            "legal_action_status": data.legal_action_status,
            "corrective_action_legal_actions_judgement": data.corrective_action_legal_actions_judgement,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAntiCompetitiveConduct(getFilterDate, payloadData);
                setCategoryData("");
                setLegalActionStatusData("");
                reset({ name_of_authority: "", brief_of_case: "", corrective_action_legal_actions_judgement: "", });
                setOpenAntiCompetitiveConduct({ action: false, antiCompetitiveConductTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };


    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAntiCompetitiveConduct.antiCompetitiveConductTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setCategoryData("");
                setLegalActionStatusData("");
                reset({ name_of_authority: "", brief_of_case: "", corrective_action_legal_actions_judgement: "", });
                getAntiCompetitiveConduct(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAntiCompetitiveConduct({ action: false, antiCompetitiveConductTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };
    return (
        <div>
            <material.Dialog open={openAntiCompetitiveConduct.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAntiCompetitiveConduct.antiCompetitiveConductTableData ? "Edit Anti Competitive Conduct" : "Add Anti Competitive Conduct"}</material.DialogTitle>
                <material.DialogContent>
                    {openAntiCompetitiveConduct.antiCompetitiveConductTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Anti Competitive Conduct</material.Typography>
                            </div>
                            <div className='col-6'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Category</material.InputLabel>
                                    <material.Select
                                        {...register("category", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={categoryData}
                                        onChange={changeCategory}
                                        label="Category"
                                    >
                                        <material.MenuItem value={"Anti Competitive Conduct"}>Anti Competitive Conduct</material.MenuItem>
                                        <material.MenuItem value={"Anti Trust and Monopoly"}>Anti Trust and Monopoly</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6'>
                                <material.TextField
                                    {...register("name_of_authority", { required: true, maxLength: 20 })}
                                    required
                                    label="Name of Authority"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.name_of_authority?.type === "required" && 'This field is required'}</p>
                                {errors?.name_of_authority?.type === "maxLength" && (
                                    <p className='text-danger'>Maximum length 20 character</p>
                                )}
                            </div>
                            <div className='col-12 mt-2'>
                                <material.TextField
                                    {...register("brief_of_case", { required: true, minLength: 2, maxLength: 200 })}
                                    required
                                    multiline
                                    label="Brief of Case"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    rows={3}
                                />
                                <p className='form-text text-danger'>{errors.brief_of_case?.type === "required" && 'This field is required'}</p>
                                {errors.brief_of_case && (errors.brief_of_case.type === "minLength" || errors.brief_of_case.type === "maxLength") && (
                                    <p className="errorMsg text-danger">Describe The Brief of Case Issues should be between 2 to 200 character.</p>
                                )}
                            </div>
                            <div className='col-12 mt-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Legal Action Status</material.InputLabel>
                                    <material.Select
                                        {...register("legal_action_status", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={legalActionStatusData}
                                        onChange={changeLegalActionStatus}
                                        label="Legal Action Status"
                                    >
                                        <material.MenuItem value={"Completed"}>Completed</material.MenuItem>
                                        <material.MenuItem value={"Pending"}>Pending</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-12 mt-2'>
                                <material.TextField
                                    {...register("corrective_action_legal_actions_judgement", { required: true, minLength: 2, maxLength: 200 })}
                                    required
                                    multiline
                                    label="Corrective Action/Legal Actions/Judgement"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    rows={3}
                                />
                                <p className='form-text text-danger'>{errors.corrective_action_legal_actions_judgement?.type === "required" && 'This field is required'}</p>
                                {errors.corrective_action_legal_actions_judgement && (errors.corrective_action_legal_actions_judgement.type === "minLength" || errors.corrective_action_legal_actions_judgement.type === "maxLength") && (
                                    <p className="errorMsg text-danger">Corrective Action/Legal Actions/Judgement Issues should be between 2 to 200 character.</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAntiCompetitiveConduct.antiCompetitiveConductTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateAntiCompetitiveConduct)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAntiCompetitiveConduct.antiCompetitiveConductTableData.rejection_note === null) || (openAntiCompetitiveConduct.antiCompetitiveConductTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveAntiCompetitiveConduct)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddAntiCompetitiveConduct;