import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateChildAndForcedLabourData } from '../../services/sub-issue-services/human-rights-services/ChildAndForcedLabourService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


const childLabourData = ["Young Workers Engaged in Facility", "Young Workers Exposed to Hazardous Work"];
const forcedLabourData = ["Slave Labour", "Bonded Labour"];

function AddChildAndForcedLabour(props) {

    const { openChildAndForcedLabour, setOpenChildAndForcedLabour, getChildAndForcedLabourAllData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const { register, handleSubmit, reset, watch, control, getValues, setValue, formState: { errors }, } = useForm({ mode: "onTouched" });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [typeOfOperation, setTypeOfOperation] = useState("");
    const [geographicLocation, setGeographicLocation] = useState("");
    const [humanRightRiskOn, setHumanRightRiskOn] = useState("");
    const [significantRiskOn, setSignificantRiskOn] = useState("");
    const [significantRiskOnData, setSignificantRiskOnData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openChildAndForcedLabour.action && openChildAndForcedLabour.childAndForcedLabourTableData) {
            setTypeOfOperation(openChildAndForcedLabour.childAndForcedLabourTableData.type_of_operation)
            setGeographicLocation(openChildAndForcedLabour.childAndForcedLabourTableData.geographic_location_based_risk_assessed)
            setHumanRightRiskOn(openChildAndForcedLabour.childAndForcedLabourTableData.human_right_risk)
            setSignificantRiskOn(openChildAndForcedLabour.childAndForcedLabourTableData.significant_risk)
            if (openChildAndForcedLabour.childAndForcedLabourTableData.human_right_risk === "Child Labour") {
                setSignificantRiskOnData(childLabourData)
            } else {
                setSignificantRiskOnData(forcedLabourData)
            }
            reset(openChildAndForcedLabour.childAndForcedLabourTableData);
        };
    }, [openChildAndForcedLabour.action === true]);

    const changeTypeOfOperation = (event) => {
        setTypeOfOperation(event.target.value);
    };
    const changeGeographicLocation = (event) => {
        setGeographicLocation(event.target.value);
    };
    const changeHumanRightRiskOn = (event) => {
        let humanRightRiskOnData = event.target.value;
        setHumanRightRiskOn(humanRightRiskOnData);
        if (humanRightRiskOnData === "Child Labour") {
            setSignificantRiskOnData(childLabourData)
        } else {
            setSignificantRiskOnData(forcedLabourData)
        };
    };
    const changeSignificantRiskOn = (event) => {
        setSignificantRiskOn(event.target.value);
    };
    const handleClose = () => {
        reset({ total_number_of_case: "", measures_taken_to_effective_abolition_of_child_and_force_labour: "" })
        setTypeOfOperation("");
        setGeographicLocation("");
        setHumanRightRiskOn("");
        setSignificantRiskOnData([]);
        setSignificantRiskOn("");
        setOpenChildAndForcedLabour({ action: false, childAndForcedLabourTableData: null });
    };

    const saveChildAndForcedLabour = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "type_of_operation": data.type_of_operation,
                "geographic_location_based_risk_assessed": data.geographic_location_based_risk_assessed,
                "human_right_risk": data.human_right_risk,
                "significant_risk": data.significant_risk,
                "total_number_of_case": data.total_number_of_case,
                "measures_taken_to_effective_abolition_of_child_and_force_labour": data.measures_taken_to_effective_abolition_of_child_and_force_labour,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getChildAndForcedLabourAllData(getFilterDate, payloadData);
                    reset({ total_number_of_case: "", measures_taken_to_effective_abolition_of_child_and_force_labour: "" })
                    setTypeOfOperation("");
                    setGeographicLocation("");
                    setHumanRightRiskOn("");
                    setSignificantRiskOnData([]);
                    setSignificantRiskOn("");
                    setOpenChildAndForcedLabour({ action: false, childAndForcedLabourTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateChildAndForcedLabour = async (data) => {
        const refNo = openChildAndForcedLabour.childAndForcedLabourTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "type_of_operation": data.type_of_operation,
            "geographic_location_based_risk_assessed": data.geographic_location_based_risk_assessed,
            "human_right_risk": data.human_right_risk,
            "significant_risk": data.significant_risk,
            "total_number_of_case": data.total_number_of_case,
            "measures_taken_to_effective_abolition_of_child_and_force_labour": data.measures_taken_to_effective_abolition_of_child_and_force_labour,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getChildAndForcedLabourAllData(getFilterDate, payloadData);
                reset({ total_number_of_case: "", measures_taken_to_effective_abolition_of_child_and_force_labour: "" })
                setTypeOfOperation("");
                setGeographicLocation("");
                setHumanRightRiskOn("");
                setSignificantRiskOnData([]);
                setSignificantRiskOn("");
                setOpenChildAndForcedLabour({ action: false, childAndForcedLabourTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openChildAndForcedLabour.childAndForcedLabourTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                getChildAndForcedLabourAllData(getFilterDate, payloadData);
                reset({ total_number_of_case: "", measures_taken_to_effective_abolition_of_child_and_force_labour: "" })
                setTypeOfOperation("");
                setGeographicLocation("");
                setHumanRightRiskOn("");
                setSignificantRiskOnData([]);
                setSignificantRiskOn("");
                setOpenChildAndForcedLabour({ action: false, childAndForcedLabourTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <>
            <material.Dialog open={openChildAndForcedLabour.action} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold'>
                    {openChildAndForcedLabour.childAndForcedLabourTableData ? "Edit Child and Forced Labour" : "Add Child and Forced Labour"}
                </material.DialogTitle>
                <material.DialogContent>
                    <div className='my-3'>
                        {openChildAndForcedLabour.childAndForcedLabourTableData ? null : (
                            <DatePeriod
                                register={register}
                                control={control}
                            />
                        )}
                    </div>
                    <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Child and Forced Labour</material.Typography>
                            </div>
                            <div className='col-6 my-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Type of Operation</material.InputLabel>
                                    <material.Select
                                        {...register("type_of_operation", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={typeOfOperation}
                                        onChange={changeTypeOfOperation}
                                        label="Type Of Operation"
                                    >
                                        <material.MenuItem value={"Facility it Self"}>Facility it Self</material.MenuItem>
                                        <material.MenuItem value={"Supplier"}>Supplier</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 my-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Geographic Location Based Risk Assessed</material.InputLabel>
                                    <material.Select
                                        {...register("geographic_location_based_risk_assessed", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={geographicLocation}
                                        onChange={changeGeographicLocation}
                                        label="Geographic Location Based Risk Assessed"
                                    >
                                        <material.MenuItem value={"Yes"}>Yes</material.MenuItem>
                                        <material.MenuItem value={"No"}>No</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 my-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Human Right Risk on</material.InputLabel>
                                    <material.Select
                                        {...register("human_right_risk", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={humanRightRiskOn}
                                        onChange={changeHumanRightRiskOn}
                                        label="Human Right Risk On"
                                    >
                                        <material.MenuItem value={"Child Labour"}>Child Labour</material.MenuItem>
                                        <material.MenuItem value={"Forced Labour"}>Forced Labour</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 my-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Significant Risk on</material.InputLabel>
                                    <material.Select
                                        {...register("significant_risk", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={significantRiskOn}
                                        onChange={changeSignificantRiskOn}
                                        label="Significant Risk on"
                                    >
                                        {significantRiskOnData?.map((ele, ind) => (
                                            <material.MenuItem key={ind} value={ele}>{ele}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 my-2'>
                                <material.TextField
                                    {...register("total_number_of_case", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Total Number of Case"
                                    id="female"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                />
                                {errors.total_number_of_case && errors.total_number_of_case.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-6 my-2'>
                                <material.TextField
                                    {...register("measures_taken_to_effective_abolition_of_child_and_force_labour", { required: true, })}
                                    required
                                    margin="dense"
                                    label="Measures Taken to Effective Abolition of Child  & Force Labour"
                                    id="measures_taken_to_effective_abolition_of_child_and_force_labour"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.measures_taken_to_effective_abolition_of_child_and_force_labour?.type === "required" && 'This field is required'}</p>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openChildAndForcedLabour.childAndForcedLabourTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateChildAndForcedLabour)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openChildAndForcedLabour.childAndForcedLabourTableData.rejection_note === null) || (openChildAndForcedLabour.childAndForcedLabourTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveChildAndForcedLabour)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </>
    )
}

export default AddChildAndForcedLabour;