import React, { useEffect } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import AddAirEmissionPointSource from '../../../sub-issues-dialogs/air-sub-issue-dialogs/AddAirEmissionPointSource';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import Snackbar from '../../../toaster-message/Snackbar';
import moment from 'moment';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';
import { approveOrRejectAirEmissionPointSourceRecords, getAirEmissionPointSourceData, sendForApprovalAirEmissionPointSourceRecords } from '../../../services/sub-issue-services/air-service/AirEmissionPointSourceService';
import AddPollutantRatePointSource from '../../../sub-issues-dialogs/air-sub-issue-dialogs/AddPollutantRatePointSource';
import Pagination from '../../../shared/pagination/Pagination';


const label = { inputProps: { 'aria-label': 'Color switch demo', 'aria-label2': 'Checkbox demo' } };
var usersRole;
var recordRefNos = [];

function AirEmissionsPointSource(props) {
    const location = useLocation();
    const treeViewData = location?.state;

    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };
    const [openAirEmissionsPointSourceDialog, setOpenAirEmissionsPointSourceDialog] = useState({ action: false, airEmissionsQuantityData: null });
    const [airEmissionQuantityData, setAirEmissionQuantityData] = useState([]);
    const [airTabValue, setAirTabValue] = React.useState('Air Emissions Point Source');
    const [applicable, setApplicable] = useState(true);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [pollutantDetails, setPollutantDetails] = useState([]);
    const [openAddPollutantRateAreaSource, setOpenAddPollutantRateAreaSource] = useState({ action: false, pollutantRateAreaSourceTableData: null });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [getFilterDate, setGetFilterDate] = useState(null);

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        allAirEmissionQuantityData()
    }, [airTabValue]);

    const getFormDateToDate = (data) => {
        setGetFilterDate(data);
        allAirEmissionQuantityData(data, paginationData)
    };

    const allAirEmissionQuantityData = async (filterDate, paginationValue) => {
        let forApproval = userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? true : null;
        let approveHistory = false;
        if (airTabValue === "Air Emissions Point Source") {
            const payload = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            await getAirEmissionPointSourceData(payload)
                .then((resp) => {
                    // let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.airEmissionsPointSourceDtos)
                    setAirEmissionQuantityData(resp.data.airEmissionsPointSourceDtos);
                    setApplicable(resp.data.applicable);
                    setApplicableRefNo(resp.data.applicableRefNo);
                    setRowsPerPage(resp.data.size);
                    setPage(resp.data.number);
                    setTotalElements(resp.data.totalElements);
                    let pollutantData = resp.data.airEmissionsPointSourceDtos?.map((ele) => {
                        let data = ele.airEmissionPointSourcePollutantDto.map((resp) => {
                            let obj = {
                                ...resp,
                                sourceIds: ele.sourceIds
                            }
                            return obj;
                        })
                        return data;
                    });
                    let concatData = pollutantData.flat();
                    setPollutantDetails(concatData);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    }
                })
        } else if (airTabValue === "Air Emissions Point Source History") {
            const payload = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10,
                forApproval: true,
                approveHistory: true,
            }
            await getAirEmissionPointSourceData(payload)
                .then((resp) => {
                    setAirEmissionQuantityData(resp.data.airEmissionsPointSourceDtos);
                    setApplicable(resp.data.applicable);
                    setRowsPerPage(resp.data.size);
                    setPage(resp.data.number);
                    setTotalElements(resp.data.totalElements);
                    let pollutantData = resp.data.airEmissionsPointSourceDtos?.map((ele) => {
                        let data = ele.airEmissionPointSourcePollutantDto.map((resp) => {
                            let obj = {
                                ...resp,
                                sourceIds: ele.sourceIds
                            }
                            return obj;
                        })
                        return data;
                    });
                    let concatData = pollutantData.flat();
                    setPollutantDetails(concatData);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    }
                })
        };
    };

    const addAirEmissionsQuantity = () => {
        setOpenAirEmissionsPointSourceDialog({ action: true, airEmissionsQuantityData: null });
    };
    const editAirEmissionsQuantity = (rowData) => {
        setOpenAirEmissionsPointSourceDialog({ action: true, airEmissionsQuantityData: rowData });
    };
    const handleChangeAirTab = (event, newValue) => {
        setAirTabValue(newValue);
    };
    const applicableNotApplicable = (data) => {
        setOpenWarningDialog(true)
    };
    const handleClickCheckbox = (value) => {
        setOpenAirEmissionsPointSourceDialog({ action: false, airEmissionsQuantityData: null });
        if (recordRefNos.includes(value)) {
            // recordRefNos.pop(value)
            recordRefNos = recordRefNos.filter(item => item !== value);
        } else {
            recordRefNos.push(value)
        };
    };

    const sendApproval = async () => {
        if (recordRefNos.length) {
            await sendForApprovalAirEmissionPointSourceRecords(recordRefNos)
                .then((resp) => {
                    allAirEmissionQuantityData(getFilterDate, paginationData)
                    recordRefNos = [];
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    }
                })
        };
    };

    const approve = async () => {
        if (recordRefNos.length) {
            let obj = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNos
            }
            await approveOrRejectAirEmissionPointSourceRecords(obj)
                .then((resp) => {
                    allAirEmissionQuantityData(getFilterDate, paginationData)
                    recordRefNos = [];
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    }
                })
        };
    };
    const reject = async () => {
        if (recordRefNos.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        };
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNos
        }
        await approveOrRejectAirEmissionPointSourceRecords(obj)
            .then((resp) => {
                allAirEmissionQuantityData(getFilterDate, paginationData)
                recordRefNos = [];
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                }
            })
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const editAirEmissionsPollutantDetails = (pollutantData) => {
        setOpenAddPollutantRateAreaSource({ action: true, pollutantRateAreaSourceTableData: pollutantData });
    };

    const getPaginationData = (filterData, paginationData) => {
        setRowsPerPage(paginationData.size);
        setPage(paginationData.page);
        allAirEmissionQuantityData(filterData, paginationData);
    };


    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7, width: 100 }}>
                    <div className='row'>
                        <div className='col-6'>
                            <span><material.Typography variant="h5" className='text-capitalize'>{treeViewData?.subIssue}</material.Typography></span>
                        </div>
                        <div className='col-6'>
                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") || userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? null : (
                                <span className="float-end">
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} onChange={applicableNotApplicable}
                                            checked={applicable}
                                            color="secondary" />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>
                    <div>
                        <material.Box sx={{ width: '100%', typography: 'body1' }}>
                            <material.TabContext value={airTabValue}>
                                <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <material.TabList onChange={handleChangeAirTab} aria-label="lab API tabs example">
                                        <material.Tab className='text-capitalize' label="Air Emissions Point Source" value="Air Emissions Point Source" />
                                        <material.Tab className='text-capitalize' label="Air Emissions Point Source History" value="Air Emissions Point Source History" />
                                    </material.TabList>
                                </material.Box>
                                <material.TabPanel value="Air Emissions Point Source">
                                    <material.Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                                        <div className="row mt-2 align-items-center">
                                            <div className="col-lg-6 pb-2">
                                                {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                    <DateFilter
                                                        getFormDateToDate={getFormDateToDate}
                                                    />
                                                )}
                                            </div>
                                            <div className="col-6 my-2" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                <span className='float-end'>
                                                    {airEmissionQuantityData?.length ? (
                                                        <div>
                                                            <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")} color='success' disabled={!applicable || !recordRefNos.length}
                                                                sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approve}>Approve</material.Button>
                                                            <material.Button sx={{ textTransform: "none" }} hidden={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")} disabled={!applicable || !recordRefNos.length}
                                                                variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={reject}>Reject</material.Button>
                                                        </div>
                                                    ) : null}
                                                    <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_APPROVER")} color='success' disabled={!applicable}
                                                        sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendApproval}>Send for Approval</material.Button>
                                                    <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_APPROVER")} color='success' disabled={!applicable}
                                                        sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addAirEmissionsQuantity}>Add Air Emissions Point Source</material.Button>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='py-2'>
                                            <material.Accordion>
                                                <material.AccordionSummary
                                                    expandIcon={<material.ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Air Emission Source Details (Point Source)</material.Typography>
                                                </material.AccordionSummary>
                                                <material.AccordionDetails>
                                                    <div className='row'>
                                                        <div className="col-12">
                                                            <material.TableContainer >
                                                                <material.Table>
                                                                    <material.TableHead>
                                                                        <material.TableRow >
                                                                            <StyledTableCell>Source Id</StyledTableCell>
                                                                            <StyledTableCell>Stack Coordinate (Latitude)</StyledTableCell>
                                                                            <StyledTableCell>Stack Coordinate (Longitude)</StyledTableCell>
                                                                            <StyledTableCell>Stack Height (m)</StyledTableCell>
                                                                            <StyledTableCell>Stack Diameter (m)</StyledTableCell>
                                                                            <StyledTableCell>Stack Gas Velocity (m/s)</StyledTableCell>
                                                                            <StyledTableCell>Stack Tempe (°C)</StyledTableCell>
                                                                            <StyledTableCell>From Date</StyledTableCell>
                                                                            <StyledTableCell>To Date</StyledTableCell>
                                                                            {/* {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                                <StyledTableCell>Approval Date</StyledTableCell>
                                                                            )} */}
                                                                            <StyledTableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>{userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                                <StyledTableCell>Rejection Note</StyledTableCell>
                                                                            )}
                                                                        </material.TableRow>
                                                                    </material.TableHead>
                                                                    <material.TableBody>
                                                                        {airEmissionQuantityData?.length ? airEmissionQuantityData.map((item, ind) => (
                                                                            <material.TableRow key={ind}
                                                                                sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                                onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true) ? () => editAirEmissionsQuantity(item) : null}
                                                                            >
                                                                                <material.TableCell>{item.sourceIds}</material.TableCell>
                                                                                <material.TableCell>{item.coordinateEasting}</material.TableCell>
                                                                                <material.TableCell>{item.coordinateNorthing}</material.TableCell>
                                                                                <material.TableCell>{item.stackIn}</material.TableCell>
                                                                                <material.TableCell>{item.stackDia}</material.TableCell>
                                                                                <material.TableCell>{item.stackVe}</material.TableCell>
                                                                                <material.TableCell>{item.temp}</material.TableCell>
                                                                                <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                                <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                                {/* {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                                    <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                                )} */}
                                                                                <material.TableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                                                                    {userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") ? (
                                                                                        <material.Checkbox {...label} disabled={!applicable || item.sendForApproval} onChange={() => handleClickCheckbox(item.refNo)} color="success" />
                                                                                    ) : (
                                                                                        <material.Checkbox {...label} disabled={!applicable || item.approvedStatus === "APPROVED"} onChange={() => handleClickCheckbox(item.refNo)} color="success" />
                                                                                    )}
                                                                                </material.TableCell>
                                                                                {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                                    <material.TableCell>{(item.rejectionNote === null) || (item.rejectionNote === "") ? "No" : (
                                                                                        <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                                    )}
                                                                                    </material.TableCell>
                                                                                )}
                                                                            </material.TableRow>
                                                                        )) : (
                                                                            <material.TableRow >
                                                                                <material.TableCell colSpan={13}>
                                                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                                </material.TableCell>
                                                                            </material.TableRow>
                                                                        )}
                                                                    </material.TableBody>
                                                                </material.Table>
                                                            </material.TableContainer>
                                                            <Pagination
                                                                getPaginationData={getPaginationData}
                                                                totalElements={totalElements}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                getFilterDate={getFilterDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </material.AccordionDetails>
                                            </material.Accordion>
                                        </div>
                                        <div className='mt-1 py-2'>
                                            <material.Accordion>
                                                <material.AccordionSummary
                                                    expandIcon={<material.ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Air Emission Pollutant & Rate (Point Source)</material.Typography>
                                                </material.AccordionSummary>
                                                <material.AccordionDetails>
                                                    <div className='row mt-2'>
                                                        <div className="col-12">
                                                            <material.TableContainer >
                                                                <material.Table>
                                                                    <material.TableHead>
                                                                        <material.TableRow >
                                                                            <StyledTableCell>Source Id</StyledTableCell>
                                                                            <StyledTableCell>Pollutant Name</StyledTableCell>
                                                                            <StyledTableCell>Pollutants Name Others (Specify)</StyledTableCell>
                                                                            <StyledTableCell>Concentration (mg/m3)</StyledTableCell>
                                                                            <StyledTableCell>Rate (gm/sec)</StyledTableCell>
                                                                            {/* {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                                <StyledTableCell>Approval Date</StyledTableCell>
                                                                            )} */}
                                                                        </material.TableRow>
                                                                    </material.TableHead>
                                                                    <material.TableBody>
                                                                        {pollutantDetails?.length ? pollutantDetails.map((item, ind) => (
                                                                            <material.TableRow key={ind}
                                                                                sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                                onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true) ? () => editAirEmissionsPollutantDetails(item) : null}
                                                                            >
                                                                                <material.TableCell>{item.sourceIds}</material.TableCell>
                                                                                <material.TableCell>{(item.pollutant === null || item.pollutant === "") ? "N/A" : item.pollutant}</material.TableCell>
                                                                                <material.TableCell>{(item.otherSpecifyPollutant === null || item.otherSpecifyPollutant === "") ? "N/A" : item.otherSpecifyPollutant}</material.TableCell>
                                                                                <material.TableCell>{(item.concentration === null || item.concentration === "") ? "N/A" : item.concentration}</material.TableCell>
                                                                                <material.TableCell>{(item.pollutantRate === null || item.pollutantRate === "") ? "N/A" : item.pollutantRate}</material.TableCell>
                                                                                {/* {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                                    <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                                )} */}
                                                                            </material.TableRow>
                                                                        )) : (
                                                                            <material.TableRow >
                                                                                <material.TableCell colSpan={10}>
                                                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                                </material.TableCell>
                                                                            </material.TableRow>
                                                                        )}
                                                                    </material.TableBody>
                                                                </material.Table>
                                                            </material.TableContainer>
                                                            <Pagination
                                                                getPaginationData={getPaginationData}
                                                                totalElements={totalElements}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                getFilterDate={getFilterDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </material.AccordionDetails>
                                            </material.Accordion>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                                <material.TabPanel value="Air Emissions Point Source History">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        </div>
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                                        <div className='mt-2 py-2'>
                                            <material.Accordion>
                                                <material.AccordionSummary
                                                    expandIcon={<material.ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Air Emission Source Details (Point Source)</material.Typography>
                                                </material.AccordionSummary>
                                                <material.AccordionDetails>
                                                    <div className='row mt-2'>
                                                        <div className="col-12">
                                                            <material.TableContainer >
                                                                <material.Table>
                                                                    <material.TableHead>
                                                                        <material.TableRow >
                                                                            <StyledTableCell>Source Id</StyledTableCell>
                                                                            <StyledTableCell>Stack Coordinate (Latitude)</StyledTableCell>
                                                                            <StyledTableCell>Stack Coordinate (Longitude)</StyledTableCell>
                                                                            <StyledTableCell>Stack Height (m)</StyledTableCell>
                                                                            <StyledTableCell>Stack Diameter (m)</StyledTableCell>
                                                                            <StyledTableCell>Stack Gas Velocity (m/s)</StyledTableCell>
                                                                            <StyledTableCell>Stack Tempe (°C)</StyledTableCell>
                                                                            <StyledTableCell>From Date</StyledTableCell>
                                                                            <StyledTableCell>To Date</StyledTableCell>
                                                                            <StyledTableCell>Approval Date</StyledTableCell>
                                                                            <StyledTableCell>Status</StyledTableCell>
                                                                        </material.TableRow>
                                                                    </material.TableHead>
                                                                    <material.TableBody>
                                                                        {airEmissionQuantityData?.length ? airEmissionQuantityData.map((item, ind) => (
                                                                            <material.TableRow key={ind}>
                                                                                <material.TableCell>{item.sourceIds}</material.TableCell>
                                                                                <material.TableCell>{item.coordinateEasting}</material.TableCell>
                                                                                <material.TableCell>{item.coordinateNorthing}</material.TableCell>
                                                                                <material.TableCell>{item.stackIn}</material.TableCell>
                                                                                <material.TableCell>{item.stackDia}</material.TableCell>
                                                                                <material.TableCell>{item.stackVe}</material.TableCell>
                                                                                <material.TableCell>{item.temp}</material.TableCell>
                                                                                <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                                <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                                <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : null}</material.TableCell>
                                                                                <material.TableCell>
                                                                                    {item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                                        APPROVED
                                                                                    </span> : null}
                                                                                </material.TableCell>
                                                                            </material.TableRow>
                                                                        )) : (
                                                                            <material.TableRow >
                                                                                <material.TableCell colSpan={12}>
                                                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                                </material.TableCell>
                                                                            </material.TableRow>
                                                                        )}
                                                                    </material.TableBody>
                                                                </material.Table>
                                                            </material.TableContainer>
                                                            <Pagination
                                                                getPaginationData={getPaginationData}
                                                                totalElements={totalElements}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                getFilterDate={getFilterDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </material.AccordionDetails>
                                            </material.Accordion>
                                        </div>
                                        <div className='py-2'>
                                            <material.Accordion>
                                                <material.AccordionSummary
                                                    expandIcon={<material.ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Air Emission Pollutant & Rate (Point Source)</material.Typography>
                                                </material.AccordionSummary>
                                                <material.AccordionDetails>
                                                    <div className='row'>
                                                        <div className="col-12">
                                                            <material.TableContainer >
                                                                <material.Table>
                                                                    <material.TableHead>
                                                                        <material.TableRow >
                                                                            <StyledTableCell>Source Id</StyledTableCell>
                                                                            <StyledTableCell>Pollutant Name</StyledTableCell>
                                                                            <StyledTableCell>Pollutant Name Others (Specify)</StyledTableCell>
                                                                            <StyledTableCell>Concentration (mg/m3)</StyledTableCell>
                                                                            <StyledTableCell>Rate (gm/sec)</StyledTableCell>
                                                                            <StyledTableCell>Approval Date</StyledTableCell>
                                                                            <StyledTableCell>Status</StyledTableCell>
                                                                        </material.TableRow>
                                                                    </material.TableHead>
                                                                    <material.TableBody>
                                                                        {pollutantDetails?.length ? pollutantDetails.map((item, ind) => (
                                                                            <material.TableRow key={ind}>
                                                                                <material.TableCell>{item.sourceIds}</material.TableCell>
                                                                                <material.TableCell>{(item.pollutant === null || item.pollutant === "") ? "N/A" : item.pollutant}</material.TableCell>
                                                                                <material.TableCell>{(item.otherSpecifyPollutant === null || item.otherSpecifyPollutant === "") ? "N/A" : item.otherSpecifyPollutant}</material.TableCell>
                                                                                <material.TableCell>{(item.concentration === null || item.concentration === "") ? "N/A" : item.concentration}</material.TableCell>
                                                                                <material.TableCell>{(item.pollutantRate === null || item.pollutantRate === "") ? "N/A" : item.pollutantRate}</material.TableCell>
                                                                                <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : null}</material.TableCell>
                                                                                <material.TableCell>
                                                                                    {item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                                        APPROVED
                                                                                    </span> : null}
                                                                                </material.TableCell>
                                                                            </material.TableRow>
                                                                        )) : (
                                                                            <material.TableRow >
                                                                                <material.TableCell colSpan={10}>
                                                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                                </material.TableCell>
                                                                            </material.TableRow>
                                                                        )}
                                                                    </material.TableBody>
                                                                </material.Table>
                                                            </material.TableContainer>
                                                            <Pagination
                                                                getPaginationData={getPaginationData}
                                                                totalElements={totalElements}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                getFilterDate={getFilterDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </material.AccordionDetails>
                                            </material.Accordion>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                            </material.TabContext>
                        </material.Box>
                    </div>
                </material.Box>
            </material.Box>
            <AddAirEmissionPointSource
                openAirEmissionsPointSourceDialog={openAirEmissionsPointSourceDialog}
                setOpenAirEmissionsPointSourceDialog={setOpenAirEmissionsPointSourceDialog}
                allAirEmissionQuantityData={allAirEmissionQuantityData}
                treeViewData={treeViewData}
                page={page}
                rowsPerPage={rowsPerPage}
                getFilterDate={getFilterDate}
            />
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                allAirEmissionQuantityData={allAirEmissionQuantityData}
                treeViewData={treeViewData}
                applicable={applicable}
                applicableRefNo={applicableRefNo}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
            <AddPollutantRatePointSource
                openAddPollutantRateAreaSource={openAddPollutantRateAreaSource}
                setOpenAddPollutantRateAreaSource={setOpenAddPollutantRateAreaSource}
                allAirEmissionQuantityData={allAirEmissionQuantityData}
            />
        </div>
    );
}

export default AirEmissionsPointSource;
