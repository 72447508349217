import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import AddCommunicationOfCriticalConcerns from '../../../sub-issues-dialogs/governance-structure/AddCommunicationOfCriticalConcerns';
import { useSelector } from 'react-redux';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import moment from 'moment';
import DateFilter from '../../../shared/DateFilter';
import SpecificDisclosure from '../../specific-disclosure/SpecificDisclosure';
import SpecificDisclosureHistory from '../../specific-disclosure/SpecificDisclosureHistory';
import Snackbar from '../../../toaster-message/Snackbar';
import RejectionNote from '../../../dialogs/RejectionNote';
import { getSubIssueRecordData, saveSubIssueData, updateSubIssueData } from '../../../services/sub-issue-services/AddSubissueService';
import ApplicableDialog from '../../../dialogs/ApplicableDialog';
import Pagination from '../../../shared/pagination/Pagination';


var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };
var recordRefNo = [];

function CommunicationOfCriticalConcerns(props) {
    const location = useLocation();
    const treeViewData = location.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);

    const [communicationOfCriticalConcernsTabs, setCommunicationOfCriticalConcernsTabs] = useState('Communication of Critical Concerns');
    const [openAddCommunicationOfCriticalConcerns, setOpenAddCommunicationOfCriticalConcerns] = useState({ action: false, communicationOfCriticalConcernsTableData: null });
    const [communicationOfCriticalConcernsTableData, setCommunicationOfCriticalConcernsTableData] = useState([]);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [applicable, setApplicable] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = React.useState(0);
    const [getFilterDate, setGetFilterDate] = useState(null);

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        getAllCommunicationOfCriticalConcernsData()
    }, [communicationOfCriticalConcernsTabs])

    const handleChange = (event, newValue) => {
        setCommunicationOfCriticalConcernsTabs(newValue);
    };

    const getFormDateToDate = (data) => {
        setGetFilterDate(data);
        getAllCommunicationOfCriticalConcernsData(data, paginationData)
    }

    const getAllCommunicationOfCriticalConcernsData = (filterDate, paginationValue) => {
        if (communicationOfCriticalConcernsTabs === "Communication of Critical Concerns") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            const payload = {
                subissueName: treeViewData.subIssue,
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                forApproval,
                approveHistory,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            getSubIssueRecordData(payload)
                .then((resp) => {
                    setCommunicationOfCriticalConcernsTableData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setApplicableRefNo(resp.data.applicableRecord?.ref_no ? resp.data.applicableRecord.ref_no : null);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else if (communicationOfCriticalConcernsTabs === "Communication of Critical Concerns History") {
            let forApproval = true;
            let approveHistory = true;
            const payload = {
                subissueName: treeViewData.subIssue,
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                forApproval,
                approveHistory,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            getSubIssueRecordData(payload)
                .then((resp) => {
                    setCommunicationOfCriticalConcernsTableData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const addCommunicationOfCriticalConcerns = () => {
        setOpenAddCommunicationOfCriticalConcerns({ action: true, communicationOfCriticalConcernsTableData: null });
    };
    const editCommunicationOfCriticalConcerns = (recordData) => {
        setOpenAddCommunicationOfCriticalConcerns({ action: true, communicationOfCriticalConcernsTableData: recordData });
    };

    const handleClickCheckbox = (ids) => {
        setOpenAddCommunicationOfCriticalConcerns({ action: false, communicationOfCriticalConcernsTableData: null });
        if (recordRefNo.includes(ids)) {
            recordRefNo.pop(ids);
        } else {
            recordRefNo.push(ids);
        }
    };

    const sendApprovalCommunicationOfCriticalConcerns = () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true
            }
            updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllCommunicationOfCriticalConcernsData(getFilterDate, paginationData);
                    recordRefNo = [];
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    }

    const approvedCommunicationOfCriticalConcerns = () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true,
                "approved_by": userDetails.username,
                "approved_date": moment().format("YYYY-MM-DD"),
                "approved_status": "APPROVED",
                "rejection_note": "",
            }
            updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllCommunicationOfCriticalConcernsData(getFilterDate, paginationData);
                    recordRefNo = [];
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const rejectRecordCommunicationOfCriticalConcerns = () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        };
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const getRejectionNoteData = async (noteData) => {
        const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
        const subissueName = treeViewData.subIssue;
        const payload = {
            send_for_approval: false,
            "rejection_note": noteData.rejectionNote,
            "approved_status": "",
        }
        updateSubIssueData(payload, refNo, subissueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllCommunicationOfCriticalConcernsData(getFilterDate, paginationData);
                recordRefNo = [];
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true)
    };

    const getApplicableData = () => {
        if (applicableRefNo === null) {
            let subIssueName = treeViewData?.subIssue;
            const payload = {
                applicable: false,
                applicable_record: true,
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
            }
            saveSubIssueData(payload, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllCommunicationOfCriticalConcernsData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else {
            let subIssueName = treeViewData?.subIssue;
            let refNo = applicableRefNo;
            const payload = {
                applicable: applicable ? false : true,
            }
            updateSubIssueData(payload, refNo, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllCommunicationOfCriticalConcernsData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const getPaginationData = (filterData, paginationData) => {
        setRowsPerPage(paginationData.size);
        setPage(paginationData.page);
        getAllCommunicationOfCriticalConcernsData(filterData, paginationData);
    };


    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className="row">
                        <div className="col-6">
                            <span><material.Typography variant="h5" className='headingText'>{treeViewData.subIssue}</material.Typography></span>
                        </div>
                        <div className="col-6">
                            <span className="float-end" hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}>
                                <material.Stack direction="row" spacing={1} alignItems="center">
                                    <material.Typography>Not applicable</material.Typography>
                                    <material.Switch {...label} color="secondary" checked={applicable}
                                        onChange={applicableNotApplicable}
                                    />
                                    <material.Typography>Applicable</material.Typography>
                                </material.Stack>
                            </span>
                        </div>
                    </div>
                    <material.Box sx={{ width: '100%', typography: 'body1' }}>
                        <material.TabContext value={communicationOfCriticalConcernsTabs}>
                            <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <material.TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <material.Tab sx={{ textTransform: 'none', }} label="Communication of Critical Concerns" value="Communication of Critical Concerns" />
                                    <material.Tab sx={{ textTransform: 'none', }} label="Communication of Critical Concerns History" value="Communication of Critical Concerns History" />
                                    <material.Tab className='text-capitalize' label="Specific Disclosure" value="Specific Disclosure" />
                                    <material.Tab className='text-capitalize' label="Specific Disclosure History" value="Specific Disclosure History" />
                                </material.TabList>
                            </material.Box>
                            <material.TabPanel value="Communication of Critical Concerns">
                                <div className="row mt-2">
                                    <div className="col-6">
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        )}
                                    </div>
                                    <div className="col-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? (
                                            <>
                                                {communicationOfCriticalConcernsTableData?.length ? (
                                                    <span className='float-end'>
                                                        <material.Button variant="contained" color='success' sx={{ textTransform: "none", mr: 2 }} disabled={!applicable} startIcon={<material.DoneIcon />} onClick={approvedCommunicationOfCriticalConcerns} >Approve</material.Button>
                                                        <material.Button variant="contained" color='error' sx={{ textTransform: "none" }} disabled={!applicable} onClick={rejectRecordCommunicationOfCriticalConcerns} startIcon={<material.PriorityHighIcon />}>Reject</material.Button>
                                                    </span>
                                                ) : null}
                                            </>
                                        ) : (
                                            <span className='float-end'>
                                                <material.Button variant="contained" color='success' sx={{ textTransform: "none", mr: 2 }} startIcon={<material.SendIcon />} disabled={!applicable} onClick={sendApprovalCommunicationOfCriticalConcerns}>Send for Approval</material.Button>
                                                <material.Button variant="contained" color='success' sx={{ textTransform: "none" }} disabled={!applicable} startIcon={<material.AddIcon />} onClick={addCommunicationOfCriticalConcerns} >Add Communication of Critical Concerns</material.Button>
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                                    <div className='row'>
                                        <div className="col-12">
                                            <material.TableContainer >
                                                <material.Table>
                                                    <material.TableHead>
                                                        <material.TableRow >
                                                            <StyledTableCell>Total Number of Critical Concerns</StyledTableCell>
                                                            <StyledTableCell>Nature of Critical Concern</StyledTableCell>
                                                            <StyledTableCell>Total Number of Critical Concerns Communicated to Highest Governance Body </StyledTableCell>
                                                            <StyledTableCell>From Date</StyledTableCell>
                                                            <StyledTableCell>To Date</StyledTableCell>
                                                            <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                            {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                <StyledTableCell>Rejection Note</StyledTableCell>
                                                            )}
                                                        </material.TableRow>
                                                    </material.TableHead>
                                                    <material.TableBody>
                                                        {communicationOfCriticalConcernsTableData.length ? communicationOfCriticalConcernsTableData.map((item, k) => (
                                                            <material.TableRow key={k}
                                                                sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true) ?
                                                                    () => editCommunicationOfCriticalConcerns(item) : null}
                                                            >
                                                                <material.TableCell>{item.total_number_of_critical_concerns}</material.TableCell>
                                                                <material.TableCell className='text-break' size='small' style={{ width: "15rem" }}>{item.nature_of_critical_concern}</material.TableCell>
                                                                <material.TableCell>{item.communicated_to_highest_governance_body}</material.TableCell>
                                                                <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                                    {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                        <material.Checkbox {...label} disabled={!applicable || item.send_for_approval} onChange={() => handleClickCheckbox(item.ref_no)} color="success" />
                                                                    ) : (
                                                                        <material.Checkbox {...label} disabled={!applicable || item.approved_status === "APPROVED"} onChange={() => handleClickCheckbox(item.ref_no)} color="success" />
                                                                    )}
                                                                </material.TableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <material.TableCell>{(item.rejection_note === null) || (item.rejection_note === "") ? "No" : (
                                                                        <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                    )}
                                                                    </material.TableCell>
                                                                )}
                                                            </material.TableRow>
                                                        )) : (
                                                            <material.TableRow>
                                                                <material.TableCell colSpan={12}>
                                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )}
                                                    </material.TableBody>
                                                </material.Table>
                                            </material.TableContainer>
                                            <Pagination
                                                getPaginationData={getPaginationData}
                                                totalElements={totalElements}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                getFilterDate={getFilterDate}
                                            />
                                        </div>
                                    </div>
                                </material.Paper>
                            </material.TabPanel>
                            <material.TabPanel value="Communication of Critical Concerns History">
                                <div className="row">
                                    <div className="col-7">
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        )}
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                                    <div className='row'>
                                        <div className="col-12">
                                            <material.TableContainer >
                                                <material.Table>
                                                    <material.TableHead>
                                                        <material.TableRow >
                                                            <StyledTableCell>Total Number of Critical Concerns</StyledTableCell>
                                                            <StyledTableCell>Nature of Critical Concern</StyledTableCell>
                                                            <StyledTableCell>Total Number of Critical Concerns Communicated to Highest Governance Body </StyledTableCell>
                                                            <StyledTableCell>From Date</StyledTableCell>
                                                            <StyledTableCell>To Date</StyledTableCell>
                                                            <StyledTableCell>Approval Date</StyledTableCell>
                                                            <StyledTableCell>Status</StyledTableCell>
                                                        </material.TableRow>
                                                    </material.TableHead>
                                                    <material.TableBody>
                                                        {communicationOfCriticalConcernsTableData?.length ? communicationOfCriticalConcernsTableData.map((item, i) => (
                                                            <material.TableRow key={i}>
                                                                <material.TableCell>{item.total_number_of_critical_concerns}</material.TableCell>
                                                                <material.TableCell className='text-break' size='small' style={{ width: "15rem" }}>{item.nature_of_critical_concern}</material.TableCell>
                                                                <material.TableCell>{item.communicated_to_highest_governance_body}</material.TableCell>
                                                                <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell>
                                                                    {item.approved_status === "APPROVED" ? <span className="badge bg-success">
                                                                        APPROVED
                                                                    </span> : null}
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )) : (
                                                            <material.TableRow >
                                                                <material.TableCell colSpan={12}>
                                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )}
                                                    </material.TableBody>
                                                </material.Table>
                                            </material.TableContainer>
                                            <Pagination
                                                getPaginationData={getPaginationData}
                                                totalElements={totalElements}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                getFilterDate={getFilterDate}
                                            />
                                        </div>
                                    </div>
                                </material.Paper>
                            </material.TabPanel>
                            <material.TabPanel value="Specific Disclosure">
                                <SpecificDisclosure
                                    treeViewData={treeViewData} />
                            </material.TabPanel>
                            <material.TabPanel value="Specific Disclosure History">
                                <SpecificDisclosureHistory
                                    treeViewData={treeViewData} />
                            </material.TabPanel>
                        </material.TabContext>
                    </material.Box>
                </material.Box>
            </material.Box>
            <AddCommunicationOfCriticalConcerns
                openAddCommunicationOfCriticalConcerns={openAddCommunicationOfCriticalConcerns}
                setOpenAddCommunicationOfCriticalConcerns={setOpenAddCommunicationOfCriticalConcerns}
                getAllCommunicationOfCriticalConcernsData={getAllCommunicationOfCriticalConcernsData}
                treeViewData={treeViewData}
                page={page}
                rowsPerPage={rowsPerPage}
                getFilterDate={getFilterDate}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <ApplicableDialog
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getApplicableData={getApplicableData}
                applicable={applicable}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default CommunicationOfCriticalConcerns;