import React, { useState } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';
import ResetPasswordUserList from './ResetPasswordUserList';
import { verifyOtpByUserName } from '../services/UsermanagementService';
import Snackbar from '../toaster-message/Snackbar';

function VerifyOtpDialog(props) {

    const { openVerifyOtpDialog, setOpenVerifyOtpDialog } = props;
    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [openResetPasswordUserListDialog, setOpenResetPasswordUserListDialog] = useState({ action: false, verifyOtp: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const verifyOtp = async (data) => {
        await verifyOtpByUserName(openVerifyOtpDialog.userName, data.otp)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenVerifyOtpDialog({ action: false, userName: null });
                setOpenResetPasswordUserListDialog({ action: true, verifyOtp: data.otp });
                reset({otp:""})
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                })
            })
    };

    const close = () => {
        setOpenVerifyOtpDialog({ action: false, userName: null });
        reset({otp:""})
    };

    return (
        <div>
            <material.Dialog open={openVerifyOtpDialog.action} maxWidth="sm" fullWidth>
                <material.DialogTitle className='fw-bold'>OTP Verify
                    <span className='float-end'>
                        <material.IconButton color='error' title='Close' onClick={close}>
                            <material.CloseIcon />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div>
                            <material.TextField
                                error={errors.otp?.type === "required"}
                                {...register("otp", { required: true, minLength: 6, maxLength: 6 })}
                                required
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Enter OTP"
                                type="number"
                                fullWidth
                                variant="standard"
                            />
                            {(errors?.otp?.type === "minLength" || errors?.otp?.type === "maxLength") && (
                                <p className='text-danger'>Enter 6 digit otp</p>
                            )}
                        </div>
                        <span className='d-flex justify-content-center mt-3'>
                            <material.Button onClick={handleSubmit(verifyOtp)} color='success' variant="outlined" sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} disabled={!isValid}>Verify-OTP</material.Button>
                        </span>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <ResetPasswordUserList
                openResetPasswordUserListDialog={openResetPasswordUserListDialog}
                setOpenResetPasswordUserListDialog={setOpenResetPasswordUserListDialog}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default VerifyOtpDialog;