import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import moment from 'moment';
import { StyledTableCell } from "../../../shared/TableHeaderStyle";
import AddDifferentlyAbledEmployeesWorkers from '../../../sub-issues-dialogs/differently-abled-employees-workers-subissue/AddDifferentlyAbledEmployeesWorkers';
import { approveDifferentlyAbledEmployeesWorkersRecord, getAllDifferentlyAbledEmployeesWorkersData, sendForApprovalDifferentlyAbledEmployeesWorkersRecord } from '../../../services/sub-issue-services/DifferentlyAbledEmployeesWorkersService';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import Snackbar from '../../../toaster-message/Snackbar';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import EmployeeAgeBasedDataDialog from '../../../sub-issues-dialogs/employee/EmployeeAgeBasedDataDialog';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';
import DatePeriod from '../../../shared/DatePeriod';
import { getSubIssueRecordData, saveSubIssueData, updateSubIssueData } from '../../../services/sub-issue-services/AddSubissueService';
import Pagination from '../../../shared/pagination/Pagination';
import ApplicableDialog from '../../../dialogs/ApplicableDialog';


var recordRefNo = [];
var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };

function DifferentlyAbledEmployeesAndWorkers(props) {
    const location = useLocation();
    const treeViewData = location?.state;
    const [differentlyAbledEmployeesWorkers, setDifferentlyAbledEmployeesWorkers] = useState("Differently Abled Employees & Workers");
    const [isEmployeeAgeBasedDataPresent, setIsEmployeeAgeBasedDataPresent] = useState("No");
    const [openAddDifferentlyAbledEmployeesWorkers, setOpenAddDifferentlyAbledEmployeesWorkers] = useState({ action: false, differentlyAbledEmployeesWorkersData: null });
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [differentlyAbledEmployeesWorkersData, setDifferentlyAbledEmployeesWorkersData] = useState([]);
    const [openEmployeeAgeBasedDataTable, setOpenEmployeeAgeBasedDataTable] = useState({ action: false, employeesAgeBasedData: null, collForm: "DIFFERENTLY_ABLED_EMPLOYEE_WORKERS" });
    const [accordionIsDisable, setAccordionIsDisable] = useState(null);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicable, setApplicable] = useState(null);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [getFilterDate, setGetFilterDate] = useState(null);

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        getDifferentlyAbledEmployeesWorkersData()
    }, [differentlyAbledEmployeesWorkers]);

    const differentlyAbledEmployeesWorkersTabs = (event, newValue) => {
        setDifferentlyAbledEmployeesWorkers(newValue)
    };

    const getFormDateToDate = (data) => {
        setGetFilterDate(data);
        getDifferentlyAbledEmployeesWorkersData(data, paginationData);
    };

    const getDifferentlyAbledEmployeesWorkersData = async (filterDate, paginationValue) => {
        if (differentlyAbledEmployeesWorkers === "Differently Abled Employees & Workers") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let typeOfRecord = "DIFFERENTLY_ABLED_EMPLOYEES_WORKERS"
            let object = {
                subissueName: treeViewData.subIssue,
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                forApproval,
                approveHistory,
                typeOfRecord,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            getSubIssueRecordData(object)
                .then((resp) => {
                    setDifferentlyAbledEmployeesWorkersData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setApplicableRefNo(resp.data.applicableRecord?.ref_no ? resp.data.applicableRecord.ref_no : null);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                });
        } else if (differentlyAbledEmployeesWorkers === "Differently Abled Employees & Workers History") {
            let typeOfRecord = "DIFFERENTLY_ABLED_EMPLOYEES_WORKERS"
            let forApproval = true;
            let approveHistory = true;
            let object = {
                subissueName: treeViewData.subIssue,
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                forApproval,
                approveHistory,
                typeOfRecord,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            await getSubIssueRecordData(object)
                .then((resp) => {
                    setApplicable(resp.data.applicable)
                    setDifferentlyAbledEmployeesWorkersData(resp.data.records.content);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const addDifferentlyAbledEmployeesWorkers = () => {
        setOpenAddDifferentlyAbledEmployeesWorkers({ action: true, differentlyAbledEmployeesWorkersData: null });
    };

    const editDifferentlyAbledEmployeesWorkers = (rowData) => {
        setOpenAddDifferentlyAbledEmployeesWorkers({ action: true, differentlyAbledEmployeesWorkersData: rowData });
    };

    const clickCheckBox = (value) => {
        setOpenAddDifferentlyAbledEmployeesWorkers({ action: false, differentlyAbledEmployeesWorkersData: null });
        if (recordRefNo.includes(value)) {
            // recordRefNo.pop(value);
            recordRefNo = recordRefNo.filter(item => item !== value);
        }
        else {
            recordRefNo.push(value);
        }
    };

    const sendForApprovalRecords = async () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true
            }
            updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getDifferentlyAbledEmployeesWorkersData(getFilterDate, paginationData);
                    recordRefNo = [];
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const approveRecords = async () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true,
                "approved_by": userDetails.username,
                "approved_date": moment().format("YYYY-MM-DD"),
                "approved_status": "APPROVED",
                "rejection_note": "",
            }
            updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getDifferentlyAbledEmployeesWorkersData(getFilterDate, paginationData);
                    recordRefNo = [];
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const rejectRecords = async () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        }
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNo
        }
        await approveDifferentlyAbledEmployeesWorkersRecord(obj)
            .then((resp) => {
                recordRefNo = [];
                getDifferentlyAbledEmployeesWorkersData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };


    const employeeAgeBasedData = (rowData) => {
        setOpenEmployeeAgeBasedDataTable({ action: true, employeesAgeBasedData: rowData, collForm: "DIFFERENTLY_ABLED_EMPLOYEE_WORKERS" });
        setOpenAddDifferentlyAbledEmployeesWorkers({ action: false, differentlyAbledEmployeesWorkersData: null });
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };

    const getApplicableData = (data) => {
        if (applicableRefNo === null) {
            let subIssueName = treeViewData?.subIssue;
            const payload = {
                applicable: false,
                applicable_record: true,
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
            }
            saveSubIssueData(payload, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getDifferentlyAbledEmployeesWorkersData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else {
            let subIssueName = treeViewData?.subIssue;
            let refNo = applicableRefNo;
            const payload = {
                applicable: applicable ? false : true,
            }
            updateSubIssueData(payload, refNo, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getDifferentlyAbledEmployeesWorkersData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    const getPaginationData = (filterData, paginationData) => {
        setRowsPerPage(paginationData.size);
        setPage(paginationData.page);
        getDifferentlyAbledEmployeesWorkersData(filterData, paginationData);
    };

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className='row'>
                        <div className='col-6'>
                            <span><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>

                        </div>
                        <div className='col-6'>
                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") || userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? null : (
                                <span className="float-end">
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} onChange={applicableNotApplicable}
                                            checked={applicable}
                                            color="secondary" />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>
                    <div>
                        <material.Box sx={{ width: '100%', typography: 'body1' }}>
                            <material.TabContext
                                value={differentlyAbledEmployeesWorkers}
                            >
                                <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <material.TabList
                                        onChange={differentlyAbledEmployeesWorkersTabs}
                                        aria-label="lab API tabs example">
                                        <material.Tab className='text-capitalize' label="Differently Abled Employees & Workers" value="Differently Abled Employees & Workers" />
                                        <material.Tab className='text-capitalize' label="Differently Abled Employees & Workers History" value="Differently Abled Employees & Workers History" />
                                    </material.TabList>
                                </material.Box>
                                <material.TabPanel value="Differently Abled Employees & Workers">
                                    <div className="row mt-2 align-items-center">
                                        <div className="col-lg-6">
                                            {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                <DateFilter
                                                    getFormDateToDate={getFormDateToDate}
                                                />
                                            )}
                                        </div>
                                        <div className="col-lg-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                            <span className='float-end'>
                                                {differentlyAbledEmployeesWorkersData?.length ? (
                                                    <>
                                                        <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} color='success' disabled={!applicable || !recordRefNo.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approveRecords}>Approve</material.Button>
                                                        <material.Button sx={{ textTransform: "none" }} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} disabled={!applicable || !recordRefNo.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectRecords}>Reject</material.Button>
                                                    </>
                                                ) : null}
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendForApprovalRecords}>Send for Approval</material.Button>
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addDifferentlyAbledEmployeesWorkers}>Add Differently Employee And Worker</material.Button>
                                            </span>
                                        </div>
                                    </div>

                                    <div className='mt-3'>
                                        <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                            <div className='row'>
                                                <div className="col-12">
                                                    <material.TableContainer>
                                                        <material.Table>
                                                            <material.TableHead>
                                                                <material.TableRow>
                                                                    <StyledTableCell>Category of Worker</StyledTableCell>
                                                                    <StyledTableCell>Male</StyledTableCell>
                                                                    <StyledTableCell>Female</StyledTableCell>
                                                                    <StyledTableCell>Other</StyledTableCell>
                                                                    <StyledTableCell>Total Derived</StyledTableCell>
                                                                    <StyledTableCell>From Date</StyledTableCell>
                                                                    <StyledTableCell>To Date</StyledTableCell>
                                                                    {/* {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <StyledTableCell>Approval Date</StyledTableCell>
                                                                    )} */}
                                                                    <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                                    <StyledTableCell>Age Based Data</StyledTableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <StyledTableCell>Rejection Note</StyledTableCell>
                                                                    )}
                                                                </material.TableRow>
                                                            </material.TableHead>
                                                            <material.TableBody>
                                                                {differentlyAbledEmployeesWorkersData.length ? differentlyAbledEmployeesWorkersData.map((rowData, k) => (
                                                                    <material.TableRow key={k}
                                                                        sx={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") && (rowData.send_for_approval === null || rowData.send_for_approval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                        onClick={((userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")) && (rowData.send_for_approval === null || rowData.send_for_approval === false) && applicable === true) ?
                                                                            () => editDifferentlyAbledEmployeesWorkers(rowData) : null}
                                                                    >
                                                                        <material.TableCell>{rowData.category_of_worker}</material.TableCell>
                                                                        <material.TableCell>{rowData.male}</material.TableCell>
                                                                        <material.TableCell>{rowData.female}</material.TableCell>
                                                                        <material.TableCell>{rowData.other}</material.TableCell>
                                                                        <material.TableCell>{rowData.total}</material.TableCell>
                                                                        <material.TableCell>{rowData.from_date ? moment(rowData.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                        <material.TableCell>{rowData.to_date ? moment(rowData.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                        {/* {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                            <material.TableCell>{rowData.approved_date ? moment(rowData.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                        )} */}
                                                                        <material.TableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                                                            {userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") ? (
                                                                                <material.Checkbox {...label} disabled={!applicable || rowData.send_for_approval} onChange={() => clickCheckBox(rowData.ref_no)} color="success" />
                                                                            ) : (
                                                                                <material.Checkbox {...label} disabled={!applicable || rowData.approved_status === "APPROVED"} onChange={() => clickCheckBox(rowData.ref_no)} color="success" />
                                                                            )}
                                                                        </material.TableCell>
                                                                        <material.TableCell>
                                                                            {rowData.is_age_based_data_present === true ? (
                                                                                <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); employeeAgeBasedData({ ...rowData, history: false }) }}>Yes</material.Link>
                                                                            ) : null}
                                                                        </material.TableCell>
                                                                        {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                            <material.TableCell>{(rowData.rejection_note === null) || (rowData.rejection_note === "") ? "No" : (
                                                                                <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(rowData) }}>Yes</material.Link>
                                                                            )}
                                                                            </material.TableCell>
                                                                        )}
                                                                    </material.TableRow>
                                                                )) : (
                                                                    <material.TableRow>
                                                                        <material.TableCell colSpan={12}>
                                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                        </material.TableCell>
                                                                    </material.TableRow>
                                                                )}
                                                            </material.TableBody>
                                                        </material.Table>
                                                    </material.TableContainer>
                                                    <Pagination
                                                        getPaginationData={getPaginationData}
                                                        totalElements={totalElements}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        getFilterDate={getFilterDate}
                                                    />
                                                </div>
                                            </div>
                                        </material.Paper>
                                    </div>
                                </material.TabPanel>
                                <material.TabPanel value="Differently Abled Employees & Workers History">
                                    <div className="col-lg-8">
                                        <DateFilter
                                            getFormDateToDate={getFormDateToDate}
                                        />
                                    </div>
                                    <div>
                                        <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                            <div className='row'>
                                                <div className="col-12">
                                                    <material.TableContainer>
                                                        <material.Table>
                                                            <material.TableHead>
                                                                <material.TableRow>
                                                                    <StyledTableCell>Category of Worker</StyledTableCell>
                                                                    <StyledTableCell>Male</StyledTableCell>
                                                                    <StyledTableCell>Female</StyledTableCell>
                                                                    <StyledTableCell>Other</StyledTableCell>
                                                                    <StyledTableCell>Total Derived</StyledTableCell>
                                                                    <StyledTableCell>From Date</StyledTableCell>
                                                                    <StyledTableCell>To Date</StyledTableCell>
                                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                                    <StyledTableCell>Approved Status</StyledTableCell>
                                                                    <StyledTableCell>Age Based Data</StyledTableCell>
                                                                </material.TableRow>
                                                            </material.TableHead>
                                                            <material.TableBody>
                                                                {differentlyAbledEmployeesWorkersData?.length ? differentlyAbledEmployeesWorkersData?.map((rowData, ind) => (
                                                                    <material.TableRow key={ind} >
                                                                        <material.TableCell>{rowData.category_of_worker}</material.TableCell>
                                                                        <material.TableCell>{rowData.male}</material.TableCell>
                                                                        <material.TableCell>{rowData.female}</material.TableCell>
                                                                        <material.TableCell>{rowData.other}</material.TableCell>
                                                                        <material.TableCell>{rowData.total}</material.TableCell>
                                                                        <material.TableCell>{rowData.from_date ? moment(rowData.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                        <material.TableCell>{rowData.to_date ? moment(rowData.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                        <material.TableCell>{rowData.approved_date ? moment(rowData.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                        <material.TableCell>
                                                                            {rowData.approved_status === "APPROVED" ? <span className="badge bg-success">
                                                                                APPROVED
                                                                            </span> : null}
                                                                        </material.TableCell>
                                                                        <material.TableCell>
                                                                            {rowData.is_age_based_data_present === true ? (
                                                                                <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); employeeAgeBasedData({ ...rowData, history: true }) }}>Yes</material.Link>
                                                                            ) : null}
                                                                        </material.TableCell>
                                                                    </material.TableRow>
                                                                )) : (
                                                                    <material.TableRow >
                                                                        <material.TableCell colSpan={12}>
                                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                        </material.TableCell>
                                                                    </material.TableRow>
                                                                )}
                                                            </material.TableBody>
                                                        </material.Table>
                                                    </material.TableContainer>
                                                    <Pagination
                                                        getPaginationData={getPaginationData}
                                                        totalElements={totalElements}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        getFilterDate={getFilterDate}
                                                    />
                                                </div>
                                            </div>
                                        </material.Paper>
                                    </div>
                                </material.TabPanel>
                            </material.TabContext>
                        </material.Box>
                    </div>
                </material.Box>
            </material.Box>
            <AddDifferentlyAbledEmployeesWorkers
                openAddDifferentlyAbledEmployeesWorkers={openAddDifferentlyAbledEmployeesWorkers}
                setOpenAddDifferentlyAbledEmployeesWorkers={setOpenAddDifferentlyAbledEmployeesWorkers}
                getDifferentlyAbledEmployeesWorkersData={getDifferentlyAbledEmployeesWorkersData}
                treeViewData={treeViewData}
                page={page}
                rowsPerPage={rowsPerPage}
                getFilterDate={getFilterDate}
            />
            <ApplicableDialog
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getApplicableData={getApplicableData}
                applicable={applicable}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <EmployeeAgeBasedDataDialog
                openEmployeeAgeBasedDataTable={openEmployeeAgeBasedDataTable}
                setOpenEmployeeAgeBasedDataTable={setOpenEmployeeAgeBasedDataTable}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default DifferentlyAbledEmployeesAndWorkers;