import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RejectionNote from '../../../dialogs/RejectionNote';
import Snackbar from '../../../toaster-message/Snackbar';
import { strategyManagementAllQuestion } from '../../../shared/StrategyManagementQuestion';
import { approveRecordStrategyManagement, getStrategyManagementData, saveStrategyManagementProcess, sendForApprovalStrategyManagement } from '../../../services/sub-issue-services/StrategyManagementServices';
import { generateFromDateAndToDate } from '../../../shared/TimePeriod';
import moment from 'moment';
import ApplicableDialog from '../../../dialogs/ApplicableDialog';
import SpecificDisclosureHistory from "../../specific-disclosure/SpecificDisclosureHistory";
import SpecificDisclosure from "../../specific-disclosure/SpecificDisclosure";
import { useForm } from 'react-hook-form';
import DatePeriod from '../../../shared/DatePeriod';


const label = { inputProps: { 'aria-label': 'Color switch demo' } };
var usersRole;

function StrategyManagement(props) {

    const location = useLocation();
    const treeViewData = location.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [strategyManagementTableData, setStrategyManagementTableData] = useState([]);
    const [strategyManagementTab, setStrategyManagementTab] = useState('ESG Strategy Management');
    const [applicable, setApplicable] = useState(true);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openSnackBar, setOpenSnackBar] = useState({ "action": false, "type": "", "message": "", });
    const { register, handleSubmit, control, watch, reset, resetField, setValue, formState: { errors, isValid } } = useForm({ mode: "onTouched" });

    const [questions, setQuestions] = useState([]);
    const [recordReferenceNo, setRecordReferenceNo] = useState([]);
    const [rejectionNoteData, setRejectionNoteData] = useState([]);
    const [sendForApproval, setSendForApproval] = useState(false);
    const [strategyData, setStrategyData] = useState([]);
    const [fromDateToDate, setFromDateToDate] = useState({});

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    useEffect(() => {
        getStrategyManagement()
    }, [strategyManagementTab]);

    useEffect(() => {
        const initialQuestionsState = strategyManagementAllQuestion.map(question => ({
            ...question,
            checkboxes: Array(9).fill(false)
        }));
        setQuestions(initialQuestionsState);
    }, [strategyManagementAllQuestion]);

    const handleChange = (event, newValue) => {
        setStrategyManagementTab(newValue);
    };

    const getFormDateToDate = (data) => {
        getStrategyManagement(data)
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true)
    };

    const getStrategyManagement = async (filterDate) => {
        if (strategyManagementTab === "ESG Strategy Management") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = usersRole === "ROLE_FACILITY_APPROVER" ? false : null;;
            let object = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                esgDataPersonal: usersRole === "ROLE_FACILITY_APPROVER" ? false : true,
            }
            await getStrategyManagementData(object)
                .then((resp) => {
                    setApplicable(resp?.data?.applicable);
                    setApplicableRefNo(resp?.data?.applicableRefNo);
                    const unapprovedData = resp?.data?.disclosures?.filter(data => data.approvedStatus !== "APPROVED");
                    if (resp.data !== "") {
                        setStrategyData(resp?.data?.disclosures);
                    } else {
                        setStrategyData([]);
                    }
                    if (resp?.data?.disclosures?.length > 0) {
                        setSendForApproval(unapprovedData[0].sendForApproval);
                    } else {
                        setSendForApproval(false)
                    }

                    if (resp?.data?.disclosures?.length) {
                        setFromDateToDate({
                            fromDate: unapprovedData[0].fromDate,
                            toDate: unapprovedData[0].toDate,
                        })
                        setQuestions(
                            unapprovedData[0].question.map(question => ({
                                questionId: question.questionId,
                                question: question.question,
                                checkboxes: [
                                    question.answer.principle1,
                                    question.answer.principle2,
                                    question.answer.principle3,
                                    question.answer.principle4,
                                    question.answer.principle5,
                                    question.answer.principle6,
                                    question.answer.principle7,
                                    question.answer.principle8,
                                    question.answer.principle9,
                                ]
                            })).sort((a, b) => a.questionId - b.questionId));
                        setRejectionNoteData(unapprovedData);
                        setRecordReferenceNo([unapprovedData[0].refNo]);
                    } else {
                        resetCheckboxes();
                        if (usersRole === "ROLE_FACILITY_APPROVER") {
                            setQuestions([]);
                        }
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    };
                })
        } else if (strategyManagementTab === "ESG Strategy Management History") {
            let forApproval = true;
            let approveHistory = true;
            let object = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                esgDataPersonal: false
            }
            await getStrategyManagementData(object)
                .then((resp) => {
                    setApplicable(resp.data.applicable);
                    if (resp?.data?.disclosures?.length) {
                        const approvedData = resp?.data?.disclosures?.map(data => ({
                            questions: data.question.map(question => ({
                                questionId: question.questionId,
                                question: question.question,
                                checkboxes: [
                                    question.answer.principle1,
                                    question.answer.principle2,
                                    question.answer.principle3,
                                    question.answer.principle4,
                                    question.answer.principle5,
                                    question.answer.principle6,
                                    question.answer.principle7,
                                    question.answer.principle8,
                                    question.answer.principle9,
                                ]
                            })).sort((a, b) => a.questionId - b.questionId),
                            refNo: data.refNo,
                            approvedDate: data.approvedDate,
                            fromDate: data.fromDate,
                            toDate: data.toDate,
                        }));

                        if (approvedData.length > 0) {
                            setQuestions(approvedData[0].questions);
                            setRecordReferenceNo([approvedData[0].refNo]);
                        }
                        setStrategyManagementTableData(approvedData);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    };
                })
        }
    };

    const sendApproval = async () => {
        if (recordReferenceNo.length) {
            sendForApprovalStrategyManagement(recordReferenceNo)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getStrategyManagement();
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    };
                })
        }
    };

    const approved = async () => {
        if (recordReferenceNo.length) {
            let obj = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordReferenceNo,
                "typeOfRecord": ""
            }
            approveRecordStrategyManagement(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getStrategyManagement();
                    resetCheckboxes();
                    if (usersRole === "ROLE_FACILITY_APPROVER") {
                        setQuestions([]);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    };
                })
        };
    };

    const rejectRecord = async () => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: null });
    };

    const viewRejectionNote = (rejectData) => {
        if (rejectionNoteData.length) {
            if (rejectionNoteData[0].rejectionNote !== "") {
                setOpenRejectionNoteDialog({ action: true, rejectionData: { rejectionNote: rejectData[0].rejectionNote } });
            }
        }
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordReferenceNo
        }
        await approveRecordStrategyManagement(obj)
            .then((resp) => {
                getStrategyManagement();
                resetCheckboxes();
                if (usersRole === "ROLE_FACILITY_APPROVER") {
                    setQuestions([]);
                }
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const handleChangeCheckedHistory = (qIndex, pIndex) => (event) => {
        const { checked } = event.target;
        setQuestions(prevQuestions => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[qIndex].checkboxes[pIndex] = checked;
            return updatedQuestions;
        });
    };

    const saveCheckboxState = (data) => {
        const question = questions.map((question) => ({
            questionId: question.questionId,
            question: question.question,
            answer: {
                principle1: question.checkboxes[0],
                principle2: question.checkboxes[1],
                principle3: question.checkboxes[2],
                principle4: question.checkboxes[3],
                principle5: question.checkboxes[4],
                principle6: question.checkboxes[5],
                principle7: question.checkboxes[6],
                principle8: question.checkboxes[7],
                principle9: question.checkboxes[8],
            },
        }));

        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            const jsonData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                question,
                applicable: true,
                // refNo: approvalStatus !== "APPROVED" && recordReferenceNo.length > 0 ? recordReferenceNo[0] : "",
                refNo: "",
                rejectionNote: "",
                fromDate: payLoad?.fromDate,
                toDate: payLoad?.toDate,
            };
            saveStrategyManagementProcess(jsonData)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    });
                    resetCheckboxes();
                    getStrategyManagement();
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        });
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        });
                    }
                });
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateCheckboxState = () => {
        const question = questions.map((question) => ({
            questionId: question.questionId,
            question: question.question,
            answer: {
                principle1: question.checkboxes[0],
                principle2: question.checkboxes[1],
                principle3: question.checkboxes[2],
                principle4: question.checkboxes[3],
                principle5: question.checkboxes[4],
                principle6: question.checkboxes[5],
                principle7: question.checkboxes[6],
                principle8: question.checkboxes[7],
                principle9: question.checkboxes[8],
            },
        }));
        const jsonData = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: userDetails.facilityRefNo,
            question,
            applicable: true,
            refNo: recordReferenceNo[0],
            rejectionNote: "",
        };
        saveStrategyManagementProcess(jsonData)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                });
                resetCheckboxes();
                getStrategyManagement();
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    });
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    });
                }
            });
    };

    const resetCheckboxes = () => {
        setQuestions(prevQuestions => prevQuestions?.map(question => ({
            ...question,
            checkboxes: Array(9).fill(false)
        })));
    };

    const getApplicableData = () => {
        if (applicableRefNo === null) {
            const payload = {
                applicable: false,
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "question": [
                    {
                        "questionId": "",
                        "question": "",
                        "answer": {
                            "principle1": true,
                            "principle2": true,
                            "principle3": true,
                            "principle4": true,
                            "principle5": true,
                            "principle6": true,
                            "principle7": true,
                            "principle8": true,
                            "principle9": true
                        }
                    }
                ]
            }
            saveStrategyManagementProcess(payload)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getStrategyManagement();
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else {
            const payload = {
                applicable: applicable ? false : true,
                refNo: applicableRefNo,
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "question": [
                    {
                        "questionId": "",
                        "question": "",
                        "answer": {
                            "principle1": true,
                            "principle2": true,
                            "principle3": true,
                            "principle4": true,
                            "principle5": true,
                            "principle6": true,
                            "principle7": true,
                            "principle8": true,
                            "principle9": true
                        }
                    }
                ]
            }
            saveStrategyManagementProcess(payload)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getStrategyManagement();
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7, width: 100 }}>
                    <div className='row'>
                        <div className='col-6'>
                            <span><material.Typography variant="h5" className="headingText">{treeViewData?.subIssue}</material.Typography></span></div>
                        <div className='col-6'>
                            <span className='float-end' hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}>
                                <material.Stack direction="row" spacing={1} alignItems="center">
                                    <material.Typography>Not applicable</material.Typography>
                                    <material.Switch {...label} onChange={applicableNotApplicable} checked={applicable} color="secondary" />
                                    <material.Typography>Applicable</material.Typography>
                                </material.Stack>
                            </span>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <material.Box sx={{ width: '100%', typography: 'body1' }}>
                            <material.TabContext value={strategyManagementTab}>
                                <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <material.TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <material.Tab className='text-capitalize' label="ESG Strategy Management" value="ESG Strategy Management" />
                                        <material.Tab className='text-capitalize' label="ESG Strategy Management History" value="ESG Strategy Management History" />
                                        <material.Tab className="text-capitalize" label="Specific Disclosure" value="Specific Disclosure" />
                                        <material.Tab className="text-capitalize" label="Specific Disclosure History" value="Specific Disclosure History" />
                                    </material.TabList>
                                </material.Box>
                                <material.TabPanel value="ESG Strategy Management">
                                    <div className="row mt-2 align-items-center">
                                        <div className="col-lg-6">
                                            {/* {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                <DateFilter
                                                    getFormDateToDate={getFormDateToDate}
                                                />
                                            )} */}
                                        </div>
                                        <div className="col-lg-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                            <span className='float-end'>
                                                {questions.length ? (
                                                    <>
                                                        <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approved}>Approve</material.Button>
                                                        <material.Button sx={{ textTransform: "none" }} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} disabled={!applicable} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectRecord}>Reject</material.Button>
                                                    </>
                                                ) : null}
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.VisibilityIcon />} onClick={() => viewRejectionNote(rejectionNoteData)}>View Rejection Note</material.Button>
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none", }} startIcon={<material.SendIcon />} onClick={sendApproval}>Send for Approval</material.Button>
                                            </span>
                                        </div>
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        {strategyData?.length ? null : (
                                            <div hidden={usersRole === "ROLE_FACILITY_APPROVER"}>
                                                <DatePeriod
                                                    register={register}
                                                    control={control}
                                                />
                                            </div>
                                        )}
                                        {usersRole !== "ROLE_FACILITY_APPROVER" ?
                                            <div className='row mt-4'>
                                                <div className='col-3'>Question</div>
                                                {[...Array(9)].map((_, pIndex) => (
                                                    <div className='col-1' key={pIndex}>Principle {pIndex + 1}</div>
                                                ))}
                                            </div>
                                            :
                                            <div className='row'>
                                                <div className='col-10'>
                                                    <div className='row'>
                                                        <div className='col-3'>Question</div>
                                                        {[...Array(9)].map((_, pIndex) => (
                                                            <div className='col-1' key={pIndex}>
                                                                Principle {pIndex + 1}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className='col-1'><span className='col-1'>From Data</span></div>
                                                <div className='col-1'><span className='col-1'>To Data</span></div>
                                            </div>}

                                        {usersRole !== "ROLE_FACILITY_APPROVER" ?
                                            questions.map((question, qIndex) => (
                                                <div className='row mt-3' key={question.questionId}>
                                                    <div className='col-3'>{question.question}</div>
                                                    {question.checkboxes.map((isChecked, pIndex) => (
                                                        <div className='col-1' key={pIndex}>
                                                            <material.Checkbox
                                                                name={`q${question.questionId}_p${pIndex + 1}`}
                                                                checked={isChecked}
                                                                onChange={handleChangeCheckedHistory(qIndex, pIndex)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )) : strategyData.length ? (
                                                <div className='row'>
                                                    <div className="col-10">
                                                        {questions.map((question, qIndex) => (
                                                            <div className='row mt-3' key={question.questionId}>
                                                                <div className='col-3'>{question.question}</div>
                                                                {question.checkboxes.map((isChecked, pIndex) => (
                                                                    <div className='col-1' key={pIndex}>
                                                                        <material.Checkbox
                                                                            name={`q${question.questionId}_p${pIndex + 1}`}
                                                                            checked={isChecked}
                                                                            onChange={handleChangeCheckedHistory(qIndex, pIndex)}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="col-1" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <span >{fromDateToDate.fromDate ? moment(fromDateToDate.fromDate).format("DD-MM-YYYY") : ""}</span>
                                                    </div>
                                                    <div className="col-1" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <span>{fromDateToDate.toDate ? moment(fromDateToDate.toDate).format("DD-MM-YYYY") : ""}</span>
                                                    </div>

                                                </div>
                                            ) : (
                                                <div className="row mt-4">
                                                    <div className="col-12 mt-5">
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <material.Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
                                            {strategyData.length ? (
                                                <material.Button
                                                    color='success'
                                                    variant="outlined"
                                                    onClick={handleSubmit(updateCheckboxState)}
                                                    sx={{ textTransform: "none" }}
                                                    startIcon={<material.DoneIcon />}
                                                    hidden={usersRole === "ROLE_FACILITY_APPROVER"}
                                                    // disabled={!applicable || !(isDatePeriodFilled() || rejectionNoteData.length)}
                                                    disabled={(!applicable || sendForApproval)}
                                                >
                                                    Update
                                                </material.Button>
                                            ) : (
                                                <material.Button
                                                    color='success'
                                                    variant="outlined"
                                                    onClick={handleSubmit(saveCheckboxState)}
                                                    sx={{ textTransform: "none" }}
                                                    startIcon={<material.DoneIcon />}
                                                    hidden={usersRole === "ROLE_FACILITY_APPROVER"}
                                                    // disabled={!applicable || !(isDatePeriodFilled() || rejectionNoteData.length)}
                                                    disabled={(!applicable || sendForApproval)}
                                                >
                                                    Save
                                                </material.Button>
                                            )}

                                        </material.Box>
                                    </material.Paper>
                                </material.TabPanel>
                                <material.TabPanel value="ESG Strategy Management History">
                                    {/* <div className="row" hidden={usersRole === "ROLE_FACILITY_APPROVER"}>
                                        <div className="col-lg-8">
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        </div>
                                    </div> */}
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div>
                                            <div className='row'>
                                                <div className='col-9'>
                                                    <div className='row'>
                                                        <div className='col-3'>Question</div>
                                                        {[...Array(9)].map((_, pIndex) => (
                                                            <div className='col-1' key={pIndex}>
                                                                Principle {pIndex + 1}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className='col-1'><span className='col-1'>From Data</span></div>
                                                <div className='col-1'><span className='col-1'>To Data</span></div>
                                                <div className='col-1'><span className='col-1'>Approved Date</span></div>
                                            </div>

                                            {strategyManagementTableData?.length ? strategyManagementTableData.map((approvedData, index) => (
                                                <material.Card className='mt-4' key={index} sx={{ border: 1 }}>
                                                    <material.CardContent>
                                                        <div className="row">
                                                            <div className="col-9">
                                                                {approvedData.questions.map((question, qIndex) => (
                                                                    <div className='row' key={question.questionId}>
                                                                        <div className='col-3'>{question.question}</div>
                                                                        {question.checkboxes.map((isChecked, pIndex) => (
                                                                            <div className='col-1' key={pIndex}>
                                                                                <material.Checkbox
                                                                                    name={`q${question.questionId}_p${pIndex + 1}`}
                                                                                    checked={isChecked}
                                                                                    onChange={handleChangeCheckedHistory(qIndex, pIndex)}
                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                ))}
                                                            </div>

                                                            <div className="col-1" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <span>{approvedData.fromDate ? moment(approvedData.fromDate).format("DD-MM-YYYY") : ""}</span>
                                                            </div>
                                                            <div className="col-1" style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                                                <span>{approvedData.toDate ? moment(approvedData.toDate).format("DD-MM-YYYY") : ""}</span>
                                                            </div>
                                                            <div className="col-1" style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                                                <span>{approvedData.approvedDate ? moment(approvedData.approvedDate).format("DD-MM-YYYY") : ""}</span>
                                                            </div>
                                                        </div>

                                                    </material.CardContent>
                                                </material.Card>
                                            )) :
                                                (
                                                    <div className="row mt-4">
                                                        <div className="col-12 mt-5">
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                                <material.TabPanel value="Specific Disclosure">
                                    <SpecificDisclosure
                                        treeViewData={treeViewData}
                                    />
                                </material.TabPanel>
                                <material.TabPanel value="Specific Disclosure History">
                                    <SpecificDisclosureHistory
                                        treeViewData={treeViewData}
                                    />
                                </material.TabPanel>
                            </material.TabContext>
                        </material.Box>
                    </div>
                </material.Box>
            </material.Box>
            <ApplicableDialog
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getApplicableData={getApplicableData}
                applicable={applicable}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
                resetCheckboxes={resetCheckboxes}
                callFrom="strategy-management"
            />
        </div>
    );
}

export default StrategyManagement;
