import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddWorkRelatedIllHealth(props) {
  const { register, handleSubmit, reset, control, resetField, watch, getValues, setValue, formState: { errors, isValid }, } = useForm({ mode: "onTouched" });
  const userDetails = useSelector((state) => state.LoginSlice.data);
  const { openAddWorkRelatedIllHealth, setOpenAddWorkRelatedIllHealth, getWorkRelatedIllHealthData, treeViewData, page, rowsPerPage, getFilterDate } = props;
  const [workerRelatedIllHealth, setWorkerRelatedIllHealth] = useState("");
  const [category, setCategory] = useState("");
  const [source, setSource] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState({
    "action": false,
    "type": "",
    "message": "",
  });
  const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const payloadData = {
    page,
    size: rowsPerPage
  };

  const onChangeTotalNumber = watch('total_number');
  const onChangeRehabilitated = watch('rehabilitated_and_placed_in_suitable_employment');
  const onChangeFamilyMembers = watch('family_members_have_been_placed_in_suitable_employment');

  useEffect(() => {
    totalCalculation();
  }, [onChangeTotalNumber, onChangeRehabilitated, onChangeFamilyMembers]);

  const totalCalculation = () => {
    let totalNumberValue = getValues('total_number');
    let val1 = getValues('rehabilitated_and_placed_in_suitable_employment');
    let val2 = getValues('family_members_have_been_placed_in_suitable_employment');
    if ((Number(val1) + Number(val2)) > Number(totalNumberValue)) {
      setOpenSnackBar({
        "action": true,
        "type": "warning",
        "message": "Rehabilitation & Placement and Placement of Employee/Worker Family Member value should not be greater than Total Number",
      });
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    };
  };

  useEffect(() => {
    if (openAddWorkRelatedIllHealth.action && openAddWorkRelatedIllHealth.workRelatedIllHealthData) {
      reset(openAddWorkRelatedIllHealth.workRelatedIllHealthData)
      setWorkerRelatedIllHealth(openAddWorkRelatedIllHealth.workRelatedIllHealthData ? openAddWorkRelatedIllHealth.workRelatedIllHealthData.safety_incident : "")
      setCategory(openAddWorkRelatedIllHealth.workRelatedIllHealthData ? openAddWorkRelatedIllHealth.workRelatedIllHealthData.worker_category : "");
      setSource(openAddWorkRelatedIllHealth.workRelatedIllHealthData.ill_health_source);
    };
    setValue("total_number", 0);
    setValue("rehabilitated_and_placed_in_suitable_employment", 0);
    setValue("family_members_have_been_placed_in_suitable_employment", 0);
  }, [openAddWorkRelatedIllHealth.action === true]);

  const selectWorkRelatedIllHealth = (event) => {
    setWorkerRelatedIllHealth(event.target.value)
  };

  const selectCategory = (event) => {
    setCategory(event.target.value)
  };

  const handleClose = () => {
    setOpenAddWorkRelatedIllHealth({ action: false, workRelatedIllHealthData: null })
    reset({ total_number: "", lost_time: "", rehabilitated_and_placed_in_suitable_employment: "", family_members_have_been_placed_in_suitable_employment: "" });
    setWorkerRelatedIllHealth("");
    setCategory("");
    setSource("")
  };

  const saveWorkRelatedIllHealth = async (data) => {
    let payLoad = generateFromDateAndToDate(data);
    let newDate = moment().format("YYYY-MM-DD");
    let subissueName = (treeViewData?.subIssue)
    if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
      let obj = {
        "corporate_ref_no": userDetails.corporateRefNo,
        "facility_ref_no": userDetails.facilityRefNo,
        "safety_incident": data.safety_incident,
        "ill_health_source": data.ill_health_source,
        "worker_category": data.worker_category,
        "total_number": parseInt(data.total_number),
        "lost_time": data.lost_time,
        "rehabilitated_and_placed_in_suitable_employment": data.rehabilitated_and_placed_in_suitable_employment,
        "family_members_have_been_placed_in_suitable_employment": data.family_members_have_been_placed_in_suitable_employment,
        "applicable_record": false,
        "applicable": true,
        "from_date": payLoad?.fromDate,
        "to_date": payLoad?.toDate,
      }
      await saveSubIssueData(obj, subissueName)
        .then((resp) => {
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
          getWorkRelatedIllHealthData(getFilterDate, payloadData);
          reset({ total_number: "", lost_time: "", rehabilitated_and_placed_in_suitable_employment: "", family_members_have_been_placed_in_suitable_employment: "" });
          setWorkerRelatedIllHealth("");
          setCategory("");
          setSource("");
          setOpenAddWorkRelatedIllHealth({ action: false, workRelatedIllHealthData: null });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
            })
          };
        })
    } else {
      setOpenSnackBar({
        "action": true,
        "type": "warning",
        "message": "You can't add future records",
      })
    };
  };

  const updateWorkRelatedIllHealth = async (data) => {
    const refNo = openAddWorkRelatedIllHealth.workRelatedIllHealthData.ref_no;
    const subIssueName = treeViewData?.subIssue;
    let obj = {
      "safety_incident": data.safety_incident,
      "ill_health_source": data.ill_health_source,
      "worker_category": data.worker_category,
      "total_number": parseInt(data.total_number),
      "lost_time": data.lost_time,
      "rehabilitated_and_placed_in_suitable_employment": data.rehabilitated_and_placed_in_suitable_employment,
      "family_members_have_been_placed_in_suitable_employment": data.family_members_have_been_placed_in_suitable_employment,
    }
    await updateSubIssueData(obj, refNo, subIssueName)
      .then((resp) => {
        setOpenSnackBar({
          "action": true,
          "type": "success",
          "message": resp.data,
        })
        getWorkRelatedIllHealthData(getFilterDate, payloadData);
        reset({ total_number: "", lost_time: "", rehabilitated_and_placed_in_suitable_employment: "", family_members_have_been_placed_in_suitable_employment: "" });
        setWorkerRelatedIllHealth("");
        setCategory("");
        setSource("");
        setOpenAddWorkRelatedIllHealth({ action: false, workRelatedIllHealthData: null });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "You are not authorized to perform this action. Redirected to login",
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
          })
        };
      })
  };

  const selectSource = (event) => {
    setSource(event.target.value)
  };

  const deleteConfirmation = () => {
    setOpenDeleteRecordConfirmationDialog(true);
  };

  const deleteRecordByRefNo = () => {
    const payload = {
      refNo: openAddWorkRelatedIllHealth.workRelatedIllHealthData.ref_no,
      subissueName: treeViewData.subIssue,
    }
    commonDelete(payload)
      .then((resp) => {
        setOpenSnackBar({
          "action": true,
          "type": "success",
          "message": resp.data,
        })
        getWorkRelatedIllHealthData(getFilterDate, payloadData);
        reset({ total_number: "", lost_time: "", rehabilitated_and_placed_in_suitable_employment: "", family_members_have_been_placed_in_suitable_employment: "" });
        setWorkerRelatedIllHealth("");
        setCategory("");
        setSource("");
        setOpenDeleteRecordConfirmationDialog(false);
        setOpenAddWorkRelatedIllHealth({ action: false, workRelatedIllHealthData: null });
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "You are not authorized to perform this action. Redirected to login",
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
          })
        }
      })
  };

  return (
    <div>
      <div>
        <material.Dialog open={openAddWorkRelatedIllHealth.action} maxWidth="lg" fullWidth >
          <material.DialogTitle className='fw-bold headingText'>{openAddWorkRelatedIllHealth.workRelatedIllHealthData ? "Edit Work Related Ill Health" : "Add Work Related Ill Health"}
          </material.DialogTitle>
          <material.DialogContent>
            <material.DialogContentText id="alert-dialog-description">
              <div className='my-3'>
                {openAddWorkRelatedIllHealth.workRelatedIllHealthData ? null : (
                  <DatePeriod
                    register={register}
                    control={control}
                  />
                )}
              </div>
              <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
                <div className='row'>
                  <div className='py-1'>
                    <material.Typography className='fw-bold'>Work Related Ill Health</material.Typography>
                  </div>
                  <div className='col-4'>
                    {/* <div className='mt-3'> */}
                    <material.Box>
                      <material.FormControl variant='standard' required fullWidth>
                        <material.InputLabel id="demo-simple-select-label">Ill Health Related Incidents</material.InputLabel>
                        <material.Select
                          {...register("safety_incident", { required: true })}
                          labelId="demo-simple-select-label"
                          id="workRelatedIllHealth"
                          value={workerRelatedIllHealth}
                          onChange={selectWorkRelatedIllHealth}
                        >
                          <material.MenuItem value={"Fatalities as a Result of Work Related Ill Health"}>Fatalities as a Result of Work Related Ill Health</material.MenuItem>
                          <material.MenuItem value={"High Consequence Work Related Ill Health"}>High Consequence Work Related Ill Health</material.MenuItem>
                          <material.MenuItem value={"Recordable Work Related Ill Health"}>Recordable Work Related Ill Health</material.MenuItem>
                        </material.Select>
                      </material.FormControl>
                    </material.Box>
                  </div>
                  <div className='col-4'>
                    <material.FormControl variant="standard" required fullWidth>
                      <material.InputLabel id="demo-simple-select-label">Ill Health Source</material.InputLabel>
                      <material.Select
                        {...register("ill_health_source", { required: true })}
                        labelId="demo-simple-select-label"
                        variant='standard'
                        id="illHealthSource"
                        value={source}
                        label="Category of worker"
                        onChange={selectSource}
                      >
                        <material.MenuItem value={"Musculoskeletal Disorders"}>Musculoskeletal Disorders</material.MenuItem>
                        <material.MenuItem value={"Skin & Respiratory"}>Skin & Respiratory</material.MenuItem>
                        <material.MenuItem value={"Cancer"}>Cancer</material.MenuItem>
                        <material.MenuItem value={"Mental Illnesses"}>Mental Illnesses</material.MenuItem>
                        <material.MenuItem value={"Hearing Loss"}>Hearing Loss</material.MenuItem>
                      </material.Select>
                    </material.FormControl>
                  </div>
                  <div className='col-4'>
                    <material.FormControl variant="standard" required fullWidth>
                      <material.InputLabel id="demo-simple-select-label">Category</material.InputLabel>
                      <material.Select
                        {...register("worker_category", { required: true })}
                        labelId="demo-simple-select-label"
                        variant='standard'
                        id="workerCategory"
                        value={category}
                        label="Category"
                        onChange={selectCategory}
                      >
                        <material.MenuItem value={"Employee Permanent"}>Employee Permanent</material.MenuItem>
                        <material.MenuItem value={"Employee other than Permanent"}>Employee other than Permanent</material.MenuItem>
                        <material.MenuItem value={"Worker Permanent"}>Worker Permanent</material.MenuItem>
                        <material.MenuItem value={"Worker other than Permanent"}>Worker other than Permanent</material.MenuItem>
                      </material.Select>
                    </material.FormControl>
                  </div>
                  <div className='col-4 mt-2'>
                    <material.TextField
                      {...register("total_number", { required: true, pattern: /^[0-9]+$/ })}
                      required
                      margin="dense"
                      label="Number"
                      id="total_number"
                      variant="standard"
                      type="number"
                      size="small"
                      fullWidth
                    />
                    {errors.total_number && errors.total_number.type === "pattern" && (
                      <p className="errorMsg text-danger">No decimal allowed</p>
                    )}
                  </div>
                  <div className='col-4 mt-2'>
                    <material.TextField
                      {...register("lost_time", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                      required
                      margin="dense"
                      label="Lost Time (hr)"
                      id="lost_time"
                      variant="standard"
                      type="number"
                      size="small"
                      fullWidth
                    />
                    {errors.lost_time && errors.lost_time.type === "pattern" && (
                      <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                    )}
                  </div>
                  <div className='col-4 mt-2'>
                    <material.TextField
                      {...register("rehabilitated_and_placed_in_suitable_employment", { required: true, pattern: /^[0-9]+$/ })}
                      required
                      // margin="dense"
                      label="Rehabilitation & Placement of Employee/Worker"
                      id="rehabilitated_and_placed_in_suitable_employment"
                      variant="standard"
                      type="number"
                      size="small"
                      fullWidth
                    />
                    {errors.rehabilitated_and_placed_in_suitable_employment && errors.rehabilitated_and_placed_in_suitable_employment.type === "pattern" && (
                      <p className="errorMsg text-danger">No decimal allowed</p>
                    )}
                  </div>
                  <div className='col-4 mt-2'>
                    <material.TextField
                      {...register("family_members_have_been_placed_in_suitable_employment", { required: true, pattern: /^[0-9]+$/ })}
                      required
                      // margin="dense"
                      label="Placement of Employee/Worker Family Members"
                      id="family_members_have_been_placed_in_suitable_employment"
                      variant="standard"
                      type="number"
                      size="small"
                      fullWidth
                    />
                    {errors.family_members_have_been_placed_in_suitable_employment && errors.family_members_have_been_placed_in_suitable_employment.type === "pattern" && (
                      <p className="errorMsg text-danger">No decimal allowed</p>
                    )}
                  </div>
                  {/* <div className='col-4 mt-2'>
                    <material.TextField
                      {...register("total_number", { required: true, pattern: /^[0-9]+$/ })}
                      required
                      margin="dense"
                      label="Total Number"
                      id="total_number"
                      variant="standard"
                      type="number"
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ readOnly: true }}
                    />
                    {errors.total_number && errors.total_number.type === "pattern" && (
                      <p className="errorMsg text-danger">No decimal allowed</p>
                    )}
                  </div> */}
                </div>
              </material.Card>
            </material.DialogContentText>
          </material.DialogContent>
          <material.DialogActions className="m-3">
            <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
            {openAddWorkRelatedIllHealth.workRelatedIllHealthData ? (
              <>
                <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} disabled={buttonDisable} onClick={handleSubmit(updateWorkRelatedIllHealth)} startIcon={<material.DoneIcon />}>Update</material.Button>
                {(openAddWorkRelatedIllHealth.workRelatedIllHealthData.rejection_note === null) || (openAddWorkRelatedIllHealth.workRelatedIllHealthData.rejection_note === "") ? (
                  <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                ) : null}
              </>
            ) : (
              <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} disabled={buttonDisable} onClick={handleSubmit(saveWorkRelatedIllHealth)} startIcon={<material.DoneIcon />}>Save</material.Button>
            )}
          </material.DialogActions>
        </material.Dialog>
        <Snackbar
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
        />
      </div>
      <DeleteRecordConfirmationDialog
        openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
        setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
        treeViewData={treeViewData}
        deleteRecordByRefNo={deleteRecordByRefNo}
      />
    </div>
  )
}

export default AddWorkRelatedIllHealth;