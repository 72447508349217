import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import SwipeDrawer from '../drawer/SwipeDrawer';
import { getAllAssessmentAnswer, getAllAssessmentQuestionMapping, getAssessment, saveAssessmentAnswer } from '../../services/AssessmentService';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import BarsLoader from '../../shared/loader/BarsLoader';
import UploadAndViewAttachedDocument from './UploadAndViewAttachedDocument';

var isAnswered;

function EsgAssessmentModule(props) {

    const { control, register, reset, handleSubmit, formState: { errors, isValid }, setValue, watch } = useForm({
        mode: 'onTouched',
        defaultValues: {
            questionsId: [],
        },
    });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [assessmentQuestions, setAssessmentQuestions] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [assessmentData, setAssessmentData] = useState([]);
    const [getAnswerMapValue, setGetAnswerMapValue] = useState(null);
    const [assessmentName, setAssessmentName] = useState([]);
    const [filterAssessment, setFilterAssessment] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ansRefNo, setAnsRefNo] = useState(null);
    const [openUploadAndAttachedDocument, setOpenUploadAndViewAttachedDocument] = useState({ action: false, ansRefNo: null });
    const [buttonDisable, setButtonDisable] = useState(false);
    const [getAssessmentRefNo, setGetAssessmentRefNo] = useState(null);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [answeredQuestions, setAnsweredQuestions] = useState({});
    const [index, setIndex] = useState(null);

    useEffect(() => {
        const subscription = watch((value) => {
            const newAnsweredQuestions = {};
            assessmentQuestions.forEach((question) => {
                const questionRefNo = question.questionRefNo;
                newAnsweredQuestions[questionRefNo] = Boolean(value[questionRefNo] && value[questionRefNo].trim());
            });
            setAnsweredQuestions(newAnsweredQuestions);
        });

        return () => subscription.unsubscribe();
    }, [watch, assessmentQuestions]);

    useEffect(() => {
        getAssessmentByFacility();
    }, []);

    const getAssessmentByFacility = () => {
        getAssessment({ ...userDetails, filterApplicable: true })
            .then((resp) => {
                setAssessmentData(resp.data)
                let assessmentName = resp.data.length ? resp.data.map((ele) => ele.assessmentName) : [];
                setAssessmentName(assessmentName);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const selectAssessment = (e, newValue) => {
        if (newValue !== null) {
            let filterData = assessmentData.length ? assessmentData.filter((ele) => ele.assessmentName == newValue) : null;
            if (filterData !== null) {
                getAssessmentQuestionsAndAnswer(filterData[0].assessmentRefNo);
                setGetAssessmentRefNo(filterData[0].assessmentRefNo);
            }
            setFilterAssessment(filterData);
        } else {
            setAssessmentQuestions([]);
            setFilterAssessment(null);
        }
    };

    const getAssessmentQuestionsAndAnswer = (assessmentRefNo) => {
        setIsLoading(true);
        reset();
        const payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: userDetails.facilityRefNo,
            assessmentRefNo: assessmentRefNo ? assessmentRefNo : getAssessmentRefNo,
            submited: false
        };
        const questionPayload = {
            corporateRefNo: userDetails.corporateRefNo,
            assessmentRefNo: assessmentRefNo ? assessmentRefNo : getAssessmentRefNo,
        }
        Promise.all([getAllAssessmentQuestionMapping(questionPayload), getAllAssessmentAnswer(payload)])
            .then((resp) => {
                const questions = resp[0].data.questions;
                const allAnswers = resp[1].data.dtoList;
                const getDocumentPresent = allAnswers.map((ele) => ele.fileUploaded);
                const allTrue = getDocumentPresent.every(element => element === true);
                if (allAnswers.length) {
                    setAllowSubmit(((ans) => {
                        for (const element of ans) {
                            if (element.responseText.length == 0) {
                                return false
                            }
                        }
                        return true
                    })(allAnswers))
                    setButtonDisable(allTrue);
                    setIsSubmit(allAnswers[0].submit);
                    setAnsRefNo(allAnswers[0].refNo)
                } else {
                    setButtonDisable(false);
                    setIsSubmit(false);
                    setAnsRefNo(null);
                }
                const answersMap = new Map();
                allAnswers.forEach(answer => {
                    const questionId = answer.questionId;
                    answersMap.set(questionId, answer);
                });
                const questionsWithAnswers = questions.map(question => {
                    const questionId = question.questionRefNo;
                    const answer = answersMap.get(questionId);
                    question.answer = answer ? answer : null;
                    return question;
                });
                setGetAnswerMapValue(answersMap);
                setAssessmentQuestions(questionsWithAnswers);
                const newAnsweredQuestions = {};
                questionsWithAnswers.forEach((question) => {
                    const questionRefNo = question.questionRefNo;
                    const existingAnswer = question.answer && question.answer.responseText;
                    newAnsweredQuestions[questionRefNo] = Boolean(existingAnswer);
                });
                setAnsweredQuestions(newAnsweredQuestions);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const saveAnswers = (formData) => {
        for (const key in formData) {
            if ((Array.isArray(formData[key]) && formData[key].length === 0)) {
                delete formData[key];
            }
        }
        let questionAnsList = Object.entries(formData).map((ele) => {
            let answerData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                assessmentRefNo: filterAssessment ? filterAssessment[0].assessmentRefNo : "",
                questionId: ele[0],
                responseText: ele[1],
                refNo: getAnswerMapValue.get(ele[0])?.refNo,
                deleted: false,
                submit: false
            };
            return answerData;
        })
        saveAssessmentAnswer(questionAnsList)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset();
                getAssessmentQuestionsAndAnswer(filterAssessment[0].assessmentRefNo);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const submitAnswers = (formData) => {
        for (const key in formData) {
            if ((Array.isArray(formData[key]) && formData[key].length === 0)) {
                delete formData[key];
            }
        }
        let questionAnsList = Object.entries(formData).map((ele) => {
            let answerData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                assessmentRefNo: filterAssessment ? filterAssessment[0].assessmentRefNo : "",
                questionId: ele[0],
                responseText: ele[1],
                refNo: getAnswerMapValue.get(ele[0])?.refNo,
                deleted: false,
                submit: true
            };
            return answerData;
        })
        saveAssessmentAnswer(questionAnsList)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset();
                getAssessmentQuestionsAndAnswer(filterAssessment[0].assessmentRefNo);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const uploadAttachedDocument = (ansRefNo) => {
        setOpenUploadAndViewAttachedDocument({ action: true, ansRefNo: ansRefNo });
    };

    const getAnsData = (data, ind) => {
        // let value = assessmentQuestions.map((ele, i) =>
        //     console.log(i === ind)
        // )
        // console.log(answeredQuestions)
        setIndex(ind)
    }

    console.log(allowSubmit)

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7, width: "80%" }}>
                    <div className="row">
                        <div className="col-12">
                            <span>
                                <material.Typography variant='h5'>ESG Assessment Module</material.Typography>
                            </span>
                        </div>
                    </div>
                    <material.Paper elevation={3} sx={{ p: 5, mt: 3, pb: 7 }}>
                        <div className="row">
                            <div className="col-3">
                                <material.Autocomplete
                                    sx={{ mb: 2, mt: -3 }}
                                    margin="dense"
                                    id="sitesName"
                                    onChange={selectAssessment}
                                    options={assessmentName}
                                    renderInput={(params) => <material.TextField {...params} variant="standard" label="Assessment Name"
                                        fullWidth
                                        required
                                    />}
                                />
                            </div>
                            {filterAssessment ? (
                                <div className="col-12">
                                    <span>
                                        <material.Typography sx={{ fontWeight: "bold" }}>Assessment Name : {filterAssessment ? filterAssessment[0].assessmentName : ""}</material.Typography>
                                        <material.Typography sx={{ fontWeight: "bold" }}>Start Date : {filterAssessment ? moment(filterAssessment[0].startDate).format("DD-MM-YYYY") : ""}</material.Typography>
                                        <material.Typography sx={{ fontWeight: "bold" }}>End Date : {filterAssessment ? moment(filterAssessment[0].endDate).format("DD-MM-YYYY") : ""}</material.Typography>
                                    </span>
                                </div>
                            ) : null}
                        </div>
                        {isLoading ? (
                            <div className="row">
                                <div className="col-12">
                                    <BarsLoader />
                                </div>
                            </div>
                        ) : (
                            <>
                                {assessmentQuestions.length ? assessmentQuestions.map((question, ind) => {
                                    isAnswered = answeredQuestions[question.questionRefNo];
                                    return (
                                        <div className="row mt-3" key={ind}>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className="col-7">
                                                        <material.FormLabel component="legend" required>{question.slNo}. {question.question}</material.FormLabel>
                                                    </div>
                                                    <div className="col-5">
                                                        <span className='float-end'>
                                                            <material.Button variant="contained" color='success' size='small' sx={{ textTransform: "none", mr: 1 }} disabled={isSubmit || question.answer === null || !isAnswered} onClick={() => uploadAttachedDocument(question.answer.refNo)} startIcon={<material.UploadFileIcon />}>Upload Supporting Document</material.Button>
                                                            <material.Button variant="contained" color='success' size='small' sx={{ textTransform: "none" }} onClick={handleSubmit(saveAnswers)} startIcon={<material.SaveIcon />} disabled={!isAnswered || isSubmit}>Save</material.Button>
                                                        </span>
                                                    </div>
                                                </div>
                                                <material.TextField
                                                    {...register(`${question.questionRefNo}`, { maxLength: 4000 })}
                                                    error={errors[question?.questionRefNo]?.type === "required"}
                                                    margin="dense"
                                                    required
                                                    id="userName"
                                                    variant="outlined"
                                                    type="text"
                                                    size="small"
                                                    defaultValue={question.answer ? question.answer.responseText : ""}
                                                    fullWidth
                                                    multiline
                                                    rows={9}
                                                    InputProps={{ readOnly: isSubmit }}
                                                />
                                                <p className='form-text text-danger'>{errors[question?.questionRefNo]?.type === "required" && 'This field is required'}</p>
                                                {(errors[question?.questionRefNo]?.type === "maxLength") && (
                                                    <p className='text-danger'>Can not exceed 4000 character</p>
                                                )}
                                                {(errors[question?.questionRefNo]?.type === "minLength") && (
                                                    <p className='text-danger'>Minimum 15 character is required</p>
                                                )}
                                            </div>
                                        </div>
                                    )
                                }) : (
                                    <div className="row">
                                        <div className="col-12">
                                            <span className='d-flex justify-content-center align-item-center fw-bold text-danger'>No data</span>
                                        </div>
                                    </div>
                                )}
                                <div>
                                    {assessmentQuestions.length ? (
                                        <span className='float-end'>
                                            <material.Button variant="outlined" color='success' sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveAnswers)} startIcon={<material.SaveIcon />} disabled={!isAnswered || isSubmit}>Save All</material.Button>
                                            <material.Button variant="outlined" color='success' sx={{ textTransform: "none" }} onClick={handleSubmit(submitAnswers)} disabled={isSubmit || !allowSubmit || !buttonDisable || !isAnswered} startIcon={<material.DoneIcon />}>Submit</material.Button>
                                        </span>
                                    ) : null}
                                </div>
                            </>
                        )}
                    </material.Paper>
                </material.Box>
            </material.Box>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <UploadAndViewAttachedDocument
                openUploadAndAttachedDocument={openUploadAndAttachedDocument}
                setOpenUploadAndViewAttachedDocument={setOpenUploadAndViewAttachedDocument}
                getAssessmentQuestionsAndAnswer={getAssessmentQuestionsAndAnswer}
            />
        </div>
    );
}

export default EsgAssessmentModule;