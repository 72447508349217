import React from 'react';
import { material } from '../library/Material';

function ApplicableDialog(props) {

    const { setOpenWarningDialog, getApplicableData, openWarningDialog, applicable } = props;

    const handleClickClose = () => {
        setOpenWarningDialog(false);
    };

    const save = () => {
        getApplicableData("yes")
    };

    return (
        <div>
            <material.Dialog open={openWarningDialog} maxWidth="sm" fullWidth>
                <material.DialogTitle>{applicable ? "Are you sure, you want to change the mode Applicable to Not Applicable ?" : "Are you sure, you want to change the mode Not Applicable to Applicable ?"}</material.DialogTitle>
                <material.DialogActions className='m-3'>
                    <material.Button onClick={handleClickClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>No</material.Button>
                    <material.Button color='success' variant="outlined" onClick={save} sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />}>Yes</material.Button>
                </material.DialogActions>
            </material.Dialog>
        </div>
    );
}

export default ApplicableDialog;