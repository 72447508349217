import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateNoticesAndFinesData } from '../../services/sub-issue-services/notices-fines-service/NoticesFinesService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddNoticesFines(props) {
    const { addOpenNoticesAndFines, setAddOpenNoticesAndFines, getAllNoticesFinesData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, watch, getValues, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [nonComplianceAspect, setNonComplianceAspect] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [buttonDisable, setButtonDisable] = useState(false);
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    const onChangeNoOfNonCompliance = watch('no_of_non_compliance');
    const onChangeDisputeResolutionCase = watch('dispute_resolution_case');

    useEffect(() => {
        totalCalculation();
    }, [onChangeNoOfNonCompliance, onChangeDisputeResolutionCase]);

    const totalCalculation = () => {
        let onChangeNoOfNonComplianceValue = getValues('no_of_non_compliance');
        let disputeResolutionCaseValue = getValues('dispute_resolution_case');

        if (Number(disputeResolutionCaseValue) > Number(onChangeNoOfNonComplianceValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Dispute resolution case value not greater than total No. of non-compliance value",
            })
            setButtonDisable(true)
        } else {
            setButtonDisable(false)
        }
    };

    const handleNonComplianceAspectChange = (event) => {
        setNonComplianceAspect(event.target.value);
    };

    useEffect(() => {
        if (addOpenNoticesAndFines.action && addOpenNoticesAndFines.noticesFinesTableData) {
            setNonComplianceAspect(addOpenNoticesAndFines.noticesFinesTableData.non_compliance_aspect);
            reset(addOpenNoticesAndFines.noticesFinesTableData);
        }
    }, [addOpenNoticesAndFines.action === true]);

    const handleClose = () => {
        reset({ other_specify: "", no_of_non_compliance: "", monetary_value_fine_inr_lakh: "", non_monetary_sanctions: "", dispute_resolution_case: "", total_monetary_value_fine_inr_lakh: "", });
        setNonComplianceAspect("");
        setAddOpenNoticesAndFines({ action: false, noticesFinesTableData: null });
    };

    const saveNoticesFines = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "non_compliance_aspect": data.non_compliance_aspect,
                "other_specify": nonComplianceAspect === "Others (Specify)" ? data.other_specify : "",
                "no_of_non_compliance": Number(data.no_of_non_compliance),
                "monetary_value_fine_inr_lakh": Number(data.monetary_value_fine_inr_lakh),
                "non_monetary_sanctions": Number(data.non_monetary_sanctions),
                "dispute_resolution_case": Number(data.dispute_resolution_case),
                // "total_monetary_value_fine_inr_lakh": Number(data.total_monetary_value_fine_inr_lakh),
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(object, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllNoticesFinesData(getFilterDate, payloadData);
                    reset({ other_specify: "", no_of_non_compliance: "", monetary_value_fine_inr_lakh: "", non_monetary_sanctions: "", dispute_resolution_case: "", total_monetary_value_fine_inr_lakh: "", });
                    setNonComplianceAspect("");
                    setAddOpenNoticesAndFines({ action: false, noticesFinesTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateNoticesFines = async (data) => {
        const refNo = addOpenNoticesAndFines.noticesFinesTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let object = {
            "non_compliance_aspect": data.non_compliance_aspect,
            "other_specify": nonComplianceAspect === "Others (Specify)" ? data.other_specify : "",
            "no_of_non_compliance": Number(data.no_of_non_compliance),
            "monetary_value_fine_inr_lakh": Number(data.monetary_value_fine_inr_lakh),
            "non_monetary_sanctions": Number(data.non_monetary_sanctions),
            "dispute_resolution_case": Number(data.dispute_resolution_case),
            // "total_monetary_value_fine_inr_lakh": Number(data.total_monetary_value_fine_inr_lakh),
        }
        await updateSubIssueData(object, refNo, subIssueName)
            .then((resp) => {
                getAllNoticesFinesData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllNoticesFinesData(getFilterDate, payloadData);
                reset({ other_specify: "", no_of_non_compliance: "", monetary_value_fine_inr_lakh: "", non_monetary_sanctions: "", dispute_resolution_case: "", total_monetary_value_fine_inr_lakh: "", });
                setNonComplianceAspect("");
                setAddOpenNoticesAndFines({ action: false, noticesFinesTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: addOpenNoticesAndFines.noticesFinesTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                getAllNoticesFinesData(getFilterDate, payloadData);
                reset({ other_specify: "", no_of_non_compliance: "", monetary_value_fine_inr_lakh: "", non_monetary_sanctions: "", dispute_resolution_case: "", total_monetary_value_fine_inr_lakh: "", });
                setNonComplianceAspect("");
                setAddOpenNoticesAndFines({ action: false, noticesFinesTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={addOpenNoticesAndFines.action} maxWidth="lg" fullWidth>
                <material.DialogTitle id="alert-dialog-title" className='fw-bold headingText'>
                    {addOpenNoticesAndFines.noticesFinesTableData ? "Edit Notices & Fines" : "Add Notices & Fines"}
                </material.DialogTitle>
                <material.DialogContent>
                    {addOpenNoticesAndFines.noticesFinesTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Notices & Fines</material.Typography>
                            </div>
                            <div className='col-4 py-1'>
                                <material.FormControl required variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Non Compliance Aspect</material.InputLabel>
                                    <material.Select
                                        {...register("non_compliance_aspect", { required: true, })}
                                        require
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={nonComplianceAspect}
                                        onChange={handleNonComplianceAspectChange}
                                        label="Type of source"
                                    >
                                        <material.MenuItem value={"Water Withdrawal"}>Water Withdrawal</material.MenuItem>
                                        <material.MenuItem value={"Water Discharge"}>Water Discharge</material.MenuItem>
                                        <material.MenuItem value={"Air Emission"}>Air Emission</material.MenuItem>
                                        <material.MenuItem value={"Waste Disposal"}>Waste Disposal</material.MenuItem>
                                        <material.MenuItem value={"Others (Specify)"}>Others (specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {nonComplianceAspect === "Others (Specify)" ? (
                                <div className='col-4 py-1'>
                                    <material.TextField
                                        {...register("other_specify", { required: true, maxLength: 20 })}
                                        required
                                        label="Other Non Compliance Aspect Specify"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors?.other_specify?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-4 py-1'>
                                <material.TextField
                                    {...register("no_of_non_compliance", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Number of Non Compliance"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.no_of_non_compliance && errors.no_of_non_compliance.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-4 py-1'>
                                <material.TextField
                                    {...register("monetary_value_fine_inr_lakh", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    required
                                    margin="dense"
                                    label="Monetary Value Fine (INR Lakh)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.monetary_value_fine_inr_lakh && errors.monetary_value_fine_inr_lakh.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6th digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 py-1'>
                                <material.TextField
                                    {...register("non_monetary_sanctions", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Non Monetary Sanctions"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.non_monetary_sanctions && errors.non_monetary_sanctions.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-4 py-1'>
                                <material.TextField
                                    {...register("dispute_resolution_case", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Dispute Resolution Case"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.dispute_resolution_case && errors.dispute_resolution_case.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            {/* <div className='col-4 py-1'>
                                <material.TextField
                                    {...register("total_monetary_value_fine_inr_lakh", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    margin="dense"
                                    label="Total Monetary Value Fine (INR lakh)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.total_monetary_value_fine_inr_lakh && errors.total_monetary_value_fine_inr_lakh.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div> */}
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenNoticesAndFines.noticesFinesTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} disabled={buttonDisable} onClick={handleSubmit(updateNoticesFines)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenNoticesAndFines.noticesFinesTableData.rejection_note === null) || (addOpenNoticesAndFines.noticesFinesTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} disabled={buttonDisable} onClick={handleSubmit(saveNoticesFines)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddNoticesFines;