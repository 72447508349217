import React, { useEffect, useState } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { addSiteByCorporateRefNo } from '../services/SiteService';
import Snackbar from '../toaster-message/Snackbar';
import { getPillarIssueSubIssueTreeList } from '../services/GeneralDisclosureService';
import { saveFacilityIssueMapping } from '../services/ManageEntityService';

function AddSite(props) {

    const { openAddSite, setOpenAddSite, getSiteDataByCorporateRefNo } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [pillarIssueSubIssueData, setPillarIssueSubIssueData] = useState([]);

    useEffect(() => {
        if (openAddSite.siteData) {
            reset(openAddSite.siteData)
        } else if (openAddSite.action === true) {
            pillarIssueSubIssueTreeList()
        };
    }, [openAddSite.action === true]);

    const pillarIssueSubIssueTreeList = async () => {
        await getPillarIssueSubIssueTreeList()
            .then((resp) => {
                const result = [];
                resp.data.forEach((pillar) => {
                    pillar.children.forEach((issue) => {
                        result.push({
                            topicId: issue.id,
                            allowed: true
                        })
                    })
                    return result;
                })
                setPillarIssueSubIssueData(result);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    const saveSite = async (formData) => {
        let array = [];
        array.push(formData);
        await addSiteByCorporateRefNo(userDetails.corporateRefNo, array)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Added successfully",
                })
                if(resp.data.length){
                    manageEntityMapping(resp.data[0]);
                };
                getSiteDataByCorporateRefNo();
                setOpenAddSite({ action: false, siteData: null, callFrom: "" })
                reset({ "assetName": "" })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const manageEntityMapping = (facilityData) => {
        const payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: [facilityData.refNo],
            data: pillarIssueSubIssueData
        }
        saveFacilityIssueMapping(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    const updateSite = async (formData) => {
        let array = [];
        array.push(formData);
        await addSiteByCorporateRefNo(userDetails.corporateRefNo, array)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Updated successfully",
                })
                getSiteDataByCorporateRefNo()
                setOpenAddSite({ action: false, siteData: null, callFrom: "" })
                reset({ "assetName": "" })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                })
            })
    };

    const close = () => {
        setOpenAddSite({ action: false, siteData: null, callFrom: "" })
        reset({ "assetName": "" })
    };

    return (
        <div>
            <form>
                <material.Dialog maxWidth="md" fullWidth open={openAddSite.action}>
                    <material.DialogTitle className="headingText fw-bold">{openAddSite.siteData ? "Edit Facility" : "Add Facility"}</material.DialogTitle>
                    <material.DialogContent>
                        {/* <material.Paper elevation={3} sx={{ p: 3 }}> */}
                        <form>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-sm-12'>
                                    <material.TextField
                                        required
                                        label="Facility Name"
                                        id="Name"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        color='success'
                                        InputProps={{ readOnly: openAddSite.readOnly }}
                                        {...register("assetName", { required: true })}
                                    />
                                </div>
                                {/* <div className='col-lg-4 col-md-6 col-sm-12'>
                                    <material.TextField
                                        label="Email"
                                        id="email"
                                        variant="standard"
                                        type="email"
                                        size="small"
                                        fullWidth
                                        color='success'
                                        {...register("email")}
                                    />
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-12'>
                                    <material.TextField
                                        label="Phone Number"
                                        id="phoneNumber"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                        color='success'
                                        {...register("phone")}
                                    />
                                </div>
                                <div className='col-lg-8 col-md-6 col-sm-12 mt-3'>
                                    <material.TextField
                                        label="Address"
                                        id="address"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        multiline
                                        color='success'
                                        {...register("address")}
                                    />
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                                    <material.TextField
                                        label="State"
                                        id="state"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        color='success'
                                        {...register("state")}
                                    />
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                                    <material.TextField
                                        label="Country"
                                        id="country"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        color='success'
                                        {...register("country")}
                                    />
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                                    <material.TextField
                                        label="Fax"
                                        id="fax"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                        color='success'
                                        {...register("fax")}
                                    />
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                                    <material.TextField
                                        label="Website"
                                        id="website"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        color='success'
                                        {...register("website")}
                                    />
                                </div> */}
                            </div>
                        </form>
                        {/* </material.Paper> */}
                    </material.DialogContent>
                    <material.DialogActions sx={{ pb: 2, pr: 3 }}>
                        <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 2 }} onClick={close} startIcon={<material.CloseIcon />}>Close</material.Button>
                        {openAddSite.siteData ? (
                            <material.Button type='button' variant="outlined" color='success' sx={{ textTransform: "none" }} onClick={handleSubmit(updateSite)} startIcon={<material.DoneIcon />}>Update</material.Button>
                        ) : (
                            <material.Button type='button' variant="outlined" color='success' sx={{ textTransform: "none" }} onClick={handleSubmit(saveSite)} startIcon={<material.DoneIcon />}>Save</material.Button>
                        )}
                    </material.DialogActions>
                </material.Dialog>
            </form>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddSite;