import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Snackbar from '../../../toaster-message/Snackbar';
import moment from 'moment';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import AddAirEmissionsInteractions from '../../../sub-issues-dialogs/air-emissions-interactions/AddAirEmissionsInteractions';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';
import { getSubIssueRecordData, saveSubIssueData, updateSubIssueData } from '../../../services/sub-issue-services/AddSubissueService';
import Pagination from '../../../shared/pagination/Pagination';
import ApplicableDialog from '../../../dialogs/ApplicableDialog';
import { getDataFilterByCurrentApprovedDataAndNotApprovedDate } from '../../../shared/Filter';


var recordRefNos = [];
const label = { inputProps: { 'aria-label': 'Color switch demo', 'aria-label2': 'Checkbox demo' } };
var usersRole;

function AirEmissionsInteractions(props) {
    const location = useLocation();
    const treeViewData = location?.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };
    const [airEmissionsInteractionsTabs, setAirEmissionsInteractionsTabs] = useState('Air Emissions & Interactions');
    const [airEmissionsInteractionsTableData, setAirEmissionsInteractionsTableData] = useState([]);
    const [addOpenAirEmissionsInteractions, setAddOpenAirEmissionsInteractions] = useState({ action: false, airEmissionsInteractionsTableData: null });
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = React.useState(0);
    const [getFilterDate, setGetFilterDate] = useState(null);

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        getAllAirEmissionsInteractionsData()
    }, [airEmissionsInteractionsTabs]);

    const getFormDateToDate = (data) => {
        setGetFilterDate(data);
        getAllAirEmissionsInteractionsData(data, paginationData)
    };

    const getAllAirEmissionsInteractionsData = async (filterDate, paginationValue) => {
        if (airEmissionsInteractionsTabs === "Air Emissions & Interactions") {
            let forApproval = userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? true : null;
            let approveHistory = false;
            let payloadData = {
                subissueName: treeViewData.subIssue,
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            await getSubIssueRecordData(payloadData)
                .then((resp) => {
                    // const filterData = getDataFilterByCurrentApprovedDataAndNotApprovedDate(resp.data.records.content);
                    setAirEmissionsInteractionsTableData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setApplicableRefNo(resp.data.applicableRecord?.ref_no ? resp.data.applicableRecord.ref_no : null);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    }
                })
        } else if (airEmissionsInteractionsTabs === "Air Emissions & Interactions History") {
            let forApproval = true;
            let approveHistory = true;
            let payloadData = {
                subissueName: treeViewData.subIssue,
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            await getSubIssueRecordData(payloadData)
                .then((resp) => {
                    setAirEmissionsInteractionsTableData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    }
                })
        };
    };

    const addAirEmissionsInteractions = () => {
        setAddOpenAirEmissionsInteractions({ action: true, airEmissionsInteractionsTableData: null });
    };

    const editAirEmissionsInteractions = (rowData) => {
        setAddOpenAirEmissionsInteractions({ action: true, airEmissionsInteractionsTableData: rowData });
    };

    const clickCheckBox = (value) => {
        setAddOpenAirEmissionsInteractions({ action: false, airEmissionsInteractionsTableData: null });
        if (recordRefNos.includes(value)) {
            // recordRefNos.pop(value);
            recordRefNos = recordRefNos.filter(item => item !== value);
        } else {
            recordRefNos.push(value)
        };
    };

    const sendForApproval = async () => {
        if (recordRefNos.length) {
            const refNo = recordRefNos.length > 0 ? recordRefNos.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true
            }
            await updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    recordRefNos = [];
                    getAllAirEmissionsInteractionsData(getFilterDate, paginationData);
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    }
                })
        };
    };

    const approve = async () => {
        if (recordRefNos.length) {
            const refNo = recordRefNos.length > 0 ? recordRefNos.join(',') : null;
            const subissueName = treeViewData.subIssue;
            const payload = {
                send_for_approval: true,
                "approved_by": userDetails.username,
                "approved_date": moment().format("YYYY-MM-DD"),
                "approved_status": "APPROVED",
                "rejection_note": "",
            }
            await updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    recordRefNos = [];
                    getAllAirEmissionsInteractionsData(getFilterDate, paginationData);
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    }
                })
        };
    };

    const reject = async () => {
        if (recordRefNos.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        };
    };

    const getRejectionNoteData = async (noteData) => {
        const refNo = recordRefNos.length > 0 ? recordRefNos.join(',') : null;
        const subissueName = treeViewData.subIssue;
        const payload = {
            send_for_approval: false,
            "rejection_note": noteData.rejectionNote,
            "approved_status": "",
        }
        await updateSubIssueData(payload, refNo, subissueName)
            .then((resp) => {
                recordRefNos = [];
                getAllAirEmissionsInteractionsData(getFilterDate, paginationData);
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                }
            })
    };

    const handleChangeTabs = (event, newValue) => {
        setAirEmissionsInteractionsTabs(newValue);
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true)
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const getApplicableData = () => {
        if (applicableRefNo === null) {
            let subIssueName = treeViewData?.subIssue;
            const payload = {
                applicable: false,
                applicable_record: true,
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
            }
            saveSubIssueData(payload, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllAirEmissionsInteractionsData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    }
                })
        } else {
            let subIssueName = treeViewData?.subIssue;
            let refNo = applicableRefNo;
            const payload = {
                applicable: applicable ? false : true,
            }
            updateSubIssueData(payload, refNo, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllAirEmissionsInteractionsData(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    }
                })
        }
    };

    const getPaginationData = (filterData, paginationData) => {
        setRowsPerPage(paginationData.size);
        setPage(paginationData.page);
        getAllAirEmissionsInteractionsData(filterData, paginationData);
    };

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7, width: "80%" }}>
                    <div className='row'>
                        <div className='col-6'>
                            <span><material.Typography variant="h5" className='text-capitalize'>{treeViewData?.subIssue}</material.Typography></span>
                        </div>
                        <div className='col-6'>
                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") || userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? null : (
                                <span className="float-end">
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} onChange={applicableNotApplicable}
                                            checked={applicable}
                                            color="secondary" />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>
                    <material.Box sx={{ width: '100%', typography: 'body1' }}>
                        <material.TabContext value={airEmissionsInteractionsTabs}>
                            <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <material.TabList onChange={handleChangeTabs} aria-label="lab API tabs example">
                                    <material.Tab className='text-capitalize' label="Air Emissions & Interactions" value="Air Emissions & Interactions" />
                                    <material.Tab className='text-capitalize' label="Air Emissions & Interactions History" value="Air Emissions & Interactions History" />
                                </material.TabList>
                            </material.Box>
                            <material.TabPanel value="Air Emissions & Interactions">
                                <div className="row mt-2 align-items-center">
                                    <div className="col-6 pb-2">
                                        {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        )}
                                    </div>
                                    <div className="col-6" hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                        <span className='float-end'>
                                            {airEmissionsInteractionsTableData?.length ? (
                                                <div>
                                                    <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")} color='success'
                                                        disabled={!applicable || !recordRefNos.length}
                                                        sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approve}>Approve</material.Button>
                                                    <material.Button sx={{ textTransform: "none" }} hidden={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")}
                                                        disabled={!applicable || !recordRefNos.length}
                                                        variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={reject}>Reject</material.Button>
                                                </div>
                                            ) : null}
                                            <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_APPROVER")} color='success'
                                                disabled={!applicable}
                                                sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendForApproval}>Send for Approval</material.Button>
                                            <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_APPROVER")} color='success'
                                                disabled={!applicable}
                                                sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addAirEmissionsInteractions}>Add Air Emissions & Interactions</material.Button>
                                        </span>
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                    <material.TableContainer>
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow >
                                                    <StyledTableCell>Monitoring Location ID</StyledTableCell>
                                                    <StyledTableCell>Monitoring Location (Latitude)</StyledTableCell>
                                                    <StyledTableCell>Monitoring Location (Longitude)</StyledTableCell>
                                                    <StyledTableCell>Pollutant Name</StyledTableCell>
                                                    <StyledTableCell>Other Pollutant Specify</StyledTableCell>
                                                    <StyledTableCell>Measured Value</StyledTableCell>
                                                    <StyledTableCell>National Standard</StyledTableCell>
                                                    <StyledTableCell>Unit</StyledTableCell>
                                                    <StyledTableCell>From Date</StyledTableCell>
                                                    <StyledTableCell>To Date</StyledTableCell>
                                                    {/* {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                        <StyledTableCell>Approval Date</StyledTableCell>
                                                    )} */}
                                                    <StyledTableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>{userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                    {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                        <StyledTableCell>Rejection Note</StyledTableCell>
                                                    )}
                                                </material.TableRow>
                                            </material.TableHead>

                                            <material.TableBody>
                                                {airEmissionsInteractionsTableData?.length ? airEmissionsInteractionsTableData.map((item, ind) => (
                                                    <material.TableRow key={ind}
                                                        sx={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                        onClick={((userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")) && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true) ?
                                                            () => editAirEmissionsInteractions(item) : null}>
                                                        <material.TableCell >{item.monitoring_location_id}</material.TableCell>
                                                        <material.TableCell >{item.monitoring_location_easting}</material.TableCell>
                                                        <material.TableCell >{item.monitoring_location_northing}</material.TableCell>
                                                        <material.TableCell >{item.pollutant_name}</material.TableCell>
                                                        <material.TableCell>{(item.other_specify === null || item.other_specify === "") ? "N/A" : item.other_specify}</material.TableCell>
                                                        <material.TableCell >{item.measured_value}</material.TableCell>
                                                        <material.TableCell >{item.national_standard}</material.TableCell>
                                                        <material.TableCell >{item.unit}</material.TableCell>
                                                        <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        {/* {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                            <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        )} */}
                                                        <material.TableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                                            {userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") ? (
                                                                <material.Checkbox {...label} disabled={!applicable || item.send_for_approval} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                            ) : (
                                                                <material.Checkbox {...label} disabled={!applicable || item.approved_status === "APPROVED"} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                            )}
                                                        </material.TableCell>
                                                        {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                            <material.TableCell>{(item.rejection_note === null) || (item.rejection_note === "") ? "No" : (
                                                                <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                            )}
                                                            </material.TableCell>
                                                        )}
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={13}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                    <Pagination
                                        getPaginationData={getPaginationData}
                                        totalElements={totalElements}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        getFilterDate={getFilterDate}
                                    />
                                </material.Paper>
                            </material.TabPanel>
                            <material.TabPanel value="Air Emissions & Interactions History">
                                <div className="row">
                                    <div className="col-lg-8">
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        )}
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                    <material.TableContainer>
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow >
                                                    <StyledTableCell>Monitoring Location ID</StyledTableCell>
                                                    <StyledTableCell>Monitoring Location (Latitude)</StyledTableCell>
                                                    <StyledTableCell>Monitoring Location (Longitude)</StyledTableCell>
                                                    <StyledTableCell>Pollutant Name</StyledTableCell>
                                                    <StyledTableCell>Other Pollutant Specify</StyledTableCell>
                                                    <StyledTableCell>Measured Value</StyledTableCell>
                                                    <StyledTableCell>National Standard</StyledTableCell>
                                                    <StyledTableCell>Unit</StyledTableCell>
                                                    <StyledTableCell>From Date</StyledTableCell>
                                                    <StyledTableCell>To Date</StyledTableCell>
                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                    <StyledTableCell>Status</StyledTableCell>
                                                </material.TableRow>
                                            </material.TableHead>

                                            <material.TableBody>
                                                {airEmissionsInteractionsTableData?.length ? airEmissionsInteractionsTableData.map((item, ind) => (
                                                    <material.TableRow key={ind} >
                                                        <material.TableCell>{item.monitoring_location_id}</material.TableCell>
                                                        <material.TableCell>{item.monitoring_location_easting}</material.TableCell>
                                                        <material.TableCell>{item.monitoring_location_northing}</material.TableCell>
                                                        <material.TableCell>{item.pollutant_name}</material.TableCell>
                                                        <material.TableCell>{(item.other_specify === null || item.other_specify === "") ? "N/A" : item.other_specify}</material.TableCell>
                                                        <material.TableCell>{item.measured_value}</material.TableCell>
                                                        <material.TableCell>{item.national_standard}</material.TableCell>
                                                        <material.TableCell>{item.unit}</material.TableCell>
                                                        <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>
                                                            {item.approved_status === "APPROVED" ? <span className="badge bg-success">
                                                                APPROVED
                                                            </span> : null}
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={12}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                    <Pagination
                                        getPaginationData={getPaginationData}
                                        totalElements={totalElements}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        getFilterDate={getFilterDate}
                                    />
                                </material.Paper>
                            </material.TabPanel>
                        </material.TabContext>
                    </material.Box>
                </material.Box>
            </material.Box>
            <AddAirEmissionsInteractions
                addOpenAirEmissionsInteractions={addOpenAirEmissionsInteractions}
                setAddOpenAirEmissionsInteractions={setAddOpenAirEmissionsInteractions}
                getAllAirEmissionsInteractionsData={getAllAirEmissionsInteractionsData}
                treeViewData={treeViewData}
                page={page}
                rowsPerPage={rowsPerPage}
                getFilterDate={getFilterDate}
            />
            <ApplicableDialog
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getApplicableData={getApplicableData}
                applicable={applicable}
            />
            {/* <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getAllAirEmissionsInteractionsData={getAllAirEmissionsInteractionsData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            /> */}
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default AirEmissionsInteractions;