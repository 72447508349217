import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { saveOrUpdateEnvironmentalCriteriaData } from '../../services/sub-issue-services/supplier-chain-environment-service/EnvironmentalCriteriaService';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddEnvironmentalCriteria(props) {
    const { addOpenEnvironmentalCriteria, setAddOpenEnvironmentalCriteria, getAllEnvironmentalCriteriaData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, watch, getValues, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [auditDoneForSupplierEsgPerformance, setAuditDoneForSupplierEsgPerformance] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [buttonDisable, setButtonDisable] = useState(false);
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const onChangeTotalNoOfSuppliers = watch('totalNoOfSuppliers');
    const onChangeSuppliersScreenedUsingEnvCriteria = watch('suppliersScreenedUsingEnvCriteria');
    const onChangeSuppliersAssessedForEnvImpact = watch('suppliersAssessedForEnvImpact');
    const onChangeSuppliersHavingSignificantNegativeImpact = watch('suppliersHavingSignificantNegativeImpact');
    const onChangeCorrectiveMeasureGivenToSupplier = watch('correctiveMeasureGivenToSupplier');
    const onChangeRelationshipTerminatedForNegativeEnvImpact = watch('relationshipTerminatedForNegativeEnvImpact');

    const payload = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        totalCalculation();
    }, [onChangeTotalNoOfSuppliers, onChangeSuppliersScreenedUsingEnvCriteria, onChangeSuppliersAssessedForEnvImpact, onChangeSuppliersHavingSignificantNegativeImpact, onChangeCorrectiveMeasureGivenToSupplier, onChangeRelationshipTerminatedForNegativeEnvImpact]);

    const totalCalculation = () => {
        let totalNoOfSuppliersValue = getValues('totalNoOfSuppliers');
        let suppliersScreenedUsingEnvCriteriaValue = getValues('suppliersScreenedUsingEnvCriteria');
        let suppliersAssessedForEnvImpactValue = getValues('suppliersAssessedForEnvImpact');
        let suppliersHavingSignificantNegativeImpactValue = getValues('suppliersHavingSignificantNegativeImpact');
        let correctiveMeasureGivenToSupplierValue = getValues('correctiveMeasureGivenToSupplier');
        let relationshipTerminatedForNegativeEnvImpactValue = getValues('relationshipTerminatedForNegativeEnvImpact');

        if (Number(suppliersScreenedUsingEnvCriteriaValue) > Number(totalNoOfSuppliersValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Suppliers screened using env criteria value not greater than total No. of suppliers value",
            })
            setButtonDisable(true)
        } else if (Number(suppliersAssessedForEnvImpactValue) > Number(totalNoOfSuppliersValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Suppliers assessed for env impact value not greater than total No. of suppliers value",
            })
            setButtonDisable(true)
        } else if (Number(suppliersHavingSignificantNegativeImpactValue) > Number(totalNoOfSuppliersValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Suppliers having significant negative impact not greater than total No. of suppliers value",
            })
            setButtonDisable(true)
        } else if (Number(correctiveMeasureGivenToSupplierValue) > Number(totalNoOfSuppliersValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Corrective measure given to supplier value not greater than total No. of suppliers value",
            })
            setButtonDisable(true)
        } else if (Number(relationshipTerminatedForNegativeEnvImpactValue) > Number(totalNoOfSuppliersValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Relationships terminated for negative env impact value not greater than total No. of suppliers value",
            })
            setButtonDisable(true)
        } else {
            setButtonDisable(false)
        }
    };

    useEffect(() => {
        if (addOpenEnvironmentalCriteria.action && addOpenEnvironmentalCriteria.environmentalCriteriaTableData) {
            setAuditDoneForSupplierEsgPerformance(addOpenEnvironmentalCriteria.environmentalCriteriaTableData.audit_done_for_supplier_esg_performance);
            reset(addOpenEnvironmentalCriteria.environmentalCriteriaTableData);
        }
    }, [addOpenEnvironmentalCriteria.action === true]);

    const selectAuditDoneForSupplierESGPerformance = (event) => {
        setAuditDoneForSupplierEsgPerformance(event.target.value);
    };

    const handleClose = () => {
        setAuditDoneForSupplierEsgPerformance("");
        reset({ total_no_of_suppliers: "", suppliers_screened_using_env_criteria: "", suppliers_assessed_for_env_impact: "", suppliers_having_significant_negative_impact: "", corrective_measure_given_to_supplier: "", relationship_terminated_for_negative_env_impact: "", });
        setAddOpenEnvironmentalCriteria({ action: false, environmentalCriteriaTableData: null });
    };

    const saveEnvironmentalCriteria = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "total_no_of_suppliers": data.total_no_of_suppliers,
                "suppliers_screened_using_env_criteria": data.suppliers_screened_using_env_criteria,
                "suppliers_assessed_for_env_impact": data.suppliers_assessed_for_env_impact,
                "suppliers_having_significant_negative_impact": data.suppliers_having_significant_negative_impact,
                "corrective_measure_given_to_supplier": data.corrective_measure_given_to_supplier,
                "relationship_terminated_for_negative_env_impact": data.relationship_terminated_for_negative_env_impact,
                "audit_done_for_supplier_esg_performance": data.audit_done_for_supplier_esg_performance,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(object, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllEnvironmentalCriteriaData(getFilterDate, payload);
                    setAuditDoneForSupplierEsgPerformance("");
                    reset({ total_no_of_suppliers: "", suppliers_screened_using_env_criteria: "", suppliers_assessed_for_env_impact: "", suppliers_having_significant_negative_impact: "", corrective_measure_given_to_supplier: "", relationship_terminated_for_negative_env_impact: "", });
                    setAddOpenEnvironmentalCriteria({ action: false, environmentalCriteriaTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        }
    };

    const updateEnvironmentalCriteria = async (data) => {
        const refNo = addOpenEnvironmentalCriteria.environmentalCriteriaTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "total_no_of_suppliers": data.total_no_of_suppliers,
            "suppliers_screened_using_env_criteria": data.suppliers_screened_using_env_criteria,
            "suppliers_assessed_for_env_impact": data.suppliers_assessed_for_env_impact,
            "suppliers_having_significant_negative_impact": data.suppliers_having_significant_negative_impact,
            "corrective_measure_given_to_supplier": data.corrective_measure_given_to_supplier,
            "relationship_terminated_for_negative_env_impact": data.relationship_terminated_for_negative_env_impact,
            "audit_done_for_supplier_esg_performance": data.audit_done_for_supplier_esg_performance,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllEnvironmentalCriteriaData(getFilterDate, payload);
                setAuditDoneForSupplierEsgPerformance("");
                reset({ total_no_of_suppliers: "", suppliers_screened_using_env_criteria: "", suppliers_assessed_for_env_impact: "", suppliers_having_significant_negative_impact: "", corrective_measure_given_to_supplier: "", relationship_terminated_for_negative_env_impact: "", });
                setAddOpenEnvironmentalCriteria({ action: false, environmentalCriteriaTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: addOpenEnvironmentalCriteria.environmentalCriteriaTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllEnvironmentalCriteriaData(getFilterDate, payload);
                setAuditDoneForSupplierEsgPerformance("");
                reset({ total_no_of_suppliers: "", suppliers_screened_using_env_criteria: "", suppliers_assessed_for_env_impact: "", suppliers_having_significant_negative_impact: "", corrective_measure_given_to_supplier: "", relationship_terminated_for_negative_env_impact: "", });
                setOpenDeleteRecordConfirmationDialog(false);
                setAddOpenEnvironmentalCriteria({ action: false, environmentalCriteriaTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={addOpenEnvironmentalCriteria.action} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold headingText'>{addOpenEnvironmentalCriteria.environmentalCriteriaTableData ? "Edit Environmental Criteria" : "Add Environmental Criteria"}</material.DialogTitle>
                <material.DialogContent>
                    {addOpenEnvironmentalCriteria.environmentalCriteriaTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Environmental Criteria</material.Typography>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("total_no_of_suppliers", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Total Number of Suppliers"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.total_no_of_suppliers?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("suppliers_screened_using_env_criteria", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Suppliers Screened Using Env Criteria"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.suppliers_screened_using_env_criteria?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("suppliers_assessed_for_env_impact", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Suppliers Assessed for Env Impact"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.suppliers_assessed_for_env_impact?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("suppliers_having_significant_negative_impact", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Suppliers Having Significant Negative Impact"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.suppliers_having_significant_negative_impact?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("corrective_measure_given_to_supplier", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Corrective Measure Given to Supplier"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.corrective_measure_given_to_supplier?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("relationship_terminated_for_negative_env_impact", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Relationships Terminated for Negative Env Impact"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.relationship_terminated_for_negative_env_impact?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Audit Done for Supplier ESG Performance</material.InputLabel>
                                    <material.Select
                                        {...register("audit_done_for_supplier_esg_performance", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={auditDoneForSupplierEsgPerformance}
                                        onChange={selectAuditDoneForSupplierESGPerformance}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Yes"}>Yes</material.MenuItem>
                                        <material.MenuItem value={"No"}>No</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenEnvironmentalCriteria.environmentalCriteriaTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateEnvironmentalCriteria)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenEnvironmentalCriteria.environmentalCriteriaTableData.rejection_note === null) || (addOpenEnvironmentalCriteria.environmentalCriteriaTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveEnvironmentalCriteria)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddEnvironmentalCriteria;