import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateAirEmissionsInteractionsData } from '../../services/sub-issue-services/air-service/AirEmissionsInteractionsService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


const pollutantName = ["PM10", "PM2.5", "NO2", "SO2", "CO", "O3", "NH3", "Pb", "Ni", "As", "Benzo(a)pyrene", "Benzene", "VOC", "CH4", "POP", "HAP", "Other Pollutant (Specify)"]

function AddAirEmissionsInteractions(props) {
    const { addOpenAirEmissionsInteractions, setAddOpenAirEmissionsInteractions, getAllAirEmissionsInteractionsData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [monitoringLocationIds, setMonitoringLocationIds] = useState("");
    const [unit, setUnit] = useState("");
    const [selectPollutantName, setSelectPollutantName] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    const handleChangePollutantName = (event) => {
        setSelectPollutantName(event.target.value)
    };

    useEffect(() => {
        if (addOpenAirEmissionsInteractions.action && addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData) {
            setMonitoringLocationIds(addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData.monitoring_location_id);
            setUnit(addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData.unit)
            setSelectPollutantName(addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData.pollutant_name)
            reset(addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData);
        }
    }, [addOpenAirEmissionsInteractions.action === true]);


    const saveAirEmissionsInteractions = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                // "refNo": "",
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "applicable": true,
                "monitoring_location_id": data.monitoring_location_id,
                "monitoring_location_easting": data.monitoring_location_easting,
                "monitoring_location_northing": data.monitoring_location_northing,
                "measured_value": Number(data.measured_value),
                "national_standard": Number(data.national_standard),
                "unit": data.unit,
                "pollutant_name": data.pollutant_name,
                "other_specify": selectPollutantName === "Other Pollutant (Specify)" ? data.other_specify : "",
                "applicable_record": false,
                "to_date": payLoad?.toDate,
                "from_date": payLoad?.fromDate
            }
            await saveSubIssueData(object, subissueName)
                .then((resp) => {
                    getAllAirEmissionsInteractionsData(getFilterDate, payloadData)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    reset({ monitoring_location_easting: "", monitoring_location_northing: "", measured_value: "", national_standard: "", unit: "",other_specify:"" });
                    setMonitoringLocationIds("");
                    setSelectPollutantName("");
                    setAddOpenAirEmissionsInteractions({ action: false, airEmissionsInteractionsTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateAirEmissionsInteractions = async (data) => {
        // let object = {
        //     "refNo": addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData.refNo,
        //     "corporateRefNo": userDetails.corporateRefNo,
        //     "facilityRefNo": userDetails.facilityRefNo,
        //     "applicable": true,
        //     "monitoringLocationIds": data.monitoringLocationIds,
        //     "monitoringLocationEasting": data.monitoringLocationEasting,
        //     "monitoringLocationNorthing": data.monitoringLocationNorthing,
        //     "measuredValue": Number(data.measuredValue),
        //     "nationalStandard": Number(data.nationalStandard),
        //     "unit": data.unit,
        //     toDate: data.toDate,
        //     fromDate: data.fromDate
        // }
        const refNo = addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        // const subIssueName = "Materials+Used";
        let obj = {
            "monitoring_location_id": data.monitoring_location_id,
            "monitoring_location_easting": data.monitoring_location_easting,
            "monitoring_location_northing": data.monitoring_location_northing,
            "measured_value": Number(data.measured_value),
            "national_standard": Number(data.national_standard),
            "unit": data.unit,
            "pollutant_name": data.pollutant_name,
            "other_specify": selectPollutantName === "Other Pollutant (Specify)" ? data.other_specify : "",
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                getAllAirEmissionsInteractionsData(getFilterDate, payloadData)
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ monitoring_location_easting: "", monitoring_location_northing: "", measured_value: "", national_standard: "", unit: "",other_specify:"" });
                setMonitoringLocationIds("");
                setSelectPollutantName("");
                setAddOpenAirEmissionsInteractions({ action: false, airEmissionsInteractionsTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    }

    const handleClose = () => {
        reset({ monitoringLocationEasting: "", monitoringLocationNorthing: "", measuredValue: "", nationalStandard: "", unit: "", other_specify:""});
        setMonitoringLocationIds("");
        setSelectPollutantName("");
        setAddOpenAirEmissionsInteractions({ action: false, airEmissionsInteractionsTableData: null });
    };

    const handleMonitoringLocationIdChange = (event) => {
        setMonitoringLocationIds(event.target.value);
    };

    const handleChangeUnit = (event) => {
        setUnit(event.target.value)
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        await commonDelete(payload)
            .then((resp) => {
                getAllAirEmissionsInteractionsData(getFilterDate, payloadData);
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ monitoring_location_easting: "", monitoring_location_northing: "", measured_value: "", national_standard: "", unit: "",other_specify:"" });
                setMonitoringLocationIds("");
                setSelectPollutantName("");
                setAddOpenAirEmissionsInteractions({ action: false, airEmissionsInteractionsTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={addOpenAirEmissionsInteractions.action} maxWidth="lg" fullWidth>
                <material.DialogTitle id="alert-dialog-title" className='fw-bold headingText'>
                    {addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData ? "Edit Air Emissions & Interactions" : "Add Air Emissions & Interactions"}
                </material.DialogTitle>
                <material.DialogContent>
                    {addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Air Emissions & Interactions</material.Typography>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl required variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Monitoring Location ID</material.InputLabel>
                                    <material.Select
                                        {...register("monitoring_location_id", { required: true, })}
                                        require
                                        labelId="demo-simple-select-standard-label"
                                        className='pt-1'
                                        id="demo-simple-select-standard"
                                        value={monitoringLocationIds}
                                        onChange={handleMonitoringLocationIdChange}
                                        label="Type of source"
                                    >
                                        <material.MenuItem value={"AAQ 1"}>AAQ 1</material.MenuItem>
                                        <material.MenuItem value={"AAQ 2"}>AAQ 2</material.MenuItem>
                                        <material.MenuItem value={"AAQ 3"}>AAQ 3</material.MenuItem>
                                        <material.MenuItem value={"AAQ 4"}>AAQ 4</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("monitoring_location_easting", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    required
                                    margin="dense"
                                    label="Monitoring Location (Latitude)"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                {errors.monitoring_location_easting && errors.monitoring_location_easting.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("monitoring_location_northing", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    required
                                    margin="dense"
                                    label="Monitoring Location (Longitude)"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                {errors.monitoring_location_northing && errors.monitoring_location_northing.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label">Pollutant Name</material.InputLabel>
                                    <material.Select
                                        {...register("pollutant_name", { required: true, })}
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={selectPollutantName}
                                        onChange={handleChangePollutantName}
                                        label="Pollutants Name"
                                    >
                                        {pollutantName.map((item, ind) => <material.MenuItem key={ind} value={item}>{item}</material.MenuItem>)}

                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {selectPollutantName === "Other Pollutant (Specify)" ? (
                                <div className='col-lg-4 py-2'>
                                    <material.TextField
                                        {...register("other_specify", { required: true, maxLength: 20 })}
                                        required
                                        label="Other Pollutant Specify"
                                        id="Other(Specify)"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors?.othersSpecifyArea?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("measured_value", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    margin="dense"
                                    label="Measured Value"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                {errors.measured_value && errors.measured_value.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("national_standard", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    margin="dense"
                                    label="National Standard"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                {errors.national_standard && errors.national_standard.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("unit", { required: true, })}
                                    required
                                    margin="dense"
                                    label="Unit"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateAirEmissionsInteractions)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData.rejection_note === null) || (addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveAirEmissionsInteractions)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddAirEmissionsInteractions;