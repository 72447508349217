import React, { useEffect, useState } from 'react'
import { material } from '../../library/Material';
import SwipeDrawer from '../drawer/SwipeDrawer';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import { useNavigate } from 'react-router-dom';
import { getEntityData } from '../../services/ManageEntityService';
import { useSelector } from 'react-redux';
import { getAllCorporate } from '../../services/CorporateService';
import { getSitesByCorporateRefNo } from '../../services/SiteService';
import Snackbar from '../../toaster-message/Snackbar';
import BarsLoader from '../../shared/loader/BarsLoader';
import { getPillarIssueSubIssueTreeList } from '../../services/GeneralDisclosureService';


var corporateList;
var corporateRefNo = [];
var facilityList = [];
// var facilityRefNo = [];

const icon = <material.CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <material.CheckBoxIcon fontSize="small" />;

function ManageEntity() {

    const navigate = useNavigate();
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [corporateName, setCorporateName] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [entityList, setEntityList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectCorporateName, setSelectCorporateName] = useState("");
    const [pillarIssueSubIssueData, setPillarIssueSubIssueData] = useState([]);

    useEffect(() => {
        if (userDetails.roles.includes("ROLE_CORPORATE_EDITOR")) {
            getFacilityByCorporateRefNo()
        } else if (userDetails.roles.includes("ROLE_ADMIN")) {
            getAllCorporateData();
        };
        pillarIssueSubIssueTreeList();
    }, []);

    const getAllCorporateData = async () => {
        await getAllCorporate()
            .then((resp) => {
                corporateList = resp.data;
                let activeCorporate = resp.data.filter((ele) => ele.active === true);
                let corporateName = activeCorporate?.map((ele) => ele.corporateName).map((element, i) => {
                    if (element !== null) {
                        return element
                    } else if (element === null) {
                        return element = "No Name" + i
                    }
                })
                setCorporateName(corporateName)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const selectCorporate = async (e, value) => {
        setSelectCorporateName(value);
        corporateRefNo = corporateList?.filter((ele) => ele.corporateName === value).map((res) => res.refNo)[0];
        getFacilityByCorporateRefNo(corporateRefNo);
    };

    const getFacilityByCorporateRefNo = async (refNo) => {
        await getSitesByCorporateRefNo(userDetails.roles.includes("ROLE_ADMIN") ? refNo : userDetails.corporateRefNo)
            .then((resp) => {
                facilityList = resp.data;
                let sitesRefNo = resp.data?.map((ele) => ele.refNo);
                getAllEntityData(sitesRefNo);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const pillarIssueSubIssueTreeList = async () => {
        await getPillarIssueSubIssueTreeList()
            .then((resp) => {
                setPillarIssueSubIssueData(resp.data)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    // const selectSite = (e, value) => {
    //     facilityRefNo = value?.map((res) => res.refNo);
    //     getAllEntityData(facilityRefNo);
    // };

    const getAllEntityData = (facilityRefNo) => {
        setIsLoading(true);
        const payload = {
            corporateRefNo: userDetails.roles.includes("ROLE_ADMIN") ? corporateRefNo : userDetails.corporateRefNo,
            facilityRefNo: facilityRefNo
        }
        getEntityData(payload)
            .then((resp) => {
                let data = resp.data.facilityData.map((ele) => {
                    return {
                        ...ele,
                        corporateName: selectCorporateName,
                        corporateRefNo: corporateRefNo
                    }
                })
                setEntityList(data);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const manageEntity = (data) => {
        // console.log(data)
        let entityData = { ...data, corporateName: selectCorporateName }
        navigate("/manage-entity/add-entity", { state: { entityData } });
    };

    const editEntity = (rowData) => {
        const editData = mergeData(pillarIssueSubIssueData, rowData);
        navigate("/manage-entity/edit-entity", { state: { editData } });
    };

    function mergeData(data1, data2) {
        data1.forEach((pillar) => {
            pillar.children.forEach(issue => {
                const filterData = data2.data.filter((ele) => {
                    if (ele.id == issue.id) {
                        return ele;
                    }
                })
                issue.allowed = filterData[0]?.allowed === undefined ? true : filterData[0].allowed;
                issue.facilityName = data2.facilityName;
                issue.corporateName = data2.corporateName;
                issue.corporateRefNo = data2.corporateRefNo;
                issue.facilityRefNo = data2.facilityRefNo
            })
        })
        return data1;
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className='row'>
                        <div className="col-6">
                            <span><material.Typography variant="h5">Entity List</material.Typography></span>
                        </div>
                        {/* <div className="col-6">
                            <span className='float-end'>
                                <material.Button variant="contained" color='success' sx={{ textTransform: "none" }}
                                    onClick={manageEntity}>Manage Entity</material.Button>
                            </span>
                        </div> */}
                        <div className='row'>
                            {userDetails.roles.includes("ROLE_ADMIN") ? (
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <material.Autocomplete
                                        margin="dense"
                                        id="sitesName"
                                        onChange={selectCorporate}
                                        options={corporateName}
                                        renderInput={(params) => <material.TextField {...params} variant="standard" label="Corporate Name"
                                            fullWidth
                                            required
                                        />}
                                    />
                                </div>
                            ) : null}
                            {/* <div className={userDetails.roles.includes("ROLE_ADMIN") ? "col-lg-6 col-md-6 col-sm-12" : "col-12"}>
                                <material.Autocomplete
                                    margin="dense"
                                    id="sitesName"
                                    onChange={selectSite}
                                    options={facilityName}
                                    disableCloseOnSelect
                                    multiple
                                    getOptionLabel={(option) => option.assetName}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <material.Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.assetName}
                                        </li>
                                    )}
                                    renderInput={(params) => <material.TextField {...params} variant="standard" label="Facility Name"
                                        fullWidth
                                        required
                                    />}
                                />
                            </div> */}
                        </div>
                        <div>
                            <material.Paper sx={{ p: 2, mt: 2, }} elevation={3}>
                                <div className='col-12'>
                                    <material.TableContainer sx={{ maxHeight: 560 }}>
                                        <material.Table stickyHeader aria-label="sticky table">
                                            <material.TableHead >
                                                <material.TableRow>
                                                    <StyledTableCell>Facility Name</StyledTableCell>
                                                    <StyledTableCell>Allowed Issue Name</StyledTableCell>
                                                    <StyledTableCell>Action</StyledTableCell>
                                                </material.TableRow>
                                            </material.TableHead>
                                            <material.TableBody>
                                                {isLoading ? (
                                                    <material.TableRow>
                                                        <material.TableCell colSpan={2}>
                                                            <BarsLoader />
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                ) : (
                                                    <>
                                                        {entityList?.length ? entityList.map((item, ind) => (
                                                            <material.TableRow
                                                                // sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                                                // onClick={() => editEntity(item)}
                                                                key={ind}
                                                            >
                                                                <material.TableCell>{item.facilityName}</material.TableCell>
                                                                <material.TableCell className='text-break' size='small' style={{ width: "50rem" }}>{item.combined}</material.TableCell>
                                                                {item.combined ? (
                                                                    <material.TableCell>
                                                                        <material.IconButton title='Edit Entity' color='success' onClick={() => editEntity(item)}>
                                                                            <material.CreateIcon />
                                                                        </material.IconButton>
                                                                    </material.TableCell>
                                                                ) : (
                                                                    <material.TableCell>
                                                                        <material.IconButton title='Add Entity' color='success' onClick={() => manageEntity(item)}>
                                                                            <material.AddIcon />
                                                                        </material.IconButton>
                                                                    </material.TableCell>
                                                                )}
                                                            </material.TableRow>
                                                        )) : (
                                                            <material.TableRow >
                                                                <material.TableCell colSpan={10}>
                                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )}
                                                    </>
                                                )}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                </div>
                            </material.Paper>
                        </div>
                    </div>
                </material.Box>
            </material.Box>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

export default ManageEntity;