import React, { useState } from 'react';
import { material } from '../../library/Material';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import AddNic from './AddNic';
import { useSelector } from 'react-redux';
import { deleteNic } from '../../services/materiality-services/MaterialityService';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import Snackbar from '../../toaster-message/Snackbar';

function NicMateriality(props) {

    const { getSiteBySiteRefNo, facilityData, getDistinctNicList } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [openNic, setOpenNic] = useState({ action: false, editData: null });
    const [deleteRowData, setDeleteRowData] = useState(null);
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const addNicDialog = () => {
        setOpenNic({ action: true, editData: null });
    };

    const editNicData = (rowData) => {
        setOpenNic({ action: true, editData: rowData });
    };

    const deleteConfirmation = (rowData) => {
        setOpenDeleteRecordConfirmationDialog(true);
        setDeleteRowData(rowData);
    };

    const deleteProductServiceData = (rowData) => {
        deleteNic(userDetails.facilityRefNo, rowData)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getSiteBySiteRefNo();
                getDistinctNicList();
                setOpenDeleteRecordConfirmationDialog(false);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message,
                    })
                };
            })
    };


    return (
        <>
            <div className='row'>
                {userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? (
                    <div className='col-12'>
                        <material.Button variant="contained" color='success' sx={{ textTransform: "none", float: 'inline-end' }} startIcon={<material.AddIcon />} onClick={addNicDialog} >Add NIC</material.Button>
                    </div>
                ) : null}
                <div className='col-12 mt-3'>
                    <material.TableContainer>
                        <material.Table>
                            <material.TableHead>
                                <material.TableRow>
                                    <StyledTableCell>Group Code</StyledTableCell>
                                    <StyledTableCell>Group Description</StyledTableCell>
                                    <StyledTableCell>Class Code</StyledTableCell>
                                    <StyledTableCell>Class Description</StyledTableCell>
                                    <StyledTableCell>Subclass Code</StyledTableCell>
                                    <StyledTableCell>Subclass Description</StyledTableCell>
                                    <StyledTableCell>Action</StyledTableCell>
                                </material.TableRow>
                            </material.TableHead>
                            <material.TableBody>
                                {facilityData?.nicDtoList?.length ? facilityData.nicDtoList.map((nic, k) => (
                                    <material.TableRow key={k}
                                    // sx={userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                    // onClick={userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? () => editNicData(nic) : null}
                                    >
                                        <material.TableCell>{nic.groupCode}</material.TableCell>
                                        <material.TableCell>{nic.groupDescription}</material.TableCell>
                                        <material.TableCell>{nic.classCode}</material.TableCell>
                                        <material.TableCell>{nic.classDescription}</material.TableCell>
                                        <material.TableCell>{nic.subclassCode}</material.TableCell>
                                        <material.TableCell className='text-break' size='small' style={{ width: "20rem" }}>{nic.subclassDescription}</material.TableCell>
                                        <material.TableCell>
                                            <material.IconButton onClick={() => deleteConfirmation(nic)} color='error'>
                                                <material.DeleteIcon />
                                            </material.IconButton>
                                        </material.TableCell>
                                    </material.TableRow>
                                )) : (
                                    <material.TableRow >
                                        <material.TableCell colSpan={8}>
                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                    </material.TableRow>
                                )}
                            </material.TableBody>
                        </material.Table>
                    </material.TableContainer>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <AddNic
                openNic={openNic}
                setOpenNic={setOpenNic}
                getSiteBySiteRefNo={getSiteBySiteRefNo}
                getDistinctNicList={getDistinctNicList}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                deleteProductServiceData={deleteProductServiceData}
                callFrom="NIC"
                deleteRowData={deleteRowData}
            />
        </>
    )
}

export default NicMateriality