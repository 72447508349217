import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { StyledTableCell } from "../../../shared/TableHeaderStyle";
import { useSelector } from "react-redux";
import AddDiversityOfGovernanceBodies from '../../../sub-issues-dialogs/diversity-of-governance/AddDiversityOfGovernanceBodies';
import { getAllDiversityOfGovernanceBodiesData } from '../../../services/sub-issue-services/DiversityOfGovernanceBodiesService';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import { approveRecord } from '../../../services/sub-issue-services/DiversityOfGovernanceBodiesService';
import { sendForApproved } from '../../../services/sub-issue-services/DiversityOfGovernanceBodiesService';
import Snackbar from '../../../toaster-message/Snackbar';
import moment from 'moment/moment';
import { getDataFilterByCurrentApprovedDataAndNotApprovedDate, getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import EmployeeAgeBasedDataDialog from '../../../sub-issues-dialogs/employee/EmployeeAgeBasedDataDialog';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';
import { getSubIssueRecordData, saveSubIssueData, updateSubIssueData } from '../../../services/sub-issue-services/AddSubissueService';
import ApplicableDialog from '../../../dialogs/ApplicableDialog';
import Pagination from '../../../shared/pagination/Pagination';

var usersRole;
var recordRefNo = []
const label = { inputProps: { "aria-label": "Color switch demo" } };

function DiversityOfGovernanceBodies(props) {
  const location = useLocation();
  const treeViewData = location?.state;
  const userDetails = useSelector((state) => state.LoginSlice.data);
  const [selectTab, setSelectTab] = useState('Diversity of Governance Bodies');
  const [diversityOfGovernanceBodiesData, setDiversityOfGovernanceBodiesData] = useState([]);
  const [openAddDiversity, setOpenAddDiversity] = useState({ action: false, diversityOfGovernanceBodiesData: null });
  const [applicable, setApplicable] = useState(true);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
  const [openEmployeeAgeBasedDataTable, setOpenEmployeeAgeBasedDataTable] = useState({ action: false, employeesAgeBasedData: null, collForm: "DIVERSITY_OF_GOVERNANCE_BODIES" });
  const [applicableRefNo, setApplicableRefNo] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState({
    "action": false,
    "type": "",
    "message": "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [getFilterDate, setGetFilterDate] = useState(null);

  for (let i = 0; i < userDetails?.roles?.length; i++) {
    usersRole = userDetails.roles[i]
  };

  const paginationData = {
    page,
    size: rowsPerPage
  };

  useEffect(() => {
    getDiversityOfGovernanceBodiesData();
  }, [selectTab]);

  const handleChangeDiversityTab = (event, newValue) => {
    setSelectTab(newValue);
  };

  const getFormDateToDate = (data) => {
    setGetFilterDate(data);
    getDiversityOfGovernanceBodiesData(data, paginationData);
  };

  const getDiversityOfGovernanceBodiesData = async (filterDate, paginationValue) => {
    if (selectTab === 'Diversity of Governance Bodies') {
      getDiversityOfGovernanceData(filterDate, paginationValue);
    } else if (selectTab === 'Diversity of Governance Bodies History') {
      getDiversityOfGovernanceBodiesHistory(filterDate, paginationValue);
    }
  };

  const getDiversityOfGovernanceData = async (filterDate, paginationValue) => {
    let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
    let approveHistory = false;
    let payload = {
      subissueName: treeViewData.subIssue,
      corporateRefNo: userDetails.corporateRefNo,
      facilityRefNo: userDetails.facilityRefNo,
      fromDate: filterDate?.fromDate,
      toDate: filterDate?.toDate,
      forApproval,
      approveHistory,
      page: paginationValue?.page ? paginationValue.page : 0,
      size: paginationValue?.size ? paginationValue.size : 10
    }
    await getSubIssueRecordData(payload)
      .then((resp) => {
        console.log(resp.data.records.content)
        // const FilterData = getDataFilterByCurrentApprovedDataAndNotApprovedDate(resp.data.records.content);
        setDiversityOfGovernanceBodiesData(resp.data.records.content);
        setApplicable(resp.data.applicableRecord.applicable);
        setApplicableRefNo(resp.data.applicableRecord?.ref_no ? resp.data.applicableRecord.ref_no : null);
        setRowsPerPage(resp.data.records.size);
        setPage(resp.data.records.number);
        setTotalElements(resp.data.records.totalElements);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "You are not authorized to perform this action. Redirected to login",
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
          })
        }
      })
  };

  const getDiversityOfGovernanceBodiesHistory = async (filterDate, paginationValue) => {
    let forApproval = true;
    let approveHistory = true;
    let payload = {
      subissueName: treeViewData.subIssue,
      corporateRefNo: userDetails.corporateRefNo,
      facilityRefNo: userDetails.facilityRefNo,
      fromDate: filterDate?.fromDate,
      toDate: filterDate?.toDate,
      forApproval,
      approveHistory,
      page: paginationValue?.page ? paginationValue.page : 0,
      size: paginationValue?.size ? paginationValue.size : 10
    }
    await getSubIssueRecordData(payload)
      .then((resp) => {
        setDiversityOfGovernanceBodiesData(resp.data.records.content);
        setRowsPerPage(resp.data.records.size);
        setPage(resp.data.records.number);
        setTotalElements(resp.data.records.totalElements);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "You are not authorized to perform this action. Redirected to login",
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
          })
        }
      })
  };

  const employeeAgeBasedData = (rowData) => {
    setOpenEmployeeAgeBasedDataTable({ action: true, employeesAgeBasedData: rowData, collForm: "DIVERSITY_OF_GOVERNANCE_BODIES" });
    setOpenAddDiversity({ action: false, diversityOfGovernanceBodiesData: null });
  };

  const addDiversity = () => {
    setOpenAddDiversity({ action: true, diversityOfGovernanceBodiesData: null });
    setOpenEmployeeAgeBasedDataTable({ action: false, employeesAgeBasedData: null, collForm: "DIVERSITY_OF_GOVERNANCE_BODIES" })
  };

  const editDiversity = (rowData) => {
    setOpenAddDiversity({ action: true, diversityOfGovernanceBodiesData: rowData });
  };

  const clickCheckBox = (value) => {
    setOpenAddDiversity({ action: false, diversityOfGovernanceBodiesData: null });
    if (recordRefNo.includes(value)) {
      // recordRefNo.pop(value);
      recordRefNo = recordRefNo.filter(item => item !== value);
    }
    else {
      recordRefNo.push(value);
    }
  };

  const sendApprovalDiversity = async () => {
    if (recordRefNo.length) {
      const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
      const subissueName = treeViewData.subIssue;
      const payload = {
        send_for_approval: true
      }
      await updateSubIssueData(payload, refNo, subissueName)
        .then((resp) => {
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
          getDiversityOfGovernanceBodiesData(getFilterDate, paginationData);
          recordRefNo = [];
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
            })
          }
        })
    }
  };

  const approvedDiversity = async () => {
    if (recordRefNo.length) {
      const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
      const subissueName = treeViewData.subIssue;
      const payload = {
        send_for_approval: true,
        "approved_by": userDetails.username,
        "approved_date": moment().format("YYYY-MM-DD"),
        "approved_status": "APPROVED",
        "rejection_note": "",
      }
      await updateSubIssueData(payload, refNo, subissueName)
        .then((resp) => {
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
          getDiversityOfGovernanceBodiesData(getFilterDate, paginationData);
          recordRefNo = [];
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
            })
          }
        })
    }
  };

  const rejectRecordDiversity = async () => {
    if (recordRefNo.length) {
      setOpenRejectionNoteDialog({ action: true, rejectionData: null });
    }
  };

  const viewRejectionNote = (rejectData) => {
    setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
  };

  const getRejectionNoteData = async (noteData) => {
    const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
    const subissueName = treeViewData.subIssue;
    const payload = {
      send_for_approval: false,
      "rejection_note": noteData.rejectionNote,
      "approved_status": "",
    }
    await updateSubIssueData(payload, refNo, subissueName)
      .then((resp) => {
        setOpenSnackBar({
          "action": true,
          "type": "success",
          "message": resp.data,
        })
        getDiversityOfGovernanceBodiesData(getFilterDate, paginationData);
        recordRefNo = [];
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "You are not authorized to perform this action. Redirected to login",
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
          })
        }
      })
  };

  const applicableNotApplicable = () => {
    setOpenWarningDialog(true);
  };

  const getApplicableData = (data) => {
    if (applicableRefNo === null) {
      let subIssueName = treeViewData?.subIssue;
      const payload = {
        applicable: false,
        applicable_record: true,
        "corporate_ref_no": userDetails.corporateRefNo,
        "facility_ref_no": userDetails.facilityRefNo,
      }
      saveSubIssueData(payload, subIssueName)
        .then((resp) => {
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
          getDiversityOfGovernanceBodiesData(getFilterDate, paginationData);
          setOpenWarningDialog(false);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
            })
          }
        })
    } else {
      let subIssueName = treeViewData?.subIssue;
      let refNo = applicableRefNo;
      const payload = {
        applicable: applicable ? false : true,
      }
      updateSubIssueData(payload, refNo, subIssueName)
        .then((resp) => {
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
          getDiversityOfGovernanceBodiesData(getFilterDate, paginationData);
          setOpenWarningDialog(false);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
            })
          }
        })
    };
  };

  const getPaginationData = (filterData, paginationData) => {
    setRowsPerPage(paginationData.size);
    setPage(paginationData.page);
    getDiversityOfGovernanceBodiesData(filterData, paginationData);
  };

  return (
    <div>
      <material.Box sx={{ display: 'flex' }}>
        <SwipeDrawer />
        <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
          <div className='row'>
            <div className='col-6'>
              <span><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
            </div>
            <div className="col-6">
              <span className="float-end" hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}>
                <material.Stack direction="row" spacing={1} alignItems="center">
                  <material.Typography>Not applicable</material.Typography>
                  <material.Switch {...label} checked={applicable} color="secondary" onChange={applicableNotApplicable} />
                  <material.Typography>Applicable</material.Typography>
                </material.Stack>
              </span>
            </div>
          </div>
          <div>
            <material.Box sx={{ width: '100%', typography: 'body1' }}>
              <material.TabContext value={selectTab}>
                <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <material.TabList onChange={handleChangeDiversityTab} aria-label="lab API tabs example">
                    <material.Tab sx={{ textTransform: "none" }} label="Diversity of Governance Bodies" value="Diversity of Governance Bodies" />
                    <material.Tab sx={{ textTransform: "none" }} label="Diversity of Governance Bodies History" value="Diversity of Governance Bodies History" />
                  </material.TabList>
                </material.Box>
                <material.TabPanel value="Diversity of Governance Bodies">
                  <div className="row mt-2 align-items-center">
                    <div className="col-lg-6">
                      {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                        <DateFilter
                          getFormDateToDate={getFormDateToDate}
                        />
                      )}
                    </div>
                    <div className="col-lg-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                      <span className='float-end'>
                        {diversityOfGovernanceBodiesData?.length ? (
                          <>
                            <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} color='success' disabled={!applicable || !recordRefNo.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approvedDiversity}>Approve</material.Button>
                            <material.Button sx={{ textTransform: "none" }} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} disabled={!applicable || !recordRefNo.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectRecordDiversity}>Reject</material.Button>
                          </>
                        ) : null}
                        <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendApprovalDiversity}>Send for Approval</material.Button>
                        <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addDiversity}>Add Diversity of Governance Bodies</material.Button>
                      </span>
                    </div>
                  </div>
                  <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                    <div className="row mt-2">
                      <div className="col-12">
                        <material.TableContainer>
                          <material.Table>
                            <material.TableHead>
                              <material.TableRow>
                                <StyledTableCell>Category of Employee & Worker</StyledTableCell>
                                <StyledTableCell>Male</StyledTableCell>
                                <StyledTableCell>Female</StyledTableCell>
                                <StyledTableCell>Other</StyledTableCell>
                                <StyledTableCell>Total Derived</StyledTableCell>
                                <StyledTableCell>Vulnerable</StyledTableCell>
                                <StyledTableCell>From Date</StyledTableCell>
                                <StyledTableCell>To Date</StyledTableCell>
                                <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                <StyledTableCell>Age Based Data</StyledTableCell>
                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                  <StyledTableCell>Rejection Note</StyledTableCell>
                                )}
                              </material.TableRow>
                            </material.TableHead>
                            <material.TableBody>
                              {diversityOfGovernanceBodiesData.length ? diversityOfGovernanceBodiesData.map((item, k) => (
                                <material.TableRow key={k}
                                  sx={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                  onClick={((userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")) && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true) ?
                                    () => editDiversity(item) : null}
                                >
                                  <material.TableCell>{item.category_of_worker}</material.TableCell>
                                  <material.TableCell>{item.male}</material.TableCell>
                                  <material.TableCell>{item.female}</material.TableCell>
                                  <material.TableCell>{item.other}</material.TableCell>
                                  <material.TableCell>{item.total}</material.TableCell>
                                  <material.TableCell>{item.vulnerable}</material.TableCell>
                                  <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                  <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                  <material.TableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                    {userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") ? (
                                      <material.Checkbox {...label} disabled={!applicable || item.send_for_approval} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                    ) : (
                                      <material.Checkbox {...label} disabled={!applicable || item.approved_status === "APPROVED"} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                    )}
                                  </material.TableCell>
                                  <material.TableCell>
                                    {item.is_age_based_data_present === true ? (
                                      <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); employeeAgeBasedData({ ...item, history: false }) }}>Yes</material.Link>
                                    ) : null}
                                  </material.TableCell>
                                  {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                    <material.TableCell>{(item.rejection_note === null) || (item.rejection_note === "") ? "No" : (
                                      <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                    )}
                                    </material.TableCell>
                                  )}
                                </material.TableRow>
                              )) : (
                                <material.TableRow>
                                  <material.TableCell colSpan={12}>
                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                  </material.TableCell>
                                </material.TableRow>
                              )}
                            </material.TableBody>
                          </material.Table>
                        </material.TableContainer>
                        <Pagination
                          getPaginationData={getPaginationData}
                          totalElements={totalElements}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          getFilterDate={getFilterDate}
                        />
                      </div>
                    </div>
                  </material.Paper>
                </material.TabPanel>
                <material.TabPanel value="Diversity of Governance Bodies History">
                  <div className="row">
                    <div className="col-lg-8">
                      <DateFilter
                        getFormDateToDate={getFormDateToDate}
                      />
                    </div>
                  </div>
                  <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                    <div className='row'>
                      <div className="col-12">
                        <material.TableContainer>
                          <material.Table>
                            <material.TableHead>
                              <material.TableRow>
                                <StyledTableCell>Category of Employee & Worker</StyledTableCell>
                                <StyledTableCell>Male</StyledTableCell>
                                <StyledTableCell>Female</StyledTableCell>
                                <StyledTableCell>Other</StyledTableCell>
                                <StyledTableCell>Total Derived</StyledTableCell>
                                <StyledTableCell>Vulnerable</StyledTableCell>
                                <StyledTableCell>From Date</StyledTableCell>
                                <StyledTableCell>To Date</StyledTableCell>
                                <StyledTableCell>Approval Date</StyledTableCell>
                                <StyledTableCell>Approved Status</StyledTableCell>
                                <StyledTableCell>Age Based Data</StyledTableCell>
                              </material.TableRow>
                            </material.TableHead>
                            <material.TableBody>
                              {diversityOfGovernanceBodiesData?.length ? diversityOfGovernanceBodiesData.map((item, ind) => (
                                <material.TableRow key={ind}>
                                  <material.TableCell>{item.category_of_worker}</material.TableCell>
                                  <material.TableCell>{item.male}</material.TableCell>
                                  <material.TableCell>{item.female}</material.TableCell>
                                  <material.TableCell>{item.other}</material.TableCell>
                                  <material.TableCell>{item.total}</material.TableCell>
                                  <material.TableCell>{item.vulnerable}</material.TableCell>
                                  <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                  <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                  <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                  <material.TableCell>
                                    {item.approved_status === "APPROVED" ? <span className="badge bg-success">
                                      APPROVED
                                    </span> : null}
                                  </material.TableCell>
                                  <material.TableCell>
                                    {item.is_age_based_data_present === true ? (
                                      <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); employeeAgeBasedData({ ...item, history: true }) }}>Yes</material.Link>
                                    ) : null}
                                  </material.TableCell>
                                </material.TableRow>
                              )) : (
                                <material.TableRow >
                                  <material.TableCell colSpan={12}>
                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                  </material.TableCell>
                                </material.TableRow>
                              )}
                            </material.TableBody>
                          </material.Table>
                        </material.TableContainer>
                        <Pagination
                          getPaginationData={getPaginationData}
                          totalElements={totalElements}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          getFilterDate={getFilterDate}
                        />
                      </div>
                    </div>
                  </material.Paper>
                </material.TabPanel>
              </material.TabContext>
            </material.Box>
          </div>
        </material.Box>
      </material.Box>
      <AddDiversityOfGovernanceBodies
        openAddDiversity={openAddDiversity}
        setOpenAddDiversity={setOpenAddDiversity}
        getDiversityOfGovernanceData={getDiversityOfGovernanceData}
        treeViewData={treeViewData}
        page={page}
        rowsPerPage={rowsPerPage}
        getFilterDate={getFilterDate}
      />
      <ApplicableDialog
        openWarningDialog={openWarningDialog}
        setOpenWarningDialog={setOpenWarningDialog}
        getApplicableData={getApplicableData}
        applicable={applicable}
      />
      <Snackbar
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
      />
      <EmployeeAgeBasedDataDialog
        openEmployeeAgeBasedDataTable={openEmployeeAgeBasedDataTable}
        setOpenEmployeeAgeBasedDataTable={setOpenEmployeeAgeBasedDataTable}
      />
      <RejectionNote
        openRejectionNoteDialog={openRejectionNoteDialog}
        setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
        getRejectionNoteData={getRejectionNoteData}
      />
    </div>
  );
}

export default DiversityOfGovernanceBodies;