import React, { useEffect, useState } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';
import { addUpdateCorporateOrFacilityPointOfContact } from '../services/CorporateService';
import { useSelector } from 'react-redux';
import Snackbar from '../toaster-message/Snackbar';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './AddContact.css';

var usersRole;

function AddContact(props) {

    const { openAddContact, setOpenAddContact, getCorporateDetails, getSiteBySiteRefNo } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [phone, setPhone] = useState("");
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    useEffect(() => {
        if (openAddContact.contactData) {
            setPhone(openAddContact.contactData.phNo);
            reset(openAddContact.contactData);
        }
    }, [openAddContact.action === true])
    const cancel = () => {
        setOpenAddContact({ action: false, contactData: "" })
        setPhone("");
        reset({ "name": "", "designation": "", "phNo": "", "email": "" })
    };
    const save = async (data) => {
        let pointOfContact = [];
        pointOfContact.push({ ...data, phNo: phone, });
        let obj = {
            corporateRefNo: userDetails.corporateRefNo,
            siteRefNo: userDetails.facilityRefNo,
            dtos: pointOfContact,
        };
        await addUpdateCorporateOrFacilityPointOfContact(obj)
            .then((rep) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Added successfully",
                })
                setOpenAddContact({ action: false, contactData: "" });
                reset({ "name": "", "designation": "", "phNo": "", "email": "" });
                setPhone("");
                if (usersRole === "ROLE_CORPORATE_EDITOR") {
                    getCorporateDetails();
                } else if (usersRole === "ROLE_FACILITY_EDITOR") {
                    getSiteBySiteRefNo();
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog maxWidth="sm" fullWidth open={openAddContact.action} sx={{ p: 4 }}>
                <material.DialogTitle className="headingText">{openAddContact.contactData ? "Edit point of contact" : "Add point of contact"}</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div>
                            <material.TextField
                                required
                                variant='standard'
                                label="Name"
                                color='success'
                                fullWidth
                                type='text'
                                {...register("name", { required: true })}
                            />
                        </div>
                        <div className='mt-3'>
                            <material.TextField
                                required
                                variant='standard'
                                label="Designation"
                                color='success'
                                fullWidth
                                type='text'
                                {...register("designation", { required: true })}
                            />
                        </div>
                        <div className="mt-3">
                            <PhoneInput
                                required
                                placeholder="Enter phone number"
                                defaultCountry="IN"
                                inputRef={register}
                                enableSearch={true}
                                value={phone}
                                control={control}
                                international
                                rules={{ required: true }}
                                onChange={(phone) => setPhone(phone)}
                                error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                            />
                            {/* {(errors?.phone?.type === "pattern") && (
                                <p className='text-danger'>Invalid input, please input only number</p>
                            )} */}
                        </div>
                        <div className='mt-3'>
                            <material.TextField
                                required
                                variant='standard'
                                label="Email"
                                color='success'
                                fullWidth
                                type='email'
                                {...register("email", { required: true })}
                            />
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions sx={{ pr: 2, pb: 2 }}>
                    <material.Button variant="outlined" color='error' sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />} onClick={cancel}>Cancel</material.Button>
                    {openAddContact.contactData ? (
                        <material.Button variant="outlined" color='success' sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} onClick={handleSubmit(save)}>Update</material.Button>
                    ) : (
                        <material.Button variant="outlined" color='success' sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} onClick={handleSubmit(save)}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddContact;