import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { updatePollutantData } from '../../services/sub-issue-services/air-service/AirEmissionPointSourceService';
import Snackbar from '../../toaster-message/Snackbar';

function AddPollutantRatePointSource(props) {
    const { openAddPollutantRateAreaSource, setOpenAddPollutantRateAreaSource, getPollutantRateAreaSource, sourceId, allAirEmissionQuantityData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, watch, setValue, getValues, formState: { errors }, } = useForm({ mode: "onTouched" });
    const [selectSourcePollutantName, setSelectSourcePollutantName] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        setValue("sourceIds", sourceId);
        if (openAddPollutantRateAreaSource.action && openAddPollutantRateAreaSource.pollutantRateAreaSourceTableData) {
            reset(openAddPollutantRateAreaSource.pollutantRateAreaSourceTableData);
            setSelectSourcePollutantName(openAddPollutantRateAreaSource.pollutantRateAreaSourceTableData.pollutant)
        }
    }, [openAddPollutantRateAreaSource.action === true]);

    const savePollutantRateAreaSource = (data) => {
        if (openAddPollutantRateAreaSource.pollutantRateAreaSourceTableData) {
            let obj = {
                ...data,
                "otherSpecifyPollutant": selectSourcePollutantName === "Other Pollutant (Specify)" ? data.otherSpecifyPollutant : "",
            }
            updatePollutantData(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    allAirEmissionQuantityData();
                    reset({ sourceId: "", concentration: "", storageArea: "", pollutantRate: "", otherSpecifyPollutant: "" });
                    setSelectSourcePollutantName("");
                    setOpenAddPollutantRateAreaSource({ action: false, pollutantRateAreaSourceTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message,
                        })
                    };
                })
        } else {
            let obj = {
                "refNo": "",
                "sourceRefNo": "",
                "sourceIds": data.sourceIds,
                "pollutant": data.pollutant,
                "pollutantRate": data.pollutantRate,
                "concentration": data.concentration,
                "otherSpecifyPollutant": data.otherSpecifyPollutant
            }
            getPollutantRateAreaSource(obj);
            reset({ sourceId: "", concentration: "", storageArea: "", pollutantRate: "", otherSpecifyPollutant: "" });
            setSelectSourcePollutantName("");
            setOpenAddPollutantRateAreaSource({ action: false, pollutantRateAreaSourceTableData: null });
        }
    };

    const handleClose = () => {
        reset({ sourceId: "", concentration: "", storageArea: "", pollutantRate: "", otherSpecifyPollutant: "" });
        setSelectSourcePollutantName("");
        setOpenAddPollutantRateAreaSource({ action: false, pollutantRateAreaSourceTableData: null });
    };

    const handleChangeSourcePollutantName = (event) => {
        setSelectSourcePollutantName(event.target.value);
    };

    return (
        <div>
            <material.Dialog open={openAddPollutantRateAreaSource.action} maxWidth="lg" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddPollutantRateAreaSource.pollutantRateAreaSourceTableData ? "Edit Air Emission Pollutant & Rate" : "Add Air Emission Pollutant & Rate"}</material.DialogTitle>
                <material.DialogContent>
                    <material.DialogContentText id="alert-dialog-description">
                        <div>
                            <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                                <div className='row'>
                                    <div className='py-1'>
                                        <material.Typography className='fw-bold'>Pollutant & Rate (Point Source)</material.Typography>
                                    </div>
                                    <div className='col-4 col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("sourceIds", { required: true, })}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ readOnly: true, }}
                                            label="Source Id"
                                            id="Source id"
                                            variant="standard"
                                            type='text'
                                            size="small"
                                            fullWidth
                                        />
                                    </div>
                                    <div className='col-4 col-lg-4 col-md-6'>
                                        <material.FormControl variant="standard" fullWidth required>
                                            <material.InputLabel id="demo-simple-select-standard-label">Pollutant Name</material.InputLabel>
                                            <material.Select
                                                {...register("pollutant", { required: true, })}
                                                required
                                                fullWidth
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={selectSourcePollutantName}
                                                onChange={handleChangeSourcePollutantName}
                                                label="Pollutants name"
                                            >
                                                <material.MenuItem value={"PM10"}>PM10</material.MenuItem>
                                                <material.MenuItem value={"NOx"}>NOx</material.MenuItem>
                                                <material.MenuItem value={"SOx"}>SOx</material.MenuItem>
                                                <material.MenuItem value={"CO"}>CO</material.MenuItem>
                                                <material.MenuItem value={"VOC"}>VOC</material.MenuItem>
                                                <material.MenuItem value={"CH4"}>CH4</material.MenuItem>
                                                <material.MenuItem value={"POP"}>POP</material.MenuItem>
                                                <material.MenuItem value={"HAP"}>HAP</material.MenuItem>
                                                <material.MenuItem value={"Other Pollutant (Specify)"}>Other Pollutant (Specify)</material.MenuItem>
                                            </material.Select>
                                        </material.FormControl>
                                    </div>
                                    {selectSourcePollutantName === "Other Pollutant (Specify)" ? (
                                        <div className='col-4 col-lg-4 col-md-6'>
                                            <material.TextField
                                                {...register("otherSpecifyPollutant", { required: true, maxLength: 20 })}
                                                required
                                                label="Other Pollutant Specify"
                                                id="Others(Specify)"
                                                variant="standard"
                                                type='text'
                                                size="small"
                                                fullWidth
                                            />
                                            {errors?.othersSpecifyPollutant?.type === "maxLength" && (
                                                <p className='text-danger'>Maximum length 20 character</p>
                                            )}
                                        </div>
                                    ) : null}
                                    <div className='col-4 col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("concentration", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                            required
                                            label="Concentration (mg/nm3)"
                                            id="Concentration (mg/nm3)"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.concentration && errors.concentration.type === "pattern" && (
                                            <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                        )}
                                    </div>
                                    <div className='col-4 col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("pollutantRate", { pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                            // required
                                            label="Rate (gm/sec)"
                                            id="rate"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.pollutantRate && errors.pollutantRate.type === "pattern" && (
                                            <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                        )}
                                    </div>
                                </div>
                            </material.Card>
                        </div>
                    </material.DialogContentText>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(savePollutantRateAreaSource)} startIcon={<material.DoneIcon />}>{openAddPollutantRateAreaSource.pollutantRateAreaSourceTableData ? "Update" : "Save"}</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

export default AddPollutantRatePointSource;