import React, { useState } from 'react';
import { material } from '../../library/Material';

function DeletedPermissionDialog(props) {
    const { openDeletePermissionDialog, setOpenDeletePermissionDialog, getDeleteConformationData } = props;
    const [selectValue, setSelectValue] = useState("");
    const handleChange = (event) => {
        let value = event.target.value;
        setSelectValue(value);
        if (value === "Yes") {
            getDeleteConformationData(openDeletePermissionDialog.document);
            setSelectValue("");
        } else {
            setSelectValue("");
            setOpenDeletePermissionDialog({ action: false, document: null });
        };
    };

    return (
        <div>
            <material.Dialog open={openDeletePermissionDialog.action} maxWidth="auto">
                <material.DialogContent>
                    <div className='row'>
                        <div className='col-12 d-flex flex-row align-items-center'>
                            <div className='me-2 fw-bold text-dark'>Are you sure, you want to delete this document ?</div>
                            <material.FormControl>
                                <material.RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={selectValue}
                                    onChange={handleChange}
                                >
                                    <material.FormControlLabel value="No" label="No" control={<material.Radio color='secondary' />} />
                                    <material.FormControlLabel value="Yes" label="Yes" control={<material.Radio color='secondary' />} />
                                </material.RadioGroup>
                            </material.FormControl>
                        </div>
                    </div>
                </material.DialogContent>
            </material.Dialog>
        </div>
    )
}

export default DeletedPermissionDialog;