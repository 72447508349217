import React from 'react';
import { material } from '../../library/Material';
import SwipeDrawer from '../drawer/SwipeDrawer';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import AssessmentQuestionnaire from './AssessmentQuestionnaire';
import ViewResponse from './ViewResponse';
import SetUpAssessment from './SetUpAssessment';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <material.Box sx={{ p: 3 }}>
                    <material.Typography>{children}</material.Typography>
                </material.Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
};

function AssessmentModule(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <material.Box sx={{ display: 'flex' }}>
            <SwipeDrawer />
            <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7, width: "80%" }}>
                <div className='row'>
                    <span><material.Typography variant="h5" className='headingText'>Corporate {userDetails.corporateName}</material.Typography></span>
                    <div className='mt-3'>
                        <material.Box sx={{ bgcolor: 'background.paper' }}>
                            <material.AppBar position="static" sx={{ backgroundColor: "rgb(4, 153, 79)" }}>
                                <material.Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                >
                                    <material.Tab sx={{ textTransform: "none" }} label="Assessment Questionnaire" {...a11yProps(0)} />
                                    <material.Tab sx={{ textTransform: "none" }} label="Set Up Assessment" {...a11yProps(1)} />
                                    <material.Tab sx={{ textTransform: "none" }} label="View Response" {...a11yProps(2)} />
                                </material.Tabs>
                            </material.AppBar>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value}
                                onChangeIndex={handleChangeIndex}
                            >
                                <TabPanel value={value} index={0} dir={theme.direction}>
                                    <AssessmentQuestionnaire />
                                </TabPanel>
                                <TabPanel value={value} index={1} dir={theme.direction}>
                                    <SetUpAssessment />
                                </TabPanel>
                                <TabPanel value={value} index={2} dir={theme.direction}>
                                    <ViewResponse />
                                </TabPanel>
                            </SwipeableViews>
                        </material.Box>
                    </div>
                </div>
            </material.Box>
        </material.Box>
    );
}

export default AssessmentModule;