export const strategyManagementAllQuestion =
    [
        { questionId: 1, question: "Whether your entity’s policy/policies cover each principle and its core elements of the NGRBCs" },
        { questionId: 2, question: "Has the policy been approved by the Board?" },
        { questionId: 3, question: "The entity does not consider the Principles material to its business?" },
        { questionId: 4, question: "Whether the entity is not at a stage where it is in a position to implement the policies on specified principles" },
        { questionId: 5, question: "The entity does not have the financial and technical resources available for the task?" },
        { questionId: 6, question: "It is planned to be done in the next financial year " },
        { questionId: 7, question: "Whether the entity has translated the policy into procedures.?" },
        { questionId: 8, question: "Do the enlisted policies extend to your value chain partners?" },
        { questionId: 9, question: "Compliance with statutory requirements of relevance to the principles, and, rectification of any non-compliances" },
        { questionId: 10, question: "Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency?" },
    ];


// Simulate fetching data from the API
// const fetchedQuestions = [
//     { id: 1, text: 'Question 1' },
//     { id: 2, text: 'Question 2' },
//     { id: 3, text: 'Question 3' },
//     // Add more questions as needed
// ];