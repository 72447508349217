import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { getAllAssessmentAnswer, getAllAssessmentQuestion, getAllAssessmentQuestionMapping, getAssessment } from '../../services/AssessmentService';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import BarsLoader from '../../shared/loader/BarsLoader';
import { useForm } from 'react-hook-form';
import { styled } from '@mui/material';
import UploadAndViewAttachedDocument from './UploadAndViewAttachedDocument';

const StyledPaper = styled(material.Paper)({
    maxHeight: 250, // Adjust the maxHeight as needed
    overflow: 'auto'
});

function ViewResponse(props) {

    const { register, handleSubmit, getValues, setValue, control, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [assessmentData, setAssessmentData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [getFacilityName, setGetFacilityName] = useState([]);
    const [facilityData, setFacilityData] = useState([]);
    const [assessmentName, setAssessmentName] = useState([]);
    const [assessmentRefNo, setAssessmentRefNo] = useState(null);
    const [getAssessmentQuestionAns, setGetAssessmentQuestionAns] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [facilityRefNo, setFacilityRefNo] = useState(null);
    const [openUploadAndAttachedDocument, setOpenUploadAndViewAttachedDocument] = useState({ action: false, ansRefNo: null });

    useEffect(() => {
        getAssessmentByCorporateRefNo();
    }, []);

    useEffect(() => {
        if (facilityRefNo !== null && assessmentRefNo !== null) {
            getFacilityResponseData(facilityRefNo)
        }
    }, [assessmentRefNo]);

    const getAssessmentByCorporateRefNo = () => {
        getAssessment({ ...userDetails, filterApplicable: false })
            .then((resp) => {
                let assessmentName = resp.data.length ? resp.data.map((res) => res.assessmentName) : [];
                setAssessmentName(assessmentName);
                setAssessmentData(resp.data);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const selectAssessment = (e, newValue) => {
        reset({ facilityName: "" });
        setFacilityRefNo(null);
        setFacilityData([]);
        setGetFacilityName([]);
        setAssessmentRefNo(null);
        let refNo = null;
        if (newValue !== null) {
            const filterData = assessmentData.length ? assessmentData.filter((ele) => ele.assessmentName == newValue) : [];
            refNo = filterData.length ? filterData.map((resp) => resp.assessmentRefNo)[0] : null;
            let facilityName = filterData.length ? filterData[0].facilityList.map((facility) => facility.facilityName) : [];
            if (filterData.length) {
                setFacilityData(filterData[0].facilityList);
                setGetFacilityName(facilityName);
                reset({ facilityName: "" });
            } else {
                setFacilityData([]);
                reset({ facilityName: "" });
            }
            if (refNo !== null) {
                setAssessmentRefNo(refNo)
            }
        } else {
            setAssessmentRefNo(null);
            setFacilityRefNo(null);
            setGetAssessmentQuestionAns([]);
        }
    };

    const selectFacility = (e, newValue) => {
        let facilityRefNo = null;
        if (newValue !== null) {
            facilityRefNo = facilityData.length ? facilityData.filter((ele) => ele.facilityName === newValue)?.map((resp) => resp.facilityRefNo)[0] : null;
            if (facilityRefNo !== null && assessmentRefNo !== null) {
                getFacilityResponseData(facilityRefNo);
                setFacilityRefNo(facilityRefNo)
            }
        } else {
            setGetAssessmentQuestionAns([]);
        }
    };

    const getFacilityResponseData = (facilityRefNo) => {
        setIsLoading(true);
        const payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: facilityRefNo,
            assessmentRefNo: assessmentRefNo,
            submited: true
        };
        const questionPayload = {
            corporateRefNo: userDetails.corporateRefNo,
            assessmentRefNo: assessmentRefNo,
        }
        Promise.all([getAllAssessmentQuestionMapping(questionPayload), getAllAssessmentAnswer(payload)])
            .then((resp) => {
                const questions = resp[0].data.questions;
                const allAnswers = resp[1].data.dtoList;
                const answersMap = new Map();
                allAnswers.forEach(answer => {
                    const questionId = answer.questionId;
                    answersMap.set(questionId, answer);
                });
                const questionsWithAnswers = questions.map(question => {
                    const questionId = question.questionRefNo;
                    const answer = answersMap.get(questionId);
                    question.answer = answer ? answer : null;
                    return question;
                });
                setGetAssessmentQuestionAns(questionsWithAnswers);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const viewAttachedDocument = (ansRefNo) => {
        setOpenUploadAndViewAttachedDocument({ action: true, ansRefNo: ansRefNo });
    };


    return (
        <div style={{ marginTop: "-15px" }}>
            <material.Paper elevation={3} sx={{ p: 5 }}>
                <div className="row">
                    <div className="col-3">
                        <material.Autocomplete
                            sx={{ mb: 2, mt: -3 }}
                            margin="dense"
                            id="sitesName"
                            onChange={selectAssessment}
                            options={assessmentName}
                            renderInput={(params) => <material.TextField {...params} variant="standard" label="Assessment Name"
                                fullWidth
                                required
                            />}
                        // PaperComponent={(props) => <StyledPaper {...props} />}
                        />
                    </div>
                    {assessmentRefNo !== null ? (
                        <div className="col-3">
                            <material.Autocomplete
                                sx={{ mb: 2, mt: -3 }}
                                margin="dense"
                                id="sitesName"
                                onChange={selectFacility}
                                options={getFacilityName}
                                renderInput={(params) => <material.TextField {...params} variant="standard" label="Facility Name"
                                    fullWidth
                                    required
                                    {...register("facilityName")}
                                />}
                            />
                        </div>
                    ) : null}
                </div>
                {isLoading ? (
                    <div className="row">
                        <div className="col-12">
                            <BarsLoader />
                        </div>
                    </div>
                ) : (
                    <>
                        {getAssessmentQuestionAns.length ? getAssessmentQuestionAns.map((question, i) => (
                            <div className="row mt-3" key={i}>
                                <div className="col-12">
                                    <div className='row'>
                                        <div className="col-10">
                                            <material.FormLabel component="legend" required>{question.slNo}. {question.question}</material.FormLabel>
                                        </div>
                                        <div className="col-2">
                                            <span className='float-end'>
                                                <material.Button variant="contained" color='success' size='small' sx={{ textTransform: "none" }} disabled={question.answer === null} onClick={() => viewAttachedDocument(question.answer.refNo)} startIcon={<material.VisibilityIcon />}>View Supporting Document</material.Button>
                                            </span>
                                        </div>
                                    </div>
                                    <material.TextField
                                        margin="dense"
                                        required
                                        id="userName"
                                        variant="outlined"
                                        type="text"
                                        size="small"
                                        defaultValue={question.answer ? question.answer.responseText : "N/A"}
                                        fullWidth
                                        multiline
                                        rows={9}
                                        InputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>
                        )) : (
                            <div className="row">
                                <div className="col-12">
                                    <span className='d-flex justify-content-center align-item-center fw-bold text-danger'>No data</span>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </material.Paper>
            <UploadAndViewAttachedDocument
                openUploadAndAttachedDocument={openUploadAndAttachedDocument}
                setOpenUploadAndViewAttachedDocument={setOpenUploadAndViewAttachedDocument}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default ViewResponse;