import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import DatePeriod from '../../shared/DatePeriod';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { commonDelete, getMaterialsSourced, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import { getSiteByRefNo } from '../../services/SiteService';



function AddMaterialSourced(props) {
    const { openAddMaterialSourced, setOpenAddMaterialSourced, getAllMaterialsSourcedData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, setValue, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [materialSourced, setMaterialSourced] = useState("");
    const [materialImported, setMaterialImported] = useState("");
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [currencyCode, setCurrencyCode] = useState(true);
    const [materialName, setMaterialName] = useState([]);
    const [materialNameData, setMaterialNameData] = useState("");

    const payload = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openAddMaterialSourced.action && openAddMaterialSourced.materialSourcedTableData) {
            // setMaterialName(openAddMaterialSourced.materialSourcedTableData.material_name);
            setMaterialNameData(openAddMaterialSourced.materialSourcedTableData.material_name);
            setMaterialSourced(openAddMaterialSourced.materialSourcedTableData.material_sourced);
            setMaterialImported(openAddMaterialSourced.materialSourcedTableData.material_imported);
            reset(openAddMaterialSourced.materialSourcedTableData);
        };
        if (openAddMaterialSourced.action) {
            getFacilityDetails();
        };
    }, [openAddMaterialSourced.action === true]);

    useEffect(() => {
        getMaterialName();
    }, [openAddMaterialSourced.action === true]);


    const selectMaterialName = (event) => {
        setMaterialNameData(event.target.value);
    };
    console.log(materialNameData);
    const selectMaterialSourced = (event) => {
        setMaterialSourced(event.target.value);
    };

    const selectMaterialImported = (event) => {
        setMaterialImported(event.target.value);
    };

    const handleClose = () => {
        reset({ other_specify: "", amount: "", currency_code: "", });
        setMaterialNameData("");
        setMaterialSourced("");
        setMaterialImported("");
        setOpenAddMaterialSourced({ action: false, materialSourcedTableData: null });
    };

    const getFacilityDetails = () => {
        getSiteByRefNo(userDetails.facilityRefNo)
            .then((resp) => {
                setValue("currency_code", resp.data.turnOverUnit);
                if (resp.data.turnOverUnit) {
                    setCurrencyCode(true)
                } else {
                    setCurrencyCode(false)
                }
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const getMaterialName = async () => {
        const obj = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: userDetails.facilityRefNo,
        }
        await getMaterialsSourced(obj)
            .then((resp) => {
                console.log(resp.data)
                setMaterialName(resp.data);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    const submitMaterialSourcedData = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "material_name": data.material_name,
                "material_sourced": data.material_sourced,
                "other_specify": data.other_specify,
                "material_imported": data.material_imported,
                "amount": data.amount,
                "currency_code": data.currency_code,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllMaterialsSourcedData(getFilterDate, payload);
                    reset({ other_specify: "", amount: "", currency_code: "", });
                    setMaterialSourced("");
                    setMaterialImported("");
                    setMaterialNameData("");
                    setOpenAddMaterialSourced({ action: false, materialSourcedTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        }
    };

    const updateMaterialSourcedData = async (data) => {
        const refNo = openAddMaterialSourced.materialSourcedTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "material_name": data.material_name,
            "material_sourced": data.material_sourced,
            "other_specify": materialSourced === "Other (Specify)" ? data.other_specify : "",
            "material_imported": data.material_imported,
            "amount": data.amount,
            "currency_code": data.currency_code,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllMaterialsSourcedData(getFilterDate, payload);
                reset({ other_specify: "", amount: "", currency_code: "", });
                setMaterialSourced("");
                setMaterialImported("");
                setMaterialNameData("");
                setOpenAddMaterialSourced({ action: false, materialSourcedTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAddMaterialSourced.materialSourcedTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ other_specify: "", amount: "", currency_code: "", });
                setMaterialSourced("");
                setMaterialImported("");
                setMaterialNameData("");
                getAllMaterialsSourcedData(getFilterDate, payload);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddMaterialSourced({ action: false, materialSourcedTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddMaterialSourced.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddMaterialSourced.materialSourcedTableData ? "Edit Material Sourced" : "Add Material Sourced"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddMaterialSourced.materialSourcedTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Material Sourced</material.Typography>
                            </div>
                            <div className='col-4'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Material Name</material.InputLabel>
                                    <material.Select
                                        {...register("material_name", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={materialNameData}
                                        onChange={selectMaterialName}
                                        label="Material Name"
                                        disabled={!currencyCode}
                                    >
                                        {materialName?.map((ele, i) =>
                                            <material.MenuItem key={i} value={ele}>{ele}</material.MenuItem>
                                        )};
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Material Sourced</material.InputLabel>
                                    <material.Select
                                        {...register("material_sourced", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={materialSourced}
                                        onChange={selectMaterialSourced}
                                        label="Material Sourced"
                                        disabled={!currencyCode}
                                    >
                                        <material.MenuItem value={"Directly Sourced from MSMEs"}>Directly Sourced from MSMEs</material.MenuItem>
                                        <material.MenuItem value={"Small Producers"}>Small Producers</material.MenuItem>
                                        <material.MenuItem value={"Other (Specify)"}>Other (Specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {materialSourced === "Other (Specify)" ? (
                                <div className='col-4'>
                                    <material.TextField
                                        {...register("other_specify", { required: true, maxLength: 20 })}
                                        required
                                        label="Other (specify)"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                        disabled={!currencyCode}
                                    />
                                    <p className='form-text text-danger'>{errors.other_specify?.type === "required" && 'This field is required'}</p>
                                    {(errors?.other_specify?.type === "maxLength") && (
                                        <p className='text-danger'>Maximum 20 characters</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-4'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Material Imported</material.InputLabel>
                                    <material.Select
                                        {...register("material_imported", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={materialImported}
                                        onChange={selectMaterialImported}
                                        label="Type of material"
                                        disabled={!currencyCode}
                                    >
                                        <material.MenuItem value={"Yes"}>Yes</material.MenuItem>
                                        <material.MenuItem value={"No"}>No</material.MenuItem>
                                        {/* <material.MenuItem value={"Both"}>Both</material.MenuItem> */}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4 mt-2'>
                                <material.TextField
                                    {...register("amount", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    required
                                    label="Amount (in Million)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                    disabled={!currencyCode}
                                />
                                {errors.amount && errors.amount.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6th digit after decimal</p>
                                )}
                            </div>
                            <div className='col-4 mt-2'>
                                <material.TextField
                                    {...register("currency_code", { required: true, })}
                                    required
                                    label="Currency Code"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={!currencyCode}
                                />
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddMaterialSourced.materialSourcedTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateMaterialSourcedData)} startIcon={<material.DoneIcon />} disabled={!currencyCode}>Update</material.Button>
                            {(openAddMaterialSourced.materialSourcedTableData.rejection_note === null) || (openAddMaterialSourced.materialSourcedTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(submitMaterialSourcedData)} startIcon={<material.DoneIcon />} disabled={!currencyCode}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddMaterialSourced;