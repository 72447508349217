import { rootApiUrl } from "../../environment/Environment";
import { axiosInstance } from "../../shared/AxiosConfig";

export const getWasteGenerationReportData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/report/waste-generation`, { params: { ...payload } })
};

export const getWasteRecoveryAndDisposalReportData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/report/waste-recovery-and-disposal`, { params: { ...payload } })
};

export const getEnergyConsumptionWithinOrgReportData = async (payload) => {
    return await axiosInstance.post(`${rootApiUrl}/api/dashboard/fuel-energy`, payload)
};

export const getAirPointSourceReportData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/report/air-point-source`, { params: { ...payload } })
};

export const getWasteDerivedReportData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/report/waste-derived`, { params: { ...payload } })
};

export const getWasteRecoveryReportData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/report/waste-recovery`, { params: { ...payload } })
};

export const getWasteDisposalReportData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/report/waste-disposal`, { params: { ...payload } })
};

export const getScope1ReportData = async (payload) => {
    return await axiosInstance.post(`${rootApiUrl}/api/dashboard/ghg-scope1`, payload)
};

export const getScope2ReportData = async (payload) => {
    return await axiosInstance.post(`${rootApiUrl}/api/dashboard/ghg-scope2`, payload)
};

export const getScope3ReportData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/report/ghg-scope-3`, { params: { ...payload } })
};

export const getGhgEmissionReportingData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/report/ghg-scope-consolidated`, { params: { ...payload } })
};

export const getWaterDerivedReportData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/report/water-derived`, { params: { ...payload } })
};

export const getWaterWithDrawlData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/report/water-withdrawl`, { params: { ...payload } })
};

export const getWaterDischargeData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/report/water-discharge`, { params: { ...payload } })
};
