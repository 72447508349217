import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import DatePeriod from '../../shared/DatePeriod';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';

function AddODSEmissionProduction(props) {
    const { openAddODSEmissionProduction, setOpenAddODSEmissionProduction, getAllODSEmissionProductionData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, watch, getValues, setValue, formState: { errors }, } = useForm({ mode: "onTouched" });
    const [unit, setUnit] = useState('');
    const [exemptedUsesForeedstock, setExemptedUsesForeedstock] = useState("");
    const [exemptedForExternalUses, setExemptedForExternalUses] = useState("");
    const [productionForSupplyToArticleCountriesInAccordanceWithArticle, setProductionForSupplyToArticleCountriesInAccordanceWithArticle] = useState("");
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openAddODSEmissionProduction.action && openAddODSEmissionProduction.oDSEmissionProductionTableData) {
            setUnit(openAddODSEmissionProduction.oDSEmissionProductionTableData.unit_cfc);
            setExemptedUsesForeedstock(openAddODSEmissionProduction.oDSEmissionProductionTableData.exempted_uses_for_feedstock);
            setExemptedForExternalUses(openAddODSEmissionProduction.oDSEmissionProductionTableData.exempted_for_external_uses);
            setProductionForSupplyToArticleCountriesInAccordanceWithArticle(openAddODSEmissionProduction.oDSEmissionProductionTableData.production_for_supply_to_article_5_countries);
            reset(openAddODSEmissionProduction.oDSEmissionProductionTableData);
        }
    }, [openAddODSEmissionProduction.action === true]);

    const changeUnit = (event) => {
        setUnit(event.target.value);
    };

    const changeExemptedUsesForeedstock = (event) => {
        setExemptedUsesForeedstock(event.target.value);
    };

    const changeExemptedForExternalUses = (event) => {
        setExemptedForExternalUses(event.target.value);
    };

    const changeProductionForSupplyToArticleCountriesInAccordanceWithArticle = (event) => {
        setProductionForSupplyToArticleCountriesInAccordanceWithArticle(event.target.value);
    };

    const handleClose = () => {
        reset({ annex: "", substances: "", total_production_for_all_uses: "" })
        setUnit("");
        setExemptedUsesForeedstock("");
        setExemptedForExternalUses("");
        setProductionForSupplyToArticleCountriesInAccordanceWithArticle("");
        setOpenAddODSEmissionProduction({ action: false, oDSEmissionProductionTableData: null });
    };

    const saveODSEmissionProduction = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subIssueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "annex": data.annex,
                "substances": data.substances,
                "total_production_for_all_uses": data.total_production_for_all_uses,
                "unit_cfc": data.unit_cfc,
                "exempted_uses_for_feedstock": data.exempted_uses_for_feedstock,
                "exempted_for_external_uses": data.exempted_for_external_uses,
                "production_for_supply_to_article_5_countries": data.production_for_supply_to_article_5_countries,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
                applicable: true,
                applicable_record: false,
            }
            await saveSubIssueData(obj, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllODSEmissionProductionData(getFilterDate, paginationData);
                    reset({ annex: "", substances: "", total_production_for_all_uses: "" })
                    setUnit("");
                    setExemptedUsesForeedstock("");
                    setExemptedForExternalUses("");
                    setProductionForSupplyToArticleCountriesInAccordanceWithArticle("");
                    setOpenAddODSEmissionProduction({ action: false, oDSEmissionProductionTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };

    };

    const updateODSEmissionProduction = async (data) => {
        const refNo = openAddODSEmissionProduction.oDSEmissionProductionTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "annex": data.annex,
            "substances": data.substances,
            "total_production_for_all_uses": data.total_production_for_all_uses,
            "unit_cfc": data.unit_cfc,
            "exempted_uses_for_feedstock": data.exempted_uses_for_feedstock,
            "exempted_for_external_uses": data.exempted_for_external_uses,
            "production_for_supply_to_article_5_countries": data.production_for_supply_to_article_5_countries,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllODSEmissionProductionData(getFilterDate, paginationData);
                reset({ annex: "", substances: "", total_production_for_all_uses: "" })
                setUnit("");
                setExemptedUsesForeedstock("");
                setExemptedForExternalUses("");
                setProductionForSupplyToArticleCountriesInAccordanceWithArticle("");
                setOpenAddODSEmissionProduction({ action: false, oDSEmissionProductionTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAddODSEmissionProduction.oDSEmissionProductionTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ annex: "", substances: "", total_production_for_all_uses: "" })
                setUnit("");
                setExemptedUsesForeedstock("");
                setExemptedForExternalUses("");
                getAllODSEmissionProductionData(getFilterDate, paginationData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddODSEmissionProduction({ action: false, oDSEmissionProductionTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddODSEmissionProduction.action} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold headingText'>{openAddODSEmissionProduction.oDSEmissionProductionTableData ? "Edit ODS Production" : "Add ODS Production"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddODSEmissionProduction.oDSEmissionProductionTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 1, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>ODS Production</material.Typography>
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("annex", { required: true, })}
                                    required
                                    label="Annex/Group"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    multiline
                                />
                                {/* {errors?.annex?.type === "maxLength" && (
                                    <p className='text-danger'>Maximum length 20 character</p>
                                )} */}
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("substances", { required: true, })}
                                    required
                                    label="Substances Name"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    multiline
                                />
                                {/* {errors?.substances?.type === "maxLength" && (
                                    <p className='text-danger'>Maximum length 20 character</p>
                                )} */}
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("total_production_for_all_uses", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Total Production for All Uses"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.total_production_for_all_uses && errors.total_production_for_all_uses.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 mt-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Unit CFC</material.InputLabel>
                                    <material.Select
                                        {...register("unit_cfc", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={unit}
                                        onChange={changeUnit}
                                        label="Unit CFC"
                                    >
                                        <material.MenuItem value={"Metric Tons"}>Metric Tons</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4 mt-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Exempted Uses for Feedstock</material.InputLabel>
                                    <material.Select
                                        {...register("exempted_uses_for_feedstock", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={exemptedUsesForeedstock}
                                        onChange={changeExemptedUsesForeedstock}
                                        label="Exempted Uses for Feedstock"
                                    >
                                        <material.MenuItem value={"Yes"}>Yes</material.MenuItem>
                                        <material.MenuItem value={"No"}>No</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4 mt-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Exempted for External Uses</material.InputLabel>
                                    <material.Select
                                        {...register("exempted_for_external_uses", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={exemptedForExternalUses}
                                        onChange={changeExemptedForExternalUses}
                                        label="Exempted for External Uses"
                                    >
                                        <material.MenuItem value={"Yes"}>Yes</material.MenuItem>
                                        <material.MenuItem value={"No"}>No</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4 mt-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Production for Supply to Arti. Countries (Art. 2A-2H & 5)</material.InputLabel>
                                    <material.Select
                                        {...register("production_for_supply_to_article_5_countries", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={productionForSupplyToArticleCountriesInAccordanceWithArticle}
                                        onChange={changeProductionForSupplyToArticleCountriesInAccordanceWithArticle}
                                        label="Production for Supply to Article 5 Countries In Accordance with Article 2A-2H & 5"
                                    >
                                        <material.MenuItem value={"Yes"}>Yes</material.MenuItem>
                                        <material.MenuItem value={"No"}>No</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddODSEmissionProduction.oDSEmissionProductionTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateODSEmissionProduction)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddODSEmissionProduction.oDSEmissionProductionTableData.rejection_note === null) || (openAddODSEmissionProduction.oDSEmissionProductionTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveODSEmissionProduction)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddODSEmissionProduction;