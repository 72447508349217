import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import DateFilter from '../../../shared/DateFilter';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import Pagination from '../../../shared/pagination/Pagination';
import { getSubIssueRecordData, saveSubIssueData, updateSubIssueData } from '../../../services/sub-issue-services/AddSubissueService';
import AddWaterTreatmentReuse from '../../../sub-issues-dialogs/water-sub-issue-dilog/AddWaterTreatmentReuse';
import ApplicableDialog from '../../../dialogs/ApplicableDialog';
import Snackbar from '../../../toaster-message/Snackbar';
import RejectionNote from '../../../dialogs/RejectionNote';
import moment from 'moment/moment';




const label = { inputProps: { "aria-label": "Color switch demo" } };
var usersRole;
var recordRefNo = [];

function WaterTreatmentReuse(props) {
    const location = useLocation();
    const treeViewData = location?.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [wasteWaterTreatmentReuseTabs, setWasteWaterTreatmentReuseTabs] = useState("Waste Water Treatment Reuse");
    const [wasteWaterTreatmentReuseTableData, setWasteWaterTreatmentReuseTableData] = useState([]);
    const [openWasteWaterTreatmentReuse, setOpenWasteWaterTreatmentReuse] = useState({ action: false, wasteWaterTreatmentReuseTableData: null });
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [getFilterDate, setGetFilterDate] = useState(null);

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    const paginationData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        getAllWasteWaterTreatmentReuse();
    }, [wasteWaterTreatmentReuseTabs]);

    const handleChangeWasteWaterTreatmentReuseTab = (event, newValue) => {
        setWasteWaterTreatmentReuseTabs(newValue);
    };

    const getFormDateToDate = (data) => {
        setGetFilterDate(data);
        getAllWasteWaterTreatmentReuse(data, paginationData);
    };

    const getAllWasteWaterTreatmentReuse = (filterDate, paginationValue) => {
        if (wasteWaterTreatmentReuseTabs === "Waste Water Treatment Reuse") {
            let forApproval = userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? true : null;
            let approveHistory = false;
            let typeOfRecord = "Waste_Water_Treatment_Reuse";
            const payload = {
                subissueName: "Water Discharge",
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                typeOfRecord,
                forApproval,
                approveHistory,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            getSubIssueRecordData(payload)
                .then((resp) => {
                    setWasteWaterTreatmentReuseTableData(resp.data.records.content);
                    setApplicable(resp.data.applicableRecord.applicable);
                    setApplicableRefNo(resp.data.applicableRecord?.ref_no ? resp.data.applicableRecord.ref_no : null);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else if (wasteWaterTreatmentReuseTabs === "Waste Water Treatment Reuse History") {
            let forApproval = true;
            let approveHistory = true;
            let typeOfRecord = "Waste_Water_Treatment_Reuse";
            const payload = {
                subissueName: "Water Discharge",
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
                typeOfRecord,
                forApproval,
                approveHistory,
                page: paginationValue?.page ? paginationValue.page : 0,
                size: paginationValue?.size ? paginationValue.size : 10
            }
            getSubIssueRecordData(payload)
                .then((resp) => {
                    setWasteWaterTreatmentReuseTableData(resp.data.records.content);
                    setRowsPerPage(resp.data.records.size);
                    setPage(resp.data.records.number);
                    setTotalElements(resp.data.records.totalElements);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const addWasteWaterTreatmentReuse = () => {
        setOpenWasteWaterTreatmentReuse({ action: true, wasteWaterTreatmentReuseTableData: null });
    };

    const editWasteWaterTreatmentReuse = (rowData) => {
        setOpenWasteWaterTreatmentReuse({ action: true, wasteWaterTreatmentReuseTableData: rowData });
    };

    const clickCheckBox = (value) => {
        setOpenWasteWaterTreatmentReuse({ action: false, wasteWaterTreatmentReuseTableData: null });
        if (recordRefNo.includes(value)) {
            recordRefNo = recordRefNo.filter(item => item !== value);
        } else {
            recordRefNo.push(value);
        }
    };

    const sendForApproval = () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = "Water Discharge";
            const payload = {
                send_for_approval: true,
                "type_of_record": "Waste_Water_Treatment_Reuse",
            }
            updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllWasteWaterTreatmentReuse(getFilterDate, paginationData);
                    recordRefNo = [];
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        }
    };

    const approved = () => {
        if (recordRefNo.length) {
            const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
            const subissueName = "Water Discharge";
            const payload = {
                send_for_approval: true,
                "approved_by": userDetails.username,
                "approved_date": moment().format("YYYY-MM-DD"),
                "approved_status": "APPROVED",
                "rejection_note": "",
                "type_of_record": "Waste_Water_Treatment_Reuse",
            }
            updateSubIssueData(payload, refNo, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllWasteWaterTreatmentReuse(getFilterDate, paginationData);
                    recordRefNo = [];
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    const rejectRecord = () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        }
    };

    const getRejectionNoteData = async (noteData) => {
        const refNo = recordRefNo.length > 0 ? recordRefNo.join(',') : null;
        const subissueName = "Water Discharge";
        const payload = {
            send_for_approval: false,
            "rejection_note": noteData.rejectionNote,
            "approved_status": "",
            "type_of_record": "Waste_Water_Treatment_Reuse",
        }
        updateSubIssueData(payload, refNo, subissueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllWasteWaterTreatmentReuse(getFilterDate, paginationData);
                recordRefNo = [];
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };

    const getApplicableData = (data) => {
        if (applicableRefNo === null) {
            const subIssueName = "Water Discharge";
            const payload = {
                applicable: false,
                applicable_record: true,
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "type_of_record": "Waste_Water_Treatment_Reuse",
            }
            saveSubIssueData(payload, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllWasteWaterTreatmentReuse(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        } else {
            const subIssueName = "Water Discharge";
            let refNo = applicableRefNo;
            const payload = {
                applicable: applicable ? false : true,
                "type_of_record": "Waste_Water_Treatment_Reuse",
            }
            updateSubIssueData(payload, refNo, subIssueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllWasteWaterTreatmentReuse(getFilterDate, paginationData);
                    setOpenWarningDialog(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    }
                })
        };
    };

    const getPaginationData = (filterData, paginationData) => {
        setRowsPerPage(paginationData.size);
        setPage(paginationData.page);
        getAllWasteWaterTreatmentReuse(filterData, paginationData);
    };

    return (
        <div>
            <material.Box sx={{ display: "flex" }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7, width: 100 }}>
                    <div className="row">
                        <span className="col-6"> <material.Typography variant="h5" className='text-capitalize'>{treeViewData?.subIssue} </material.Typography> </span>
                        <div className="col-6">
                            {usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR" ? null : (
                                <span className="float-end">
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} checked={applicable} color="secondary" onClick={applicableNotApplicable} />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>
                    <div>
                        <material.Box sx={{ width: "100%", typography: "body1" }}>
                            <material.TabContext value={wasteWaterTreatmentReuseTabs}>
                                <material.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <material.TabList onChange={handleChangeWasteWaterTreatmentReuseTab} aria-label="lab API tabs example">
                                        <material.Tab className="text-capitalize" label="Waste Water Treatment Reuse" value="Waste Water Treatment Reuse" />
                                        <material.Tab className="text-capitalize" label="Waste Water Treatment Reuse History" value="Waste Water Treatment Reuse History" />
                                    </material.TabList>
                                </material.Box>
                                <material.TabPanel value="Waste Water Treatment Reuse">
                                    <div className="row mt-2 mb-2 align-items-center">
                                        <div className="col-6">
                                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                <DateFilter
                                                    getFormDateToDate={getFormDateToDate}
                                                />
                                            )}
                                        </div>
                                        <div className="col-6" hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                            <span className='float-end'>
                                                {wasteWaterTreatmentReuseTableData?.length ? (
                                                    <div>
                                                        <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")} color='success'
                                                            disabled={!applicable || !recordRefNo.length}
                                                            sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approved}>Approve</material.Button>
                                                        <material.Button sx={{ textTransform: "none" }} hidden={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")}
                                                            disabled={!applicable || !recordRefNo.length}
                                                            variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectRecord}>Reject</material.Button>
                                                    </div>
                                                ) : null}
                                                <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_APPROVER")} color='success'
                                                    disabled={!applicable}
                                                    sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendForApproval}>Send for Approval</material.Button>
                                                <material.Button variant="contained" hidden={userDetails.roles.includes("ROLE_FACILITY_APPROVER")} color='success'
                                                    disabled={!applicable}
                                                    sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addWasteWaterTreatmentReuse}>Add Waste Water Treatment Reuse</material.Button>
                                            </span>
                                        </div>
                                    </div>
                                    <material.Accordion className='mb-3'>
                                        <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                                            <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Water Discharge Recycle or Reuse KLD</material.Typography>
                                        </material.AccordionSummary>
                                        <material.AccordionDetails>
                                            <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <material.TableContainer>
                                                            <material.Table>
                                                                <material.TableHead>
                                                                    <material.TableRow>
                                                                        <StyledTableCell>Output Source</StyledTableCell>
                                                                        <StyledTableCell>Level of Treatment</StyledTableCell>
                                                                        <StyledTableCell>Category</StyledTableCell>
                                                                        <StyledTableCell>Quantity Reused/Recycled (KL)</StyledTableCell>
                                                                        <StyledTableCell>Purpose of Reuse/Recycle</StyledTableCell>
                                                                        <StyledTableCell>Final Discharge Quantity (KL)</StyledTableCell>
                                                                        <StyledTableCell>Discharge Point</StyledTableCell>
                                                                        <StyledTableCell>From Date</StyledTableCell>
                                                                        <StyledTableCell>To Date</StyledTableCell>
                                                                        <StyledTableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>{userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                                        {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                            <StyledTableCell>Rejection Note</StyledTableCell>
                                                                        )}
                                                                    </material.TableRow>
                                                                </material.TableHead>
                                                                <material.TableBody>
                                                                    {wasteWaterTreatmentReuseTableData.length ? wasteWaterTreatmentReuseTableData.map((item, k) => (
                                                                        <material.TableRow key={k}
                                                                            sx={userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                            onClick={((userDetails.roles.includes("ROLE_FACILITY_ESG_DATA")) && (item.send_for_approval === null || item.send_for_approval === false) && applicable === true) ?
                                                                                () => editWasteWaterTreatmentReuse(item) : null}
                                                                        >
                                                                            <material.TableCell>{item.output_source}</material.TableCell>
                                                                            <material.TableCell>{item.level_of_treatment}</material.TableCell>
                                                                            <material.TableCell>{(item.category === "" || item.category === null) ? "N/A" : item.category}</material.TableCell>
                                                                            <material.TableCell>{(item.quantity_reused_or_recycled === "" || item.quantity_reused_or_recycled === null) ? "N/A" : item.quantity_reused_or_recycled}</material.TableCell>
                                                                            <material.TableCell>{(item.purpose_of_reuse_or_recycle === "" || item.purpose_of_reuse_or_recycle === null) ? "N/A" : item.purpose_of_reuse_or_recycle}</material.TableCell>
                                                                            <material.TableCell>{item.final_discharge}</material.TableCell>
                                                                            <material.TableCell>{item.discharge_point}</material.TableCell>
                                                                            <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>
                                                                                {userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") ? (
                                                                                    <material.Checkbox {...label} disabled={!applicable || item.send_for_approval} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                                                ) : (
                                                                                    <material.Checkbox {...label} disabled={!applicable || item.approved_status === "APPROVED"} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                                                )}
                                                                            </material.TableCell>
                                                                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                                <material.TableCell>{(item.rejection_note === null) || (item.rejection_note === "") ? "No" : (
                                                                                    <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                                )}
                                                                                </material.TableCell>
                                                                            )}
                                                                        </material.TableRow>
                                                                    )) : (
                                                                        <material.TableRow>
                                                                            <material.TableCell colSpan={12}>
                                                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                            </material.TableCell>
                                                                        </material.TableRow>
                                                                    )}
                                                                </material.TableBody>
                                                            </material.Table>
                                                        </material.TableContainer>
                                                        <Pagination
                                                            getPaginationData={getPaginationData}
                                                            totalElements={totalElements}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            getFilterDate={getFilterDate}
                                                        />
                                                    </div>
                                                </div>
                                            </material.Paper>
                                        </material.AccordionDetails>
                                    </material.Accordion>
                                    <material.Accordion className='mb-3'>
                                        <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                                            <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Water Discharge Effluent Destination</material.Typography>
                                        </material.AccordionSummary>
                                        <material.AccordionDetails>
                                            <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <material.TableContainer>
                                                            <material.Table>
                                                                <material.TableHead>
                                                                    <material.TableRow>
                                                                        <StyledTableCell>Discharge Point</StyledTableCell>
                                                                        <StyledTableCell>Discharge Location (latitude)</StyledTableCell>
                                                                        <StyledTableCell>Discharge Location (longitude)</StyledTableCell>
                                                                        <StyledTableCell>Discharge Destination</StyledTableCell>
                                                                        <StyledTableCell>Discharge Destination Type</StyledTableCell>
                                                                        <StyledTableCell>Destination Category</StyledTableCell>
                                                                        <StyledTableCell>From Date</StyledTableCell>
                                                                        <StyledTableCell>To Date</StyledTableCell>
                                                                        {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                            <StyledTableCell>Approval Date</StyledTableCell>
                                                                        )}
                                                                        {/* <StyledTableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")}>{userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? "Approve" : "Send for Approval"}</StyledTableCell> */}
                                                                        {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                            <StyledTableCell>Rejection Note</StyledTableCell>
                                                                        )}
                                                                    </material.TableRow>
                                                                </material.TableHead>
                                                                <material.TableBody>
                                                                    {wasteWaterTreatmentReuseTableData.length ? wasteWaterTreatmentReuseTableData.map((item, k) => (
                                                                        <material.TableRow key={k}>
                                                                            <material.TableCell>{item.discharge_point}</material.TableCell>
                                                                            <material.TableCell>{item.discharge_location_lat}</material.TableCell>
                                                                            <material.TableCell>{item.discharge_location_long}</material.TableCell>
                                                                            <material.TableCell>{item.discharge_destination}</material.TableCell>
                                                                            <material.TableCell>{item.discharge_destination_type}</material.TableCell>
                                                                            <material.TableCell>{item.destination_category}</material.TableCell>
                                                                            <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            {
                                                                                userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                                    <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                                )
                                                                            }
                                                                            {/* < material.TableCell hidden={userDetails.roles.includes("ROLE_FACILITY_EDITOR")} >
                                                                                {
                                                                                    userDetails.roles.includes("ROLE_FACILITY_ESG_DATA") ? (
                                                                                        <material.Checkbox {...label} disabled={!applicable || item.send_for_approval} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                                                    ) : (
                                                                                        <material.Checkbox {...label} disabled={!applicable || item.approved_status === "APPROVED"} onChange={() => clickCheckBox(item.ref_no)} color="success" />
                                                                                    )
                                                                                }
                                                                            </material.TableCell> */}
                                                                            {userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? null : (
                                                                                <material.TableCell>{(item.rejection_note === null) || (item.rejection_note === "") ? "No" : (
                                                                                    <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                                )}
                                                                                </material.TableCell>
                                                                            )}
                                                                        </material.TableRow>
                                                                    )) : (
                                                                        <material.TableRow>
                                                                            <material.TableCell colSpan={12}>
                                                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                            </material.TableCell>
                                                                        </material.TableRow>
                                                                    )}
                                                                </material.TableBody>
                                                            </material.Table>
                                                        </material.TableContainer>
                                                        <Pagination
                                                            getPaginationData={getPaginationData}
                                                            totalElements={totalElements}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            getFilterDate={getFilterDate}
                                                        />
                                                    </div>
                                                </div>
                                            </material.Paper>
                                        </material.AccordionDetails>
                                    </material.Accordion>
                                </material.TabPanel>
                                <material.TabPanel value="Waste Water Treatment Reuse History">
                                    <material.Accordion className='mb-3'>
                                        <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                                            <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Water Discharge Recycle or Reuse KLD</material.Typography>
                                        </material.AccordionSummary>
                                        <material.AccordionDetails>
                                            <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <material.TableContainer>
                                                            <material.Table>
                                                                <material.TableHead>
                                                                    <material.TableRow>
                                                                        <StyledTableCell>Output Source</StyledTableCell>
                                                                        <StyledTableCell>Level of Treatment</StyledTableCell>
                                                                        <StyledTableCell>Category</StyledTableCell>
                                                                        <StyledTableCell>Quantity Reused/Recycled (KL)</StyledTableCell>
                                                                        <StyledTableCell>Purpose of Reuse/Recycle</StyledTableCell>
                                                                        <StyledTableCell>Final Discharge Quantity (KL)</StyledTableCell>
                                                                        <StyledTableCell>Discharge Point</StyledTableCell>
                                                                        <StyledTableCell>From Date</StyledTableCell>
                                                                        <StyledTableCell>To Date</StyledTableCell>
                                                                        <StyledTableCell>Approval Date</StyledTableCell>
                                                                        <StyledTableCell>Status</StyledTableCell>
                                                                    </material.TableRow>
                                                                </material.TableHead>
                                                                <material.TableBody>
                                                                    {wasteWaterTreatmentReuseTableData.length ? wasteWaterTreatmentReuseTableData.map((item, k) => (
                                                                        <material.TableRow key={k}>
                                                                            <material.TableCell>{item.output_source}</material.TableCell>
                                                                            <material.TableCell>{item.level_of_treatment}</material.TableCell>
                                                                            <material.TableCell>{(item.category === "" || item.category === null) ? "N/A" : item.category}</material.TableCell>
                                                                            <material.TableCell>{(item.quantity_reused_or_recycled === "" || item.quantity_reused_or_recycled === null) ? "N/A" : item.quantity_reused_or_recycled}</material.TableCell>
                                                                            <material.TableCell>{(item.purpose_of_reuse_or_recycle === "" || item.purpose_of_reuse_or_recycle === null) ? "N/A" : item.purpose_of_reuse_or_recycle}</material.TableCell>
                                                                            <material.TableCell>{item.final_discharge}</material.TableCell>
                                                                            <material.TableCell>{item.discharge_point}</material.TableCell>
                                                                            <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell>
                                                                                {item.approved_status === "APPROVED" ? <span className="badge bg-success">
                                                                                    APPROVED
                                                                                </span> : null}
                                                                            </material.TableCell>
                                                                        </material.TableRow>
                                                                    )) : (
                                                                        <material.TableRow >
                                                                            <material.TableCell colSpan={12}>
                                                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                            </material.TableCell>
                                                                        </material.TableRow>
                                                                    )}
                                                                </material.TableBody>
                                                            </material.Table>
                                                        </material.TableContainer>
                                                        <Pagination
                                                            getPaginationData={getPaginationData}
                                                            totalElements={totalElements}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            getFilterDate={getFilterDate}
                                                        />
                                                    </div>
                                                </div>
                                            </material.Paper>
                                        </material.AccordionDetails>
                                    </material.Accordion>
                                    <material.Accordion className='mb-3'>
                                        <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                                            <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Water Discharge Effluent Destination</material.Typography>
                                        </material.AccordionSummary>
                                        <material.AccordionDetails>
                                            <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <material.TableContainer>
                                                            <material.Table>
                                                                <material.TableHead>
                                                                    <material.TableRow>
                                                                        <StyledTableCell>Discharge Point</StyledTableCell>
                                                                        <StyledTableCell>Discharge Location (latitude)</StyledTableCell>
                                                                        <StyledTableCell>Discharge Location (longitude)</StyledTableCell>
                                                                        <StyledTableCell>Discharge Destination</StyledTableCell>
                                                                        <StyledTableCell>Discharge Destination Type</StyledTableCell>
                                                                        <StyledTableCell>Destination Category</StyledTableCell>
                                                                        <StyledTableCell>From Date</StyledTableCell>
                                                                        <StyledTableCell>To Date</StyledTableCell>
                                                                        <StyledTableCell>Approval Date</StyledTableCell>
                                                                        <StyledTableCell>Status</StyledTableCell>
                                                                    </material.TableRow>
                                                                </material.TableHead>
                                                                <material.TableBody>
                                                                    {wasteWaterTreatmentReuseTableData.length ? wasteWaterTreatmentReuseTableData.map((item, k) => (
                                                                        <material.TableRow key={k}>
                                                                            <material.TableCell>{item.discharge_point}</material.TableCell>
                                                                            <material.TableCell>{item.discharge_location_lat}</material.TableCell>
                                                                            <material.TableCell>{item.discharge_location_long}</material.TableCell>
                                                                            <material.TableCell>{item.discharge_destination}</material.TableCell>
                                                                            <material.TableCell>{item.discharge_destination_type}</material.TableCell>
                                                                            <material.TableCell>{item.destination_category}</material.TableCell>
                                                                            <material.TableCell>{item.from_date ? moment(item.from_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell>{item.to_date ? moment(item.to_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell>{item.approved_date ? moment(item.approved_date).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                            <material.TableCell>
                                                                                {item.approved_status === "APPROVED" ? <span className="badge bg-success">
                                                                                    APPROVED
                                                                                </span> : null}
                                                                            </material.TableCell>
                                                                        </material.TableRow>
                                                                    )) : (
                                                                        <material.TableRow >
                                                                            <material.TableCell colSpan={12}>
                                                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                            </material.TableCell>
                                                                        </material.TableRow>
                                                                    )}
                                                                </material.TableBody>
                                                            </material.Table>
                                                        </material.TableContainer>
                                                        <Pagination
                                                            getPaginationData={getPaginationData}
                                                            totalElements={totalElements}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            getFilterDate={getFilterDate}
                                                        />
                                                    </div>
                                                </div>
                                            </material.Paper>
                                        </material.AccordionDetails>
                                    </material.Accordion>
                                </material.TabPanel>
                            </material.TabContext>
                        </material.Box>
                    </div>
                </material.Box >
            </material.Box >
            <AddWaterTreatmentReuse
                openWasteWaterTreatmentReuse={openWasteWaterTreatmentReuse}
                setOpenWasteWaterTreatmentReuse={setOpenWasteWaterTreatmentReuse}
                getAllWasteWaterTreatmentReuse={getAllWasteWaterTreatmentReuse}
                treeViewData={treeViewData}
                page={page}
                rowsPerPage={rowsPerPage}
                getFilterDate={getFilterDate}
            />
            <ApplicableDialog
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getApplicableData={getApplicableData}
                applicable={applicable}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </div >
    )
}

export default WaterTreatmentReuse;