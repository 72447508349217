import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import moment from 'moment';


function AddEntryLevelWage(props) {

    const { openEntryLevelWage, setOpenEntryLevelWage, treeViewData, getAllEntryLevelWage,page, rowsPerPage, getFilterDate } = props;

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [categoryEmployeeWorker, setCategoryEmployeeWorker] = useState('');
    const [gender, setGender] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);


    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openEntryLevelWage.action && openEntryLevelWage.entryLevelWageTableData) {
            setCategoryEmployeeWorker(openEntryLevelWage.entryLevelWageTableData.category_of_employees_or_worker);
            setGender(openEntryLevelWage.entryLevelWageTableData.gender);
            reset(openEntryLevelWage.entryLevelWageTableData);
        }
    }, [openEntryLevelWage.action === true]);

    const handleClose = () => {
        reset({ equal_to_minimum_wage: "", more_than_to_minimum_wage: "", });
        setCategoryEmployeeWorker("");
        setGender("");
        setOpenEntryLevelWage({ action: false, entryLevelWageTableData: null });
    };

    const saveEntryLevelWage = async (data) => {
        console.log(data)
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "category_of_employees_or_worker": data.category_of_employees_or_worker,
                "gender": data.gender,
                "equal_to_minimum_wage": data.equal_to_minimum_wage,
                "more_than_to_minimum_wage": data.more_than_to_minimum_wage,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllEntryLevelWage(getFilterDate, payloadData);
                    reset({ equal_to_minimum_wage: "", more_than_to_minimum_wage: "", });
                    setCategoryEmployeeWorker("");
                    setGender("");
                    setOpenEntryLevelWage({ action: false, entryLevelWageTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateEntryLevelWage = async (data) => {
        const refNo = openEntryLevelWage.entryLevelWageTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "category_of_employees_or_worker": data.category_of_employees_or_worker,
            "gender": data.gender,
            "equal_to_minimum_wage": data.equal_to_minimum_wage,
            "more_than_to_minimum_wage": data.more_than_to_minimum_wage,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllEntryLevelWage(getFilterDate, payloadData);
                reset({ equal_to_minimum_wage: "", more_than_to_minimum_wage: "", });
                setCategoryEmployeeWorker("");
                setGender("");
                setOpenEntryLevelWage({ action: false, entryLevelWageTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const categoryOfEmployeeWorker = (event) => {
        setCategoryEmployeeWorker(event.target.value);
    };
    const selectGender = (event) => {
        setGender(event.target.value);
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        const payload = {
            refNo: openEntryLevelWage.entryLevelWageTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ equal_to_minimum_wage: "", more_than_to_minimum_wage: "", });
                setCategoryEmployeeWorker("");
                setGender("");
                getAllEntryLevelWage(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenEntryLevelWage({ action: false, entryLevelWageTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openEntryLevelWage.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openEntryLevelWage.entryLevelWageTableData ? "Edit Entry Level Wage" : "Add Entry Level Wage"}</material.DialogTitle>
                <material.DialogContent>
                    {openEntryLevelWage.entryLevelWageTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Entry Level Wage</material.Typography>
                            </div>
                            <div className='col-6 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Category of Employee & Worker</material.InputLabel>
                                    <material.Select
                                        {...register("category_of_employees_or_worker", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={categoryEmployeeWorker}
                                        onChange={categoryOfEmployeeWorker}
                                        label="Category of Employee & Worker"
                                    >
                                        <material.MenuItem value={"Employee Permanent"}>Employee Permanent</material.MenuItem>
                                        <material.MenuItem value={"Employee other than Permanent"}>Employee other than Permanent</material.MenuItem>
                                        <material.MenuItem value={"Worker Permanent"}>Worker Permanent</material.MenuItem>
                                        <material.MenuItem value={"Worker other than Permanent"}>Worker other than Permanent</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Gender</material.InputLabel>
                                    <material.Select
                                        {...register("gender", { required: true, })}
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={gender}
                                        onChange={selectGender}
                                        label="Gender"
                                    >
                                        <material.MenuItem value={"Male"}>Male</material.MenuItem>
                                        <material.MenuItem value={"Female"}>Female</material.MenuItem>
                                        <material.MenuItem value={"Others"}>Others</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 py-2'>
                                <material.TextField
                                    {...register("equal_to_minimum_wage", { required: true,pattern: /^[0-9]+$/})}
                                    required
                                    label="Equal to Minimum Wage"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                 {errors.equal_to_minimum_wage && errors.equal_to_minimum_wage.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-6 py-2'>
                                <material.TextField
                                    {...register("more_than_to_minimum_wage", { required: true,pattern: /^[0-9]+$/})}
                                    required
                                    label="More than Minimum Wage"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                 {errors.more_than_to_minimum_wage && errors.more_than_to_minimum_wage.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openEntryLevelWage.entryLevelWageTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateEntryLevelWage)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openEntryLevelWage.entryLevelWageTableData.rejection_note === null) || (openEntryLevelWage.entryLevelWageTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveEntryLevelWage)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddEntryLevelWage;