import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import Snackbar from '../../toaster-message/Snackbar';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import DatePeriod from '../../shared/DatePeriod';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import moment from 'moment';
import { getSiteByRefNo } from '../../services/SiteService';


const categoryChangeData = [
    "Total Amount of Sales",
    "Total Amount of Loans & Advances",
    "Total Amount of Investments",
    "Amount of Sales to Dealers / Distributors ",
    "Amount of Sales to Top 10 Dealers / Distributors ",
    "Amount of Loans & Advance to Parties",
    "Amount of Investments to Parties"
];

function AddOpenNessOfBusinessSales(props) {

    const { openNessOfBusinessSales, setOpenNessOfBusinessSales, getAllNessOfBusinessSales, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, setValue, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [categoryData, setCategoryData] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [currencyCode, setCurrencyCode] = useState(true);

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openNessOfBusinessSales.action && openNessOfBusinessSales.nessOfBusinessSalesTableData) {
            setCategoryData(openNessOfBusinessSales.nessOfBusinessSalesTableData.category);
            reset(openNessOfBusinessSales.nessOfBusinessSalesTableData);
        };
        if (openNessOfBusinessSales.action) {
            getFacilityDetails();
        };
    }, [openNessOfBusinessSales.action === true]);

    const changeCategory = (event) => {
        setCategoryData(event.target.value);
    };

    const handleClose = () => {
        reset({ number_of_dealers_or_distributors: "", amount: "", currency_code: "", });
        setCategoryData("");
        setOpenNessOfBusinessSales({ action: false, nessOfBusinessSalesTableData: null });
    };

    const getFacilityDetails = () => {
        getSiteByRefNo(userDetails.facilityRefNo)
            .then((resp) => {
                setValue("currency_code", resp.data.turnOverUnit);
                if (resp.data.turnOverUnit) {
                    setCurrencyCode(true)
                } else {
                    setCurrencyCode(false);
                    setOpenSnackBar({
                        "action": true,
                        "type": "warning",
                        "message": "Currency code is not present",
                    })
                };
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const saveNessOfBusinessSales = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "number_of_dealers_or_distributors": data.number_of_dealers_or_distributors,
                "category": data.category,
                "amount": data.amount,
                "currency_code": data.currency_code,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllNessOfBusinessSales(getFilterDate, payloadData);
                    reset({ number_of_dealers_or_distributors: "", amount: "", currency_code: "", });
                    setCategoryData("");
                    setOpenNessOfBusinessSales({ action: false, nessOfBusinessSalesTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateNessOfBusinessSales = async (data) => {
        const refNo = openNessOfBusinessSales.nessOfBusinessSalesTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "number_of_dealers_or_distributors": data.number_of_dealers_or_distributors,
            "category": data.category,
            "amount": data.amount,
            "currency_code": data.currency_code,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllNessOfBusinessSales(getFilterDate, payloadData);
                reset({ number_of_dealers_or_distributors: "", amount: "", currency_code: "", });
                setCategoryData("");
                setOpenNessOfBusinessSales({ action: false, nessOfBusinessSalesTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openNessOfBusinessSales.nessOfBusinessSalesTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ number_of_dealers_or_distributors: "", amount: "", currency_code: "", });
                setCategoryData("");
                getAllNessOfBusinessSales(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenNessOfBusinessSales({ action: false, nessOfBusinessSalesTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openNessOfBusinessSales.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openNessOfBusinessSales.nessOfBusinessSalesTableData ? "Edit Open Ness of Business Sales" : "Add Open Ness of Business Sales"}</material.DialogTitle>
                <material.DialogContent>
                    {openNessOfBusinessSales.nessOfBusinessSalesTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Open Ness of Business Sales</material.Typography>
                            </div>
                            <div className='col-6 py-2'>
                                <material.TextField
                                    {...register("number_of_dealers_or_distributors", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number of Dealers / Distributors"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                    disabled={!currencyCode}
                                />
                                <p className='form-text text-danger'>{errors.number_of_dealers_or_distributors?.type === "required" && 'This field is required'}</p>
                                {errors.number_of_dealers_or_distributors && errors.number_of_dealers_or_distributors.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-6 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Category</material.InputLabel>
                                    <material.Select
                                        {...register("category", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={categoryData}
                                        onChange={changeCategory}
                                        label="Category"
                                        disabled={!currencyCode}
                                    >
                                        {categoryChangeData.map((ele, ind) => (
                                            <material.MenuItem key={ind} value={ele}>{ele}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 py-2'>
                                <material.TextField
                                    {...register("amount", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    required
                                    label="Amount (in Million)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                    disabled={!currencyCode}
                                />
                                <p className='form-text text-danger'>{errors.amount?.type === "required" && 'This field is required'}</p>
                                {errors.amount && errors.amount.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6th digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-6 py-2'>
                                <material.TextField
                                    {...register("currency_code", { required: true, })}
                                    required
                                    label="Currency Code"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={!currencyCode}
                                />
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openNessOfBusinessSales.nessOfBusinessSalesTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateNessOfBusinessSales)} startIcon={<material.DoneIcon />} disabled={!currencyCode}>Update</material.Button>
                            {(openNessOfBusinessSales.nessOfBusinessSalesTableData.rejection_note === null) || (openNessOfBusinessSales.nessOfBusinessSalesTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveNessOfBusinessSales)} startIcon={<material.DoneIcon />} disabled={!currencyCode}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddOpenNessOfBusinessSales