import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from "react-hook-form";
import { saveOrUpdateCoverageOfTraining } from '../../services/sub-issue-services/CoverageOfTrainingService';
import { useSelector } from "react-redux";
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';


function AddCoverageOfTraining(props) {

  const { openAddCoverageOfTraining, setOpenAddCoverageOfTraining, getCoverageOfTrainingData, treeViewData, page, rowsPerPage, getFilterDate } = props;
  const userDetails = useSelector((state) => state.LoginSlice.data);
  const [categoryOfEmployees, setCategoryOfEmployees] = React.useState("");
  const [trainingTopicsEmployees, setTrainingTopicsEmployees] = useState("");
  const [gender, setGender] = useState("");
  const [typeOfTraining, setTypeOfTraining] = useState("")
  const { register, handleSubmit, reset, control, resetField, setValue, formState: { errors, isValid }, } = useForm({ mode: "onTouched" });
  const [openSnackBar, setOpenSnackBar] = useState({
    "action": false,
    "type": "",
    "message": "",
  });
  const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

  const payload = {
    page,
    size: rowsPerPage
  };

  useEffect(() => {
    if (openAddCoverageOfTraining.action && openAddCoverageOfTraining.coverageOfTrainingAllData) {
      reset(openAddCoverageOfTraining.coverageOfTrainingAllData);
      setCategoryOfEmployees(openAddCoverageOfTraining.coverageOfTrainingAllData.category_of_worker_or_employee)
      setTrainingTopicsEmployees(openAddCoverageOfTraining.coverageOfTrainingAllData.training_topic)
      setTypeOfTraining(openAddCoverageOfTraining.coverageOfTrainingAllData.type_of_training)
      setGender(openAddCoverageOfTraining.coverageOfTrainingAllData.gender)
    }
  }, [openAddCoverageOfTraining.action === true]);

  const handleSelectCategoryOfEmployees = (event) => {
    setCategoryOfEmployees(event.target.value);
  };

  const handleSelectTrainingTopicsEmployees = (event) => {
    setTrainingTopicsEmployees(event.target.value);
  };

  const handleChangeGender = (event) => {
    setGender(event.target.value)
  };

  const handleChangeTraining = (event) => {
    setTypeOfTraining(event.target.value)
  };

  const handleClose = () => {
    setCategoryOfEmployees("")
    setTrainingTopicsEmployees("")
    setTypeOfTraining("")
    setGender("")
    reset({ number_of_workers_or_employees_receiving_training: "", duration_of_training: "", other_specify: "", })
    setOpenAddCoverageOfTraining({ action: false, coverageOfTrainingAllData: null });
  };

  const saveCoverageOfTraining = async (formData) => {
    let payLoad = generateFromDateAndToDate(formData);
    let newDate = moment().format("YYYY-MM-DD");
    let subissueName = (treeViewData?.subIssue)
    if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
      let payloadData = {
        "corporate_ref_no": userDetails.corporateRefNo,
        "facility_ref_no": userDetails.facilityRefNo,
        "category_of_worker_or_employee": formData.category_of_worker_or_employee,
        "gender": formData.gender,
        "type_of_training": formData.type_of_training,
        "training_topic": formData.training_topic,
        "other_specify": trainingTopicsEmployees === "Other (Specify)" ? formData.other_specify : "",
        "number_of_workers_or_employees_receiving_training": formData.number_of_workers_or_employees_receiving_training,
        "duration_of_training": formData.duration_of_training,
        "applicable_record": false,
        "applicable": true,
        "from_date": payLoad?.fromDate,
        "to_date": payLoad?.toDate,
      }
      await saveSubIssueData(payloadData, subissueName)
        .then((resp) => {
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
          getCoverageOfTrainingData(getFilterDate, payload);
          setCategoryOfEmployees("")
          setTrainingTopicsEmployees("")
          setTypeOfTraining("")
          setGender("")
          reset({ number_of_workers_or_employees_receiving_training: "", duration_of_training: "", other_specify: "", })
          setOpenAddCoverageOfTraining({ action: false, coverageOfTrainingAllData: null });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
            })
          };
        })
    } else {
      setOpenSnackBar({
        "action": true,
        "type": "warning",
        "message": "You can't add future records",
      })
    };
  };

  const updateCoverageOfTraining = async (formData) => {
    const refNo = openAddCoverageOfTraining.coverageOfTrainingAllData.ref_no;
    const subIssueName = treeViewData?.subIssue;
    let payloadData = {
      "category_of_worker_or_employee": formData.category_of_worker_or_employee,
      "gender": formData.gender,
      "type_of_training": formData.type_of_training,
      "training_topic": formData.training_topic,
      "other_specify": trainingTopicsEmployees === "Other (Specify)" ? formData.other_specify : "",
      "number_of_workers_or_employees_receiving_training": formData.number_of_workers_or_employees_receiving_training,
      "duration_of_training": formData.duration_of_training,
    }
    await updateSubIssueData(payloadData, refNo, subIssueName)
      .then((resp) => {
        setOpenSnackBar({
          "action": true,
          "type": "success",
          "message": resp.data,
        })
        getCoverageOfTrainingData(getFilterDate, payload);
        setCategoryOfEmployees("")
        setTrainingTopicsEmployees("")
        setTypeOfTraining("")
        setGender("")
        reset({ number_of_workers_or_employees_receiving_training: "", duration_of_training: "", other_specify: "", })
        setOpenAddCoverageOfTraining({ action: false, coverageOfTrainingAllData: null });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "You are not authorized to perform this action. Redirected to login",
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
          })
        };
      })
  };

  const deleteConfirmation = () => {
    setOpenDeleteRecordConfirmationDialog(true);
  };

  const deleteRecordByRefNo = () => {
    const payloadData = {
      refNo: openAddCoverageOfTraining.coverageOfTrainingAllData.ref_no,
      subissueName: treeViewData.subIssue,
    }
    commonDelete(payloadData)
      .then((resp) => {
        setOpenSnackBar({
          "action": true,
          "type": "success",
          "message": resp.data,
        })
        getCoverageOfTrainingData(getFilterDate, payload);
        setCategoryOfEmployees("")
        setTrainingTopicsEmployees("")
        setTypeOfTraining("")
        setGender("")
        reset({ number_of_workers_or_employees_receiving_training: "", duration_of_training: "", other_specify: "", });
        setOpenDeleteRecordConfirmationDialog(false);
        setOpenAddCoverageOfTraining({ action: false, coverageOfTrainingAllData: null });
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "You are not authorized to perform this action. Redirected to login",
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
          })
        }
      })
  };

  return (
    <div>
      <material.Dialog open={openAddCoverageOfTraining.action} maxWidth="md" fullWidth >
        <material.DialogTitle className='fw-bold headingText'>{openAddCoverageOfTraining.coverageOfTrainingAllData ? "Edit Coverage of Training" : "Add Coverage of Training"}
        </material.DialogTitle>
        <material.DialogContent>
          <div className='my-3'>
            {openAddCoverageOfTraining.coverageOfTrainingAllData ? null : (
              <DatePeriod
                register={register}
                control={control}
              />
            )}
          </div>
          <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
            <div className='row'>
              <div className='py-1'>
                <material.Typography className='fw-bold'>Coverage of Training</material.Typography>
              </div>
              <div className='col-4'>
                <material.Box>
                  <material.FormControl required fullWidth margin="dense">
                    <material.InputLabel id="demo-simple-select-label" variant="standard">Category of Worker / Employee</material.InputLabel>
                    <material.Select
                      {...register("category_of_worker_or_employee", { required: true })}
                      variant='standard'
                      margin="dense"
                      id="categoryOfWorkerEmployee"
                      value={categoryOfEmployees}
                      label="Category of employees"
                      onChange={handleSelectCategoryOfEmployees}
                    >
                      <material.MenuItem value={"Board of Directors"}>Board of Directors</material.MenuItem>
                      <material.MenuItem value={"Key Managerial Personnel"}>Key Managerial Personnel</material.MenuItem>
                      <material.MenuItem value={"Governing Body"}>Governing Body</material.MenuItem>
                      <material.MenuItem value={"Employee Permanent"}>Employee Permanent</material.MenuItem>
                      <material.MenuItem value={"Employee other than Permanent"}>Employee other than Permanent</material.MenuItem>
                      <material.MenuItem value={"Worker Permanent"}>Worker Permanent</material.MenuItem>
                      <material.MenuItem value={"Worker other than Permanent"}>Worker other than Permanent</material.MenuItem>
                    </material.Select>
                  </material.FormControl>
                </material.Box>
              </div>
              <div className='col-4'>
                <material.Box>
                  <material.FormControl required fullWidth margin="dense">
                    <material.InputLabel id="demo-simple-select-label" variant="standard">Gender</material.InputLabel>
                    <material.Select
                      {...register("gender", { required: true })}
                      variant='standard'
                      margin="dense"
                      id="gender"
                      value={gender}
                      onChange={handleChangeGender}
                    >
                      <material.MenuItem value={"Male"}>Male</material.MenuItem>
                      <material.MenuItem value={"Female"}>Female</material.MenuItem>
                      <material.MenuItem value={"Other"}>Other</material.MenuItem>
                    </material.Select>
                  </material.FormControl>
                </material.Box>
              </div>
              <div className='col-4'>
                <material.Box>
                  <material.FormControl required fullWidth margin="dense">
                    <material.InputLabel id="demo-simple-select-label" variant="standard">Type of Training</material.InputLabel>
                    <material.Select
                      {...register("type_of_training", { required: true })}
                      variant='standard'
                      margin="dense"
                      id="typeOfTraining"
                      value={typeOfTraining}
                      onChange={handleChangeTraining}
                    >
                      <material.MenuItem value={"Paid Educational Leave Externally"}>Paid Educational Leave Externally</material.MenuItem>
                      <material.MenuItem value={"Paid Educational Leave Internally"}>Paid Educational Leave Internally</material.MenuItem>
                      <material.MenuItem value={"Other"}>Other</material.MenuItem>
                    </material.Select>
                  </material.FormControl>
                </material.Box>
              </div>
              <div className='col-4 mt-2'>
                <material.Box>
                  <material.FormControl required fullWidth margin="dense">
                    <material.InputLabel id="demo-simple-select-label" variant="standard">Training Topics Employees</material.InputLabel>
                    <material.Select
                      {...register("training_topic", { required: true })}
                      variant='standard'
                      margin="dense"
                      id="trainingTopic"
                      value={trainingTopicsEmployees}
                      label="Training topics"
                      onChange={handleSelectTrainingTopicsEmployees}
                    >
                      <material.MenuItem value={"Health and Safety Measures"}>Health and Safety Measures</material.MenuItem>
                      <material.MenuItem value={"Human Rights Issues"}>Human Rights Issues</material.MenuItem>
                      <material.MenuItem value={"Policy of the Entity"}>Policy of the Entity</material.MenuItem>
                      <material.MenuItem value={"Skill Upgradation"}>Skill Upgradation</material.MenuItem>
                      <material.MenuItem value={"ESG"}>ESG</material.MenuItem>
                      <material.MenuItem value={"Training on Anti Corruption"}>Training on Anti Corruption</material.MenuItem>
                      <material.MenuItem value={"Other (Specify)"}>Other (Specify)</material.MenuItem>
                    </material.Select>
                  </material.FormControl>
                </material.Box>
              </div>
              {trainingTopicsEmployees === "Other (Specify)" ? (
                <div className='col-4 mt-2'>
                  <material.TextField
                    {...register("other_specify", { required: true, maxLength: 20 })}
                    required
                    margin="dense"
                    label="Other Training Topics Specify"
                    id="othersTrainingTopics"
                    variant="standard"
                    type="text"
                    size="small"
                    fullWidth
                  />
                  <p className='form-text text-danger'>{errors.other_specify?.type === "required" && 'This field is required'}</p>
                  {(errors?.other_specify?.type === "maxLength") && (
                    <p className='text-danger'>Maximum 20 characters</p>
                  )}
                </div>
              ) : null}
              <div className='col-4 mt-2'>
                <material.TextField
                  {...register("number_of_workers_or_employees_receiving_training", { required: true, pattern: /^[0-9]+$/ })}
                  required
                  margin="dense"
                  label="Number of Worker / Employees Received Training"
                  id="number_of_workers_or_employees_receiving_training"
                  variant="standard"
                  type="number"
                  size="small"
                  fullWidth
                />
                {errors.number_of_workers_or_employees_receiving_training && errors.number_of_workers_or_employees_receiving_training.type === "pattern" && (
                  <p className="errorMsg text-danger">No decimal allowed</p>
                )}
              </div>
              <div className='col-4 mt-2'>
                <material.TextField
                  {...register("duration_of_training", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                  required
                  margin="dense"
                  label="Duration of Training Employee (hr)"
                  id="duration_of_training"
                  variant="standard"
                  type="number"
                  size="small"
                  fullWidth
                />
                {errors.duration_of_training && errors.duration_of_training.type === "pattern" && (
                  <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                )}
              </div>
            </div>
            {/* <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
              <div className='row mt-2'>
                <div className='py-2'>
                  <material.Typography className='fw-bold'>Worker</material.Typography>
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("workerCategory", { required: true })}
                    required
                    label="Category of worker"
                    id="workerNumberOfTrainees"
                    variant="standard"
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("workerTrainingTopic", { required: true })}
                    required
                    label="Training topics worker"
                    id="workerNumberOfTrainees"
                    variant="standard"
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("workerNumberOfTrainees", { required: true, pattern: /^[0-9]+$/ })}
                    required
                    label="Number of worker received training"
                    id="workerNumberOfTrainees"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                  />
                  {errors.workerNumberOfTrainees && errors.workerNumberOfTrainees.type === "pattern" && (
                    <p className="errorMsg text-danger">No decimal allowed</p>
                  )}
                </div>
                <div className='col-lg-4 col-md-6'>
                  <material.TextField
                    {...register("workerDurationOfTraining", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                    required
                    margin="dense"
                    label="Duration  of training worker (hr)"
                    id="workerDurationOfTraining"
                    variant="standard"
                    type="number"
                    size="small"
                    fullWidth
                  />
                  {errors.workerDurationOfTraining && errors.workerDurationOfTraining.type === "pattern" && (
                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                  )}
                </div>
              </div>
            </material.Card> */}
            {/* </form> */}
          </material.Card>
        </material.DialogContent>
        <material.DialogActions className="m-3">
          <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
          {openAddCoverageOfTraining.coverageOfTrainingAllData ? (
            <>
              <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateCoverageOfTraining)} startIcon={<material.DoneIcon />}>Update</material.Button>
              {(openAddCoverageOfTraining.coverageOfTrainingAllData.rejection_note === null) || (openAddCoverageOfTraining.coverageOfTrainingAllData.rejection_note === "") ? (
                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
              ) : null}
            </>
          ) : (
            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveCoverageOfTraining)} startIcon={<material.DoneIcon />}>Save</material.Button>
          )}
        </material.DialogActions>
      </material.Dialog>
      <Snackbar
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
      />
      <DeleteRecordConfirmationDialog
        openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
        setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
        treeViewData={treeViewData}
        deleteRecordByRefNo={deleteRecordByRefNo}
      />
    </div>
  )
}

export default AddCoverageOfTraining;