import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import DatePeriod from '../../shared/DatePeriod';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';

function AddNonComplianceProductOrService(props) {
    const { openAddNonComplianceProductOrService, setOpenAddNonComplianceProductOrService, getAllNonComplianceProductOrService, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [nonComplianceAspect, setNonComplianceAspect] = useState("");
    const [natureOfNonCompliance, setNatureOfNonCompliance] = useState("");
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openAddNonComplianceProductOrService.action && openAddNonComplianceProductOrService.nonComplianceProductOrServiceTableData) {
            setNonComplianceAspect(openAddNonComplianceProductOrService.nonComplianceProductOrServiceTableData.non_compliance_aspect);
            setNatureOfNonCompliance(openAddNonComplianceProductOrService.nonComplianceProductOrServiceTableData.nature_of_non_compliance);
            reset(openAddNonComplianceProductOrService.nonComplianceProductOrServiceTableData);
        }
    }, [openAddNonComplianceProductOrService.action === true]);

    const selectNonComplianceAspect = (event) => {
        setNonComplianceAspect(event.target.value);
    };

    const selectNatureOfNonCompliance = (event) => {
        setNatureOfNonCompliance(event.target.value);
    };

    const handleClose = () => {
        reset({ number_of_non_compliance: "", });
        setNonComplianceAspect("");
        setNatureOfNonCompliance("");
        setOpenAddNonComplianceProductOrService({ action: false, nonComplianceProductOrServiceTableData: null });
    };

    const submitNonComplianceProductOrService = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "non_compliance_aspect": data.non_compliance_aspect,
                "nature_of_non_compliance": data.nature_of_non_compliance,
                "number_of_non_compliance": data.number_of_non_compliance,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllNonComplianceProductOrService(getFilterDate, payloadData);
                    reset({ number_of_non_compliance: "", });
                    setNonComplianceAspect("");
                    setNatureOfNonCompliance("");
                    setOpenAddNonComplianceProductOrService({ action: false, nonComplianceProductOrServiceTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateNonComplianceProductOrService = async (data) => {
        const refNo = openAddNonComplianceProductOrService.nonComplianceProductOrServiceTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "non_compliance_aspect": data.non_compliance_aspect,
            "nature_of_non_compliance": data.nature_of_non_compliance,
            "number_of_non_compliance": data.number_of_non_compliance,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllNonComplianceProductOrService(getFilterDate, payloadData);
                reset({ number_of_non_compliance: "", });
                setNonComplianceAspect("");
                setNatureOfNonCompliance("");
                setOpenAddNonComplianceProductOrService({ action: false, nonComplianceProductOrServiceTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAddNonComplianceProductOrService.nonComplianceProductOrServiceTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ number_of_non_compliance: "", });
                setNonComplianceAspect("");
                setNatureOfNonCompliance("");
                getAllNonComplianceProductOrService(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddNonComplianceProductOrService({ action: false, nonComplianceProductOrServiceTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddNonComplianceProductOrService.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddNonComplianceProductOrService.nonComplianceProductOrServiceTableData ? "Edit Non Compliance Product or Service" : "Add Non Compliance Product or Service"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddNonComplianceProductOrService.nonComplianceProductOrServiceTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Non Compliance Product or Service</material.Typography>
                            </div>
                            <div>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Non-compliance Aspect</material.InputLabel>
                                    <material.Select
                                        {...register("non_compliance_aspect", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={nonComplianceAspect}
                                        onChange={selectNonComplianceAspect}
                                        label="Material Sourced"
                                    >
                                        <material.MenuItem value={"Product and Service Information and Labeling"}>Product and Service Information and Labeling</material.MenuItem>
                                        <material.MenuItem value={"Marketing Communications"}>Marketing Communications</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='mt-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Nature of Non-compliance</material.InputLabel>
                                    <material.Select
                                        {...register("nature_of_non_compliance", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={natureOfNonCompliance}
                                        onChange={selectNatureOfNonCompliance}
                                        label="Material Sourced"
                                    >
                                        <material.MenuItem value={"Incidents of Non-compliance with Regulations Resulting in a Fine or Penalty"}>Incidents of Non-compliance with Regulations Resulting in a Fine or Penalty</material.MenuItem>
                                        <material.MenuItem value={"Incidents of Non-compliance with Regulations Resulting in a Warning"}>Incidents of Non-compliance with Regulations Resulting in a Warning</material.MenuItem>
                                        <material.MenuItem value={"Incidents of Non-compliance with Voluntary Codes"}>Incidents of Non-compliance with Voluntary Codes</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='mt-2'>
                                <material.TextField
                                    {...register("number_of_non_compliance", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number of Non-compliance"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.number_of_non_compliance && errors.number_of_non_compliance.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number </p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddNonComplianceProductOrService.nonComplianceProductOrServiceTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateNonComplianceProductOrService)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddNonComplianceProductOrService.nonComplianceProductOrServiceTableData.rejection_note === null) || (openAddNonComplianceProductOrService.nonComplianceProductOrServiceTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(submitNonComplianceProductOrService)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddNonComplianceProductOrService;