import moment from "moment";

export const dataPeriod = ['Monthly', 'Quarterly', 'Half Yearly', 'Yearly'];
export const quarterData = [
    { name: 'Quarter-1', value: 2 },
    { name: 'Quarter-2', value: 3 },
    { name: 'Quarter-3', value: 4 },
    { name: 'Quarter-4', value: 1 }
];
export const halfYearPeriod = [
    { name: '1st-half', value: 1 },
    { name: '2nd-half', value: 2 }
];
export const months = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 },
];

export const quarterDataForDashboard = [
    { name: 'Quarter-1', value: 1 },
    { name: 'Quarter-2', value: 2 },
    { name: 'Quarter-3', value: 3 },
    { name: 'Quarter-4', value: 4 }
];

const getQuarterDates = (year, selectedQuarter) => {
    if (selectedQuarter < 1 || selectedQuarter > 4) {
        throw new Error('Invalid quarter number. Must be between 1 and 4.');
    };

    const startMonth = (selectedQuarter - 1) * 3 + 1;
    const endMonth = startMonth + 2;

    const quarterStartDate = new Date(year, startMonth - 1, 1);
    const quarterEndDate = new Date(year, endMonth, 0);

    return {
        // quarter: selectedQuarter,
        fromDate: quarterStartDate,
        toDate: quarterEndDate
    };
};

const getHalfYearDates = (year, isFirstHalf) => {
    if (isFirstHalf !== true && isFirstHalf !== false) {
        throw new Error('Invalid value for isFirstHalf parameter. Must be true or false.');
    };

    const startMonth = isFirstHalf ? 3 : 9;
    const endMonth = startMonth + 6;

    const halfYearStartDate = new Date(year, startMonth, 1);
    const halfYearEndDate = new Date(year, endMonth, 0);

    return {
        fromDate: halfYearStartDate,
        toDate: halfYearEndDate
    };
};

const getMonthDates = (year, month) => {
    if (month < 1 || month > 12) {
        throw new Error('Invalid month number. Must be between 1 and 12.');
    };

    const monthStartDate = new Date(year, month - 1, 1);
    const nextMonthStartDate = new Date(year, month, 1);
    const monthEndDate = new Date(nextMonthStartDate - 1);
    return {
        fromDate: monthStartDate,
        toDate: monthEndDate
    };
};

const getYearDates = (year) => {
    const yearStartDate = new Date(year, 3, 1);
    const nextYearStartDate = new Date(year + 1, 3, 1);
    const yearEndDate = new Date(nextYearStartDate - 1);
    return {
        fromDate: yearStartDate,
        toDate: yearEndDate
    };
};

export const generateFromDateAndToDate = (formData) => {
    if (formData.periodType === 'Monthly') {
        let month = Number(formData.selectedMonth);
        let year = Number(formData.selectedYear?.$y);
        let dateObj = getMonthDates(year, month);
        let toDate = moment(dateObj.toDate).format("YYYY-MM-DD");
        let fromDate = moment(dateObj.fromDate).format("YYYY-MM-DD");
        let payload = { toDate: toDate, fromDate: fromDate };
        return payload;
    } else if (formData.periodType === 'Quarterly') {
        let selectedQuarter = Number(formData.selectedQuarter);
        let year = Number(formData.selectedYear?.$y);
        let dateObj = getQuarterDates(year, selectedQuarter);
        let toDate = moment(dateObj.toDate).format("YYYY-MM-DD");
        let fromDate = moment(dateObj.fromDate).format("YYYY-MM-DD");
        let payload = { toDate: toDate, fromDate: fromDate };
        return payload;
    } else if (formData.periodType === 'Half Yearly') {
        let halfYearType = Number(formData.halfYearType);
        let isFirstHalf = halfYearType === 1 ? true : false;
        let year = Number(formData.selectedYear?.$y);
        let dateObj = getHalfYearDates(year, isFirstHalf);
        let toDate = moment(dateObj.toDate).format("YYYY-MM-DD");
        let fromDate = moment(dateObj.fromDate).format("YYYY-MM-DD");
        let payload = { toDate: toDate, fromDate: fromDate };
        return payload;
    } else if (formData.periodType === 'Yearly') {
        let year = Number(formData.selectedYear?.$y);
        let dateObj = getYearDates(year);
        let toDate = moment(dateObj.toDate).format("YYYY-MM-DD");
        let fromDate = moment(dateObj.fromDate).format("YYYY-MM-DD");
        let payload = { toDate: toDate, fromDate: fromDate };
        return payload;
    }
};
