import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Loader from '../../../shared/Loader';
import { MoreOptionMenu } from '../Dashboard';
import ColumnChart from '../../../shared/reporting/ColumnChart';
import GlobalFilter from '../GlobalFilter';
import FilterImage from '../../../assets/filter.png';
import LineChart from '../../../shared/reporting/LineChart';
import Snackbar from '../../../toaster-message/Snackbar';
import { getAirPointSourceData } from '../../../services/sub-issue-services/air-service/AirPointSource';
import { tableHeaderContent } from '../../../shared/reporting/TableHeader';
import BarsLoader from '../../../shared/loader/BarsLoader';


function AirDashboard(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState(dayjs(new Date()));
    const [openFilterDialog, setOpenFilterDialog] = useState({ action: false, userData: null });
    const [columnChartLoading, setColumnChartLoading] = useState(true);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const yearData = dayjs(new Date());
    localStorage.setItem("year", yearData.$y);
    const year = localStorage.getItem("year");
    const [airChartData, setAirChartData] = useState([]);
    const [airTableData, setAirTableData] = useState([]);

    useEffect(() => {
        // let facilities = null;
        let facilities = userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? null : [userDetails.facilityRefNo];
        airDashboarddata(facilities, year);
    }, []);

    const airDashboarddata = (facilities, year) => {
        let chartTableData = [];
        setColumnChartLoading(true);

        let obj = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityList: facilities?.length > 0 ? facilities.join(',') : null,
            YearList: year
        };

        // List of all possible pollutants
        const allPollutants = ["PM10", "NOx", "SOx", "CO", "VOC", "CH4", "POP", "HAP", "Other Pollutant (Specify)"];

        getAirPointSourceData(obj)
            .then((resp) => {
                if (resp.data.facilityDetails) {
                    // Initialize the loadByPollutant object with all pollutants set to zero
                    const loadByPollutant = allPollutants.reduce((acc, pollutant) => {
                        acc[pollutant] = 0;
                        return acc;
                    }, {});

                    // Sum the loads for each pollutant across all facilities
                    resp.data.facilityDetails.forEach((facility) => {
                        facility.pollutionLoad.forEach(({ pollutantName, load }) => {
                            if (loadByPollutant[pollutantName] !== undefined) {
                                loadByPollutant[pollutantName] += load;
                            }
                        });
                    });

                    // Convert loadByPollutant to the format required by the chart
                    const chartData = Object.entries(loadByPollutant).map(([pollutantName, load]) => [pollutantName, load]);
                    chartData.unshift(['Pollutant', 'Load']);
                    setAirChartData(chartData);

                    // Process table data
                    resp.data.facilityDetails.forEach((facility) => {
                        facility.pollutantData.forEach((pollutant) => {
                            pollutant.pollutantInfo.forEach((info) => {
                                info.facilityName = facility.facilityName;
                                info.sourceId = pollutant.sourceId;

                                Object.keys(info).forEach((key) => {
                                    if (typeof info[key] === 'number') {
                                        info[key] = info[key].toFixed(2);
                                    }
                                });
                                chartTableData.push(info);
                            });
                        });
                    });
                    setAirTableData(chartTableData);
                }
                setColumnChartLoading(false);
            })
            .catch((error) => {
                setColumnChartLoading(false); // Ensure loading state is reset in case of error
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        action: true,
                        type: "error",
                        message: "You are not authorized to perform this action. Redirected to login",
                    });
                } else {
                    setOpenSnackBar({
                        action: true,
                        type: "error",
                        message: error.response?.data?.message || "An error occurred while fetching the data",
                    });
                }
            });
    };

    const getFilterData = (filterData) => {
        airDashboarddata(filterData.facilityRefNo, filterData.year)
    };
    const refreshData = () => {
        // let facilities = null;
        const facilities = userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? null : [userDetails.facilityRefNo];
        airDashboarddata(facilities, yearData.$y)
        localStorage.setItem("year", yearData.$y);
    };
    const handleMenu = () => {
        setOpenFilterDialog({ action: true, userData: userDetails });
    };

    const airOptions = {
        title: "Point Source Pollution Load",
        titleTextStyle: {
            fontName: 'Arial', // Font family of the title
            fontSize: 18,      // Size of the title text
            bold: true,        // Make the title text bold
            italic: false      // Title text style is not italic
        },
        colors: ['#a15755', '#5f8971', '#907350', '#bec991', '#5a8b5d'],
        hAxis: {
            title: 'Pollutant Name',
        },
        vAxis: {
            title: 'Load (MT/Day)',
        },
        legend: {
            position: "top",
            alignment: 'end'
        },
        chartArea: { width: "80%" },
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 1, mt: 7 }}>
                    <div className="container-fluid">
                        <div className="row p-2">
                            <div className="col-lg-8 col-md-5">
                                <span><material.Typography variant="h5" className='text-capitalize'>Air</material.Typography></span>
                            </div>
                            <div className="col-lg-4 col-md-3 mt-3">
                                <div className='float-end'>
                                    <span>
                                        <material.IconButton
                                            aria-label="more"
                                            id="long-button"
                                            onClick={handleMenu}
                                            title='Filter'
                                            color='info'
                                        >
                                            <img
                                                src={FilterImage}
                                                height={25}
                                                width={25}
                                            />
                                        </material.IconButton>
                                    </span>
                                    <span>
                                        <material.IconButton title='Refresh' color='success' onClick={refreshData}>
                                            <material.RefreshIcon />
                                        </material.IconButton>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-5">
                                <material.Card elevation={3} sx={{ height: 430, backgroundColor: '#a0c6f2' }} className='column-chart'>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "15px" }}>
                                        <span>
                                            <span className='text-center'>
                                                <material.Typography variant="h5" sx={{ mt: 2, fontWeight: "bold" }} className='text-capitalize'>PM Emission</material.Typography>
                                            </span>
                                            <div className='text-center d-flex flex-column'>
                                                <material.Typography variant="h4" sx={{ mt: 14, color: "brown", fontWeight: "bold" }}>0.58 MT/day emission  </material.Typography>
                                            </div>
                                        </span>
                                    </div>
                                </material.Card>
                            </div>

                            <div className="col-lg-7">
                                <material.Card elevation={3} className='column-chart'>
                                    <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                        <span>
                                            <MoreOptionMenu
                                                chartName="column-chart"
                                                options={airOptions}
                                                columnChartData={airChartData}
                                                tableData={airTableData}
                                                columnName={tableHeaderContent[0].airEmissionTableColumns}
                                                year={year}
                                                tableHeading="Air Emission Point Source"
                                            />
                                        </span>
                                    </div>
                                    {columnChartLoading ? (
                                        <BarsLoader />
                                    ) : (
                                        <ColumnChart
                                            options={airOptions}
                                            columnChartData={airChartData}
                                        />
                                    )}
                                </material.Card>
                            </div>
                            <div className="col-lg-6 mt-3">
                                <material.Card elevation={3} className='column-chart'>
                                    <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                        <span>
                                            <MoreOptionMenu
                                                chartName="line-chart"
                                                lineChartData={airChartData}
                                                options={airOptions}
                                                tableData={airTableData}
                                                columnName={tableHeaderContent[0].airEmissionTableColumns}
                                                year={year}
                                                tableHeading="Air Emission Point Source"
                                            />
                                        </span>
                                    </div>
                                    {columnChartLoading ? (
                                        <BarsLoader />
                                    ) : (
                                        <LineChart
                                            lineChartData={airChartData}
                                            options={airOptions}
                                        />
                                    )}
                                </material.Card>
                            </div>
                        </div>
                    </div>
                </material.Box>
            </material.Box>
            <GlobalFilter
                openFilterDialog={openFilterDialog}
                setOpenFilterDialog={setOpenFilterDialog}
                getFilterData={getFilterData}
                callFrom="issue-dashboard"
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AirDashboard;