import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';
import moment from 'moment';


function AddMaterialityOfBusiness(props) {

    const { openMaterialityOfBusiness, setOpenMaterialityOfBusiness, getMaterialityOfBusiness, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [impactBusinessData, setImpactBusinessData] = useState('');
    const [impactFinanceData, setImpactFinanceData] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const payloadData = {
        page,
        size: rowsPerPage
    };

    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (openMaterialityOfBusiness.action && openMaterialityOfBusiness.materialityOfBusinessTableData) {
            setImpactBusinessData(openMaterialityOfBusiness.materialityOfBusinessTableData.impact_on_business);
            setImpactFinanceData(openMaterialityOfBusiness.materialityOfBusinessTableData.impact_on_finance);
            reset(openMaterialityOfBusiness.materialityOfBusinessTableData);
        }
    }, [openMaterialityOfBusiness.action === true]);

    const handleClose = () => {
        setImpactBusinessData("");
        setImpactFinanceData("");
        reset({ material_issue_identified: "", rationale_for_identifying_risk: "", approach_to_adapt_or_mitigate_risk: "", });
        setOpenMaterialityOfBusiness({ action: false, materialityOfBusinessTableData: null });
    };

    const saveMaterialityOfBusiness = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "material_issue_identified": data.material_issue_identified,
                "impact_on_business": data.impact_on_business,
                "rationale_for_identifying_risk": data.rationale_for_identifying_risk,
                "approach_to_adapt_or_mitigate_risk": data.approach_to_adapt_or_mitigate_risk,
                "impact_on_finance": data.impact_on_finance,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getMaterialityOfBusiness(getFilterDate, payloadData);
                    setImpactBusinessData("");
                    setImpactFinanceData("");
                    reset({ material_issue_identified: "", rationale_for_identifying_risk: "", approach_to_adapt_or_mitigate_risk: "", });
                    setOpenMaterialityOfBusiness({ action: false, materialityOfBusinessTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateMaterialityOfBusiness = async (data) => {
        const refNo = openMaterialityOfBusiness.materialityOfBusinessTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "material_issue_identified": data.material_issue_identified,
            "impact_on_business": data.impact_on_business,
            "rationale_for_identifying_risk": data.rationale_for_identifying_risk,
            "approach_to_adapt_or_mitigate_risk": data.approach_to_adapt_or_mitigate_risk,
            "impact_on_finance": data.impact_on_finance,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getMaterialityOfBusiness(getFilterDate, payloadData);
                setImpactBusinessData("");
                setImpactFinanceData("");
                reset({ material_issue_identified: "", rationale_for_identifying_risk: "", approach_to_adapt_or_mitigate_risk: "", });
                setOpenMaterialityOfBusiness({ action: false, materialityOfBusinessTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const changeImpactBusiness = (event) => {
        setImpactBusinessData(event.target.value);
    };
    const changeImpactFinance = (event) => {
        setImpactFinanceData(event.target.value);
    }

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openMaterialityOfBusiness.materialityOfBusinessTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setImpactBusinessData("");
                setImpactFinanceData("");
                reset({ material_issue_identified: "", rationale_for_identifying_risk: "", approach_to_adapt_or_mitigate_risk: "", });
                getMaterialityOfBusiness(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenMaterialityOfBusiness({ action: false, materialityOfBusinessTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openMaterialityOfBusiness.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openMaterialityOfBusiness.materialityOfBusinessTableData ? "Edit Materiality Of Business" : "Add Materiality Of Business"}</material.DialogTitle>
                <material.DialogContent>
                    {openMaterialityOfBusiness.materialityOfBusinessTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Materiality Of Business</material.Typography>
                            </div>
                            <div className='col-12'>
                                <material.TextField
                                    {...register("material_issue_identified", { required: true, minLength: 2, maxLength: 200 })}
                                    label="Material Issue Identified"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                                <p className='form-text text-danger'>{errors.material_issue_identified?.type === "required" && 'This field is required'}</p>
                                {errors.material_issue_identified && (errors.material_issue_identified.type === "minLength" || errors.material_issue_identified.type === "maxLength") && (
                                    <p className="errorMsg text-danger">Describe The Violation & Exceedance Issues should be between 2 to 200 character.</p>
                                )}
                            </div>
                            <div className='mt-3'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Impact on Business</material.InputLabel>
                                    <material.Select
                                        {...register("impact_on_business", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={impactBusinessData}
                                        onChange={changeImpactBusiness}
                                        label="Impact on Business"
                                    >
                                        <material.MenuItem value={"Risk"}>Risk</material.MenuItem>
                                        <material.MenuItem value={"Opportunity"}>Opportunity</material.MenuItem>
                                        <material.MenuItem value={"Both"}>Both</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='mt-3'>
                                <material.TextField
                                    {...register("rationale_for_identifying_risk", { required: true, minLength: 2, maxLength: 200 })}
                                    required
                                    label="Rationale for Identifying the Risk/Opportunity"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                                <p className='form-text text-danger'>{errors.rationale_for_identifying_risk?.type === "required" && 'This field is required'}</p>
                                {errors.rationale_for_identifying_risk && (errors.rationale_for_identifying_risk.type === "minLength" || errors.rationale_for_identifying_risk.type === "maxLength") && (
                                    <p className="errorMsg text-danger">Describe The Rationale for Identifying the Risk/Opportunity Issues should be between 2 to 200 character.</p>
                                )}
                            </div>
                            <div className='col-6 mt-2'>
                                <material.TextField
                                    {...register("approach_to_adapt_or_mitigate_risk", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Approach to Adapt or Mitigate Risk"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                <p className='form-text text-danger'>{errors.approach_to_adapt_or_mitigate_risk?.type === "required" && 'This field is required'}</p>
                                {errors.approach_to_adapt_or_mitigate_risk && errors.approach_to_adapt_or_mitigate_risk.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-6 mt-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Impact on Finance</material.InputLabel>
                                    <material.Select
                                        {...register("impact_on_finance", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={impactFinanceData}
                                        onChange={changeImpactFinance}
                                        label="Impact on Finance"
                                    >
                                        <material.MenuItem value={"Risk"}>Risk</material.MenuItem>
                                        <material.MenuItem value={"Opportunity"}>Opportunity</material.MenuItem>
                                        <material.MenuItem value={"Both"}>Both</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openMaterialityOfBusiness.materialityOfBusinessTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateMaterialityOfBusiness)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openMaterialityOfBusiness.materialityOfBusinessTableData.rejection_note === null) || (openMaterialityOfBusiness.materialityOfBusinessTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveMaterialityOfBusiness)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddMaterialityOfBusiness