import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import DatePeriod from '../../shared/DatePeriod';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import { commonDelete, saveSubIssueData, updateSubIssueData } from '../../services/sub-issue-services/AddSubissueService';

function AddCommunityEngagement(props) {
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { openAddCommunityEngagement, setOpenAddCommunityEngagement, getAllCommunityEngagementData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [category, setCategory] = useState("");
    const [stage, SetStage] = useState("");
    const [topicOfConsultation, setTopicOfConsultation] = useState("");

    const payloadData = {
        page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openAddCommunityEngagement.action && openAddCommunityEngagement.communityEngagementTableData) {
            setCategory(openAddCommunityEngagement.communityEngagementTableData.category);
            SetStage(openAddCommunityEngagement.communityEngagementTableData.stage);
            setTopicOfConsultation(openAddCommunityEngagement.communityEngagementTableData.topic_of_consultation);
            reset(openAddCommunityEngagement.communityEngagementTableData);
        }
    }, [openAddCommunityEngagement.action === true]);

    const selectCategory = (event) => {
        setCategory(event.target.value)
    };

    const selectStage = (event) => {
        SetStage(event.target.value)
    };

    const selectTopicOfConsultation = (event) => {
        setTopicOfConsultation(event.target.value)
    };

    const handleClose = () => {
        reset({ other_specify: "", we_link_of_documentation: "", });
        setCategory("");
        SetStage("");
        setTopicOfConsultation("");
        setOpenAddCommunityEngagement({ action: false, communityEngagementTableData: null });
    };

    const saveCommunityEngagementData = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        let subissueName = (treeViewData?.subIssue)
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporate_ref_no": userDetails.corporateRefNo,
                "facility_ref_no": userDetails.facilityRefNo,
                "category": data.category,
                "stage": data.stage,
                "topic_of_consultation": data.topic_of_consultation,
                "other_specify": data.other_specify,
                "we_link_of_documentation": data.we_link_of_documentation,
                "applicable_record": false,
                "applicable": true,
                "from_date": payLoad?.fromDate,
                "to_date": payLoad?.toDate,
            }
            await saveSubIssueData(obj, subissueName)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    reset({ other_specify: "", we_link_of_documentation: "", });
                    setCategory("");
                    SetStage("");
                    setTopicOfConsultation("");
                    getAllCommunityEngagementData(getFilterDate, payloadData);
                    setOpenAddCommunityEngagement({ action: false, communityEngagementTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateCommunityEngagementData = async (data) => {
        const refNo = openAddCommunityEngagement.communityEngagementTableData.ref_no;
        const subIssueName = treeViewData?.subIssue;
        let obj = {
            "category": data.category,
            "stage": data.stage,
            "topic_of_consultation": data.topic_of_consultation,
            "other_specify": topicOfConsultation === "Other Topic (Specify)" ? data.other_specify : "",
            "we_link_of_documentation": data.we_link_of_documentation,
        }
        await updateSubIssueData(obj, refNo, subIssueName)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ other_specify: "", we_link_of_documentation: "", });
                setCategory("");
                SetStage("");
                setTopicOfConsultation("");
                getAllCommunityEngagementData(getFilterDate, payloadData);
                setOpenAddCommunityEngagement({ action: false, communityEngagementTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })

    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        const payload = {
            refNo: openAddCommunityEngagement.communityEngagementTableData.ref_no,
            subissueName: treeViewData.subIssue,
        }
        commonDelete(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllCommunityEngagementData(getFilterDate, payloadData);
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddCommunityEngagement({ action: false, communityEngagementTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddCommunityEngagement.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddCommunityEngagement.communityEngagementTableData ? "Edit Community Engagement" : "Add Community Engagement"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddCommunityEngagement.communityEngagementTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Community Engagement</material.Typography>
                            </div>
                            <div className='col-6'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Category</material.InputLabel>
                                    <material.Select
                                        {...register("category", { required: true, })}
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={category}
                                        onChange={selectCategory}
                                        label="Unit CFC"
                                    >
                                        <material.MenuItem value={"Consultation"}>Consultation</material.MenuItem>
                                        <material.MenuItem value={"Disclosure"}>Disclosure</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Stage</material.InputLabel>
                                    <material.Select
                                        {...register("stage", { required: true, })}
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={stage}
                                        onChange={selectStage}
                                        label="Unit CFC"
                                    >
                                        <material.MenuItem value={"Planning"}>Planning</material.MenuItem>
                                        <material.MenuItem value={"Construction "}>Construction </material.MenuItem>
                                        <material.MenuItem value={"Operational"}>Operational</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 mt-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Topic of Consultation</material.InputLabel>
                                    <material.Select
                                        {...register("topic_of_consultation", { required: true, })}
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={topicOfConsultation}
                                        onChange={selectTopicOfConsultation}
                                        label="Unit CFC"
                                    >
                                        <material.MenuItem value={"Community Development"}>Community Development</material.MenuItem>
                                        <material.MenuItem value={"Community Health"}>Community Health</material.MenuItem>
                                        <material.MenuItem value={"Project Information"}>Project Information</material.MenuItem>
                                        <material.MenuItem value={"Project Impact"}>Project Impact</material.MenuItem>
                                        <material.MenuItem value={"Stakeholder Engagement"}>Stakeholder Engagement</material.MenuItem>
                                        <material.MenuItem value={"Management Plan"}>Management Plan</material.MenuItem>
                                        <material.MenuItem value={"Resettlement"}>Resettlement</material.MenuItem>
                                        <material.MenuItem value={"Other Topic (Specify)"}>Other Topic (Specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {topicOfConsultation === "Other Topic (Specify)" ? (
                                <div className='col-6 mt-2'>
                                    <material.TextField
                                        {...register("other_specify", { required: true, maxLength: 20 })}
                                        required
                                        label="Other Topic (specify)"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors?.other_specify?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-6 mt-2'>
                                <material.TextField
                                    {...register("we_link_of_documentation",)}
                                    required
                                    label="Web Link of Documentation"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddCommunityEngagement.communityEngagementTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateCommunityEngagementData)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddCommunityEngagement.communityEngagementTableData.rejection_note === null) || (openAddCommunityEngagement.communityEngagementTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveCommunityEngagementData)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddCommunityEngagement;