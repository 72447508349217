import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import SwipeDrawer from '../drawer/SwipeDrawer';
import { getSitesByCorporateRefNo } from '../../services/SiteService';
import { useSelector } from 'react-redux';
import AddSite from '../../dialogs/AddSite';
import AddUser from '../../dialogs/AddUser';
import ViewUsers from '../../dialogs/ViewUsers';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import Snackbar from '../../toaster-message/Snackbar';

function SiteList(props) {
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [siteData, setSiteData] = useState([]);
    const [openAddSite, setOpenAddSite] = useState({ action: false, siteData: null, readOnly: null });
    const [openAddUser, setOpenAddUser] = useState({ "action": false, "siteData": "", "callFrom": "" });
    const [openViewUsers, setOpenViewUsers] = useState({ "action": false, "siteData": "", "callFrom": "" });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getSiteDataByCorporateRefNo()
    }, []);

    const getSiteDataByCorporateRefNo = async () => {
        await getSitesByCorporateRefNo(userDetails.corporateRefNo)
            .then((resp) => {
                setSiteData(resp.data)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const addSite = () => {
        setOpenAddSite({ action: true, siteData: null })
    };
    const editSite = (rowData) => {
        setOpenAddSite({ action: true, siteData: rowData, readOnly: true })
    };
    const addUser = (rowData) => {
        setOpenAddUser({ action: true, siteData: rowData, callFrom: "siteList" })
    };

    const viewUsers = (rowData) => {
        setOpenViewUsers({ action: true, siteData: rowData, callFrom: "siteList" });
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className='row'>
                        <div className="col-6">
                            <span><material.Typography variant="h5" className='headingText'>Facility list for : {userDetails.corporateName}</material.Typography></span>
                        </div>
                        <div className="col-6">
                            <span className='float-end'>
                                <material.Button variant="contained" color='success' sx={{ textTransform: "none" }} onClick={addSite} startIcon={<material.AddIcon />}>Add-Facility</material.Button>
                            </span>
                        </div>
                        <material.Paper sx={{ p: 2, mt: 2, }} elevation={3}>
                            <div className='col-12'>
                                <material.TableContainer sx={{ maxHeight: 560, width: '100%' }}>
                                    <material.Table stickyHeader aria-label="sticky table">
                                        <material.TableHead >
                                            <material.TableRow>
                                                <StyledTableCell>Facility Name</StyledTableCell>
                                                {/* <StyledTableCell>Ref No.</StyledTableCell> */}
                                                <StyledTableCell align='left'>Email</StyledTableCell>
                                                <StyledTableCell align='left'>Website</StyledTableCell>
                                                <StyledTableCell align='left'>Phone</StyledTableCell>
                                                <StyledTableCell align='left'>Address</StyledTableCell>
                                                <StyledTableCell align='left'>Action</StyledTableCell>
                                            </material.TableRow>
                                        </material.TableHead>
                                        <material.TableBody>
                                            {siteData.length ? siteData.map((row, i) => (
                                                <material.TableRow key={i}>
                                                    <material.TableCell size='small'>{row.assetName}</material.TableCell>
                                                    {/* <material.TableCell size='small' sx={{ width: "200px" }}>{row.refNo}</material.TableCell> */}
                                                    <material.TableCell align='left' size='small'>{row.email}</material.TableCell>
                                                    <material.TableCell align='left' size='small'>{row.website}</material.TableCell>
                                                    <material.TableCell align='left' size='small'>{row.phone}</material.TableCell>
                                                    <material.TableCell align='left' className='text-break' size='small' style={{ width: "10rem" }}>{row.address}</material.TableCell>
                                                    <material.TableCell align='left'>
                                                        <material.IconButton title='Add User' aria-label="create" size="large" onClick={() => addUser(row)}>
                                                            <material.PersonAddIcon color='primary' />
                                                        </material.IconButton>
                                                        <material.IconButton title='View User' aria-label="create" size="large" onClick={() => viewUsers(row)}>
                                                            <material.VisibilityIcon color='primary' />
                                                        </material.IconButton>
                                                        {/* <material.IconButton title='Edit User' aria-label="create" size="large" onClick={() => editSite(row)}>
                                                        <material.CreateIcon color='primary' />
                                                    </material.IconButton> */}
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )) : null}
                                        </material.TableBody>
                                    </material.Table>
                                </material.TableContainer>
                            </div>
                        </material.Paper>
                    </div>
                </material.Box>
            </material.Box>
            <AddSite
                openAddSite={openAddSite}
                setOpenAddSite={setOpenAddSite}
                getSiteDataByCorporateRefNo={getSiteDataByCorporateRefNo}
            />
            <AddUser
                openAddUser={openAddUser}
                setOpenAddUser={setOpenAddUser}
            />
            <ViewUsers
                openViewUsers={openViewUsers}
                setOpenViewUsers={setOpenViewUsers}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default SiteList;