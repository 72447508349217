import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import moment from 'moment';
import BarsLoader from '../../shared/loader/BarsLoader';
import Pagination from '../../shared/pagination/Pagination';
import { useSelector } from 'react-redux';
import UploadDocumentDialog from '../../tabs/upload-document/UploadDocumentDialog';
import { deleteDocumentByRefNo, downloadDocument, getAttachedDocumentList } from '../../services/DocumentUploadService';
import DeletedPermissionDialog from '../../tabs/upload-document/DeletedPermissionDialog';
import Snackbar from '../../toaster-message/Snackbar';

function UploadAndViewAttachedDocument(props) {

    const { setOpenUploadAndViewAttachedDocument, openUploadAndAttachedDocument, getAssessmentQuestionsAndAnswer } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [documentList, setDocumentList] = useState([]);
    const [openUploadDialog, setOpenUploadDialog] = useState({ action: false, documentData: null });
    const [openDeletePermissionDialog, setOpenDeletePermissionDialog] = useState({ action: false, document: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const paginationData = {
        page: page,
        size: rowsPerPage
    };

    useEffect(() => {
        if (openUploadAndAttachedDocument.action) {
            getAllAttachedDocumentList()
        }
    }, [openUploadAndAttachedDocument.action === true]);

    const getAllAttachedDocumentList = (payloadData) => {
        setIsLoading(true);
        const payload = {
            answerRefNo: openUploadAndAttachedDocument.ansRefNo,
            page: payloadData?.page ? payloadData.page : 0,
            size: payloadData?.size ? payloadData.size : 10,
            search: payloadData?.search ? payloadData.search : ""
        }
        getAttachedDocumentList(payload)
            .then((resp) => {
                setDocumentList(resp.data.content);
                setPage(resp.data.number);
                setRowsPerPage(resp.data.size);
                setTotalElements(resp.data.totalPages);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const searchCategory = (value) => {
        const payload = {
            search: value
        }
        getAllAttachedDocumentList(payload)
    };

    const getPaginationData = (filterData, paginationData) => {
        setRowsPerPage(paginationData.size);
        setPage(paginationData.page);
        const payload = {
            page: paginationData.page,
            size: paginationData.size
        }
        getAllAttachedDocumentList(payload);
    };

    const addDocument = () => {
        setOpenUploadDialog({ action: true, documentData: null });
    };

    const editDocument = (rowData) => {
        setOpenUploadDialog({ action: true, documentData: rowData });
    };

    const downloadFile = (rowData) => {
        const fileType = rowData.documentType === "PDF" ? ".pdf" : rowData.documentType === "DOC" ? ".doc" : rowData.documentType === "DOCX" ? ".docx" : rowData.documentType === "PNG" ? ".png" : rowData.documentType === "JPEG" ? ".jpeg" : "";
        downloadDocument(rowData)
            .then((resp) => {
                const blob = new Blob([resp.data], { type: resp.headers['content-type'] });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = rowData.nameOfDocument.concat(fileType);
                link.click();
                window.URL.revokeObjectURL(link.href);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteDocument = (rowData) => {
        setOpenDeletePermissionDialog({ action: true, document: rowData })
    };

    const getDeleteConformationData = (deleteData) => {
        deleteDocumentByRefNo(deleteData.refNo)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllAttachedDocumentList(paginationData);
                getAssessmentQuestionsAndAnswer();
                setOpenDeletePermissionDialog({ action: false, document: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const handleClose = () => {
        setOpenUploadAndViewAttachedDocument({ action: false, ansRefNo: null });
    };

    return (
        <div>
            <material.Dialog maxWidth="xl" fullWidth open={openUploadAndAttachedDocument.action}>
                <material.DialogTitle className='fw-bold d-flex justify-content-between'>Supporting Document List
                    <material.IconButton title='Close' onClick={handleClose}>
                        <material.CloseIcon color='error' />
                    </material.IconButton>
                </material.DialogTitle>
                <material.DialogContent>
                    <div className="row mt-3">
                        <div className="col-3">
                            {documentList?.length ? (
                                <material.TextField
                                    sx={{ mb: 2, mt: -3 }}
                                    fullWidth
                                    variant="standard"
                                    label="Search"
                                    onChange={(e) => searchCategory(e.target.value)}
                                />
                            ) : null}
                        </div>
                        <div className="col-9 mb-2">
                            {userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? (
                                <span className='float-end'>
                                    <material.Button variant="contained" color='success' sx={{ textTransform: "none", mt: -2 }}
                                        startIcon={<material.AddIcon />} onClick={addDocument}>Add Document</material.Button>
                                </span>
                            ) : null}
                        </div>
                        <div className="col-12">
                            <material.TableContainer sx={{ maxHeight: "70vh" }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead>
                                        <material.TableRow>
                                            <StyledTableCell>Document Category</StyledTableCell>
                                            <StyledTableCell>Name of Document</StyledTableCell>
                                            <StyledTableCell>Validity Date</StyledTableCell>
                                            <StyledTableCell align='center'>Action</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {isLoading ? (
                                            <material.TableRow>
                                                <material.TableCell colSpan={5}>
                                                    <BarsLoader />
                                                </material.TableCell>
                                            </material.TableRow>
                                        ) : (
                                            <>
                                                {documentList?.length ? documentList.map((item, i) => (
                                                    <material.TableRow key={i}>
                                                        <material.TableCell>{item.category}</material.TableCell>
                                                        <material.TableCell>{item.nameOfDocument}</material.TableCell>
                                                        <material.TableCell>{(item.validUpTo === "" || item.validUpTo === null) ? "N/A" : moment(item.validUpTo).format("DD-MM-YYYY")}</material.TableCell>
                                                        <material.TableCell align='center'>
                                                            {userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? (
                                                                <>
                                                                    <material.IconButton title='Edit Document' aria-label="create" size="large" onClick={() => editDocument(item)}>
                                                                        <material.CreateIcon color='success' />
                                                                    </material.IconButton>
                                                                    <material.IconButton title='Delete Document' aria-label="create" size="large" onClick={() => deleteDocument(item)}>
                                                                        <material.DeleteIcon color='error' />
                                                                    </material.IconButton>
                                                                </>
                                                            ) : null}
                                                            <material.IconButton title='Download Document' aria-label="create" size="large" onClick={() => downloadFile(item)}>
                                                                <material.DownloadIcon color='secondary' />
                                                            </material.IconButton>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={10}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </>
                                        )}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                            <Pagination
                                getPaginationData={getPaginationData}
                                totalElements={totalElements}
                                rowsPerPage={rowsPerPage}
                                page={page}
                            />
                        </div>
                    </div>
                </material.DialogContent>
                <UploadDocumentDialog
                    openUploadDialog={openUploadDialog}
                    setOpenUploadDialog={setOpenUploadDialog}
                    getAllDocumentList={getAllAttachedDocumentList}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    ansRefNo={openUploadAndAttachedDocument.ansRefNo}
                    getAssessmentQuestionsAndAnswer={getAssessmentQuestionsAndAnswer}
                    callFrom="supporting-document"
                />
                <DeletedPermissionDialog
                    openDeletePermissionDialog={openDeletePermissionDialog}
                    setOpenDeletePermissionDialog={setOpenDeletePermissionDialog}
                    getDeleteConformationData={getDeleteConformationData}
                />
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
            </material.Dialog>
        </div>
    );
}

export default UploadAndViewAttachedDocument;