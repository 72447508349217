import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import DatePeriod from '../../shared/DatePeriod';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { commonDelete } from '../../services/sub-issue-services/AddSubissueService';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment/moment';
import { deleteEnergyConsumptionWithinOrgData, saveOrUpdateEnergyConsumptionWithinOrgData } from '../../services/sub-issue-services/energy/EnergyConsumptionWithinOrgService';



const renewableElectricityData = ["Renewable Electricity - Self Produced", "Renewable Electricity - Purchased"];
const nonRenewableElectricityData = ["Non Renewable Electricity - Purchased (Grid)", "Non Renewable Electricity - Purchased (3rd Party)"];
const renewableHeatingCoolingSteamData = ["Produced from Renewable Source"];
const nonRenewableHeatingCoolingSteamData = ["Produced from Non Renewable Source"];

function AddElectricityConsumption(props) {
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, watch, getValues, setValue, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const { openEnergyConsumptionWithinOrganizationElectricity, setOpenEnergyConsumptionWithinOrganizationElectricity, getAllEnergyConsumptionWithinOrganizationElectricityData, treeViewData, page, rowsPerPage, getFilterDate } = props;
    const [typeOfSource, setTypeOfSource] = useState("");
    const [sourceCategory, setSourceCategory] = useState("");
    const [sourceCategoryData, setSourceCategoryData] = useState([]);
    const [unit, setUnit] = useState("");
    const [purposeOfConsumption, setPurposeOfConsumption] = useState("");
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [getEmissionFactorValue, setGetEmissionFactorValue] = useState(null);

    const payload = {
        page,
        size: rowsPerPage
    };

    const mapEnergyConsumptionData = (data) => {
        return {
            refNo: data.ref_no,
            applicable: data.applicable,
            purposeOfConsumption: data.purpose_of_consumption,
            sourceType: data.source_type,
            sourceCategory: data.source_category,
            quantity: data.quantity,
            unit: data.unit,
            emissionFactor: data.emission_factor,
        };
    };

    useEffect(() => {
        if (openEnergyConsumptionWithinOrganizationElectricity.action && openEnergyConsumptionWithinOrganizationElectricity.energyConsumptionWithinOrganizationElectricityTableData) {
            const mappedData = mapEnergyConsumptionData(openEnergyConsumptionWithinOrganizationElectricity.energyConsumptionWithinOrganizationElectricityTableData)
            setTypeOfSource(mappedData.sourceType);
            setSourceCategory(mappedData.sourceCategory);

            if (mappedData.sourceType === 'Renewable'
                && mappedData.purposeOfConsumption === "Electricity") {
                setSourceCategoryData(renewableElectricityData)
            } else if (mappedData.sourceType === 'Non Renewable'
                && mappedData.purposeOfConsumption === "Electricity") {
                setSourceCategoryData(nonRenewableElectricityData)
            } else if (mappedData.sourceType === 'Renewable'
                && (mappedData.purposeOfConsumption === "Heating"
                    || mappedData.purposeOfConsumption === "Cooling"
                    || mappedData.purposeOfConsumption === "Steam")) {
                setSourceCategoryData(renewableHeatingCoolingSteamData)
            } else if (mappedData.sourceType === 'Non Renewable'
                && (mappedData.purposeOfConsumption === "Heating"
                    || mappedData.purposeOfConsumption === "Cooling"
                    || mappedData.purposeOfConsumption === "Steam")) {
                setSourceCategoryData(nonRenewableHeatingCoolingSteamData)
            }
            setUnit(mappedData.unit);
            setPurposeOfConsumption(mappedData.purposeOfConsumption);
            reset(mappedData);
            setGetEmissionFactorValue(mappedData.emissionFactor)
        };
    }, [openEnergyConsumptionWithinOrganizationElectricity.action === true]);

    useEffect(() => {
        if (sourceCategory === "Non Renewable Electricity - Purchased (Grid)") {
            setValue("emissionFactor", getEmissionFactorValue ? getEmissionFactorValue : 0.19888)
        } else if (sourceCategory === "Non Renewable Electricity - Purchased (3rd Party)") {
            setValue("emissionFactor", getEmissionFactorValue ? getEmissionFactorValue : 0.26972)
        } else if (sourceCategory === "Renewable Electricity - Self Produced") {
            setValue("emissionFactor", getEmissionFactorValue ? getEmissionFactorValue : 0)
        } else if (sourceCategory === "Renewable Electricity - Purchased") {
            setValue("emissionFactor", getEmissionFactorValue ? getEmissionFactorValue : 0)
        } else if ((purposeOfConsumption === "Heating" || purposeOfConsumption === "Cooling" || purposeOfConsumption === "Steam") && sourceCategory === "Produced from Renewable Source") {
            setValue("emissionFactor", getEmissionFactorValue ? getEmissionFactorValue : 0)
        } else if ((purposeOfConsumption === "Heating" || purposeOfConsumption === "Cooling" || purposeOfConsumption === "Steam") && sourceCategory === "Produced from Non Renewable Source") {
            setValue("emissionFactor", getEmissionFactorValue ? getEmissionFactorValue : 0.06294)
        }
    }, [sourceCategory]);

    const selectTypeOfSource = (event) => {
        setValue("emissionFactor", "");
        setGetEmissionFactorValue(null);
        setSourceCategory("");
        setSourceCategoryData([]);
        let selectedTypeOfSource = event.target.value;
        setTypeOfSource(event.target.value);
        if (selectedTypeOfSource === 'Renewable' && purposeOfConsumption === "Electricity") {
            setSourceCategoryData(renewableElectricityData)
        } else if (selectedTypeOfSource === 'Non Renewable' && purposeOfConsumption === "Electricity") {
            setSourceCategoryData(nonRenewableElectricityData)
        } else if (selectedTypeOfSource === 'Renewable' && (purposeOfConsumption === "Heating" || purposeOfConsumption === "Cooling" || purposeOfConsumption === "Steam")) {
            setSourceCategoryData(renewableHeatingCoolingSteamData)
        } else if (selectedTypeOfSource === 'Non Renewable' && (purposeOfConsumption === "Heating" || purposeOfConsumption === "Cooling" || purposeOfConsumption === "Steam")) {
            setSourceCategoryData(nonRenewableHeatingCoolingSteamData)
        } else {
            setSourceCategoryData([])
        }
    };

    const selectSourceCategory = (event) => {
        setSourceCategory(event.target.value);
        setValue("emissionFactor", "");
        setGetEmissionFactorValue(null);
    };

    const selectUnit = (event) => {
        setUnit(event.target.value);
    };

    const selectPurposeOfConsumption = (event) => {
        setValue("emissionFactor", "");
        setGetEmissionFactorValue(null);
        setTypeOfSource('');
        setSourceCategory("");
        setSourceCategoryData([]);
        setPurposeOfConsumption(event.target.value);
    };

    const handleClose = () => {
        setTypeOfSource("");
        setSourceCategory("");
        setUnit("");
        setPurposeOfConsumption("");
        setGetEmissionFactorValue(null);
        reset({
            purposeOfConsumption: "",
            sourceType: "",
            sourceCategory: "",
            quantity: "",
            unit: "",
            emissionFactor: "",
        });
        setOpenEnergyConsumptionWithinOrganizationElectricity({ action: false, energyConsumptionWithinOrganizationElectricityTableData: null });
    };

    const submitEnergyConsumptionWithinOrganizationElectricity = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": true,
                "fromDate": payLoad?.fromDate,
                "toDate": payLoad?.toDate,
                ...data
            }
            await saveOrUpdateEnergyConsumptionWithinOrgData(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllEnergyConsumptionWithinOrganizationElectricityData(getFilterDate, payload);
                    setTypeOfSource("");
                    setSourceCategory("");
                    setUnit("");
                    setPurposeOfConsumption("");
                    setGetEmissionFactorValue(null);
                    reset({
                        purposeOfConsumption: "",
                        sourceType: "",
                        sourceCategory: "",
                        quantity: "",
                        unit: "",
                        emissionFactor: "",
                    });
                    setOpenEnergyConsumptionWithinOrganizationElectricity({ action: false, energyConsumptionWithinOrganizationElectricityTableData: null });
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        }
    };

    const updateEnergyConsumptionWithinOrganizationElectricity = async (data) => {
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            ...data
        }
        await saveOrUpdateEnergyConsumptionWithinOrgData(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllEnergyConsumptionWithinOrganizationElectricityData(getFilterDate, payload);
                setTypeOfSource("");
                setSourceCategory("");
                setUnit("");
                setPurposeOfConsumption("");
                setGetEmissionFactorValue(null);
                reset({
                    purposeOfConsumption: "",
                    sourceType: "",
                    sourceCategory: "",
                    quantity: "",
                    unit: "",
                    emissionFactor: "",
                });
                setOpenEnergyConsumptionWithinOrganizationElectricity({ action: false, energyConsumptionWithinOrganizationElectricityTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = () => {
        deleteEnergyConsumptionWithinOrgData(openEnergyConsumptionWithinOrganizationElectricity.energyConsumptionWithinOrganizationElectricityTableData.ref_no)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                getAllEnergyConsumptionWithinOrganizationElectricityData(getFilterDate, payload);
                setTypeOfSource("");
                setSourceCategory("");
                setUnit("");
                setPurposeOfConsumption("");
                setGetEmissionFactorValue(null);
                reset({
                    purposeOfConsumption: "",
                    sourceType: "",
                    sourceCategory: "",
                    quantity: "",
                    unit: "",
                    emissionFactor: "",
                });
                setOpenEnergyConsumptionWithinOrganizationElectricity({ action: false, energyConsumptionWithinOrganizationElectricityTableData: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openEnergyConsumptionWithinOrganizationElectricity.action} maxWidth="lg" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openEnergyConsumptionWithinOrganizationElectricity.energyConsumptionWithinOrganizationElectricityTableData ? `Edit ${treeViewData?.subIssue}` : `Add ${treeViewData?.subIssue}`}</material.DialogTitle>
                <material.DialogContent>
                    {openEnergyConsumptionWithinOrganizationElectricity.energyConsumptionWithinOrganizationElectricityTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='col-4'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Purpose of Consumption</material.InputLabel>
                                    <material.Select
                                        {...register("purposeOfConsumption", { required: true })}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={purposeOfConsumption}
                                        onChange={selectPurposeOfConsumption}
                                        label="Material Sourced"
                                    >
                                        <material.MenuItem value={"Electricity"}>Electricity</material.MenuItem>
                                        <material.MenuItem value={"Heating"}>Heating</material.MenuItem>
                                        <material.MenuItem value={"Cooling"}>Cooling</material.MenuItem>
                                        <material.MenuItem value={"Steam"}>Steam</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Type of Source</material.InputLabel>
                                    <material.Select
                                        {...register("sourceType", { required: true })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={typeOfSource}
                                        onChange={selectTypeOfSource}
                                        label="Material Sourced"
                                    >
                                        <material.MenuItem value={"Renewable"}>Renewable</material.MenuItem>
                                        <material.MenuItem value={"Non Renewable"}>Non Renewable</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Source Category</material.InputLabel>
                                    <material.Select
                                        {...register("sourceCategory", { required: true })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={sourceCategory}
                                        onChange={selectSourceCategory}
                                        label="Material Sourced"
                                    >
                                        {sourceCategoryData.map((ele, i) =>
                                            <material.MenuItem value={ele} key={i}>{ele}</material.MenuItem>
                                        )}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4 mt-2'>
                                <material.TextField
                                    {...register("quantity", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Quantity"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.quantity && errors.quantity.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 2 digit after decimal</p>
                                )}
                            </div>
                            <div className='col-4'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Unit</material.InputLabel>
                                    <material.Select
                                        {...register("unit", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={unit}
                                        onChange={selectUnit}
                                        label="Material Sourced"
                                    >
                                        {purposeOfConsumption === "Electricity" ? [
                                            <material.MenuItem value={"kWh"}>kWh</material.MenuItem>,
                                            <material.MenuItem value={"MWh"}>MWh</material.MenuItem>,
                                            <material.MenuItem value={"GJ"}>GJ</material.MenuItem>
                                        ] : purposeOfConsumption === "Heating" || purposeOfConsumption === "Cooling" || purposeOfConsumption === "Steam" ? [
                                            <material.MenuItem value={"MMBTU"}>MMBTU</material.MenuItem>,
                                            <material.MenuItem value={"MMKcal"}>MMKcal</material.MenuItem>,
                                            <material.MenuItem value={"GJ"}>GJ</material.MenuItem>
                                        ] : ""}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4 mt-2'>
                                <material.TextField
                                    {...register("emissionFactor", { required: true, pattern: /^\d*\.?\d{0,5}$/ })}
                                    required
                                    label="Emission Factor"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                    InputProps={{ readOnly: typeOfSource === "Non Renewable" ? true : false }}
                                    InputLabelProps={{ shrink: true }}
                                />
                                {errors.emissionFactor && errors.emissionFactor.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 5 digit after decimal</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openEnergyConsumptionWithinOrganizationElectricity.energyConsumptionWithinOrganizationElectricityTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateEnergyConsumptionWithinOrganizationElectricity)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openEnergyConsumptionWithinOrganizationElectricity.energyConsumptionWithinOrganizationElectricityTableData.rejection_note === null) || (openEnergyConsumptionWithinOrganizationElectricity.energyConsumptionWithinOrganizationElectricityTableData.rejection_note === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(submitEnergyConsumptionWithinOrganizationElectricity)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddElectricityConsumption;