import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { material } from '../../library/Material';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import { useSelector } from 'react-redux';
import { getAllAssessmentQuestion, saveAssessmentQuestion } from '../../services/AssessmentService';
import Snackbar from '../../toaster-message/Snackbar';

var deletedSlNo = [];

function AssessmentQuestionnaire(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [questions, setQuestions] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [isEditing, setIsEditing] = useState(false);
    const [openDeletePermissionDialog, setOpenDeletePermissionDialog] = useState({ action: false, question: null });

    useEffect(() => {
        getAssessmentQuestions();
    }, []);

    const getAssessmentQuestions = () => {
        getAllAssessmentQuestion(userDetails.corporateRefNo)
            .then((resp) => {
                setQuestions(resp.data)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const addQuestion = () => {
        setButtonDisabled(true);
        setQuestions([...questions, { questionText: '', isNew: true }]);
        setEditingIndex(questions.length);
    };

    const deleteQuestion = (rowData) => {
        setOpenDeletePermissionDialog({ action: true, question: rowData })
    };

    const getDeleteConformationData = (deleteData) => {
        const payload = {
            "questionRefNo": deleteData.questionRefNo,
            "corporateRefNo": userDetails.corporateRefNo,
            "questionText": deleteData.questionText,
            "maxCharResponse": 500,
            "deleted": true,
        }
        saveAssessmentQuestion(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                deletedSlNo.push(deleteData.slNo);
                deletedSlNo.sort();
                getAssessmentQuestions();
                setEditingIndex(null);
                setButtonDisabled(false);
                setOpenDeletePermissionDialog({ action: false, question: null });
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const cancel = () => {
        getAssessmentQuestions();
        setEditingIndex(null);
        setButtonDisabled(false);
    };

    const editQuestion = (index) => {
        setEditingIndex(index);
        setIsEditing(true);
        setButtonDisabled(true);
    };

    const saveQuestion = (index, data) => {
        let editedQuestion = [];
        const newQuestions = questions.map((q, i) =>
            i === index ? { ...q, questionText: data.questionText, isNew: false } : q
        );
        if (editingIndex != null) {
            editedQuestion = newQuestions.filter((ele, i) => i == editingIndex);
        };
        if (editedQuestion.length) {
            const payload = {
                "questionRefNo": editedQuestion.length && editedQuestion[0].questionRefNo ? editedQuestion[0].questionRefNo : "",
                "corporateRefNo": userDetails.corporateRefNo,
                "questionText": editedQuestion.length ? editedQuestion[0].questionText : "",
                "maxCharResponse": 500,
                "deleted": false
            }
            saveAssessmentQuestion(payload)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    deletedSlNo.shift();
                    getAssessmentQuestions();
                    setEditingIndex(null);
                    setButtonDisabled(false);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                        })
                    };
                })
        };
    };


    return (
        <div style={{ marginTop: "-15px" }}>
            <material.Paper elevation={3} sx={{ p: 3 }}>
                <div className="row">
                    <div className="col-12">
                        <span className='float-end'>
                            <material.Button onClick={addQuestion} variant="contained" color='success' sx={{ textTransform: "none", mb: 1 }} startIcon={<material.AddIcon />} disabled={buttonDisabled || questions.length == 20}>
                                Add Question
                            </material.Button>
                        </span>
                    </div>
                    <div className="col-12">
                        <material.TableContainer sx={{ maxHeight: "70vh" }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead>
                                    <material.TableRow>
                                        <StyledTableCell sx={{ width: "5%" }}>SL.No</StyledTableCell>
                                        <StyledTableCell sx={{ width: "80%" }}>Question</StyledTableCell>
                                        <StyledTableCell sx={{ width: "15%" }}>Actions</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {questions.map((row, index) => (
                                        <EditableRow
                                            key={index}
                                            index={index}
                                            row={row}
                                            isEditing={editingIndex === index}
                                            onSave={saveQuestion}
                                            onEdit={editQuestion}
                                            onDelete={deleteQuestion}
                                            isEditable={isEditing}
                                            cancel={cancel}
                                        />
                                    ))}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                    </div>
                </div>
            </material.Paper>
            <DeletedPermissionDialog
                openDeletePermissionDialog={openDeletePermissionDialog}
                setOpenDeletePermissionDialog={setOpenDeletePermissionDialog}
                getDeleteConformationData={getDeleteConformationData}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const EditableRow = ({ index, row, isEditing, onSave, onEdit, onDelete, cancel }) => {
    const { control, handleSubmit, reset, formState: { errors, isValid } } = useForm({
        defaultValues: {
            questionText: row.questionText,
        },
        mode: "onTouched"
    });

    useEffect(() => {
        if (isEditing) {
            reset(row)
        }
    }, [isEditing === true]);

    const saveQuestion = (data) => {
        onSave(index, data);
        reset({ slNo: "", questionText: "" });
    };

    const handleCancel = () => {
        cancel(index);
        reset({ slNo: "", questionText: "" })
    };

    return (
        <material.TableRow>
            <material.TableCell>{index + 1}</material.TableCell>
            <material.TableCell className='text-break' size='small' style={{ width: "50rem" }}>
                {isEditing ? (
                    <Controller
                        name="questionText"
                        control={control}
                        rules={{ required: true, minLength: 10, maxLength: 500 }}
                        render={({ field }) => (
                            <>
                                <material.TextField
                                    {...field}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    sx={{ width: "100%" }}
                                />
                                {(errors?.questionText?.type === "maxLength") && (
                                    <p className='text-danger'>Can not exceed 500 character</p>
                                )}
                                {(errors?.questionText?.type === "minLength") && (
                                    <p className='text-danger'>Minimum 15 character is required</p>
                                )}
                            </>
                        )}
                    />
                ) : (
                    row.questionText
                )}
            </material.TableCell>
            <material.TableCell>
                {isEditing ? (
                    <>
                        <material.IconButton onClick={handleSubmit(saveQuestion)} disabled={!isValid}>
                            <material.SaveIcon color={isValid ? 'success' : 'disabled'} />
                        </material.IconButton>
                    </>
                ) : (
                    <material.IconButton onClick={() => onEdit(index)}>
                        <material.CreateIcon color='success' />
                    </material.IconButton>
                )}
                {isEditing ? (
                    <material.IconButton onClick={handleCancel}>
                        <material.CloseIcon color='error' />
                    </material.IconButton>
                ) : (
                    <material.IconButton onClick={() => onDelete(row)}>
                        <material.DeleteIcon color='error' />
                    </material.IconButton>
                )}
            </material.TableCell>
        </material.TableRow>
    );
};

const DeletedPermissionDialog = (props) => {

    const { openDeletePermissionDialog, setOpenDeletePermissionDialog, getDeleteConformationData } = props;
    const [selectValue, setSelectValue] = useState("");
    const handleChange = (event) => {
        let value = event.target.value;
        setSelectValue(value);
        if (value === "Yes") {
            getDeleteConformationData(openDeletePermissionDialog.question);
            setSelectValue("");
        } else {
            setSelectValue("");
            setOpenDeletePermissionDialog({ action: false, question: null });
        };
    };

    return (
        <div>
            <material.Dialog open={openDeletePermissionDialog.action} maxWidth="auto">
                <material.DialogContent>
                    <div className='row'>
                        <div className='col-12 d-flex flex-row align-items-center'>
                            <div className='me-2 fw-bold text-dark'>Are you sure, you want to delete this question ?</div>
                            <material.FormControl>
                                <material.RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={selectValue}
                                    onChange={handleChange}
                                >
                                    <material.FormControlLabel value="No" label="No" control={<material.Radio color='secondary' />} />
                                    <material.FormControlLabel value="Yes" label="Yes" control={<material.Radio color='secondary' />} />
                                </material.RadioGroup>
                            </material.FormControl>
                        </div>
                    </div>
                </material.DialogContent>
            </material.Dialog>
        </div>
    )
};

export default AssessmentQuestionnaire;