import React, { useEffect, useState } from 'react';
import SwipeDrawer from '../drawer/SwipeDrawer';
import { material } from '../../library/Material';
import { addCorporate, getAllCorporate } from '../../services/CorporateService';
import AddCorporate from '../../dialogs/AddCorporate';
import { useSelector, useDispatch } from 'react-redux';
import AddUser from '../../dialogs/AddUser';
import ViewUsers from '../../dialogs/ViewUsers';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import Snackbar from '../../toaster-message/Snackbar';

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

function CorporateList(props) {
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [openAddCorporate, setOpenAddCorporate] = useState({ "action": false, "corporateData": "", "readOnly": null });
    const [corporateData, setCorporateData] = useState([]);
    const [openAddUser, setOpenAddUser] = useState({ "action": false, "corporateData": {}, "callFrom": "" });
    const [openViewUsers, setOpenViewUsers] = useState({ "action": false, "corporateData": null, "callFrom": "" });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getCorporateList()
    }, []);

    const getCorporateList = async () => {
        await getAllCorporate()
            .then((resp) => {
                let corporateData = resp.data?.map((ele) => {
                    if (ele.corporateName !== null) {
                        return ele
                    }
                })
                let filterCorporateData = corporateData?.filter((item) => {
                    return item
                })
                setCorporateData(filterCorporateData)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    const addNewCorporate = () => {
        setOpenAddCorporate({ action: true })
    };

    const editCorporate = (corporateData) => {
        setOpenAddCorporate({ action: true, corporateData: corporateData, readOnly: true })
    };

    const addUser = (rowData) => {
        setOpenAddUser({ action: true, corporateData: rowData, callFrom: "corporateList" })
    };

    const viewUsers = (rowData) => {
        setOpenViewUsers({ action: true, corporateData: rowData, callFrom: "corporateList" });
    };

    const activeDeActiveCorporate = async (rowData) => {
        let obj = {
            corporateName: rowData.corporateName,
            refNo: rowData.refNo,
            active: rowData.active ? false : true
        }
        await addCorporate(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getCorporateList()
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response?.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className='row'>
                        <div className="col-6">
                            <span><material.Typography variant="h5">Corporate List</material.Typography></span>
                        </div>
                        <div className="col-6">
                            <span className='float-end'>
                                <material.Button variant="contained" color='success' sx={{ textTransform: "none" }} onClick={addNewCorporate} startIcon={<material.AddIcon />}>Add-Corporate</material.Button>
                            </span>
                        </div>
                        <material.Paper sx={{ p: 2, mt: 2, }} elevation={3}>
                            <div className='col-12'>
                                <material.TableContainer sx={{ maxHeight: 560 }}>
                                    <material.Table stickyHeader aria-label="sticky table">
                                        <material.TableHead >
                                            <material.TableRow>
                                                <StyledTableCell>Corporate Name</StyledTableCell>
                                                <StyledTableCell>Email</StyledTableCell>
                                                <StyledTableCell>Phone</StyledTableCell>
                                                <StyledTableCell>Address</StyledTableCell>
                                                <StyledTableCell>Status</StyledTableCell>
                                                <StyledTableCell align='right'>Action</StyledTableCell>
                                            </material.TableRow>
                                        </material.TableHead>
                                        <material.TableBody>
                                            {corporateData?.length ? corporateData.map((row, i) => (
                                                <material.TableRow key={i}>
                                                    <material.TableCell size='small'>{row.corporateName}</material.TableCell>
                                                    <material.TableCell size='small'>{row.email}</material.TableCell>
                                                    <material.TableCell size='small'>{row.phone}</material.TableCell>
                                                    <material.TableCell className='text-break' size='small' style={{ width: "10rem" }}>{row.registeredAddress}</material.TableCell>
                                                    <material.TableCell size='small'>{row.active ? (<p style={{ color: "green", fontWeight: "bold" }}>active</p>) : (<p style={{ color: "red", fontWeight: "bold" }}>In-active</p>)}</material.TableCell>
                                                    <material.TableCell align='right' size='small'>
                                                        <material.IconButton title={row.active ? "Active" : "In-active"} aria-label="visibility" size="large">
                                                            <material.Switch {...label} checked={row.active} color='success' onClick={() => activeDeActiveCorporate(row)} />
                                                        </material.IconButton>
                                                        <material.IconButton title='Add User' aria-label="create" size="large" onClick={() => addUser(row)} disabled={!row.active}>
                                                            <material.PersonAddIcon color='primary' />
                                                        </material.IconButton>
                                                        <material.IconButton title='View User' aria-label="create" size="large" onClick={() => viewUsers(row)}>
                                                            <material.VisibilityIcon color='primary' />
                                                        </material.IconButton>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )) : null}
                                        </material.TableBody>
                                    </material.Table>
                                </material.TableContainer>
                            </div>
                        </material.Paper>
                    </div>
                </material.Box>
            </material.Box>
            <AddCorporate
                openAddCorporate={openAddCorporate}
                setOpenAddCorporate={setOpenAddCorporate}
                getCorporateList={getCorporateList}
            />
            <AddUser
                openAddUser={openAddUser}
                setOpenAddUser={setOpenAddUser}
            />
            <ViewUsers
                openViewUsers={openViewUsers}
                setOpenViewUsers={setOpenViewUsers}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default CorporateList;